import { useState } from "react";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ChevronThinIcon from "../../global/components/ChevronThinIcon/ChevronThinIcon";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import { AccordionItem } from "../../shared/components/Accordion/Accordion";
import { convertAccordionIdToValues } from "../../shared/components/Accordion/StyledAccordion.methods";
import { StyledInput } from "../../shared/components/Input/StyledInput/StyledInput";
import { ReactComponent as CheckMarkIcon } from "../../shared/icons/checkMarkIcon.svg";
import { ReactComponent as SearchIcon } from "../../shared/icons/search1.svg";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { BOX_ACCORDION_DISPLAY_SETTINGS } from "../BoxAccordion/BoxAccordion.pattern";
import { StyledFacetAccordion } from "./BoxSearchFilters.styled";
import { FacetStyle, ISearchFacetProps } from "./BoxSearchFilters.types";
import {
  StyledFacetCheckbox,
  StyledFacetContainer,
  StyledFacetList,
  StyledInputWithIcon,
  StyledSearchValue,
  StyledShowMore,
  TextBreaker,
} from "./SearchFacet.styled";
import { useSearchFacetLogic } from "./useSearchFacetLogic";
import { useSetFacetListHeight } from "./useSetFacetListHeight";

const SearchFacetMobile: React.FC<ISearchFacetProps> = ({
  displaySettings,
  contentSettings,
  contentSettings: {
    count,
    showMore: { initialLimit },
    search,
  },
  content: { showMore, showLess, searchPlaceholder },
  facet,
  selectValue,
}) => {
  const theme = useTheme() as IThemeState;

  const [showAll, setShowAll] = useState(false);
  const [isOpen, setIsOpen] = useState(
    displaySettings.facetStyle.displayType === FacetStyle.ACCORDION
  );
  const {
    changeSearchQuery,
    showElement,
    facetValuesSearchResult,
    isMoreToShow,
  } = useSearchFacetLogic(facet, initialLimit);

  const numberOfItems = showAll ? initialLimit * 2 : initialLimit;

  const { myRefs, totalHeight } = useSetFacetListHeight(
    facetValuesSearchResult,
    numberOfItems
  );

  return (
    <ConditionalWrapper
      condition={displaySettings.facetStyle.displayType === FacetStyle.LIST}
      wrapper={(children) => (
        <StyledFacetContainer isOpen={isOpen} className="facet-container">
          <div
            onClick={() => setIsOpen((prevState) => !prevState)}
            className="facet-container__title"
            data-testid="facet-container__title"
          >
            <StyledText $settings={displaySettings.facetNameStyle}>
              <TextBreaker>
                {facet.translation || facet.attributeId}
              </TextBreaker>
            </StyledText>
            <div className="facet-container__right-icon">
              <ChevronThinIcon className="rightIcon" width="14" height="7" />
            </div>
          </div>
          {children}
        </StyledFacetContainer>
      )}
    >
      <ConditionalWrapper
        condition={
          displaySettings.facetStyle.displayType === FacetStyle.ACCORDION
        }
        wrapper={(children) => (
          <StyledFacetAccordion
            accordionAlignment={
              convertAccordionIdToValues(
                displaySettings.facetStyle.accordionStyle,
                theme.globalObjects.accordions
              ).accordionAlignment
            }
          >
            <AccordionItem
              forceOpen={isOpen}
              labelText={facet.translation || facet.attributeId}
              accordionItemStyle={displaySettings.facetStyle.accordionStyle}
              handleOpenCallback={() => setIsOpen((prevState) => !prevState)}
              disabled={false}
              accordionSettings={BOX_ACCORDION_DISPLAY_SETTINGS()}
            >
              {children}
            </AccordionItem>
          </StyledFacetAccordion>
        )}
      >
        <>
          {isOpen && (
            <>
              {contentSettings.search.show && (
                <StyledInputWithIcon className="facet-container__search-input">
                  <SearchIcon />
                  <StyledInput
                    $settings={search}
                    placeholder={searchPlaceholder}
                    type="text"
                    onChange={(e) => changeSearchQuery(e.target.value)}
                  />
                </StyledInputWithIcon>
              )}
              <div className="facet-container__list-wrapper">
                <StyledFacetList
                  showMore={numberOfItems > initialLimit}
                  className="facet-container__list-wrapper__facet-list"
                  listHeight={totalHeight}
                  data-testid="facet-container__list-wrapper__facet-list"
                >
                  {facetValuesSearchResult.map(
                    (value, index) =>
                      showElement(index, showAll, numberOfItems) && (
                        <StyledSearchValue
                          key={value.name}
                          className="facet-container__list-wrapper__facet-list__single-facet"
                          ref={myRefs.current[index]}
                          data-testid="facet-container__list-wrapper__facet-list__single-facet"
                        >
                          <StyledText
                            $settings={displaySettings.facetValueStyle}
                          >
                            <StyledFacetCheckbox checked={value.checked}>
                              <input
                                type="checkbox"
                                onChange={() => {
                                  selectValue(
                                    value.checked,
                                    facet.attributeId,
                                    value.name
                                  );
                                }}
                                checked={value.checked}
                              ></input>
                              {value.checked && (
                                <span className="checkmark">
                                  <CheckMarkIcon />
                                </span>
                              )}
                              <TextBreaker>{value.name}</TextBreaker>
                            </StyledFacetCheckbox>
                          </StyledText>
                          <StyledText $settings={count}>
                            {value.count}
                          </StyledText>
                        </StyledSearchValue>
                      )
                  )}
                </StyledFacetList>
              </div>

              {isMoreToShow && facetValuesSearchResult.length > 0 && (
                <StyledShowMore showMore={showAll}>
                  <StyledText
                    $settings={contentSettings.showMore}
                    onClick={() => setShowAll((prevState) => !prevState)}
                    className="facet-container__show-more-button"
                    data-testid="facet-container__show-more-button"
                  >
                    {numberOfItems > initialLimit
                      ? showLess
                      : `${showMore} (${isMoreToShow})`}
                  </StyledText>
                </StyledShowMore>
              )}
            </>
          )}
        </>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
};

export default SearchFacetMobile;
