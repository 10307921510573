import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  ILinkContent,
  SelectedGoToOption,
} from "../settingsPatterns/contentPatterns.types";

export const getHrefFromLinkContent = (el: ILinkContent): TUrl => {
  if (
    el.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE &&
    el.goToPageSetting?.link
  ) {
    return el.goToPageSetting.link;
  }
  if (
    el.selectedGoToOption === SelectedGoToOption.GO_TO_ROW &&
    el.goToRowSetting?.rowId
  ) {
    return `#SECTION=${el.goToRowSetting.rowId}`;
  }
  return "";
};

export const getOpenInNewTabFromLinkContent = (el: ILinkContent): boolean => {
  return (
    el.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE &&
    el.goToPageSetting.openInNewTab
  );
};
