import { useEffect } from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import { isPortalSide } from "../../helpers/helpers";
import * as myOrdersAPI from "@ecp-redux/api/myOrders";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import { StyledOrderGapWrapper } from "./BoxMyAccountMyOrdersDetails.styled";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
  TBoxMyAccountMyOrdersDetailsProps,
} from "./BoxMyAccountMyOrdersDetails.types";
import MyOrderDeliveryDetails from "./MyOrderDeliveryDetails/MyOrderDeliveryDetails";
import MyOrderDetailsHeader from "./MyOrderDetailsHeader/MyOrderDetailsHeader";
import MyOrderDetailsTotalCosts from "./MyOrderDetailsTotalCosts/MyOrderDetailsTotalCosts";

export const StyledOrderDetailsViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const useOrderDetail = (): IOrderDetailsResponse | undefined => {
  const orderIdFromUrl = useRouter()?.query?.["orderId"] ?? "";

  const { data: dataOrderList } = myOrdersAPI.useGetMyOrdersOrdersListQuery(0, {
    skip: isPortalSide(),
  });
  const [trigger, { data: orderData }] =
    myOrdersAPI.useLazyGetMyOrdersOrderDetailsQuery();

  const orderIdFirstFromList = !isPortalSide() && dataOrderList?.orders.length !== 0 && dataOrderList?.orders[0].orderId;

  const choosenOrder = orderIdFirstFromList || orderIdFromUrl;

  useEffect(() => {
    if (choosenOrder && typeof choosenOrder === "string") {
      trigger(choosenOrder);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenOrder]);

  return orderData;
};

const BoxMyAccountMyOrdersDetails: React.FC<TBoxMyAccountMyOrdersDetailsProps> =
  () => {
    const { settings, messages } = useMessagesSettingsContext<
      IBoxMyAccountMyOrdersDetailsMessages,
      IBoxMyAccountMyOrdersDetailsSettings
    >();

    const orderData = useOrderDetail();

    if (orderData === null || orderData === undefined)
      return <div>{messages.customer_data_order_no_data}</div>;

    return (
      <StyledOrderGapWrapper
        elementsGap={
          settings.customer_data_order_details_heading_info_elements_gap
        }
        className="my-account-my-orders-details"
      >
        <MyOrderDetailsHeader orderResponse={orderData} />
        <MyOrderDetailsTotalCosts orderResponse={orderData} />

        {orderData.order.portalOrderDispatches.map((deliveryData, index) => (
          <MyOrderDeliveryDetails
            {...{ deliveryData, orderUuid: orderData.order.uuid }}
            key={index}
          />
        ))}
      </StyledOrderGapWrapper>
    );
  };

export default BoxMyAccountMyOrdersDetails;
