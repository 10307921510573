import { useState } from "react";
import { IElementTextContent } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import Dropdown from "../../../shared/components/Dropdown/Dropdown";
import useScrollWhileOnDropdown from "../../../shared/hooks/useScrollWhileOnDropdown";
import { ReactComponent as ArrowDown } from "../../../shared/icons/arrowDown.svg";
import {
  DropdownListEl,
  StyledDropdownList,
  StyledSearchUtilsLabel,
  StyledToggler,
  StyledTogglerLabel,
} from "../../../shared/styleElements/StyledSearchResultsDropdown/SearchResultsDropdown.styled";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../BoxSearchResults.types";
import { SortOption } from "./Sort.types";
import { useSearchResultsLogic } from "./useSearchResultsLogic";

interface SortProps {
  label: string;
  sortSettings: IElementTextContent;
  changeSort: (sort: SortOption) => void;
}

const Sort: React.FC<SortProps> = ({ sortSettings, label, changeSort }) => {
  const { messages } = useMessagesSettingsContext<
    IBoxSearchResultsMessages,
    IBoxSearchResultsSettings
  >();

  const { findSortElementMessageKey, choosenSortQuery, sortOptions } =
    useSearchResultsLogic();

  const [isOpen, setIsOpen] = useState(false);
  useScrollWhileOnDropdown(isOpen, setIsOpen);

  if (!sortSettings.show) {
    return null;
  }

  return (
    <div className="sort-container">
      <StyledSearchUtilsLabel $settings={sortSettings}>
        {label}:
      </StyledSearchUtilsLabel>
      <div
        className="sort-container__dropdown-container"
        onMouseLeave={() => setIsOpen(false)}
      >
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onBackDropClick={() => setIsOpen(false)}
          buttonContent={
            <StyledToggler
              $settings={sortSettings}
              onClick={() => setIsOpen((prev) => !prev)}
              renderAs="button"
              isOpen={isOpen}
            >
              <StyledTogglerLabel>
                {messages[findSortElementMessageKey(choosenSortQuery)]}
              </StyledTogglerLabel>
              <ArrowDown />
            </StyledToggler>
          }
          selectContainerContent={
            <StyledDropdownList $settings={sortSettings} renderAs="ul">
              {sortOptions.map((el) => (
                <DropdownListEl
                  key={messages[findSortElementMessageKey(el)]}
                  onClick={() => {
                    setIsOpen(false);
                    changeSort(el);
                  }}
                >
                  {messages[findSortElementMessageKey(el)]}
                </DropdownListEl>
              ))}
            </StyledDropdownList>
          }
        />
      </div>
    </div>
  );
};

export default Sort;
