import React, { useMemo, useState } from "react";
import LinkWrapper, {
  StyledLinkWrapper,
} from "../../global/components/LinkWrapper/LinkWrapper";
import Dropdown from "../../shared/components/Dropdown/Dropdown";
import DropdownSelectContainer from "../../shared/components/Dropdown/DropdownSelectContainer";
import useScrollWhileOnDropdown from "../../shared/hooks/useScrollWhileOnDropdown";
import { StyledCloseDropdown, StyledOpenDropdown } from "./BoxDropdown.styled";
import { IBoxDropdownProps } from "./BoxDropdown.types";

const BoxDropdown: React.FC<IBoxDropdownProps> = ({
  settings,
  messages,
}: IBoxDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const dropdownItems = useMemo(
    () => messages["dropdown_labels"].split(";"),
    [messages.dropdown_labels]
  );

  const redirectUrls = useMemo(
    () => settings["dropdown_label_redirect_urls"].split(";"),
    [settings.dropdown_label_redirect_urls]
  );

  useScrollWhileOnDropdown(isOpen, setIsOpen);

  return (
    <StyledCloseDropdown
      dropdownWidth={settings.dropdown_min_width}
      closeDropdownPadding={{
        top: settings.close_dropdown_top_padding,
        right: settings.close_dropdown_right_padding,
        bottom: settings.close_dropdown_bottom_padding,
        left: settings.close_dropdown_left_padding,
      }}
      borderSize={settings.close_dropdown_border_size}
      borderColor={settings.close_dropdown_border_color}
      closeDropdownBackgroundColor={settings.close_dropdown_background_color}
      boxPosition={settings.box_position}
      itemsInDropdownPosition={settings.items_in_dropdown_position}
      mainLinkStyle={settings.dropdown_label_main_link_style}
    >
      <div className="dropdown" onMouseLeave={() => setIsOpen(false)}>
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onBackDropClick={() => setIsOpen(false)}
          buttonContent={
            <StyledLinkWrapper
              $linkStyle={settings.dropdown_label_main_link_style}
              $fontStyle={settings.dropdown_label_main_typography}
              onClick={() => setIsOpen(true)}
              renderAs="div"
            >
              <div className="close-dropdown-container">
                <span
                  className="close-dropdown-item"
                  data-testid="select-toggler-item"
                >
                  {messages.dropdown_main_label}
                </span>
                {((settings.close_dropdown_icon_url !== "" && !isOpen) ||
                  (settings.open_dropdown_icon_url !== "" && isOpen)) && (
                  <img
                    width={
                      isOpen
                        ? settings.open_dropdown_icon_size
                        : settings.close_dropdown_icon_size
                    }
                    src={
                      isOpen
                        ? settings.open_dropdown_icon_url
                        : settings.close_dropdown_icon_url
                    }
                  />
                )}
              </div>
            </StyledLinkWrapper>
          }
          selectContainerContent={
            <DropdownSelectContainer
              isOpen={isOpen}
              className="box-dropdown__dropdown-select-container"
            >
              <StyledOpenDropdown
                dropdownWidth={settings.dropdown_min_width}
                dropdownPosition={settings.dropdown_position}
                openDropdownPadding={{
                  top: settings.open_dropdown_top_padding,
                  right: settings.open_dropdown_right_padding,
                  bottom: settings.open_dropdown_bottom_padding,
                  left: settings.open_dropdown_left_padding,
                }}
                openDropdownBackgroundColor={
                  settings.open_dropdown_background_color
                }
                itemsInDropdownPosition={settings.items_in_dropdown_position}
                linkStyle={settings.dropdown_label_link_style}
              >
                <ul>
                  {dropdownItems.map((item, index) => (
                    <li
                      data-testid="select-dropdown-item"
                      key={`${item}_${index}`}
                      onClick={closeDropdown}
                    >
                      <LinkWrapper
                        linkStyle={settings.dropdown_label_link_style}
                        fontStyle={settings.dropdown_label_typography}
                        href={redirectUrls[index] ?? "/"}
                      >
                        {item}
                      </LinkWrapper>
                    </li>
                  ))}
                </ul>
              </StyledOpenDropdown>
            </DropdownSelectContainer>
          }
        />
      </div>
    </StyledCloseDropdown>
  );
};

export default BoxDropdown;
