import React from "react";
import { IElementInput } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { TInputId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledInputValidation } from "./StyledInput";

interface IInputValidation {
  $settings: IElementInput | TInputId;
  errorMassage: string;
}
const InputValidation: React.FC<IInputValidation> = ({
  $settings,
  errorMassage,
}): JSX.Element => {
  return (
    <StyledInputValidation $settings={$settings} role="alert">
      {errorMassage}
    </StyledInputValidation>
  );
};

export default InputValidation;
