import React from "react";
import { IIcon } from "../icons.types";

const VideoIcon: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2829_6954)">
        <path
          d="M56.7191 6.39486C56.3864 5.15884 55.7374 4.032 54.8369 3.12654C53.9363 2.22109 52.8155 1.56862 51.5861 1.2341C47.0852 0 28.9706 0 28.9706 0C28.9706 0 10.8551 0.0373555 6.35422 1.27146C5.12477 1.60599 4.00395 2.2585 3.10337 3.16399C2.20278 4.06949 1.55386 5.19638 1.22122 6.43244C-0.140199 14.4725 -0.668316 26.7238 1.2586 34.4423C1.59128 35.6783 2.24022 36.8051 3.1408 37.7106C4.04138 38.616 5.16218 39.2685 6.3916 39.603C10.8925 40.8371 29.0075 40.8371 29.0075 40.8371C29.0075 40.8371 47.1223 40.8371 51.623 39.603C52.8524 39.2686 53.9733 38.6161 54.8739 37.7106C55.7745 36.8052 56.4235 35.6783 56.7562 34.4423C58.1922 26.3908 58.6346 14.1473 56.7191 6.39509V6.39486Z"
          fill="#b6d0fb"
        />
        <path
          d="M23.2051 29.1692L38.2325 20.4185L23.2051 11.6677V29.1692Z"
          fill="#4286f4"
        />
      </g>
      <defs>
        <clipPath id="clip0_2829_6954">
          <rect width="58" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

VideoIcon.defaultProps = {
  width: "50",
  height: "50",
  fill: "none",
  viewBox: "0 0 50 50",
};

export default VideoIcon;
