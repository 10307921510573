import React, { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as myAccount from "@ecp-redux/api/myAccount";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { isPhone } from "../../../helpers/validators";
import { useAddAlert } from "../../../redux/slices/alertsSlice";
import { CheckboxesList } from "../../../shared/components/CheckboxesList/CheckboxesList";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountConsentsMessages,
  IBoxMyAccountConsentsSettings,
} from "../BoxMyAccountConsents.types";
import EmailConsent from "./checkboxes/EmailConsent";
import ProfileConsent from "./checkboxes/ProfileConsent";
import SmsConsent from "./checkboxes/SmsConsent";

export interface IConsentsForm {
  sms_consent: boolean;
  email_consent: boolean;
  profiling_consent: boolean;
  phone: string;
}

interface MyAccountConsentsProps {
  editData?: IConsentsForm;
  email?: string;
}

const MyAccountConsentsForm: React.FC<MyAccountConsentsProps> = ({
  editData,
  email,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountConsentsMessages,
    IBoxMyAccountConsentsSettings
  >();
  const { addAlert } = useAddAlert();

  const [saveConsents] = myAccount.usePutConsentsMutation();

  const formData: IConsentsForm = {
    sms_consent: editData?.sms_consent ?? false,
    email_consent: editData?.email_consent ?? false,
    profiling_consent: editData?.profiling_consent ?? false,
    phone: editData?.phone ?? "",
  };

  const validationSchema = yup.object().shape({
    phone: isPhone(messages.form_syntax_verification_phone).when(
      "sms_consent",
      {
        is: true,
        then: yup.string().required(messages.form_empty_verification_phone),
      }
    ),
  });

  const formMethods = useForm<IConsentsForm>({
    defaultValues: formData,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    delayError: 500,
  });

  const { handleSubmit, formState, control, reset } = formMethods;

  const registerCheckboxes = useMemo(
    () => [
      ...(settings.show_sms_consent === "true" &&
      isNotEmptyMessage(messages.customer_data_sms_consent) &&
      isNotEmptyMessage(messages.customer_data_sms_consent_heading)
        ? [
            {
              name: "sms_consent",
              label: (
                <SmsConsent phone={editData?.phone} phone_control={control} />
              ),
            },
          ]
        : []),
      ...(settings.show_email_consent === "true" &&
      isNotEmptyMessage(messages.customer_data_email_consent) &&
      isNotEmptyMessage(messages.customer_data_email_consent_heading)
        ? [
            {
              name: "email_consent",
              label: <EmailConsent email={email} />,
            },
          ]
        : []),
      ...(settings.show_profiling_consent === "true" &&
      isNotEmptyMessage(messages.customer_data_profile_consent) &&
      isNotEmptyMessage(messages.customer_data_profile_consent_heading)
        ? [
            {
              name: "profiling_consent",
              label: <ProfileConsent />,
            },
          ]
        : []),
    ],
    [
      editData,
      control,
      email,
      settings.show_email_consent,
      settings.show_profiling_consent,
      settings.show_sms_consent,
    ]
  );

  const onSubmit = (data: IConsentsForm) => {
    saveConsents({
      consentD: data.profiling_consent,
      email: data.email_consent,
      mobile: data.phone,
      sms: data.sms_consent,
    })
      .unwrap()
      .then((r) => {
        addAlert({
          code: "cons_succ",
          message: messages.customer_data_consent_success,
        });

        reset(data);
      })
      .catch(() => {
        addAlert({
          code: "cons_error",
          message: messages.customer_data_consent_error,
        });
      });
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit((d) => onSubmit(d))}
        autoComplete="off"
        data-testid="consentsForm"
        className="my-account-consents-container__form"
      >
        <CheckboxesList
          checkboxes={registerCheckboxes}
          checkAllText={
            <StyledText
              $settings={{
                font: settings.checkbox_title_typo,
                text: { color: settings.checkbox_color },
              }}
              className="my-account-consents-container__form__select-all-button"
              show={isNotEmptyMessage(
                messages.customer_data_consent_select_all
              )}
            >
              {messages.customer_data_consent_select_all}
            </StyledText>
          }
          checkboxesGap={settings.gap}
          textStyles={{
            labelTypo: settings.checkbox_description_typo,
            labelColor: settings.checkbox_color,
            errorTypo: settings.checkbox_description_typo,
            errorColor: settings.checkbox_color,
          }}
        />
        {formState.isDirty && (
          <div className="form-button-container">
            <StyledButton
              renderAs="button"
              type="submit"
              $settings={settings.save_button}
              className="my-account-consents-container__form__submit-button"
              show={isNotEmptyMessage(messages.customer_data_save_button)}
            >
              {messages.customer_data_save_button}
            </StyledButton>
            <StyledButton
              renderAs="button"
              $settings={settings.cancel_button}
              onClick={() => reset()}
              className="my-account-consents-container__form__cancel-button"
              show={isNotEmptyMessage(messages.customer_data_cancel_button)}
            >
              {messages.customer_data_cancel_button}
            </StyledButton>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default MyAccountConsentsForm;
