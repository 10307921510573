import React, { useEffect } from "react";
import { IGlobalDropdownObject } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isPortalSide } from "../../helpers/helpers";
import { useGetProductAttributesContext } from "../../helpers/pageContext";
import { findCartLineBySku } from "../../shared/components/domain/CartB2B/cartB2B.methods";
import { useCartB2BContext } from "../../shared/components/domain/CartB2B/useCartB2BContext";
import ThemeStyledDropdown from "../../shared/styleElements/StyledDropdown/ThemeStyledDropdown";
import { useCommunicationBetweenBoxesContext } from "../../structure/Contexts/CommunicationBetweenBoxes";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxB2BAddToCartUnitProps,
  IBoxB2BAddToCartUnitSettings,
} from "./BoxB2BAddToCartUnit.types";

const BoxB2BAddToCartUnit: React.FC<IBoxB2BAddToCartUnitProps> = () => {
  const { settings } = useMessagesSettingsContext<
    Record<string, never>,
    IBoxB2BAddToCartUnitSettings
  >();

  const { cartData } = useCartB2BContext();

  const product = useGetProductAttributesContext();

  const { chosenUnit, setChosenUnit } = useCommunicationBetweenBoxesContext();

  useEffect(() => {
    if (chosenUnit === "" && product)
      setChosenUnit(product.mainUnitOfMeasurement);
  }, [product]);

  const cartLine = findCartLineBySku(
    cartData,
    product
      ? {
          sku: product.sku,
          unitOfMeasure: {
            currentUnitOfMeasure: product.mainUnitOfMeasurement,
            availableUnitsOfMeasure: [
              ...product.alternativeUnitOfMeasures.map((unit) => ({
                unitOfMeasure: unit.auoM,
                unitOfMeasureLabel: unit.auoMLabel,
                unitOfMeasureQuantity: unit.value,
              })),
              {
                unitOfMeasure: product.mainUnitOfMeasurement,
                unitOfMeasureLabel: product.mainUnitOfMeasurementLabel,
                unitOfMeasureQuantity: 1,
              },
            ],
          },
        }
      : undefined
  );

  const dropdownItems = cartLine
    ? cartLine.unitOfMeasure.availableUnitsOfMeasure.map(
        ({ unitOfMeasure, unitOfMeasureLabel, unitOfMeasureQuantity }) => ({
          unitOfMeasure: unitOfMeasure,
          unitOfMeasureLabel:
            unitOfMeasure === cartLine.unitOfMeasure.baseUnitOfMeasure
              ? unitOfMeasureLabel
              : `${unitOfMeasureLabel} (${unitOfMeasureQuantity})`,
        })
      )
    : [];

  if (product === null) {
    return isPortalSide() ? null : <div>No content to display</div>;
  }

  return (
    <ThemeStyledDropdown
      style={{
        id: settings.b2b_add_to_cart_unit_dropdown_style,
        custom: {} as IGlobalDropdownObject,
      }}
      data-testid="b2b-unit-chooser"
      items={dropdownItems.map((item) => ({
        id: item.unitOfMeasure,
        label: item.unitOfMeasureLabel,
      }))}
      selectedItemId={chosenUnit}
      onClickItem={(id) => setChosenUnit(id)}
    />
  );
};

export default BoxB2BAddToCartUnit;
