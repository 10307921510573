import classNames from "classnames";
import { IElementButton } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { TButtonId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import StyledButton from "../../../../../../../lib/shared/styleElements/StyledButton/StyledButton";
import LinkWrapper from "../../../../../../global/components/LinkWrapper/LinkWrapper";
import AddToCart from "../../../Cart/AddToCart/AddToCart";
import { IProductButton, ProductButtonActions } from "../ProductModule.types";
import { StyledActionButton } from "./ActionButton.styled";

interface IActionButtonProps {
  buttonText: string;
  buttonStyle: IElementButton | TButtonId;
  itemId: string | undefined;
  productUrl: string;
  buttonSettings?: IProductButton;
  className?: string;
}

const ActionButton: React.FC<IActionButtonProps> = ({
  buttonText,
  buttonStyle,
  itemId,
  buttonSettings,
  productUrl,
  className,
}) => {
  const isGoToUrl =
    buttonSettings?.type === ProductButtonActions.GO_TO_URL &&
    buttonSettings?.url !== undefined;
  const isGoToProduct =
    buttonSettings?.type === ProductButtonActions.GO_TO_PRODUCT && productUrl;

  return (
    <StyledActionButton
      className={classNames("actionButtons", className ?? "")}
    >
      {isGoToUrl && (
        <LinkWrapper
          href={buttonSettings.url}
          className="action-button-container__link-wrapper"
        >
          <StyledButton
            className="action-button-container__link-wrapper__go-to-url"
            role="button"
            $settings={buttonStyle}
            data-testid="go-to-url-button"
            renderAs={"button"}
          >
            <i />
            {buttonText}
          </StyledButton>
        </LinkWrapper>
      )}
      {isGoToProduct && (
        <LinkWrapper
          href={productUrl}
          className="action-button-container__link-wrapper"
        >
          <StyledButton
            className="action-button-container__link-wrapper__go-to-product"
            role="button"
            $settings={buttonStyle}
            data-testid="go-to-product-button"
            renderAs={"button"}
          >
            <i />
            {buttonText}
          </StyledButton>
        </LinkWrapper>
      )}
      {!isGoToProduct && !isGoToUrl && (
        <AddToCart
          itemId={itemId}
          buttonText={buttonText}
          buttonStyle={buttonStyle}
        />
      )}
    </StyledActionButton>
  );
};

export default ActionButton;
