import classNames from "classnames";
import { IRatingStyle } from "../../../../../../boxes/BoxProductAttributes/BoxProductAttributes.types";
import StarIcon from "../../../../../icons/Star";
import StarCheckedIcon from "../../../../../icons/StarChecked";
import StyledText from "../../../../../styleElements/StyledText/StyledText";
import { StyledProductRating } from "./ProductRating.styled";

interface IProductRatingProps {
  labelName: string;
  ratingCount?: number;
  ratingScore: number;
  className?: string;
  ratingSettings?: IRatingStyle;
}

const ProductRating: React.FC<IProductRatingProps> = ({
  labelName,
  ratingCount,
  ratingScore,
  ratingSettings,
  className,
}) => {
  if (ratingSettings == null || ratingSettings.show === false) return null;

  return (
    <StyledProductRating
      data-testid="product_rating"
      className={classNames("product-rating", className ?? "")}
    >
      <StyledText
        $settings={ratingSettings}
        alignment="left"
        className="product-rating__rating-count"
      >
        {`${labelName} ${
          ratingCount || ratingCount === 0 ? `(${ratingCount})` : ""
        }`}
      </StyledText>
      <span className="product-rating__star-container">
        {new Array(5)
          .fill(0)
          .map((_, idx) =>
            idx < ratingScore ? (
              <StarCheckedIcon
                key={idx}
                fill={ratingSettings.filledPointsColor}
              />
            ) : (
              <StarIcon key={idx} fill={ratingSettings.unfilledPointsColor} />
            )
          )}
      </span>
    </StyledProductRating>
  );
};

export default ProductRating;
