import { useState } from "react";
import { useRouter } from "next/router";
import * as myOrdersAPI from "@ecp-redux/api/myOrders";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledOrderButtonsWrapper } from "../../BoxMyAccountMyOrders/BoxMyAccountMyOrders.styled";
import { Popups } from "../../BoxMyAccountMyOrders/OrderActionButtons/OrderActionButtons";
import CancelOrderPopup from "../../BoxMyAccountMyOrders/popups/CancelOrderPopup";
import { StyledOrderDetailsActionButtonsWrapper } from "../BoxMyAccountMyOrdersDetails.styled";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../BoxMyAccountMyOrdersDetails.types";

const OrderDetailsActionButtons: React.FC<IOrderDetailsResponse> = ({
  order,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersDetailsMessages,
    IBoxMyAccountMyOrdersDetailsSettings
  >();

  const router = useRouter();
  const [cancellOrder] = myOrdersAPI.usePutMyOrdersOrderCancelMutation();
  const [showPopup, setShowPopup] = useState<Popups>(Popups.NONE);

  return (
    <StyledOrderDetailsActionButtonsWrapper>
      <StyledOrderButtonsWrapper
        elementsGap={
          settings.customer_data_order_details_heading_info_buttons_gap
        }
      >
        <StyledButton
          onClick={() => setShowPopup(Popups.CANCEL_ORDER)}
          renderAs="button"
          $settings={settings.customer_data_order_details_cancel_button_style}
          show={
            isNotEmptyMessage(
              messages.customer_data_orders_dispatch_cancel_button
            ) && order?.allowedOperations?.canBeCancelled
          }
        >
          {messages.customer_data_orders_dispatch_cancel_button}
        </StyledButton>

        <StyledButton
          onClick={() => {
            router.push(
              settings.customer_data_order_details_repeat_payment_redirect_link
            );
          }}
          renderAs="button"
          $settings={
            settings.customer_data_order_details_repeat_payment_button_style
          }
          show={
            isNotEmptyMessage(
              messages.customer_data_order_details_repeat_payment_button
            ) &&
            order?.allowedOperations?.canBePaid &&
            order?.allowedOperations?.canBeCancelled
          }
        >
          {messages.customer_data_order_details_repeat_payment_button}
        </StyledButton>
      </StyledOrderButtonsWrapper>

      {showPopup === Popups.CANCEL_ORDER && (
        <CancelOrderPopup
          closePopup={() => setShowPopup(Popups.NONE)}
          onConfirmClick={() => {
            cancellOrder(order?.uuid).then(() => {
              setShowPopup(Popups.NONE);
            });
          }}
          settings={settings}
          messages={{
            popup_title: messages.customer_data_cancel_dispatch_popup_title,
            popup_description:
              messages.customer_data_cancel_dispatch_popup_description,
            button_save:
              messages.customer_data_cancel_dispatch_popup_button_save,
            button_cancel:
              messages.customer_data_cancel_dispatch_popup_button_cancel,
          }}
        />
      )}
    </StyledOrderDetailsActionButtonsWrapper>
  );
};

export default OrderDetailsActionButtons;
