import React from "react";
import { IPortalSection, SectionType } from "@ecp-pageTypes";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Section from "../Section/Section";

export interface IFooterData {
  sections: IPortalSection[];
}

const Footer: React.FC<IFooterData> = ({
  sections,
}: IFooterData): JSX.Element | null => {
  if (sections.length === 0) return null;

  return (
    <footer>
      {sections.map((section: IPortalSection, index: number) => {
        return (
          <Section
            key={section.uuid + section.mobileSubSection}
            sectionData={section}
            type={SectionType.FOOTER}
            path={
              new PortalPath([
                {
                  type: "FOOTER",
                  elementId: "x",
                  elementIndex: "x",
                },
                {
                  type: "SECTION",
                  elementId: section.id,
                  elementIndex: section.sectionOrder,
                },
              ])
            }
          />
        );
      })}
    </footer>
  );
};

Footer.defaultProps = {
  sections: [],
};

export default Footer;
