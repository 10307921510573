import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { A11y, Pagination, Swiper as SwiperProps } from "swiper";
import { SwiperSlide } from "swiper/react";
import { TSrpProductType } from "@ecp-redux/dto/searchResults.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { SliderPaginationOptions } from "../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import { StyledSwiper } from "../../settingsPatterns/elements/StyledSwiper/StyledSwiper";
import ClipboardIcon from "../../shared/components/Clipboard/ClipboardIcon";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import { isOnePhotoOrMore } from "./BoxPhotoGallery.method";
import {
  IBoxPhotoGallerySettings,
  IPhotoGallerySliderProps,
} from "./BoxPhotoGallery.types";
import { StyledPhotoGallerySlider } from "./elements/PhotoGallerySlider/PhotoGallerySlider.styles";

export const PhotoGalleryMobile: React.FC<IPhotoGallerySliderProps> = ({
  images,
  handleClick,
  setActiveSlide,
  productData = {
    productName: "",
    productSku: "",
    wishlist: { base: false, concept: false },
    type: TSrpProductType.PRODUCT,
  },
}) => {
  const { productName, productSku, wishlist, type } = productData;

  const [imageSwiper, setImageSwiper] = useState<SwiperProps>();
  const [imgWrapper, imageWidth] = useImageSize();
  const themeSettings = useTheme() as IThemeState;
  const { settings } = useMessagesSettingsContext<
    Record<string, never>,
    IBoxPhotoGallerySettings
  >();

  useEffect(() => {
    if (!imageSwiper) return;

    imageSwiper.on("snapIndexChange", (swiper) => {
      setActiveSlide(swiper.activeIndex);
    });
  }, [imageSwiper, setActiveSlide]);

  const updateImageSwiper = (swiper: SwiperProps) => {
    setImageSwiper(swiper);
  };

  return (
    <StyledPhotoGallerySlider
      ref={imgWrapper}
      disabled={!isOnePhotoOrMore(images)}
      className="photo-gallery-slider-container"
      bulletBackgroundColor={settings.photo_gallery_pagination_empty_color}
      bulletActiveBackgroundColor={
        settings.photo_gallery_pagination_fullfield_color
      }
    >
      <div className="photo-gallery-slider-container__photo-gallery-slider">
        <div className="photo-gallery-slider-container__photo-gallery-slider__image">
          <StyledSwiper
            $settings={{
              elementsCount: images ? images.length : 0,
              theme: themeSettings,
              paginationStyle: SliderPaginationOptions.DOTS,
              centerSlides: true,
            }}
            modules={[Pagination, A11y]}
            onSwiper={updateImageSwiper}
            pagination={{
              dynamicBullets: true,
              clickable: true,
              renderBullet: () => {
                return `<span class="swiper-pagination-bullet"></span>`;
              },
            }}
          >
            {settings.wishlist_icon_visible === "true" && productSku && (
              <ClipboardIcon
                isOnWishList={wishlist.base}
                productData={{ sku: productSku, type }}
              />
            )}
            {images?.map((image, index) => (
              <SwiperSlide
                key={index}
                onClick={handleClick}
                data-testid="slider-gallery"
                className="photo-gallery-slider-container__photo-gallery-slider__slide"
              >
                <div className="photo-gallery-slider-container__photo-gallery-slider__image--image-container">
                  <ImageWrapper
                    imageUrl={image ?? null}
                    seoDescription={productName}
                    imageFit={true}
                    imageAlignment={{
                      horizontal: AlignmentHorizontalOption.CENTER,
                      vertical: AlignmentVerticalOption.CENTER,
                    }}
                    width={imageWidth}
                  />
                </div>
              </SwiperSlide>
            ))}
          </StyledSwiper>
        </div>
      </div>
    </StyledPhotoGallerySlider>
  );
};

export default PhotoGalleryMobile;
