import styled, { useTheme } from "styled-components";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../../helpers/helpers";
import ProgressBar from "../../../../shared/components/ProgressBar/ProgressBar";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { IBoxCartB2BMessages, IBoxCartB2BSettings } from "../BoxCartB2B.types";

export const StyledLogisticMinimumProgressBarContainer = styled.div`
  width: fit-content;
`;
export const StyledLogisticMinimumContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 1px 0px 2px;
  gap: 85px;

  div {
    text-wrap: nowrap;
  }
`;

const LogisticMinimumProgressBar: React.FC<{
  order: IOrderB2B;
}> = ({ order }) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();
  const {
    basket_data_typo,
    basket_data_color,
    basket_data_typo2,
    basket_data_color2,
    basket_logistic_bar_border_color,
    basket_logistic_bar_progress_background_color,
    basket_logistic_bar_progress_border_color,
    basket_logistic_bar_progress_success_background_color,
  } = settings;

  return (
    <StyledLogisticMinimumProgressBarContainer className="logistic-minimum-bar__wrapper">
      <ProgressBar
        actualValue={order.netLogisticMin - order.netLogisticMinMissing}
        fullValue={order.netLogisticMin}
        fillerColor={
          order.netLogisticMinMissing === 0
            ? basket_logistic_bar_progress_success_background_color
            : basket_logistic_bar_progress_background_color
        }
        borderColor={basket_logistic_bar_border_color}
        fillerBorderColor={
          order.netLogisticMinMissing === 0
            ? basket_logistic_bar_progress_success_background_color
            : basket_logistic_bar_progress_border_color
        }
      />
      <StyledLogisticMinimumContent>
        <StyledText
          $settings={{
            font: basket_data_typo,
            text: {
              color: basket_data_color,
            },
          }}
        >
          {messages.basket_logistic_bar_minimum_label}
        </StyledText>
        <StyledText
          $settings={{
            font: basket_data_typo2,
            text: {
              color: basket_data_color2,
            },
          }}
        >
          {`${messages.basket_logistic_bar_minimum_left_label} ${
            order.netLogisticMinMissing < 0
              ? 0
              : formatPrice(
                  order.netLogisticMinMissing,
                  globalMessages.currencyShort,
                  false
                )
          }`}
        </StyledText>
      </StyledLogisticMinimumContent>
    </StyledLogisticMinimumProgressBarContainer>
  );
};

export default LogisticMinimumProgressBar;
