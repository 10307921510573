import React from "react";
import { useRouter } from "next/router";
import * as cartAPI from "@ecp-redux/api/cart";
import { CART_ERROR_STATUS } from "@ecp-redux/dto/cart.types";
import { isPortalSide } from "../../../helpers/helpers";
import { isCartError } from "../cart.methods";
import { StyledBoxCartStepOne } from "./BoxCartStepOne.styled";
import { IBoxCartStepOneProps } from "./BoxCartStepOne.types";
import ProductsCartList from "./potentialBoxes/ProductCartList/ProductsCartList";
import Summary from "./potentialBoxes/Summary/Summary";

const BoxCartStepOne: React.FC<IBoxCartStepOneProps> = ({
  settings,
  messages,
}) => {
  const { data, refetch } = cartAPI.useGetCartByPortalUserTokenQuery();
  const router = useRouter();

  const isEmptyCart =
    data == null || data.cartViewResponse.orderLines.length === 0;

  const goToStepTwo = () => {
    if (data?.cartViewResponse.cartUserStatus === "ANONYMOUS") {
      router.push(`${settings.basket_step_0_login_url}?cart=true`);
    } else {
      refetch()
        .unwrap()
        .then((resp) => {
          if (
            !isCartError(resp, [
              CART_ERROR_STATUS.PRODUCT_OUT_OF_STOCK,
              CART_ERROR_STATUS.PRODUCT_PARTIALLY_AVAILABLE,
              CART_ERROR_STATUS.PRODUCT_DISABLED,
              CART_ERROR_STATUS.PRODUCT_DELIVERY_TIME_NOT_AVAILABLE,
              CART_ERROR_STATUS.PRODUCT_NOT_EXIST,
            ])
          ) {
            router.push(settings.basket_step_0_next_step_url);
          }
        });
    }
  };

  if (isEmptyCart) {
    if (
      isPortalSide() &&
      settings.basket_step_one_empty_cart_url_redirect.startsWith("/")
    ) {
      router.push(settings.basket_step_one_empty_cart_url_redirect);
      return null;
    } else {
      return <div>{messages.empty_cart}</div>;
    }
  }

  return (
    <StyledBoxCartStepOne className="cart-step-one">
      <div className="cart_products">
        <ProductsCartList />
      </div>

      {data && (
        <Summary
          cartSummary={data.cartViewResponse.cartSummary}
          disabledNextStep={isCartError(data, [
            CART_ERROR_STATUS.PRODUCT_OUT_OF_STOCK,
            CART_ERROR_STATUS.PRODUCT_DISABLED,
            CART_ERROR_STATUS.PRODUCT_DELIVERY_TIME_NOT_AVAILABLE,
          ])}
          buttonClick={goToStepTwo}
          isSelectedDeliveryChannel={false}
        />
      )}
    </StyledBoxCartStepOne>
  );
};

export default BoxCartStepOne;
