import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../../global/global";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import Popup from "../../../../shared/components/Popup/Popup";
import StyledButton from "../../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../CartB2B/BoxCartB2B/BoxCartB2B.types";

const StyledPopupWrapper = styled.div`
  margin: 30px;

  .delete-type-popup__button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    align-items: center;
  }

  .delete-type-popup__description {
    margin: 30px 0;
  }

  .delete-type-popup__button-container__save-button {
    min-width: 100%;
  }

  .delete-type-popup__title,
  .delete-type-popup__description {
    text-align: center;
  }

  .delete-type-popup__button-container__cancel-button {
    min-width: 100%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .delete-type-popup__button-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

const ClearCartB2BPopup = ({
  confirmPopup,
  closePopup,
}: {
  closePopup: () => void;
  confirmPopup: () => void;
}) => {
  const {
    messages: {
      basket_deleting_popup_title,
      basket_deleting_popup_description,
      basket_deleting_popup_success_button_message,
      basket_deleting_popup_cancel_button_message,
    },
    settings: {
      basket_deleting_popup_cancel_button_style,
      basket_deleting_popup_approve_button_style,
      basket_deleting_popup_title_color,
      basket_deleting_popup_description_color,
      basket_deleting_popup_title_typo,
      basket_deleting_popup_description_typo,
    },
  } = useMessagesSettingsContext<IBoxCartB2BMessages, IBoxCartB2BSettings>();

  return (
    <Popup
      desktopWidth={"700px"}
      className="delete-type-popup"
      closePopup={closePopup}
    >
      <StyledPopupWrapper>
        <StyledText
          className="delete-type-popup__title"
          $settings={{
            font: basket_deleting_popup_title_typo,
            text: { color: basket_deleting_popup_title_color },
          }}
          show={isNotEmptyMessage(basket_deleting_popup_title)}
        >
          {basket_deleting_popup_title}
        </StyledText>

        <StyledText
          className="delete-type-popup__description"
          $settings={{
            font: basket_deleting_popup_description_typo,
            text: {
              color: basket_deleting_popup_description_color,
            },
          }}
          show={isNotEmptyMessage(basket_deleting_popup_description)}
        >
          {basket_deleting_popup_description}
        </StyledText>
        <div className="delete-type-popup__button-container">
          <StyledButton
            className="delete-type-popup__button-container__cancel-button"
            $settings={basket_deleting_popup_cancel_button_style}
            renderAs={"button"}
            data-testid="noPopupBtn"
            onClick={() => closePopup()}
            show={isNotEmptyMessage(
              basket_deleting_popup_cancel_button_message
            )}
          >
            {basket_deleting_popup_cancel_button_message}
          </StyledButton>
          <StyledButton
            className="delete-type-popup__button-container__save-button"
            $settings={basket_deleting_popup_approve_button_style}
            renderAs="button"
            data-testid="yesPopupBtn"
            onClick={() => {
              confirmPopup();
            }}
            show={isNotEmptyMessage(
              basket_deleting_popup_success_button_message
            )}
          >
            {basket_deleting_popup_success_button_message}
          </StyledButton>
        </div>
      </StyledPopupWrapper>
    </Popup>
  );
};

export default ClearCartB2BPopup;
