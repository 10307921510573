import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import { IDisplaySettingsSlot } from "@ecp-redux/dto/themeSettings/StyledSlot.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
  OutlineOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  HoverRowAndColumnEffectsScheme,
  hoverEffectType,
} from "@ecp-redux/dto/themeSettings/visualEffects.schemes";
import { HoverRowColumnEffectOption } from "@ecp-redux/dto/themeSettings/visualEffects.types";
import { createHoverEffect } from "../../shared/styleElements/Effects/RowAndColumnsEffects/hoverEffects";
import composeCss, { alignmentOptionsToValue } from "../composeCss";

export const SLOT_DISPLAY_PATTERN = (): IDisplaySettingsSlot => ({
  margin: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  padding: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  background: {
    color: { id: "color$$4", custom: "#000000" },
    backgroundUrl: "",
    fit: false,
    alignment: {
      horizontal: AlignmentHorizontalOption.CENTER,
      vertical: AlignmentVerticalOption.CENTER,
    },
    opacity: 100,
  },
  border: {
    color: { id: "color$$1", custom: "#000000" },
    style: OutlineOptions.NONE,
    width: { left: 0, right: 0, top: 0, bottom: 0 },
    radius: 0,
  },
  alignment: { vertical: AlignmentVerticalOption.CENTER },
  effects: {
    hoverEffect: {
      name: HoverRowColumnEffectOption.NONE,
      effectDetails: {},
    },
  },
});

export const StyledSlot = styled.div<ISlotSettings>((props) =>
  generateSlotStyles(props)
);

interface ISlotSettings {
  $settings: IDisplaySettingsSlot;
  theme: IThemeState;
  slotDataColumns?: number;
  hoverEffect: hoverEffectType;
}

const generateSlotStyles = ({
  $settings,
  theme,
  slotDataColumns,
  hoverEffect,
}: ISlotSettings): FlattenSimpleInterpolation => css`
  border-color: transparent;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${alignmentOptionsToValue($settings.alignment.vertical)};

  padding: ${composeCss.padding($settings.padding)};
  background: transparent;
  ${composeCss.backgroundWithOpacity($settings.background, theme)}
  grid-column: span ${slotDataColumns} / span ${slotDataColumns};
  ${composeCss.borderWidth($settings.border.width)};
  ${composeCss.borderStyle($settings.border.style)};
  ${composeCss.borderColor($settings.border.color, theme)};
  ${createHoverEffect(
    HoverRowAndColumnEffectsScheme.parse(hoverEffect),
    "slot"
  )};
  #overlay-slot {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
`;
