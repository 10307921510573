import styled from "styled-components";

export const StyledUploadFilesManager = styled.div<{
  gapBetweenFiles: string;
}>(({ gapBetweenFiles }) => {
  return `
  display: flex;
  flex-direction: column;
  gap: ${gapBetweenFiles};

  .complaint-form__upload-file-button__label {
    width: fit-content;
  }

  .complaint-form__upload-file-button {
    width: fit-content;
    cursor: pointer;
  }

  .complaint-form__file-uploader__input {
    display: none;
  }

  .complaint-form__file-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;    
  }

  .complaint-form__file-title {
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  `;
});
