import { FC } from "react";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import { StyledDisplayCustomerData } from "./BoxMyAccountMyData.styled";
import {
  IBoxMyAccountMyDataMessages,
  IBoxMyAccountMyDataSettings,
  TDisplayCustomerDataProps,
} from "./BoxMyAccountMyData.types";

const DisplayCustomerData: FC<TDisplayCustomerDataProps> = ({
  customerDataValue,
  message,
  children,
  className,
}) => {
  const { settings } = useMessagesSettingsContext<
    IBoxMyAccountMyDataMessages,
    IBoxMyAccountMyDataSettings
  >();

  return (
    <StyledDisplayCustomerData className={className + "__container"}>
      <div className={className + "__container__data"}>
        <StyledText
          className={`${className}__container__heading`}
          $settings={{
            font: settings.customer_data_label_typo,
            text: { color: settings.customer_data_label_color },
          }}
          show={isNotEmptyMessage(message)}
        >
          {message}
        </StyledText>
      </div>
      <StyledText
        className={`${className}__container__value`}
        $settings={{
          font: settings.customer_data_value_typo,
          text: { color: settings.customer_data_value_color },
        }}
        show={isNotEmptyMessage(message)}
      >
        {customerDataValue}
      </StyledText>
      {children}
    </StyledDisplayCustomerData>
  );
};

export default DisplayCustomerData;
