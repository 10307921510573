import styled from "styled-components";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import ConditionalWrapper from "../../../../../global/components/ConditionalWrapper/ConditionalWrapper";
import Portal from "../../../../../global/components/Portal/Portal";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import DeliveryCalendarB2B from "../DeliveryCalendarB2B/DeliveryCalendarB2B";
import OrderProductList from "./OrderProductList";
import OrderProductListMobile from "./OrderProductListMobile";

const StyledOrderB2B = styled.div(({ isMobile }: { isMobile: boolean }) => {
  return `
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${isMobile ? "" : "  overflow: hidden;"}
`;
});

const OrderB2B: React.FC<{
  order: IOrderB2B;
  deliveryCalendarOpen: boolean;
  handleCloseCalendar: () => void;
}> = ({ order, deliveryCalendarOpen, handleCloseCalendar }) => {
  const isMobile = useIsMobilePortal();

  return (
    <StyledOrderB2B isMobile={isMobile}>
      {deliveryCalendarOpen && (
        <ConditionalWrapper
          condition={isMobile}
          wrapper={(children) => <Portal>{children}</Portal>}
        >
          <DeliveryCalendarB2B
            handleCloseCalendar={handleCloseCalendar}
            deliveryCalendarOpen={deliveryCalendarOpen}
            order={order}
          />
        </ConditionalWrapper>
      )}

      {isMobile ? (
        <OrderProductListMobile order={order} />
      ) : (
        <OrderProductList order={order} />
      )}
    </StyledOrderB2B>
  );
};

export default OrderB2B;
