import React, { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { nanoid } from "nanoid";
import DropdownPortal from "@ecp-boxes/shared/components/Dropdown/DropdownPortal";
import useDropdownDimensions from "@ecp-boxes/shared/components/Dropdown/useDropdownDimensions";
import { useLayoutControllerContext } from "@ecp-boxes/structure/Contexts/LayoutControllerContext";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledMultimenuHorizontalDropdownContent } from "./MultimenuHorizontalDropdown.styled";

interface IMultiMenuHorizontalDropdownProps {
  width: number;
  isFullWidth: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  buttonContent: ReactNode;
  selectContainerContent: ReactNode;
  menuWrapperPercentageWidth: number;
  closeDropdown?: () => void;
  openHorizontalDirection: AlignmentHorizontalOption;
  onBackDropClick?: () => void;
  onBackDropMouseOver?: () => void;
  multimenuFullWidthPortalRef: MutableRefObject<HTMLUListElement | null>;
}

const MultimenuHorizontalDropdown: React.FC<IMultiMenuHorizontalDropdownProps> =
  ({
    width,
    isFullWidth,
    isOpen,
    setIsOpen,
    buttonContent,
    selectContainerContent,
    menuWrapperPercentageWidth,
    closeDropdown,
    openHorizontalDirection,
    onBackDropClick,
    onBackDropMouseOver,
    multimenuFullWidthPortalRef,
  }) => {
    const dropdownWrapper = useRef<HTMLDivElement>(null);
    const dropdownContent = useRef<HTMLDivElement>(null);

    const { dropdownContentDimensions, dropdownOpen } = useDropdownDimensions(
      isOpen,
      isFullWidth && multimenuFullWidthPortalRef
        ? multimenuFullWidthPortalRef
        : dropdownWrapper,
      dropdownContent
    );

    const { lastClickedId, setLastClickedId } = useLayoutControllerContext();
    const dropdownId = nanoid();

    const handleMouseEnter = () => {
      setLastClickedId(dropdownId);
    };

    useEffect(() => {
      if (lastClickedId !== dropdownId && dropdownOpen) {
        setIsOpen(false);
      }
    }, [lastClickedId]);

    useEffect(() => {
      dropdownWrapper.current?.addEventListener("wheel", () => {
        setIsOpen(false);
      });

      return () => {
        if (dropdownWrapper)
          dropdownWrapper.current?.removeEventListener("wheel", () => {
            setIsOpen(false);
          });
      };
    }, []);

    return (
      <div ref={dropdownWrapper}>
        <div
          onClick={() => setLastClickedId(dropdownId)}
          onMouseEnter={handleMouseEnter}
        >
          {buttonContent}
        </div>
        <DropdownPortal
          isOpen={dropdownOpen}
          close={closeDropdown}
          onBackDropClick={() => {
            onBackDropClick && onBackDropClick();
            setLastClickedId(null);
          }}
          onBackDropMouseOver={onBackDropMouseOver}
          data-testid="dropdown"
        >
          <StyledMultimenuHorizontalDropdownContent
            ref={dropdownContent}
            openHorizontalDirection={openHorizontalDirection}
            left={dropdownContentDimensions?.left ?? null}
            top={dropdownContentDimensions?.top ?? null}
            right={dropdownContentDimensions?.right ?? null}
            wrapperWidth={dropdownContentDimensions?.wrapperWidth ?? null}
            menuWrapperPercentageWidth={menuWrapperPercentageWidth}
            dropdownWidth={width}
          >
            {selectContainerContent}
          </StyledMultimenuHorizontalDropdownContent>
        </DropdownPortal>
      </div>
    );
  };

export default MultimenuHorizontalDropdown;
