import { useTheme } from "styled-components";
import {
  IThemeState,
  TButtonReadoutValue,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  convertButtonIdToValues,
  convertTypoIdToValues,
} from "../../../../../settingsPatterns/settingsPatterns.methods";

type TextHeight = { typo: TTypoReadoutValue; button?: undefined };
type ButtonHeight = { button: TButtonReadoutValue; typo?: undefined };

interface SpaceReservationProps {
  reservation: boolean;
  component: TextHeight | ButtonHeight;
}

const SpaceReservation: React.FC<SpaceReservationProps> = ({
  reservation,
  component,
}) => {
  const { globalObjects, typography } = useTheme() as IThemeState;

  let height = 0;
  if (component.typo !== undefined) {
    height = convertTypoIdToValues(component.typo, typography).lineHeight;
  }
  if (component.button !== undefined) {
    const button = convertButtonIdToValues(
      component.button,
      globalObjects.buttons
    );
    const currentButtonText = convertTypoIdToValues(button.font, typography);

    const width =
      typeof button.border.width === "number" ? button.border.width : 0; // Extra safety check, see oldBorderScheme "width" property

    height =
      button.verticalPadding * 2 +
      currentButtonText.lineHeight +
      (button.border.widthV2?.top ?? width) +
      (button.border.widthV2?.bottom ?? width);
  }

  return reservation ? (
    <div
      style={{ height }}
      className="product-module__content-container__empty-one-attribute"
    />
  ) : (
    <div
      className="product-module__content-container__empty-all-attributes"
      style={{ display: "none" }}
    />
  );
};

export default SpaceReservation;
