import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { IPortalProps } from "./Portal.types";

const DEFAULT_CONTAINER_ID = "portal";

const Portal: React.FC<IPortalProps> = ({
  anchorId,
  containerId = DEFAULT_CONTAINER_ID,
  children,
}) => {
  const currentElement =
    document.querySelector(`[data-id="${anchorId}"]`) ||
    document.getElementById(containerId);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!currentElement) {
      const rootElement = document.createElement("div");
      rootElement.setAttribute("id", containerId);
      rootElement.style.position = "absolute";
      rootElement.style.zIndex = "var(--zindex-header)";

      document.body.appendChild(rootElement);
    }

    setMounted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorId, mounted]);

  return mounted && currentElement
    ? createPortal(children, currentElement)
    : null;
};

export default Portal;
