import React from "react";
import { IIcon } from "../icons.types";

const ChevronThinIcon: React.FC<IIcon> = ({
  width,
  height,
  viewBox,
  onClick,
}: IIcon): JSX.Element => {
  return (
    <svg
      onClick={onClick}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 8.5L8.5 1.5L15.5 8.5"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

ChevronThinIcon.defaultProps = {
  fill: "#000",
  width: "17",
  height: "10",
  viewBox: "0 0 17 10",
};

export default ChevronThinIcon;
