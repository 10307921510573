import { differenceInDays, parseISO } from "date-fns";
import {
  CART_ERROR_STATUS,
  ICartResponse,
  IDeliveryAndPaymentResponse,
  IDeliveryChannel,
} from "@ecp-redux/dto/cart.types";
import { IBoxCartStepTwoMessages } from "./BoxCartStepTwo/BoxCartStepTwo.types";

export const isCartError = (
  cartResp: ICartResponse | IDeliveryAndPaymentResponse | undefined,
  errorType: CART_ERROR_STATUS[]
): boolean => {
  if (cartResp?.errors && cartResp.errors.length > 0) {
    return cartResp?.errors.some((error) =>
      errorType.some((type) => type === error.status)
    );
  }
  return false;
};

export const displayDeliveryDateRange = (
  deliveryChannel: IDeliveryChannel,
  messages: IBoxCartStepTwoMessages
) => {
  const { deliveryDateFrom, deliveryDateTo } = deliveryChannel;

  if (!deliveryDateFrom || !deliveryDateTo) {
    return messages.delivery_range_unavailable;
  }
  const daysFrom = differenceInDays(parseISO(deliveryDateFrom), new Date());
  const daysTo = differenceInDays(parseISO(deliveryDateTo), new Date());

  if (daysFrom === daysTo) {
    return `${daysFrom} ${messages.delivery_days}`;
  }

  return `${differenceInDays(
    parseISO(deliveryDateFrom),
    new Date()
  )}-${differenceInDays(parseISO(deliveryDateTo), new Date())} ${
    messages.delivery_days
  }`;
};
