import { useContext } from "react";
import {
  IBoxes,
  ILayoutPageBuilder,
  IPublicationPageBuilder,
  PageWithBoxesPageBuilder,
} from "@ecp-pageTypes";
import * as pages from "@ecp-redux/api/pages";
import { getNewestPagePortalArgs } from "../../helpers/pageContext";
import { usePortalSelector } from "../../redux/store";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { PortalPath } from "../../shared/portalPath/portalPath";
import { LayoutPageBuilderContext } from "../../structure/Contexts/LayoutPageBuilderForBox";
import { BOX_ACCORDION_DISPLAY_SETTINGS } from "./BoxAccordion.pattern";
import { IBoxAccordionProps } from "./BoxAccordion.types";

const getPageAndBoxesPerType = (
  dataPage: PageWithBoxesPageBuilder,
  type: "HEADER" | "FOOTER" | "MAIN"
): { page: ILayoutPageBuilder; boxes: IBoxes } | undefined => {
  if (type === "MAIN") {
    return { page: dataPage.page, boxes: dataPage.boxes };
  }
  if (type === "HEADER" && dataPage.page.header !== undefined) {
    return { page: dataPage.page.header, boxes: dataPage.headerBoxes };
  }
  if (type === "FOOTER" && dataPage.page.footer !== undefined) {
    return { page: dataPage.page.footer, boxes: dataPage.footerBoxes };
  }
  return undefined;
};

export const useGetDataPageAccordion = (
  pageTypeFromPath: "HEADER" | "FOOTER" | "MAIN"
): { page: ILayoutPageBuilder; boxes: IBoxes } | undefined => {
  const pagePortalArgs = usePortalSelector((state) =>
    getNewestPagePortalArgs(state.api.queries)
  );
  const { data } = pages.useGetPagePortalQuery(pagePortalArgs);
  const pageWithBoxesPageBuilder = useContext(LayoutPageBuilderContext);

  if (data !== undefined) {
    return getPageAndBoxesPerType(data, pageTypeFromPath);
  } else if (pageWithBoxesPageBuilder !== null) {
    return getPageAndBoxesPerType(pageWithBoxesPageBuilder, pageTypeFromPath);
  }

  return undefined;
};

export const getAllPublicationsInStackByIndex = (
  pageLayout: ILayoutPageBuilder | undefined | null,
  portalPath: PortalPath
): IPublicationPageBuilder[] | undefined => {
  if (pageLayout === undefined || pageLayout === null) return undefined;
  if (portalPath.isEmpty() || !portalPath.hasStack()) return undefined;

  const it = portalPath.getIterator();
  portalPath.isPageTypeInfoInPath() ? it.next() : 0;

  if (portalPath.isSubsection()) {
    const pathSection = pageLayout.sections[it.next().elementIndex as number];
    const pathSlot = pathSection?.slots[it.next().elementIndex as number];
    const pathSubsection = pathSlot?.sections[it.next().elementIndex as number];
    const pathSlotInSubsection =
      pathSubsection.slots[it.next().elementIndex as number];
    const pathStack =
      pathSlotInSubsection.stacks[it.next().elementIndex as number];
    return pathStack.publications;
  }

  const pathSection = pageLayout.sections[it.next().elementIndex as number];
  const pathSlot = pathSection.slots[it.next().elementIndex as number];
  const pathStack = pathSlot.stacks[it.next().elementIndex as number];
  return pathStack.publications;
};

export const useBoxAccordionData = (
  props: IBoxAccordionProps,
  path: PortalPath
) => {
  const displaySettings = usePrepareSettings({
    props: props.displaySettings,
    settingsPattern: BOX_ACCORDION_DISPLAY_SETTINGS(),
  }).settings;

  const dataPage = useGetDataPageAccordion(path.getPageType());

  if (
    dataPage === undefined ||
    dataPage.page === undefined ||
    dataPage.boxes === undefined
  ) {
    return { content: undefined, displaySettings };
  }

  const { content } = props;
  const allPublicationsInStack = getAllPublicationsInStackByIndex(
    dataPage?.page,
    path
  );

  const realContentPublication = content.accordionsList.map((acc) => {
    const pub = allPublicationsInStack?.find((p) => {
      return acc.boxesToShow
        .map((str) => parseFloat(str as unknown as string))
        .includes(p.boxId);
    });
    return {
      ...acc,
      boxesToShow: pub,
    };
  });

  const realContentBoxes = realContentPublication.map((acc) => ({
    ...acc,
    boxesToShow: acc.boxesToShow && dataPage?.boxes[acc.boxesToShow.boxId],
  }));

  return { content: realContentBoxes, displaySettings };
};
