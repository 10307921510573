import { useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as contact from "@ecp-redux/api/contact";
import { yupResolver } from "@hookform/resolvers/yup";
import htmlToReact from "../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import {
  isEmail,
  isPhone,
  isRequiredTextByLength,
} from "../../../helpers/validators";
import { useAddAlert } from "../../../redux/slices/alertsSlice";
import Alert from "../../../shared/components/Alert/Alert";
import { InputPhone, InputText } from "../../../shared/components/Input/Inputs";
import { Textarea } from "../../../shared/components/Textarea/Textarea";
import useScrollIntoElement from "../../../shared/hooks/useScrollIntoElement";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledContactForm } from "../BoxContactForm.styled";
import {
  IBoxContactFormMessages,
  IBoxContactFormSettings,
} from "../BoxContactForm.types";

export interface IContactForm {
  topic: string;
  firstName: string;
  lastName: string;
  emails: string;
  phone: string;
  message: string;
}

const contactDefaultValues: IContactForm = {
  topic: "",
  firstName: "",
  lastName: "",
  emails: "",
  phone: "",
  message: "",
};

const ContactForm: React.FC = () => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxContactFormMessages,
    IBoxContactFormSettings
  >();
  const contactFormRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = useScrollIntoElement(contactFormRef);

  const validationSchema = yup.object().shape({
    topic: isRequiredTextByLength(
      messages.contact_form_empty_verification_topic,
      messages.contact_form_short_input_length_exceeded,
      100
    ),
    firstName: isRequiredTextByLength(
      messages.contact_form_empty_verification_firstName,
      messages.contact_form_short_input_length_exceeded,
      100
    ),
    lastName: isRequiredTextByLength(
      messages.contact_form_empty_verification_lastName,
      messages.contact_form_short_input_length_exceeded,
      100
    ),
    emails: isEmail(
      messages.contact_form_empty_verification_email,
      messages.contact_form_syntax_verification_email
    ),
    phone: isPhone(messages.contact_form_verification_phone).required(
      messages.contact_form_empty_verification_phone
    ),
    message: isRequiredTextByLength(
      messages.contact_form_empty_verification_textArea,
      messages.contact_form_long_input_length_exceeded,
      500
    ),
  });

  const { addAlert } = useAddAlert();

  const [postContactForm] = contact.usePostContactFormMutation();

  const formMethods = useForm<IContactForm>({
    defaultValues: contactDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
  });
  const { handleSubmit, control, reset } = formMethods;
  const onSubmit = (data: IContactForm) => {
    postContactForm({ ...data, type: "TriggerContactFormMessage" })
      .unwrap()
      .then(() => {
        reset();
        addAlert({
          code: "210",
          message: messages.contact_form_success,
        });
        scrollIntoElement();
      })
      .catch(() => {
        addAlert({
          code: "410",
          message: messages.contact_form_failure,
        });
        scrollIntoElement();
      });

    // scrollIntoElement();
  };

  return (
    <StyledContactForm
      buttonWidth={settings.contact_form_submit_button_width}
      inputStyleId={settings.contact_form_input_style}
      formElementsGap={settings.contact_form_elements_gap}
      titleSpacing={settings.contact_form_title_spacing}
      termsSpacing={settings.contact_form_terms_spacing}
      backgroundColor={settings.contact_form_background_color}
      submitButtonBottomSpacing={
        settings.contact_form_submit_button_bottom_spacing
      }
      className="contact-form-container"
      ref={contactFormRef}
    >
      <>
        <StyledText
          className="contact-form-container__title"
          $settings={{
            font: settings.contact_form_title_typo,
            text: { color: settings.contact_form_title_color },
          }}
          alignment={settings.contact_form_title_align?.toLowerCase()}
          show={isNotEmptyMessage(messages.contact_form_title)}
        >
          {messages.contact_form_title}
        </StyledText>

        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit((d) => onSubmit(d))}
            autoComplete="off"
            className="contact-form"
          >
            <Alert
              alertsCodes={["210"]}
              backgroundColor={settings.contact_form_background_success_color}
              textColor={settings.contact_form_success_info_text_color}
              typography={settings.contact_form_success_info_typo}
              closeable={true}
              lifetime={5000}
            />
            <Alert
              alertsCodes={["410"]}
              backgroundColor={settings.contact_form_background_error_color}
              textColor={settings.contact_form_error_info_text_color}
              typography={settings.contact_form_error_info_typo}
              closeable={true}
              lifetime={5000}
            />
            <InputText
              className="contact-form__topic"
              name="topic"
              control={control}
              placeholder={messages.contact_form_placeholder_topic}
              label={messages.contact_form_heading_topic}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="contact-form__first-name"
              name="firstName"
              control={control}
              placeholder={messages.contact_form_placeholder_name}
              label={messages.contact_form_heading_name}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="contact-form__last-name"
              name="lastName"
              control={control}
              placeholder={messages.contact_form_placeholder_surname}
              label={messages.contact_form_heading_surname}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="contact-form__email"
              name="emails"
              control={control}
              placeholder={messages.contact_form_placeholder_email}
              label={messages.contact_form_heading_email}
              $settings={settings.contact_form_input_style}
            />
            <InputPhone
              className="contact-form__phone"
              name="phone"
              control={control}
              placeholder={messages.contact_form_placeholder_phone}
              label={messages.contact_form_heading_phone}
              $settings={settings.contact_form_input_style}
            />
            <Textarea
              className="contact-form__message"
              name="message"
              control={control}
              placeholder={messages.contact_form_placeholder_textArea}
              label={messages.contact_form_heading_textArea}
              $settings={settings.contact_form_textArea_style}
            />

            <StyledText
              className="contact-form__terms terms"
              $settings={{
                font: settings.contact_form_information_typo,
                text: { color: settings.contact_form_information_text_color },
              }}
              show={isNotEmptyMessage(messages.contact_form_information)}
            >
              {htmlToReact(
                messages.contact_form_information,
                settings.contact_form_link_style,
                settings.contact_form_link_basic_color
              )}
            </StyledText>
            <StyledButton
              className="contact-form__save-button"
              renderAs="button"
              type="submit"
              $settings={settings.contact_form_button_style}
              show={isNotEmptyMessage(messages.contact_form_save_button)}
            >
              {messages.contact_form_save_button}
            </StyledButton>
          </form>
        </FormProvider>
      </>
    </StyledContactForm>
  );
};

export default ContactForm;
