/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useRef } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
  useFormContext,
} from "react-hook-form";
import {
  TColorId,
  TLinkId,
  TSizeCSS,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import htmlToReactParser from "../../../helpers/HtmlToReactParser";
import {
  asJSON,
  checkIfMoreThanOneOtherCheckbox,
} from "../../../helpers/helpers";
import StyledText from "../../styleElements/StyledText/StyledText";
import {
  CheckboxButton,
  CheckboxButtonLabel,
  Item,
  StyledCheckboxList,
} from "./CheckboxesList.styled";

interface ICheckboxTextStyles {
  labelTypo: TTypoId;
  labelColor: TColorId;
  errorTypo: TTypoId;
  errorColor: TColorId;
  linkTypo?: TLinkId;
}

export interface ICheckboxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  label: JSX.Element | string;
  textStyles: ICheckboxTextStyles;
  disabled?: boolean;
}
interface ICheckboxesListProps {
  checkboxes: Omit<ICheckboxProps<FieldValues>, "register" | "textStyles">[];
  checkAllText?: JSX.Element | string;
  checkboxesGap: TSizeCSS;
  textStyles: ICheckboxTextStyles;
}

export const Checkbox = <T extends FieldValues>(
  props: ICheckboxProps<T>
): JSX.Element => {
  const { label, textStyles, disabled } = props;
  const { field, fieldState } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <Item disabled={disabled} className="checkbox-list__element">
      <div className="checkbox-list__element__checkbox">
        <CheckboxButton
          type="checkbox"
          {...field}
          checked={field.value}
          disabled={disabled}
          className="checkbox-list__element__button"
        />
        <CheckboxButtonLabel className="checkbox-list__element__button-label" />
        <StyledText
          $settings={{
            font: textStyles.labelTypo,
            text: { color: textStyles.labelColor },
          }}
          className="checkbox-list__element__text"
        >
          {typeof label === "string" && textStyles.linkTypo
            ? htmlToReactParser(label, textStyles.linkTypo)
            : label}
        </StyledText>
      </div>
      {fieldState.error !== undefined && (
        <StyledText
          $settings={{
            font: textStyles.errorTypo,
            text: { color: textStyles.errorColor },
          }}
          role="alert"
          className="checkbox-list__element__validation"
        >
          {fieldState.error.message}
        </StyledText>
      )}
    </Item>
  );
};

export const CheckboxesList: React.FC<ICheckboxesListProps> = ({
  checkboxes,
  checkAllText,
  checkboxesGap,
  textStyles,
}: ICheckboxesListProps): JSX.Element => {
  const { control, setValue, watch } = useFormContext();
  const watchCheckboxesList = watch(checkboxes.map((checkb) => checkb.name));
  const checkAllElement = useRef<HTMLInputElement>(null);
  const checkAllToBeShown =
    checkAllText && checkIfMoreThanOneOtherCheckbox(checkboxes);

  useEffect(() => {
    if (checkAllElement.current) {
      if (watchCheckboxesList.every((a) => a === true)) {
        checkAllElement.current.checked = true;
        checkAllElement.current.indeterminate = false;
      } else if (watchCheckboxesList.every((a) => a === false)) {
        checkAllElement.current.checked = false;
        checkAllElement.current.indeterminate = false;
      } else {
        checkAllElement.current.checked = false;
        checkAllElement.current.indeterminate = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asJSON(watchCheckboxesList)]);

  const checkAll = () => {
    if (checkAllElement?.current?.checked === false) {
      checkboxes.forEach((checkbox) =>
        setValue(checkbox.name, false, { shouldDirty: true, shouldTouch: true })
      );
      checkAllElement.current.checked = false;
    }

    if (checkAllElement?.current?.checked === true) {
      checkboxes.forEach((checkbox) =>
        setValue(checkbox.name, true, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        })
      );
      checkAllElement.current.checked = true;
    }
  };

  return (
    <StyledCheckboxList gap={checkboxesGap} className="checkbox-list">
      {checkAllToBeShown && (
        <Item
          className="checkbox-list__element"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            alignItems: "center",
          }}
        >
          {checkAllText !== null && (
            <>
              <CheckboxButton
                type="checkbox"
                onClick={checkAll}
                ref={checkAllElement}
                data-testid="checkAllForm"
                className="checkbox-list__element__button"
              />
              <CheckboxButtonLabel className="checkbox-list__element__button-label" />
              <StyledText
                $settings={{
                  font: textStyles.labelTypo,
                  text: { color: textStyles.labelColor },
                }}
                className="checkbox-list__element__text"
              >
                {checkAllText}
              </StyledText>
            </>
          )}
        </Item>
      )}

      {checkboxes.map(
        (checkbox) =>
          checkbox.label && (
            <Checkbox
              control={control}
              name={checkbox.name}
              label={checkbox.label}
              textStyles={textStyles}
              key={checkbox.name}
            />
          )
      )}
    </StyledCheckboxList>
  );
};
