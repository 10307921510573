import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../../../../settingsPatterns/composeCss";

export const StyledAddToCartWrapper = styled.div<{
  $alignment: AlignmentHorizontalOption;
}>(({ $alignment }) => {
  return `
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: ${alignmentOptionsToValue($alignment)};  
    `;
});
