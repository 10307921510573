import React, { useState } from "react";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  AddressType,
  TAddresses,
  isPostalBillingAddress,
} from "@ecp-redux/dto/cartAddresses.type";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { Radio } from "../../../shared/components/Radio/Radio";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { ReactComponent as PenIcon } from "../../../shared/icons/pen.svg";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import SelectStoreFromMapPopup from "../../BoxMyAccountMyAdressList/elements/SelectStoreFromMapPopup";
import { StyledChangeAddress } from "../BoxCartStepTwo/BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../BoxCartStepTwo/BoxCartStepTwo.types";
import AddressInfo from "../BoxCartStepTwo/potentialBoxes/DeliveryChannels/elements/SelectedAddress/elements/AddressInfo";
import BillingPostalAdressForm from "./BillingPostalAdressForm";
import InpostAddressMap from "./InpostAddressMap";

interface ChangeAddressProps {
  selectedAddress: TAddresses | null;
  addresses: TAddresses[];
  closeEditAddress: () => void;
  addressType: AddressType;
}

const ChangeAddress: React.FC<ChangeAddressProps> = ({
  selectedAddress,
  addresses,
  addressType,
  closeEditAddress,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();
  const hasOnlySelectedAddress =
    addresses.length === 0 && selectedAddress != null; //Probably it is UNREGISTERED user

  const [showAddressForm, setShowAddressForm] = useState<
    "NEW" | "NONE" | "EDIT"
  >(hasOnlySelectedAddress ? "EDIT" : "NONE");

  const [editAdderss, setEditAddres] = useState<null | TAddresses>(
    hasOnlySelectedAddress ? selectedAddress : null
  );

  const [changeSelectedAddress] =
    cartAPI.usePostChangeCartSelectedAddressMutation();

  const [addStoreAddress] =
    cartAPI.usePostCartAddressByPortalUserTokenMutation();

  const closeForm = () => {
    if (hasOnlySelectedAddress) {
      closeEditAddress();
    } else {
      setShowAddressForm("NONE");
    }
  };

  const selectStoreAddress = async (code: string) => {
    const { addressId } = await addStoreAddress({
      code,
      type: AddressType.STORE,
    }).unwrap();
    await changeSelectedAddress({ addressId });
    closeForm();
  };

  const isMobile = useIsMobilePortal();

  return (
    <StyledChangeAddress
      data-testid="ChangeBillingPostalAddressComponent"
      className="change-address"
    >
      {((showAddressForm === "NEW" && addressType === AddressType.STORE) ||
        showAddressForm === "NONE") && (
        <div className="change-address__address-container">
          {addresses.map((address) => (
            <div
              className="change-address__address-container__content"
              key={address.id}
            >
              <Radio
                className="change-address__address-container__content__radio"
                checked={address.id === selectedAddress?.id}
                onClick={() =>
                  changeSelectedAddress({ addressId: address.id }).then(
                    closeEditAddress
                  )
                }
              >
                <div className="change-address__address-container__content__radio__address">
                  {addressType !== AddressType.BILLING &&
                    addressType !== AddressType.STORE && (
                      <StyledText
                        $settings={{
                          font: settings.basket_step_2_typography_paragraph_3,
                          text: {
                            color: settings.basket_step_all_color_main,
                          },
                        }}
                        show={
                          isPostalBillingAddress(selectedAddress)
                            ? isNotEmptyMessage(messages.delivery_address)
                            : isNotEmptyMessage(messages.delivery_point_address)
                        }
                      >
                        {isPostalBillingAddress(selectedAddress)
                          ? messages.delivery_address
                          : messages.delivery_point_address}
                      </StyledText>
                    )}
                  <AddressInfo address={address} showAddressDefaultLabel />
                </div>
              </Radio>

              {addressType !== AddressType.STORE && (
                <button
                  className="change-address__address-container__main__button"
                  onClick={() => {
                    setShowAddressForm("EDIT");
                    setEditAddres(address);
                  }}
                  data-testid="editDeliveryAddress"
                >
                  <PenIcon className="change-address__address-container__main__button__icon" />
                </button>
              )}
            </div>
          ))}

          <div className="change-address__address-container__buttons-container">
            <StyledButton
              $settings={
                addressType === AddressType.STORE
                  ? settings.basket_step_choose_shop_button
                  : settings.basket_step_all_second_button
              }
              renderAs="button"
              onClick={() => setShowAddressForm("NEW")}
              data-testid="addNewAddressButton"
              show={
                addressType === AddressType.STORE
                  ? isNotEmptyMessage(messages.basket_step_choose_shop_button)
                  : isNotEmptyMessage(messages.delivery_add_new_address)
              }
            >
              {addressType === AddressType.STORE
                ? messages.basket_step_choose_shop_button
                : messages.delivery_add_new_address}
            </StyledButton>
            {selectedAddress?.id != null && !isMobile && (
              <StyledButton
                $settings={settings.basket_step_all_back_button}
                renderAs="button"
                onClick={() => closeEditAddress()}
                show={messages.delivery_back_button}
              >
                {messages.delivery_back_button}
              </StyledButton>
            )}
          </div>
        </div>
      )}
      {showAddressForm !== "NONE" && (
        <div>
          {(addressType === AddressType.BILLING ||
            addressType === AddressType.POSTAL) && (
            <BillingPostalAdressForm
              disableForm={closeForm}
              addressType={addressType}
              editData={
                showAddressForm === "EDIT" &&
                isPostalBillingAddress(editAdderss)
                  ? editAdderss
                  : null
              }
              isDefaultAddress={addresses.length === 0}
            />
          )}
          {addressType === AddressType.PACKSTATION && (
            <InpostAddressMap disableForm={() => setShowAddressForm("NONE")} />
          )}
          {addressType === AddressType.STORE && (
            <SelectStoreFromMapPopup
              onStoreSelect={selectStoreAddress}
              closePopup={closeForm}
            />
          )}
        </div>
      )}
    </StyledChangeAddress>
  );
};

export default ChangeAddress;
