import styled from "styled-components";
import composeCss from "../../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import { convertInputIdToValues } from "../../Input/StyledInput/StyledInput.methods";
import { ITextareaSettings } from "../Textarea";

export const StyledLine = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
`;

export const StyledTextareaLabel = styled.label<ITextareaSettings>(
  ({ $settings, theme }) => {
    const { font, text } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
  width: fit-content;
  color: ${convertColorIdToHex(text.labelColor, theme.colorPalette)};
  ${composeCss.font(font.label, theme.typography)};
`;
  }
);

export const StyledTextareaValidation = styled.span<ITextareaSettings>(
  ({ $settings, theme }) => {
    const { font, text } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
    width: fit-content;
    ${composeCss.font(font.error, theme.typography)};
    color: ${convertColorIdToHex(text.errorColor, theme.colorPalette)};
    `;
  }
);

export const StyledTextareaComponent = styled.div<ITextareaSettings>(
  ({ $settings, theme }) => {
    const { width } = convertInputIdToValues(
      $settings,
      theme.globalObjects?.inputs
    );
    return `
      display: flex;
      flex-direction: column;
      width: ${width}%;
    `;
  }
);

export const StyledTextareaIcon = styled.div`
  position: relative;
  > input {
    width: 100%;
  }
  .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export const StyledTextarea = styled.textarea<ITextareaSettings>(
  ({ $settings, theme, noValidate }) => {
    const inputStyles = convertInputIdToValues(
      $settings,
      theme?.globalObjects?.inputs
    );
    const {
      height,
      font,
      border,
      background,
      text,
      activeBorder,
      errorBorder,
      hoverBorder,
      disabledBorder,
    } = inputStyles;

    return `
  width: 100%;
  height: ${height}px;
  border-width: ${border.width.top}px 
    ${border.width.right}px 
    ${border.width.bottom}px 
    ${border.width.left}px;
  border-radius: ${border.radius}px;
  ${composeCss.font(font.input, theme.typography)};
  min-height: 30px;
  border-color: ${
    noValidate
      ? convertColorIdToHex(border.color, theme.colorPalette)
      : convertColorIdToHex(errorBorder.color, theme.colorPalette)
  };
  border-style: ${border.style};
  padding-left: 10px;
  background-color: ${convertColorIdToHex(
    background.color,
    theme.colorPalette
  )};
  padding-top: 6px;
  color: ${convertColorIdToHex(text.color, theme.colorPalette)};

  &::hover {
    background-color: ${convertColorIdToHex(
      background.hoverColor,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(text.hoverColor, theme.colorPalette)};
    border-color: ${convertColorIdToHex(hoverBorder.color, theme.colorPalette)};
  }

  &::active {
    background-color: ${convertColorIdToHex(
      background.activeColor,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(text.activeColor, theme.colorPalette)};
    border-color: ${convertColorIdToHex(
      activeBorder.color,
      theme.colorPalette
    )};
  }

  &::disabled {
    background-color: ${convertColorIdToHex(
      background.disabledColor,
      theme.colorPalette
    )};
    color: ${convertColorIdToHex(text.disabledColor, theme.colorPalette)};
    border-color: ${convertColorIdToHex(
      disabledBorder.color,
      theme.colorPalette
    )};
  }
  
  &::placeholder {
    ${composeCss.font(font.placeholder, theme.typography)};
  }
  `;
  }
);
