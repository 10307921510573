import { useMemo } from "react";
import { useRouter } from "next/router";
import { capitalizeFirstLetter } from "../../../helpers/helpers";
import { IBoxSearchResultsMessages } from "../BoxSearchResults.types";
import { SortDirection, SortOption, SortType } from "./Sort.types";

const findSortElementMessageKey = (
  sortOption: SortOption
): keyof IBoxSearchResultsMessages => {
  const { direction, type } = sortOption;
  if (type === SortType.DEFAULT)
    return (type + "SortText") as keyof IBoxSearchResultsMessages;
  return (direction +
    capitalizeFirstLetter(type) +
    "SortText") as keyof IBoxSearchResultsMessages;
};

const chooseSortTypeFromQuery = (sortQuery: string): SortType => {
  if (sortQuery.includes("name")) {
    return SortType.NAME;
  } else if (sortQuery.includes("price")) {
    return SortType.PRICE;
  } else return SortType.DEFAULT;
};

const chooseSortDirectionFromQuery = (sortQuery: string): SortDirection => {
  if (sortQuery.includes("name") || sortQuery.includes("price")) {
    return sortQuery.includes("-name") || sortQuery.includes("-price")
      ? SortDirection.DESC
      : SortDirection.ASC;
  }
  return SortDirection.NONE;
};

const sortOptions: SortOption[] = [
  { type: SortType.DEFAULT, direction: SortDirection.NONE },
  {
    type: SortType.PRICE,
    direction: SortDirection.ASC,
  },
  {
    type: SortType.PRICE,
    direction: SortDirection.DESC,
  },
  { type: SortType.NAME, direction: SortDirection.ASC },
  { type: SortType.NAME, direction: SortDirection.DESC },
];

const showOptions = [36, 72, 108];

export const useSearchResultsLogic = () => {
  const router = useRouter();

  const choosenSortQuery = useMemo((): SortOption => {
    if (typeof router.query["sort"] === "string") {
      return {
        type: chooseSortTypeFromQuery(router.query["sort"]),
        direction: chooseSortDirectionFromQuery(router.query["sort"]),
      };
    }
    return { type: SortType.DEFAULT, direction: SortDirection.NONE };
  }, [router.query]);

  return {
    findSortElementMessageKey,
    chooseSortTypeFromQuery,
    chooseSortDirectionFromQuery,
    choosenSortQuery,
    sortOptions,
    showOptions,
  };
};
