import React from "react";
import styled from "styled-components";
import {
  TAddresses,
  isPackstationAddress,
  isPickUpPointAddress,
  isPostalBillingAddress,
  isStoreAddress,
} from "@ecp-redux/dto/cartAddresses.type";
import { isNotEmptyMessage } from "../../../../../../../../helpers/isNotEmptyMessage";
import StyledText from "../../../../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../../../../BoxCartStepTwo.types";

interface ChangeAddressProps {
  address: TAddresses;
  showAddressDefaultLabel?: boolean;
}

const StyledAddressInfo = styled.div`
  overflow: hidden;

  .address-info {
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

const AddressInfo: React.FC<ChangeAddressProps> = ({
  address,
  showAddressDefaultLabel = false,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  return (
    <StyledAddressInfo>
      <StyledText
        $settings={{
          font: settings.basket_step_2_typography_paragraph_3,
          text: { color: settings.basket_step_all_color_main },
        }}
        className="address-info"
      >
        {isPackstationAddress(address) ||
          (isPickUpPointAddress(address) && (
            <p>{messages.delivery_point_address}</p>
          ))}
        {isStoreAddress(address) && <p>{address.storeName}</p>}
        {isPackstationAddress(address) && <p>{address.packstationCode}</p>}
        {isPostalBillingAddress(address) && (
          <p>{`${address.firstName} ${address.lastName}`}</p>
        )}
        {isPostalBillingAddress(address) && <p>{address.companyName} </p>}
        {isPostalBillingAddress(address) && <p>{address.nip}</p>}
        <p>{`${address.street} ${address.streetNumber ?? ""}${
          isPostalBillingAddress(address) &&
          address.flatNumber &&
          address.streetNumber
            ? `/${address.flatNumber}`
            : ""
        }`}</p>
        <p>{`${address.zipCode} ${address.city}`}</p>
        {isPostalBillingAddress(address) && <p>{address.country}</p>}

        <StyledText
          $settings={{
            font: settings.basket_step_choose_default_address_typography,
            text: {
              color: settings.basket_step_choose_default_address_color,
            },
          }}
          show={
            isNotEmptyMessage(messages.basket_step_default_address) &&
            showAddressDefaultLabel &&
            address.defaultAddress
          }
        >
          {messages.basket_step_default_address}
        </StyledText>
      </StyledText>
    </StyledAddressInfo>
  );
};

export default AddressInfo;
