import {
  MutableRefObject,
  createRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { AttributeValueViewModel } from "./BoxSearchFilters.types";

export const useSetFacetListHeight = (
  facetValuesSearchResult: AttributeValueViewModel[],
  limit: number
) => {
  // total height of facet elements which are visible (limit)
  const [totalHeight, setTotalHeight] = useState(0);
  const myRefs = useRef<MutableRefObject<HTMLLIElement>[]>([]);
  // every facet element has its own ref
  myRefs.current = facetValuesSearchResult.map(
    (value, index) => myRefs.current[index] ?? createRef()
  );

  useEffect(() => {
    let newTotalHeight = 0;

    myRefs.current.forEach((ref, index) => {
      if (ref.current && index < limit) {
        newTotalHeight += ref.current.offsetHeight;
      }
    });
    setTotalHeight(newTotalHeight);
  }, [myRefs.current]);

  return { myRefs, totalHeight };
};
