import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxCartStepOneMessagesScheme = z.object({
  cart_header: themeScheme.MessageScheme.default("Koszyk"),
  cart_art: themeScheme.MessageScheme.default("art."),
  cart_description: themeScheme.MessageScheme.default(
    "Nie zwlekaj z zakupem, dodanie artykułów do koszyka nie oznacza ich rezerwacji"
  ),
  cart_delivery_time: themeScheme.MessageScheme.default("Czas dostawy: "),
  summary_header: themeScheme.MessageScheme.default("Podsumowanie"),
  summary_products_price: themeScheme.MessageScheme.default("Cena produktów"),
  summary_save_ammont: themeScheme.MessageScheme.default("Oszczędzasz"),
  summary_delivery_costs_before_select:
    themeScheme.MessageScheme.default("Dostawa od"),
  summary_delivery_costs_after_select:
    themeScheme.MessageScheme.default("Dostawa"),
  summary_amount_to_pay:
    themeScheme.MessageScheme.default("Łączna kwota z vat"),
  summary_button_next_step: themeScheme.MessageScheme.default(
    "Przejdź do finalizacji zamówienia"
  ),
  empty_cart: themeScheme.MessageScheme.default("Twój koszyk jest pusty"),
  cart_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Niektóre produkty dodane do koszyka są obecnie niedostępne"
  ),
  cart_error_product_partially_unavailable: themeScheme.MessageScheme.default(
    "Ilość sztuk produktów dodanych do koszyka uległa zmianie"
  ),
  product_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono dozwolony limit produktów"
  ),
  basket_unavailable_delivery_channels_alert: themeScheme.MessageScheme.default(
    "Na ten moment nie możesz złożyć zamówienia"
  ),
  cart_error_product_price_alert_changed: themeScheme.MessageScheme.default(
    "Cena produktu uległa zmianie"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  product_not_available_error: themeScheme.MessageScheme.default(
    "Produkt niedostępny"
  ),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),
  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),
  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
});

export const IBoxCartStepOneSettingsScheme = z.object({
  basket_step_all_summary_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  basket_step_all_typography_error_success:
    themeScheme.TypoIdScheme.default("typo$$8").catch("typo$$8"),
  basket_step_0_typography_header:
    themeScheme.TypoIdScheme.default("typo$$2").catch("typo$$2"),
  basket_step_0_typography_header_2:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  basket_step_all_typography_paragraph_2:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  basket_step_all_typography_paragraph_1:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  basket_step_all_typography_old_price:
    themeScheme.TypoIdScheme.default("typo$$9").catch("typo$$9"),
  basket_step_all_color_second:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  basket_step_all_color_main:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_step_all_background_color_error:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  basket_step_all_background_color_success:
    themeScheme.ColorIdScheme.default("color$$13").catch("color$$13"),
  basket_step_all_background_color:
    themeScheme.ColorIdScheme.default("color$$8").catch("color$$8"),
  basket_step_all_summary_background_color:
    themeScheme.ColorIdScheme.default("color$$8").catch("color$$8"),
  basket_step_0_input_product_amount:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  basket_step_2_3_button_summary:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  basket_step_0_next_step_url: themeScheme.UrlScheme.default(
    "/dostawa-i-platnosc"
  ).catch("/dostawa-i-platnosc"),
  basket_step_0_login_url:
    themeScheme.UrlScheme.default("/login").catch("/login"),
  basket_unavialable_text_color:
    themeScheme.ColorIdScheme.default("color$$6").catch("color$$6"),
  basket_alert_background:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  basket_alert_typo_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  basket_alert_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  basket_step_0_image_size:
    themeScheme.SizeCSSScheme.default("50%").catch("50%"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  basket_step_0_show_delivery_time:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  basket_step_all_amount_to_pay_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  wishlist_icon_visible:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  basket_step_one_empty_cart_url_redirect:
    themeScheme.UrlScheme.default("").catch(""),
});
