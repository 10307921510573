import { useCallback, useMemo } from "react";
import ChoosePackageTypeB2B from "@ecp-boxes/shared/components/domain/CartB2B/ChoosePackageTypeB2B/ChoosePackageTypeB2B";
import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import { GlobalTableCellReadoutValue } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import { TDropdownReadoutValue } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledTableCell } from "../../../shared/components/Table/TableCell";
import { findCartLineBySku } from "../../../shared/components/domain/CartB2B/cartB2B.methods";
import { useCartB2BContext } from "../../../shared/components/domain/CartB2B/useCartB2BContext";

interface ITableDataPackageTypeCell {
  tableCellStyleId: GlobalTableCellReadoutValue;
  isOdd: boolean;
  dropdownStyle: TDropdownReadoutValue;
  product: ISrpTransformResponseV2;
  setUnitOfMeasure: (unitOfMeasure: string) => void;
  unitOfMeasure: string;
}

export const TableDataPackageTypeCell: React.FC<ITableDataPackageTypeCell> = ({
  tableCellStyleId,
  dropdownStyle,
  isOdd,
  product,
  setUnitOfMeasure,
  unitOfMeasure,
}) => {
  const { addToCart, cartData } = useCartB2BContext();
  const { quantity, unitOfMeasure: cartUnitOfMeasure } = useMemo(
    () =>
      findCartLineBySku(cartData, {
        sku: product.sku,
        deliveryQuantity: product.deliveryQuantity,
        unitOfMeasure: {
          currentUnitOfMeasure: unitOfMeasure,
          availableUnitsOfMeasure: [
            ...product.alternativeUnitOfMeasures.map((e) => ({
              unitOfMeasure: e.auoM,
              unitOfMeasureLabel: e.auoMLabel,
              unitOfMeasureQuantity: e.value,
            })),
            {
              unitOfMeasure: product.mainUnitOfMeasurement,
              unitOfMeasureLabel: product.mainUnitOfMeasurementLabel,
              unitOfMeasureQuantity: 1,
            },
          ],
        },
      }),
    [cartData, product, unitOfMeasure]
  );

  const onChangingUnitOfMeasure = useCallback(
    (unitOfMeasure: string) => {
      if (quantity > 0) {
        addToCart({
          sku: product.sku,
          quantity: quantity,
          unitOfMeasure: unitOfMeasure,
          isCart: false,
        });
      } else {
        setUnitOfMeasure(unitOfMeasure);
      }
    },
    [addToCart, quantity, product.sku, setUnitOfMeasure]
  );

  return (
    <StyledTableCell tableCellStyleId={tableCellStyleId} isOdd={isOdd}>
      <ChoosePackageTypeB2B
        dropdownStyle={dropdownStyle}
        onChange={onChangingUnitOfMeasure}
        product={{
          currentUnitOfMeasure: cartUnitOfMeasure.currentUnitOfMeasure,
          availableUnitsOfMeasure: cartUnitOfMeasure.availableUnitsOfMeasure,
          mainUnitOfMeasure: cartUnitOfMeasure.baseUnitOfMeasure,
        }}
      />
    </StyledTableCell>
  );
};

export default TableDataPackageTypeCell;
