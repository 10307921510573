interface TableRowCellProps {
  column: {
    name: string;
    key: string;
    width: number;
  };
  cellComponent: JSX.Element;
  tableSettings: {
    table: {
      fixedTableSize: boolean;
    };
  };
}

const TableRowCell: React.FC<TableRowCellProps> = ({
  tableSettings,
  column,
  cellComponent,
}) => {
  return (
    <td
      id="cell"
      className="table__rows__row__cell"
      style={
        !tableSettings.table.fixedTableSize
          ? {
              width: `${column.width}px`,
              maxWidth: `${column.width}px`,
            }
          : {}
      }
    >
      <div className="table__rows__row__cell__content">{cellComponent}</div>
    </td>
  );
};

export default TableRowCell;
