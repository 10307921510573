import classNames from "classnames";
import styled, { css } from "styled-components";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import {
  IThemeState,
  TColorId,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import { convertColorIdToHex } from "../../../../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { useCartB2BContext } from "../useCartB2BContext";
import ProductLine from "./ProductLineB2B";

const HeaderProductList: React.FC = (props) => {
  const { settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  return (
    <StyledText
      className="cart-line-b2b_header"
      $settings={{
        font: settings.basket_data_typo2,
        text: { color: settings.basket_data_color2 },
      }}
    >
      {props.children}
    </StyledText>
  );
};

interface IStyledOrderProductList {
  gridTemplate?: string;
  lineSize: TSizeCSS;
  lineColor: TColorId;
  theme: IThemeState;
}

export const StyledOrderProductList = styled.div(
  ({ gridTemplate, lineSize, lineColor, theme }: IStyledOrderProductList) => {
    const isMobile = useIsMobilePortal();

    return css`
      display: grid;
      ${isMobile ? "" : `grid-template-columns: ${gridTemplate};`};
      gap: 5px 10px;
      span {
        padding-bottom: 5px;
      }

      .line {
        grid-column: 1/-1;
        color: ${convertColorIdToHex(lineColor, theme.colorPalette)};
        border-top-width: ${lineSize};
      }

      .cartB2B__product-line-item {
        display: flex;
        position: relative;
        padding-bottom: 5px !important;
        align-items: center;

        &.disabled {
          > :not(button) {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
      ${isMobile
        ? css`
            display: flex;
            flex-direction: column;

            .cartB2B__summary-mobile-container {
              &:not(:last-child) {
                border-bottom: ${lineSize} solid #cccccc;
                padding-bottom: 6px;
              }

              &__product-line-column {
                display: flex;
                justify-content: space-between;
                height: 32px;
                align-items: center;
                border-bottom: ${lineSize} solid #eeeeee;
                padding: 20px 0px;

                &__image {
                  display: grid;
                  grid-template-columns: auto 1fr;
                  grid-gap: 10px;
                  align-items: center;

                  img {
                    height: 60px;
                  }
                }

                &__netto {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 32px;
                  padding: 20px 0px;
                }
              }
            }

            .cartB2B__product-line-item {
              padding-bottom: 0px !important;

              &__spacekeeper {
                min-height: 0;
              }
            }
          `
        : ""}
    `;
  }
);

const OrderProductList: React.FC<{ order: IOrderB2B }> = ({ order }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const { grossPrice, cartData } = useCartB2BContext();

  const {
    basket_single_order_table_sku_label,
    basket_single_order_table_image_label,
    basket_single_order_table_product_name_label,
    basket_single_order_table_unit_label,
    basket_single_order_table_product_added_label,
    basket_single_order_table_netto_price_label,
    basket_single_order_table_netto_value_label,
    basket_single_order_table_gross_price_label,
    basket_single_order_table_gross_value_label,
  } = messages;

  return (
    <StyledOrderProductList
      gridTemplate="0.6fr 92px 1fr minmax(0, 1fr) minmax(0, 1fr) 0.7fr 1fr"
      lineSize={settings.basket_header_line_size}
      lineColor={settings.basket_header_line_color}
    >
      <HeaderProductList>
        {basket_single_order_table_sku_label}
      </HeaderProductList>
      <HeaderProductList>
        {basket_single_order_table_image_label}
      </HeaderProductList>
      <HeaderProductList>
        {basket_single_order_table_product_name_label}
      </HeaderProductList>
      <HeaderProductList>
        {basket_single_order_table_unit_label}
      </HeaderProductList>
      <HeaderProductList>
        {basket_single_order_table_product_added_label}
      </HeaderProductList>
      <HeaderProductList>
        {grossPrice === true
          ? basket_single_order_table_gross_price_label
          : basket_single_order_table_netto_price_label}
      </HeaderProductList>
      <HeaderProductList>
        {grossPrice === true
          ? basket_single_order_table_gross_value_label
          : basket_single_order_table_netto_value_label}
      </HeaderProductList>
      {order.cartLines.map((cartLine, idx, arr) => {
        return (
          <>
            <ProductLine
              key={cartLine.name}
              className={classNames(
                cartData?.unavailableProducts?.find(
                  (e) => e === cartLine.sku
                ) && "disabled"
              )}
              cartLine={cartLine}
              productType={order.productType}
            />
            {idx < arr.length - 1 && (
              <hr key={cartLine.name + arr.length} className="line" />
            )}
          </>
        );
      })}
    </StyledOrderProductList>
  );
};

export default OrderProductList;
