import { isClientSide } from "@ecp-redux/api/api";

const useSetSessionStorage = () => {
  const setSessionStorageItem = (key: string, value: string) => {
    if (isClientSide()) {
      sessionStorage.setItem(key, value);
    }
  };
  const getSessionStorageItem = (key: string) => {
    if (isClientSide()) {
      const storedItem = sessionStorage.getItem(key);
      return storedItem ? storedItem : null;
    }
    return null;
  };

  const removeSessionStorageItem = (key: string) => {
    if (isClientSide()) {
      return sessionStorage.removeItem(key);
    }
  };

  return {
    setSessionStorageItem,
    getSessionStorageItem,
    removeSessionStorageItem,
  };
};
export default useSetSessionStorage;
