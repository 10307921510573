import {
  AlignmentHorizontalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  IGlobalAccordionObject,
  OutlineOptions,
  SettingPositionOptions,
  TAccordionId,
  TAccordionReadoutValue,
  TAccordionSet,
  TColorReadoutValue,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const colorFallback: TColorReadoutValue = {
  id: "color$$1",
  custom: "#000000",
};

export const typoFallback: TTypoReadoutValue = {
  id: "typo$$1",
  custom: {
    family: FontFamilyOptions.ROBOTO,
    weight: FontWeightOptionsLongListOfWeights.Weight300,
    size: 24,
    lineHeight: 10,
    letterSpacing: 1,
  },
};

const borderFallback = {
  radius: 0,
  width: { left: 10, right: 10, top: 10, bottom: 10 },
  style: OutlineOptions.SOLID,
  color: colorFallback,
};

const accordionFallback: IGlobalAccordionObject = {
  accordionAlignment: AlignmentHorizontalOption.CENTER,
  accordionPadding: { top: 0, bottom: 0, right: 0, left: 0 },
  accordionWidth: 100,
  accordionTypography: typoFallback,
  accordionBackground: {
    color: colorFallback,
    hoverColor: colorFallback,
    activeColor: colorFallback,
    disabledColor: colorFallback,
  },
  accordionText: {
    color: colorFallback,
    hoverColor: colorFallback,
    activeColor: colorFallback,
    disabledColor: colorFallback,
  },
  accordionBorder: borderFallback,
  accordionHoverBorder: borderFallback,
  accordionActiveBorder: borderFallback,
  accordionDisabledBorder: borderFallback,
  openIcon: {
    size: 10,
    iconUrl: null,
    position: SettingPositionOptions.LEFT,
    spacing: 10,
  },
  closeIcon: {
    size: 10,
    spacing: 10,
    iconUrl: null,
    position: SettingPositionOptions.LEFT,
  },
};

const isAccordionId = (
  value: TAccordionReadoutValue | TAccordionId
): value is TAccordionId => {
  if (typeof value === "string") {
    return value.startsWith("accordion$$");
  }
  return false;
};

export const convertAccordionIdToValues = (
  value: TAccordionReadoutValue | TAccordionId,
  globalAccordions: TAccordionSet
): IGlobalAccordionObject => {
  if (value === undefined) return accordionFallback;
  if (isAccordionId(value)) {
    return (
      globalAccordions.find((key) => key.id === value) ??
      globalAccordions[0] ??
      accordionFallback
    );
  } else if (!isAccordionId(value) && value.id !== "custom") {
    return (
      globalAccordions.find((key) => key.id === value.id) ??
      globalAccordions?.[0] ??
      accordionFallback
    );
  } else if (!isAccordionId(value) && value.id === "custom") {
    return value.custom;
  }

  return accordionFallback;
};
