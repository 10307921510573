import styled from "styled-components";

interface IVideoLightCoverProps {
  coverImage: string;
  alt?: string;
}

const StyledVideoLightCover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoLightCover = ({
  coverImage,
  alt = "",
}: IVideoLightCoverProps) => (
  <StyledVideoLightCover src={coverImage} alt={alt} />
);
