import { isArray } from "lodash";
import {
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { StyledColumnInfoText } from "../BoxMyAccountMyOrdersDetails.styled";

interface ColumnInfoTextProps {
  fontLabelTypo: TTypoId;
  textLabelColor: TColorId;
  messageLabel: string;
  fontValueTypo: TTypoId;
  textValueColor: TColorId;
  value: string | number | string[] | JSX.Element;
}

const ColumnInfoText: React.FC<ColumnInfoTextProps> = ({
  fontLabelTypo,
  textLabelColor,
  messageLabel,
  fontValueTypo,
  textValueColor,
  value,
}) => {
  return (
    <StyledColumnInfoText>
      <div className="column-info-text__message-label">
        <StyledText
          $settings={{
            font: fontLabelTypo,
            text: {
              color: textLabelColor,
            },
          }}
          show={isNotEmptyMessage(messageLabel)}
        >
          {`${messageLabel}:`}
        </StyledText>
      </div>
      <div className="column-info-text__value">
        <StyledText
          $settings={{
            font: fontValueTypo,
            text: {
              color: textValueColor,
            },
          }}
          show={isNotEmptyMessage(messageLabel)}
        >
          {isArray(value) ? (
            <div>
              {value.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          ) : (
            value
          )}
        </StyledText>
      </div>
    </StyledColumnInfoText>
  );
};

export default ColumnInfoText;
