import { useState } from "react";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { ReactComponent as PinIcon } from "../../../shared/icons/pin.svg";
import { ReactComponent as TableIcon } from "../../../shared/icons/table.svg";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { StyledStoresDisplayDesktop } from "../BoxStoreLocator.styled";
import StoreLocatorMap from "../StoreLocatorMap/StoreLocatorMap";
import { IStoresDisplayMobileProps } from "./StoresDisplayMobile";
import StoresSearchBar from "./StoresSearchBar";
import TableView from "./TableView";

const StoresDisplayDesktop: React.FC<IStoresDisplayMobileProps> = ({
  messages,
  settings,
  fetchStores,
  data,
  onSelectAddressButtonClick,
  refreshMapView,
  isEmptySearchValue,
}: IStoresDisplayMobileProps) => {
  const [tableView, setTableView] = useState(false);

  return (
    <StyledStoresDisplayDesktop className="stores-display-desktop">
      <div className="stores-display-desktop__top">
        <StoresSearchBar onClickButton={fetchStores} />
        <div className="stores-display-desktop__top__view-label">
          <StyledText
            className="stores-display-desktop__top__view-label__text"
            $settings={{
              font: settings.storelocator_view_typography,
              text: { color: settings.storelocator_view_color },
            }}
            show={isNotEmptyMessage(messages.storelocator_view_label)}
          >
            {messages.storelocator_view_label}
          </StyledText>

          <button onClick={() => setTableView(false)}>
            <PinIcon />
          </button>
          <button onClick={() => setTableView(true)}>
            <TableIcon />
          </button>
        </div>
      </div>
      {data && data.result.length > 0 ? (
        <div className="stores-display-desktop__choose-view">
          {tableView ? (
            <TableView
              stores={data.result}
              onSelectAddressButtonClick={onSelectAddressButtonClick}
              imagesVisible
            />
          ) : (
            <StoreLocatorMap
              stores={data.result}
              onSelectAddressButtonClick={onSelectAddressButtonClick}
              refreshMapView={refreshMapView}
              isEmptySearchValue={isEmptySearchValue}
            />
          )}
        </div>
      ) : (
        <div className="stores-display-desktop__not-found">
          <StyledText
            className="stores-display-desktop__not-found__label"
            $settings={{
              font: settings.storelocator_not_found_typography,
              text: { color: settings.storelocator_not_found_color },
            }}
            show={isNotEmptyMessage(messages.storelocator_not_found)}
          >
            {messages.storelocator_not_found}
          </StyledText>
        </div>
      )}
    </StyledStoresDisplayDesktop>
  );
};

export default StoresDisplayDesktop;
