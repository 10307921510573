import { API, api } from "./api";

export type TCreditLimitsSingleResultPayload = {
  projection: string[];
};

export type ICartLimitResponse = {
  result: Record<string, string>;
};

export const creditLimits = api.injectEndpoints({
  endpoints: (builder) => ({
    getCreditLimitsFieldNames: builder.query<{ columnNames: string[] }, void>({
      query: () => {
        return API.getCreditLimitsFieldNames.endpoint();
      },
      providesTags: ["CONTRACTORS"],
    }),
    postCreditLimitsSingleResult: builder.query<
      ICartLimitResponse,
      TCreditLimitsSingleResultPayload
    >({
      query: (body) => {
        return {
          url: API.postCreditLimitsSingleResult.endpoint(),
          method: "POST",
          body: { projection: [...body.projection, "currency"] },
        };
      },
      providesTags: ["CONTRACTORS"],
    }),
  }),
});

export const {
  useLazyGetCreditLimitsFieldNamesQuery,
  usePostCreditLimitsSingleResultQuery,
} = creditLimits;

export const { getCreditLimitsFieldNames, postCreditLimitsSingleResult } =
  creditLimits.endpoints;
