import React from "react";
import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import {
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IPortalSwitchStyleSettings,
  StyledSwitcher,
} from "./PortalSwitch.styled";

interface IPortalSwitchProps {
  checked: boolean;
  handleChange: (v: boolean) => void;
  styleSettings: IPortalSwitchStyleSettings;
  leftLabel?: { text: string; color: TColorId; typo: TTypoId };
  rightLabel?: { text: string; color: TColorId; typo: TTypoId };
  disabled?: boolean;
}

const PortalSwitch: React.FC<IPortalSwitchProps> = ({
  checked,
  handleChange,
  disabled,
  styleSettings,
  leftLabel,
  rightLabel,
}) => {
  return (
    <StyledSwitcher
      className="switcher"
      styleSettings={styleSettings}
      checked={checked}
    >
      {leftLabel && (
        <StyledText
          className="switcher__left-label"
          $settings={{
            font: leftLabel.typo,
            text: {
              color: leftLabel.color,
            },
          }}
        >
          {leftLabel.text}
        </StyledText>
      )}
      <label className="switcher__container">
        <input
          type="checkbox"
          className="switcher__container__input"
          data-testid="input-switch"
          defaultChecked={checked}
          onChange={() => disabled === true || handleChange(!checked)}
          disabled={disabled}
        />
        <span
          className="switcher__container__span"
          data-testid="switcher__container__span"
        ></span>
      </label>
      {rightLabel && (
        <StyledText
          className="switcher__right-label"
          $settings={{
            font: rightLabel.typo,
            text: {
              color: rightLabel.color,
            },
          }}
        >
          {rightLabel.text}
        </StyledText>
      )}
    </StyledSwitcher>
  );
};

export default PortalSwitch;
