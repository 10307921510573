import { useState } from "react";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import type { Swiper as SwiperType } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { isClientSide } from "@ecp-redux/api/api";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import LinkWrapper, {
  StyledLinkWrapper,
} from "../../global/components/LinkWrapper/LinkWrapper";
import { isPortalSide } from "../../helpers/helpers";
import { getHrefFromLinkContent } from "../../helpers/linkHelpers";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useStickyOffsetsContext } from "../../structure/Contexts/StickyOffsetsContext";
import { scrollIntoRowPosition } from "../BoxListOfButtons/BoxListOfButtons.methods";
import { useBoxTilesSlider } from "./BoxTilesSlider.methods";
import {
  IBoxTilesSliderProps,
  TilesSliderRotateOptions,
} from "./BoxTilesSlider.types";
import {
  StyledSwiperSlideContainer,
  StyledTilesSlider,
} from "./StyledTilesSlider";

const BoxTilesSlider: React.FC<IBoxTilesSliderProps> = (props) => {
  const { offsetsData } = useStickyOffsetsContext();
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  const [imgWrapper, imageWidth] = useImageSize();

  const {
    content,
    contentSettings,
    displaySettings,
    getSwiperCustomConfiguration,
  } = useBoxTilesSlider(props);

  if (!isClientSide()) return null;

  const currentTileIndex =
    content?.tiles?.findIndex(
      (tile) => tile.id + props.id === props.pathExtraSettings
    ) + 1 || 1;
  // The + 1 adds 1 to the result returned by .findIndex(), ensuring the first slide has an index of 1 instead of 0.
  // The || 1 ensures that if .findIndex() returns -1 (indicating the slide was not found),
  // the currentSlideIndex value defaults to 1, representing the first slide.

  if (swiper != null && !isPortalSide()) {
    swiper.slideTo(currentTileIndex, 0);
  }

  if (!isPortalSide()) {
    displaySettings.slideRotateType = TilesSliderRotateOptions.SINGLE_TILE;
  }

  return (
    <StyledTilesSlider
      $contentSettings={contentSettings}
      $displaySettings={displaySettings}
      data-testid="boxTilesSlider"
      className="tiles-container"
    >
      <StyledText
        $settings={{
          text: {
            color: contentSettings.headerTextColor,
          },
          font: contentSettings.headerTextStyle,
        }}
        className="tiles-container__title"
      >
        {content.header}
      </StyledText>

      <Swiper
        className="swiper"
        modules={[Navigation, A11y, Autoplay, Pagination]}
        spaceBetween={contentSettings.spaceBetweenTiles}
        onSwiper={setSwiper}
        {...getSwiperCustomConfiguration()}
      >
        {contentSettings.tileSettings?.map((contentSettingsTile, index) => {
          const currentTileContent = content.tiles[index];
          const linkURL = getHrefFromLinkContent(currentTileContent.slideUrl);

          const currentUrlSettings =
            currentTileContent.slideUrl.selectedGoToOption ===
            SelectedGoToOption.GO_TO_PAGE
              ? {
                  href: linkURL,
                }
              : {
                  onClick: () =>
                    scrollIntoRowPosition(
                      currentTileContent.slideUrl,
                      props.path,
                      offsetsData
                    ),
                };

          return (
            <SwiperSlide
              key={content.tiles[index].id}
              data-testid="slider-slide"
              className="swiper__slide"
            >
              <LinkWrapper
                className="swiper__slide__link"
                openInNewTab={
                  currentTileContent.slideUrl.goToPageSetting?.openInNewTab
                }
                {...currentUrlSettings}
                href={
                  currentTileContent.slideUrl.selectedGoToOption ===
                  SelectedGoToOption.GO_TO_PAGE
                    ? linkURL
                    : ""
                }
              >
                <StyledSwiperSlideContainer
                  ref={imgWrapper}
                  tileSettings={contentSettingsTile}
                >
                  <>
                    {contentSettingsTile.show.title &&
                      (linkURL !== "" ? (
                        <StyledLinkWrapper
                          role="link"
                          className="swiper__slide__title"
                          $linkStyle={content.tiles[index].linkStyle}
                          $fontStyle={contentSettingsTile.titleStyle}
                          $basicColor={contentSettingsTile.titleColor}
                          {...currentUrlSettings}
                        >
                          {content.tiles[index].title}
                        </StyledLinkWrapper>
                      ) : (
                        <StyledText
                          $settings={{
                            text: {
                              color: contentSettingsTile.titleColor,
                            },
                            font: contentSettingsTile.titleStyle,
                          }}
                          className="swiper__slide__title"
                        >
                          {content.tiles[index].title}
                        </StyledText>
                      ))}

                    {contentSettingsTile.show.image && imageWidth && (
                      <div className="swiper__slide__image-container">
                        <div>
                          <ImageWrapper
                            imageUrl={content.tiles[index].image.imageUrl}
                            seoDescription={content.tiles[index].seoDescription}
                            imageFit={!contentSettingsTile.fill}
                            imageAlignment={contentSettingsTile.image.alignment}
                            opacity={contentSettingsTile.image.opacity}
                            width={
                              imageWidth -
                              displaySettings.padding.left -
                              displaySettings.padding.right
                            }
                            imgTagAsBackground
                          />
                        </div>
                      </div>
                    )}
                  </>
                </StyledSwiperSlideContainer>
              </LinkWrapper>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </StyledTilesSlider>
  );
};

export default BoxTilesSlider;
