export const swiperPaginationArrow = `
  .swiper-button-prev,
  .swiper-button-next {
    color: #81889c !important;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    z-index: var(--zindex-swiper-buttons);
    transition: background-color var(--ecp-transition);
  }

  .swiper-button-prev {
    left: 20px;
  }

  .swiper-button-next {
    right: 20px;
  }

  .swiper-button-prev:not(.swiper-button-prev.swiper-button-disabled):hover,
  .swiper-button-next:not(.swiper-button-next.swiper-button-disabled):hover {
    background-color: #f0f5fe;
  }

  .swiper-button-prev:not(.swiper-button-prev.swiper-button-disabled):active,
  .swiper-button-next:not(.swiper-button-next.swiper-button-disabled):active {
    background-color: #dce9fd;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 23px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    pointer-events: auto !important;
  }
`;
