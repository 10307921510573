import { format } from "date-fns";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import { formatPrice } from "../../../helpers/helpers";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../BoxMyAccountMyOrdersDetails.types";

export const getMyOrderDeliveryDetailsElements = (
  messages: IBoxMyAccountMyOrdersDetailsMessages,
  settings: IBoxMyAccountMyOrdersDetailsSettings,
  values: IOrderDetailsResponse["order"]["portalOrderDispatches"]["0"],
  currency: string
) => {
  return [
    {
      fontLabelTypo:
        settings.customer_data_order_details_dispatch_status_label_typo,
      textLabelColor:
        settings.customer_data_order_details_dispatch_status_label_color,
      messageLabel: messages.customer_data_order_details_dispatch_status,

      fontValueTypo:
        settings.customer_data_order_details_dispatch_status_value_typo,
      textValueColor:
        settings.customer_data_order_details_dispatch_status_value_color,
      value: values.orderDispatchStatus,
    },

    {
      fontLabelTypo:
        settings.customer_data_order_details_dispatch_products_value_label_typo,
      textLabelColor:
        settings.customer_data_order_details_dispatch_products_value_label_color,
      messageLabel:
        messages.customer_data_order_details_dispatch_products_value,

      fontValueTypo:
        settings.customer_data_order_details_dispatch_products_value_typo,
      textValueColor:
        settings.customer_data_order_details_dispatch_products_value_color,
      value: formatPrice(values.dispatchValue, currency),
    },
    {
      fontLabelTypo:
        settings.customer_data_order_details_dispatch_delivery_cost_label_typo,
      textLabelColor:
        settings.customer_data_order_details_dispatch_delivery_cost_label_color,
      messageLabel: messages.customer_data_order_details_dispatch_delivery_cost,

      fontValueTypo:
        settings.customer_data_order_details_dispatch_delivery_cost_value_typo,
      textValueColor:
        settings.customer_data_order_details_dispatch_delivery_cost_value_color,
      value: formatPrice(values.deliveryCost, currency),
    },
    {
      fontLabelTypo:
        settings.customer_data_order_details_dispatch_delivery_date_label_typo,
      textLabelColor:
        settings.customer_data_order_details_dispatch_delivery_date_label_color,
      messageLabel: messages.customer_data_order_details_dispatch_delivery_date,

      fontValueTypo:
        settings.customer_data_order_details_dispatch_delivery_date_value_typo,
      textValueColor:
        settings.customer_data_order_details_dispatch_delivery_date_value_color,
      value: format(new Date(values.deliveryDate), "dd.MM.yyyy"),
    },
  ];
};
