import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

interface IProgressBar {
  actualValue: number;
  fullValue: number;
  fillerColor: TColorId;
  borderColor: TColorId;
  backgroundColor?: TColorId;
  fillerBorderColor?: TColorId;
  borderSize?: number;
  borderRadius?: number;
}

const StyledProgressBarContainer = styled.div<{
  borderColor: TColorId;
  borderRadius?: number;
  borderSize?: number;
  backgroundColor?: TColorId;
}>(
  ({
    borderColor,
    backgroundColor,
    borderRadius = 20,
    borderSize = 1,
    theme,
  }) => `
    height: fit-content;
    width: 100%;
    background-color: ${
      backgroundColor
        ? convertColorIdToHex(backgroundColor, theme.colorPalette)
        : "transparent"
    };
    border-width: ${borderSize}px;
    border-color: ${convertColorIdToHex(borderColor, theme.colorPalette)};
    border-radius: ${borderRadius}px;
    padding: 2px 5px 2px 2px;
    `
);

const StyledProgressBarFiller = styled.div<{
  completed: number;
  fillerColor: TColorId;
  fillerBorderColor?: TColorId;
}>(
  ({ completed, fillerColor, fillerBorderColor, theme }) => `
   box-sizing: content-box; 
   width: ${completed}%;
   height: 3px;
   max-width: 100%;
   background-color: ${convertColorIdToHex(fillerColor, theme.colorPalette)};
   border-radius: inherit;
   border-width: 2px;
   border-color: ${
     fillerBorderColor
       ? convertColorIdToHex(fillerBorderColor, theme.colorPalette)
       : "transparent"
   };
`
);

const ProgressBar: React.FC<IProgressBar> = (props) => {
  const {
    actualValue,
    fullValue,
    fillerBorderColor,
    fillerColor,
    borderColor,
    backgroundColor,
  } = props;
  const completed = Math.round((actualValue / fullValue) * 100);

  return (
    <StyledProgressBarContainer
      borderColor={borderColor}
      backgroundColor={backgroundColor}
    >
      <StyledProgressBarFiller
        completed={completed}
        fillerColor={fillerColor}
        fillerBorderColor={fillerBorderColor}
      />
    </StyledProgressBarContainer>
  );
};

export default ProgressBar;
