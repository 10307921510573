import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import styled, { useTheme } from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { isPortalSide } from "../../helpers/helpers";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import {
  getPreparedSettings,
  usePrepareSettings,
} from "../../settingsPatterns/settingsPatterns.methods";
import { Pagination } from "../../shared/components/Pagination/Pagination";
import Popup from "../../shared/components/Popup/Popup";
import ProductModule from "../../shared/components/domain/Product/ProductModule/ProductModule";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import ArrowDownIcon from "../../shared/icons/ArrowDownIcon";
import { StyledSearchUtilsLabel } from "../../shared/styleElements/StyledSearchResultsDropdown/SearchResultsDropdown.styled";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import {
  BOX_SEARCH_RESULTS_CONTENT_PATTERN,
  BOX_SEARCH_RESULTS_CONTENT_SETTINGS_PATTERN,
  BOX_SEARCH_RESULTS_DISPLAY_SETTINGS_PATTERN,
  BOX_SRP_PRODUCT_SLIDER_CUSTOM_ATTRIBUTE_PATTERN,
} from "./BoxSearchResults.pattern";
import {
  StyledNavigation,
  StyledSearchResultsGrid,
} from "./BoxSearchResults.styled";
import {
  BoxSearchResultsProps,
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "./BoxSearchResults.types";
import ExtendedTopNavigation from "./components/RWD components/ExtendedTopNavigation";
import { StyledSortingButton } from "./components/RWD components/ExtendedTopNavigation.styled";
import TopNavigation from "./components/TopNavigation";
import { useBoxSearchResultPagination } from "./components/useSearchResultPaginationData";

const StyledExtendedTopNavigationPopupWrapper = styled(Popup)`
  height: 100svh;

  .extended-top-navigation {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 100vh;
  }
`;

export const repairOrderInModule = (orderInModule: string[]): string[] => {
  // taxRate became unnecessary after ECP-5274
  const orderInModuleWithoutTaxRate = orderInModule.filter(
    (o) => o !== "taxRate"
  );
  // old boxes may be missing "netPrice" (added by improvment) and by this also may be duplicated "button" (removal by Set)
  if (orderInModuleWithoutTaxRate.includes("netPrice"))
    return orderInModuleWithoutTaxRate;
  const unique = [...new Set(orderInModuleWithoutTaxRate)];
  unique.push("netPrice");
  return unique;
};

const BoxSearchResults: React.FC<BoxSearchResultsProps> = (props) => {
  const [gridSingleElement, singleElementWidth] = useImageSize();

  const tmpCont = usePrepareSettings({
    props: props.contentSettings,
    settingsPattern: BOX_SEARCH_RESULTS_CONTENT_SETTINGS_PATTERN(),
    options: {
      includeTheme: true,
      themePath: "advanceSettings.settings",
      themeWrapperPath: "image",
    },
  }).settings;

  const contentSettings = {
    ...tmpCont,
    product: {
      ...tmpCont.product,
      custom: Object.fromEntries(
        Object.entries(tmpCont.product.custom).map(([key, value]) => [
          key,
          getPreparedSettings(
            value,
            BOX_SRP_PRODUCT_SLIDER_CUSTOM_ATTRIBUTE_PATTERN()
          ),
        ])
      ),
    },
  };

  const content = usePrepareSettings({
    props: props.content,
    settingsPattern: BOX_SEARCH_RESULTS_CONTENT_PATTERN(),
  }).settings;

  const displaySettings = usePrepareSettings({
    props: props.displaySettings,
    settingsPattern: BOX_SEARCH_RESULTS_DISPLAY_SETTINGS_PATTERN(),
  }).settings;

  const {
    productPhoto,
    badge,
    product: specificProduct,
    productsOnPage,
    pagination,
    sorting,
    productsPerRow,
    favorites,
    variants,
  } = contentSettings;

  const router = useRouter();

  const { grid, module, orderInModule, marketingCampaign } = displaySettings;

  const updatedOrderInModule = repairOrderInModule(orderInModule);

  const { sortingText, productButtonText } = content;

  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchResultsMessages,
    IBoxSearchResultsSettings
  >();

  const [expandNavigation, setExpandNavigation] = useState(false);

  const {
    productsVm,
    isFetching,
    pageCount,
    page,
    pageSize,
    attributesLineDetection,
    setPage,
    setPageSize,
    isError,
  } = useBoxSearchResultPagination(
    productsOnPage,
    Object.keys(contentSettings.product.custom ?? {}),
    marketingCampaign
  );

  const showProductGrid = productsVm && productsVm.length > 0;

  const isMobile = useIsMobilePortal();

  const fetchedTheme = useTheme() as IThemeState;

  useEffect(() => {
    if (!isMobile) {
      setExpandNavigation(false);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!isFetching && !isError && productsVm.length === 0 && isPortalSide()) {
      router.push(
        `${fetchedTheme?.advanceSettings.settings.redirectForEmptyResults}`,
        undefined,
        { shallow: false }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsVm.length]);

  const closingNavigation = () => {
    setExpandNavigation(false);
  };

  return (
    <>
      {isFetching && <div>Loading...</div>}

      <div className="search-results">
        {!isMobile && (
          <TopNavigation
            contentSettings={contentSettings}
            content={content}
            pageCount={pageCount}
            page={page}
            pageSize={pageSize}
            changePageSize={setPageSize}
            changePage={setPage}
          />
        )}
        {isMobile && !expandNavigation && (
          <StyledSortingButton align={settings.optionsAlign}>
            <button
              onClick={() => setExpandNavigation(true)}
              className="sortingButtonLabel"
              data-testid="expandNavigation"
            >
              <StyledSearchUtilsLabel $settings={sorting}>
                {sortingText}:
              </StyledSearchUtilsLabel>
              <ArrowDownIcon width={14} height={7} />
            </button>
          </StyledSortingButton>
        )}
        {isMobile && expandNavigation && (
          <StyledExtendedTopNavigationPopupWrapper>
            <ExtendedTopNavigation
              contentSettings={contentSettings}
              content={content}
              changeProductAmount={setPageSize}
              productsPerPage={pageSize}
              closingNavigation={closingNavigation}
            />
          </StyledExtendedTopNavigationPopupWrapper>
        )}
        {showProductGrid && (
          <StyledSearchResultsGrid
            perRow={productsPerRow}
            space={grid}
            className="search-results__products-container"
          >
            {productsVm.map((product) => {
              return product && "sku" in product ? (
                <div
                  ref={gridSingleElement}
                  key={product.sku}
                  className="search-results__products-container__product-wrapper"
                >
                  <ProductModule
                    product={product}
                    moduleSettings={module}
                    productPhoto={productPhoto}
                    badge={badge}
                    favorites={favorites}
                    buttonText={productButtonText}
                    ratingText={messages.reviewsText}
                    productSettings={specificProduct}
                    orderInModule={updatedOrderInModule}
                    variantSettings={variants?.find(
                      (variant) => variant.code === product.axis
                    )}
                    boxId={props.id}
                    attributesLineDetection={attributesLineDetection}
                  />
                </div>
              ) : (
                <div
                  className="search-results__products-container__baner-wrapper"
                  key={product.imageUrl}
                >
                  <ImageWrapper
                    key={product.imageUrl}
                    imageUrl={product.imageUrl}
                    link={{
                      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                      goToPageSetting: {
                        link: product.redirectUrl,
                        openInNewTab: false,
                      },
                    }}
                    seoDescription={product.seoDescription}
                    imageFit={false}
                    imageAlignment={{
                      horizontal: AlignmentHorizontalOption.CENTER,
                      vertical: AlignmentVerticalOption.TOP,
                    }}
                    height={2000}
                    width={1080}
                  />
                </div>
              );
            })}
          </StyledSearchResultsGrid>
        )}
        {!isFetching && (
          <StyledNavigation
            align={
              isMobile
                ? AlignmentHorizontalOption.CENTER
                : settings.optionsAlign
            }
            className="search-results__pagination"
          >
            <Pagination
              page={page}
              pageCount={pageCount}
              setPage={setPage}
              fromText={messages.paginationFrom}
              textSettings={pagination}
            />
          </StyledNavigation>
        )}
      </div>
    </>
  );
};

export default BoxSearchResults;
