import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MAIN_BUTTON_CONTENT_SETTING_PATTERN } from "../../settingsPatterns/settingsPatterns.utils";
import {
  TBoxVariant,
  TVariant,
} from "../../shared/components/domain/Product/ProductVariants/ProductVariants.types";
import { IBoxProductVariantsContentSettings } from "./BoxProductVariants.types";

export const BOX_PRODUCT_VARIANTS_CONTENT_SETTINGS_PATTERN =
  (): IBoxProductVariantsContentSettings => ({
    variants: [],
  });

export const BOX_VARIANT_PATTERN: TBoxVariant = {
  code: "",
  optionStyle: `option$$${1}`,
  rowWidth: 100,
  spacingElementsInRow: 5,
  spacingBetweenRows: 5,
  variantsAlignment: AlignmentHorizontalOption.CENTER,
  label: {
    show: true,
    name: "",
    style: "typo$$1",
    color: "color$$1",
    spacingBetweenLabelAndVariants: 5,
    labelAlignment: AlignmentHorizontalOption.LEFT,
  },
};

export const VARIANT_PATTERN: TVariant = {
  code: "",
  optionStyle: `option$$${1}`,
  maxNumbersOfRows: 2,
  rowWidth: 100,
  spacingElementsInRow: 5,
  spacingBetweenRows: 5,
  variantsAlignment: AlignmentHorizontalOption.CENTER,
  label: {
    show: true,
    name: "See more",
    style: {
      id: MAIN_BUTTON_CONTENT_SETTING_PATTERN().id,
      custom: MAIN_BUTTON_CONTENT_SETTING_PATTERN().custom,
    },
  },
};
