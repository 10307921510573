import React from "react";
import { IIcon } from "./Icon.types";

const IconResize: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
}: IIcon): JSX.Element => {
  return (
    <svg
      data-testid="iconResize"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="0.5" x2="20" y2="0.5" stroke="#CCCCCC" />
    </svg>
  );
};

IconResize.defaultProps = {
  width: "20px",
  height: "1px",
  fill: "none",
  color: "#CCCCCC",
  viewBox: "0 0 12 1",
};

export default IconResize;
