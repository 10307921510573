import { useEffect, useState } from "react";
import {
  A11y,
  FreeMode,
  Navigation,
  Swiper as SwiperProps,
  Thumbs,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CloseIcon from "../../../../global/components/CloseIcon/CloseIcon";
import Icon from "../../../../global/components/Icon/Icon";
import Portal from "../../../../global/components/Portal/Portal";
import { IPhotoGalleryModalProps } from "../../BoxPhotoGallery.types";
import { StyledPhotoGalleryModal } from "./PhotoGalleryModal.styles";

const PhotoGalleryModal: React.FC<IPhotoGalleryModalProps> = ({
  images,
  onClose,
  setActiveSlide,
  activeSlide,
  productData = {
    productName: "",
    productSku: "",
    wishlist: false,
  },
}) => {
  const { productName } = productData;
  const [imageSwiper, setImageSwiper] = useState<SwiperProps>();
  const [gallerySwiper, setGallerySwiper] = useState<SwiperProps>();

  useEffect(() => {
    if (!imageSwiper) return;

    imageSwiper?.on("snapIndexChange", (swiper) => {
      setActiveSlide(swiper.activeIndex);
    });
  }, [imageSwiper, setActiveSlide]);

  const updateImageSwiper = (swiper: SwiperProps) => {
    setImageSwiper(swiper);
  };

  const updateGallerySwiper = (swiper: SwiperProps) => {
    setGallerySwiper(swiper);
  };

  return (
    <Portal containerId="photo-gallery-modal">
      <StyledPhotoGalleryModal
        data-testid="slider-modal"
        className="photo-gallery-modal-container"
      >
        <div className="photo-gallery-modal-container__photo-gallery-modal">
          <div className="photo-gallery-modal-container__photo-gallery-modal__close">
            <Icon color="#000000" hoverColor="#81889c" onClick={onClose}>
              <CloseIcon basic />
            </Icon>
          </div>
          <div className="photo-gallery-modal-container__photo-gallery-modal__image">
            <Swiper
              modules={[Navigation, A11y, Thumbs]}
              initialSlide={activeSlide}
              thumbs={{ swiper: gallerySwiper }}
              navigation
              onSwiper={updateImageSwiper}
            >
              {images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="photo-gallery-modal-container__photo-gallery-modal__image--image-container">
                    <img
                      src={image}
                      className="zoom-modal__image--image"
                      alt={productName}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="photo-gallery-modal-container__photo-gallery-modal__gallery">
            <Swiper
              modules={[Navigation, FreeMode, A11y]}
              watchSlidesProgress
              slidesPerView={5}
              freeMode={{ enabled: true, sticky: true }}
              navigation
              onSwiper={updateGallerySwiper}
            >
              {images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="photo-gallery-modal-container__photo-gallery-modal__gallery--image-container">
                    <img
                      src={image}
                      className="zoom-modal__gallery--image"
                      alt={productName}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </StyledPhotoGalleryModal>
    </Portal>
  );
};

export default PhotoGalleryModal;
