import { IIcon } from "./Icon.types";

const TrashIcon: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  stroke = "black",
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 5L15.1327 17.1425C15.0579 18.1891 14.187 19 13.1378 19H4.86224C3.81296 19 2.94208 18.1891 2.86732 17.1425L2 5M7 9V15M11 9V15M12 5V2C12 1.44772 11.5523 1 11 1H7C6.44772 1 6 1.44772 6 2V5M1 5H17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

TrashIcon.defaultProps = {
  width: "18",
  height: "20",
  fill: "none",
  viewBox: "0 0 18 20",
};

export default TrashIcon;
