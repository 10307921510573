import React, { useState } from "react";
import { AddressType } from "@ecp-redux/dto/cartAddresses.type";
import { isNotEmptyMessage } from "../../../../../helpers/isNotEmptyMessage";
import Alert from "../../../../../shared/components/Alert/Alert";
import { ReactComponent as PenIcon } from "../../../../../shared/icons/pen.svg";
import { StyledBackground } from "../../../../../shared/styleElements/StyledBackground/StyledBackground.styled";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import ChangeAddress from "../../../shared/ChangeBillingPostalAddress";
import { StyledBillingAddress } from "../../BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../BoxCartStepTwo.types";
import AddressInfo from "../DeliveryChannels/elements/SelectedAddress/elements/AddressInfo";
import { IBillingProps } from "./Billing.types";

const Billing: React.FC<IBillingProps> = ({
  selectedBilling,
  billingAddresses,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  const [editAddress, setEditAddress] = useState(false);

  return (
    <StyledBillingAddress className="billing">
      <StyledText
        className="billing__header"
        $settings={{
          font: settings.basket_step_2_3_typography_header_1,
          text: { color: settings.basket_step_all_color_main },
        }}
        show={isNotEmptyMessage(messages.billing_header)}
      >
        <p>{messages.billing_header}</p>
      </StyledText>

      <Alert
        alertsCodes={["1015"]}
        backgroundColor={settings.basket_alert_background}
        textColor={settings.basket_alert_typo_color}
        typography={settings.basket_alert_typo}
        closeable={false}
        className="billing__alert"
        removeWhen={!!selectedBilling}
      />

      {!editAddress && selectedBilling ? (
        <StyledBackground
          className="billing__selected-address-background"
          backgroundColor={
            settings.basket_step_all_background_adress_payment_color
          }
        >
          <StyledText
            className="billing__select-billing"
            $settings={{
              font: settings.basket_step_2_3_typography_paragraph_4,
              text: { color: settings.basket_step_all_color_main },
            }}
          >
            <AddressInfo address={selectedBilling} />
            <button
              className="billing__selected-billing__button"
              onClick={() => setEditAddress(true)}
            >
              <PenIcon />
            </button>
          </StyledText>
        </StyledBackground>
      ) : (
        <ChangeAddress
          closeEditAddress={() => setEditAddress(false)}
          selectedAddress={selectedBilling}
          addresses={billingAddresses}
          addressType={AddressType.BILLING}
        />
      )}
    </StyledBillingAddress>
  );
};

export default Billing;
