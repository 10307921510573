import { API, api } from "./api";

type Currency = string;
type Value = string;

export type ISettlementResponse = {
  result: Record<string, Record<Currency, Value>>;
};

export interface ISettlementPayload {
  projection: string[];
}

export const settlement = api.injectEndpoints({
  endpoints: (builder) => ({
    postSettlement: builder.query<ISettlementResponse, ISettlementPayload>({
      query: (body) => {
        return {
          url: API.postSettlement.endpoint(),
          method: "POST",
          body,
        };
      },
      providesTags: ["CONTRACTORS"],
    }),
    getSettlementFieldNames: builder.query<{ columnNames: string[] }, void>({
      query: () => {
        return API.getSettlementFieldNames.endpoint();
      },
    }),
  }),
});

export const { usePostSettlementQuery, useLazyGetSettlementFieldNamesQuery } =
  settlement;

export const { postSettlement, getSettlementFieldNames } = settlement.endpoints;
