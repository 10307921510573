import { IPaginatedPortalResponse } from "../dto/paginationResponse.types";
import { IStoreLocator } from "../dto/storesLocator.types";
import { IStoreLocatorRequest } from "./../dto/storesLocator.types";
import { API, api } from "./api";

export const storeLocatorResults = api.injectEndpoints({
  endpoints: (builder) => ({
    //use query in post method because only in query work cache
    postStoresLocator: builder.query<
      IPaginatedPortalResponse<IStoreLocator[]>,
      IStoreLocatorRequest
    >({
      query: (body) => {
        return {
          url: API.getStoresLocator.endpoint(),
          method: "POST",
          body: {
            facilities: [], //!! facilities option is not currently used in our project but is required on the BE side.
            ...body,
          },
        };
      },
    }),
  }),
});

export const { usePostStoresLocatorQuery } = storeLocatorResults;
