import React from "react";
import { useRouter } from "next/router";
import slugify from "slugify";
import {
  CategoryValueDto,
  FacetCatDto,
  FacetParam,
} from "@ecp-redux/dto/searchResults.types";
import ChevronThinIcon from "../../global/components/ChevronThinIcon/ChevronThinIcon";
import { StyledLinkWrapper } from "../../global/components/LinkWrapper/LinkWrapper";
import { getCategoryTreeName } from "../../helpers/pageContext";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import {
  IBoxSearchFiltersContent,
  IBoxSearchFiltersContentSettings,
  IBoxSearchFiltersDisplaySettings,
} from "./BoxSearchFilters.types";
import { StyledSearchValue, TextBreaker } from "./SearchFacet.styled";

interface ISearchCategoriesProps {
  categoryBranch: FacetCatDto<FacetParam.TREE> | undefined;
  displaySettings: IBoxSearchFiltersDisplaySettings;
  contentSettings: IBoxSearchFiltersContentSettings;
  content: IBoxSearchFiltersContent;
}

const SearchCategories: React.FC<ISearchCategoriesProps> = ({
  categoryBranch,
  displaySettings: { categoryLinkStyle },
  contentSettings: { count, title },
  content,
}) => {
  const router = useRouter();
  const isMobile = useIsMobilePortal();
  const changeCategory = (category: CategoryValueDto) => {
    const { id, name } = category;
    const newCategoryUrl = `/${id}_${slugify(name, {
      lower: true,
      remove: /(percent|[^\x20-\x7E])/g,
    })}_${getCategoryTreeName(id)}`
      .replace(/__/g, "_")
      .replace(/,/g, "");

    const query = router.query;
    delete query["page"];
    delete query["pageSize"];
    delete query["standartPage"];

    router.push({
      pathname: newCategoryUrl,
      query,
    });
  };

  return (
    <ul className="search-filters-container__categories">
      <StyledText
        $settings={title}
        className="search-filters-container__categories__title"
      >
        {content.categoriesTitle}
      </StyledText>
      {!isMobile &&
        categoryBranch?.values.map((category) => (
          <StyledSearchValue
            key={category.id}
            className="search-filters-container__categories__category-name"
          >
            <StyledLinkWrapper
              $linkStyle={categoryLinkStyle.link}
              $fontStyle={categoryLinkStyle.text}
              onClick={() => changeCategory(category)}
              renderAs="span"
            >
              {category.name}
            </StyledLinkWrapper>
            <StyledText $settings={count}>{category.count}</StyledText>
          </StyledSearchValue>
        ))}
      {isMobile &&
        categoryBranch?.values.map((category) => (
          <StyledSearchValue
            key={category.id}
            className="search-filters-container__categories__category-name"
            onClick={() => {
              changeCategory(category);
            }}
          >
            <StyledLinkWrapper
              $linkStyle={categoryLinkStyle.link}
              $fontStyle={categoryLinkStyle.text}
              renderAs="span"
            >
              <TextBreaker>{category.name}</TextBreaker>
            </StyledLinkWrapper>
            <div className="search-filters-container__categories__category-name__count-container">
              <StyledText $settings={count}>{category.count}</StyledText>
              <div className="search-filters-container__categories__category-name__count-container__right-icon">
                <ChevronThinIcon className="rightIcon" width="14" height="7" />
              </div>
            </div>
          </StyledSearchValue>
        ))}
    </ul>
  );
};

export default SearchCategories;
