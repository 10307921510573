import React from "react";
import { isTruthy } from "../../helpers/helpers";
import { TBoxContactFormProps } from "./BoxContactForm.types";
import ComplaintForm from "./forms/ComplaintForm";
import ContactForm from "./forms/ContactForm";

const BoxContactForm: React.FC<TBoxContactFormProps> = ({
  settings,
}: TBoxContactFormProps) => {
  return isTruthy(settings.file_upload) ? <ComplaintForm /> : <ContactForm />;
};

export default BoxContactForm;
