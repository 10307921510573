import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, {
  alignmentOptionsToValue,
} from "../../../../../../libs/boxes/src/lib/settingsPatterns/composeCss";

export const StyledSearchInputWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  > div {
    width: 100%;
  }
`;

export const StyledSingleItemWrapper = styled.div<{
  itemAlignment: AlignmentHorizontalOption;
}>(({ itemAlignment }) => {
  return `
  display: flex;
  align-items: center;
  justify-content: 
    ${alignmentOptionsToValue(itemAlignment)};
`;
});

export const StyledContractorTextContainer = styled.div<{
  isDropdownLabel: boolean;
}>`
  display: flex;
  flex-direction: column;
  .contractor-name {
    ${({ isDropdownLabel }) =>
      isDropdownLabel ? composeCss.lineClamp(1) : composeCss.lineClamp(2)};
  }
`;
