import { useRouter } from "next/router";
import { nanoid } from "nanoid";
import styled, { css, useTheme } from "styled-components";
import {
  IMiniCartB2BResponse,
  PaymentMethodOptionB2B,
} from "@ecp-redux/dto/cartB2B.types";
import {
  IThemeState,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { usePortalSelector } from "../../../redux/store";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import Alert from "../../../shared/components/Alert/Alert";
import useSummaryB2BAlerts from "../../../shared/components/domain/CartB2B/SummaryB2B/useSummaryB2BAlerts";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMiniCartB2BMessages,
  IBoxMiniCartB2BSettings,
} from "../BoxMiniCartB2B.types";
import BoxMiniCartB2BOrderAccordion from "./BoxMiniCartB2BOrderAccordion";

const BoxMiniCartB2BButtonContentWrapper = styled.div(
  ({
    backgroundColor,
    theme,
  }: {
    backgroundColor: TColorId;
    theme: IThemeState;
  }) => {
    return css`
      width: 500px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      box-shadow: 0px 4px 16px 0px #c4d0d826;
      background-color: ${convertColorIdToHex(
        backgroundColor,
        theme.colorPalette
      )};

      .minicart-b2b__content-wrapper {
        &__header-wrapper {
          display: flex;
          align-items: baseline;

          &__header__items {
            margin-left: 10px;
            margin-right: 5px;
          }
        }

        &__limit-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #f4f5f7;
          padding: 2px 5px;
          gap: 10px;

          div {
            text-wrap: nowrap;
          }

          &__limit-content {
            display: flex;
            flex-flow: wrap;
            justify-content: end;

            &__usage,
            &__left {
              width: fit-content;
              display: flex;
              align-items: center;
              flex-wrap: nowrap;
              gap: 5px;

              span {
                text-wrap: nowrap;
              }
            }

            &__left {
              margin-left: 5px;
            }
          }
        }
      }
    `;
  }
);

const BoxMiniCartB2BButtonContent = ({
  data,
}: {
  data: IMiniCartB2BResponse;
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMiniCartB2BMessages,
    IBoxMiniCartB2BSettings
  >();
  const router = useRouter();
  const { alerts } = usePortalSelector((state) => state.alerts);

  useSummaryB2BAlerts(data.summary, {
    limitedAccount: messages.mini_cart_summary_credit_bar_error_restricted,
    limitExceeded: messages.mini_cart_summary_credit_bar_error_usage,
  });

  const ordersWithUniqueIds = data.orders.map((order) => ({
    ...order,
    orderUniqueId: nanoid(),
  }));

  return (
    <BoxMiniCartB2BButtonContentWrapper
      backgroundColor={settings.mini_cart_background_color}
      className="minicart-b2b__content-wrapper"
    >
      <div
        className="minicart-b2b__content-wrapper__header-wrapper"
        data-testid="minicart-b2b__content-wrapper__header-wrapper"
      >
        <StyledText
          className="minicart-b2b__content-wrapper__header-wrapper__header"
          $settings={{
            font: settings.mini_cart_header_typo,
            text: {
              color: settings.mini_cart_header_color,
            },
          }}
        >
          {messages.mini_cart_header_label}
        </StyledText>

        <StyledText
          className="minicart-b2b__content-wrapper__header-wrapper__header__items"
          $settings={{
            font: settings.mini_cart_header_items_typo,
            text: {
              color: settings.mini_cart_header_items_color,
            },
          }}
        >
          {`${ordersWithUniqueIds.reduce(
            (acc, order) =>
              acc +
              order.cartLines.reduce((acc, line) => acc + line.quantity, 0),
            0
          )} ${messages.mini_cart_summary_items_sufix}`}
        </StyledText>
        <StyledText
          className="minicart-b2b__content-wrapper__header-wrapper__header__items-count"
          $settings={{
            font: settings.mini_cart_header_items_typo,
            text: {
              color: settings.mini_cart_header_items_color,
            },
          }}
        >
          {`(${ordersWithUniqueIds.length} ${messages.mini_cart_header_summary_orders_sufix})`}
        </StyledText>
      </div>

      {ordersWithUniqueIds.map((order, idx) => (
        <BoxMiniCartB2BOrderAccordion
          key={order.orderUniqueId}
          order={order}
          idx={idx}
        />
      ))}

      {!alerts.filter((alert) => alert.code === "credit_limit_exceeded")
        .length &&
        data.summary.paymentMethod !== PaymentMethodOptionB2B.CASH && (
          <div className="minicart-b2b__content-wrapper__limit-wrapper">
            <StyledText
              $settings={{
                font: settings.mini_cart_summary_credit_bar_label_typo,
                text: {
                  color: settings.mini_cart_summary_credit_bar_label_color,
                },
              }}
            >
              {messages.mini_cart_credit_bar_label}
            </StyledText>
            <div className="minicart-b2b__content-wrapper__limit-wrapper__limit-content">
              <div className="minicart-b2b__content-wrapper__limit-wrapper__limit-content__usage">
                <StyledText
                  renderAs="span"
                  $settings={{
                    font: settings.mini_cart_summary_credit_bar_usage_label_typo,
                    text: {
                      color:
                        settings.mini_cart_summary_credit_bar_usage_label_color,
                    },
                  }}
                >
                  {messages.mini_cart_credit_bar_usage_label}
                </StyledText>
                <StyledText
                  renderAs="span"
                  $settings={{
                    font: settings.mini_cart_summary_credit_bar_usage_amount_typo,
                    text: {
                      color:
                        settings.mini_cart_summary_credit_bar_usage_amount_color,
                    },
                  }}
                >
                  {formatPrice(
                    data.summary.totalCreditLimit -
                      data.summary.creditLimitAvailable,
                    globalMessages.currencyShort
                  )}
                </StyledText>
              </div>
              <div className="minicart-b2b__content-wrapper__limit-wrapper__limit-content__left">
                <StyledText
                  renderAs="span"
                  $settings={{
                    font: settings.mini_cart_summary_credit_bar_left_label_typo,
                    text: {
                      color:
                        settings.mini_cart_summary_credit_bar_left_label_color,
                    },
                  }}
                >
                  {` / ${messages.mini_cart_credit_bar_left_label}`}
                </StyledText>
                <StyledText
                  renderAs="span"
                  $settings={{
                    font: settings.mini_cart_summary_credit_bar_left_amount_typo,
                    text: {
                      color:
                        settings.mini_cart_summary_credit_bar_left_amount_color,
                    },
                  }}
                >
                  {formatPrice(
                    data.summary.creditLimitAvailable,
                    globalMessages.currencyShort
                  )}
                </StyledText>
              </div>
            </div>
          </div>
        )}
      <Alert
        alertsCodes={["limited_account"]}
        backgroundColor={settings.mini_cart_credit_bar_error_background_color}
        textColor={settings.mini_cart_credit_bar_error_alert_color}
        typography={settings.mini_cart_credit_bar_error_alert_typo}
        closeable
      />
      <Alert
        alertsCodes={["credit_limit_exceeded"]}
        backgroundColor={settings.mini_cart_credit_bar_error_background_color}
        textColor={settings.mini_cart_credit_bar_error_alert_color}
        typography={settings.mini_cart_credit_bar_error_alert_typo}
        closeable
      />
      <StyledButton
        onClick={() => router.push(settings.mini_cart_button_redirect_URL)}
        style={{
          width: "100%",
        }}
        renderAs="button"
        type="submit"
        $settings={settings.mini_cart_button_to_cart_style}
        show={isNotEmptyMessage(messages.mini_cart_button_to_cart_label)}
        data-testid="go-to-cart-button"
      >
        {messages.mini_cart_button_to_cart_label}
      </StyledButton>
    </BoxMiniCartB2BButtonContentWrapper>
  );
};

export default BoxMiniCartB2BButtonContent;
