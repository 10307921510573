import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TMessage } from "@ecp-redux/dto/boxes.types";
import { IElementText } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrowRight.svg";
import StyledText from "../../styleElements/StyledText/StyledText";

export interface PaginationProps {
  page: number;
  pageCount: number;
  setPage: (page: number) => void;
  fromText: TMessage;
  textSettings: IElementText;
}

const StyledPaginationContainer = styled(StyledText)`
  width: content-fit;

  input {
    border: 1px solid #eeeeee;
    padding: 8px 16px;
    width: 70px;
    text-align: center;
  }
  svg {
    display: inline;
    cursor: pointer;

    &.left {
      transform: rotate(180deg);
      margin-right: 26px;
    }
    &.right {
      margin-left: 26px;
    }
  }

  .from-text {
    margin: 0 17px;
  }
`;

export const Pagination: React.FC<PaginationProps> = ({
  page,
  pageCount,
  setPage,
  fromText,
  textSettings,
}) => {
  const showLeftArrow = page > 0;
  const showRightArrow = page !== pageCount;
  const [count, setCount] = useState(page + 1);

  const handleSetPage = useCallback(
    (val: number) => {
      if (val > pageCount) {
        setPage(pageCount);
        setCount(page + 1);
      } else if (val < 1) {
        setPage(0);
        setCount(1);
      } else {
        setPage(val - 1);
      }
    },
    [page, pageCount, setPage]
  );

  useEffect(() => {
    setCount(page + 1);
  }, [page]);

  return (
    <StyledPaginationContainer
      $settings={textSettings}
      className="pagination-container"
    >
      {showLeftArrow && (
        <ArrowRightIcon className="left" onClick={() => setPage(page - 1)} />
      )}
      <input
        name="actualPage"
        data-testid="setPageInput"
        value={count}
        onChange={(e) => !isNaN(+e.target.value) && setCount(+e.target.value)}
        onBlur={(e) => handleSetPage(+e.target.value)}
        onKeyDown={(e) =>
          e.key === "Enter" && handleSetPage(+e.currentTarget.value)
        }
        maxLength={8}
      />
      <span className="from-text"> {fromText} </span>
      <span>{pageCount + 1}</span>
      {showRightArrow && (
        <ArrowRightIcon className="right" onClick={() => setPage(page + 1)} />
      )}
    </StyledPaginationContainer>
  );
};
