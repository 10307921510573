import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const StyledArticleImageLinkWrapper = styled.div<{
  alignment: AlignmentHorizontalOption;
}>(
  ({ alignment }) => `
  .article-list__link {
    overflow-wrap: normal;
    word-break: normal;
    text-align: ${alignment.toLowerCase()};
  }
`
);
