import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../../../lib/global/global";
import { StyledSwiper } from "../../../../../lib/settingsPatterns/elements/StyledSwiper/StyledSwiper";
import { convertColorIdToHex } from "../../../../../lib/settingsPatterns/settingsPatterns.methods";
import { swiperPaginationArrow } from "../../../../styles/swiper.styles";

export const StyledPhotoGallerySlider = styled.div<{
  disabled: boolean;
  bulletBackgroundColor?: TColorId;
  bulletActiveBackgroundColor?: TColorId;
}>(
  ({ disabled, bulletBackgroundColor, bulletActiveBackgroundColor, theme }) => `
  max-height: 800px;
  position: relative;
  background-color: #ffffff;
  padding: 20px;

  .photo-gallery-slider-container__photo-gallery-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .photo-gallery-slider-container__photo-gallery-slider__image {
    .swiper-slide {
      ${disabled ? "" : "cursor: pointer;"}
      height: 100%;
      width: 100%;

      .photo-gallery-slider-container__photo-gallery-slider__image--image-container {
        max-height: 640px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .photo-gallery-slider-container__photo-gallery-slider__gallery {
    height: 100%;
    overflow: hidden;
    position: relative;


    .swiper {
      position: initial;
      width: 665px;
    }

    .swiper-slide {
      cursor: pointer;
      height: 100px;
      width: 113px;

      .photo-gallery-slider-container__photo-gallery-slider__gallery--image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        height: 100px;
        width: 113px;
        border: 1px solid #eeeeee;
        transition: border-color var(--ecp-transition);

        &:hover {
          border-color: #cccccc;
        }
      }
    }

    .swiper-slide-thumb-active {
      .photo-gallery-slider-container__photo-gallery-slider__gallery--image-container {
        border: 1px solid #000000;

        &:hover {
          border-color: #000000;
        }
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 40px;
      width: 40px;

      &::after {
        font-size: 14px;
      }
    }
  }

  .ecp-image {
    max-height: 640px;
  }
  
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    ${StyledSwiper}  {
      
      .swiper-pagination-bullet-active-prev-prev,
      .swiper-pagination-bullet-active-next-next {
        scale: 1.75;
      }

      .swiper-pagination-bullet {
        background-color: ${
          bulletBackgroundColor
            ? convertColorIdToHex(bulletBackgroundColor, theme.colorPalette)
            : "#eeeeee"
        } !important;
      }
      
      .swiper-pagination-bullet-active {
        background-color: ${
          bulletActiveBackgroundColor
            ? convertColorIdToHex(
                bulletActiveBackgroundColor,
                theme.colorPalette
              )
            : "#81889c"
        } !important;
      }
    }
  }

  ${swiperPaginationArrow}
`
);
