import styled from "styled-components";

export const StyledSmsConsent = styled.div`
  .sms-consent__phone {
    display: flex;
    gap: 0.5rem;
  
    &__text {
      cursor: pointer;
      text-decoration: underline;
      user-select: none;
    }
  }
`;
