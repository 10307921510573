import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMultiMenuMessagesScheme = z.object({});

export const IBoxMultiMenuSettingsScheme = z.object({
  multimenu_mobile_right_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  multimenu_mobile_background_color:
    themeScheme.ColorIdScheme.default("color$$3").catch("color$$3"),
  multimenu_mobile_hamburger_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  multimenu_mobile_hamburger_aligment: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  multimenu_mobile_hamburger_view:
    themeScheme.SettingBooleanScheme.default("true").catch("true"),
  multimenu_mobile_hamburger_left_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_hamburger_right_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_hamburger_bottom_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_hamburger_top_padding:
    themeScheme.SizeCSSScheme.default("0px").catch("0px"),
  multimenu_mobile_close_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  multimenu_mobile_items_left_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  multimenu_mobile_items_top_padding:
    themeScheme.SizeCSSScheme.default("5px").catch("5px"),
  multimenu_mobile_hamburger_size:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
});
