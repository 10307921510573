import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";
import { IProductModuleElementsStyle } from "./ProductModule.types";

export const labelsToString = (
  o: TAttributeValue | TAttributeValue[] | undefined
) => {
  return Array.isArray(o) ? o.map((a) => a.label).join(", ") : o?.label;
};

export const valuesToString = (
  o: TAttributeValue | TAttributeValue[] | undefined
) => {
  return Array.isArray(o) ? o.map((a) => a.value).join(", ") : o?.value;
};

export const isPromotion = (
  orderInModule: (keyof IProductModuleElementsStyle | string)[],
  oldPrice: number | null,
  actualPrice: number | null
): (keyof IProductModuleElementsStyle | string)[] => {
  if (actualPrice === oldPrice) {
    return orderInModule.filter((el) => el !== "oldPrice");
  }
  return orderInModule;
};
