import { createPortal } from "react-dom";
import useIsMobilePortal from "../../hooks/useIsMobilePortal";
import { BackDropElement, Wrapper } from "./StyledDropdownElements";

export interface DropdownPortalProps {
  isOpen?: boolean;
  close?: () => void;
  onBackDropClick?: () => void;
  onBackDropMouseOver?: () => void;
  actionType?: "HOVER" | "REDIRECT" | "CLICK";
}

const DropdownPortal: React.FC<DropdownPortalProps> = ({
  children,
  isOpen,
  close,
  onBackDropMouseOver,
  onBackDropClick,
  actionType,
}) => {
  const isMobile = useIsMobilePortal();

  if (!isOpen) return null;

  const ModalDom = () => {
    if ((actionType !== "HOVER" && isMobile) || isMobile) {
      return (
        <Wrapper onMouseLeave={close} id="dropdown">
          <BackDropElement
            onClick={() => onBackDropClick && onBackDropClick()}
            onMouseOver={() => onBackDropMouseOver && onBackDropMouseOver()}
            data-testid="dropdown-backdrop"
            isMobile={isMobile}
          />
          {children}
        </Wrapper>
      );
    }

    return (
      <Wrapper
        onMouseLeave={close}
        id="dropdown"
        data-testid="dropdown-backdrop"
      >
        {children}
      </Wrapper>
    );
  };

  const target = document.body;
  return createPortal(ModalDom(), target);
};

export default DropdownPortal;
