import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../../global/components/ImageWrapper/ImageWrapper";
import { convertUrlToImage } from "../../../helpers/helpers";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import {
  TTitleIconSettings,
  TValueIconSettings,
} from "../BoxProductAttributes.types";

const StyledIconAttrValue = styled.div(
  ({
    imageSize,
    iconSettings,
  }: {
    imageSize: { width: number; height: number },
    iconSettings?: TTitleIconSettings | TValueIconSettings;
  }) => {
    const theme = useTheme() as IThemeState;
    return `
  ${
    iconSettings && iconSettings.iconUrl
      ? `
            mask: url(${iconSettings?.iconUrl}) no-repeat CENTER / contain;
            -webkit-mask: url(${
              iconSettings?.iconUrl
            }) no-repeat CENTER / contain;

            order: ${iconSettings?.iconPosition === "LEFT" ? 0 : 1};

            ${
              iconSettings.type === "SVG"
                ? `
                background-color: ${convertColorIdToHex(
                  iconSettings.iconMainColor,
                  theme.colorPalette
                )};
                &:hover{
                  background-color: ${convertColorIdToHex(
                    iconSettings.iconHoverColor,
                    theme.colorPalette
                  )};
                }
                  `
                : ""
            }
            
            ${
              iconSettings.iconSize.auto
                ? `
                    height: ${imageSize.height}px;
                    width: ${imageSize.width}px;
                  `
                : `
                    height: ${iconSettings.iconSize.height}px;
                    width: ${iconSettings.iconSize.height}px;
                  `
            }
        
      `
      : ""
  };
  `;
  }
);

const IconDisplay = ({
  actualIconSettings,
}: {
  actualIconSettings: TValueIconSettings | TTitleIconSettings | undefined;
}) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!actualIconSettings) return;
    convertUrlToImage(actualIconSettings.iconUrl).then((img) => {
      if (!img) return;
      setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
    });
  }, [actualIconSettings]);
  return actualIconSettings?.type === "SVG" ? (
    <StyledIconAttrValue
      role="icon"
      className={`product-attributes-container__attribute__value__icon ${
        actualIconSettings && "valueCode" in actualIconSettings
          ? actualIconSettings.valueCode
          : ""
      }`}
      iconSettings={actualIconSettings}
      imageSize={imageSize}
    />
  ) : (
    <div className="product-attributes-container__attribute__value__image">
      <ImageWrapper
        imageUrl={actualIconSettings?.iconUrl ?? ""}
        seoDescription={
          actualIconSettings && "valueCode" in actualIconSettings
            ? actualIconSettings.valueCode
            : ""
        }
        imageFit={false}
        imageAlignment={{
          horizontal: AlignmentHorizontalOption.CENTER,
          vertical: AlignmentVerticalOption.CENTER,
        }}
        height={actualIconSettings?.iconSize.height || imageSize.height}
        width={actualIconSettings?.iconSize.height || imageSize.width}
      />
    </div>
  );
};

export default IconDisplay;
