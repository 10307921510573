import { useMemo } from "react";
import { useTheme } from "styled-components";
import { PageCategoryType } from "@ecp-pageTypes";
import { searchResults } from "@ecp-redux/api/searchResults";
import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useCheckPageContext } from "../../../helpers/pageContext";
import { usePrepareSettings } from "../../../settingsPatterns/settingsPatterns.methods";
import {
  addBadgeFieldToObject,
  addBasePriceToObject,
  addDeliveryTimeFieldToObject,
  addGrossPriceToObject,
  addNetPriceToObject,
  addOldPriceToObject,
  addPoolFieldToObject,
  addPromotionPriceToObject,
  addRatingFieldToObject,
  addReviewsFieldToObject,
  addSkuToObject,
  addTaxRateToObject,
  getLabelsFromProductAttributes,
} from "../BoxProductAttributes.methods";
import {
  BOX_PRODUCT_ATTRIBUTES_CONTENT_SETTINGS,
  BOX_PRODUCT_ATTRIBUTES_DISPLAY_SETTINGS,
} from "../BoxProductAttributes.pattern";
import {
  AttributeIcon,
  BoxProductAttributesProps,
  ProductAttributeElement,
} from "../BoxProductAttributes.types";

export interface IProductExtendedAttribute {
  value?:
    | string
    | number
    | string[]
    | null
    | TAttributeValue
    | TAttributeValue[]
    | undefined;
  numberOfReviews?: number | null | undefined;
  attributeId: string;
  translation: string;
  activeLink?: boolean;
  attributeIcon?: AttributeIcon;
}

export const getProductPayload = (
  productSku: string,
  attributes: ProductAttributeElement[]
) => ({
  basicInfo: [
    "price",
    "rating",
    "deliveryTime",
    "badges",
    "pools",
    "url",
    "tax",
  ],
  attributes: attributes.map((selection) => selection.attributeId) ?? [],
  skus: [productSku],
});

const useBoxProductAttribute = (props: BoxProductAttributesProps) => {
  const {
    advanceSettings: { settings: globaSettings, messages: globalMessages },
  } = useTheme() as IThemeState;

  const pageContext = useCheckPageContext(PageCategoryType.PRODUCT);

  const contentSettings = usePrepareSettings({
    props: props.contentSettings,
    settingsPattern: BOX_PRODUCT_ATTRIBUTES_CONTENT_SETTINGS(),
  }).settings;

  const displaySettings = usePrepareSettings({
    props: props.displaySettings,
    settingsPattern: BOX_PRODUCT_ATTRIBUTES_DISPLAY_SETTINGS(),
  }).settings;

  const productSku =
    pageContext.type === PageCategoryType.PRODUCT
      ? pageContext.productSku
      : props.settings["product_attribute_product_id"];

  const { data: productAttributes } = searchResults.useGetPostProductsQuery(
    getProductPayload(productSku, props.content.selections),
    { skip: !productSku }
  );
  const currentProduct = productAttributes?.[0];

  //TODO refactor change add functions to hook useGetProductValueByKey
  const productExtendedAttributes: IProductExtendedAttribute[] | undefined =
    useMemo(
      () => {
        if (!currentProduct) return;

        return props.content.selections?.map((selection) => {
          return {
            ...selection,
            ...getLabelsFromProductAttributes(currentProduct, selection),
            ...addRatingFieldToObject(currentProduct, selection),
            ...addReviewsFieldToObject(currentProduct, selection),
            ...addDeliveryTimeFieldToObject(currentProduct, selection),
            ...addSkuToObject(currentProduct, selection),
            ...addPoolFieldToObject(currentProduct, selection),
            ...addBadgeFieldToObject(currentProduct, selection),
            ...addPromotionPriceToObject(
              currentProduct,
              selection,
              globalMessages["currencyShort"]
            ),
            ...addBasePriceToObject(
              currentProduct,
              selection,
              globalMessages["currencyShort"]
            ),
            ...addOldPriceToObject(
              currentProduct,
              selection,
              globalMessages["currencyShort"]
            ),
            ...addNetPriceToObject(
              currentProduct,
              selection,
              globalMessages["currencyShort"]
            ),
            ...addGrossPriceToObject(
              currentProduct,
              selection,
              globalMessages["currencyShort"]
            ),
            ...addTaxRateToObject(
              currentProduct,
              selection,
              globalMessages["taxShort"]
            ),
          };
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.content.selections, currentProduct]
    );

  return {
    contentSettings,
    displaySettings,
    productExtendedAttributes,
    searchRedirectUrl: globaSettings["searchRedirectUrl"],
  };
};

export default useBoxProductAttribute;
