import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import { IPopupProps } from "./Popup";

export const StyledPopup = styled.div<IPopupProps>(({ desktopWidth }) => {
  return `

        position: fixed;
        left: 50%;
        top: 50%;
        z-index: var(--z-index-popup));
        background: white;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.37);
        width: ${desktopWidth ?? "100%"};

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          width: 90%;
        }

        @media (max-width: 600px) {
          width: 95%;
        }

        .popup__close-button {
          position: absolute;
          right: 0.75rem;
          top: 0.75rem;

          &:hover {
            opacity: 0.4;
          }
        }
    `;
});

export const StyledPopupBackdrop = styled.div`
  background-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: var(--zindex-header);
  width: 100%;
  height: 100%;
`;
