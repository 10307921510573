import React from "react";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { getHrefFromLinkContent } from "../../helpers/linkHelpers";
import {
  BOX_BUTTON_CONTENT_PATTERN,
  BOX_BUTTON_CONTENT_SETTINGS_PATTERN,
} from "../../settingsPatterns/boxes/BoxButton/StyledButton";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { useStickyOffsetsContext } from "../../structure/Contexts/StickyOffsetsContext";
import { scrollIntoRowPosition } from "../BoxListOfButtons/BoxListOfButtons.methods";
import { StyledButtonAlignment } from "./BoxButton.styled";
import { IBoxButtonProps } from "./BoxButton.types";

const BoxButton: React.FC<IBoxButtonProps> = (props) => {
  const { offsetsData } = useStickyOffsetsContext();
  const boxSettings = usePrepareSettings({
    props: {
      ...props.contentSettings,
    },
    settingsPattern: BOX_BUTTON_CONTENT_SETTINGS_PATTERN(),
  }).settings;

  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_BUTTON_CONTENT_PATTERN(),
  }).settings;

  const isGoToPage =
    content.buttonLink.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE;
  const href = getHrefFromLinkContent(content.buttonLink);
  const handleClick = () => {
    scrollIntoRowPosition(content.buttonLink, props.path, offsetsData);
  };

  const button = (
    <StyledButton
      $settings={boxSettings.buttonStyle}
      className="button-container"
      onClick={!isGoToPage ? handleClick : undefined}
      renderAs="button"
    >
      <i />
      {content.buttonLabel}
    </StyledButton>
  );

  return (
    <StyledButtonAlignment
      alignment={boxSettings.buttonAlignment}
      className="button-container__alignment"
    >
      <ConditionalWrapper
        condition={isGoToPage}
        wrapper={(children) => (
          <LinkWrapper
            href={href}
            openInNewTab={content.buttonLink.goToPageSetting?.openInNewTab}
          >
            {children}
          </LinkWrapper>
        )}
      >
        {button}
      </ConditionalWrapper>
    </StyledButtonAlignment>
  );
};

export default BoxButton;
