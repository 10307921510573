import styled from "styled-components";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSummaryB2BMessages,
  IBoxSummaryB2BSettings,
} from "../BoxSummaryB2B.types";

const StyledSummaryValue = styled.div`
  width: 50%;
  .summary-b2b-order__values__label {
    margin-bottom: 3px;
  }
`;

interface ISummaryValue {
  headerText: string;
  value: string;
}

export const SummaryValue: React.FC<ISummaryValue> = (props) => {
  const { headerText, value } = props;

  const {
    settings: {
      basket_success_label_typo,
      basket_success_label_color,
      basket_success_text_typo,
      basket_success_text_color,
    },
  } = useMessagesSettingsContext<
    IBoxSummaryB2BMessages,
    IBoxSummaryB2BSettings
  >();

  return (
    <StyledSummaryValue className="summary-b2b-order__values">
      <StyledText
        className="summary-b2b-order__values__label"
        $settings={{
          font: basket_success_label_typo,
          text: {
            color: basket_success_label_color,
          },
        }}
      >
        {headerText}
      </StyledText>
      <StyledText
        className="summary-b2b-order__values__value"
        $settings={{
          font: basket_success_text_typo,
          text: {
            color: basket_success_text_color,
          },
        }}
      >
        {value}
      </StyledText>
    </StyledSummaryValue>
  );
};
