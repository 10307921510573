import styled from "styled-components";
import { ImagePlacement } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../composeCss";
import { SelectedGoToOption } from "../../contentPatterns.types";
import {
  IMAGE_INLINE_CONTENT_SETTING_PATTERN,
  TEXT_STYLE_SHOW_SETTING_PATTERN,
  TITLE_CONTENT_SETTING_PATTERN,
} from "../../settingsPatterns.utils";
import { EDITOR_INITIAL_VALUE } from "../BoxText/StyledText";
import {
  IBoxRichTextContent,
  IBoxRichTextContentSettings,
  IBoxRichTextDisplaySettings,
  IBoxRichTextSettings,
} from "./StyledRichText.types";

export const BOX_RICH_TEXT_CONTENT_SETTINGS_PATTERN =
  (): IBoxRichTextContentSettings => ({
    title: TITLE_CONTENT_SETTING_PATTERN(),
    description: TEXT_STYLE_SHOW_SETTING_PATTERN(),
    imageInline: IMAGE_INLINE_CONTENT_SETTING_PATTERN(),
    contentAlignment: AlignmentHorizontalOption.LEFT,
    imageAlignment: ImagePlacement.TOP,
  });

export const BOX_RICH_TEXT_DISPLAY_SETTINGS_PATTERN =
  (): IBoxRichTextDisplaySettings => ({
    gap: 20,
  });

export const BOX_RICH_TEXT_CONTENT_PATTERN = (): IBoxRichTextContent => ({
  title: JSON.stringify(EDITOR_INITIAL_VALUE),
  description: JSON.stringify(EDITOR_INITIAL_VALUE),
  imageInline: { imageUrl: "" },
  richTextUrl: {
    showLinkOptions: true,
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    goToRowSetting: {
      rowId: null,
    },
    goToPageSetting: {
      link: "",
      openInNewTab: true,
    },
  },
  seoDescription: "",
});

export const BOX_RICH_TEXT_PATTERN = () => ({
  ...BOX_RICH_TEXT_CONTENT_SETTINGS_PATTERN(),
  ...BOX_RICH_TEXT_DISPLAY_SETTINGS_PATTERN(),
});

export const generateBoxRichTextStyles = ({
  $settings,
  $imageWidth,
  $content,
}: {
  $settings: IBoxRichTextSettings["$settings"];
  $imageWidth: number | undefined;
  $content: IBoxRichTextContent;
}) => {
  const isRow =
    $settings.imageAlignment === ImagePlacement.LEFT ||
    $settings.imageAlignment === ImagePlacement.RIGHT;
  const isLast =
    $settings.imageAlignment === ImagePlacement.RIGHT ||
    $settings.imageAlignment === ImagePlacement.BOTTOM;

  const cssStyles = `
  display: flex;
  flex-direction: ${isRow ? "row" : "column"};
  align-items: ${
    isRow ? "initial" : alignmentOptionsToValue($settings.contentAlignment)
  };
  justify-content: ${
    isRow ? alignmentOptionsToValue($settings.contentAlignment) : "initial"
  };
  gap: ${$settings.gap}px;

  .rich-text-container {
    &__image-container {
      order: ${isLast ? "2" : "initial"};
      width: ${$imageWidth}px;
    }

    &__content-container {
      overflow: hidden;
      width: ${
        isRow && $imageWidth && $content.imageInline.imageUrl?.length
          ? `calc(100% - ${$imageWidth + $settings.gap}px);`
          : "100%;"
      };
    }
  }
  `;

  return cssStyles;
};

export const StyledBoxRichText = styled.div(
  (props: {
    $settings: IBoxRichTextSettings["$settings"];
    $imageWidth: number | undefined;
    $content: IBoxRichTextContent;
  }) => generateBoxRichTextStyles(props)
);
