import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxAccordionContent,
  IBoxAccordionContentSettings,
  IBoxAccordionDisplaySettings,
} from "./BoxAccordion.types";
import { nanoid } from "nanoid";


export const BOX_ACCORDION_CONTENT = (): IBoxAccordionContent => ({
  accordionsList: [
          {
            id: nanoid(),
            headerText: "Akordion header 1",
            boxesToShow: [],
          },
          {
            id: nanoid(),
            headerText: "Akordion header 2",
            boxesToShow: [],
          },
  ],
});

export const BOX_ACCORDION_CONTENT_SETTINGS =
  (): IBoxAccordionContentSettings => ({});

export const BOX_ACCORDION_DISPLAY_SETTINGS =
  (): IBoxAccordionDisplaySettings => ({
    accordionStyle: "accordion$$1",
    show: {
      type: "NONE",
      accordionId: "",
    },
    showOneAtATime: true,
    spaceBetweenAccordions: 5,
    spaceBetweenAccordionAndContent: 5,
    maxHeight: 1000,
    contentAlignment: AlignmentHorizontalOption.LEFT,
  });
