import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";
import { IAdvanceMessages } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  ProductAttributeElement,
  SrpInfoProductFields,
} from "../BoxProductAttributes.types";

export const parseDeliveryCodeToText = (
  deliveryTimeCode: string,
  globalMessages: IAdvanceMessages
) => {
  const globalMessagesDeliveryKey = Object.keys(globalMessages).find((key) =>
    key.includes(deliveryTimeCode)
  ) as keyof IAdvanceMessages;

  return globalMessages[
    globalMessagesDeliveryKey ?? globalMessages.delivery_unknown
  ];
};

export const getAttributeValue = (
  attribute: ProductAttributeElement,
  globalMessages: IAdvanceMessages
) => {
  switch (attribute.attributeId) {
    case SrpInfoProductFields.deliveryTime:
      return (
        typeof attribute.value === "string" &&
        parseDeliveryCodeToText(attribute.value, globalMessages)
      );

    default:
      return attribute.value;
  }
};

export const getPrimitiveAttrValue = (
  attrib:
    | string
    | number
    | false
    | string[]
    | TAttributeValue
    | TAttributeValue[],
  index: number
): string | number | false => {
  if (
    Array.isArray(attrib) &&
    attrib.length > 0 &&
    attrib[index] !== undefined &&
    typeof attrib === "string"
  ) {
    return attrib[index];
  } else if (
    Array.isArray(attrib) &&
    attrib.length > 0 &&
    attrib[index] !== undefined &&
    typeof attrib[index] === "object"
  ) {
    return (attrib as TAttributeValue[])[index].value;
  } else if (typeof attrib === "object" && !Array.isArray(attrib)) {
    return attrib.value;
  } else {
    return attrib as string | number | false;
  }
};

export const getValueOrLabel = (v: string | TAttributeValue): string | null =>
  typeof v === "string" ? v : v.label;
