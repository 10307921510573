import React from "react";
import { IIcon } from "./Icon.types";

const ArrowDownIcon: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
  className,
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      onClick={onClick}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L7 7L13 1"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ArrowDownIcon.defaultProps = {
  width: "14",
  height: "8",
  fill: "#293740",
  viewBox: "0 0 14 8",
};

export default ArrowDownIcon;
