import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { PageCategoryType } from "@ecp-pageTypes";
import * as searchResults from "@ecp-redux/api/searchResults";
import { FacetCatDto, FacetParam } from "@ecp-redux/dto/searchResults.types";
import {
  ProductCategoryContext,
  categoryCodeForSearch,
  useCheckPageContext,
  useSearchQueries,
} from "../../helpers/pageContext";
import {
  chooseOnlyFacets,
  convertHandyQueryToParsedQuery,
  convertParsedQueryToHandyQuery,
  isValueInFilters,
} from "./BoxSearchFilters.methods";
import {
  AttributeValueViewModel,
  FacetVM,
  IBoxSearchFiltersContent,
  SelectedFacet,
} from "./BoxSearchFilters.types";

const isCategory = (
  param: FacetCatDto<FacetParam>
): param is FacetCatDto<FacetParam.TREE> => param.type === FacetParam.TREE;

export const useSearchFiltersLogic = (content: IBoxSearchFiltersContent) => {
  const router = useRouter();
  const { searchQueries } = useSearchQueries();
  const categoryContext = useCheckPageContext<ProductCategoryContext>(
    PageCategoryType.PRODUCT_CATEGORY
  );

  const productCategoryCode = useMemo(
    () =>
      categoryContext.type === "CONTEXT_ERROR" ||
      categoryContext.productCategoryCode === categoryCodeForSearch
        ? ""
        : categoryContext.productCategoryCode,
    [categoryContext]
  );

  const { data } = searchResults.useGetSearchResultsQuery({
    query: searchQueries,
    strictCategory: productCategoryCode,
  });

  const facetsToShow = useMemo(
    () =>
      content.facetsSelection.reduce<FacetVM[]>(
        (result: FacetVM[], facet: SelectedFacet) => {
          const choosenAttribute =
            data &&
            data.parameters &&
            chooseOnlyFacets(data.parameters).find(
              (attrib) => attrib.name === facet.attributeId
            );
          if (choosenAttribute) {
            const valuesWithChecked: AttributeValueViewModel[] =
              choosenAttribute.values.map((v) =>
                isValueInFilters(facet.attributeId, v.name, router.query)
                  ? { ...v, checked: true }
                  : { ...v, checked: false }
              );
            result.push({ ...facet, values: valuesWithChecked });
          }
          return result;
        },
        []
      ),
    [data?.parameters, content.facetsSelection, router.query]
  );

  const changeCheckbox = useCallback(
    (checked: boolean, facetId: string, value: string) => {
      const newQuery = { ...router.query };
      const convertedQuery = convertParsedQueryToHandyQuery(newQuery);

      if (checked) {
        convertedQuery[facetId] = convertedQuery[facetId].filter(
          (v) => v !== value
        );
      } else {
        if (convertedQuery[facetId]) {
          convertedQuery[facetId].push(value);
        } else {
          convertedQuery[facetId] = [value];
        }
      }

      const parsedQuery = convertHandyQueryToParsedQuery(
        convertedQuery,
        newQuery
      );

      delete parsedQuery["page"];
      delete parsedQuery["pageSize"];

      router.push({ href: "/", query: parsedQuery }, undefined, {
        shallow: true,
      });
    },
    [router.query]
  );

  const categories = useMemo(
    () =>
      data?.parameters?.filter((param) => isCategory(param)).sort() as
        | FacetCatDto<FacetParam.TREE>[]
        | undefined,
    [data?.parameters]
  );

  const childCategory = useCallback(() => {
    if (categories) {
      if (categoryContext.type === "CONTEXT_ERROR") {
        return categories[0];
      }
      const { productCategoryCode, productCategoryLevel } = categoryContext;
      if (productCategoryCode === categoryCodeForSearch) {
        return categories[0];
      }
      if (
        categories.length >= (productCategoryLevel as number) + 1 &&
        categories[productCategoryLevel as number].values.length > 0
      ) {
        return categories[productCategoryLevel as number];
      }
      return categories[(productCategoryLevel as number) - 1];
    } else return;
  }, [categories, categoryContext]);

  const deleteFiltersFromUrl = useCallback(() => {
    const newQuery = { ...router.query };

    if (!newQuery["filter"]) return;
    delete newQuery["filter"];

    router.push({ href: "/", query: newQuery }, undefined, {
      shallow: true,
    });
  }, [router.query]);

  return {
    changeCheckbox,
    facetsToShow,
    hasCategories: !!categories && categories.length > 0,
    childCategory,
    deleteFiltersFromUrl,
  };
};
