import React from "react";
import * as myAddressList from "@ecp-redux/api/myAddressList";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import Popup from "../../../shared/components/Popup/Popup";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountMyAddressListMessages,
  IBoxMyAccountMyAddressListSettings,
} from "../BoxMyAccountMyAddressList.types";
import { StyledConfirmDeleteAddressPopup } from "./StyledConfirmDeleteAddressPopup.styled";

interface IConfirmDeleteAddressPopupProps {
  closePopup: () => void;
  addressId: number;
}

const ConfirmDeleteAddressPopup: React.FC<IConfirmDeleteAddressPopupProps> = ({
  closePopup,
  addressId,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyAddressListMessages,
    IBoxMyAccountMyAddressListSettings
  >();
  const [deleteAddress] = myAddressList.useDeleteCustomerAddressMutation();

  return (
    <Popup
      desktopWidth={"600px"}
      className="delete-address-popup"
      closePopup={closePopup}
    >
      <StyledConfirmDeleteAddressPopup>
        <StyledText
          className="delete-address-popup__title"
          $settings={{
            font: settings.myaccount_address_list_popup_title_typo,
            text: { color: settings.myaccount_address_list_popup_title_color },
          }}
          show={isNotEmptyMessage(
            messages.myaccount_address_list_addresses_popup_title
          )}
        >
          {messages.myaccount_address_list_addresses_popup_title}
        </StyledText>
        <StyledText
          className="delete-address-popup__description"
          $settings={{
            font: settings.myaccount_address_list_popup_description_typo,
            text: {
              color: settings.myaccount_address_list_popup_description_color,
            },
          }}
          show={isNotEmptyMessage(
            messages.myaccount_address_list_addresses_popup_description
          )}
        >
          {messages.myaccount_address_list_addresses_popup_description}
        </StyledText>
        <div className="delete-address-popup__button-container">
          <StyledButton
            className="delete-address-popup__button-container__save-button"
            $settings={settings.myaccount_address_list_popup_yes_button}
            renderAs={"button"}
            data-testid="yesPopupBtn"
            onClick={() => {
              deleteAddress(addressId);
              closePopup();
            }}
            show={isNotEmptyMessage(
              messages.myaccount_address_list_addresses_popup_button_save
            )}
          >
            {messages.myaccount_address_list_addresses_popup_button_save}
          </StyledButton>
          <StyledButton
            className="delete-address-popup__button-container__cancel-button"
            $settings={settings.myaccount_address_list_popup_no_button}
            renderAs={"button"}
            data-testid="noPopupBtn"
            onClick={() => closePopup()}
            show={isNotEmptyMessage(
              messages.myaccount_address_list_addresses_popup_button_cancel
            )}
          >
            {messages.myaccount_address_list_addresses_popup_button_cancel}
          </StyledButton>
        </div>
      </StyledConfirmDeleteAddressPopup>
    </Popup>
  );
};

export default ConfirmDeleteAddressPopup;
