import React from "react";
import { useRouter } from "next/router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormProvider, useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import * as yup from "yup";
import * as auth from "@ecp-redux/api/auth";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  IThemeState,
  SettingActive,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isB2BClient } from "@ecp-redux/helpers";
import { yupResolver } from "@hookform/resolvers/yup";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import htmlToReact from "../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { isEmail, isPassword } from "../../../helpers/validators";
import {
  useAddAlert,
  useAlertByCodes,
} from "../../../redux/slices/alertsSlice";
import {
  InputPassword,
  InputText,
} from "../../../shared/components/Input/Inputs";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  EXECUTE_CAPTCHA_ERROR,
  IBoxLoginMessages,
  IBoxLoginSettings,
  LoginFormErrorCodes,
} from "../BoxLogin.types";
import { StyledLinkContainer } from "./../BoxLogin.styled";

interface IUserLoginForm {
  username: string;
  password: string;
}

const loginDefaultValues: IUserLoginForm = {
  username: "",
  password: "",
};

const alertMessages = {
  "pa-001": "login_form_error",
  "pa-002": "login_form_error_not_actived",
  "pa-004": "login_execute_recaptcha_error",
  "pa-006": "login_form_account_blocked",
  "pa-008": "login_form_account_blocked",
} as const;

interface LoginFormProps {
  clickShoppingWitoutRegister: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  clickShoppingWitoutRegister,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxLoginMessages,
    IBoxLoginSettings
  >();
  const router = useRouter();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const isCartLogin = router?.query?.["cart"] ?? false;
  const { addAlert } = useAddAlert();
  const [postLogin] = auth.usePostLoginMutation();
  const [assignCart] = cartAPI.usePostAssignCartMutation();
  const { googleRecaptchaSiteKey } = (useTheme() as IThemeState).advanceSettings
    .settings;

  const { PA001, PA002, PA004, PA006, PA008 } = LoginFormErrorCodes;
  const { closeAlert } = useAlertByCodes([
    PA001,
    PA002,
    PA004,
    PA006,
    PA008,
    EXECUTE_CAPTCHA_ERROR,
  ]);

  const validationSchema = yup.object().shape({
    username: isEmail(
      messages.login_form_empty_verification_email,
      messages.login_form_syntax_verification_email
    ),
    password: isPassword(
      messages.login_form_empty_verification_password,
      messages.login_form_syntax_verification_password
    ),
  });

  const formOptions = useForm<IUserLoginForm>({
    defaultValues: loginDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
  });
  const { handleSubmit, control } = formOptions;

  const onSubmit = async (data: IUserLoginForm) => {
    closeAlert && closeAlert();
    let captchaToken = null;
    if (googleRecaptchaSiteKey !== "") {
      if (!executeRecaptcha) {
        addAlert({
          code: EXECUTE_CAPTCHA_ERROR,
          message: messages.login_execute_recaptcha_error,
        });
        return;
      }
      captchaToken = await executeRecaptcha("login_submit");
    }
    const formDataWithOptionalCaptcha = {
      ...data,
      ...(captchaToken !== null && { captcha: captchaToken }),
    };
    postLogin(formDataWithOptionalCaptcha)
      .unwrap()
      .then(async () => {
        !isB2BClient() && (await assignCart());
        if (isCartLogin) {
          router.replace(settings.shopping_step_2_url);
        } else {
          router.replace(settings.login_form_redirect_after_login);
        }
      })
      .catch((res) => {
        const code = res?.data?.code as LoginFormErrorCodes;
        if (code) {
          closeAlert && closeAlert();
          addAlert({
            code: code,
            message: messages[alertMessages[code]],
          });
        }
      });
  };

  return (
    <FormProvider {...formOptions}>
      <form
        onSubmit={handleSubmit((d) => onSubmit(d))}
        className="login-form-container"
      >
        <InputText
          name="username"
          control={control}
          placeholder={messages.login_form_placeholder_email}
          label={messages.login_form_heading_email}
          $settings={settings.login_form_input_style}
          className="login-form-container__username"
        />
        <InputPassword
          name="password"
          control={control}
          placeholder={messages.login_form_placeholder_password}
          label={messages.login_form_heading_password}
          $settings={settings.login_form_input_style}
          className="login-form-container__password"
        />
        <StyledLinkContainer
          className="login-form-container__privacy-policy"
          remindPasswordPosition={settings.login_form_remind_password_position}
        >
          {settings.login_form_remind_password_active === SettingActive.ON && (
            <div className="login-form-container__privacy-policy__remind-password">
              <LinkWrapper
                linkStyle={settings.login_form_link_style}
                fontStyle={settings.login_form_redirect_remind_password_typo}
                href={settings.login_form_redirect_remind_password}
              >
                {messages.login_form_remind_password}
              </LinkWrapper>
            </div>
          )}
          <div className="login-form-container__privacy-policy__link">
            <StyledText
              $settings={{
                font: settings.login_form_privacy_policy_typo,
                text: { color: settings.login_form_privacy_policy_color },
              }}
              show={isNotEmptyMessage(messages.login_form_privacy_policy)}
            >
              <p>
                {htmlToReact(
                  messages.login_form_privacy_policy,
                  settings.login_form_link_style
                )}
              </p>
            </StyledText>
          </div>
        </StyledLinkContainer>

        <StyledButton
          renderAs="button"
          type="submit"
          role="submit"
          $settings={settings.login_form_login_button_style}
          className="login-form-container__submit-button"
          data-testid="loginForm_submit"
          show={isNotEmptyMessage(messages.login_form_save_button)}
        >
          {messages.login_form_save_button}
        </StyledButton>
      </form>

      <StyledButton
        renderAs="button"
        $settings={settings.login_form_redirect_button_style}
        onClick={() => router.push(settings.login_form_redirect_after_button_2)}
        className="login-form-container__redirect-button"
        show={
          isNotEmptyMessage(messages.login_form_redirect_button) &&
          settings.login_form_button_2_active === SettingActive.ON
        }
      >
        {messages.login_form_redirect_button}
      </StyledButton>

      <StyledButton
        renderAs="button"
        $settings={settings.shopping_without_register}
        onClick={clickShoppingWitoutRegister}
        data-testid="ShoppingWithoutRegistrationBtn"
        className="login-form-container__shopping-without-registration-button"
        show={
          isNotEmptyMessage(messages.login_form_shopping_without_register) &&
          isCartLogin
        }
      >
        {messages.login_form_shopping_without_register}
      </StyledButton>
    </FormProvider>
  );
};

export default LoginForm;
