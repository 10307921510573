import styled from "styled-components";
import { HoverEffects } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, { chooseHorizontalAlignValue } from "../../composeCss";
import { SelectedGoToOption } from "../../contentPatterns.types";
import { convertColorIdToHex } from "../../settingsPatterns.methods";
import {
  IBoxImageContent,
  IBoxImageContentSettings,
  IBoxImageDisplaySettings,
  IBoxImageSettings,
} from "./StyledImage.types";

export const BOX_IMAGE_DISPLAY_PATTERN = (): IBoxImageDisplaySettings => ({
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  margin: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const BOX_IMAGE_CONTENT_SETTINGS_PATTERN =
  (): IBoxImageContentSettings => ({
    imageSize: { auto: true, height: null },
    imageOpacity: 100,
    imagePlacement: false,
    imageColor: {
      color: { id: "color$$1", custom: "#000000" },
      hoverColor: { id: "color$$1", custom: "#000000" },
    },
    contentAlignment: AlignmentHorizontalOption.CENTER,
    imageHover: HoverEffects.NONE,
  });

export const BOX_IMAGE_CONTENT_PATTERN = (): IBoxImageContent => ({
  imageUrl: "",
  imageLink: {
    showLinkOptions: true,
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    goToRowSetting: {
      rowId: null,
    },
    goToPageSetting: {
      link: "",
      openInNewTab: false,
    },
  },
  seoDescription: "",
  openInTab: false,
});

export const BOX_IMAGE_PATTERN = () => ({
  ...BOX_IMAGE_DISPLAY_PATTERN(),
  ...BOX_IMAGE_CONTENT_SETTINGS_PATTERN(),
});

export const generateBoxImageStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxImageSettings["$settings"];
  theme: IBoxImageSettings["theme"];
}) => {
  const cssStyles = `
    position: relative;
    display: flex;
    padding: ${composeCss.padding($settings.padding)};
    margin: ${composeCss.margin($settings.margin)};
    justify-content: ${chooseHorizontalAlignValue($settings.contentAlignment)};

    .svg {
      background-color: ${convertColorIdToHex(
        $settings.imageColor.color,
        theme.colorPalette
      )};
      
      width: 100%;
      height: ${$settings.imageSize.height}px;
      mask: url(${$settings.imageUrl}) no-repeat ${
    $settings.contentAlignment
  } / contain;
      -webkit-mask: url(${$settings.imageUrl}) no-repeat ${
    $settings.contentAlignment
  } / contain;
      object-fit: ${$settings.imagePlacement ? "contain" : "cover"};
      opacity: ${$settings.imageOpacity / 100};
      ${composeCss.hoverEffect($settings.imageHover)}

      &:hover{
        background-color: ${convertColorIdToHex(
          $settings.imageColor.hoverColor,
          theme.colorPalette
        )};
      }
    }

    .imageContainer {
      overflow: hidden;
      ${composeCss.hoverEffect($settings.imageHover)}

      span {
        height: ${$settings.imageSize.height}px !important;
      }

      width: 100%;
    }
  `;

  return cssStyles;
};

export const StyledBoxImage = styled.div<IBoxImageSettings>((props) =>
  generateBoxImageStyles(props)
);

export const StyledHrefWrapper = styled.div`
  .box-image-href {
    height: 100%;
    position: relative;
    width: 100%;
    flex-shrink: 1;
  }
`;
