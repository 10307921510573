import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxRegisterMessagesScheme = z.object({
  register_form_diffrent_verification_password:
    themeScheme.MessageScheme.default("Passwords must match"),
  register_form_email_consent:
    themeScheme.MessageScheme.default("Email consent"),
  register_form_empty_verification_email:
    themeScheme.MessageScheme.default("Email is required"),
  register_form_empty_verification_password: themeScheme.MessageScheme.default(
    "Password is required"
  ),
  register_form_empty_verification_repeat_password:
    themeScheme.MessageScheme.default("Confirm password is required"),
  register_form_empty_phone_verification:
    themeScheme.MessageScheme.default("Phone is required"),
  register_form_error: themeScheme.MessageScheme.default("Register form error"),
  register_form_heading_email: themeScheme.MessageScheme.default("Email"),
  register_form_heading_name: themeScheme.MessageScheme.default("Name"),
  register_form_heading_password: themeScheme.MessageScheme.default("Password"),
  register_form_heading_phone: themeScheme.MessageScheme.default("Phone"),
  register_form_heading_repeat_password:
    themeScheme.MessageScheme.default("Repeat password"),
  register_form_heading_surname: themeScheme.MessageScheme.default("Surname"),
  register_form_login: themeScheme.MessageScheme.default("Log in"),
  register_form_placeholder_email: themeScheme.MessageScheme.default("Email"),
  register_form_placeholder_name: themeScheme.MessageScheme.default("Name"),
  register_form_placeholder_password:
    themeScheme.MessageScheme.default("Password"),
  register_form_placeholder_phone: themeScheme.MessageScheme.default("Phone"),
  register_form_placeholder_repeat_password:
    themeScheme.MessageScheme.default("Repeat password"),
  register_form_placeholder_surname:
    themeScheme.MessageScheme.default("Surname"),
  register_form_profile_consent:
    themeScheme.MessageScheme.default("Profile consent"),
  register_form_regulations: themeScheme.MessageScheme.default("Regulations"),
  register_form_required_verification_consent:
    themeScheme.MessageScheme.default("Consent is required"),
  register_form_save_button:
    themeScheme.MessageScheme.default("Create account"),
  register_form_success: themeScheme.MessageScheme.default(
    "A link to activate the account was sent to the indicated email address"
  ),
  register_form_activated_error: themeScheme.MessageScheme.default(
    "Customer has not yet been activated!"
  ),
  register_form_syntax_verification_email:
    themeScheme.MessageScheme.default("Email is invalid"),
  register_form_syntax_verification_password: themeScheme.MessageScheme.default(
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  ),
  register_form_terms_link: themeScheme.MessageScheme.default("link"),
  register_form_tick_all_consents:
    themeScheme.MessageScheme.default("Check all"),
  register_form_title: themeScheme.MessageScheme.default("Register"),
  register_form_verification_phone:
    themeScheme.MessageScheme.default("Phone is invalid"),
  register_form_placeholder_company_name:
    themeScheme.MessageScheme.default("Company name"),
  register_form_heading_company_name:
    themeScheme.MessageScheme.default("Company name"),
  register_form_syntax_verification_zip_code:
    themeScheme.MessageScheme.default("Wrong zip code"),
  register_form_placeholder_nip: themeScheme.MessageScheme.default("TAX ID"),
  register_form_heading_nip: themeScheme.MessageScheme.default("Tax ID"),
  register_form_input_wrong_nip_message:
    themeScheme.MessageScheme.default("Wrong tax number"),
  register_form_empty_verification_inputs:
    themeScheme.MessageScheme.default("Please type here"),
  register_form_placeholder_street: themeScheme.MessageScheme.default("Street"),
  register_form_placeholder_heading_street:
    themeScheme.MessageScheme.default("Street"),
  register_form_placeholder_house: themeScheme.MessageScheme.default("House"),
  register_form_heading_house: themeScheme.MessageScheme.default("House"),
  register_form_placeholder_flat: themeScheme.MessageScheme.default("Flat"),
  register_form_heading_flat: themeScheme.MessageScheme.default("Flat"),
  register_form_placeholder_zip_code:
    themeScheme.MessageScheme.default("Zip code"),
  register_form_heading_zip_code: themeScheme.MessageScheme.default("Zip code"),
  register_form_placeholder_city: themeScheme.MessageScheme.default("City"),
  register_form_heading_city: themeScheme.MessageScheme.default("City"),
  register_form_placeholder_country:
    themeScheme.MessageScheme.default("Country"),
  register_form_heading_country: themeScheme.MessageScheme.default("Country"),
  register_form_empty_name_surname_verification:
    themeScheme.MessageScheme.default("This field is required"),
});

export const IBoxRegisterSettingsScheme = z.object({
  register_form_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  register_form_checkbox_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  register_form_checkbox_error_color:
    themeScheme.ColorIdScheme.default("color$$12").catch("color$$12"),
  register_form_checkbox_error_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  register_form_checkbox_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  register_form_checkboxes_gap:
    themeScheme.SizeCSSScheme.default("15px").catch("15px"),
  register_form_elements_gap:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  register_form_input_style:
    themeScheme.InputIdScheme.default("input$$1").catch("input$$1"),
  register_form_input_name_and_surname_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  register_form_link_style:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  register_form_redirect_login:
    themeScheme.UrlScheme.default("/login").catch("/login"),
  register_form_submit_button_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  register_form_terms_link: themeScheme.UrlScheme.default(
    "https://www.lipsum.com/"
  ).catch("https://www.lipsum.com/"),
  register_form_terms_link_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  register_form_title_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  register_form_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  register_form_title_spacing:
    themeScheme.SizeCSSScheme.default("30px").catch("30px"),
  register_form_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  register_form_success_info_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  register_form_success_info_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  register_form_redirect_login_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  register_form_wrapper_background_color:
    themeScheme.ColorIdScheme.default("color$$9").catch("color$$9"),
  register_form_link_align: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  register_form_wrapper_margin: themeScheme.MarginSizeScheme.default(
    "30px 30px 30px 30px"
  ).catch("30px 30px 30px 30px"),
  register_form_checkbox_link_typo:
    themeScheme.LinkIdScheme.default("link$$1").catch("link$$1"),
  register_form_customer_type:
    themeScheme.MessageScheme.default("B2C").catch("B2C"),
  register_form_error_background_color:
    themeScheme.ColorIdScheme.default("color$$10").catch("color$$10"),
  register_form_error_info_typo:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  register_form_error_info_text_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
});
