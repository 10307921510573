import React, { memo } from "react";
import Link from "next/link";
import { isEqual } from "lodash";
import styled from "styled-components";
import { AlignmentVerticalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { getImageNameFromUrl, isPortalSide } from "../../helpers/helpers";
import {
  getHrefFromLinkContent,
  getOpenInNewTabFromLinkContent,
} from "../../helpers/linkHelpers";
import {
  BOX_IMAGE_CONTENT_PATTERN,
  BOX_IMAGE_PATTERN,
  StyledBoxImage,
} from "../../settingsPatterns/boxes/BoxImage/StyledImage";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { useStickyOffsetsContext } from "../../structure/Contexts/StickyOffsetsContext";
import { scrollIntoRowPosition } from "../BoxListOfButtons/BoxListOfButtons.methods";
import { IBoxImageProps } from "./BoxImage.types";

const StyledHrefWrapper = styled.a`
  height: 100%;
  position: relative;
  width: 100%;
  flex-shrink: 1;
  cursor: pointer;
`;

const BoxImage: React.FC<IBoxImageProps> = (props) => {
  const { offsetsData } = useStickyOffsetsContext();
  const [imgWrapper, imageWidth] = useImageSize();

  const { settings } = usePrepareSettings({
    props: {
      ...props.displaySettings,
      ...props.contentSettings,
      ...props.content,
    },
    settingsPattern: BOX_IMAGE_PATTERN(),
  });

  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_IMAGE_CONTENT_PATTERN(),
    options: {
      includeTheme: true,
      themePath: "advanceSettings.settings.backgroundUrl",
      themeWrapperPath: "imageUrl",
    },
  }).settings;

  const imageLinkHref = getHrefFromLinkContent(content.imageLink);

  const hrefWrapper = (children: React.ReactNode) =>
    content.imageLink.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE ? (
      <Link href={getHrefFromLinkContent(content.imageLink)} passHref>
        <StyledHrefWrapper
          target={
            getOpenInNewTabFromLinkContent(content.imageLink)
              ? "_blank"
              : "_parent"
          }
        >
          {children}
        </StyledHrefWrapper>
      </Link>
    ) : (
      <StyledHrefWrapper
        href={undefined}
        onClick={() =>
          scrollIntoRowPosition(content.imageLink, props.path, offsetsData)
        }
      >
        {children}
      </StyledHrefWrapper>
    );

  const isSvg = content.imageUrl.endsWith(".svg");

  if (content.imageUrl === "" && !isPortalSide()) {
    return <div>No content to display</div>;
  }

  return (
    <StyledBoxImage
      ref={imgWrapper}
      $settings={settings}
      data-testid="BoxImage"
      className="image-container"
    >
      <ConditionalWrapper
        wrapper={hrefWrapper}
        condition={!!imageLinkHref && imageLinkHref !== ""}
      >
        {isSvg ? (
          <div
            role="icon"
            aria-label={
              content.seoDescription
                ? content.seoDescription
                : getImageNameFromUrl(content.imageUrl)
            }
            className="svg image-container__svg"
          />
        ) : content.imageUrl !== "" && settings.imageSize.height !== 0 ? (
          <div className="imageContainer image-container__image">
            <ImageWrapper
              imageUrl={content.imageUrl}
              seoDescription={content.seoDescription}
              imageFit={!settings.imagePlacement}
              imageAlignment={{
                horizontal: settings.contentAlignment,
                vertical: AlignmentVerticalOption.CENTER,
              }}
              opacity={settings.imageOpacity}
              width={imageWidth}
              height={settings.imageSize.height ?? 0}
            />
          </div>
        ) : (
          <div></div>
        )}
      </ConditionalWrapper>
    </StyledBoxImage>
  );
};

export default memo(BoxImage, isEqual);
