import { useMemo } from "react";
import { useRouter } from "next/router";
import {
  IBoxSearchResultsB2BMessages,
  SortDirection,
  SortOption,
  SortType,
} from "../../../../../boxes/BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import { capitalizeFirstLetter } from "../../../../../helpers/helpers";

export interface SortB2BProps {
  closeSorting: () => void;
}

export const useSortB2B = () => {
  const router = useRouter();

  const sortOptions: SortOption[] = [
    { type: SortType.RECOMMENDED, direction: SortDirection.NONE },

    { type: SortType.LATEST, direction: SortDirection.NONE },

    {
      type: SortType.PRICE,
      direction: SortDirection.ASC,
    },
    {
      type: SortType.PRICE,
      direction: SortDirection.DESC,
    },
  ];

  const setPageSize = (pageSize?: number, sort?: SortOption) => {
    const newSortQuery =
      sort !== undefined
        ? sort.direction === SortDirection.ASC ||
          sort.direction === SortDirection.NONE
          ? sort.type
          : `-${sort.type}`
        : undefined;

    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...{ page: 1 },
          ...(pageSize ? { pageSize: pageSize } : {}),
          ...(sort ? { sort: newSortQuery } : {}),
        },
      },
      undefined,
      { shallow: true }
    );
  };

  const choseB2BSortTypeFromQuery = (sortQuery: string): SortType => {
    if (sortQuery.includes("latest")) {
      return SortType.LATEST;
    } else if (sortQuery.includes("price")) {
      return SortType.PRICE;
    } else return SortType.RECOMMENDED;
  };

  const choseB2BSortDirectionFromQuery = (sortQuery: string): SortDirection => {
    if (sortQuery.includes("price")) {
      return sortQuery.includes("-price")
        ? SortDirection.DESC
        : SortDirection.ASC;
    }
    return SortDirection.NONE;
  };

  const chosenB2BSortQuery = useMemo((): SortOption => {
    if (typeof router.query["sort"] === "string") {
      return {
        type: choseB2BSortTypeFromQuery(router.query["sort"]),
        direction: choseB2BSortDirectionFromQuery(router.query["sort"]),
      };
    }
    return { type: SortType.RECOMMENDED, direction: SortDirection.NONE };
  }, [router.query]);

  const findSortElementMessageKey = (
    sortOption: SortOption
  ):
    | "scoreSortText"
    | "created_atSortText"
    | "ascPriceSortText"
    | "descPriceSortText" => {
    if (sortOption.type === SortType.LATEST) return "created_atSortText";
    if (
      sortOption.type === SortType.PRICE &&
      sortOption.direction === SortDirection.ASC
    )
      return "ascPriceSortText";
    if (
      sortOption.type === SortType.PRICE &&
      sortOption.direction === SortDirection.DESC
    )
      return "descPriceSortText";
    return "scoreSortText";
  };

  return {
    findSortElementMessageKey,
    chosenB2BSortQuery,
    sortOptions,
    setPageSize,
  };
};
