import styled, { FlattenSimpleInterpolation, css } from "styled-components";
import { SectionType } from "@ecp-pageTypes";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  HoverRowAndColumnEffectsScheme,
  hoverEffectType,
} from "@ecp-redux/dto/themeSettings/visualEffects.schemes";
import { createHoverEffect } from "../../shared/styleElements/Effects/RowAndColumnsEffects/hoverEffects";
import composeCss from "../composeCss";

export interface ISectionSettings {
  $settings: IDisplaySettingsSection;
  theme: IThemeState;
  type: SectionType;
  hoverEffect: hoverEffectType;
}

export const generateSectionStyles = (
  props: ISectionSettings
): FlattenSimpleInterpolation => {
  const isFullBackground = props.$settings.fullWidthBackground;
  // BELOW is full-width background for section (content-width background in StyledSlotsLine)
  return css`
    position: relative;
    ${isFullBackground
      ? composeCss.backgroundWithOpacity(
          props.$settings.background,
          props.theme
        )
      : ""}

    ${createHoverEffect(
      HoverRowAndColumnEffectsScheme.parse(props.hoverEffect),
      "section"
    )};

    #overlay-section {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  `;
};

export const StyledSection = styled.section<ISectionSettings>((props) =>
  generateSectionStyles(props)
);

export const StyledLayoutWidth = styled.div(
  ({ theme }) =>
    `position: relative; max-width: ${theme.stylePages.pages.maxWidth}px; margin: auto;`
);
