import { memo, useCallback, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { isEqual } from "lodash";
import useTranslation from "next-translate/useTranslation";
import { useReadOnly } from "slate-react";
import {
  PlateRenderElementProps,
  findNodePath,
  getRootProps,
  setNodes,
} from "@udecode/plate";
import htmlToReactParser from "../../helpers/HtmlToReactParser";
import { isPortalSide } from "../../helpers/helpers";
import { StyledCustomHtml } from "./CustomHtml.styled";
import { MyHtmlElement, MyValue } from "./plateTypes";

const CustomHtml: React.FC<PlateRenderElementProps<MyValue>> = (props) => {
  const { attributes, nodeProps, element, children, editor } = props;
  const { showHtml, code } = element;
  const { t } = useTranslation();
  const rootProps = getRootProps(props);
  const isReadOnly = useReadOnly();
  const path = useMemo(() => findNodePath(editor, element), [editor, element]);
  const [insertCode, setInsertCode] = useState(code as string);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const saveChangedCode = useCallback(() => {
    if (path !== undefined) {
      setNodes<MyHtmlElement>(
        editor,
        {
          code: insertCode,
        },
        { at: path }
      );
    }
  }, [insertCode, path, editor]);
  const onShowHtmlChange = useCallback(
    (showHtml: boolean) => {
      if (path !== undefined) {
        setNodes<MyHtmlElement>(
          editor,
          {
            showHtml,
          },
          { at: path }
        );
      }
    },
    [path, editor]
  );

  const iframeReady = useCallback(() => {
    if (!iframeRef.current) return;
    let iframeWin = iframeRef.current.contentWindow;
    if (iframeWin?.document.body) {
      iframeRef.current.height = String(
        iframeWin.document.documentElement.scrollHeight ||
          iframeWin.document.body.scrollHeight
      );
    }
  }, [iframeRef.current]);

  return (
    <div {...attributes} {...rootProps} {...nodeProps}>
      <StyledCustomHtml
        className="custom-html-container"
        style={{ userSelect: "none" }}
        contentEditable={false}
      >
        {!isPortalSide() && !isReadOnly && (
          <button
            className="custom-html-container__button"
            onClick={() => onShowHtmlChange(!showHtml)}
          >
            {t(
              showHtml
                ? "sidebar_settings_box_html_content.show_editor"
                : "sidebar_settings_box_html_content.show_html"
            )}
          </button>
        )}
        {isReadOnly || showHtml ? (
          <div className={classNames(!isPortalSide() ? "h-[40px]" : "")}>
            {htmlToReactParser(code as string)}
          </div>
        ) : (
          <textarea
            className="custom-html-container__textarea"
            value={insertCode as string}
            onChange={(e) => {
              setInsertCode(e.target.value);
            }}
            onBlur={saveChangedCode}
          />
        )}
      </StyledCustomHtml>

      {children}
    </div>
  );
};
export default memo(CustomHtml, isEqual);
