import { useState } from "react";
import { useRouter } from "next/router";
import * as myOrdersAPI from "@ecp-redux/api/myOrders";
import { IOrdersHistoryResponse } from "@ecp-redux/dto/myOrders.types";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  StyledOrderActionButtonsWrapper,
  StyledOrderButtonsWrapper,
} from "../BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";
import CancelOrderPopup from "../popups/CancelOrderPopup";
import ReorderPopup from "../popups/ReorderPopup";
import UnavailableReorderPopup from "../popups/UnavalibleReorderPopup";

export const enum Popups {
  NONE = "NONE",
  CANCEL_ORDER = "CANCEL_ORDER",
  UNAVALIBLE_REORDER = "UNAVALIBLE_REORDER",
  MERGE_PRODUCTS = "MERGE_PRODUCTS",
  CANCEL_DISPATCH = "CANCEL_DISPATCH",
}

interface IOrderActionButtonsProps {
  order: IOrdersHistoryResponse["orders"]["0"];
}

const OrderActionButtons: React.FC<IOrderActionButtonsProps> = ({ order }) => {
  const router = useRouter();
  const [showPopup, setShowPopup] = useState<Popups>(Popups.NONE);
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();

  const [reorderProduct] = myOrdersAPI.usePostMyOrdersReorderProductMutation();
  const [cancellOrder] = myOrdersAPI.usePutMyOrdersOrderCancelMutation();

  const handleReorderProduct = () => {
    reorderProduct({
      orderId: order.orderId,
    })
      .unwrap()
      .then(() => {
        return router.push(`${settings.customer_data_reorder_success_link}`);
      })
      .catch((err) => {
        err.data.code === 1007 && setShowPopup(Popups.MERGE_PRODUCTS);
        err.data.code === 2004 && setShowPopup(Popups.UNAVALIBLE_REORDER);
        err.data.code === 2005 && setShowPopup(Popups.UNAVALIBLE_REORDER);
        err.data.code === 2012 && setShowPopup(Popups.UNAVALIBLE_REORDER);
        err.data.code === 2013 && setShowPopup(Popups.UNAVALIBLE_REORDER);
        err.data.code === 2014 && setShowPopup(Popups.UNAVALIBLE_REORDER);
        err.data.code === 2015 && setShowPopup(Popups.UNAVALIBLE_REORDER);
      });
  };

  const handleCancellOrder = () => {
    cancellOrder(order?.uuid).then(() => {
      setShowPopup(Popups.NONE);
    });
  };

  return (
    <StyledOrderActionButtonsWrapper className="order-action-buttons-container">
      <StyledOrderButtonsWrapper
        elementsGap={settings.customer_data_orders_heading_buttons_gap}
        className="order-action-buttons-container_inner-container"
      >
        <StyledButton
          className="order-action-buttons-container_inner-container__details-button"
          onClick={() =>
            router.push(
              `${settings.customer_data_orders_order_details_url}?orderId=${order.orderId}`
            )
          }
          renderAs="button"
          $settings={settings.customer_data_orders_details_button_style}
          show={isNotEmptyMessage(messages.customer_data_orders_details_button)}
        >
          {messages.customer_data_orders_details_button}
        </StyledButton>

        <StyledButton
          className="order-action-buttons-container_inner-container__cancel-button"
          onClick={() => setShowPopup(Popups.CANCEL_ORDER)}
          renderAs="button"
          $settings={settings.customer_data_orders_cancel_button_style}
          show={
            isNotEmptyMessage(messages.customer_data_orders_cancel_button) &&
            order.canBeCancelled
          }
        >
          {messages.customer_data_orders_cancel_button}
        </StyledButton>

        <StyledButton
          className="order-action-buttons-container_inner-container__repeat-payment-button"
          onClick={() => {
            router.push(
              settings.customer_data_order_repeat_payment_redirect_link
            );
          }}
          renderAs="button"
          $settings={settings.customer_data_orders_repeat_payment_button_style}
          show={
            isNotEmptyMessage(
              messages.customer_data_order_repeat_payment_button
            ) &&
            order.canBePaid &&
            order.canBeCancelled
          }
        >
          {messages.customer_data_order_repeat_payment_button}
        </StyledButton>
      </StyledOrderButtonsWrapper>

      <LinkWrapper
        onClick={() => handleReorderProduct()}
        linkStyle={settings.customer_data_orders_reorder_button_style}
        fontStyle={settings.customer_data_orders_reorder_button_typo}
        href={""}
      >
        {messages.customer_data_orders_reorder_button}
      </LinkWrapper>

      {showPopup === Popups.CANCEL_ORDER && (
        <CancelOrderPopup
          closePopup={() => setShowPopup(Popups.NONE)}
          onConfirmClick={handleCancellOrder}
          settings={settings}
          messages={{
            popup_title: messages.customer_data_cancel_order_popup_title,
            popup_description:
              messages.customer_data_cancel_order_popup_description,
            button_save: messages.customer_data_cancel_order_popup_button_save,
            button_cancel:
              messages.customer_data_cancel_order_popup_button_cancel,
          }}
        />
      )}

      {showPopup === Popups.MERGE_PRODUCTS && (
        <ReorderPopup
          closePopup={() => setShowPopup(Popups.NONE)}
          orderId={order.orderId}
          onReorderError={() => setShowPopup(Popups.UNAVALIBLE_REORDER)}
        />
      )}

      {showPopup === Popups.UNAVALIBLE_REORDER && (
        <UnavailableReorderPopup closePopup={() => setShowPopup(Popups.NONE)} />
      )}
    </StyledOrderActionButtonsWrapper>
  );
};

export default OrderActionButtons;
