import { useTheme } from "styled-components";
import { IOrdersHistoryResponse } from "@ecp-redux/dto/myOrders.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledSingleProductWrapper } from "../BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";

interface ISingleProductProps {
  product: IOrdersHistoryResponse["orders"]["0"]["products"]["0"];
  imageUrl: string;
  imageHref?: string;
}

const SingleProduct: React.FC<ISingleProductProps> = ({
  product,
  imageUrl,
  imageHref,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();

  return (
    <StyledSingleProductWrapper
      elementPaddingX={settings.customer_data_orders_gap}
      className="single-product"
    >
      <div className="single-product__container">
        <a href={imageHref}>
          <img
            src={imageUrl}
            alt={product.productSku}
            className="single-product__container__image"
          />
        </a>
        <div className="single-product__container__product-info">
          <StyledText
            className="single-product__container__product-info__sku"
            $settings={{
              font: settings.customer_data_orders_heading_product_name_typo,

              text: {
                color: settings.customer_data_orders_heading_product_name_color,
              },
            }}
          >
            {product.productSku}
          </StyledText>

          {product?.status && (
            <StyledText
              className="single-product__container__product-info__status__header"
              $settings={{
                font: settings.customer_data_orders_heading_product_status_label_typo,

                text: {
                  color:
                    settings.customer_data_orders_heading_product_status_label_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.customer_data_orders_heading_product_status
              )}
            >
              {`${messages.customer_data_orders_heading_product_status}: `}
              <StyledText
                className="single-product__container__product-info__status__value"
                renderAs="span"
                $settings={{
                  font: settings.customer_data_orders_heading_product_status_value_typo,
                  text: {
                    color:
                      settings.customer_data_orders_heading_product_status_value_color,
                  },
                }}
                show={isNotEmptyMessage(
                  messages.customer_data_orders_heading_product_status
                )}
              >
                {product.status}
              </StyledText>
            </StyledText>
          )}

          <StyledText
            className="single-product__container__product-info__quantity__header"
            $settings={{
              font: settings.customer_data_orders_heading_product_quantity_label_typo,

              text: {
                color:
                  settings.customer_data_orders_heading_product_quantity_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_orders_heading_product_quantity
            )}
          >
            {`${messages.customer_data_orders_heading_product_quantity}: `}
            <StyledText
              className="single-product__container__product-info__quantity__value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_orders_heading_product_quantity_value_typo,
                text: {
                  color:
                    settings.customer_data_orders_heading_product_quantity_value_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.customer_data_orders_heading_product_status
              )}
            >
              {product.quantity}
            </StyledText>
          </StyledText>
          <StyledText
            className="single-product__container__product-info__price__header"
            $settings={{
              font: settings.customer_data_orders_heading_product_price_label_typo,
              text: {
                color:
                  settings.customer_data_orders_heading_product_price_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_orders_heading_product_price
            )}
          >
            {`${messages.customer_data_orders_heading_product_price}: `}
            <StyledText
              className="single-product__container__product-info__price__value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_orders_heading_product_price_value_typo,
                text: {
                  color:
                    settings.customer_data_orders_heading_product_price_value_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.customer_data_orders_heading_product_status
              )}
            >
              {formatPrice(product.price, globalMessages["currencyShort"])}
            </StyledText>
          </StyledText>
        </div>
      </div>
    </StyledSingleProductWrapper>
  );
};

export default SingleProduct;
