import React from "react";
import { useTheme } from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../../../global/components/ImageWrapper/ImageWrapper";
import { formatPrice } from "../../../../helpers/helpers";
import { StyledOrderProductList } from "../../../../shared/components/domain/CartB2B/Order/OrderProductList";
import { ISummaryB2BDesktopMobile } from "../BoxSummaryB2B.types";
import { ColumnLabel } from "./ColumnLabel";
import { TableValue } from "./TableValue";

const MobileSummaryB2B: React.FC<ISummaryB2BDesktopMobile> = ({
  messages,
  order,
  settings,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { basket_success_header_line_size, basket_success_header_line_color } =
    settings;
  return (
    <>
      <StyledOrderProductList
        lineSize={basket_success_header_line_size}
        lineColor={basket_success_header_line_color}
      >
        {order.orderPositions?.map((position) => (
          <div
            key={`${position.sku} - ${position.name}`}
            className="cartB2B__summary-mobile-container"
          >
            <div className="cartB2B__summary-mobile-container__product-line-column__image">
              <ImageWrapper
                width={92}
                imageUrl={position.coverPhoto}
                seoDescription={position.name}
                imageFit
                imageAlignment={{
                  horizontal: AlignmentHorizontalOption.CENTER,
                  vertical: AlignmentVerticalOption.CENTER,
                }}
              />

              <TableValue>{position.name}</TableValue>
            </div>
            <div className="cartB2B__summary-mobile-container__product-line-column">
              <ColumnLabel>
                {messages.basket_success_single_order_table_sku_label}
              </ColumnLabel>
              <TableValue key={position.sku}>{position.sku}</TableValue>
            </div>
            <div className="cartB2B__summary-mobile-container__product-line-column">
              <ColumnLabel>
                {messages.basket_success_single_order_table_product_added_label}
              </ColumnLabel>
              <TableValue>{`${position.quantity} ${
                position.unitOfMeasurementLabel ?? ""
              }`}</TableValue>
            </div>
            <div className="cartB2B__summary-mobile-container__product-line-column">
              <ColumnLabel>
                {messages.basket_success_single_order_table_netto_price_label}
              </ColumnLabel>
              <TableValue>
                {formatPrice(
                  position.netValue,
                  globalMessages["currencyShort"]
                )}
              </TableValue>
            </div>
            <div className="cartB2B__summary-mobile-container__product-line-column__netto">
              <ColumnLabel>
                {messages.basket_success_single_order_table_netto_value_label}
              </ColumnLabel>
              <TableValue>
                {formatPrice(
                  position.totalNetValue,
                  globalMessages["currencyShort"]
                )}
              </TableValue>
            </div>
          </div>
        ))}
      </StyledOrderProductList>
    </>
  );
};

export default MobileSummaryB2B;
