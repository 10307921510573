import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

export interface IPortalSwitchStyleSettings {
  backgroundColor: TColorId;
  borderSize: string;
  borderColor: TColorId;
  dotColor: TColorId;
}

export const StyledSwitcher = styled.div<{
  styleSettings: IPortalSwitchStyleSettings;
  checked: boolean;
}>(({ checked, styleSettings, theme }) => {
  return `
  display: flex;
  gap: 10px;
  align-items: center;

    .switcher {
      &__container {
        position: relative;
        width: 44px;
        height: 24px;
  
      &__input {
        opacity: 0;
        appearance: none;
        peer: none;
      }
  
      &__span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 44px;
        height: 24px;
        border-radius: 20px;
        background-color: ${convertColorIdToHex(
          styleSettings.backgroundColor,
          theme.colorPalette
        )};
        outline: ${styleSettings.borderSize} solid ${convertColorIdToHex(
    styleSettings.borderColor,
    theme.colorPalette
  )};
        
        transition: background-color 0.3s;
  
        &::before {
          content: '';
          position: absolute;
          height: 20px;
          width: 20px;
          top: 50%;
          transform: translateY(-50%) translateX(${checked ? "22px" : "2px"});
          transition: transform 0.3s;
          border-radius: 20px;
          background-color: ${convertColorIdToHex(
            styleSettings.dotColor,
            theme.colorPalette
          )};
        }
      }
    }
  `;
});
