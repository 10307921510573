import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import { IProductAttributePm } from "@ecp-redux/dto/productList.types";
import {
  IElementInput,
  IElementLink,
  IElementText,
  IElementTextContent,
  IElementVisibility,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  TAccordionReadoutValue,
  VerticalPosition,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { AttributeIcon } from "../BoxProductAttributes/BoxProductAttributes.types";
import {
  IBoxSearchFiltersMessagesScheme,
  IBoxSearchFiltersSettingsScheme,
} from "./BoxSearchFilters.scheme";

// Programmer Model
export interface SelectedFacet extends IProductAttributePm {
  translation: string;
  activeLink?: boolean;
  attributeIcon?: AttributeIcon;
}

export interface IBoxSearchFiltersContent {
  facetsSelection: SelectedFacet[];
  facetsTitle: string;
  showMore: string;
  showLess: string;
  clear: string;
  categoriesTitle: string;
  searchPlaceholder: string;
}

interface IShowMore extends IElementTextContent {
  initialLimit: number;
}

interface ICategoriesSettings extends IElementVisibility {
  position: VerticalPosition;
}

export enum FacetStyle {
  ACCORDION = "ACCORDION",
  LIST = "LIST",
}

export interface IBoxSearchFiltersContentSettings {
  title: IElementTextContent;
  showMore: IShowMore;
  clear: IElementTextContent;
  count: IElementTextContent;
  search: IElementInput;
  categories: ICategoriesSettings;
}

export interface IBoxSearchFiltersDisplaySettings {
  spaceBetweenFacets: number;
  spaceBetweenCategoriesAndFilters: number;
  facetNameStyle: IElementText;
  facetValueStyle: IElementText;
  facetStyle: {
    displayType: FacetStyle;
    accordionStyle: TAccordionReadoutValue;
  };
  categoryLinkStyle: IElementLink;
}

export type IBoxSearchFiltersData = GenericBoxData<
  IBoxSearchFiltersContent,
  IBoxSearchFiltersContentSettings,
  IBoxSearchFiltersDisplaySettings,
  Record<string, string>,
  Record<string, string>
>;

export type IBoxSearchFiltersMessages = z.infer<
  typeof IBoxSearchFiltersMessagesScheme
>;
export type IBoxSearchFiltersSettings = z.infer<
  typeof IBoxSearchFiltersSettingsScheme
>;

export type BoxSearchFiltersProps = IPortalBox<IBoxSearchFiltersData>;

export interface AttributeValueViewModel {
  name: string;
  count: number;
  checked: boolean;
}

export interface Attribute {
  name: string;
  translation: string | null;
  values: AttributeValueViewModel[];
}

export interface FacetVM {
  attributeId: string;
  translation: string;
  values: AttributeValueViewModel[];
}

export interface ISearchFacetProps
  extends Omit<
    IBoxSearchFiltersData,
    | "messages"
    | "settings"
    | "mobileSettings"
    | "mobileDisplaySettings"
    | "mobileContentSettings"
  > {
  facet: FacetVM;
  selectValue: (checked: boolean, facetId: string, value: string) => void;
}

export interface ISearchFiltersProps {
  contentSettings: IBoxSearchFiltersContentSettings;
  displaySettings: IBoxSearchFiltersDisplaySettings;
  content: IBoxSearchFiltersContent;
}
