import React, { useEffect } from "react";
import {
  HEX,
  ITypography,
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { AlertCode, useAlertByCodes } from "../../../redux/slices/alertsSlice";
import SimplyAlert from "./SimplyAlert";

interface AlertProps {
  alertsCodes: AlertCode[];
  closeable: boolean;
  backgroundColor: HEX | TColorId;
  typography: ITypography | TTypoId;
  textColor: HEX | TColorId;
  lifetime?: number;
  className?: string;
  removeWhen?: boolean;
}

const Alert: React.FC<AlertProps> = (props): JSX.Element | null => {
  const { alert, closeAlert } = useAlertByCodes(props.alertsCodes);

  useEffect(() => {
    if (props.removeWhen && closeAlert) {
      closeAlert();
    }
  }, [props.removeWhen, closeAlert]);

  if (alert === undefined) {
    return null;
  }

  return (
    <div className={props.className}>
      <SimplyAlert
        {...props}
        message={alert.message}
        {...(props.closeable && { close: closeAlert })}
      />
    </div>
  );
};

export default Alert;
