import classNames from "classnames";
import styled, { useTheme } from "styled-components";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
  TColorId,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import ImageWrapper from "../../../../../global/components/ImageWrapper/ImageWrapper";
import {
  convertColorIdToHex,
  convertTypoIdToValues,
} from "../../../../../settingsPatterns/settingsPatterns.methods";
import CloseIcon from "../../../../../shared/icons/CloseIcon";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { StyledSpaceKeeper } from "../../Product/ProductModule/ProductModule.styled";
import ChangeProductQuantityB2B from "../ChangeProductQuantityB2B";
import ChoosePackageTypeB2B from "../ChoosePackageTypeB2B/ChoosePackageTypeB2B";
import { useCartB2BContext } from "../useCartB2BContext";
import { PriceB2B } from "./PriceB2B/PriceB2B";
import { ProductListTextValue } from "./ProductLineB2B";

interface IStyledOrderProductListMobile {
  lineSize: TSizeCSS;
  lineColor: TColorId;
  theme: IThemeState;
  labelBackground: TColorId;
}

export const StyledOrderProductListMobile = styled.div(
  ({
    lineSize,
    lineColor,
    theme,
    labelBackground,
  }: IStyledOrderProductListMobile) => {
    return `

    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;


    .product-list {
      &__line-row {
        display: flex;
        flex-direction: row;
        gap: 8px;

        &__product-image {
          max-width: 20%;
        } 

        &__product-package-type div {
          justify-content: space-between;
          display: flex;
        }

        &__product-package-type .dropdown__button {
          width: inherit;
        }

        &__product-change-quantity {
          display: flex;
          justify-content: center;

          div {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
        
      &__product-info {
        &__wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          width: 100%;
          border-bottom: ${lineSize} solid ${convertColorIdToHex(
      lineColor,
      theme.colorPalette
    )};

          &__label-content {
            display: flex;
            width: 35%;
            background-color: ${convertColorIdToHex(
              labelBackground,
              theme.colorPalette
            )};
            padding: 6px 0 6px 6px;
          }

          &__space-keeper {
            flex: 1;

            div {
              text-align: end;
            }
          }
        }   
      }
   
      &__product-clear-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 0;
        cursor: pointer;
        border-bottom: ${lineSize} solid ${convertColorIdToHex(
      lineColor,
      theme.colorPalette
    )};
      margin-bottom: 22px;
      }
    }

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  `;
  }
);

const OrderProductListMobile: React.FC<{ order: IOrderB2B }> = ({ order }) => {
  const {
    advanceSettings: { settings: globalSettings },
    typography,
  } = useTheme() as IThemeState;
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const { grossPrice, addToCart, deleteProduct, cartData } =
    useCartB2BContext();

  return (
    <StyledOrderProductListMobile
      lineSize={settings.basket_header_line_size}
      lineColor={settings.basket_header_line_color}
      labelBackground={settings.mobile_basket_data_label_background}
    >
      {order.cartLines.map((cartLine, idx, arr) => {
        const { netValue, grossValue } = cartLine.productPriceDetails;
        const isDisabled = cartData?.unavailableProducts?.find(
          (e) => e === cartLine.sku
        );

        return (
          <>
            <div
              key={"row1" + cartLine.sku}
              className={classNames("product-list__line-row", {
                disabled: isDisabled,
              })}
            >
              <div className={`product-list__line-row__product-image`}>
                <ImageWrapper
                  width={60}
                  imageUrl={
                    cartLine.photoUrl ?? globalSettings.dynamicBoxImagePlug
                  }
                  seoDescription={cartLine.name}
                  imageFit
                  imageAlignment={{
                    horizontal: AlignmentHorizontalOption.CENTER,
                    vertical: AlignmentVerticalOption.CENTER,
                  }}
                />
              </div>

              <ProductListTextValue
                className={`product-list__line-row__product-name`}
              >
                {cartLine.name}
              </ProductListTextValue>
            </div>

            <ChoosePackageTypeB2B
              key={"row2" + cartLine.sku}
              product={{
                currentUnitOfMeasure:
                  cartLine.unitOfMeasure.currentUnitOfMeasure,
                availableUnitsOfMeasure:
                  cartLine.unitOfMeasure.availableUnitsOfMeasure,
                mainUnitOfMeasure: cartLine.unitOfMeasure.baseUnitOfMeasure,
              }}
              onChange={(unitOfMeasure) => {
                if (
                  unitOfMeasure === cartLine.unitOfMeasure.currentUnitOfMeasure
                )
                  return;
                addToCart({
                  sku: cartLine.sku,
                  quantity: cartLine.quantity,
                  unitOfMeasure: unitOfMeasure,
                  isCart: true,
                });
              }}
              dropdownStyle={settings.basket_single_order_unit_dropdown_style}
              className={classNames(
                "product-list__line-row__product-package-type",
                {
                  disabled: isDisabled,
                }
              )}
            />

            <div
              key={"row3" + cartLine.sku}
              className={classNames(
                "product-list__line-row__product-package-type",
                {
                  disabled: isDisabled,
                }
              )}
            >
              <ChangeProductQuantityB2B
                product={{ sku: cartLine.sku }}
                key={JSON.stringify(cartLine)}
                minusButtonQuantityStyle={
                  settings.basket_single_order_quantity_minus_button_style
                }
                plusButtonQuantityStyle={
                  settings.basket_single_order_quantity_plus_button_style
                }
                inputQuantityStyle={
                  settings.basket_single_order_quantity_input_style
                }
                isCart
              />
            </div>

            <div
              key={"row4" + cartLine.sku}
              className={classNames("product-list__product-info", {
                disabled: isDisabled,
              })}
            >
              <div className="product-list__product-info__wrapper">
                <div className="product-list__product-info__wrapper__label-content">
                  <StyledText
                    $settings={{
                      font: settings.mobile_basket_data_label_typo,
                      text: { color: settings.mobile_basket_data_label_color },
                    }}
                  >
                    {messages.basket_single_order_table_sku_label}
                  </StyledText>
                </div>
                <StyledSpaceKeeper
                  className="product-list__product-info__wrapper__space-keeper"
                  height={
                    convertTypoIdToValues(settings.basket_data_typo, typography)
                      .lineHeight
                  }
                  rows={1}
                >
                  <StyledText
                    $settings={{
                      font: settings.basket_data_typo,
                      text: { color: settings.basket_data_color },
                    }}
                  >
                    {cartLine.sku}
                  </StyledText>
                </StyledSpaceKeeper>
              </div>
              <div className="product-list__product-info__wrapper">
                <div className="product-list__product-info__wrapper__label-content">
                  <StyledText
                    $settings={{
                      font: settings.mobile_basket_data_label_typo,
                      text: { color: settings.mobile_basket_data_label_color },
                    }}
                  >
                    {grossPrice === true
                      ? messages.basket_single_order_table_gross_price_label
                      : messages.basket_single_order_table_netto_price_label}
                  </StyledText>
                </div>

                <PriceB2B
                  netProductValue={netValue}
                  grossProductValue={grossValue}
                />
              </div>
              <div className="product-list__product-info__wrapper">
                <div className="product-list__product-info__wrapper__label-content">
                  <StyledText
                    $settings={{
                      font: settings.mobile_basket_data_label_typo,
                      text: { color: settings.mobile_basket_data_label_color },
                    }}
                  >
                    {grossPrice === true
                      ? messages.basket_single_order_table_gross_value_label
                      : messages.basket_single_order_table_netto_value_label}
                  </StyledText>
                </div>
                <div>
                  <StyledText
                    $settings={{
                      font: settings.basket_data_typo,
                      text: { color: settings.basket_data_color },
                    }}
                  >
                    <PriceB2B
                      netProductValue={cartLine.quantity * netValue}
                      grossProductValue={cartLine.quantity * grossValue}
                    />
                  </StyledText>
                </div>
              </div>
            </div>
            <div
              key={"row5" + cartLine.sku}
              onClick={() =>
                deleteProduct({
                  sku: cartLine.sku,
                  productType: order.productType,
                })
              }
              className="product-list__product-clear-button"
            >
              <button>
                <CloseIcon width={15} height={15} fill="#293740" />
              </button>
            </div>
          </>
        );
      })}
    </StyledOrderProductListMobile>
  );
};

export default OrderProductListMobile;
