import { z } from "zod";
import { IBackground, IBorder_v2 } from "./settingsPatterns.types";
import {
  BackgroundScheme,
  Border_v2Scheme,
  RowHeightScheme,
  RowWidthScheme,
  SpacingScheme,
} from "./themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  COLOR_TRANSPARENT,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
  RowHeightOption,
  SettingPositionOptions,
  TRowWidthOption,
} from "./themeSettings.types";
import { RowEffectsScheme } from "./visualEffects.schemes";
import {
  HoverRowColumnEffectOption,
  StickyRowEffectOption,
  TRowEffects,
} from "./visualEffects.types";

const initWidth = {
  selectedOption: TRowWidthOption.PERCENT,
  percent: 100,
  margin: { left: 0, right: 0 },
};

const initHeight = {
  selectedOption: RowHeightOption.CONTENT,
  value: 20,
};

const initBackground: IBackground = {
  color: { id: "color$$11", custom: COLOR_TRANSPARENT },
  backgroundUrl: "",
  fit: false,
  alignment: {
    horizontal: AlignmentHorizontalOption.CENTER,
    vertical: AlignmentVerticalOption.CENTER,
  },
  opacity: 100,
};

const initBorder: IBorder_v2 = {
  color: { id: "color$$1", custom: "#000000" },
  style: OutlineOptions.NONE,
  width: { left: 0, right: 0, top: 0, bottom: 0 },
  radius: 0,
};

const initRowEffects: TRowEffects = {
  hoverEffect: {
    name: HoverRowColumnEffectOption.NONE,
    effectDetails: {},
  },
  stickyRow: {
    enabled: false,
    settings: {
      name: StickyRowEffectOption.NONE,
      revealOnScrollUp: true,
      topOffset: 50,
      duration: 3,
      padding: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      backgroundColor: { id: "color$$11", custom: COLOR_TRANSPARENT },
    },
  },
  foldedRow: {
    enabled: false,
    settings: {
      effectHeight: 100,
      fadeOpacity: 0,
      height: 600,
      button: {
        collapseLabel: "Pokaż mniej",
        padding: { top: 0, right: 0, bottom: 0, left: 0 },
        horizontalAlignment: AlignmentHorizontalOption.CENTER,
        expandLabel: "Pokaż więcej",
        style: {
          id: "button$$1",
          custom: {
            horizontalPadding: 35,
            verticalPadding: 8,
            background: {
              color: { id: "color$$2", custom: "#000000" },
              hoverColor: { id: "color$$2", custom: "#000000" },
              activeColor: { id: "color$$3", custom: "#000000" },
              disabledColor: { id: "color$$4", custom: "#000000" },
            },
            border: {
              radius: 4,
              radiusV2: {
                topLeft: 4,
                topRight: 4,
                bottomLeft: 4,
                bottomRight: 4,
              },
              width: 1,
              widthV2: { left: 1, right: 1, top: 1, bottom: 1 },
              color: { id: "color$$5", custom: "#000000" },
              hoverColor: { id: "color$$6", custom: "#000000" },
              activeColor: { id: "color$$7", custom: "#000000" },
              disabledColor: { id: "color$$8", custom: "#000000" },
            },
            text: {
              color: { id: "color$$1", custom: "#000000" },
              hoverColor: { id: "color$$10", custom: "#000000" },
              activeColor: { id: "color$$11", custom: "#000000" },
              disabledColor: { id: "color$$12", custom: "#000000" },
            },
            font: {
              id: "typo$$1",
              custom: {
                family: FontFamilyOptions.ROBOTO,
                weight: FontWeightOptionsLongListOfWeights.Weight300,
                size: 24,
                lineHeight: 10,
                letterSpacing: 1,
              },
            },
            icon: {
              iconUrl: null,
              position: SettingPositionOptions.RIGHT,
              size: 16,
              spacing: 10,
            },
          },
        },
      },
    },
  },
};

export const DisplaySettingsSectionScheme = z.object({
  width: RowWidthScheme.default(initWidth).catch(initWidth),
  height: RowHeightScheme.default(initHeight).catch(initHeight),
  padding: SpacingScheme.default({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }).catch({ top: 0, bottom: 0, right: 0, left: 0 }),
  background: BackgroundScheme.default(initBackground).catch(initBackground),
  border: Border_v2Scheme.default(initBorder).catch(initBorder),
  fullWidthBackground: z.boolean().default(false).catch(false),
  spacingBetweenSlots: z.number().default(0).catch(0),
  effects: RowEffectsScheme.default(initRowEffects).catch(initRowEffects),
});

export type IDisplaySettingsSection = z.infer<
  typeof DisplaySettingsSectionScheme
>;
