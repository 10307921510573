import { FC } from "react";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import { StyledCustomerData } from "./BoxMyAccountMyData.styled";
import {
  IBoxMyAccountMyDataMessages,
  IBoxMyAccountMyDataSettings,
  TCustomerDataProps,
} from "./BoxMyAccountMyData.types";
import DisplayCustomerData from "./DisplayCustomerData";

const CustomerData: FC<TCustomerDataProps> = ({ customerData }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyDataMessages,
    IBoxMyAccountMyDataSettings
  >();

  return (
    <StyledCustomerData className="customer-data-container">
      <DisplayCustomerData
        className="customer-data-container__email"
        message={messages.customer_data_heading_email}
        customerDataValue={customerData?.email ?? "-"}
      />
      <DisplayCustomerData
        className="customer-data-container__password"
        message={messages.customer_data_heading_password}
        customerDataValue={"********"}
      >
        <div className="customer-data-container__change-password">
          <LinkWrapper
            href={settings.customer_data_redirect_change_password}
            linkStyle={
              settings.customer_data_redirect_change_password_link_style
            }
          >
            {messages.customer_data_edit_password_info}
          </LinkWrapper>
        </div>
      </DisplayCustomerData>

      <DisplayCustomerData
        className="customer-data-container__first-name"
        message={messages.customer_data_heading_name}
        customerDataValue={customerData?.firstName ?? "-"}
      />

      <DisplayCustomerData
        className="customer-data-container__last-name"
        message={messages.customer_data_heading_surname}
        customerDataValue={customerData?.lastName ?? "-"}
      />

      <DisplayCustomerData
        className="customer-data-container__phone"
        message={messages.customer_data_heading_phone}
        customerDataValue={customerData?.phone ?? "-"}
      />
    </StyledCustomerData>
  );
};

export default CustomerData;
