import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSummaryB2BMessages,
  IBoxSummaryB2BSettings,
} from "../BoxSummaryB2B.types";

export const ColumnLabel: React.FC = (props) => {
  const {
    settings: { basket_success_data_typo2, basket_success_data_color2 },
  } = useMessagesSettingsContext<
    IBoxSummaryB2BMessages,
    IBoxSummaryB2BSettings
  >();

  return (
    <StyledText
      className="summary-b2b-order-table__column-label"
      $settings={{
        font: basket_success_data_typo2,
        text: {
          color: basket_success_data_color2,
        },
      }}
    >
      {props.children}
    </StyledText>
  );
};
