import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../global/global";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";

export const StyledBoxMyAccountMyAddressList = styled.div`
  .my-account__delivery-address-container {
    &__title {
      margin-bottom: 0.75rem;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__form-container {
      display: flex;
      gap: 1.25rem;
    }

    &__form__buttons_container {
      margin-top: 5px;
    }
  }
`;

export const StyledChangeAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    ${StyledButton} {
      width: 100%;
    }
  }

  .change-my-address-container__main {
    display: flex;
    justify-content: space-between;

    &__radio-button {
      display: flex;
      gap: 15px;
      overflow: hidden;
    }
  }

  .change-my-address-container__button-container {
    display: flex;
    gap: 15px;

    &__edit-address-button__icon {
      margin-left: auto;
    }

    &__delete-address-button__icon {
      margin-left: auto;
    }
  }
`;

export const StyledMyAdressInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .my-address-default-address {
    margin-top: 0.25rem;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledPostal = styled.div`
  background-color: #ffffff;
  padding: 1.25rem;

  .postal {
    &__title {
      margin-bottom: 15px;
    }

    &__background {
      padding: 10px;
    }

    &__address {
      display: flex;
      justify-content: space-between;
    }

    &__edit-address-button {
      align-self: flex-start;
    }
  }
`;

export const StyledBilling = styled.div`
  background-color: #ffffff;
  padding: 1.25rem;

  .billing {
    &__title {
      margin-bottom: 15px;
    }

    &__address__background {
      padding: 10px;
    }

    &__address {
      display: flex;
      justify-content: space-between;
    }

    &__edit-address-button {
      align-self: flex-start;
    }
  }
`;

export const StyleStoreAddresses = styled.div`
  background-color: #ffffff;
  padding: 1.25rem;

  .store-addresses-container {
    &__title {
      margin-bottom: 15px;
    }

    &__address__background {
      padding: 10px;
    }

    &__address {
      display: flex;
      justify-content: space-between;
    }

    &__edit-address-button {
      align-self: flex-start;
    }
  }
`;
