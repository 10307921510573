import useTranslation from "next-translate/useTranslation";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTheme } from "styled-components";
import { GridPaginationOptions } from "@ecp-redux/dto/article.type";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import Loading from "../../../../global/components/Loading/Loading";
import PaginationWithInput from "../../../../global/components/Pagination/paginationType/PaginationWithInput";
import { isPortalSide } from "../../../../helpers/helpers";
import { StyledGrid } from "../../../../settingsPatterns/elements/StyledGrid/StyledGrid";
import {
  getSortedElements,
  useFetchArticleList,
} from "../../BoxArticleList.methods";
import { IBoxArticleListGridProps } from "../../BoxArticleList.types";
import { paginationStyle } from "./BoxArticleListGrid.styles";

const BoxArticleListGrid = ({
  settings,
  content,
  editMode,
}: IBoxArticleListGridProps) => {
  const { t } = useTranslation();
  const { articles, pagination, currentPage, setCurrentPage } =
    useFetchArticleList(content, settings, editMode);
  const theme = useTheme() as IThemeState;
  const isDefault = () => {
    if (pagination === undefined) return false;

    return (
      settings.gridPaginationStyle === GridPaginationOptions.DEFAULT &&
      pagination.total >
        settings.article.rowCount * settings.article.articlesPerRow
    );
  };

  const incrementPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const getConditionalProps = () => {
    if (isDefault()) {
      return { renderAs: "div" };
    }

    return {
      renderAs: InfiniteScroll,
      dataLength: articles?.length ?? 0,
      next: incrementPage,
      hasMore:
        pagination === undefined
          ? false
          : pagination?.number < pagination?.count,
      loader: <Loading />,
      height: "100%",
    };
  };

  if (articles?.length === 0)
    return (
      <div className="article-list__empty-box">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );

  return (
    <StyledGrid
      data-testid="grid-article-wrapper"
      $settings={{
        spacing: settings.spacing,
        gridPaginationStyle: settings.gridPaginationStyle,
        ...settings.article,
      }}
      {...getConditionalProps()}
      className="article-list__grid"
    >
      {articles?.length !== 0 ? (
        articles?.map((article, index) => (
          <div
            className="article"
            data-testid="article"
            key={`${article.id}-${index}`}
          >
            {getSortedElements(settings, article, theme)}
          </div>
        ))
      ) : (
        <div className="article-list__empty-box">
          {t("global_keys.empty_box")}
        </div>
      )}
      {isDefault() && pagination !== undefined && (
        <PaginationWithInput
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageCount={pagination?.count}
          customStyles={paginationStyle}
        />
      )}
    </StyledGrid>
  );
};

export default BoxArticleListGrid;
