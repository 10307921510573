import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  ISpacing,
  IThemeState,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, {
  alignmentOptionsToValue,
} from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import {
  TTitleIconSettings,
  TValueIconSettings,
} from "./BoxProductAttributes.types";

export const StyledAttributeListWrapper = styled.div(
  ({ gap }: { gap: number }) => {
    return `
      display: flex;
      flex-direction: column; 
      gap: ${gap}px;

      .product-attributes-container__attribute {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    `;
  }
);

export const StyledBoxProductAttributesWrapper = styled.div(
  ({ spacing }: { spacing: ISpacing }) => {
    return `
      padding: ${composeCss.padding(spacing)};
    `;
  }
);

export const StyledRowStyle = styled.div(
  ({
    colorsStyle,
    theme,
  }: {
    colorsStyle: {
      even: TColorReadoutValue;
      odd: TColorReadoutValue;
    };
    theme: IThemeState;
  }) =>
    `
    &:nth-child(2n+2) {
      background-color: ${convertColorIdToHex(
        colorsStyle.even,
        theme.colorPalette
      )};
    }

    &:nth-child(2n+1) {
      background-color: ${convertColorIdToHex(
        colorsStyle.odd,
        theme.colorPalette
      )};
    }
    .product-attributes-container__table__cell-wrapper {
      display: flex;
    }
    `
);

export const StyledAttributeTableWrapper = styled.div(
  ({ gap }: { gap: number }) => `
    display: flex;
    flex-direction: column;
    gap: ${gap}px  


  `
);

export const StyledAttrTableCell = styled.div(
  ({
    width,
    alignment,
    type,
  }: {
    width: number;
    iconSettings?: TTitleIconSettings | TValueIconSettings;
    alignment: AlignmentHorizontalOption;
    type: "label" | "value";
  }) => {
    return `
      width:100%;
      ${type === "label" ? `min-width: ${width}%; width: fit-content;` : ""}
      ${type === "value" ? `min-width: ${width}%;` : ""}
      display: flex;
      justify-content: ${alignmentOptionsToValue(alignment)};
      `;
  }
);

export const StyledDeliveryTimeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .singleDeliveyTimeItem {
    margin-right: 7px;
  }
`;
