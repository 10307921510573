import { createContext, useCallback, useContext, useState } from "react";
import { PortalPath } from "../../shared/portalPath/portalPath";

export interface IElement {
  boxId: number;
  paths: PortalPath[];
}

export const DynamicPageContext = createContext<{
  activePaths: IElement[];
  setActivePaths: (boxId: number, paths: PortalPath[]) => void;
}>({
  activePaths: [],
  setActivePaths: () => {},
});

export function useDynamicPageContext() {
  const context = useContext(DynamicPageContext);
  if (!context) {
    throw new Error(
      "useDynamicPageContext must be used under DynamicPageContext.Provider"
    );
  }
  return context;
}

export const DynamicPageContextProvider: React.FC = ({ children }) => {
  const [activePaths, setActivePaths] = useState<IElement[]>([]);

  const setPath = useCallback(
    (boxId: number, paths: PortalPath[]) => {
      setActivePaths((prev) => {
        const index = prev.findIndex((el) => el.boxId === boxId);
        if (index === -1) {
          return [{ boxId, paths }, ...prev];
        }
        const newActivePaths = [...prev];
        const deleted = newActivePaths.splice(index, 1);
        newActivePaths.unshift({ boxId, paths });
        return newActivePaths;
      });
    },
    [activePaths]
  );

  return (
    <DynamicPageContext.Provider
      value={{
        activePaths,
        setActivePaths: setPath,
      }}
    >
      {children}
    </DynamicPageContext.Provider>
  );
};
