import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxSearchResultsMessagesScheme = z.object({
  reviewsText: themeScheme.MessageScheme.default("Reviews"),
  paginationFrom: themeScheme.MessageScheme.default("from"),
  no_photo_text: themeScheme.MessageScheme.default("No photo"),
  unknown_product_text: themeScheme.MessageScheme.default("Unknown Product"),
  descNameSortText: themeScheme.MessageScheme.default("name Z-A"),
  ascNameSortText: themeScheme.MessageScheme.default("name A-Z"),
  descPriceSortText: themeScheme.MessageScheme.default("lower price"),
  ascPriceSortText: themeScheme.MessageScheme.default("higher price"),
  defaultSortText: themeScheme.MessageScheme.default("default"),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  add_to_cart_success_message: themeScheme.MessageScheme.default(
    "Produkt dodany do koszyka"
  ),
  product_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono dozwolony limit produktów"
  ),
  product_not_available_error: themeScheme.MessageScheme.default(
    "Produkt niedostępny"
  ),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
  srp_mobile_show_result_button_label:
    themeScheme.MessageScheme.default("Pokaż wyniki"),

  add_to_wishlist_success_text: themeScheme.MessageScheme.default(
    "Dodałeś produkt do schowka"
  ),
  delete_from_wishlist_success_text: themeScheme.MessageScheme.default(
    "Usunąłeś produkt ze schowka"
  ),
  wishlist_error_text: themeScheme.MessageScheme.default(
    "Brak możliwości zmiany w schowku"
  ),
  wishlist_not_logged_error: themeScheme.MessageScheme.default(
    "Zaloguj się przed dodaniem produktu do schowka"
  ),
});

export const IBoxSearchResultsSettingsScheme = z.object({
  optionsAlign: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.RIGHT)
    .catch(AlignmentHorizontalOption.RIGHT),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  add_to_cart_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  srp_mobile_sorting_parameter_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  srp_mobile_show_result_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  srp_mobile_right_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  wishlist_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  wishlist_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  attribiute_in_row:
    themeScheme.SettingBooleanScheme.default("false").catch("false"),
  attribute_row_1: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_2: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_3: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_4: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_5: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_6: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_7: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_8: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_9: themeScheme.SettingAttributesScheme.default("").catch(""),
  attribute_row_10: themeScheme.SettingAttributesScheme.default("").catch(""),
});
