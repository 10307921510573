import { ReactNode, createContext } from "react";
import { PageWithBoxesPageBuilder } from "@ecp-pageTypes";

export const LayoutPageBuilderContext =
  createContext<PageWithBoxesPageBuilder | null>(null);

export const LayoutPageBuilderContextProvider: React.FC<{
  value: PageWithBoxesPageBuilder;
  children: ReactNode;
}> = (props: { value: PageWithBoxesPageBuilder; children: ReactNode }) => {
  return (
    <LayoutPageBuilderContext.Provider value={props.value}>
      {props.children}
    </LayoutPageBuilderContext.Provider>
  );
};
