import { useEffect, useMemo, useState } from "react";
import * as myOrdersAPI from "@ecp-redux/api/myOrders";
import { IOrdersHistoryResponse } from "@ecp-redux/dto/myOrders.types";
import {
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isClientSide } from "../../helpers/helpers";
import { Pagination } from "../../shared/components/Pagination/Pagination";
import { useProductInfo } from "../../shared/hooks/useProductInfo";
import { StyledBoxMyAccountMyOrdersWrapper } from "./BoxMyAccountMyOrders.styled";
import { TBoxMyAccountMyOrdersProps } from "./BoxMyAccountMyOrders.types";
import OrdersMainViewHeader from "./OrdersMainViewHeader/OrdersMainViewHeader";
import SingleOrder from "./SingleOrder/SingleOrder";

type TOrder = IOrdersHistoryResponse["orders"]["0"];
type TProduct = IOrdersHistoryResponse["orders"]["0"]["products"]["0"];

const BoxMyAccountMyOrders: React.FC<TBoxMyAccountMyOrdersProps> = ({
  settings,
  messages,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const changePagination = (nextPage: number) => {
    setCurrentPage(nextPage);
  };

  const { data } = myOrdersAPI.useGetMyOrdersOrdersListQuery(currentPage);

  useEffect(() => {
    if (!isClientSide()) return;
    const element = document.getElementById("scrollTopElement");

    if (element) {
      element.scrollIntoView({
        behavior: "instant" as ScrollBehavior,
      });
    }
  }, [currentPage]);

  const orderProducts = useMemo(
    () =>
      data?.orders.reduce(
        (acc: TProduct[], order: TOrder) => [...acc, ...order.products],
        []
      ),
    [data?.orders]
  );

  const { getProductInfo, isLoadingProduct } = useProductInfo(orderProducts);

  if (data == null) {
    return <strong>No data to display</strong>;
  }

  const { orders } = data;

  return (
    <StyledBoxMyAccountMyOrdersWrapper
      gapElements={settings.customer_data_orders_gap}
      className="my-account-orders-container"
    >
      <OrdersMainViewHeader ordersCount={data.pageParams.totalElements} />
      {orders.map((order) => (
        <SingleOrder
          key={order.orderId}
          order={order}
          getProductInfo={getProductInfo}
          imageLoading={isLoadingProduct}
        />
      ))}
      <div className="my-account-orders-container__single-order">
        {orders.length > 0 && (
          <Pagination
            page={currentPage}
            pageCount={(data?.pageParams.totalPages ?? 2) - 1}
            setPage={changePagination}
            fromText={messages.paginationFrom}
            textSettings={{
              font: {
                id: settings.customer_data_orders_pagination_text_typo,
                custom: {
                  family: FontFamilyOptions.ROBOTO,
                  weight: FontWeightOptionsLongListOfWeights.Weight300,
                  size: 24,
                  lineHeight: 10,
                  letterSpacing: 1,
                },
              },
              text: {
                color: {
                  id: settings.customer_data_orders_pagination_text_color,
                  custom: "#000000",
                },
              },
            }}
          />
        )}
      </div>
    </StyledBoxMyAccountMyOrdersWrapper>
  );
};

export default BoxMyAccountMyOrders;
