import {
  ArticleListSourceOptions,
  GridPaginationOptions,
  TArticleCategory,
  TArticleElements,
} from "@ecp-redux/dto/article.type";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBoxBaseSettings,
  IElementArticleImage,
  IElementText,
  IElementVisibility,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SliderPaginationOptions } from "../BoxSlider/StyledSlider.types";

export type IBoxArticleListData = GenericBoxData<
  IBoxArticleListContent,
  IBoxArticleListContentSettings,
  IBoxArticleListDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export interface IBoxArticleListContent {
  article: {
    contentSource: ArticleListSourceOptions;
    articleCategories?: TArticleCategory[];
  };
  heading: string;
}

export interface IBoxArticleListDisplaySettings {
  sliderPaginationStyle: SliderPaginationOptions;
  slidesRotate: number[];
  gridPaginationStyle: GridPaginationOptions;
  spacing: {
    horizontal: number;
    vertical: number;
  };
}

export interface IBoxArticleListContentSettings {
  article: {
    type: "SLIDER" | "GRID";
    articlesCount: number;
    articlesPerSlide: number;
    articlesPerRow: number;
    rowCount: number;
    contentAlignment: AlignmentHorizontalOption;
  };
  heading: IElementText & IElementVisibility;
  image: IElementArticleImage;
  articleTitle: IElementText & IElementVisibility;
  articleDescription: IElementText & IElementVisibility;
  articleCategory: IElementText & IElementVisibility;
  articleCreationDate: IElementText & IElementVisibility;
  order: TArticleElements[];
}

export type IBoxArticleListSettings = IBoxBaseSettings<
  IBoxArticleListDisplaySettings & IBoxArticleListContentSettings
>;

export const BOX_ARTICLE_LIST_DISPLAY_PATTERN =
  (): IBoxArticleListDisplaySettings => ({
    gridPaginationStyle: GridPaginationOptions.DEFAULT,
    sliderPaginationStyle: SliderPaginationOptions.DOTS,
    slidesRotate: [20],
    spacing: {
      horizontal: 40,
      vertical: 20,
    },
  });
