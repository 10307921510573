import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";

const OrdersMainViewHeader = ({ ordersCount }: { ordersCount: number }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();

  return (
    <div className="orders-main-view-header">
      <StyledText
        className="orders-main-view-header__title"
        renderAs="span"
        $settings={{
          font: settings.customer_data_orders_title_typo,
          text: { color: settings.customer_data_orders_title_text_color },
        }}
        show={isNotEmptyMessage(messages.customer_data_orders_title)}
      >
        {messages.customer_data_orders_title}
      </StyledText>
      <StyledText
        className="orders-main-view-header__orders-count"
        renderAs="span"
        $settings={{
          font: settings.customer_data_orders_count_typo,
          text: { color: settings.customer_data_orders_count_text_color },
        }}
        show={isNotEmptyMessage(messages.customer_data_orders_count)}
      >
        <span>{` (${messages.customer_data_orders_count} ${ordersCount})`}</span>
      </StyledText>
    </div>
  );
};

export default OrdersMainViewHeader;
