import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";

type ScrollIntoElementHook = (
  ref: React.RefObject<HTMLDivElement>
) => () => void;

const useScrollIntoElement: ScrollIntoElementHook = (ref) => {
  const {
    stylePages: {
      pages: {
        headerFooter: { stickyHeader },
      },
    },
  } = useTheme() as IThemeState;

  const scrollIntoElement: () => void = () => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: stickyHeader ? "center" : "start",
    });
  };

  return scrollIntoElement;
};

export default useScrollIntoElement;
