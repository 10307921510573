import { useState } from "react";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import { AccordionItem } from "../../shared/components/Accordion/Accordion";
import { convertAccordionIdToValues } from "../../shared/components/Accordion/StyledAccordion.methods";
import { StyledInput } from "../../shared/components/Input/StyledInput/StyledInput";
import { ReactComponent as CheckMarkIcon } from "../../shared/icons/checkMarkIcon.svg";
import { ReactComponent as SearchIcon } from "../../shared/icons/search.svg";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { BOX_ACCORDION_DISPLAY_SETTINGS } from "../BoxAccordion/BoxAccordion.pattern";
import { StyledFacetAccordion } from "./BoxSearchFilters.styled";
import { FacetStyle, ISearchFacetProps } from "./BoxSearchFilters.types";
import {
  StyledFacetCheckbox,
  StyledFacetList,
  StyledInputWithIcon,
  StyledSearchValue,
  StyledShowMore,
} from "./SearchFacet.styled";
import { useSearchFacetLogic } from "./useSearchFacetLogic";

const SearchFacet: React.FC<ISearchFacetProps> = ({
  displaySettings,
  contentSettings,
  contentSettings: {
    count,
    showMore: { initialLimit },
    search,
  },
  content: { showMore, showLess, searchPlaceholder },
  facet,
  selectValue,
}) => {
  const theme = useTheme() as IThemeState;
  const [showAll, setShowAll] = useState(false);
  const [isOpen, setOpen] = useState(true);
  const {
    changeSearchQuery,
    showElement,
    facetValuesSearchResult,
    isMoreToShow,
  } = useSearchFacetLogic(facet, initialLimit);

  return (
    <div className="facet-container">
      {displaySettings.facetStyle.displayType === FacetStyle.LIST && (
        <div className="facet-container__title">
          <StyledText $settings={displaySettings.facetNameStyle}>
            {facet.translation || facet.attributeId}
          </StyledText>
        </div>
      )}
      <ConditionalWrapper
        condition={
          displaySettings.facetStyle.displayType === FacetStyle.ACCORDION
        }
        wrapper={(children) => (
          <StyledFacetAccordion
            accordionAlignment={
              convertAccordionIdToValues(
                displaySettings.facetStyle.accordionStyle,
                theme.globalObjects.accordions
              ).accordionAlignment
            }
          >
            <AccordionItem
              forceOpen={isOpen}
              labelText={facet.translation || facet.attributeId}
              accordionItemStyle={displaySettings.facetStyle.accordionStyle}
              handleOpenCallback={() => setOpen((prevState) => !prevState)}
              disabled={false}
              accordionSettings={BOX_ACCORDION_DISPLAY_SETTINGS()}
            >
              {isOpen && <>{children}</>}
            </AccordionItem>
          </StyledFacetAccordion>
        )}
      >
        <>
          {contentSettings.search.show && (
            <StyledInputWithIcon className="facet-container__search-input">
              <StyledInput
                $settings={search}
                placeholder={searchPlaceholder}
                type="text"
                onChange={(e) => changeSearchQuery(e.target.value)}
              />
              <SearchIcon />
            </StyledInputWithIcon>
          )}
          <StyledFacetList className="facet-container__facet-list">
            {facetValuesSearchResult.map(
              (value, index) =>
                showElement(index, showAll, initialLimit) && (
                  <StyledSearchValue
                    key={value.name}
                    className="facet-container__facet-list__single-facet"
                  >
                    <StyledText $settings={displaySettings.facetValueStyle}>
                      <StyledFacetCheckbox checked={value.checked}>
                        <input
                          type="checkbox"
                          onChange={() => {
                            selectValue(
                              value.checked,
                              facet.attributeId,
                              value.name
                            );
                          }}
                          checked={value.checked}
                        />
                        {value.name}
                        {value.checked && (
                          <span className="checkmark">
                            <CheckMarkIcon />
                          </span>
                        )}
                      </StyledFacetCheckbox>
                    </StyledText>
                    <StyledText $settings={count}>{value.count}</StyledText>
                  </StyledSearchValue>
                )
            )}
          </StyledFacetList>
          {isMoreToShow && facetValuesSearchResult.length > 0 && (
            <StyledShowMore>
              <StyledText
                $settings={contentSettings.showMore}
                onClick={() => setShowAll((prevState) => !prevState)}
                className="facet-container__show-more-button"
                data-testid="facet-container__show-more-button"
              >
                {showAll ? showLess : `${showMore} (${isMoreToShow})`}
              </StyledText>
            </StyledShowMore>
          )}
        </>
      </ConditionalWrapper>
    </div>
  );
};

export default SearchFacet;
