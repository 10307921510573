import {
  EndpointType,
  TCustomerSingleResultPayload,
  TCustomersSingleResult,
} from "../dto/customers.types";
import { API, api } from "./api";

export const payers = api.injectEndpoints({
  endpoints: (builder) => ({
    postPayerSingleResult: builder.query<
      TCustomersSingleResult,
      { endpointType: EndpointType; body: TCustomerSingleResultPayload }
    >({
      query: ({ endpointType, body }) => {
        return {
          url: API.postPayerSingleResult.endpoint(endpointType),
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { usePostPayerSingleResultQuery } = payers;

export const { postPayerSingleResult } = payers.endpoints;
