import { ISrpTransformResponse } from "@ecp-redux/dto/searchResults.types";
import { formatPercentage, formatPrice } from "../../helpers/helpers";
import {
  ProductAttributeElement,
  SrpInfoProductFields,
} from "./BoxProductAttributes.types";
import { IProductExtendedAttribute } from "./hooks/useBoxProductAttributes";

export const getLabelsFromProductAttributes = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement
) => {
  return {
    ...(currentProduct.attributes?.[selection.attributeId] && {
      value:
        currentProduct.attributes[selection.attributeId] ||
        currentProduct.attributes?.[selection.attributeId],
    }),
  };
};

export const addRatingFieldToObject = (
  currentProduct: ISrpTransformResponse | undefined,
  selection: ProductAttributeElement
) => {
  return {
    ...(selection.attributeId === SrpInfoProductFields.rating &&
      currentProduct &&
      currentProduct && { value: currentProduct?.rating?.rate }),
  };
};

export const addReviewsFieldToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement
) => {
  return {
    ...(selection.attributeId === SrpInfoProductFields.reviews && {
      numberOfReviews: currentProduct.rating.numberOfReviews,
      value: currentProduct.rating.rate ?? 0,
    }),
  };
};

export const addDeliveryTimeFieldToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement
) => {
  return {
    ...(selection.attributeId === SrpInfoProductFields.deliveryTime && {
      value: currentProduct?.deliveryTime,
    }),
  };
};

export const addSkuToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement
) => {
  return {
    ...(selection.attributeId === SrpInfoProductFields.sku && {
      value: currentProduct?.sku,
    }),
  };
};

export const addPoolFieldToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement
) => {
  return {
    ...(selection.attributeId === SrpInfoProductFields.pools &&
      currentProduct?.pools && {
        value: currentProduct?.pools.map((pool) => pool.name),
      }),
  };
};

export const addBadgeFieldToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement
) => {
  return {
    ...(selection.attributeId === SrpInfoProductFields.badges && {
      value: currentProduct?.badges.map((badge) => badge.name),
      badgeType: currentProduct?.badges.map((badge) => badge.badgeKind),
    }),
  };
};

export const addBasePriceToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement,
  currency: string
) => {
  const { oldPrice } = currentProduct.price;
  const { actualPrice } = currentProduct.price;
  const basePrice = oldPrice || actualPrice;
  return {
    ...(selection.attributeId === SrpInfoProductFields.basePrice && {
      value: basePrice ? formatPrice(basePrice, currency) : 0,
    }),
  };
};

export const addOldPriceToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement,
  currency: string
) => {
  const { oldPrice } = currentProduct.price;
  return {
    ...(selection.attributeId === SrpInfoProductFields.oldPrice && {
      value: oldPrice ? formatPrice(oldPrice, currency) : null,
    }),
  };
};

export const addNetPriceToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement,
  currency: string
) => {
  const { netPrice } = currentProduct.price;
  return {
    ...((selection.attributeId === SrpInfoProductFields.netPrice ||
      selection.attributeId === "netPrice") && {
      value: netPrice ? formatPrice(netPrice, currency) : 0,
    }),
  };
};

export const addGrossPriceToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement,
  currency: string
) => {
  const { grossPrice } = currentProduct.price;
  return {
    ...(selection.attributeId === SrpInfoProductFields.grossPrice && {
      value: grossPrice ? formatPrice(grossPrice, currency) : 0,
    }),
  };
};

export const addTaxRateToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement,
  taxShort: string
) => {
  const { tax } = currentProduct;
  return {
    ...(selection.attributeId === SrpInfoProductFields.TAX && {
      value: tax ? formatPercentage(tax, taxShort) : 0,
    }),
  };
};

export const addPromotionPriceToObject = (
  currentProduct: ISrpTransformResponse,
  selection: ProductAttributeElement,
  currency: string
) => {
  const basePrice = currentProduct.price?.oldPrice;
  const promoPrice = currentProduct.price?.actualPrice;

  const finalPrice = promoPrice === 0 || !promoPrice ? basePrice : promoPrice;

  return {
    ...(selection.attributeId === SrpInfoProductFields.promotionPrice && {
      value: finalPrice ? formatPrice(finalPrice, currency) : 0,
    }),
  };
};

export const isAttributeTranslationAndValueExist = (
  attribute: ProductAttributeElement
) => {
  return attribute.translation.length && Array.isArray(attribute.value)
    ? attribute.value.length > 0
    : attribute.value !== undefined && attribute.value !== null;
};

export const checkEmptyAttribute = (
  extendedAttribute: IProductExtendedAttribute
): boolean =>
  extendedAttribute.value === undefined || extendedAttribute.value === null;

export const isEveryAttributeIsEmpty = (
  extendedAttribute: IProductExtendedAttribute[]
): boolean => extendedAttribute?.every(checkEmptyAttribute);
