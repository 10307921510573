import { useTheme } from "styled-components";
import {
  ISrpTransformResponseV2,
  TAttributeValue,
} from "@ecp-redux/dto/searchResults.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPercentage, formatPrice } from "../../../helpers/helpers";

export const isPIMAttribute = (
  attr: ISrpTransformResponseV2[keyof ISrpTransformResponseV2]
): attr is TAttributeValue => {
  return (
    attr !== null &&
    typeof attr === "object" &&
    ("label" in attr || "value" in attr)
  );
};

const useGetProductValueByKey = (): ((
  product: ISrpTransformResponseV2,
  columnKey: string
) => string | null) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  return (product: ISrpTransformResponseV2, columnKey: string) => {
    const deliveryTimeMessage =
      globalMessages[`delivery_${product.deliveryTime}`] ??
      globalMessages[`delivery_unknown`];
    if (columnKey === "sku") {
      return product["type"] === "VARIANT"
        ? product["conceptId"]
        : product["sku"];
    } else if (columnKey.toLocaleLowerCase().includes("price")) {
      const value = product[columnKey];
      return typeof value === "number"
        ? formatPrice(value, globalMessages["currencyShort"])
        : null;
    } else if (columnKey === "rating") {
      return product.rating.rate ? `${product.rating.rate}/ 5` : null;
    } else if (columnKey === "pools") {
      return product.pools && product.pools?.length > 0
        ? product.pools.map((pool) => pool.name).join(", ")
        : null;
    } else if (columnKey === "TAX") {
      const value = product[columnKey];
      return product[columnKey] && isPIMAttribute(value)
        ? formatPercentage(parseFloat(value?.value), globalMessages["taxShort"])
        : null;
    } else if (columnKey === "deliveryTime" || columnKey === "delivery") {
      return deliveryTimeMessage;
    } else {
      const productAttributes = product[columnKey];

      if (isPIMAttribute(productAttributes)) {
        return productAttributes.value;
      } else if (
        Array.isArray(productAttributes) &&
        productAttributes.length > 0 &&
        isPIMAttribute(productAttributes[0])
      ) {
        return productAttributes.map((el) => el.value).join(", ");
      } else if (
        Array.isArray(productAttributes) &&
        productAttributes.length > 0
      ) {
        return productAttributes.join(", ");
      } else {
        return productAttributes ? String(productAttributes) : null;
      }
    }
  };
};

export default useGetProductValueByKey;
