import { useTheme } from "styled-components";
import { IOrdersHistoryResponse } from "@ecp-redux/dto/myOrders.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { useProductInfo } from "../../../shared/hooks/useProductInfo";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  StyledSingleOrderHeadingWrapper,
  StyledSingleOrderWrapper,
} from "../BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";
import OrderActionButtons from "../OrderActionButtons/OrderActionButtons";
import SingleOrderInfoHeading from "../SingleOrderInfoHeading/SingleOrderInfoHeading";
import SingleProduct from "../SingleProduct/SingleProduct";

export interface ISingleOrderProps {
  order: IOrdersHistoryResponse["orders"]["0"];
  getProductInfo: ReturnType<typeof useProductInfo>["getProductInfo"];
  imageLoading: boolean;
}

const SingleOrder: React.FC<ISingleOrderProps> = ({
  order,
  getProductInfo,
  imageLoading,
}) => {
  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;
  const { settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();
  const isMobile = useIsMobilePortal();

  return (
    <StyledSingleOrderWrapper
      backgroundColorId={settings.customer_data_orders_background_color}
      className="single-order-container"
    >
      <StyledSingleOrderHeadingWrapper>
        <SingleOrderInfoHeading order={order} />
        {!isMobile && <OrderActionButtons order={order} />}
      </StyledSingleOrderHeadingWrapper>

      {!imageLoading &&
        order.products.map((product) => (
          <SingleProduct
            key={product.productSku}
            product={product}
            imageUrl={
              getProductInfo(product.productSku)?.coverPhoto ??
              globalSettings.dynamicBoxImagePlug
            }
            imageHref={getProductInfo(product.productSku)?.url}
          />
        ))}
    </StyledSingleOrderWrapper>
  );
};

export default SingleOrder;
