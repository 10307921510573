import { useState } from "react";
import { IElementTextContent } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import Dropdown from "../../../shared/components/Dropdown/Dropdown";
import useScrollWhileOnDropdown from "../../../shared/hooks/useScrollWhileOnDropdown";
import { ReactComponent as ArrowDown } from "../../../shared/icons/arrowDown.svg";
import {
  DropdownListEl,
  StyledDropdownList,
  StyledSearchUtilsLabel,
  StyledToggler,
} from "../../../shared/styleElements/StyledSearchResultsDropdown/SearchResultsDropdown.styled";
import { useSearchResultsLogic } from "./useSearchResultsLogic";

export interface ShowOnPage {
  choosen: number;
  label: string;
  showOnPageSettings: IElementTextContent;
  changeProductsPerPage: (perPage?: number) => void;
}

const ShowOnPage: React.FC<ShowOnPage> = ({
  showOnPageSettings,
  label,
  changeProductsPerPage,
  choosen,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { showOptions } = useSearchResultsLogic();

  if (!showOnPageSettings.show) return null;

  const chooseProductsPerPage = (perPage: number) => {
    changeProductsPerPage(perPage);
    setIsOpen(false);
  };

  useScrollWhileOnDropdown(isOpen, setIsOpen);

  return (
    <div className="show-on-page-container">
      <StyledSearchUtilsLabel $settings={showOnPageSettings}>
        {label}:
      </StyledSearchUtilsLabel>
      <div
        className="show-on-page-container__dropdown-container"
        onMouseLeave={() => setIsOpen(false)}
      >
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onBackDropClick={() => setIsOpen(false)}
          buttonContent={
            <StyledToggler
              $settings={showOnPageSettings}
              onClick={() => setIsOpen((prev) => !prev)}
              renderAs="button"
              isOpen={isOpen}
              data-testid="show-on-page-toggler"
            >
              <span>{choosen}</span>
              <ArrowDown />
            </StyledToggler>
          }
          selectContainerContent={
            <StyledDropdownList $settings={showOnPageSettings} renderAs="ul">
              {showOptions.map((el) => (
                <DropdownListEl
                  key={el}
                  onClick={() => {
                    setIsOpen(false);
                    chooseProductsPerPage(el);
                  }}
                  data-testid={`show-on-page-option-${el}`}
                >
                  {el}
                </DropdownListEl>
              ))}
            </StyledDropdownList>
          }
        />
      </div>
    </div>
  );
};

export default ShowOnPage;
