import styled from "styled-components";
import { IBorder_v2 } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  ISpacing,
  TColorReadoutValue,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, {
  alignmentOptionsToValue,
} from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

//Get type from zod scheme
export interface ITableSettings {
  table: {
    fixedTableSize: boolean;
    fixedWidth: number;
    fixedMaxHeight: number;
    fixedMaxColumnWidth: number;
    autoMaxWidth: number;
    autoMaxHeight: number;
    tableBackground: TColorReadoutValue;
    tableBorder: IBorder_v2;
    padding: ISpacing;
    columnsSpacing: number;
    rowsSpacing: number;
    spacingBetweenHeaderAndData: number;
    stickyHeader: boolean;
    headerHeight: number;
    rowHeight: number;
  };

  sorting: {
    show: boolean;
    iconColor: TColorReadoutValue;
    iconColorActive: TColorReadoutValue;
    iconSize: number;
  };

  pagination: {
    show: boolean;
    afterRows: number;
    alignment: {
      horizontal: AlignmentHorizontalOption;
      vertical: AlignmentVerticalOption;
    };
    textStyle: TTypoReadoutValue;
    textColor: TColorReadoutValue;
  };
}

export const StyledPagination = styled.div<{
  alignment: AlignmentHorizontalOption;
}>`
  display: flex;
  justify-content: ${({ alignment }) => alignmentOptionsToValue(alignment)};

  .table-pagination {
    &__container {
      display: flex;
      flex-direction: row;
      gap: 16px;

      .show-on-page-container {
        display: flex;
      }
    }
  }
`;

export const StyledMobileTableWrapper = styled.div<{
  tableSettings: ITableSettings;
}>(({ tableSettings, theme }) => {
  const { tableBackground, tableBorder, padding, rowsSpacing } =
    tableSettings.table;

  return `
    width: 100%;

    .sorting {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 5px;

      &__button {
        display: flex;
        align-items: center;

        &__icon {
          margin: 0 15px;
        }
      }
    }

    .table{
      display: flex;
      flex-direction: column;
      gap: ${rowsSpacing}px;

      background-color: ${convertColorIdToHex(
        tableBackground,
        theme.colorPalette
      )};
      ${composeCss.borderColor(tableBorder.color, theme)}
      ${composeCss.borderWidth(tableBorder.width)}
      ${composeCss.borderStyle(tableBorder.style)}
      ${composeCss.borderRadius(tableBorder.radius)}  

      padding: ${composeCss.padding(padding)};
    } 
  `;
});

export const StyledTableWrapper = styled.div<{
  tableSettings: ITableSettings;
}>(({ tableSettings, theme }) => {
  const {
    fixedTableSize,
    fixedWidth,
    fixedMaxHeight,
    fixedMaxColumnWidth,
    autoMaxWidth,
    autoMaxHeight,
    tableBackground,
    tableBorder,
    padding,
    columnsSpacing,
    rowsSpacing,
    spacingBetweenHeaderAndData,
    stickyHeader,
    headerHeight,
    rowHeight,
  } = tableSettings.table;

  const { pagination } = tableSettings;

  return `
      width: 100%;

      
      .table-wrapper{
       
        max-width: ${fixedTableSize ? `${fixedWidth}px` : `${autoMaxWidth}px`};
        max-height: ${
          fixedTableSize ? `${fixedMaxHeight}px` : `${autoMaxHeight}px`
        };
        overflow-x: auto;
        
        .table{
         ${pagination.show ? "margin: 35px 0;" : ""}
          min-width: ${fixedTableSize ? `${fixedWidth}px` : "min-content"};
          border-collapse: separate;
          border-spacing: 0;  
          position: relative; 
          background-color: ${convertColorIdToHex(
            tableBackground,
            theme.colorPalette
          )};  
          ${composeCss.borderColor(tableBorder.color, theme)}
          ${composeCss.borderWidth(tableBorder.width)}
          ${composeCss.borderStyle(tableBorder.style)}
          ${composeCss.borderRadius(tableBorder.radius)}

          padding: ${composeCss.padding(padding)};

          .column_spacing {
            width: ${columnsSpacing}px;
            padding: 0;
          }

          &__header{

            tr {
              position: relative;
              z-index: 9;
            }

            &__cell{
              cursor: pointer;
              height: ${headerHeight}px;
              white-space: nowrap;
              overflow: hidden;
              position: ${stickyHeader ? "sticky" : "static"};
              top: 0;
              padding: 0;
              
              
              &__content{
                max-width: ${
                  fixedTableSize ? `${fixedMaxColumnWidth}px` : "none"
                };
                overflow: hidden;

                &__sorting{
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  gap: 4px;

                  &__asc{
                    rotate: 180deg;
                  }
                }
              }

              
            }
          }

          &__rows{
            &:before{
              content: "@";
              display: block;
              line-height: ${spacingBetweenHeaderAndData}px;
              text-indent: -99999px;
            }

            &__rowSpacing{
              height: ${rowsSpacing}px;
            }

            &__row{
              &__cell{
                height: ${rowHeight}px;
                white-space: nowrap;
                overflow: hidden;
                padding: 0;
               
                &__content{
                  height: 100%;
                  overflow: hidden;
                  max-width: ${
                    fixedTableSize ? `${fixedMaxColumnWidth}px` : "none"
                  };
                }
              }
            }
          }

          
        }
      }
      
  `;
});
