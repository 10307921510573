import { useMemo } from "react";
import { useTheme } from "styled-components";
import {
  BadgeType,
  ISrpTransformResponse,
} from "@ecp-redux/dto/searchResults.types";
import {
  IThemeState,
  TColorId,
  TTypoId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import StyledText from "../../../../../styleElements/StyledText/StyledText";
import { StyledBadgeContent } from "./BadgesList.styled";

export enum Shape {
  DEFAULT = "DEFAULT",
  CIRCLE = "CIRCLE",
}

export interface BadgeVM {
  name: string;
  backgroundColorId?: TColorId;
  shape?: Shape;
  typoId?: TTypoId;
  textColorId?: string;
}

interface BadgesListProps {
  product: { badges: ISrpTransformResponse["badges"] };
}

const BadgesList: React.FC<BadgesListProps> = ({ product }) => {
  const { settings } = (useTheme() as IThemeState).advanceSettings;

  const createProductBadge = (
    name: string,
    backgroundColor: TColorId
  ): BadgeVM => ({
    name: name,
    shape: Shape.DEFAULT,
    typoId: settings.ribbons_label_typography,
    backgroundColorId: backgroundColor,
    textColorId: settings.ribbons_label_color,
  });

  const changeBadgeTypeToVm = (name: string): Record<BadgeType, BadgeVM> => ({
    [BadgeType.PROMOTION]: createProductBadge(
      name,
      settings.promotion_ribbon_background_color
    ),
    [BadgeType.NEW]: createProductBadge(
      name,
      settings.new_ribbon_background_color
    ),
    [BadgeType.BESTSELLER]: createProductBadge(
      name,
      settings.bestseller_ribbon_background_color
    ),
  });

  const list = useMemo(
    (): BadgeVM[] =>
      product.badges
        .filter((b) => b.badgeKind)
        .map((b) => changeBadgeTypeToVm(b.name)[b.badgeKind]),
    [product]
  );

  return (
    <>
      {list.map(
        ({
          name,
          backgroundColorId = "color$$3",
          typoId,
          textColorId,
          shape = Shape.DEFAULT,
        }) => {
          return (
            <StyledBadgeContent
              backgroundColorId={backgroundColorId}
              shape={shape}
              key={name}
            >
              <StyledText
                $settings={{
                  font: { id: typoId },
                  text: { color: textColorId },
                }}
                className="badge__name"
                alignment="left"
              >
                {name}
              </StyledText>
            </StyledBadgeContent>
          );
        }
      )}
    </>
  );
};

export default BadgesList;
