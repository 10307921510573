import { TUrl } from "../dto/themeSettings/themeSettings.types";
import { API, api } from "./api";

export const breadcrumbs = api.injectEndpoints({
  endpoints: (builder) => ({
    getBreadcrumbs: builder.query<{ url: TUrl; name: string }[], string>({
      query: (arg) => {
        return API.getBreadcrumbs.endpoint(arg);
      },
    }),
  }),
});

export const { useLazyGetBreadcrumbsQuery } = breadcrumbs;
