import styled, { css } from "styled-components";
import { convertColorIdToHex } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";

export const Item = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  gap: 10px;
  padding-left: 30px;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: white;
  border: 1px solid #ccc;
  left: 0;
`;
export const RadioButton = styled.input`
  opacity: 0;
  z-index: var(--zindex-radio-button);
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  margin-left: -30px;

  &:hover ~ ${RadioButtonLabel} {
    background: #ccc;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      content: "";
      background-color: white;
      color: white;
      width: 6px;
      height: 6px;
    }
  }

  &:checked + ${RadioButtonLabel} {
    background: black;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      content: "";
      background-color: white;
      color: white;
      width: 6px;
      height: 6px;
    }
  }
`;

interface RadioButtonProps {
  outlineColor: `color$$${number}`;
  emptyColor: `color$$${number}`;
  fullfiledColor: `color$$${number}`;
}
export const CustomRadioButton = styled.input<RadioButtonProps>(
  ({ outlineColor, fullfiledColor, emptyColor, theme }) => {
    return css`
      flex-shrink: 0;
      appearance: none;
      z-index: var(--zindex-radio-button);
      cursor: pointer;
      margin-right: 10px;
      height: 16px;
      width: 16px;
      border: 3px solid ${convertColorIdToHex(outlineColor, theme.colorPalette)};
      background-color: ${convertColorIdToHex(emptyColor, theme.colorPalette)};
      border-radius: 50%;
      color: ${convertColorIdToHex(emptyColor, theme.colorPalette)};
      &:checked {
        background-color: ${convertColorIdToHex(
          fullfiledColor,
          theme.colorPalette
        )};
      }
    `;
  }
);
