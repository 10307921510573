import { useEffect, useMemo, useRef, useState } from "react";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import DynamicLinkWrapper from "../../../global/components/DynamicLinkWrapper/DynamicLinkWrapper";
import { MultimenuHorizontalExpandOptions } from "../../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import StyledMultiMenuHorizontal from "../../../settingsPatterns/elements/multiMenu/StyledMultiMenuHorizontal";
import { setPublicationZIndex } from "../../BoxMiniCart/BoxMiniCart.methods";
import { getCurrGroupSett } from "../BoxMultiMenu.methods";
import { StyledDropdownWrapperMultimenu } from "../BoxMultiMenu.styled";
import { IMultiMenuHorizontalProps } from "../BoxMultiMenu.types";
import MultiMenuHorizontaContent from "../views/MultiMenuHorizontal/MultiMenuHorizontaContent";
import MultimenuHorizontalDropdown from "./MultimenuHorizontalDropdown";

const MultimenuHorizontal: React.FC<IMultiMenuHorizontalProps> = ({
  content,
  settings,
  path,
  publicationRef,
}) => {
  const [activeGroup, setActiveGroup] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const multimenuPortalRef = useRef<HTMLDivElement | null>(null);
  const multimenuFullWidthPortalRef = useRef<HTMLUListElement | null>(null);

  const [width, setWidth] = useState<number>();

  useEffect(() => {
    if (publicationRef === null) {
      return;
    }
    const handleResize = () => {
      if (publicationRef && publicationRef.current) {
        const closestSlotLine = publicationRef.current.closest("#slots-line");
        if (closestSlotLine && closestSlotLine instanceof HTMLElement) {
          setWidth(closestSlotLine.offsetWidth);
        }
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isFullWidth = useMemo(() => {
    if (activeGroup !== null) {
      return (
        settings.groups[activeGroup].editColumnsWidth?.expandType ===
        MultimenuHorizontalExpandOptions.FULL_WIDTH
      );
    }
    return false;
  }, [activeGroup]);

  return (
    <StyledMultiMenuHorizontal
      $settings={settings}
      className="multi-menu-container__horizontal"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => {
        setIsOpen(false);
        setActiveGroup(null);
      }}
      onMouseMove={() => {
        setIsOpen(true);
        setPublicationZIndex(
          "var(--zindex-dropdown-content)",
          multimenuPortalRef
        );
      }}
    >
      <ul
        className="groups multi-menu-container__horizontal__groups"
        ref={multimenuFullWidthPortalRef}
      >
        {content.groups?.map((group, groupIndex) => {
          return (
            <MultimenuHorizontalDropdown
              key={groupIndex}
              width={width || 100}
              isFullWidth={isFullWidth}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              openHorizontalDirection={
                settings.groups.length > 1
                  ? AlignmentHorizontalOption.CENTER
                  : AlignmentHorizontalOption.LEFT
              }
              menuWrapperPercentageWidth={
                settings.groups[groupIndex].editColumnsWidth
                  ?.dropdownPercentage || 100
              }
              onBackDropClick={() => {
                setIsOpen(false);
              }}
              onBackDropMouseOver={() => {
                setPublicationZIndex(
                  "var(--zindex-publication)",
                  multimenuPortalRef
                );
                setIsOpen(false);
              }}
              multimenuFullWidthPortalRef={multimenuFullWidthPortalRef}
              buttonContent={
                <li
                  className={`group ${
                    groupIndex === activeGroup ? "active" : ""
                  } multi-menu-container__horizontal__single-group-${groupIndex}`}
                  key={`group${groupIndex}`}
                  onMouseEnter={() => setActiveGroup(groupIndex)}
                >
                  <DynamicLinkWrapper
                    path={path}
                    linkStyle={
                      settings.items?.find(
                        (item) =>
                          item.id ===
                          getCurrGroupSett(groupIndex, settings)?.linkType
                            ?.style
                      )?.link
                    }
                    fontStyle={
                      settings.items?.find(
                        (item) =>
                          item.id ===
                          getCurrGroupSett(groupIndex, settings)?.linkType
                            ?.style
                      )?.font
                    }
                    icon={{
                      iconUrl: group?.linkType?.iconUrl,
                      ...settings?.groups[groupIndex]?.linkType.icon,
                    }}
                    link={group?.link}
                  >
                    <>
                      <i className="icon"></i>
                      {group.heading}
                    </>
                  </DynamicLinkWrapper>
                  <div ref={multimenuPortalRef} />
                </li>
              }
              selectContainerContent={
                <StyledDropdownWrapperMultimenu
                  key={`StyledDropdownWrapperMultimenu${activeGroup}`}
                  settings={settings}
                  data-testid="multimenu-dropdown-wrapper"
                  onMouseLeave={() => setIsOpen(false)}
                  groupIndex={activeGroup || 0}
                  isFullWidth={isFullWidth}
                  fullWidth={width || 0}
                >
                  {isOpen &&
                    multimenuPortalRef?.current !== null &&
                    activeGroup === groupIndex &&
                    group.columns.some((el) => el.items?.length > 0) && (
                      <MultiMenuHorizontaContent
                        key={groupIndex + "MultimenuContent"}
                        group={group}
                        groupIndex={groupIndex}
                        settings={settings}
                      />
                    )}
                </StyledDropdownWrapperMultimenu>
              }
            />
          );
        })}
      </ul>
    </StyledMultiMenuHorizontal>
  );
};

export default MultimenuHorizontal;
