import { useEffect, useState } from "react";
import styled from "styled-components";
import { convertUrlToImage } from "../../../helpers/helpers";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { IAttributesListProps } from "../AttributeList/AttributeList";
import IconDisplay from "../AttributeValue/IconDisplay";
import {
  TTitleIconSettings,
  TValueIconSettings,
} from "../BoxProductAttributes.types";

export const StyledAttributeWrapper = styled.div(
  ({
    iconSettings,
  }: {
    iconSettings?: TTitleIconSettings | TValueIconSettings;
  }) => {
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
      if (!iconSettings) return;
      convertUrlToImage(iconSettings.iconUrl).then((img) => {
        if (!img) return;
        setImageSize({ width: img.naturalWidth, height: img.naturalHeight });
      });
    }, [iconSettings]);

    return `
      display: flex;
      align-items: center;
      gap: ${iconSettings && iconSettings?.iconSpacing}px;

      .product-attributes-container__attribute__value {
        &__image {
          order: ${iconSettings?.iconPosition === "LEFT" ? 0 : 1};

          span, img {
            ${
              iconSettings?.iconSize.auto
                ? `
                    height: ${imageSize.height}px !important;
                    width: ${imageSize.width}px !important;
                  `
                : `
                    height: ${iconSettings?.iconSize.height}px !important;
                    width: ${iconSettings?.iconSize.height}px !important;
                  `
            }
          }
        }
      }

      ${
        iconSettings && iconSettings.iconUrl
          ? `
            ${
              "pureIcon" in iconSettings &&
              (iconSettings as TValueIconSettings).pureIcon
                ? `
                    .product-attributes-container__attribute__value__text {
                      display: none;
                    } 
                  `
                : ``
            }
            
          `
          : ""
      };
      
      ul {
        list-style: disc;
      }
    
      ol {
        list-style: decimal;
      }
    
      ul,
      ol {
        padding-left: 20px;
      }
  `;
  }
);

interface IAttributeLabelProps {
  attribute: IAttributesListProps["attribute"];
  contentSettings: IAttributesListProps["contentSettings"];
  displaySettings: IAttributesListProps["displaySettings"];
}

const AttributeLabel: React.FC<IAttributeLabelProps> = ({
  contentSettings,
  displaySettings,
  attribute,
}) => {
  return displaySettings.showLabel ? (
    <StyledAttributeWrapper iconSettings={attribute.attributeIcon?.titleIcon}>
      <IconDisplay actualIconSettings={attribute.attributeIcon?.titleIcon} />
      <StyledText
        $settings={{
          font: contentSettings.nameStyle.font,
          text: { color: contentSettings.nameStyle.text.color },
        }}
        className="product-attributes-container__attribute__label"
      >
        {attribute.translation}
      </StyledText>
    </StyledAttributeWrapper>
  ) : null;
};

export default AttributeLabel;
