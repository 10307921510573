import { ParsedUrlQuery } from "querystring";
import { FacetCatDto, FacetParam } from "@ecp-redux/dto/searchResults.types";

const isFacet = (
  parameter: FacetCatDto<FacetParam>
): parameter is FacetCatDto<FacetParam.FACET> =>
  parameter.type === FacetParam.FACET;

const isFilterStringType = (filter: string | string[]): filter is string =>
  typeof filter === "string";

const existInString = (v: string, strToVerified: string): boolean =>
  strToVerified.indexOf(v) !== -1;

type FilterNameAndValues = `${FilterName}:${FilterValues}`;
type FilterName = string;
type FilterValue = string;
type FilterValues = string;

interface HandyUrlQueryFilter {
  [key: FilterName]: FilterValue[];
}

export const convertParsedQueryToHandyQuery = (
  query: ParsedUrlQuery
): HandyUrlQueryFilter => {
  const urlQuery = {};
  if (query["filter"]) {
    if (isFilterStringType(query["filter"])) {
      return separateFacet(query["filter"] as FilterNameAndValues, urlQuery);
    } else {
      query["filter"].forEach((facet) =>
        separateFacet(facet as FilterNameAndValues, urlQuery)
      );
      return urlQuery;
    }
  }
  return {};
};

export const convertHandyQueryToParsedQuery = (
  queryFilter: HandyUrlQueryFilter,
  query: ParsedUrlQuery
): ParsedUrlQuery => {
  const newFilter: FilterValue[] = [];
  for (const [name, values] of Object.entries(queryFilter)) {
    if (values.length > 0) {
      const newValue = joinValues(values);
      newFilter.push(`${name}:${newValue}`);
    }
  }
  return { ...query, filter: newFilter };
};

export const isValueInFilters = (
  facetId: string,
  valueName: string,
  query: ParsedUrlQuery
): boolean => {
  if (query["filter"]) {
    if (isFilterStringType(query["filter"])) {
      if (!existInString(facetId, query["filter"])) return false;
      // filter is string
      const indexOfValue = query["filter"].indexOf(valueName);
      return indexOfValue !== -1;
    } else {
      // filter is string[]
      const facetIndex = query["filter"].findIndex((el) =>
        existInString(facetId, el)
      );
      if (facetIndex === -1) return false;
      return existInString(valueName, query["filter"][facetIndex]);
    }
  } else {
    // no filters
    return false;
  }
};

export const chooseOnlyFacets = (
  parameters: FacetCatDto<FacetParam>[]
): FacetCatDto<FacetParam.FACET>[] => parameters.filter(isFacet);

const separateName = (
  filter: FilterNameAndValues
): [FilterName, FilterValues] =>
  filter.split(":") as unknown as [FilterName, FilterValues];

const separateValues = (values: FilterValues): FilterValue[] =>
  values.split(";");

const separateFacet = (
  filter: FilterNameAndValues,
  urlQuery: HandyUrlQueryFilter
): HandyUrlQueryFilter => {
  const nameAndValues = separateName(filter as FilterNameAndValues);
  const values: FilterValue[] = separateValues(nameAndValues[1]);
  urlQuery[nameAndValues[0]] = values;
  return urlQuery;
};

const joinValues = (valuesInArray: FilterValue[]): FilterValues =>
  valuesInArray.join(";");
