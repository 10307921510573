import React, { useEffect, useState } from "react";
import { Control, useController } from "react-hook-form";
import InputValidation from "../../../../../lib/shared/components/Input/StyledInput/InputValidation";
import htmlToReact from "../../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import { InputPhone } from "../../../../shared/components/Input/Inputs";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountConsentsMessages,
  IBoxMyAccountConsentsSettings,
} from "../../BoxMyAccountConsents.types";
import { IConsentsForm } from "./../MyAccountConsentsForm";
import { StyledSmsConsent } from "./StyledSmsConsent.styled";

interface SmsConsentsProps {
  phone_control: Control<IConsentsForm, any>;
  phone: string | undefined;
}

const SmsConsent: React.FC<SmsConsentsProps> = ({ phone_control, phone }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountConsentsMessages,
    IBoxMyAccountConsentsSettings
  >();

  useEffect(() => {
    setShowPhoneInput(false);
  }, [phone, phone_control._formValues]);

  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const newPhoneControl = {
    name: "phone",
    ...phone_control,
  };
  const { fieldState } = useController(newPhoneControl);
  return (
    <StyledSmsConsent>
      <StyledText
        $settings={{
          font: settings.checkbox_title_typo,
          text: { color: settings.checkbox_color },
        }}
        show={isNotEmptyMessage(messages.customer_data_sms_consent_heading)}
      >
        {messages.customer_data_sms_consent_heading}
      </StyledText>

      <p>
        {htmlToReact(
          messages.customer_data_sms_consent,
          settings.terms_link_style
        )}
      </p>

      {!showPhoneInput && (
        <div className="sms-consent__phone">
          {messages.customer_data_heading_phone}: {phone ?? "-"}
          <p
            className="sms-consent__phone__text"
            onClick={() => setShowPhoneInput(!showPhoneInput)}
          >
            {messages.change_phone}
          </p>
        </div>
      )}
      {showPhoneInput && (
        <InputPhone
          name="phone"
          control={phone_control}
          placeholder={messages.customer_data_placeholder_phone}
          label={messages.customer_data_heading_phone}
          $settings={settings.phone_input}
          withoutErrorMessage
        />
      )}
      {fieldState.error !== undefined && fieldState.error.message && (
        <InputValidation
          $settings={settings.phone_input}
          errorMassage={fieldState.error.message}
        />
      )}
    </StyledSmsConsent>
  );
};

export default SmsConsent;
