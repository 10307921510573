import React from "react";
import * as cartAPI from "@ecp-redux/api/cart";
import { IPayment } from "@ecp-redux/dto/cart.types";
import { Radio } from "../../../../../../shared/components/Radio/Radio";
import StyledText from "../../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../../structure/Contexts/MessagesSettingsContext";
import { StyledPaymentElement } from "../../../BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../../BoxCartStepTwo.types";

interface PaymentElementProps {
  payment: IPayment;
  paymentName: string;
  closeEditAddress: () => void;
}

const PaymentElement: React.FC<PaymentElementProps> = ({
  payment,
  paymentName,
  closeEditAddress,
}) => {
  const { settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  const [changeMethod] = cartAPI.usePostChangePaymentMethodMutation();

  const handleSelectPaymentMethod = () => {
    if (!payment.selected) {
      changeMethod({ paymentType: payment.paymentType }).then(closeEditAddress);
    } else {
      closeEditAddress();
    }
  };

  return (
    <StyledPaymentElement
      data-testid="PaymentElementComponent"
      paymentDisabled={!payment.enabled}
    >
      <Radio checked={payment.selected} onClick={handleSelectPaymentMethod}>
        <StyledText
          $settings={{
            font: settings.basket_step_2_3_typography_paragraph_4,
            text: { color: settings.basket_step_all_color_main },
          }}
        >
          <p>{paymentName}</p>
        </StyledText>
      </Radio>
    </StyledPaymentElement>
  );
};

export default PaymentElement;
