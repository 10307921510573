import { SomePropertyFrom } from "../../../boxes/BoxGetData/BoxGetData.types";
import AttributeLabel from "../AttributeLabel/AttributeLabel";
import AttributeValue from "../AttributeValue/AttributeValue";
import { isAttributeTranslationAndValueExist } from "../BoxProductAttributes.methods";
import {
  IBoxProductAttributesContentSettings,
  IBoxProductAttributesDisplaySettings,
  ProductAttributeElement,
} from "../BoxProductAttributes.types";

export interface IAttributesListProps {
  attribute: ProductAttributeElement;
  contentSettings: SomePropertyFrom<
    IBoxProductAttributesContentSettings,
    "nameStyle" | "valueStyle" | "contentAlignment"
  >;
  displaySettings: IBoxProductAttributesDisplaySettings;
  searchRedirectUrl: string;
  currency?: string;
}

const AttributeList: React.FC<IAttributesListProps> = ({
  attribute,
  contentSettings,
  displaySettings,
  searchRedirectUrl,
  currency,
}) => {
  if (!isAttributeTranslationAndValueExist(attribute)) return null;

  return (
    <div className="product-attributes-container__attribute">
      <AttributeLabel
        attribute={attribute}
        contentSettings={contentSettings}
        displaySettings={displaySettings}
      />

      <AttributeValue
        attribute={attribute}
        contentSettings={contentSettings}
        searchRedirectUrl={searchRedirectUrl}
        currency={currency}
      />
    </div>
  );
};

export default AttributeList;
