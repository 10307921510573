import styled from "styled-components";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";

export const StyledOrderAccordion = styled.div(
  ({
    background_color,
    isExpanded,
  }: {
    background_color: string;
    isExpanded: boolean;
  }) => {
    const isMobile = useIsMobilePortal();
    return `

    ${!isExpanded ? "padding: 16px;" : "padding: 16px 16px 0 16px;"}
    background-color: ${background_color};
    box-shadow: 0px 2px 4px 0px #00000014;

    .order-accordion {
      &__scroll-wrapper {
        min-width: 845px;
        display: flex;
        flex-direction: column;
        gap: 20px;

      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }

      &__header-left {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          align-self: end;
        } 
      }

     
    }


    ${
      isMobile
        ? `
          padding: 0;

          .order-accordion {

            &__scroll-wrapper {
              min-width: unset;
              display: flex;
              flex-direction: column;
              gap: 20px;
            }

            &__header {
              padding: 16px;
              flex-direction: column;
              gap: 12px;
            }

            &__header-left {
              width: 100%;

              .remove-order {
                margin-left: auto;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }

              &__arrow {
                transition: transform 0.3s;
                transform: rotate(${isExpanded ? "-180deg" : "0deg"});
              }
            }

            &__header-right {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 8px;

              .logistic-minimum-bar__wrapper {
                display: flex;
                flex-direction: column;
                gap: 4px;
                width: 80%;
              }

              &__delivery-date-mobile {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                  text-wrap: nowrap;
                }
              }
            }


          }

          `
        : "overflow-x: auto;"
    }

    `;
  }
);
