import React, { useEffect, useMemo } from "react";
import { nanoid } from "nanoid";
import { PageCategoryType } from "@ecp-pageTypes";
import * as breadcrumbs from "@ecp-redux/api/breadcrumbs";
import DynamicLinkWrapper from "../../global/components/DynamicLinkWrapper/DynamicLinkWrapper";
import {
  ProductCategoryContext,
  categoryCodeForSearch,
  useCheckPageContext,
  useGetPortalUrl,
} from "../../helpers/pageContext";
import {
  BOX_BREADCRUMB_CONTENT_PATTERN,
  BOX_BREADCRUMB_DISPLAY_PATTERN,
  StyledBreadcrumb,
} from "../../settingsPatterns/boxes/BoxBreadcrumb/StyledBreadcrumb";
import {
  BreadcrumbSeparatorType,
  BreadcrumbTypes,
  IBoxBreadcrumbContent,
  IBreadcrumb,
} from "../../settingsPatterns/boxes/BoxBreadcrumb/StyledBreadcrumb.types";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { IBoxBreadcrumbProps } from "./BoxBreadcrumb.types";

const BoxBreadcrumb: React.FC<IBoxBreadcrumbProps> = (props) => {
  const { data: pageUrl, isSuccessLoaded } = useGetPortalUrl();
  const [trigger, result] = breadcrumbs.useLazyGetBreadcrumbsQuery();
  const { settings } = usePrepareSettings({
    props: {
      ...props.displaySettings,
    },
    settingsPattern: BOX_BREADCRUMB_DISPLAY_PATTERN(),
  });

  const content: IBoxBreadcrumbContent = usePrepareSettings({
    props: {
      ...props.content,
    },
    settingsPattern: BOX_BREADCRUMB_CONTENT_PATTERN(),
  }).settings;

  const context = useCheckPageContext<ProductCategoryContext>(
    PageCategoryType.PRODUCT_CATEGORY
  );

  const isAuto = content.type === BreadcrumbTypes.AUTO;

  const breadcrumbsData = useMemo(
    () =>
      isAuto
        ? result?.data?.map(
            (e): IBreadcrumb => ({
              id: nanoid(),
              heading: e.name,
              link: {
                showLinkOptions: true,
                selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
                goToPageSetting: { link: e.url, openInNewTab: false },
                goToRowSetting: {
                  rowId: null,
                },
              },
            })
          ) ?? []
        : content.breadcrumbs,
    [result, content, isAuto]
  );

  const separator =
    settings.separator.type === BreadcrumbSeparatorType.ARROW ? ">" : "/";

  if (pageUrl && isAuto && result.isUninitialized && isSuccessLoaded) {
    trigger(`?platform=DESKTOP&url=${pageUrl}`);
  }

  useEffect(() => {
    if (pageUrl && isAuto) {
      trigger(`?platform=DESKTOP&url=${pageUrl}`);
    }
  }, [pageUrl]);

  return (
    <StyledBreadcrumb
      $settings={settings}
      data-testid="boxBreadcrumb"
      className="breadcrumb-container"
    >
      {isAuto && content.homeBreadcrumb.heading.length > 0 && (
        <div
          key="home"
          className="breadcrumb breadcrumb-container__breadcrumb__home"
        >
          {content.homeBreadcrumb.link.showLinkOptions ? (
            <DynamicLinkWrapper
              path={props.path}
              link={content.homeBreadcrumb.link}
              linkStyle={settings.breadcrumbs.link}
              fontStyle={settings.breadcrumbs.text}
            >
              {content.homeBreadcrumb.heading}
            </DynamicLinkWrapper>
          ) : (
            <p>{content.homeBreadcrumb.heading}</p>
          )}
          <p className="separator">{separator}</p>
        </div>
      )}

      {breadcrumbsData.map((breadcrumb, index) => {
        const isLast = index === breadcrumbsData.length - 1;
        const isLinkAdded = breadcrumb.link?.goToPageSetting?.link != null;
        return (
          <div
            key={breadcrumb.id}
            className="breadcrumb breadcrumb-container__breadcrumb"
          >
            {breadcrumb.link?.showLinkOptions && (
              <DynamicLinkWrapper
                link={breadcrumb.link}
                linkStyle={settings.breadcrumbs.link}
                fontStyle={settings.breadcrumbs.text}
                isActive={!isLast ? isLinkAdded : false}
              >
                {breadcrumb.heading}
              </DynamicLinkWrapper>
            )}
            {!isLast && <p className="separator">{separator}</p>}
          </div>
        );
      })}

      {isAuto &&
        context.type === PageCategoryType.PRODUCT_CATEGORY &&
        context.searchQuery && (
          <div
            key="searchResult"
            className="breadcrumb breadcrumb-container__breadcrumb__search-result"
          >
            {context.productCategoryLevel !== categoryCodeForSearch && (
              <span className="separator">{separator}</span>
            )}
            <span>{content.searchResultBreadcrumb}</span>
            <span className="heading">{`"${context.searchQuery}"`}</span>
          </div>
        )}
    </StyledBreadcrumb>
  );
};

export default BoxBreadcrumb;
