import { IPlaceOrderResponse } from "../dto/cart.types";
import {
  IOrderDetailsResponse,
  IOrdersHistoryResponse,
} from "../dto/myOrders.types";
import { API, api } from "./api";

interface IReorderProductResponse {
  errors: Pick<IPlaceOrderResponse, "errors">;
  error?: {
    status: number;
  };
  errorMessage?: string;
  status?: string;
}
interface IReorderProductPayload {
  orderId: string;
}

interface ICancellDispatchPayload {
  orderUuid: string;
  orderDispatchUuid: string;
}

export const myOrdersAPI = api
  .enhanceEndpoints({ addTagTypes: ["MYORDERS"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMyOrdersOrdersList: builder.query<IOrdersHistoryResponse, number>({
        query: (pageNumber) => {
          return API.getMyOrdersOrdersList.endpoint(pageNumber);
        },
        providesTags: ["MYORDERS"],
      }),
      putMyOrdersOrderCancel: builder.mutation<unknown, string>({
        query: (orderUuid: string) => {
          return {
            url: API.putMyOrdersOrderCancel.endpoint(orderUuid),
            method: "PUT",
          };
        },
        invalidatesTags: ["MYORDERS"],
      }),
      putMyOrdersDetailsDispatchCancel: builder.mutation<
        unknown,
        ICancellDispatchPayload
      >({
        query: ({ orderUuid, orderDispatchUuid }) => {
          return {
            url: API.putMyOrdersDetailsDispatchCancel.endpoint(
              orderUuid,
              orderDispatchUuid
            ),
            method: "PUT",
          };
        },
        invalidatesTags: ["MYORDERS"],
      }),
      postMyOrdersReorderProduct: builder.mutation<
        IReorderProductResponse[],
        IReorderProductPayload
      >({
        query: (body) => {
          return {
            url: API.postMyOrdersReorderProduct.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["MYORDERS", "CART"],
      }),
      postMyOrdersMergeProduct: builder.mutation<
        IReorderProductResponse,
        IReorderProductPayload
      >({
        query: (body) => {
          return {
            url: API.postMyOrdersMergeProduct.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["MYORDERS", "CART"],
      }),

      getMyOrdersOrderDetails: builder.query<IOrderDetailsResponse, string>({
        query: (orderId: string) => {
          return API.getMyOrdersOrderDetails.endpoint(orderId);
        },
        providesTags: ["MYORDERS"],
      }),
    }),
  });

export const {
  useGetMyOrdersOrdersListQuery,
  usePutMyOrdersOrderCancelMutation,
  usePutMyOrdersDetailsDispatchCancelMutation,
  usePostMyOrdersReorderProductMutation,
  usePostMyOrdersMergeProductMutation,
  useGetMyOrdersOrderDetailsQuery,
  useLazyGetMyOrdersOrderDetailsQuery,
} = myOrdersAPI;

export const {
  getMyOrdersOrdersList,
  putMyOrdersOrderCancel,
  putMyOrdersDetailsDispatchCancel,
  getMyOrdersOrderDetails,
} = myOrdersAPI.endpoints;
