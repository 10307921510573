import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../global/global";
import StyledText from "../../shared/styleElements/StyledText/StyledText";

export const StyledFacetContainer = styled.div<{
  isOpen: boolean;
}>(({ isOpen }) => {
  return `
  .facet-container {
    &__right-icon {
    ${isOpen ? "transform: rotate(90deg);" : "transform: rotate(-90deg);"}
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .facet-container {
      &__right-icon {
        display: flex;
        align-items: center;
        margin-left: 8px;
        ${isOpen ? "transform: rotate(0deg);" : "transform: rotate(180deg);"};
      }
      &__title {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
      }

      &__list-wrapper {
        margin-right: 8px;
      }
    }
  }
`;
});

export const StyledFacetList = styled.ul<{
  showMore?: boolean;
  listHeight?: number;
}>`
  width: 100%;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    overflow-y: scroll;
    ${({ showMore, listHeight }) =>
      showMore &&
      `
  max-height: ${listHeight}px;
  
  /* Scrollbar styles */

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #CCCCCC;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: #EEEEEE;
    margin-top: 5px;
  }
`}
  }
`;

export const StyledSearchValue = styled.li`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  input {
    height: 100%;
    width: auto;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    align-items: center;
    gap: 10px;
    padding: 8px 10px 8px 30px;
    cursor: pointer;
    input {
      width: 100%;
    }

    .search-filters-container__categories__category-name__count-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;

      &__right-icon {
        display: flex;
        align-items: center;
        transform: rotate(90deg);
      }
    }
  }
`;

export const StyledShowMore = styled.span<{
  showMore?: boolean;
}>(({ showMore }) => {
  return `
  cursor: pointer;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
  ${showMore ? "overflow-y: scroll;" : ""}

  ${StyledText} { padding: 8px 16px 0 30px };
  }
`;
});

export const StyledFacetCheckbox = styled.label<{
  checked: boolean;
}>(({ checked }) => {
  return `
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: flex;
  input {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    transform: translateY(10%);
    width: 16px;
    height: 16px;
    border: 1px solid #000000;
    background: ${checked ? "black" : "white"};
  }

  .checkmark {
    position: absolute;
    left: 0.5px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      transform: translateY(-5%);
      width: 24px;
      height: 24px;
    }

    .checkmark {
      left: 5px;
    }

    ${TextBreaker} {
      margin-left: 10px;
    }
    
  }

  @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {  
    .checkmark {
      top: 4px;
      left: 1.25px;
    }
  }
`;
});

export const StyledInputWithIcon = styled.div`
  position: relative;
  svg {
    position: absolute;
    left: 13px;
    top: 50%;
    transform: translateY(-50%);
  }
  input {
    padding-left: 40px;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 0 -12px;
    &::placeholder {
      color: #666972;
    }
  }
`;

export const TextBreaker = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
`;
