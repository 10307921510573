import * as yup from "yup";

export const isRequiredCheckbox = (requiredM: string) =>
  yup.boolean().isTrue(requiredM);

export const isEmail = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).email(invalidM);

export const isShortText = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).max(20, invalidM);

export const isRequiredTextByLength = (
  requiredM: string,
  invalidM: string,
  maxLength: number
) => yup.string().required(requiredM).max(maxLength, invalidM);

export const isLongText = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).max(300, invalidM);

export const maxLength = (max: number, invalidM: string) =>
  yup.string().max(max, invalidM);

// Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character
const passwordRegEx = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
);

export const isPassword = (requiredM: string, invalidM: string) =>
  yup.string().required(requiredM).matches(passwordRegEx, invalidM);

export const isConfirmPassword =
  (requiredM: string, matchM: string) => (ref: string) =>
    yup
      .string()
      .required(requiredM)
      .oneOf([yup.ref(ref)], matchM);

const phoneRegex = new RegExp(/^[+]?[0-9]{9,13}$/);

const removeSpecialChars = (val: string) => {
  // eslint-disable-next-line no-useless-escape
  return val.replace(/[\ \-]/gi, "");
};

export const isPhone = (invalidM: string) =>
  yup
    .string()
    .transform((value, originalValue) => {
      const validatedPhone = removeSpecialChars(originalValue);
      return validatedPhone;
    })
    .matches(phoneRegex, { message: invalidM, excludeEmptyString: true })
    .notRequired();

export const isPhoneB2B = (invalidM: string, requiredM: string) =>
  yup
    .string()
    .transform((value, originalValue) => {
      const validatedPhone = removeSpecialChars(originalValue);
      return validatedPhone;
    })
    .matches(phoneRegex, { message: invalidM, excludeEmptyString: true })
    .required(requiredM);

const zipCodeRegex = new RegExp(/(^\d{5}$)|(^\d{9}$)|(^\d{2}-\d{3}$)/);

export const isZipCode = (invalidM: string, requiredM: string) =>
  yup
    .string()
    .matches(zipCodeRegex, { message: invalidM, excludeEmptyString: true })
    .required(requiredM);

export const isNameOrSurname = () => yup.string().trim().max(20).notRequired();

export const isNameOrSurnameB2B = (requiredM: string) =>
  yup.string().trim().max(20).required(requiredM);

const nipValidation = (nip: string) => {
  if (typeof nip !== "string") return "";

  // eslint-disable-next-line no-useless-escape
  nip = nip.replace(/[\ \-]/gi, "");

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  if (sum % 11 === controlNumber) {
    return nip;
  }
  return "";
};

export const isNIP = (invalidM: string) =>
  yup
    .string()
    .transform((value, originalValue) => {
      const validatedNIP = nipValidation(originalValue);
      return validatedNIP;
    })
    .min(10, invalidM)
    .max(10, invalidM)
    .required();

export const isRequiredString = (invalidM: string) => {
  return yup.string().trim().required(invalidM);
};

export const isString = () => {
  return yup.string().trim();
};
