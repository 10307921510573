import { useEffect } from "react";
import { useTheme } from "styled-components";
import {
  CreditLimitType,
  ISummaryB2B,
  LimitedClientStatus,
  PaymentMethodOptionB2B,
} from "@ecp-redux/dto/cartB2B.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../../../helpers/helpers";
import {
  useAddAlert,
  useAlertByCodes,
} from "../../../../../redux/slices/alertsSlice";

const useSummaryB2BAlerts = (
  summary: ISummaryB2B,
  messages: {
    limitedAccount: string;
    limitExceeded: string;
  }
) => {
  const { addAlert } = useAddAlert();
  const { closeAlert } = useAlertByCodes([
    "credit_limit_exceeded",
    "limited_account",
  ]);

  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  useEffect(() => {
    closeAlert && closeAlert();

    summary.limitedClientStatus === LimitedClientStatus.LIMITED &&
      addAlert({
        code: "limited_account",
        message: messages.limitedAccount,
      });

    summary.paymentMethod === PaymentMethodOptionB2B.CREDIT &&
      summary.creditLimitExceeded < 0 &&
      addAlert({
        code: "credit_limit_exceeded",
        message: `${messages.limitExceeded} ${formatPrice(
          Math.abs(summary.creditLimitExceeded),
          globalMessages.currencyShort,
          false
        )}
         ${
           summary.creditLimitType === CreditLimitType.NET ? "netto" : "brutto"
         }`,
      });
  }, [summary]);
};

export default useSummaryB2BAlerts;
