import { FC } from "react";
import { IPortalSection, SectionType } from "@ecp-pageTypes";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Section from "../Section/Section";

export interface IHeaderData {
  sections: IPortalSection[];
}

const Header: FC<IHeaderData> = ({ sections }: IHeaderData): JSX.Element => {
  return (
    <header id="header" style={{ display: "inline" }}>
      {sections.length > 0 && (
        <>
          {sections.map((section: IPortalSection, index: number) => {
            return (
              <Section
                key={section.uuid + section.mobileSubSection}
                sectionData={section}
                type={SectionType.HEADER}
                path={
                  new PortalPath([
                    {
                      type: "HEADER",
                      elementId: "x",
                      elementIndex: "x",
                    },
                    {
                      type: "SECTION",
                      elementId: section.id,
                      elementIndex: section.sectionOrder,
                    },
                  ])
                }
              />
            );
          })}
        </>
      )}
    </header>
  );
};

export default Header;
