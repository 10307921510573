import React from "react";
import styled from "styled-components";
import { IIcon } from "../icons.types";

interface ICloseIcon extends IIcon {
  disabled?: boolean;
  basic?: boolean;
}

const IconBody: React.FC<{ fill?: string; basic?: boolean }> = ({
  fill = "#4286F4",
  basic,
}) => (
  <>
    {!basic && <rect width="30" height="30" rx="4" fill="#F0F5FE" />}
    <path
      d="M16.1758 14.9997L22.2508 8.92467C22.3873 8.76525 22.4587 8.56019 22.4506 8.35046C22.4424 8.14073 22.3555 7.94178 22.2071 7.79337C22.0587 7.64495 21.8597 7.55801 21.65 7.54991C21.4403 7.54181 21.2352 7.61315 21.0758 7.74967L15.0008 13.8247L8.92579 7.74134C8.76637 7.60481 8.56131 7.53348 8.35158 7.54158C8.14185 7.54968 7.9429 7.63662 7.79449 7.78503C7.64608 7.93344 7.55913 8.13239 7.55103 8.34212C7.54293 8.55185 7.61427 8.75692 7.75079 8.91633L13.8258 14.9997L7.74246 21.0747C7.65522 21.1494 7.58437 21.2413 7.53435 21.3447C7.48433 21.4481 7.45623 21.5607 7.45179 21.6755C7.44736 21.7902 7.4667 21.9047 7.50859 22.0116C7.55049 22.1185 7.61404 22.2157 7.69525 22.2969C7.77646 22.3781 7.87359 22.4416 7.98052 22.4835C8.08746 22.5254 8.2019 22.5448 8.31667 22.5403C8.43144 22.5359 8.54405 22.5078 8.64743 22.4578C8.75082 22.4078 8.84275 22.3369 8.91746 22.2497L15.0008 16.1747L21.0758 22.2497C21.2352 22.3862 21.4403 22.4575 21.65 22.4494C21.8597 22.4413 22.0587 22.3544 22.2071 22.206C22.3555 22.0576 22.4424 21.8586 22.4506 21.6489C22.4587 21.4391 22.3873 21.2341 22.2508 21.0747L16.1758 14.9997Z"
      fill={fill}
    />
  </>
);

const CloseIcon: React.FC<ICloseIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
  disabled,
  basic,
}: ICloseIcon): JSX.Element => {
  return basic ? (
    <svg
      data-testid="editIcon"
      width={width}
      height={height}
      fill={fill}
      viewBox={viewBox}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <IconBody fill={fill} basic />
    </svg>
  ) : (
    <CloseIconWrapper
      data-testid="picker-clearDate"
      fill={fill}
      width={width}
      height={height}
      viewBox={viewBox}
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      disabled={disabled}
    >
      <IconBody />
    </CloseIconWrapper>
  );
};

CloseIcon.defaultProps = {
  width: "30",
  height: "30",
  fill: "#F0F5FE",
  viewBox: "0 0 30 30",
};

const CloseIconWrapper = styled.svg<Omit<ICloseIcon, "basic">>`
  transition: box-shadow var(--ecp-transition);
  border-radius: 4px;

  :active {
    box-shadow: 0 0 0 2px #0c56ce;
  }

  ${({ disabled }) =>
    disabled
      ? `
      cursor: not-allowed;
      > path {
        fill: #666972;
      }
      > rect {
        fill: #cccccc;
      }
      `
      : `
  :hover > rect,
  :active > rect {
    fill: #dce9fd;
  }

  :hover > path {
    fill: #176af1;
  }

  :active > path {
    fill: #0c56ce;
  }

  & > rect {
    transition: fill var(--ecp-transition);
  }
  `}
`;

export default CloseIcon;
