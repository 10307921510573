import {
  addDays,
  differenceInWeeks,
  getDay,
  getMonth,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import { IDeliveryCalendarB2B } from "@ecp-redux/dto/cartB2B.types";
import { IBoxCartB2BMessages } from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";

export const getMessageForMonth = (
  month: Date,
  messages: IBoxCartB2BMessages
) => {
  switch (getMonth(month)) {
    case 0:
      return messages.basket_single_order_delivery_term_month_january_label;
    case 1:
      return messages.basket_single_order_delivery_term_month_february_label;
    case 2:
      return messages.basket_single_order_delivery_term_month_march_label;
    case 3:
      return messages.basket_single_order_delivery_term_month_april_label;
    case 4:
      return messages.basket_single_order_delivery_term_month_may_label;
    case 5:
      return messages.basket_single_order_delivery_term_month_june_label;
    case 6:
      return messages.basket_single_order_delivery_term_month_july_label;
    case 7:
      return messages.basket_single_order_delivery_term_month_august_label;
    case 8:
      return messages.basket_single_order_delivery_term_month_september_label;
    case 9:
      return messages.basket_single_order_delivery_term_month_october_label;
    case 10:
      return messages.basket_single_order_delivery_term_month_november_label;
    case 11:
      return messages.basket_single_order_delivery_term_month_december_label;
    default:
      return "";
  }
};

export const getMessageForDay = (day: Date, messages: IBoxCartB2BMessages) => {
  switch (getDay(day)) {
    case 1:
      return messages.basket_single_order_delivery_term_day_mon_label;
    case 2:
      return messages.basket_single_order_delivery_term_day_tue_label;
    case 3:
      return messages.basket_single_order_delivery_term_day_wed_label;
    case 4:
      return messages.basket_single_order_delivery_term_day_thu_label;
    case 5:
      return messages.basket_single_order_delivery_term_day_fri_label;
    case 6:
      return messages.basket_single_order_delivery_term_day_sat_label;
    case 0:
      return messages.basket_single_order_delivery_term_day_sun_label;
    default:
      return "";
  }
};

const checkInWhichDeliveryWeek = (
  deliveryCalendar: IDeliveryCalendarB2B[]
): { inFirstWeek: boolean; inSecondWeek: boolean } => {
  const firstWeekStart = startOfDay(new Date());
  const firstWeekEnd = addDays(firstWeekStart, 6);
  const secondWeekStart = addDays(firstWeekEnd, 1);
  const secondWeekEnd = addDays(secondWeekStart, 6);

  const isInFirstWeek = (date: Date) =>
    isWithinInterval(date, {
      start: firstWeekStart,
      end: firstWeekEnd,
    });

  const isInSecondWeek = (date: Date) =>
    isWithinInterval(date, {
      start: secondWeekStart,
      end: secondWeekEnd,
    });

  return {
    inFirstWeek: deliveryCalendar.some(
      ({ deliveryDate, enabled }) => enabled && isInFirstWeek(deliveryDate)
    ),
    inSecondWeek: deliveryCalendar.some(
      ({ deliveryDate, enabled }) => enabled && isInSecondWeek(deliveryDate)
    ),
  };
};

export const checkIfTwoWeeks = (deliveryCalendar: IDeliveryCalendarB2B[]) => {
  const deliveryDates = checkInWhichDeliveryWeek(deliveryCalendar);
  return deliveryDates.inFirstWeek && deliveryDates.inSecondWeek;
};

export const getWeekWithDeliveryDate = (deliveryDate: Date | null) =>
  deliveryDate
    ? differenceInWeeks(deliveryDate, startOfDay(new Date()), {
        roundingMethod: "floor",
      })
    : 0;
