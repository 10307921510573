import React, { useEffect, useMemo, useState } from "react";
import { useTheme } from "styled-components";
import { isClientSide } from "@ecp-redux/api/api";
import * as contractors from "@ecp-redux/api/contractors";
import { TContractor } from "@ecp-redux/dto/contractors.type";
import {
  IGlobalDropdownObject,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertDropdownIdToValues } from "../../settingsPatterns/settingsPatterns.methods";
import { InputSearch } from "../../shared/components/Input/Inputs";
import { CustomRadio } from "../../shared/components/Radio/Radio";
import useSetSessionStorage from "../../shared/hooks/useSetSessionStorage";
import ThemeStyledDropdown from "../../shared/styleElements/StyledDropdown/ThemeStyledDropdown";
import {
  StyledContractorTextContainer,
  StyledSearchInputWrapper,
  StyledSingleItemWrapper,
} from "./BoxContractors.styled";
import { IBoxContractorsProps } from "./BoxContractors.types";

const getInitialTokenFromStorage = (): string | null => {
  const tokenSessionStorage = isClientSide()
    ? sessionStorage.getItem("contractor-token")
    : null;
  if (tokenSessionStorage) return tokenSessionStorage;
  const tokenLocalStorage = isClientSide()
    ? localStorage.getItem("contractor-token")
    : null;
  if (tokenLocalStorage) return tokenLocalStorage;
  return null;
};

const BoxContractors: React.FC<IBoxContractorsProps> = ({
  settings,
  messages,
}: IBoxContractorsProps) => {
  const { contractor_quicksearch_placeholder } = messages;
  const {
    contractor_fullfield_radiobutton_color,
    contractor_empty_radiobutton_color,
    contractor_outline_radiobutton_color,
    contractor_quicksearch_input_style,
    contractor_quicksearch_loop_color,
    contractor_dropdown_style,
  } = settings;
  const { setSessionStorageItem, removeSessionStorageItem } =
    useSetSessionStorage();

  const contractorTokenFromStorage = getInitialTokenFromStorage();

  const [selectedItemToken, setSelectedItemToken] = useState<string | null>(
    contractorTokenFromStorage
  );

  const [searchPhrase, setSearchPhrase] = useState("");

  const { data } = contractors.useGetContractorsQuery();
  const [putContractor] = contractors.usePutContractorsMutation();

  const dropdowns = (useTheme() as IThemeState).globalObjects.dropdowns;

  const searchResult = useMemo(() => {
    if (data?.contractors == null) {
      return [];
    }
    if (searchPhrase.length === 0) {
      return data.contractors;
    }
    return data.contractors.filter(
      (contractor) =>
        contractor.name.toLowerCase().includes(searchPhrase.toLowerCase()) ||
        contractor.externalId.toLowerCase().includes(searchPhrase.toLowerCase())
    );
  }, [data?.contractors, searchPhrase]);

  const handleSetSelectedContractor = (el: TContractor) => {
    if (selectedItemToken !== el.contractorToken) {
      setSessionStorageItem("contractor-token", el.contractorToken);
      putContractor()
        .then(() => {
          setSelectedItemToken(el.contractorToken);
        })
        .catch(() => removeSessionStorageItem("contractor-token"));
    }
  };

  useEffect(() => {
    if (data && data?.contractors?.length > 0) {
      if (selectedItemToken === null) {
        const contractorWithSelected = data.contractors.find(
          (element) => element.selected === true
        );
        contractorWithSelected
          ? setSelectedItemToken(contractorWithSelected.contractorToken)
          : setSelectedItemToken(data.contractors[0].contractorToken);
        contractorWithSelected
          ? setSessionStorageItem(
              "contractor-token",
              contractorWithSelected.contractorToken
            )
          : setSessionStorageItem(
              "contractor-token",
              data.contractors[0].contractorToken
            );
        putContractor()
          .then(() => {})
          .catch(() => removeSessionStorageItem("contractor-token"));
      }
    }
  }, [data]);

  return (
    <ThemeStyledDropdown
      style={{
        id: contractor_dropdown_style,
        custom: {} as IGlobalDropdownObject,
      }}
      data-testid="contractors-dropdown"
      items={searchResult.map((el, index) => {
        return {
          id: el.contractorToken,
          label: (dropdown, selected, isDropdownLabel) => {
            return (
              <StyledSingleItemWrapper
                key={el.contractorId}
                onClick={() => handleSetSelectedContractor(el)}
                data-testid="contractors-dropdown-wrapper"
                itemAlignment={
                  convertDropdownIdToValues(
                    contractor_dropdown_style,
                    dropdowns
                  ).selectedItemAlignment
                }
              >
                {!isDropdownLabel && (
                  <CustomRadio
                    fullfiledColor={contractor_fullfield_radiobutton_color}
                    emptyColor={contractor_empty_radiobutton_color}
                    outlineColor={contractor_outline_radiobutton_color}
                    checked={selectedItemToken === el.contractorToken}
                    onClick={() => {}}
                    onChange={() => handleSetSelectedContractor(el)}
                  />
                )}
                <StyledContractorTextContainer
                  isDropdownLabel={isDropdownLabel}
                >
                  <p className="contractor-name">{el.name}</p>
                  {!isDropdownLabel && <p>ID: {el.externalId}</p>}
                </StyledContractorTextContainer>
              </StyledSingleItemWrapper>
            );
          },
        };
      })}
      selectedItemId={selectedItemToken ?? ""}
      onClickItem={(_, onClose) => {
        onClose();
      }}
      searchComponent={(dropdownStyle: IGlobalDropdownObject) => {
        return (
          <StyledSearchInputWrapper>
            <InputSearch
              $settings={contractor_quicksearch_input_style}
              placeholder={contractor_quicksearch_placeholder}
              onChange={(e) => {
                setSearchPhrase(e.target.value);
              }}
              iconColor={contractor_quicksearch_loop_color}
            />
          </StyledSearchInputWrapper>
        );
      }}
    />
  );
};

export default BoxContractors;
