export const hideHeader = () => {
  const header = document.querySelector<HTMLElement>("header");
  if (header) header.style.display = "none";
};

export const showHeader = () => {
  const header = document.querySelector<HTMLElement>("header");
  if (header) header.style.display = "inline";
};

export const setMarginTop = (number: number) => {
  const body = document.querySelector<HTMLElement>("body");
  if (body) body.style.marginTop = `${number}px`;
};
