import { IUserNewsletterForm } from "../dto/newsletter.types";
import { API, api } from "./api";

export const newsletter = api.injectEndpoints({
  endpoints: (builder) => ({
    postNewsletter: builder.mutation<unknown, IUserNewsletterForm>({
      query: (body: IUserNewsletterForm) => {
        return {
          url: API.postNewsletter.endpoint(),
          method: "POST",
          body: { email: body.email },
        };
      },
    }),
    postUnsubscribleNewsletter: builder.mutation<unknown, string>({
      query: (token) => {
        return {
          url: API.postUnsubscribleNewsletter.endpoint(token),
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  usePostNewsletterMutation,
  usePostUnsubscribleNewsletterMutation,
} = newsletter;

export const { postNewsletter } = newsletter.endpoints;
