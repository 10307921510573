import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";

export const IBoxSearchFiltersMessagesScheme = z.object({
  filters_mobile_header_label:
    themeScheme.MessageScheme.default("Kategorie i filtry"),
  filters_mobile_show_result_button_label:
    themeScheme.MessageScheme.default("Pokaż wyniki"),
});

export const IBoxSearchFiltersSettingsScheme = z.object({
  filters_mobile_header_label_typography:
    themeScheme.TypoIdScheme.default("typo$$1").catch("typo$$1"),
  filters_mobile_header_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_header_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_right_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_categoryandfilters_background_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_close_icon_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  filters_mobile_clear_filters_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  filters_mobile_show_result_button_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
});
