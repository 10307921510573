import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import { formatPrice } from "../../../../../../helpers/helpers";
import StyledText from "../../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../../structure/Contexts/MessagesSettingsContext";
import { useCartB2BContext } from "../../useCartB2BContext";

export interface IPriceB2BProps {
  netProductValue: number;
  grossProductValue: number;
}

export const PriceB2B: React.FC<IPriceB2BProps> = ({
  netProductValue,
  grossProductValue,
}) => {
  const { settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { grossPrice } = useCartB2BContext();

  return (
    <StyledText
      className="cart-line-b2b_net-gross-price__value"
      $settings={{
        font: settings.basket_data_typo,
        text: { color: settings.basket_data_color },
      }}
    >
      {`${
        grossPrice
          ? formatPrice(
              grossProductValue,
              globalMessages["currencyShort"],
              false
            )
          : formatPrice(netProductValue, globalMessages["currencyShort"], false)
      }`}
    </StyledText>
  );
};
