import styled from "styled-components";
import { TSizeCSS } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";

export const StyledBoxMyAccountMyData = styled.div`
  background: white;
  box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  > form {
    margin: auto;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form > * {
    width: 100%;
  }
  a {
    width: fit-content;
    margin: auto;
  }
`;

export const StyledBoxMyAccountMyDataWrapper = styled.div<{
  titleSpacing: TSizeCSS;
}>`
  padding: 20px;
  background: white;
  box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
  display: flex;
  flex-direction: column;

  input {
    margin-bottom: 10px;
  }

  .my-account-data-container__data-info {
    margin-bottom: ${({ titleSpacing }) => titleSpacing};
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: "row";
  gap: 20px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    display: flex;
    flex-direction: column;
    .edit-customer-data__form__button-container__save-button,
    .edit-customer-data__form__button-container__cancel-button,
    .my-account__delivery-address-container__form__save-button,
    .my-account__delivery-address-container__form__cancel-button {
      min-width: 100%;
    }
  }
`;

export const StyledCustomerData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .customer-data-container__change-password {
    margin-left: 20px;
    text-decoration: underline;
  }
`;

export const StyledDisplayCustomerData = styled.div<{
  className: string;
}>`
  display: flex;

  .${({ className }) => className} {
    &__data {
      max-width: 120px;
      width: 100%;
    }
  }
`;
