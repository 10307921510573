import { useEffect, useMemo } from "react";
import { NextRouter, useRouter } from "next/router";
import { useSelector } from "react-redux";
import {
  ILayoutPageBuilder,
  PageCategoryType,
  PageWithBoxesPageBuilder,
  TPlatform,
} from "@ecp-pageTypes";
import * as articleApi from "@ecp-redux/api/articles";
import * as pages from "@ecp-redux/api/pages";
import * as searchResults from "@ecp-redux/api/searchResults";
import { ISrpTransformResponse } from "@ecp-redux/dto/searchResults.types";
import { BannersPositions } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { QuerySubState } from "@reduxjs/toolkit/dist/query/core/apiState";
import { BaseEndpointDefinition } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { usePortalSelector } from "../redux/store";

export const categoryCodeForSearch = "www";

export type ProductCategoryContext = {
  type: PageCategoryType.PRODUCT_CATEGORY;
  productCategoryCode: string;
  productCategoryLevel: number | typeof categoryCodeForSearch;
  searchQuery?: string;
};
export type ArticleContext = {
  type: PageCategoryType.ARTICLE;
  articleId: number | null;
};
export type ProductContext = {
  type: PageCategoryType.PRODUCT;
  productSku: string;
};
export type RegularContext = { type: PageCategoryType.REGULAR };

export const getProductCategoryLevel = (
  categoryCode: string | null
): number | null | typeof categoryCodeForSearch => {
  if (categoryCode === categoryCodeForSearch) return categoryCode;
  if (categoryCode && categoryCode.length > 0) {
    return (categoryCode.length - 2) / 2;
  }
  return null;
};

type TCategoryTreeName = `cat${string}`;

const convertCategoryLevelToTreeName = (
  categoryLevel: number | null | typeof categoryCodeForSearch
): TCategoryTreeName => {
  const fallbackCategory = "cat01";
  return typeof categoryLevel === "number"
    ? `cat${categoryLevel.toString().padStart(2, "0")}`
    : fallbackCategory;
};

export const getCategoryTreeName = (
  categoryCode: string | null
): TCategoryTreeName => {
  const lvl = getProductCategoryLevel(categoryCode);
  return convertCategoryLevelToTreeName(lvl);
};

export type PageContext =
  | { type: "UNKNOWN" }
  | RegularContext
  | ProductCategoryContext
  | ArticleContext
  | ProductContext
  | { type: "CONTEXT_ERROR" };
// CONTEXT_ERROR:
// * [usePageContext] if the dynamic page does not have a complete set of information (eg. without id, code...)
// * [usePageContext] if the home page has an invalid type (valid types: ARTICLE, PRODUCT, REGULAR, PRODUCT_CATEGORY)
// * [useCheckPageContext] if you expect different type of page

export const getProductCategoryCodeFromUrl = (url: string): string | null => {
  const categoryUrlFragments = url?.split("_");

  if (categoryUrlFragments.length >= 2) {
    return categoryUrlFragments[0].slice(1);
  }
  return null;
};

const choosePageContextPortal = (
  page: ILayoutPageBuilder,
  router: NextRouter
): PageContext => {
  const { type, productCategoryCode, productSku, articleId } = { ...page };

  const searchQuery = router.query?.["phrase"]?.toString();

  const sku = productSku;

  const categoryCode =
    productCategoryCode ?? getProductCategoryCodeFromUrl(router.asPath);

  const productCategoryLevel = getProductCategoryLevel(categoryCode);

  switch (type) {
    case PageCategoryType.REGULAR:
      return { type: PageCategoryType.REGULAR };
    case PageCategoryType.ARTICLE:
      return articleId
        ? { type: PageCategoryType.ARTICLE, articleId }
        : { type: "CONTEXT_ERROR" };
    case PageCategoryType.PRODUCT_CATEGORY:
      return categoryCode && productCategoryLevel
        ? {
            type: PageCategoryType.PRODUCT_CATEGORY,
            productCategoryCode: categoryCode,
            productCategoryLevel,
            searchQuery,
          }
        : { type: "CONTEXT_ERROR" };
    case PageCategoryType.PRODUCT:
      return sku
        ? { type: PageCategoryType.PRODUCT, productSku: sku }
        : { type: "CONTEXT_ERROR" };
    default:
      return { type: "CONTEXT_ERROR" };
  }
};

const choosePageContextPageWizard = (
  page: ILayoutPageBuilder,
  router: NextRouter
): PageContext => {
  const {
    articleId: articleIdFromPage,
    productCategoryCode,
    productSku,
  } = page;

  const productCategoryLevel = getProductCategoryLevel(productCategoryCode);
  const searchQuery = router.query["phrase"]?.toString();
  const sku = productSku ?? router.query["c_sku"]?.toString() ?? "";
  const articleQueryId = Number(router.query["c_articleId"]?.toString());
  const articleId = articleIdFromPage || articleQueryId;

  switch (page.type) {
    case PageCategoryType.REGULAR:
      return { type: PageCategoryType.REGULAR };
    case PageCategoryType.ARTICLE:
      return articleId
        ? { type: PageCategoryType.ARTICLE, articleId }
        : { type: "CONTEXT_ERROR" };
    case PageCategoryType.PRODUCT_CATEGORY:
      return productCategoryCode && productCategoryLevel
        ? {
            type: PageCategoryType.PRODUCT_CATEGORY,
            productCategoryCode,
            productCategoryLevel,
            searchQuery,
          }
        : { type: "CONTEXT_ERROR" };
    case PageCategoryType.PRODUCT:
      return sku
        ? { type: PageCategoryType.PRODUCT, productSku: sku }
        : { type: "CONTEXT_ERROR" };

    default:
      return { type: "CONTEXT_ERROR" };
  }
};

export const getNewestPagePortalArgs = (
  queries: Record<
    string,
    QuerySubState<BaseEndpointDefinition<any, any, any>> | undefined
  >
): {
  pageQuery: string;
  cookies: string;
  platform: TPlatform;
} => {
  return (
    queries &&
    Object.values(queries)
      .filter((data) => {
        return data?.endpointName === "getPagePortal";
      })
      .at(-1)?.originalArgs
  );
};

export const useGetDataPage = ():
  | {
      dataPage: PageWithBoxesPageBuilder;
      isPortal: boolean;
    }
  | undefined => {
  const router = useRouter();
  const pagePortalArgs = usePortalSelector((state) =>
    getNewestPagePortalArgs(state.api.queries)
  );

  const pagePortalSelect = useMemo(
    () =>
      pages.getPagePortal.select({
        pageQuery: pagePortalArgs?.pageQuery,
        platform: pagePortalArgs?.platform,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath]
  );

  const pageWizardSelect = useMemo(
    () =>
      pages.getPageWizard.select({
        pageId: router.query && router.query["pageId"]?.toString(),
      }),
    [router.query]
  );

  const pagePortal = useSelector(pagePortalSelect);
  const pageWizard = useSelector(pageWizardSelect);

  if (pagePortal.isSuccess) {
    return { dataPage: pagePortal.data, isPortal: true };
  } else if (pageWizard.isSuccess) {
    return { dataPage: pageWizard.data, isPortal: false };
  }

  return undefined;
};

export const usePageContext = (): PageContext => {
  const router = useRouter();
  const dataPage = useGetDataPage();

  let pageContext: PageContext = { type: "UNKNOWN" };

  if (dataPage?.isPortal) {
    pageContext = choosePageContextPortal(dataPage.dataPage.page, router);
  } else if (dataPage !== undefined) {
    pageContext = choosePageContextPageWizard(dataPage.dataPage.page, router);
  }
  return pageContext;
};

export const useGetArticleByContext = () => {
  const pageContext = usePageContext();
  const [getArticles, articles] = articleApi.useGetArticlesMutation();

  const isSuccessLoaded =
    articles.isSuccess && !articles.isUninitialized && !articles.isLoading;

  useEffect(() => {
    if (pageContext.type !== PageCategoryType.ARTICLE) return articles.reset();
    if (
      pageContext.type === PageCategoryType.ARTICLE &&
      articles?.data?.result[0].id !== pageContext.articleId &&
      !articles.isLoading &&
      pageContext.articleId
    ) {
      getArticles({
        page: { number: 1, size: 1 },
        filter: [
          {
            name: "id",
            operator: "EQ",
            value: pageContext.articleId,
          },
        ],
        sort: [],
      });
    }
  }, [pageContext]);

  if (articles.data?.result?.[0]) {
    return { data: articles?.data?.result[0], isSuccessLoaded };
  }
  return { data: null, isSuccessLoaded };
};

export const useGetProductAttributesContext =
  (): ISrpTransformResponse | null => {
    const pageContext = usePageContext() as ProductContext;
    const [
      getPostProducts,
      { data: productAttributes, isLoading, isSuccess, isUninitialized },
    ] = searchResults.useLazyGetPostProductsQuery();

    useEffect(() => {
      if (
        pageContext.type === PageCategoryType.PRODUCT &&
        pageContext.productSku !== undefined &&
        productAttributes?.[0]?.sku !== pageContext.productSku
      ) {
        getPostProducts({
          basicInfo: [
            "rating",
            "deliveryTime",
            "badges",
            "pools",
            "url",
            "mainUnitOfMeasurement",
            "mainUnitOfMeasurementLabel",
            "alternativeUnitOfMeasures",
            "deliveryQuantity",
          ],
          attributes: [""],
          skus: [pageContext.productSku],
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageContext?.productSku]);

    return productAttributes?.[0] ?? null;
  };

export const useCheckPageContext = <T extends PageContext>(
  pageType: PageCategoryType
): T | { type: "CONTEXT_ERROR" } => {
  const pageContext = usePageContext();
  if (pageContext.type === pageType) {
    return pageContext as T;
  }

  return { type: "CONTEXT_ERROR" };
};

//Set context use only page-wizard
export const useSetPageContext = () => {
  const router = useRouter();

  const actualSku = router.query["c_sku"]?.toString();
  const actualQuery = router.query["phrase"]?.toString();
  const actualArticleId = Number(router.query["c_articleId"]?.toString());

  const setContextSku = (c_sku: string | undefined) => {
    if (actualSku !== c_sku) {
      router.push(
        {
          pathname: router.asPath.split("?")[0],
          query: c_sku === undefined ? {} : { c_sku },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const setContextArticleId = (c_articleId: number | undefined) => {
    if (actualArticleId !== c_articleId) {
      router.push(
        {
          pathname: router.asPath.split("?")[0],
          query: c_articleId === undefined ? {} : { c_articleId },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  const setContextQuery = (phrase: string | undefined) => {
    if (actualQuery !== phrase) {
      router.push(
        {
          pathname: router.asPath.split("?")[0],
          query: phrase === undefined ? {} : { phrase },
        },
        undefined,
        { shallow: true }
      );
    }
  };

  return {
    c_sku: actualSku,
    setContextSku,
    c_articleId: actualArticleId,
    phrase: actualQuery,
    setContextArticleId,
    setContextQuery,
  };
};

interface PaginationSetting {
  initialPageSize: number;
  initialPageIndex: number;
}

interface MarketingDetails {
  bannersTemplate: number[];
  bannersDisplayVariant: BannersPositions;
}

export const getSearchQuery = (
  url: string,
  pagination?: PaginationSetting,
  productCategoryCode?: string,
  marketingDisplay?: MarketingDetails
) => {
  const tmpURL = new URL(url, "http://example");
  const phrase = tmpURL.searchParams.get("phrase");
  const sort = tmpURL.searchParams.get("sort");

  const pageSize = Number(
    tmpURL.searchParams.get("pageSize") ?? pagination?.initialPageSize
  );
  const page = Number(
    pagination !== undefined
      ? +(tmpURL.searchParams.get("page") ?? pagination.initialPageIndex) - 1
      : undefined
  );

  const skip = page * pageSize;
  const take = pageSize;

  let skipForSearch = skip;
  let takeForSearch = take;

  if (marketingDisplay !== undefined) {
    const { bannersTemplate, bannersDisplayVariant } = marketingDisplay;

    const bannersForCurrentPage =
      bannersDisplayVariant === BannersPositions.FIX
        ? bannersTemplate.filter(
            (item) =>
              item < page * pageSize + pageSize && item >= page * pageSize
          ).length
        : bannersTemplate.length;

    if (Number(page) > 0) {
      const bannersForPreviousPage =
        bannersDisplayVariant === BannersPositions.FIX
          ? bannersTemplate.filter(
              (item) => item < (page - 1) * pageSize + pageSize
            ).length
          : bannersTemplate.length * page;

      skipForSearch = skipForSearch - bannersForPreviousPage;
    }

    takeForSearch = takeForSearch - bannersForCurrentPage;
  }

  const filters = tmpURL.searchParams.getAll("filter");

  const queriesArray: Array<string | null | undefined> = [];
  if (productCategoryCode && productCategoryCode !== categoryCodeForSearch) {
    queriesArray.push(
      `filter=${convertCategoryLevelToTreeName(
        getProductCategoryLevel(productCategoryCode)
      )}:${productCategoryCode}`
    );
  }
  phrase && queriesArray.push(`phrase=${encodeURIComponent(phrase)}`);
  sort && queriesArray.push(`sort=${sort}`);
  if (pageSize !== undefined && page !== undefined) {
    queriesArray.push(`size=${takeForSearch}`);
    queriesArray.push(`from=${skipForSearch}`);
  }

  filters && filters.forEach((filter) => queriesArray.push(`filter=${filter}`));

  return { searchQueries: queriesArray.join("&"), skip, take };
};

export const useSearchQueries = (
  pagination?: PaginationSetting,
  marketingDetails?: MarketingDetails
) => {
  const router = useRouter();

  const categoryContext = useCheckPageContext<ProductCategoryContext>(
    PageCategoryType.PRODUCT_CATEGORY
  );

  return getSearchQuery(
    router.asPath,
    pagination,
    categoryContext.type !== "CONTEXT_ERROR"
      ? categoryContext.productCategoryCode
      : undefined,
    marketingDetails
  );
};

export const useGetPortalUrl = (): {
  data: string | undefined;
  isSuccessLoaded: boolean;
} => {
  const page = useGetDataPage();
  const { data: article, isSuccessLoaded: articleLoading } =
    useGetArticleByContext();
  const product = useGetProductAttributesContext();
  if (article !== null)
    return { data: article.articleUrl, isSuccessLoaded: articleLoading };
  if (
    product !== null &&
    product.url !== null &&
    page?.dataPage.page.type === PageCategoryType.PRODUCT
  ) {
    return { data: product.url, isSuccessLoaded: true };
  }
  return {
    data: page?.dataPage.page.url,
    isSuccessLoaded: articleLoading || product !== null,
  };
};
