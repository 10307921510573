import { useState } from "react";
import styled from "styled-components";
import { AlignmentVerticalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxSearchResultsB2BMessages,
  IBoxSearchResultsB2BSettings,
} from "../../../../boxes/BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import {
  IBoxTableDataMessages,
  IBoxTableDataSettings,
} from "../../../../boxes/BoxTableData/BoxTableData.types";
import ArrowDownIcon from "../../../../shared/icons/ArrowDownIcon";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { StyledMobileTableWrapper } from "../Table.styled";
import { TableProps } from "../Table.types";
import { StyledTableCell } from "../TableCell";
import TablePagination from "../elements/TablePagination";
import SortB2B from "./RWD components/SortB2B";

const StyledMobileRow = styled.div<{ withoutLabel: boolean }>`
  display: grid;
  ${({ withoutLabel }) =>
    !withoutLabel && `grid-template-columns: repeat(2, 1fr);`};
`;

const MobileTable: React.FC<TableProps> = ({
  columns,
  rows,
  pagination,
  tableSettings,
}) => {
  const [expandSort, setExpandSort] = useState(false);
  const closeSorting = () => {
    setExpandSort(false);
  };

  const { settings, messages } = useMessagesSettingsContext<
    IBoxSearchResultsB2BMessages | IBoxTableDataMessages,
    IBoxSearchResultsB2BSettings | IBoxTableDataSettings
  >();

  return (
    <StyledMobileTableWrapper tableSettings={tableSettings}>
      {!expandSort && (
        <div className="sorting">
          <button
            onClick={() => setExpandSort(true)}
            className="sorting__button"
            data-testid="expand-sorting-button"
          >
            <StyledText
              $settings={{
                text: {
                  color: settings.srp_mobile_expand_button_label_color,
                },
                font: settings.srp_mobile_expand_button_label_typo,
              }}
            >
              {messages.srp_mobile_expand_button_label}
            </StyledText>
            <ArrowDownIcon
              width={25}
              height={12}
              className="sorting__button__icon"
            />
          </button>
        </div>
      )}
      {expandSort && <SortB2B closeSorting={closeSorting}></SortB2B>}
      <div className="table">
        {rows.map((row, rowIndex) => (
          <div className="table__row" key={rowIndex}>
            {columns.map(
              (column) =>
                !row[column.key].isEmpty && (
                  <StyledMobileRow
                    withoutLabel={column.name === ""}
                    key={column.key}
                  >
                    {column.name !== "" && (
                      <StyledTableCell
                        className="table__header__cell__content"
                        tableCellStyleId={column.headerCellStyleId}
                      >
                        {column.name}
                      </StyledTableCell>
                    )}
                    <div className="table__row__cell__content">
                      {row[column.key].component(true, 340)}
                    </div>
                  </StyledMobileRow>
                )
            )}
          </div>
        ))}
      </div>

      {pagination &&
        tableSettings.pagination.show &&
        tableSettings.pagination.alignment.vertical !==
          AlignmentVerticalOption.BOTTOM && (
          <TablePagination
            pagination={pagination}
            paginationStyle={tableSettings.pagination}
          />
        )}
    </StyledMobileTableWrapper>
  );
};

export default MobileTable;
