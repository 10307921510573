import React from "react";
import { TBoxRegisterProps } from "./BoxRegister.types";
import BusinessRegisterForm from "./BusinessRegisterForm";
import ClientRegisterForm from "./ClientRegisterForm";

const BoxRegister: React.FC<TBoxRegisterProps> = ({
  messages,
  settings,
}: TBoxRegisterProps) => {
  return settings?.register_form_customer_type?.toUpperCase() === "B2B" ? (
    <BusinessRegisterForm messages={messages} settings={settings} />
  ) : (
    <ClientRegisterForm messages={messages} settings={settings} />
  );
};

export default BoxRegister;
