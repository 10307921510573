import StyledText from "@ecp-boxes/shared/styleElements/StyledText/StyledText";
import { IElementText } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { StyledDeliveryTimeWrapper } from "../BoxProductAttributes.styled";

interface IDeliveryTimeDisplay {
  attributeValue: string[];
  textStyle: IElementText;
}

const DeliveryTimeDisplay: React.FC<IDeliveryTimeDisplay> = ({
  attributeValue,
  textStyle,
}) => {
  return (
    <StyledDeliveryTimeWrapper>
      {attributeValue.map((value, index) => {
        const isLast = index === attributeValue.length - 1;
        return (
          <StyledText
            key={`singleDeliveyTimeItem-${index}`}
            className="singleDeliveyTimeItem product-attributes-container__attribute__value__text"
            $settings={{
              font: textStyle.font,
              text: { color: textStyle.text.color },
            }}
          >
            {value}
            {!isLast && ", "}
          </StyledText>
        );
      })}
    </StyledDeliveryTimeWrapper>
  );
};

export default DeliveryTimeDisplay;
