import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";
import * as myOrdersAPI from "@ecp-redux/api/myOrders";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import Popup from "../../../shared/components/Popup/Popup";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledReorderPopup } from "../BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";

interface IReorderPopupProps {
  orderId: string;
  onReorderError: () => void;
  closePopup: () => void;
}

const StyledReorderPopupWrapper = styled(Popup)`
  padding: 30px;
}`;

const ReorderPopup: React.FC<IReorderPopupProps> = ({
  orderId,
  closePopup,
  onReorderError,
}) => {
  const { settings, messages } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();
  const router = useRouter();

  const [mergeProduct] = myOrdersAPI.usePostMyOrdersMergeProductMutation();

  return (
    <StyledReorderPopupWrapper
      desktopWidth="600px"
      className="reorder-popup"
      closePopup={closePopup}
    >
      <StyledReorderPopup>
        <StyledText
          className="reorder-popup__title"
          $settings={{
            font: settings.popup_title_typo,
            text: { color: settings.popup_title_color },
          }}
          show={isNotEmptyMessage(messages.customer_data_reorder_popup_title)}
        >
          {messages.customer_data_reorder_popup_title}
        </StyledText>
        <StyledText
          className="reorder-popup__description"
          $settings={{
            font: settings.popup_description_typo,
            text: { color: settings.popup_description_color },
          }}
          show={isNotEmptyMessage(
            messages.customer_data_reorder_popup_description
          )}
        >
          {messages.customer_data_reorder_popup_description}
        </StyledText>
        <div className="reorder-popup__button-container">
          <StyledButton
            className="reorder-popup__button-container__save-button"
            $settings={settings.popup_yes_button}
            renderAs={"button"}
            data-testid="yesPopupBtn"
            onClick={() => {
              mergeProduct({ orderId })
                .unwrap()
                .then(() => {
                  router.push(settings.customer_data_reorder_success_link);
                })
                .catch((err) => {
                  onReorderError();
                });
            }}
            show={isNotEmptyMessage(
              messages.customer_data_reorder_popup_button_save
            )}
          >
            {messages.customer_data_reorder_popup_button_save}
          </StyledButton>
          <StyledButton
            className="reorder-popup__button-container__cancel-button"
            $settings={settings.popup_no_button}
            renderAs={"button"}
            onClick={() => closePopup()}
            show={isNotEmptyMessage(
              messages.customer_data_reorder_popup_button_cancel
            )}
          >
            {messages.customer_data_reorder_popup_button_cancel}
          </StyledButton>
        </div>
      </StyledReorderPopup>
    </StyledReorderPopupWrapper>
  );
};

export default ReorderPopup;
