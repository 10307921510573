import { FC, useState } from "react";
import styled, { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledLinkWrapper } from "../../../../global/components/LinkWrapper/LinkWrapper";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import { useCartB2BContext } from "../../../../shared/components/domain/CartB2B/useCartB2BContext";
import useIsMobilePortal from "../../../../shared/hooks/useIsMobilePortal";
import TrashIcon from "../../../../shared/icons/TrashIcon";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { IBoxCartB2BMessages, IBoxCartB2BSettings } from "../BoxCartB2B.types";
import ClearCartB2BPopup from "./ClearCartB2BPopup";
import SwitcherB2B from "./SwitcherB2B/SwitcherB2B";

const StyledCartB2BHeader = styled.div(
  ({
    theme,
    $settings,
  }: {
    theme: IThemeState;
    $settings: IBoxCartB2BSettings;
  }) => {
    const isMobile = useIsMobilePortal();

    return `
        display: flex;
        justify-content: space-between;
        padding: ${isMobile ? "5px 20px" : "20px"};
        border-bottom: ${
          $settings.basket_header_line_size
        } solid ${convertColorIdToHex(
      $settings.basket_header_line_color,
      theme.colorPalette
    )};
  
      


    .cart-step-one__content {
      &__header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: baseline;  

        ${
          isMobile
            ? `
              flex-direction: column;
              gap: 10px;
            `
            : ""
        }

        div {
         display: flex;
        } 
      }

   

      &__header {
        display: flex;
        gap: 10px;
        align-items: center;
        height: fit-content;
      }

      &__header-title {
        margin-right: 20px;
      }

      &__header-products-count {
        margin-right: 10px;
      }

      &__header-orders-count {
        margin-right: 8px;
      }
      
      &__clear-cart {
        cursor: pointer;
      }

      &__header-prices-switcher {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: 900px) {
          flex-direction: column;
          gap: 5px;
          align-items: ${isMobile ? "end" : "flex-start"};
        }
      }

     
    }
    `;
  }
);

interface ICartB2BHeaderProps {
  articlesCount: number;
  ordersCount: number;
}

const CartB2BHeader: FC<ICartB2BHeaderProps> = ({
  articlesCount,
  ordersCount,
}) => {
  const { colorPalette } = useTheme() as IThemeState;
  const isMobile = useIsMobilePortal();

  const [popupOpen, setPopupOpen] = useState(false);
  const { clearCart } = useCartB2BContext();
  const {
    messages: {
      basket_header_title_label,
      basket_header_products_count,
      basket_header_orders_count,
      basket_header_prices_switch_label,
      mobile_basket_remove_cart,
    },
    settings: {
      basket_header_typo,
      basket_header_color,
      basket_data_typo,
      basket_data_color,
      basket_remove_icon_size,
      basket_remove_icon_color,
      mobile_basket_remove_cart_typo,
      mobile_basket_remove_cart_color,
      mobile_basket_remove_cart_link_style,
    },
    settings,
  } = useMessagesSettingsContext<IBoxCartB2BMessages, IBoxCartB2BSettings>();

  return (
    <>
      <StyledCartB2BHeader $settings={settings}>
        <div className="cart-step-one__content__header-wrapper">
          <StyledText
            data-testid="cart-step-one-header-title"
            className="cart-step-one__content__header-title"
            $settings={{
              font: basket_header_typo,
              text: {
                color: basket_header_color,
              },
            }}
          >
            {basket_header_title_label}
          </StyledText>
          <div>
            <StyledText
              className="cart-step-one__content__header-products-count"
              $settings={{
                font: basket_data_typo,
                text: {
                  color: basket_data_color,
                },
              }}
            >
              {`${articlesCount} ${basket_header_products_count}`}
            </StyledText>
            <StyledText
              className="cart-step-one__content__header-orders-count"
              $settings={{
                font: basket_data_typo,
                text: {
                  color: basket_data_color,
                },
              }}
            >
              {`(${ordersCount} ${basket_header_orders_count})`}
            </StyledText>

            {!isMobile && (
              <div
                data-testid="clear-cart"
                className="cart-step-one__content__clear-cart"
                onClick={(e) => {
                  setPopupOpen(true);
                }}
              >
                <TrashIcon
                  stroke={convertColorIdToHex(
                    basket_remove_icon_color,
                    colorPalette
                  )}
                  width={basket_remove_icon_size}
                  height={basket_remove_icon_size}
                />
              </div>
            )}
          </div>
        </div>

        <div className="cart-step-one__content__header-prices-switcher">
          {!isMobile ? (
            <StyledText
              className="cart-step-one__content__header-prices-label"
              $settings={{
                font: basket_data_typo,
                text: {
                  color: basket_data_color,
                },
              }}
            >
              {basket_header_prices_switch_label}
            </StyledText>
          ) : (
            <StyledLinkWrapper
              className="cart-step-one__content__header-prices-label"
              $settings={{
                font: mobile_basket_remove_cart_typo,
                text: {
                  color: mobile_basket_remove_cart_color,
                },
              }}
              onClick={() => {
                setPopupOpen(true);
              }}
              $linkStyle={mobile_basket_remove_cart_link_style}
            >
              {mobile_basket_remove_cart}
            </StyledLinkWrapper>
          )}
          <SwitcherB2B />
        </div>
      </StyledCartB2BHeader>
      {popupOpen && (
        <ClearCartB2BPopup
          confirmPopup={() => {
            clearCart().then(() => setPopupOpen(false));
          }}
          closePopup={() => setPopupOpen(false)}
        />
      )}
    </>
  );
};

export default CartB2BHeader;
