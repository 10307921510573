import { useState } from "react";
import CloseIcon from "../../../../shared/icons/CloseIcon";
import StyledButton from "../../../../shared/styleElements/StyledButton/StyledButton";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSearchResultsContent,
  IBoxSearchResultsContentSettings,
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../../BoxSearchResults.types";
import { SortOption } from "../Sort.types";
import { useSearchResultsLogic } from "../useSearchResultsLogic";
import ExtendedShowOnPage from "./ExtendedShowOnPage";
import ExtendedSort from "./ExtendedSort";
import {
  StyledCloseArea,
  StyledNavigationLeftArea,
} from "./ExtendedTopNavigation.styled";

interface ExtendedTopNavigation {
  changeProductAmount: (perPage: number, sort: SortOption) => void;
  productsPerPage: number;
  closingNavigation: () => void;
  contentSettings: IBoxSearchResultsContentSettings;
  content: IBoxSearchResultsContent;
}

const ExtendedTopNavigation: React.FC<ExtendedTopNavigation> = ({
  changeProductAmount,
  productsPerPage,
  closingNavigation,
  contentSettings: { showOnPage, sorting },
  content: { showOnPageText, sortingText },
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchResultsMessages,
    IBoxSearchResultsSettings
  >();

  const { choosenSortQuery } = useSearchResultsLogic();

  const [temporaryProductsPerPage, setTemporaryProductsPerPage] =
    useState<number>(productsPerPage);
  const [temporarySortingOption, setTemporarySortingOption] =
    useState<SortOption>(choosenSortQuery);

  return (
    <div className="extended-top-navigation">
      <StyledNavigationLeftArea className="navigation__left-area">
        <ExtendedSort
          sortSettings={sorting}
          label={sortingText}
          temporarySortingOption={temporarySortingOption}
          chooseTemporarySortingOption={setTemporarySortingOption}
        />
        <ExtendedShowOnPage
          showOnPageSettings={showOnPage}
          label={showOnPageText}
          chooseTemporaryProductsPerPage={setTemporaryProductsPerPage}
          temporaryProductsPerPage={temporaryProductsPerPage}
        />
        <div className="navigation__left-area__button-container">
          <div className="navigation__left-area__separator"></div>
          <StyledButton
            data-testid="showResultButton"
            renderAs="button"
            $settings={settings.srp_mobile_show_result_button_style}
            onClick={() => {
              changeProductAmount(
                temporaryProductsPerPage,
                temporarySortingOption
              );
              closingNavigation();
            }}
          >
            {messages.srp_mobile_show_result_button_label}
          </StyledButton>
        </div>
      </StyledNavigationLeftArea>
      <StyledCloseArea
        backgroundColor={settings.srp_mobile_right_background_color}
        data-testid="closeArea"
        onClick={closingNavigation}
      >
        <CloseIcon width={24} height={24} fill="white" />
      </StyledCloseArea>
    </div>
  );
};

export default ExtendedTopNavigation;
