import styled, { css } from "styled-components";
import {
  AlignmentHorizontalOption,
  IThemeState,
  TColorId,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { IBoxMiniCartB2BSettings } from "./BoxMiniCartB2B.types";

export const StyledBoxMiniCartB2BButton = styled.div<{
  alignment: AlignmentHorizontalOption;
  backgroundColor: TColorId;
  iconSize: TSizeCSS;
}>(({ alignment, backgroundColor, iconSize, theme }) => {
  return css`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: ${alignmentOptionsToValue(alignment)};

    .mini-cart-b2b-button__container {
      display: flex;
      position: relative;
      align-items: center;
      cursor: pointer;

      &__products {
        margin-bottom: calc(${iconSize} + 20px);
        margin-left: -15px;
        padding: 0 12px;
        border-radius: 50%;
        background-color: ${convertColorIdToHex(
          backgroundColor,
          theme.colorPalette
        )};
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        aspect-ratio: 1;
        height: auto;
      }
    }
  `;
});

export const StyledBoxMiniCartB2BWrapper = styled.div``;

export const StyledOrderAccordionMiniCartB2B = styled.div(
  ({
    settings,
    theme,
    isExpanded,
  }: {
    settings: IBoxMiniCartB2BSettings;
    theme: IThemeState;
    isExpanded: boolean;
  }) => {
    return css`
      display: flex;
      padding: 5px 0;
      gap: 10px;
      user-select: none;
      flex-direction: column;
      border-bottom: ${settings.mini_cart_orders_separator_line_size} solid
        ${convertColorIdToHex(
          settings.mini_cart_orders_separator_line_color,
          theme.colorPalette
        )};

      .order-accordion {
        &__accordion-header {
          display: flex;
          padding: 5px 0;
          gap: 10px;
          align-items: center;
          cursor: pointer;

          &__arrow {
            transition: transform 0.3s;
            transform: rotate(${isExpanded ? "-180deg" : "0deg"});
          }
        }

        &__accordion-content {
          display: flex;
          flex-direction: column;
          gap: 10px;

          &__logistic-bar-wrapper {
            width: 100%;
          }

          &__order {
            display: flex;
            gap: 15px;

            &__product-image {
              width: 60px;
              height: 60px;
              min-width: 60px;
            }

            &__product-info {
              width: 100%;

              &__line {
                display: flex;
                justify-content: space-between;
                align-items: end;

                div {
                  display: flex;
                  flex-direction: column;
                  align-items: end;
                }
              }
            }
          }
        }
      }
    `;
  }
);
