import styled from "styled-components";

export const StyledArticleContent = styled.div`
  display: grid;

  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  ol,
  blockquote,
  figure {
    margin-bottom: 1em;
  }
  strong {
    font-weight: bold;
  }
  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
    p {
      margin-top: 1em;
    }
  }

  table {
    overflow: hidden;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
    margin: 0.9em auto;
  }

  table td,
  table th {
    overflow-wrap: break-word;
    position: relative;
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid hsl(0, 0%, 75%);
  }

  table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
  }

  figcaption {
    background-color: #f4f5f7;
    text-align: center;
  }
`;
