import styled from "styled-components";
import { chooseHorizontalAlignValue } from "../../settingsPatterns/composeCss";
import { TBoxVariant } from "../../shared/components/domain/Product/ProductVariants/ProductVariants.types";

export const StyledBoxProductVariants = styled.div<{ variant: TBoxVariant }>(
  ({ variant }) => {
    return `
      .variants-wrapper {
        display: flex;
        flex-direction: column;
        align-items: ${chooseHorizontalAlignValue(variant.variantsAlignment)};

        .label {
          margin-bottom: ${variant.label.spacingBetweenLabelAndVariants}px;
          text-align: ${variant.label.labelAlignment};
          max-width: ${variant.rowWidth}%;
          width: 100%;
        }

          
        .variants-content {
          display: flex;
          flex-wrap: wrap;
          gap: ${variant.spacingElementsInRow}px;
          row-gap: ${variant.spacingBetweenRows}px;
          justify-content: ${variant.variantsAlignment};
          max-width: ${variant.rowWidth}%;
        }

      }
    `;
  }
);
