import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import { GlobalTableCellReadoutValue } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import {
  AlignmentHorizontalOption,
  TButtonReadoutValue,
  TInputReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { StyledTableCell } from "../../../shared/components/Table/TableCell";
import ChangeProductQuantityB2B from "../../../shared/components/domain/CartB2B/ChangeProductQuantityB2B";

interface ITableDataAddToCartActionCell {
  tableCellStyleId: GlobalTableCellReadoutValue;
  inputStyleId: TInputReadoutValue;
  plusButtonStyleId: TButtonReadoutValue;
  minusButtonStyleId: TButtonReadoutValue;
  plusLabel: string;
  minusLabel: string;
  spaceBetweenInputButtons: number;
  actionAlignment: AlignmentHorizontalOption;
  isOdd: boolean;
  product: ISrpTransformResponseV2;
  unitOfMeasure: string;
}

export const TableDataAddToCartActionCell: React.FC<ITableDataAddToCartActionCell> =
  ({
    tableCellStyleId,
    isOdd,
    inputStyleId,
    plusLabel,
    minusButtonStyleId,
    minusLabel,
    plusButtonStyleId,
    spaceBetweenInputButtons,
    product,
    unitOfMeasure,
    actionAlignment,
  }) => {
    return (
      <StyledTableCell
        tableCellStyleId={tableCellStyleId}
        isOdd={isOdd}
        actionAlignment={actionAlignment}
      >
        <ChangeProductQuantityB2B
          product={{
            sku: product.sku,
            deliveryQuantity: product.deliveryQuantity,
            unitOfMeasure: {
              currentUnitOfMeasure: unitOfMeasure,
              availableUnitsOfMeasure: product.alternativeUnitOfMeasures.map(
                (unit) => ({
                  unitOfMeasure: unit.auoM,
                  unitOfMeasureLabel: unit.auoMLabel,
                  unitOfMeasureQuantity: unit.value,
                })
              ),
            },
            concession: product.concession,
          }}
          minusButtonQuantityStyle={minusButtonStyleId}
          plusButtonQuantityStyle={plusButtonStyleId}
          changeProductQuantityMinusLabel={minusLabel}
          changeProductQuantityPlusLabel={plusLabel}
          inputQuantityStyle={inputStyleId}
          spaceBetweenInputButtons={spaceBetweenInputButtons}
          isCart={false}
        />
      </StyledTableCell>
    );
  };

export default TableDataAddToCartActionCell;
