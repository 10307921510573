import { useGetProductAttributesContext } from "../../helpers/pageContext";
import ChangeProductQuantityB2B from "../../shared/components/domain/CartB2B/ChangeProductQuantityB2B";
import { IBoxAddToCartQuantityB2BProps } from "./BoxAddToCartQuantityB2B.types";

const BoxAddToCartQuantityB2B: React.FC<IBoxAddToCartQuantityB2BProps> = ({
  messages,
  settings,
}) => {
  const product = useGetProductAttributesContext();

  if (product === null) return null;
  return (
    <ChangeProductQuantityB2B
      product={{
        sku: product.sku,
        deliveryQuantity: product.deliveryQuantity,
        unitOfMeasure: {
          currentUnitOfMeasure: product.mainUnitOfMeasurement,
          availableUnitsOfMeasure: product.alternativeUnitOfMeasures.map(
            (unit) => ({
              unitOfMeasure: unit.auoM,
              unitOfMeasureLabel: unit.auoMLabel,
              unitOfMeasureQuantity: unit.value,
            })
          ),
        },
        concession: product.concession,
      }}
      minusButtonQuantityStyle={settings.remove_from_cart_quantity_button_style}
      plusButtonQuantityStyle={settings.add_to_cart_quantity_button_style}
      inputQuantityStyle={settings.add_to_cart_quantity_input_style}
      changeProductQuantityMinusLabel={messages.add_to_cart_quantity_minus}
      changeProductQuantityPlusLabel={messages.add_to_cart_quantity_plus}
      isCart={false}
    />
  );
};

export default BoxAddToCartQuantityB2B;
