import { useState } from "react";
import classNames from "classnames";
import { getDate } from "date-fns";
import { useTheme } from "styled-components";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import ClearProductTypeB2BPopup from "../../../../../boxes/CartB2B/BoxCartB2B/components/ClearProductTypeB2BPopup";
import LogisticMinimumProgressBar from "../../../../../boxes/CartB2B/BoxCartB2B/components/LogisticMinimumProgressBar";
import { convertColorIdToHex } from "../../../../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import TrashIcon from "../../../../../shared/icons/TrashIcon";
import { ReactComponent as ArrowDown } from "../../../../../shared/icons/arrowDown.svg";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import {
  getMessageForDay,
  getMessageForMonth,
} from "../DeliveryCalendarB2B/DeliveryCalendarB2B.methods";
import OrderB2B from "../Order/OrderB2B";
import { useCartB2BContext } from "../useCartB2BContext";
import { StyledOrderAccordion } from "./OrderAccordion.styled";

const OrderAccordion = ({ order, idx }: { order: IOrderB2B; idx: number }) => {
  const isMobile = useIsMobilePortal();

  const colorPalette = (useTheme() as IThemeState).colorPalette;
  const [isExpanded, setIsExpanded] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [deliveryCalendarOpen, setDeliveryCalendarOpen] = useState(false);

  const { clearProductType } = useCartB2BContext();

  const {
    messages: {
      basket_single_order_title_label,
      basket_single_order_products_count,
    },
    messages,
    settings: {
      basket_remove_icon_size,
      basket_remove_icon_color,
      basket_orders_list_background_color,
      basket_header_section_typo,
      basket_header_section_color,
      basket_data_typo,
      basket_data_color,
      mobile_basket_delivery_icon_URL,
      mobile_basket_delivery_icon_size,
      mobile_basket_delivery_date_typo,
      mobile_basket_delivery_date_color,
      mobile_basket_delivery_edit_icon_URL,
      mobile_basket_delivery_edit_icon_size,
    },
  } = useMessagesSettingsContext<IBoxCartB2BMessages, IBoxCartB2BSettings>();

  return (
    <StyledOrderAccordion
      background_color={convertColorIdToHex(
        basket_orders_list_background_color,
        colorPalette
      )}
      isExpanded={isExpanded}
      className="order-accordion"
    >
      <div className="order-accordion__scroll-wrapper">
        <div
          className="order-accordion__header"
          data-testid="order-accordion-header"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          <div className="order-accordion__header-left">
            <ArrowDown
              width="24px"
              height="24px"
              className={classNames("order-accordion__header-left__arrow", {
                "order-accordion__header-left__arrow--expanded": isExpanded,
              })}
            />
            <StyledText
              $settings={{
                font: basket_header_section_typo,
                text: { color: basket_header_section_color },
              }}
            >
              {`${basket_single_order_title_label} ${idx + 1}`}
            </StyledText>
            <StyledText
              renderAs="span"
              $settings={{
                font: basket_data_typo,
                text: { color: basket_data_color },
              }}
            >
              {`(${order.cartLines.length} ${basket_single_order_products_count})`}
            </StyledText>
            <div
              data-testid="remove-order"
              className="remove-order"
              onClick={(e) => {
                e.stopPropagation();
                setPopupOpen(true);
              }}
            >
              <TrashIcon
                stroke={convertColorIdToHex(
                  basket_remove_icon_color,
                  colorPalette
                )}
                width={basket_remove_icon_size}
                height={basket_remove_icon_size}
              />
            </div>
          </div>

          <div className="order-accordion__header-right">
            <LogisticMinimumProgressBar order={order}  />
            {isMobile && (
              <div
                className="order-accordion__header-right__delivery-date-mobile"
                data-testid="delivery-date-mobile"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeliveryCalendarOpen((prev) => !prev);
                  setIsExpanded(true);
                }}
              >
                <div
                  className="order-accordion__header-right__delivery-date-mobile__content
                "
                >
                  {mobile_basket_delivery_icon_URL !== "" && (
                    <img
                      alt="delivery icon"
                      width={mobile_basket_delivery_icon_size}
                      src={mobile_basket_delivery_icon_URL}
                    />
                  )}
                  <StyledText
                    renderAs="span"
                    $settings={{
                      font: mobile_basket_delivery_date_typo,
                      text: { color: mobile_basket_delivery_date_color },
                    }}
                  >
                    {order.deliveryDate
                      ? `${getMessageForDay(
                          order.deliveryDate,
                          messages
                        )} ${getDate(order.deliveryDate)} ${getMessageForMonth(
                          order.deliveryDate,
                          messages
                        )}`
                      : ""}
                  </StyledText>
                </div>
                {mobile_basket_delivery_edit_icon_URL !== "" && (
                  <img
                    className="order-accordion__header-right__delivery-date-mobile__edit-icon"
                    alt="edit delivery icon"
                    width={mobile_basket_delivery_edit_icon_size}
                    src={mobile_basket_delivery_edit_icon_URL}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {isExpanded && (
          <OrderB2B
            handleCloseCalendar={() => setDeliveryCalendarOpen(false)}
            deliveryCalendarOpen={isMobile ? deliveryCalendarOpen : true}
            order={order}
          />
        )}
      </div>
      {popupOpen && (
        <ClearProductTypeB2BPopup
          confirmPopup={() => {
            clearProductType(order.productType).then(() => setPopupOpen(false));
          }}
          closePopup={() => setPopupOpen(false)}
        />
      )}
    </StyledOrderAccordion>
  );
};

export default OrderAccordion;
