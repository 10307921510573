import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  ISpacing,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss, {
  alignmentOptionsToValue,
} from "../../settingsPatterns/composeCss";

export const StyledListOfButtons = styled.div<{
  boxWidth: number;
  spaceBetweenButtons: number;
  spaceBetweenRows: number;
  alignment: AlignmentHorizontalOption;
  padding: ISpacing;
  vertical: boolean;
}>(
  ({
    boxWidth,
    spaceBetweenButtons,
    spaceBetweenRows,
    alignment,
    padding,
    vertical,
  }) => {
    return `
        width: 100%;
        display: flex;
        justify-content: center;

        .list-of-buttons {
          &__container {
            display: flex;
            flex-wrap: wrap;
            column-gap: ${spaceBetweenButtons}px;
            row-gap: ${spaceBetweenRows}px;
            padding: ${composeCss.padding(padding)};
            justify-content: ${alignmentOptionsToValue(alignment)};
            width: ${vertical ? `100%` : `${boxWidth}%`};
            ${vertical ? `flex-direction: column` : ``};
            align-items: ${alignmentOptionsToValue(alignment)};

            &__item {
              cursor: pointer;
            }
          }
        }
    `;
  }
);
