import React from "react";
import ConditionalWrapper from "../../../../global/components/ConditionalWrapper/ConditionalWrapper";
import DynamicLinkWrapper from "../../../../global/components/DynamicLinkWrapper/DynamicLinkWrapper";
import { getImageNameFromUrl } from "../../../../helpers/helpers";
import serializeHtmlFromDescendant from "../../../../helpers/serializeHtmlFromDescendant";
import {
  MultiMenuItemType,
  TMenuColumnContent,
  TMenuGroupContent,
} from "../../../../settingsPatterns/boxes/BoxMultiMenu/StyledMultiMenu.types";
import StyledImageInline from "../../../../settingsPatterns/elements/StyledImageInline/StyledImageInline";
import StyledRichText from "../../../../settingsPatterns/elements/StyledRichText/StyledRichText";
import ArrowLeftIcon from "../../../../shared/icons/ArrowLeftIcon";
import { PortalPath } from "../../../../shared/portalPath/portalPath";
import {
  getCurrGroupSett,
  getCurrItemSettMultimenuLine,
  hrefWrapper,
} from "../../BoxMultiMenu.methods";
import { IMultimenuHorizontalSettings } from "../../BoxMultiMenu.types";
import {
  StyledMultiMenuColumnHeader,
  StyledMultimenuMobileColumnItems,
} from "./MultiMenuMobile.styled";

interface IMultiMenuMobileColumn {
  settings: IMultimenuHorizontalSettings;
  closeColumn: () => void;
  groupIndex: number;
  group: TMenuGroupContent;
  columns: TMenuColumnContent[];
  path: PortalPath;
}

const MultiMenuMobileColumn: React.FC<IMultiMenuMobileColumn> = ({
  settings,
  closeColumn,
  groupIndex,
  group,
  columns,
  path,
}) => {
  return (
    <StyledMultimenuMobileColumnItems
      backgroundColor={settings?.multimenu_mobile_right_background_color}
    >
      <div className="wrapper">
        <StyledMultiMenuColumnHeader onClick={closeColumn}>
          <ArrowLeftIcon className="leftIcon" />
          <DynamicLinkWrapper
            path={path}
            linkStyle={
              settings.items?.find(
                (item) =>
                  item.id ===
                  getCurrGroupSett(groupIndex, settings)?.linkType?.style
              )?.link
            }
            fontStyle={
              settings.items?.find(
                (item) =>
                  item.id ===
                  getCurrGroupSett(groupIndex, settings)?.linkType?.style
              )?.font
            }
            icon={{
              iconUrl: group?.linkType?.iconUrl,
              ...settings?.groups[groupIndex]?.linkType.icon,
            }}
            link={group?.link}
          >
            <>
              <i className="icon"></i>
              {group.heading}
            </>
          </DynamicLinkWrapper>
        </StyledMultiMenuColumnHeader>

        {columns.map((column, columnIndex) => {
          return column?.items?.map((item, itemIndex) => {
            return (
              <div key={item.heading + itemIndex}>
                {getCurrItemSettMultimenuLine(
                  [groupIndex, columnIndex, itemIndex],
                  settings
                )?.type === MultiMenuItemType.LINK && (
                  <DynamicLinkWrapper
                    linkStyle={
                      settings.items?.find(
                        (item) =>
                          item.id ===
                          getCurrItemSettMultimenuLine(
                            [groupIndex, columnIndex, itemIndex],
                            settings
                          )?.linkType?.style
                      )?.link
                    }
                    fontStyle={
                      settings.items?.find(
                        (item) =>
                          item.id ===
                          getCurrItemSettMultimenuLine(
                            [groupIndex, columnIndex, itemIndex],
                            settings
                          )?.linkType?.style
                      )?.font
                    }
                    icon={{
                      iconUrl: item.linkType?.iconUrl,
                      ...getCurrItemSettMultimenuLine(
                        [groupIndex, columnIndex, itemIndex],
                        settings
                      )?.linkType?.icon,
                    }}
                    link={item.linkType?.link}
                  >
                    <>
                      <i className="icon"></i>
                      {item.heading}
                    </>
                  </DynamicLinkWrapper>
                )}
                {getCurrItemSettMultimenuLine(
                  [groupIndex, columnIndex, itemIndex],
                  settings
                )?.type === MultiMenuItemType.TEXT && (
                  <StyledRichText
                    $settings={{
                      ...settings,
                      text: {
                        color: settings.text,
                      },
                    }}
                  >
                    {serializeHtmlFromDescendant(JSON.parse(item.textType))}
                  </StyledRichText>
                )}
                {getCurrItemSettMultimenuLine(
                  [groupIndex, columnIndex, itemIndex],
                  settings
                )?.type === MultiMenuItemType.IMAGE && (
                  <ConditionalWrapper
                    wrapper={(children) =>
                      hrefWrapper(
                        children,
                        item.imageType.link.goToPageSetting?.link ?? ""
                      )
                    }
                    condition={
                      item.imageType.link?.goToPageSetting?.link
                        ? item.imageType.link?.goToPageSetting?.link.length > 0
                        : false
                    }
                  >
                    <StyledImageInline
                      src={item.imageType.url}
                      alt={
                        item.imageType.seoDescription
                          ? item.imageType.seoDescription
                          : getImageNameFromUrl(item.imageType.url)
                      }
                      $settings={{
                        imageUrl: item.imageType?.url,
                        ...item.imageType,
                      }}
                      className={`multi-menu-container__list__single-item-${itemIndex}__image`}
                    />
                  </ConditionalWrapper>
                )}
              </div>
            );
          });
        })}
      </div>
    </StyledMultimenuMobileColumnItems>
  );
};

export default MultiMenuMobileColumn;
