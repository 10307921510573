import { useEffect } from "react";
import { cart } from "@ecp-redux/api/cart";
import { searchResults } from "@ecp-redux/api/searchResults";
import {
  IAddToCartQuantity,
  ProductQuantityWithMaxError,
} from "@ecp-redux/dto/cart.types";
import { IElementButton } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  TButtonId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isB2BClient } from "@ecp-redux/helpers";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError, QueryStatus } from "@reduxjs/toolkit/dist/query";
import {
  IBoxAddToCartMessages,
  IBoxAddToCartSettings,
} from "../../../../../boxes/BoxAddToCart/BoxAddToCart.types";
import { useCommunicationBetweenBoxesContext } from "../../../../../structure/Contexts/CommunicationBetweenBoxes";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import StyledButton from "../../../../styleElements/StyledButton/StyledButton";
import { useOpenPortalSnackbar } from "../../../Snackbar/Snackbar";
import { findCartLineBySku } from "../../CartB2B/cartB2B.methods";
import { useCartB2BContext } from "../../CartB2B/useCartB2BContext";
import { useCart } from "../cart.methods";
import { StyledAddToCartWrapper } from "./AddToCart.styled";

interface IAddToCartProps {
  buttonText: string;
  buttonStyle: IElementButton | TButtonId;
  itemId: string | undefined;
}

const useAddToCartLimitHandler = (
  result: {
    requestId?: string;
    error?: FetchBaseQueryError | SerializedError;
    status: QueryStatus;
  },
  changeAddToCartQuantity: (v: number | IAddToCartQuantity) => void,
  itemId: string | undefined
) => {
  const miniCartData = cart.useGetMiniCartByPortalUserTokenQuery(undefined, {
    skip: isB2BClient(),
  });

  useEffect(() => {
    if (isB2BClient()) return;

    if (result.status === "rejected" && result.error !== undefined) {
      const productQuantityError = result.error as ProductQuantityWithMaxError;
      const actualProductInCart = miniCartData.data?.content?.orderLines?.find(
        (p) => p.productSku === itemId
      );
      const actualProductQuantity = actualProductInCart
        ? actualProductInCart.quantity
        : 0;
      if (productQuantityError.data?.code === 2014) {
        const productCartLimitQuantity = Number(
          productQuantityError.data?.data?.PRODUCT_CART_LIMIT_QUANTITY
        );
        changeAddToCartQuantity({
          status: "ERROR",
          quantity: productCartLimitQuantity - actualProductQuantity,
        });
      }

      if (productQuantityError.data?.code === 2015) {
        const productQuantityStockLimit = Number(
          productQuantityError.data?.data?.PRODUCT_QUANTITY_STOCK_LIMIT
        );
        changeAddToCartQuantity({
          status: "ERROR",
          quantity: productQuantityStockLimit - actualProductQuantity,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result.requestId, result.status]);
};

const AddToCart: React.FC<IAddToCartProps> = ({
  buttonText,
  buttonStyle,
  itemId,
}) => {
  const [postAddToCart, result] = cart.usePostAddProductQuantityMutation();

  const { addToCartQuantity, changeAddToCartQuantity } =
    useCommunicationBetweenBoxesContext();

  const { settings, messages } = useMessagesSettingsContext<
    IBoxAddToCartMessages,
    IBoxAddToCartSettings
  >();

  const { openSnackbarWithCartError } = useCart();
  const { cartData } = useCartB2BContext();

  useAddToCartLimitHandler(result, changeAddToCartQuantity, itemId);

  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const [getB2BProduct] = searchResults.useLazyGetSearchWithSrpResultsV2Query();

  const handleAddToCart = async () => {
    if (itemId) {
      try {
        let unitOfMeasure = undefined;
        let quantity =
          "add_to_cart_quantity" in settings &&
          settings.add_to_cart_quantity === "true"
            ? addToCartQuantity.quantity
            : 1;

        if (isB2BClient()) {
          const product = await getB2BProduct({
            projection: ["mainUnitOfMeasurement"],
            searchQueries: `filter=sku:${itemId}`,
          });

          quantity += findCartLineBySku(cartData, {
            sku: itemId,
          }).quantity;
          unitOfMeasure = product?.data?.products?.[0]?.mainUnitOfMeasurement;
        }

        await postAddToCart({
          sku: itemId,
          quantity,
          isCart: false,
          unitOfMeasure,
        }).unwrap();

        openPortalSnackbar(
          settings.add_to_cart_success_snackbar,
          messages.add_to_cart_success_message
        );
      } catch (e) {
        openSnackbarWithCartError(e);
      }
    }
  };

  return (
    <StyledAddToCartWrapper
      className="add-to-cart__container"
      $alignment={
        "add_to_cart_aligment" in settings
          ? settings.add_to_cart_aligment
          : AlignmentHorizontalOption.CENTER
      }
    >
      <StyledButton
        className="add-to-cart__button"
        onClick={handleAddToCart}
        renderAs="button"
        role="button"
        data-testid="add-to-cart-button"
        $settings={buttonStyle}
        disabled={result.isLoading}
      >
        <i />
        {buttonText}
      </StyledButton>
    </StyledAddToCartWrapper>
  );
};

export default AddToCart;
