import { useMemo } from "react";
import { Swiper as SwiperProps } from "swiper";
import { checkSlidesPerView } from "../../helpers/helpers";
import { SliderPaginationOptions } from "../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import useCountSlidesPerView from "../../shared/hooks/useCountSlidesPerView";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import {
  BoxTilesSliderContentScheme,
  BoxTilesSliderContentSettingsScheme,
  BoxTilesSliderDisplaySettings,
  BoxTilesSliderDisplaySettingsScheme,
} from "./BoxTilesSlider.scheme";
import {
  IBoxTilesSliderProps,
  TilesSliderRotateOptions,
} from "./BoxTilesSlider.types";

export const getPaginationStyle = (
  displaySettings: BoxTilesSliderDisplaySettings
) => {
  if (displaySettings.slideRotateType === TilesSliderRotateOptions.SMOOTHLY)
    return { pagination: false, navigation: false };

  switch (displaySettings.paginationStyle) {
    case SliderPaginationOptions.DOTS:
      return {
        pagination: {
          clickable: true,
          renderBullet: undefined,
        },
      };
    case SliderPaginationOptions.ARROWS:
      return {
        pagination: false,
        navigation: true,
      };

    default:
      return { navigation: false, pagination: false };
  }
};

export const useBoxTilesSlider = (props: IBoxTilesSliderProps) => {
  const isMobile = useIsMobilePortal();
  const content = BoxTilesSliderContentScheme.parse(props.content);
  const contentSettings = BoxTilesSliderContentSettingsScheme.parse(
    props.contentSettings
  );
  const displaySettings = BoxTilesSliderDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const numberProductPerSlide = useMemo(
    () => Math.min(contentSettings.numberOfTilesPerSlide, content.tiles.length),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentSettings.numberOfTilesPerSlide, content.tiles.length]
  );

  const count = useCountSlidesPerView(
    numberProductPerSlide,
    contentSettings.numberOfTilesPerSlide,
    content.tiles.length
  );

  return {
    content,
    contentSettings,
    displaySettings,
    numberProductPerSlide,
    count,
    getSwiperCustomConfiguration: () => {
      const getAutoplay = (customDelay?: number) => {
        if (props.editMode === false && displaySettings.autoRotate) {
          return {
            autoplay: {
              delay: customDelay ?? displaySettings.timeBetweenSlides * 1000,
            },
          };
        }
        return false;
      };

      const commonConfiguration = {
        slidesPerView: checkSlidesPerView(count, isMobile ?? false),
        ...getPaginationStyle(displaySettings),
        loop: true,
      };

      switch (displaySettings.slideRotateType) {
        case TilesSliderRotateOptions.SMOOTHLY:
          return {
            ...commonConfiguration,
            allowTouchMove: false,
            ...getAutoplay(0),
            speed: displaySettings.timeBetweenSlides * 1000,
            pagination: false,
            navigation: false,
            onSwiper: (swiper: SwiperProps) => {
              (
                swiper.$wrapperEl[0] as HTMLElement
              ).style.transitionTimingFunction = "linear";
            },
          };
        case TilesSliderRotateOptions.SINGLE_TILE:
          return {
            ...commonConfiguration,
            slidesPerGroup: 1,
            ...getAutoplay(),
          };
        case TilesSliderRotateOptions.SINGLE_SLIDE:
          return {
            ...commonConfiguration,
            slidesPerGroup: numberProductPerSlide,
            ...getAutoplay(),
          };

        default:
          return {};
      }
    },
  };
};
