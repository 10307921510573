import styled from "styled-components";
import { ArticleListSourceOptions } from "@ecp-redux/dto/article.type";
import { ImagePlacement } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../composeCss";
import {
  ARTICLE_IMAGE_CONTENT_SETTING_PATTERN,
  TEXT_STYLE_SHOW_SETTING_PATTERN,
  TITLE_CONTENT_SETTING_PATTERN,
} from "../../settingsPatterns.utils";
import {
  BOX_ARTICLE_LIST_DISPLAY_PATTERN,
  IBoxArticleListContent,
  IBoxArticleListContentSettings,
  IBoxArticleListSettings,
} from "./StyledBoxArticleList.types";

export const BOX_ARTICLE_LIST_CONTENT_SETTINGS_PATTERN =
  (): IBoxArticleListContentSettings => ({
    article: {
      type: "SLIDER",
      articlesCount: 10,
      articlesPerSlide: 5,
      contentAlignment: AlignmentHorizontalOption.LEFT,
      articlesPerRow: 3,
      rowCount: 2,
    },
    heading: TITLE_CONTENT_SETTING_PATTERN(),
    articleTitle: TITLE_CONTENT_SETTING_PATTERN(),
    articleDescription: TEXT_STYLE_SHOW_SETTING_PATTERN(),
    articleCategory: TEXT_STYLE_SHOW_SETTING_PATTERN(),
    articleCreationDate: TEXT_STYLE_SHOW_SETTING_PATTERN(),
    image: ARTICLE_IMAGE_CONTENT_SETTING_PATTERN(),
    order: [
      "articleTitle",
      "articleDescription",
      "articleCategory",
      "articleCreationDate",
      "image",
    ],
  });

export const BOX_ARTICLE_LIST_CONTENT_PATTERN = (): IBoxArticleListContent => ({
  heading: "heading",
  article: {
    contentSource: ArticleListSourceOptions.ARTICLE_CATEGORIES,
  },
});

export const BOX_ARTICLE_LIST_PATTERN = () => ({
  ...BOX_ARTICLE_LIST_DISPLAY_PATTERN(),
  ...BOX_ARTICLE_LIST_CONTENT_SETTINGS_PATTERN(),
});

export const generateBoxArticleListStyles = ({
  $settings,
}: {
  $settings: IBoxArticleListSettings["$settings"];
}) => {
  return `
    display: flex;
    flex-direction: column;
    gap: 30px;

    & .article,
    & .article-list__slider__single-slide__content {
      display: flex;
      ${
        $settings.image.alignment === ImagePlacement.LEFT
          ? "flex-direction: row-reverse;"
          : ""
      }
      ${
        $settings.image.alignment === ImagePlacement.RIGHT
          ? "flex-direction: row;"
          : ""
      }
      ${
        $settings.image.alignment === ImagePlacement.TOP
          ? `flex-direction: column;
          `
          : ""
      }

      overflow: hidden;
      width: 100%;
    }

    & a {
      display: none;
    }

    & a:not(:empty) {
      display: flex;
      justify-content: ${alignmentOptionsToValue(
        $settings.article.contentAlignment
      )};
      width: 100%;
      height: 100%;
    }

    ${
      $settings.order[$settings.order.length - 1] === "image" &&
      `
    & img {
      margin-top: auto;
    }
    `
    }

    .article-list__heading {
      ${
        $settings.sliderPaginationStyle === "ARROWS"
          ? `margin-left: ${$settings.spacing.horizontal}px`
          : ""
      }
    }
  `;
};

export const StyledBoxArticleList = styled.div<IBoxArticleListSettings>(
  (props) => generateBoxArticleListStyles(props)
);
