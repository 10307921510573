import { useRouter } from "next/router";
import { HiOutlineMailOpen } from "react-icons/hi";
import * as auth from "@ecp-redux/api/auth";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { Info, StyledCircleIcon } from "../../../shared/components/Info/Info";
import CloseCircle from "../../../shared/icons/CloseCircle";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledRegisterCodeFailed } from "../BoxLogin.styled";
import { IBoxLoginMessages, IBoxLoginSettings } from "../BoxLogin.types";

const RegisterCodeFailed = () => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxLoginMessages,
    IBoxLoginSettings
  >();
  const [postRegisterConfirmationCodeRefresh, { isSuccess }] =
    auth.usePostRegisterConfirmationCodeRefreshMutation();
  const router = useRouter();
  return (
    <StyledRegisterCodeFailed
      buttonWidth={settings.register_code_failed_button_width}
      iconBackgroundColor={settings.register_code_failed_color_header}
      className="register-code-failed-container"
    >
      {isSuccess ? (
        <Info>
          <>
            <StyledCircleIcon className="register-code-failed-container__circle-icon">
              <HiOutlineMailOpen size={24} color="black" />
            </StyledCircleIcon>
            <StyledText
              $settings={{
                font: settings.register_code_resend_token_info_typo,
                text: {
                  color: settings.register_code_resend_token_info_text_color,
                },
              }}
              className="register-code-failed-container__resend-token"
              show={isNotEmptyMessage(
                messages.register_code_resend_token_success
              )}
            >
              {messages.register_code_resend_token_success}
            </StyledText>
          </>
        </Info>
      ) : (
        <>
          <StyledText
            $settings={{
              font: settings.register_code_failed_header_typo,
              text: { color: settings.register_code_failed_color_header },
            }}
            alignment={settings.register_code_failed_header_align?.toLowerCase()}
            className="register-code-failed-container__header"
            show={isNotEmptyMessage(messages.register_code_failed_header)}
          >
            <div className="registerFailedHeader__Container">
              <div className="registerFailedHeader__iconContainer">
                <CloseCircle />
              </div>
              {messages.register_code_failed_header}
            </div>
          </StyledText>
          <StyledButton
            renderAs="button"
            $settings={settings.register_code_failed_button_typo}
            onClick={() => {
              postRegisterConfirmationCodeRefresh(
                router.query?.["id"] as string
              );
            }}
            className="register-code-failed-container__button"
            show={isNotEmptyMessage(messages.register_code_failed_button_text)}
          >
            {messages.register_code_failed_button_text}
          </StyledButton>
          <StyledText
            style={{ marginTop: "20px" }}
            $settings={{
              font: settings.register_code_failed_description_typo,
              text: { color: settings.register_code_failed_color_description },
            }}
            alignment={settings.register_code_failed_description_align?.toLowerCase()}
            className="register-code-failed-container__description"
            show={isNotEmptyMessage(messages.register_code_failed_description)}
          >
            {messages.register_code_failed_description}
          </StyledText>
        </>
      )}
    </StyledRegisterCodeFailed>
  );
};

export default RegisterCodeFailed;
