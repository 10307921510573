import React, { Fragment, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { useTheme } from "styled-components";
import { TAttributeValue } from "@ecp-redux/dto/searchResults.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SomePropertyFrom } from "../../../boxes/BoxGetData/BoxGetData.types";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import htmlToReactParser from "../../../helpers/HtmlToReactParser";
import ProductRating from "../../../shared/components/domain/Product/ProductModule/ProductRating/ProductRating";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { StyledAttributeWrapper } from "../AttributeLabel/AttributeLabel";
import {
  IBoxProductAttributesContentSettings,
  ProductAttributeElement,
  SrpInfoProductFields,
} from "../BoxProductAttributes.types";
import {
  getAttributeValue,
  getPrimitiveAttrValue,
  getValueOrLabel,
} from "./AttributeValue.methods";
import DeliveryTimeDisplay from "./DeliveryTimeDisplay";
import IconDisplay from "./IconDisplay";

interface IAttributeValueProps {
  attribute: ProductAttributeElement;
  contentSettings: SomePropertyFrom<
    IBoxProductAttributesContentSettings,
    "nameStyle" | "valueStyle" | "contentAlignment"
  >;
  searchRedirectUrl: string;
  currency?: string;
}

const AttributeValue: React.FC<IAttributeValueProps> = ({
  attribute,
  contentSettings,
  searchRedirectUrl,
  currency,
}) => {
  const router = useRouter();
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const attributeValue = useMemo(() => {
    if (
      attribute.attributeId === "maxCreditValue" ||
      attribute.attributeId === "actualCreditValue"
    ) {
      return `${getAttributeValue(attribute, globalMessages)} 
          ${currency ?? ""}`;
    }
    return getAttributeValue(attribute, globalMessages) ?? "";
  }, [attribute, globalMessages, currency]);

  const createValueMultiselect = useCallback(
    (attributeValue: string[] | TAttributeValue[]): JSX.Element[] => {
      return attributeValue.map((attr, i) => {
        const attrValue = typeof attr === "string" ? attr : attr.value;
        const attrLabel = typeof attr === "string" ? attr : attr.label;
        const separator =
          attributeValue.length > i + 1 ? <>{","} &nbsp;</> : "";

        const actualIconSettings = attribute.attributeIcon?.valuesIcons.find(
          (icon) => icon.valueCode === attrValue
        );
        return (
          <Fragment key={attrValue}>
            <StyledAttributeWrapper
              className="product-attributes-container__attribute__value"
              iconSettings={actualIconSettings}
            >
              <IconDisplay actualIconSettings={actualIconSettings} />

              <StyledText
                className="product-attributes-container__attribute__value__text"
                $settings={{
                  font: contentSettings.valueStyle.font,
                  text: { color: contentSettings.valueStyle.text.color },
                }}
              >
                {attrLabel || attrValue}
              </StyledText>
            </StyledAttributeWrapper>
            <StyledText
              className="product-attributes-container__attribute__value__text"
              $settings={{
                font: contentSettings.valueStyle.font,
                text: { color: contentSettings.valueStyle.text.color },
              }}
            >
              {separator}
            </StyledText>
          </Fragment>
        );
      });
    },
    [
      attribute,
      contentSettings.valueStyle.font,
      contentSettings.valueStyle.text.color,
    ]
  );

  const createValueMultiselectLink = useCallback(
    (attributeValue: string[] | TAttributeValue[]): JSX.Element[] => {
      return attributeValue.map((attr, i) => {
        const attrValue = typeof attr === "string" ? attr : attr.value;
        const attrLabel = typeof attr === "string" ? attr : attr.label;
        const separator =
          attributeValue.length > i + 1 ? <>{","} &nbsp;</> : "";
        const actualIconSettings = attribute.attributeIcon?.valuesIcons.find(
          (icon) => icon.valueCode === attrValue
        );
        return (
          <Fragment key={attrValue}>
            <StyledAttributeWrapper
              className="product-attributes-container__attribute__value"
              iconSettings={actualIconSettings}
              key={attrValue}
            >
              <IconDisplay actualIconSettings={actualIconSettings} />

              <LinkWrapper
                linkStyle={contentSettings.linkStyle}
                fontStyle={contentSettings.valueStyle.font}
                href={""}
                onClick={() => {
                  attribute.badgeType &&
                  attribute.attributeId === SrpInfoProductFields.badges
                    ? searchRedirectUrl.length &&
                      router.push(
                        `${searchRedirectUrl}?filter=${attribute.attributeId}:${attribute.badgeType[i]}`
                      )
                    : searchRedirectUrl.length &&
                      router.push(
                        `${searchRedirectUrl}?filter=${
                          attribute.attributeId
                        }:${getValueOrLabel(attr)}`
                      );
                }}
              >
                {attrLabel || attrValue}
              </LinkWrapper>
            </StyledAttributeWrapper>
            <StyledText
              className="product-attributes-container__attribute__value__text"
              $settings={{
                font: contentSettings.valueStyle.font,
                text: { color: contentSettings.valueStyle.text.color },
              }}
            >
              {separator}
            </StyledText>
          </Fragment>
        );
      });
    },
    [
      attribute,
      contentSettings.valueStyle.font,
      contentSettings.valueStyle.text.color,
    ]
  );

  const createValueToShow = useCallback(
    (
      attributeValue:
        | string
        | number
        | false
        | string[]
        | TAttributeValue
        | TAttributeValue[]
    ): JSX.Element[] | JSX.Element => {
      if (Array.isArray(attributeValue))
        return createValueMultiselect(attributeValue);

      const attr =
        typeof attributeValue === "object"
          ? attributeValue.value
          : attributeValue;

      const actualIconSettings = attribute.attributeIcon?.valuesIcons.find(
        (icon) => icon.valueCode === attr
      );
      return (
        <StyledAttributeWrapper
          className="product-attributes-container__attribute__value"
          iconSettings={actualIconSettings}
        >
          <IconDisplay actualIconSettings={actualIconSettings} />

          <StyledText
            className="product-attributes-container__attribute__value__text"
            $settings={{
              font: contentSettings.valueStyle.font,
              text: { color: contentSettings.valueStyle.text.color },
              textDecoration:
                attribute.attributeId === SrpInfoProductFields.oldPrice
                  ? "line-through"
                  : null,
            }}
          >
            {htmlToReactParser(
              `${
                typeof attributeValue === "object"
                  ? attributeValue.label || attributeValue.value
                  : attributeValue
              }`
            )}
          </StyledText>
        </StyledAttributeWrapper>
      );
    },
    [
      contentSettings.valueStyle.font,
      contentSettings.valueStyle.text.color,
      createValueMultiselect,
      attribute.attributeId,
      attribute.attributeIcon,
    ]
  );

  const actualIconSettings = attribute.attributeIcon?.valuesIcons.find(
    (icon, indx) =>
      icon.valueCode === getPrimitiveAttrValue(attributeValue, indx)
  );
  return (
    <StyledAttributeWrapper
      className="product-attributes-container__attribute__value"
      iconSettings={actualIconSettings}
    >
      {attribute.activeLink &&
        (Array.isArray(attributeValue) ? (
          createValueMultiselectLink(attributeValue)
        ) : (
          <>
            <IconDisplay actualIconSettings={actualIconSettings} />
            <LinkWrapper
              linkStyle={contentSettings.linkStyle}
              fontStyle={contentSettings.valueStyle.font}
              href={""}
              onClick={() => {
                searchRedirectUrl.length &&
                  router.push(
                    `${searchRedirectUrl}?filter=${attribute.attributeId}:${
                      typeof attribute.value === "string" ||
                      typeof attribute.value === "number"
                        ? attribute.value
                        : (attribute.value as TAttributeValue)?.label
                    }`
                  );
              }}
            >
              {
                <span>
                  {typeof attributeValue === "object"
                    ? attributeValue.label
                    : attributeValue}
                </span>
              }
            </LinkWrapper>
          </>
        ))}

      {!attribute.activeLink &&
        attribute.attributeId === SrpInfoProductFields.reviews && (
          <ProductRating
            ratingSettings={contentSettings.rating}
            labelName=""
            ratingCount={attribute.numberOfReviews ?? 0}
            ratingScore={Number(attributeValue)}
          />
        )}
      {!attribute.activeLink &&
        attribute.attributeId === SrpInfoProductFields.rating && (
          <ProductRating
            ratingSettings={contentSettings.rating}
            labelName={String(
              typeof attributeValue === "number" && attributeValue.toFixed(2)
            )}
            ratingScore={Number(attributeValue)}
          />
        )}
      {!attribute.activeLink &&
        attribute.attributeId === SrpInfoProductFields.otherDeliveryDates && (
          <DeliveryTimeDisplay
            attributeValue={attributeValue as string[]}
            textStyle={contentSettings.valueStyle}
          />
        )}

      {!attribute.activeLink &&
        attribute.attributeId !== SrpInfoProductFields.otherDeliveryDates &&
        attribute.attributeId !== SrpInfoProductFields.reviews &&
        attribute.attributeId !== SrpInfoProductFields.rating &&
        createValueToShow(attributeValue)}
    </StyledAttributeWrapper>
  );
};

export default AttributeValue;
