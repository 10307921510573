import { PIMInputTypes } from "@ecp-redux/dto/productList.types";
import {
  SortingItemType,
  SortingItemVariant,
} from "@ecp-redux/dto/searchResults.types";
import { HoverEffects } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
  OutlineOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SliderPaginationOptions } from "../../settingsPatterns/boxes/BoxSlider/StyledSlider.types";
import {
  LINK_STYLE_SETTING_PATTERN,
  MAIN_BUTTON_CONTENT_SETTING_PATTERN,
  TEXT_STYLE_SHOW_SETTING_PATTERN,
  TYPO_STYLE_SETTING_PATTERN,
} from "../../settingsPatterns/settingsPatterns.utils";
import { ProductButtonActions } from "../../shared/components/domain/Product/ProductModule/ProductModule.types";
import {
  IBoxProductSliderContent,
  IBoxProductSliderContentSettings,
  IBoxProductSliderDisplaySettings,
  ProductSliderBoxType,
  ProductSliderBoxViewType,
  SlideRotateOptions,
} from "./BoxProductSlider.types";

export const BOX_PRODUCT_SLIDER_DISPLAY_SETTINGS_PATTERN =
  (): IBoxProductSliderDisplaySettings => ({
    paginationStyle: SliderPaginationOptions.ARROWS,
    paginationStyleGrid: SliderPaginationOptions.NUMBERS,
    slidesRotate: [10],
    slideRotateType: SlideRotateOptions.SINGLE,
    pageStyle: { spaceBetweenRows: 0, spaceBetweenProducts: 0 },
    module: {
      alignment: AlignmentHorizontalOption.LEFT,
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      spaceBetweenPhotoAndContent: 0,
      spaceBetweenTextElements: 0,
      background: { id: "color$$9", custom: "#000000" },
      border: {
        style: OutlineOptions.NONE,
        width: 0,
        color: { id: "color$$1", custom: "#000000" },
      },
      spaceBetweenSlides: 5,
      spaceBetweenPhotoAndAttributes: 5,
      pictureAlignment: AlignmentVerticalOption.TOP,
      attributesAlignment: AlignmentVerticalOption.TOP,
    },
    orderInModule: [
      "productName",
      "actualPrice",
      "oldPrice",
      "netPrice",
      "rating",
      "button",
    ],
  });

export const BOX_PRODUCT_SLIDER_CONTENT_SETTINGS_PATTERN =
  (): IBoxProductSliderContentSettings => ({
    sliderSettings: {
      type: ProductSliderBoxType.SLIDER,
      viewType: ProductSliderBoxViewType.TOP_PICTURE,
      maxNumberOfProducts: 12,
      maxNumberOfProductsGrid: 60,
      numberOfProductsPerSlide: 3,
      numberOfProductsPerRow: 2,
      numberOfRows: 10,
      sorting: SortingItemType.DATE_ADDED_DESC,
      sortingAttributeCode: "",
      sortingAttributeName: "",
      sortingAttributeVariant: SortingItemVariant.ASC,
      sortingAttributeType: "" as PIMInputTypes,
    },
    product: {
      productName: {
        link: LINK_STYLE_SETTING_PATTERN("link$$1"),
        text: TYPO_STYLE_SETTING_PATTERN("typo$$7"),
        show: true,
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      actualPrice: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$4", "color$$1"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      oldPrice: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$2"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      netPrice: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      rating: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$1"),
        filledPointsColor: { id: "color$$1", custom: "#000000" },
        unfilledPointsColor: { id: "color$$2", custom: "#000000" },
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      button: {
        ...MAIN_BUTTON_CONTENT_SETTING_PATTERN(),
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.ADD_TO_CART,
        url: "",
      },
      custom: {},
    },
    productPhoto: {
      height: 364,
      hover: HoverEffects.ZOOMIN,
      photoSize: 30,
    },
    badge: {
      show: true,
    },
    favorites: {
      show: true,
    },
    searchQuery: "",
    dynamicProductListOption: undefined,
    selectedAssociation: undefined,
    header: {
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      contentAlignment: AlignmentHorizontalOption.LEFT,
      font: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 2,
          lineHeight: 2,
          letterSpacing: 2,
        },
      },
      text: {
        color: { id: "color$$1", custom: "#000000" },
      },
      show: false,
    },
    variants: [],
    attributes: [],
  });

export const BOX_PRODUCT_SLIDER_CONTENT_PATTERN =
  (): IBoxProductSliderContent => ({
    ratingText: "Reviews",
    productButtonText: "Add to cart",
    headerText: "Heading",
  });
