import { useEffect, useState } from "react";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { useTemporaryErrorBySnackbar } from "../../helpers/useTemporaryErrorBySnackbar";
import { StyledInput } from "../../shared/components/Input/StyledInput/StyledInput";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { useCommunicationBetweenBoxesContext } from "../../structure/Contexts/CommunicationBetweenBoxes";
import { StyledBoxAddToCartQuantityWrapper } from "./BoxAddToCartQuantity.styled";
import { TBoxAddToCartQuantityProps } from "./BoxAddToCartQuantity.types";

const INITIAL_QUANTITY_VALUE = 1;

const BoxAddToCartQuantity: React.FC<TBoxAddToCartQuantityProps> = ({
  messages,
  settings,
}: TBoxAddToCartQuantityProps) => {
  const [quantityValue, setQuantityValue] = useState<number | "">(INITIAL_QUANTITY_VALUE);
  const { addToCartQuantity, changeAddToCartQuantity } =
    useCommunicationBetweenBoxesContext();
  const temporaryError = useTemporaryErrorBySnackbar(
    addToCartQuantity.status === "ERROR"
  );

  const maxValue = /^[1-9]\d*$/.test(settings.add_to_cart_quantity_maks_value)
    ? Number(settings.add_to_cart_quantity_maks_value)
    : 100;

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const onlyNumbers = /^[1-9]\d*$/;

    if (
      e.target.value === "" ||
      (onlyNumbers.test(e.target.value) && parseInt(e.target.value) <= maxValue)
    ) {
      setQuantityValue(e.target.value as number | "");
    }
  };

  useEffect(() => {
    if (quantityValue !== "") {
      changeAddToCartQuantity(Number(quantityValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantityValue]);

  useEffect(() => {
    setQuantityValue(addToCartQuantity.quantity)
  }, [addToCartQuantity])

  const handleAddQuantity = () => {
    addToCartQuantity.quantity < maxValue &&
      changeAddToCartQuantity(addToCartQuantity.quantity + 1);
  };

  const handleSubQuantity = () => {
    addToCartQuantity.quantity > 1 &&
      changeAddToCartQuantity(addToCartQuantity.quantity - 1);
  };

  return (
    <StyledBoxAddToCartQuantityWrapper
      $settings={settings}
      className="add-to-cart-quantity"
    >
      <div>
        <StyledButton
          className="add-to-cart-quantity__minus-button"
          onClick={handleSubQuantity}
          renderAs="button"
          role="button"
          $settings={settings.add_to_cart_quantity_button_style}
          show={isNotEmptyMessage(messages.add_to_cart_quantity_minus)}
        >
          <i />
          {messages.add_to_cart_quantity_minus}
        </StyledButton>

        <StyledInput
          className="add-to-cart-quantity__quantity-input"
          $settings={settings.add_to_cart_quantity_input_style}
          type="text"
          data-testid="quantity-input"
          value={quantityValue}
          onChange={(e) => handleQuantityChange(e)}
          formNoValidate={!temporaryError}
          onBlur={() => quantityValue === "" && setQuantityValue(INITIAL_QUANTITY_VALUE)}
        />
        <StyledButton
          className="add-to-cart-quantity__plus-button"
          onClick={handleAddQuantity}
          renderAs="button"
          role="button"
          $settings={settings.add_to_cart_quantity_button_style}
          show={isNotEmptyMessage(messages.add_to_cart_quantity_plus)}
        >
          <i />
          {messages.add_to_cart_quantity_plus}
        </StyledButton>
      </div>
    </StyledBoxAddToCartQuantityWrapper>
  );
};

export default BoxAddToCartQuantity;
