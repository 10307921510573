import useTranslation from "next-translate/useTranslation";
import * as myAddressList from "@ecp-redux/api/myAddressList";
import { isPortalSide } from "../../helpers/helpers";
import Alert from "../../shared/components/Alert/Alert";
import { IBoxMyAccountMyAddressListProps } from "./BoxMyAccountMyAddressList.types";
import Billing from "./elements/Billing/Billing";
import Postal from "./elements/Postal/Postal";
import StoreAddresses from "./elements/StoreAddresses/StoreAddresses";

const BoxMyAccountMyAddressList: React.FC<IBoxMyAccountMyAddressListProps> = ({
  settings,
}) => {
  const { t } = useTranslation();
  const { data } = myAddressList.useGetMyAddressesQuery();
  if (!data)
    return (
      <div className="myaccount_address_list--empty">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );

  return (
    <div>
      <Alert
        alertsCodes={["ADDRESS_ADDED_SUCCESS"]}
        backgroundColor={
          settings.myaccount_address_list_edit_success_background_color
        }
        textColor={settings.myaccount_address_list_edit_success_text_color}
        typography={settings.myaccount_address_list_edit_success_typo}
        closeable={true}
        lifetime={5000}
      />
      <Postal
        selectedPostal={
          data.postalAddresses.find((elem) => elem.defaultAddress) ?? null
        }
        postalAddresses={data.postalAddresses}
      />
      <Billing
        selectedBilling={
          data.billingAddresses.find((elem) => elem.defaultAddress) ?? null
        }
        billingAddresses={data.billingAddresses}
      />
      <StoreAddresses
        selectedAddress={
          data.storeAddresses.find((elem) => elem.defaultAddress) ?? null
        }
        storeAddresses={data.storeAddresses}
      />
    </div>
  );
};

export default BoxMyAccountMyAddressList;
