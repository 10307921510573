import {
  IBillingPostalAddMyAddressRequest,
  IMyAddressesAdd,
  IMyAddressesResponse,
  TAddMyAddressRequest,
} from "../dto/myAddresses.type";
import { API, api } from "./api";

export const myAddressList = api
  .enhanceEndpoints({ addTagTypes: ["DEFAULT"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      postAddNewAddress: builder.mutation<
        { addressId: number },
        TAddMyAddressRequest
      >({
        query: (body) => {
          return {
            url: API.postAddNewAddress.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["PORTAL_USER_AUTH"],
      }),

      putEditAddress: builder.mutation<
        void,
        IBillingPostalAddMyAddressRequest & IMyAddressesAdd
      >({
        query: ({ addressId, ...body }) => {
          return {
            url: API.putEditAddress.endpoint(addressId),
            method: "PUT",
            body,
          };
        },
        invalidatesTags: ["PORTAL_USER_AUTH"],
      }),

      getMyAddresses: builder.query<IMyAddressesResponse, void>({
        query: () => {
          return API.getCustomerAddresses.endpoint();
        },
        providesTags: ["PORTAL_USER_AUTH", "DEFAULT"],
      }),

      deleteCustomerAddress: builder.mutation<void, number>({
        query: (addressId) => {
          return {
            url: API.deleteCustomerAddress.endpoint(addressId),
            method: "DELETE",
          };
        },
        invalidatesTags: ["PORTAL_USER_AUTH"],
      }),
      putChangeDefaultAddress: builder.mutation<unknown, IMyAddressesAdd>({
        query: (body) => {
          return {
            url: API.putChangeDefaultAddress.endpoint(
              body.addressId.toString()
            ),
            method: "PUT",
          };
        },
        invalidatesTags: ["DEFAULT"],
      }),
    }),
  });

export const {
  useGetMyAddressesQuery,
  useDeleteCustomerAddressMutation,
  usePutChangeDefaultAddressMutation,
  usePostAddNewAddressMutation,
  usePutEditAddressMutation,
} = myAddressList;

export const { getMyAddresses } = myAddressList.endpoints;
