import { useState } from "react";
import { IElementText } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import Dropdown from "../../../shared/components/Dropdown/Dropdown";
import useScrollWhileOnDropdown from "../../../shared/hooks/useScrollWhileOnDropdown";
import { ReactComponent as ArrowDown } from "../../../shared/icons/arrowDown.svg";
import {
  DropdownListEl,
  StyledDropdownList,
  StyledSearchUtilsLabel,
  StyledToggler,
} from "../../../shared/styleElements/StyledSearchResultsDropdown/SearchResultsDropdown.styled";

export interface ShowOnPage {
  choosen: number;
  label: string;
  showOnPageLabelSettings: IElementText;
  changePageSize: (pageSize: number) => void;
  showOptions: number[];
}

const ShowOnPage: React.FC<ShowOnPage> = ({
  showOnPageLabelSettings,
  label,
  changePageSize,
  choosen,
  showOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useScrollWhileOnDropdown(isOpen, setIsOpen);

  const chooseProductsPerPage = (pageSize: number) => {
    changePageSize(pageSize);
    setIsOpen(false);
  };

  return (
    <div className="show-on-page-container">
      <StyledSearchUtilsLabel $settings={showOnPageLabelSettings}>
        {label}:
      </StyledSearchUtilsLabel>
      <div onMouseLeave={() => setIsOpen(false)}>
        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onBackDropClick={() => setIsOpen(false)}
          buttonContent={
            <StyledToggler
              $settings={showOnPageLabelSettings}
              onClick={() => setIsOpen((prev) => !prev)}
              renderAs="button"
              isOpen={isOpen}
              data-testid="show-on-page-toggler"
            >
              <span>{choosen}</span>
              <ArrowDown />
            </StyledToggler>
          }
          selectContainerContent={
            <StyledDropdownList
              $settings={showOnPageLabelSettings}
              renderAs="ul"
            >
              {showOptions.map((el) => (
                <DropdownListEl
                  key={el}
                  onClick={() => {
                    chooseProductsPerPage(el);
                  }}
                  data-testid={`show-on-page-option-${el}`}
                >
                  {el}
                </DropdownListEl>
              ))}
            </StyledDropdownList>
          }
        />
      </div>
    </div>
  );
};

export default ShowOnPage;
