import styled from "styled-components";
import {
  TInputId,
  TInputReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertInputIdToValues } from "../../Input/StyledInput/StyledInput.methods";

export const StyledChangeProductQuantityB2B = styled.div<{
  inputStyle: TInputId | TInputReadoutValue;
  spaceBetweenInputButtons: number;
}>(({ inputStyle, spaceBetweenInputButtons, theme }) => {
  const { width } = convertInputIdToValues(
    inputStyle,
    theme.globalObjects?.inputs
  );

  return `
  display: flex;
  align-items: center;
  gap: ${spaceBetweenInputButtons}px;

  .change-product-quantity-b2b {
    &__count-input {
      text-align: center;
      padding-right: 10px;
      width: ${width}%;
    }

    &__plus-button, &__minus-button {
      cursor: pointer;
    }
  }
`;
});
