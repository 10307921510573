import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useTheme } from "styled-components";
import * as cartAPI from "@ecp-redux/api/cart";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isClientSide, isPortalSide } from "../../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxOrderSummaryMessage,
  IBoxOrderSummarySettings,
} from "../BoxOrderSummary.types";
import { StyledOrderSummary } from "./OrderSummary.styled";

const OrderSummary: React.FC = () => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { messages, settings } = useMessagesSettingsContext<
    IBoxOrderSummaryMessage,
    IBoxOrderSummarySettings
  >();
  const router = useRouter();
  const orderId = router?.query?.["orderId"];

  const [trigger, { data: orderConfirmation, isError }] =
    cartAPI.useLazyGetCartOrderConfirmationQuery();

  useEffect(() => {
    if (orderId && typeof orderId === "string") {
      trigger(orderId);
    } else if (isPortalSide() && isClientSide()) {
      router.push(settings.basket_step_3_url_step_0);
    }
  }, [orderId]);

  useEffect(() => {
    if (isError && isPortalSide() && isClientSide()) {
      router.push(settings.basket_step_3_url_step_0);
    }
  }, [isError]);

  return (
    <StyledOrderSummary
      backgroundColor={settings.basket_step_3_color_background}
      className="order-summary-container"
    >
      <StyledText
        className="order-summary-container__header"
        $settings={{
          font: settings.basket_step_2_3_typography_header_1,
          text: { color: settings.basket_step_all_color_main },
        }}
        show={isNotEmptyMessage(messages.header)}
      >
        <p>{`${messages.header}${orderConfirmation?.orderId ?? ""}`}</p>
      </StyledText>

      <div className="order-summary-grid">
        <div className="grid-item-1">
          <div className="order-summary-container__email-container">
            <StyledText
              className="order-summary-container__email__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.email)}
            >
              <p>{messages.email}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__email__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
            >
              <p>{orderConfirmation?.email ?? ""}</p>
            </StyledText>
          </div>

          <div className="order-summary-container__payment-method-container">
            <StyledText
              className="order-summary-container__payment-method__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
            >
              <p>{messages.payment_method}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__payment-method__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={
                orderConfirmation &&
                isNotEmptyMessage(
                  messages[
                    `payment_name_${orderConfirmation.payments[0].paymentType}`
                  ]
                )
              }
            >
              <p>
                {orderConfirmation &&
                  messages[
                    `payment_name_${orderConfirmation.payments[0].paymentType}`
                  ]}
              </p>
            </StyledText>
          </div>

          <div className="order-summary-container__order-date-container">
            <StyledText
              className="order-summary-container__order-date__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.order_date)}
            >
              <p>{messages.order_date}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__order-date__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
            >
              <p>{orderConfirmation?.creationDate ?? ""}</p>
            </StyledText>
          </div>

          <div className="order-summary-container__phone-container">
            <StyledText
              className="order-summary-container__phone__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.phone)}
            >
              <p>{messages.phone}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__phone__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
            >
              <p>{orderConfirmation?.deliveryAddress.phone ?? ""}</p>
            </StyledText>
          </div>
        </div>
        <div className="grid-item-2">
          <div className="order-summary-container__status-container">
            <StyledText
              className="order-summary-container__status__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.status)}
            >
              <p>{messages.status}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__status__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.order_status_NEW)}
            >
              <p>{messages.order_status_NEW}</p>
            </StyledText>
          </div>
          <div className="order-summary-container__delivery-time-container">
            <StyledText
              className="order-summary-container__delivery-time__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.delivery_time)}
            >
              <p>{messages.delivery_time}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__delivery-time__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.expected_delivery_time)}
            >
              <p>
                {orderConfirmation?.deliveryDateFrom &&
                orderConfirmation?.deliveryDateTo
                  ? `${messages.expected_delivery_time} ${orderConfirmation.deliveryDateFrom} - ${orderConfirmation.deliveryDateTo}`
                  : globalMessages.delivery_unknown}
              </p>
            </StyledText>
          </div>

          <div className="order-summary-container__delivery-address-container">
            <StyledText
              className="order-summary-container__delivery-address__header"
              $settings={{
                font: settings.basket_step_2_3_typography_paragraph_4,
                text: { color: settings.basket_step_all_color_main },
              }}
              show={isNotEmptyMessage(messages.delivery_address)}
            >
              <p>{messages.delivery_address}</p>
            </StyledText>
            <StyledText
              className="order-summary-container__delivery-address__value"
              $settings={{
                font: settings.basket_step_all_typography_paragraph_2,
                text: { color: settings.basket_step_all_color_main },
              }}
            >
              <p>{`${
                orderConfirmation?.deliveryAddress.firstName ??
                orderConfirmation?.deliveryAddress.storeName ??
                ""
              } ${orderConfirmation?.deliveryAddress.lastName ?? ""}`}</p>
              <p>{`${orderConfirmation?.deliveryAddress.street ?? ""} ${
                orderConfirmation?.deliveryAddress.streetNumber ?? ""
              }${
                orderConfirmation?.deliveryAddress.flatNumber
                  ? "/" + orderConfirmation?.deliveryAddress.flatNumber
                  : ""
              }`}</p>
              <p>{`${orderConfirmation?.deliveryAddress.zipCode ?? ""} ${
                orderConfirmation?.deliveryAddress.city ?? ""
              }`}</p>
            </StyledText>
          </div>
        </div>
      </div>
    </StyledOrderSummary>
  );
};

export default OrderSummary;
