import { z } from "zod";
import { IBackground, IBorder_v2 } from "./settingsPatterns.types";
import {
  BackgroundScheme,
  Border_v2Scheme,
  SpacingScheme,
} from "./themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  OutlineOptions,
} from "./themeSettings.types";
import { ColumnEffectsScheme } from "./visualEffects.schemes";
import {
  HoverRowColumnEffectOption,
  TColumnEffects,
} from "./visualEffects.types";

const initBackground: IBackground = {
  color: { id: "color$$4", custom: "#000000" },
  backgroundUrl: "",
  fit: false,
  alignment: {
    horizontal: AlignmentHorizontalOption.CENTER,
    vertical: AlignmentVerticalOption.CENTER,
  },
  opacity: 100,
};

const initBorder: IBorder_v2 = {
  color: { id: "color$$4", custom: "#000000" },
  style: OutlineOptions.NONE,
  width: { left: 0, right: 0, top: 0, bottom: 0 },
  radius: 0,
};

const initColumnEffects: TColumnEffects = {
  hoverEffect: {
    name: HoverRowColumnEffectOption.NONE,
    effectDetails: {},
  },
};

export const DisplaySettingsSlotScheme = z.object({
  margin: SpacingScheme.default({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }).catch({ top: 0, bottom: 0, right: 0, left: 0 }),
  padding: SpacingScheme.default({
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }).catch({ top: 0, bottom: 0, right: 0, left: 0 }),
  background: BackgroundScheme.default(initBackground).catch(initBackground),
  border: Border_v2Scheme.default(initBorder).catch(initBorder),
  alignment: z
    .object({
      vertical: z
        .nativeEnum(AlignmentVerticalOption)
        .default(AlignmentVerticalOption.CENTER)
        .catch(AlignmentVerticalOption.CENTER),
    })
    .default({ vertical: AlignmentVerticalOption.CENTER })
    .catch({
      vertical: AlignmentVerticalOption.CENTER,
    }),
  effects:
    ColumnEffectsScheme.default(initColumnEffects).catch(initColumnEffects),
});

export type IDisplaySettingsSlot = z.infer<typeof DisplaySettingsSlotScheme>;
