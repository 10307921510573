import {
  EndpointType,
  TCustomerSingleResultPayload,
  TCustomersSingleResult,
} from "../dto/customers.types";
import { API, api } from "./api";

export const customers = api.injectEndpoints({
  endpoints: (builder) => ({
    getPostCustomersColumnNames: builder.query<
      { columnNames: string[] },
      { endpointType: EndpointType }
    >({
      query: ({ endpointType }) => {
        return {
          url: API.postCustomersColumnNames.endpoint(endpointType),
          method: "POST",
        };
      },
    }),
    postCustomersSingleResult: builder.query<
      TCustomersSingleResult,
      { endpointType: EndpointType; body: TCustomerSingleResultPayload }
    >({
      query: ({ endpointType, body }) => {
        return {
          url: API.postCustomersSingleResult.endpoint(endpointType),
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useLazyGetPostCustomersColumnNamesQuery,
  usePostCustomersSingleResultQuery,
} = customers;

export const { getPostCustomersColumnNames, postCustomersSingleResult } =
  customers.endpoints;
