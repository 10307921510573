import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../global/global";

export const StyledConfirmDeleteAddressPopup = styled.div`
  margin: 30px;

  .delete-address-popup__button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }

  .delete-address-popup__description {
    margin: 30px 0;
  }

  .delete-address-popup__button-container__save-button {
    min-width: 100%;
  }

  .delete-address-popup__button-container__cancel-button {
    min-width: 100%;
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    .delete-address-popup__button-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
