export enum EndpointType {
  CUSTOMER_DETAILS = "portal_customers",
  CREDIT_LIMIT = "crl",
  DELIVERY_SCHEDULE = "dls",
  SETTLEMENT = "settlement",
  LOGISTICS_MINIMUM = "logistics_minimum",
  PAYERS = "payers",
}

export type TCustomerSingleResultPayload = {
  filter: string[];
  projection: string[];
};

export type TCustomersSingleResult = { result: Record<string, string> };

export type TCreditLimitResponse = { result: Map<string, number> };
