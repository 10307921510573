import styled from "styled-components";
import { PageCategoryType } from "@ecp-pageTypes";
import { useCheckPageContext } from "../../helpers/pageContext";
import AddToCart from "../../shared/components/domain/Cart/AddToCart/AddToCart";
import { IBoxAddToCartProps } from "./BoxAddToCart.types";

const StyledBoxAddToCartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoxAddToCart: React.FC<IBoxAddToCartProps> = ({ messages, settings }) => {
  const pageContext = useCheckPageContext(PageCategoryType.PRODUCT);

  return (
    <StyledBoxAddToCartWrapper className="box-add-to-cart-container">
      <AddToCart
        itemId={
          pageContext.type === PageCategoryType.PRODUCT
            ? pageContext.productSku
            : settings.product_button_add_to_card_product_id
        }
        buttonText={messages.product_button_add_to_cart}
        buttonStyle={settings.product_button_add_to_cart_style}
      />
    </StyledBoxAddToCartWrapper>
  );
};

export default BoxAddToCart;
