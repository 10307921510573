import { forwardRef } from "react";
import { useTheme } from "styled-components";
import {
  IThemeState,
  TOptionId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import StyledButton from "../StyledButton/StyledButton";

interface OptionProps {
  option: TOptionId;
  text: string;
  ref?: (el: HTMLButtonElement) => HTMLButtonElement;
  className?: string;
  disabled?: boolean;
  url?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const StyledOption = forwardRef<HTMLButtonElement, OptionProps>(
  (
    { disabled = false, url, option, text, className, onClick },
    ref: React.ForwardedRef<HTMLButtonElement>
  ): JSX.Element => {
    const {
      globalObjects: { options },
    } = useTheme() as IThemeState;

    return (
      <StyledButton
        renderAs="button"
        className={className}
        $settings={options?.find((o) => o.id === option)?.button}
        ref={ref}
        data-url={url}
        disabled={disabled}
        onClick={onClick}
      >
        <i />
        {text}
      </StyledButton>
    );
  }
);

export default StyledOption;
