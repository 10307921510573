import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";

export interface IHeadingValues {
  orderStatus: string;
  orderDate: string;
  orderFinalCost: string;
}

export const getSingleOrderInfoHeadingElements = (
  messages: IBoxMyAccountMyOrdersMessages,
  settings: ITBoxMyAccountMyOrdersSettings,
  values: IHeadingValues
) => {
  return [
    {
      fontLabelTypo: settings.customer_data_orders_heading_status_label_typo,
      textLabelColor: settings.customer_data_orders_heading_status_label_color,
      messageLabel: messages.customer_data_orders_heading_status,

      fontValueTypo: settings.customer_data_orders_heading_status_value_typo,
      textValueColor: settings.customer_data_orders_heading_status_value_color,
      value: values.orderStatus,
    },
    {
      fontLabelTypo: settings.customer_data_orders_heading_date_label_typo,
      textLabelColor: settings.customer_data_orders_heading_date_label_color,
      messageLabel: messages.customer_data_orders_heading_date,

      fontValueTypo: settings.customer_data_orders_heading_date_value_typo,
      textValueColor: settings.customer_data_orders_heading_date_value_color,
      value: values.orderDate,
    },
    {
      fontLabelTypo:
        settings.customer_data_orders_heading_final_value_label_typo,
      textLabelColor:
        settings.customer_data_orders_heading_final_value_label_color,
      messageLabel: messages.customer_data_orders_heading_final_value,

      fontValueTypo: settings.customer_data_orders_heading_final_value_typo,
      textValueColor: settings.customer_data_orders_heading_final_value_color,
      value: values.orderFinalCost,
    },
  ];
};
