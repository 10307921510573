import {
  IBusinessUserRegisterForm,
  IUserChangePasswordForm,
  IUserLoginForm,
  IUserRegisterForm,
  IUserRemindPasswordForm,
} from "../dto/auth.types";
import { IRegisterConfirmationResponse } from "../dto/registerConfirmation.types";
import { API, api } from "./api";

export const auth = api.injectEndpoints({
  endpoints: (builder) => ({
    postRegister: builder.mutation<
      unknown,
      IUserRegisterForm | IBusinessUserRegisterForm
    >({
      query: (body: IUserRegisterForm | IBusinessUserRegisterForm) => {
        return {
          url: API.postRegister.endpoint(),
          method: "POST",
          body: {
            ...body,
            languageId: 1,
            timezoneId: 1,
            currencyId: 1,
            action: "/",
          },
        };
      },
    }),
    postLogin: builder.mutation<unknown, IUserLoginForm>({
      query: (body: IUserLoginForm) => {
        return {
          url: API.postLogin.endpoint(),
          method: "POST",
          ...(body.captcha && {
            headers: {
              "g-recaptcha-response": body.captcha,
            },
          }),
          body: {
            username: body.username,
            password: body.password,
          },
        };
      },
      transformResponse: (_, meta: any) => {
        const contractorToken = meta.response.headers.get("contractor-token");
        if (contractorToken)
          localStorage.setItem("contractor-token", contractorToken);
      },
    }),
    deleteLogout: builder.mutation<void, void>({
      query: () => {
        return {
          url: API.deleteLogout.endpoint(),
          method: "DELETE",
        };
      },
      transformResponse: () => {
        localStorage.removeItem("contractor-token");
        sessionStorage.removeItem("contractor-token");
      },
      invalidatesTags: ["PORTAL_USER_AUTH", "SEARCH_RESULTS"],
    }),
    postLoginTestUser: builder.mutation<void, void>({
      query: () => {
        sessionStorage.removeItem("contractor-token");
        localStorage.removeItem("contractor-token");
        return {
          url: API.postLoginTestUser.endpoint(),
          method: "POST",
        };
      },
      transformResponse: (_, meta: any) => {
        const contractorToken = meta.response.headers.get("contractor-token");
        if (contractorToken)
          localStorage.setItem("contractor-token", contractorToken);
      },
      invalidatesTags: ["PORTAL_USER_AUTH", "SEARCH_RESULTS"],
    }),
    postLoginAsCustomer: builder.mutation<void, string>({
      query: (arg) => {
        return {
          url: API.postLoginAsCustomer.endpoint(arg),
          method: "POST",
        };
      },
      transformResponse: (_, meta: any) => {
        const contractorToken = meta.response.headers.get("contractor-token");
        if (contractorToken)
          localStorage.setItem("contractor-token", contractorToken);
      },
      invalidatesTags: ["PORTAL_USER_AUTH", "SEARCH_RESULTS"],
    }),
    postLogOutTestUser: builder.mutation<void, void>({
      query: () => {
        return {
          url: API.postLogoutTestUser.endpoint(),
          method: "POST",
        };
      },
      transformResponse: () => {
        localStorage.removeItem("contractor-token");
        sessionStorage.removeItem("contractor-token");
      },
      invalidatesTags: ["PORTAL_USER_AUTH", "SEARCH_RESULTS"],
    }),
    postRemindPassword: builder.mutation<unknown, IUserRemindPasswordForm>({
      query: (body) => {
        return {
          url: API.postRemindPassword.endpoint(),
          method: "POST",
          body,
        };
      },
    }),
    postResetForgettenPassword: builder.mutation<unknown, { token: string }>({
      query: (body) => {
        return {
          url: API.postResetForgettenPassword.endpoint(),
          method: "POST",
          body,
        };
      },
    }),
    postChangePassword: builder.mutation<
      unknown,
      IUserChangePasswordForm & { token: string }
    >({
      query: (body) => {
        return {
          url: API.postChangePassword.endpoint(),
          method: "POST",
          body,
        };
      },
    }),
    postRegisterConfirmationCode: builder.mutation<
      IRegisterConfirmationResponse,
      string
    >({
      query: (token: string) => {
        return {
          url: API.postRegisterConfirmationCode.endpoint(token),
          method: "POST",
        };
      },
    }),
    postRegisterConfirmationCodeRefresh: builder.mutation<unknown, string>({
      query: (token: string) => {
        return {
          url: API.postRegisterConfirmationCodeRefresh.endpoint(token),
          method: "POST",
        };
      },
    }),
    getVerifyResetPasswordToken: builder.query<
      {
        tokenValid: boolean;
        email?: string;
      },
      string
    >({
      query: (token) => {
        return API.getVerifyResetPasswordToken.endpoint(token);
      },
    }),
  }),
});

export const {
  usePostRegisterMutation,
  usePostRemindPasswordMutation,
  usePostChangePasswordMutation,
  usePostLoginMutation,
  usePostLoginTestUserMutation,
  usePostLogOutTestUserMutation,
  useDeleteLogoutMutation,
  usePostLoginAsCustomerMutation,
  usePostRegisterConfirmationCodeMutation,
  usePostRegisterConfirmationCodeRefreshMutation,
  usePostResetForgettenPasswordMutation,
  useGetVerifyResetPasswordTokenQuery,
} = auth;

export const {
  postRegister,
  postRemindPassword,
  postChangePassword,
  postLogin,
  deleteLogout,
  postRegisterConfirmationCode,
  postRegisterConfirmationCodeRefresh,
  getVerifyResetPasswordToken,
} = auth.endpoints;
