import styled from "styled-components";
import {
  TColorId,
  TInputId,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { convertInputIdToValues } from "../../shared/components/Input/StyledInput/StyledInput.methods";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";

export const StyledContactForm = styled.div<{
  buttonWidth: TSizeCSS;
  inputStyleId: TInputId;
  formElementsGap: TSizeCSS;
  titleSpacing: TSizeCSS;
  termsSpacing: TSizeCSS;
  backgroundColor: TColorId;
  submitButtonBottomSpacing: TSizeCSS;
}>(
  ({
    buttonWidth,
    inputStyleId,
    formElementsGap,
    titleSpacing,
    termsSpacing,
    backgroundColor,
    theme,
    submitButtonBottomSpacing,
  }) => {
    const { width } = convertInputIdToValues(
      inputStyleId,
      theme.globalObjects?.inputs
    );
    return `
    background: ${convertColorIdToHex(backgroundColor, theme.colorPalette)};
    box-shadow: 0px 4px 16px rgba(196, 208, 216, 0.15);
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    ${buttonWidth && `${StyledButton} { width: ${buttonWidth}; }`}
    > form {
      width: ${width}%;
      margin: auto;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      gap: ${formElementsGap};
      margin-top: ${titleSpacing};
      margin-bottom: ${submitButtonBottomSpacing};

      .terms {
        margin-top: calc(${termsSpacing} - ${formElementsGap ?? "0px"});
      }
    }
    form > * {
      width: 100%;
    }

    form > label {
      width: fit-content;
    }
    a {
      width: fit-content;
      margin: auto;
    }
  `;
  }
);
