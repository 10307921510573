import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  LINK_STYLE_SETTING_PATTERN,
  TEXT_STYLE_SHOW_SETTING_PATTERN,
} from "../../settingsPatterns/settingsPatterns.utils";
import {
  AttributeDisplayType,
  IBoxProductAttributesContentSettings,
  IBoxProductAttributesDisplaySettings,
} from "./BoxProductAttributes.types";

export const BOX_PRODUCT_ATTRIBUTES_CONTENT_SETTINGS =
  (): IBoxProductAttributesContentSettings => ({
    nameStyle: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$3", "color$$1"),
    valueStyle: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$3", "color$$1"),
    rating: {
      ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$3", "color$$1"),
      filledPointsColor: { id: "color$$1", custom: "#000000" },
      unfilledPointsColor: { id: "color$$2", custom: "#000000" },
    },
    linkStyle: LINK_STYLE_SETTING_PATTERN("link$$1"),
    contentAlignment: AlignmentHorizontalOption.CENTER,
  });

export const BOX_PRODUCT_ATTRIBUTES_DISPLAY_SETTINGS =
  (): IBoxProductAttributesDisplaySettings => ({
    displayType: AttributeDisplayType.LIST,
    showLabel: true,
    padding: { left: 0, right: 0, top: 0, bottom: 0 },
    tableSettings: {
      firstColumnSize: 50,
      spaceBetweenRows: 1,
      evenRows: { id: "custom", custom: "#" },
      oddRows: { id: "custom", custom: "#" },
    },
  });
