import React from "react";

const CloseCircle: React.FC = () => {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.4999"
        cy="14.0001"
        r="10"
        transform="rotate(60 14.4999 14.0001)"
        stroke="black"
        stroke-width="2"
      />
      <path
        d="M19.4497 9.28955C19.4497 9.28955 18.831 9.90828 14.8535 13.8858C10.876 17.8632 9.5502 19.1891 9.5502 19.1891"
        stroke="black"
        stroke-width="2"
      />
      <path
        d="M9.55029 9.28955C9.55029 9.28955 10.169 9.90828 14.1465 13.8858C18.124 17.8632 19.4498 19.1891 19.4498 19.1891"
        stroke="black"
        stroke-width="2"
      />
    </svg>
  );
};

export default CloseCircle;
