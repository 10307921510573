import { EndpointType } from "@ecp-redux/dto/customers.types";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { IBoxGetDataContentSettings } from "../../settingsPatterns/boxes/BoxGetData/StyledGetData.types";
import { Element } from "../../settingsPatterns/boxes/BoxGetData/StyledGetData.types";
import { TEXT_STYLE_SHOW_SETTING_PATTERN } from "../../settingsPatterns/settingsPatterns.utils";
import { BOX_PRODUCT_ATTRIBUTES_DISPLAY_SETTINGS } from "../BoxProductAttributes/BoxProductAttributes.pattern";

export const BOX_GET_DATA_CONTENT_SETTINGS =
  (): IBoxGetDataContentSettings => ({
    nameStyle: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$3", "color$$1"),
    valueStyle: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$3", "color$$1"),
    contentAlignment: AlignmentHorizontalOption.CENTER,
  });

export const BOX_GET_DATA_DISPLAY_SETTINGS =
  BOX_PRODUCT_ATTRIBUTES_DISPLAY_SETTINGS;

export const getCurrentSingleResultBody = (selections: Element[]) => {
  return {
    filter: [],
    projection: selections.map((selection) => selection.attributeId),
  };
};

export const getCurrentCreditLimitsSingleResultBody = (
  selections: Element[]
) => {
  return {
    projection: selections.map((selection) => selection.attributeId),
  };
};

export const getCurrentDeliveryScheduleSingleResultBody = (
  selections: Element[]
) => {
  return {
    projection: selections.map((selection) => selection.attributeId),
  };
};
