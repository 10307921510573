import { Pagination } from "../../../shared/components/Pagination/Pagination";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledNavigation } from "../BoxSearchResults.styled";
import {
  IBoxSearchResultsContent,
  IBoxSearchResultsContentSettings,
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../BoxSearchResults.types";
import ShowOnPage from "./ShowOnPage";
import Sort from "./Sort";
import { SortOption } from "./Sort.types";

interface TopNavigationProps {
  pageCount: number;
  page: number;
  pageSize: number;
  changePageSize: (pageSize?: number, sort?: SortOption) => void;
  changePage: (page: number) => void;
  contentSettings: IBoxSearchResultsContentSettings;
  content: IBoxSearchResultsContent;
}

const TopNavigation: React.FC<TopNavigationProps> = ({
  contentSettings: { showOnPage, sorting, pagination },
  content: { showOnPageText, sortingText },
  page,
  pageSize,
  pageCount,
  changePageSize,
  changePage,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchResultsMessages,
    IBoxSearchResultsSettings
  >();

  const isMobile = useIsMobilePortal();

  return (
    <StyledNavigation
      align={settings.optionsAlign}
      className="search-results__navigation"
      sameTypography={
        new Set([sorting.font.id, showOnPage.font.id, pagination.font.id])
          .size === 1
      }
    >
      <Sort
        sortSettings={sorting}
        label={sortingText}
        changeSort={(sort) => changePageSize(undefined, sort)}
      />
      {showOnPage.show && (
        <ShowOnPage
          choosen={pageSize}
          showOnPageSettings={showOnPage}
          label={showOnPageText}
          changeProductsPerPage={changePageSize}
        />
      )}
      {!isMobile && (
        <Pagination
          page={page}
          pageCount={pageCount}
          setPage={changePage}
          fromText={messages.paginationFrom}
          textSettings={pagination}
        />
      )}
    </StyledNavigation>
  );
};

export default TopNavigation;
