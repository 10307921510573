import { ChangeEvent, useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";
import {
  TButtonId,
  TButtonReadoutValue,
  TInputId,
  TInputReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxAddToCartQuantityB2BMessages,
  IBoxAddToCartQuantityB2BSettings,
} from "../../../../boxes/BoxAddToCartB2B/BoxAddToCartQuantityB2B.types";
import {
  IBoxSearchResultsB2BMessages,
  IBoxSearchResultsB2BSettings,
} from "../../../../boxes/BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import { useTemporaryErrorBySnackbar } from "../../../../helpers/useTemporaryErrorBySnackbar";
import StyledButton from "../../../../shared/styleElements/StyledButton/StyledButton";
import { useCommunicationBetweenBoxesContext } from "../../../../structure/Contexts/CommunicationBetweenBoxes";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { StyledInput } from "../../Input/StyledInput/StyledInput";
import { useOpenPortalSnackbar } from "../../Snackbar/Snackbar";
import { StyledChangeProductQuantityB2B } from "./StyledChangeProductQuantityB2B.styled";
import {
  findCartLineBySku,
  getProductType,
  quantityRounding,
  stockDisplaying,
} from "./cartB2B.methods";
import { useCartB2BContext } from "./useCartB2BContext";

type CartProps = {
  product: {
    sku: string;
    deliveryQuantity?: number;
    unitOfMeasure?: {
      currentUnitOfMeasure: string;
      availableUnitsOfMeasure: {
        unitOfMeasure: string;
        unitOfMeasureLabel: string;
        unitOfMeasureQuantity: number;
      }[];
    };
    concession?: boolean;
  };
  isCart: true;
};

type SRPProps = {
  product: {
    sku: string;
    deliveryQuantity: number;
    unitOfMeasure: {
      currentUnitOfMeasure: string;
      availableUnitsOfMeasure: {
        unitOfMeasure: string;
        unitOfMeasureLabel: string;
        unitOfMeasureQuantity: number;
      }[];
    };
    concession: boolean;
  };
  isCart: false;
};
export type IChangeProductQuantityProps = {
  minusButtonQuantityStyle: TButtonReadoutValue | TButtonId;
  plusButtonQuantityStyle: TButtonReadoutValue | TButtonId;
  inputQuantityStyle: TInputReadoutValue | TInputId;
  changeProductQuantityMinusLabel?: string;
  changeProductQuantityPlusLabel?: string;
  disabledInput?: boolean;
  spaceBetweenInputButtons?: number;
} & (CartProps | SRPProps);

const ChangeProductQuantityB2B: React.FC<IChangeProductQuantityProps> = (
  props
) => {
  const {
    product,
    inputQuantityStyle,
    minusButtonQuantityStyle,
    plusButtonQuantityStyle,
    disabledInput,
    changeProductQuantityMinusLabel = "-",
    changeProductQuantityPlusLabel = "+",
    spaceBetweenInputButtons = 10,
    isCart,
  } = props;

  const { addToCart, deleteProduct, cartData, status } = useCartB2BContext();
  const timeoutRef = useRef<number | null>(null);
  const { quantity, deliveryQuantity, unitOfMeasure, stock } =
    findCartLineBySku(cartData, product);

  const productType = getProductType(cartData, product.sku);
  const { chosenUnit } = useCommunicationBetweenBoxesContext();

  const isError = status !== "OK" && status !== "LOADING";
  const concession = product.concession;

  const { openPortalSnackbar } = useOpenPortalSnackbar();

  const [quantityState, setQuantityState] = useState(quantity);

  useEffect(() => {
    setQuantityState(quantity);
  }, [quantity]);

  const [stockExceeded, setStockExceeded] = useState(false);

  const { messages, settings, boxType } = useMessagesSettingsContext<
    | IBoxCartB2BMessages
    | IBoxAddToCartQuantityB2BMessages
    | IBoxSearchResultsB2BMessages,
    | IBoxCartB2BSettings
    | IBoxAddToCartQuantityB2BSettings
    | IBoxSearchResultsB2BSettings
  >();

  const temporaryError = useTemporaryErrorBySnackbar(stockExceeded);

  const disableMinusButton = quantityState <= 0 || isError;

  const disablePlusButton = stockExceeded || isError || concession === false;

  const disableInput = concession === false || disabledInput;

  const inputError =
    boxType === "B2B_ADD_TO_CART_QUANTITY" ? !temporaryError : undefined;

  const debouncedChangeProductQuantity = debounce((quantity: number) => {
    if (cartData?.stockVerification && stock !== null && quantity > stock) {
      openPortalSnackbar(
        settings.add_to_cart_error_snackbar,
        messages.product_error_stock_exceeded
      );
      setStockExceeded(true);
    } else if (quantity <= 0) {
      deleteProduct({ sku: product.sku, productType: productType ?? "" });
      setStockExceeded(false);
      setQuantityState(0);
      return;
    } else {
      setStockExceeded(false);
    }

    const newQuantity = quantityRounding(quantity, stock, deliveryQuantity);

    if (newQuantity > 0) {
      setQuantityState(newQuantity);
      addToCart({
        sku: product.sku,
        quantity: newQuantity,
        unitOfMeasure: unitOfMeasure.currentUnitOfMeasure,
        isCart,
      }).then(() => {
        setQuantityState(newQuantity);
      });
    }
  }, 500);

  const changeProductQuantity = (quantity: number) => {
    debouncedChangeProductQuantity(quantity);
  };

  const currentPackageTypeCapacity =
    unitOfMeasure.availableUnitsOfMeasure.find((pack) => {
      if (
        boxType === "B2B_ADD_TO_CART_QUANTITY" &&
        (settings as IBoxAddToCartQuantityB2BSettings).b2b_add_to_cart_unit ===
          "true"
      )
        return pack.unitOfMeasure === chosenUnit;
      else if (
        boxType === "B2B_ADD_TO_CART_QUANTITY" &&
        (settings as IBoxAddToCartQuantityB2BSettings).b2b_add_to_cart_unit ===
          "false"
      )
        return pack.unitOfMeasure === unitOfMeasure.baseUnitOfMeasure;
      else return pack.unitOfMeasure === unitOfMeasure.currentUnitOfMeasure;
    })?.unitOfMeasureQuantity ?? 1;

  const loseFocusAfterValueHasChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = window.setTimeout(() => {
      e.target.blur();
    }, 3000);
  };

  return (
    <StyledChangeProductQuantityB2B
      inputStyle={inputQuantityStyle}
      spaceBetweenInputButtons={spaceBetweenInputButtons}
    >
      <StyledButton
        renderAs="button"
        $settings={minusButtonQuantityStyle}
        className={`change-product-quantity-b2b__minus-button ${
          disableMinusButton ? "disabled-button" : ""
        }`}
        onClick={() =>
          changeProductQuantity(
            quantityState -
              (currentPackageTypeCapacity === 1
                ? deliveryQuantity
                : currentPackageTypeCapacity)
          )
        }
        data-testid="decrementButton"
      >
        <i />
        {changeProductQuantityMinusLabel}
      </StyledButton>
      <StyledInput
        className={"change-product-quantity-b2b__count-input"}
        $settings={inputQuantityStyle}
        value={stockDisplaying(quantityState, currentPackageTypeCapacity)}
        onChange={(e) => {
          setQuantityState(~~e.target.value * currentPackageTypeCapacity);
          loseFocusAfterValueHasChanged(e);
        }}
        onBlur={(e) => {
          changeProductQuantity(quantityState);
        }}
        onKeyPress={(e) => {
          e.key === "Enter" && changeProductQuantity(quantityState);
        }}
        data-testid="productCountInput"
        disabled={disableInput}
        formNoValidate={inputError}
      />
      <StyledButton
        renderAs="button"
        $settings={plusButtonQuantityStyle}
        className={`change-product-quantity-b2b__plus-button ${
          disablePlusButton ? "disabled-button" : ""
        }`}
        onClick={() => {
          changeProductQuantity(
            quantityState +
              (currentPackageTypeCapacity === 1
                ? deliveryQuantity
                : currentPackageTypeCapacity)
          );
        }}
        data-testid="incrementButton"
      >
        <i />
        {changeProductQuantityPlusLabel}
      </StyledButton>
    </StyledChangeProductQuantityB2B>
  );
};

export default ChangeProductQuantityB2B;
