import { useTheme } from "styled-components";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import {
  IThemeState,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { StyledSingleProductWrapper } from "../../../BoxMyAccountMyOrders/BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../../BoxMyAccountMyOrdersDetails.types";

interface MyOrderDeliveryProductsProps {
  product: IOrderDetailsResponse["order"]["portalOrderDispatches"]["0"]["products"]["0"];
  imageUrl: TUrl;
  imageHref?: TUrl;
}

const MyOrderDeliveryProducts: React.FC<MyOrderDeliveryProductsProps> = ({
  product: { productName, quantity, price, lineStatus },
  imageUrl,
  imageHref,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersDetailsMessages,
    IBoxMyAccountMyOrdersDetailsSettings
  >();

  const deliveryProductViewModel = {
    productTitle: productName,
    productCount: quantity,
    productPrice: price,
    totalProductPrice: price * quantity,
    status: lineStatus.status,
  };

  return (
    <StyledSingleProductWrapper
      elementPaddingX={
        settings.customer_data_order_details_dispatch_delivery_elements_gap
      }
      className="my-order-details-delivery__product"
    >
      <div className="my-order-details-delivery__product__container">
        <a href={imageHref}>
          <img
            src={imageUrl}
            alt={productName}
            className="my-order-details-delivery__product-image"
          />
        </a>
        <div className="my-order-details-delivery__product__info-container">
          <StyledText
            className="my-order-details-delivery__product-info-container__product-title"
            $settings={{
              font: settings.customer_data_order_details_heading_product_name_typo,

              text: {
                color:
                  settings.customer_data_order_details_heading_product_name_color,
              },
            }}
          >
            {deliveryProductViewModel.productTitle}
          </StyledText>

          <StyledText
            className="my-order-details-delivery__product-info-container__product-status"
            $settings={{
              font: settings.customer_data_order_details_product_status_label_typo,

              text: {
                color:
                  settings.customer_data_order_details_product_status_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_product_status
            )}
          >
            {`${messages.customer_data_order_details_product_status}: `}
            <StyledText
              className="my-order-details-delivery__product-info-container__product-status__value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_order_details_product_status_value_typo,
                text: {
                  color:
                    settings.customer_data_order_details_product_status_value_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.customer_data_order_details_product_status
              )}
            >
              {deliveryProductViewModel.status}
            </StyledText>
          </StyledText>

          <StyledText
            className="my-order-details-delivery__product-info-container__product-quantity__header"
            $settings={{
              font: settings.customer_data_order_details_product_quantity_label_typo,

              text: {
                color:
                  settings.customer_data_order_details_product_quantity_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_product_quantity
            )}
          >
            {`${messages.customer_data_order_details_product_quantity}: `}
            <StyledText
              className="my-order-details-delivery__product-info-container__product-quantity__value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_order_details_product_quantity_value_typo,
                text: {
                  color:
                    settings.customer_data_order_details_product_quantity_value_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.customer_data_order_details_product_quantity
              )}
            >
              {deliveryProductViewModel.productCount}
            </StyledText>
          </StyledText>

          <StyledText
            className="my-order-details-delivery__product-info-container__product-price__header"
            $settings={{
              font: settings.customer_data_order_details_product_price_label_typo,

              text: {
                color:
                  settings.customer_data_order_details_product_price_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_product_price
            )}
          >
            {`${messages.customer_data_order_details_product_price}: `}
            <StyledText
              className="my-order-details-delivery__product-info-container__product-price__value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_order_details_product_price_value_typo,
                text: {
                  color:
                    settings.customer_data_order_details_product_price_value_color,
                },
              }}
            >
              {formatPrice(
                deliveryProductViewModel.productPrice,
                globalMessages["currencyShort"]
              )}
            </StyledText>
          </StyledText>
          <StyledText
            className="my-order-details-delivery__product-info-container__product-total-price__header"
            $settings={{
              font: settings.customer_data_order_details_line_price_label_typo,

              text: {
                color:
                  settings.customer_data_order_details_line_price_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_line_price
            )}
          >
            {`${messages.customer_data_order_details_line_price}: `}
            <StyledText
              className="my-order-details-delivery__product-info-container__product-total-price__value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_order_details_line_price_value_typo,
                text: {
                  color:
                    settings.customer_data_order_details_line_price_value_color,
                },
              }}
            >
              {formatPrice(
                deliveryProductViewModel.totalProductPrice,
                globalMessages["currencyShort"]
              )}
            </StyledText>
          </StyledText>
        </div>
      </div>
    </StyledSingleProductWrapper>
  );
};

export default MyOrderDeliveryProducts;
