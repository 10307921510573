import styled from "styled-components";
import {
  FontFamilyOptions,
  FontWeightOptionsLongListOfWeights,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import composeCss from "../../composeCss";
import { SelectedGoToOption } from "../../contentPatterns.types";
import { convertLinkIdToValues } from "../../settingsPatterns.methods";
import {
  BreadcrumbSeparatorType,
  BreadcrumbTypes,
  IBoxBreadcrumbContent,
  IBoxBreadcrumbContentSettings,
  IBoxBreadcrumbDisplaySettings,
  IBoxBreadcrumbSettings,
} from "./StyledBreadcrumb.types";

export const BOX_BREADCRUMB_DISPLAY_PATTERN =
  (): IBoxBreadcrumbDisplaySettings => ({
    breadcrumbs: {
      text: {
        id: "typo$$1",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      link: {
        id: "link$$1",
        custom: {
          text: {
            color: { id: "color$$1", custom: "#000000" },
            hoverColor: { id: "color$$1", custom: "#000000" },
            activeColor: { id: "color$$1", custom: "#000000" },
          },
        },
      },
    },
    separator: {
      type: BreadcrumbSeparatorType.BACKSLASH,
      text: {
        id: "typo$$3",
        custom: {
          family: FontFamilyOptions.ROBOTO,
          weight: FontWeightOptionsLongListOfWeights.Weight300,
          size: 24,
          lineHeight: 10,
          letterSpacing: 1,
        },
      },
      color: { id: "color$$1", custom: "#000000" },
    },
  });

export const BOX_BREADCRUMB_CONTENT_SETTINGS_PATTERN =
  (): IBoxBreadcrumbContentSettings => ({});

export const BOX_BREADCRUMB_CONTENT_PATTERN = (): IBoxBreadcrumbContent => ({
  breadcrumbs: [],
  type: BreadcrumbTypes.AUTO,
  homeBreadcrumb: {
    heading: "",
    link: {
      showLinkOptions: true,
      selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
      goToRowSetting: {
        rowId: null,
      },
      goToPageSetting: {
        link: "/",
        openInNewTab: true,
      },
    },
  },
  searchResultBreadcrumb: "",
});

export const BOX_BREADCRUMB_PATTERN = () => ({
  ...BOX_BREADCRUMB_DISPLAY_PATTERN(),
  ...BOX_BREADCRUMB_CONTENT_SETTINGS_PATTERN(),
});

export const generateBoxBreadcrumbStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxBreadcrumbSettings["$settings"];
  theme: IBoxBreadcrumbSettings["theme"];
}) => {
  const cssStyles = `
    display: flex;
    flex-flow: wrap;

    .breadcrumb{
      display: flex;
      align-items: center;
      ${composeCss.font($settings.breadcrumbs.text, theme.typography)}
      
      .separator{
        ${composeCss.font($settings.separator.text, theme.typography)}
        ${composeCss.color(
          { color: $settings.separator.color },
          theme.colorPalette
        )}
        padding: 0 8px 0 8px;
      }

    }
    
    breadcrumb-container__breadcrumb__search-result{
      pointer-events: none;
    }

    .breadcrumb:last-child{
      pointer-events: none;
      .heading{
        ${composeCss.color(
          {
            color: convertLinkIdToValues(
              $settings.breadcrumbs.link,
              theme.globalObjects.links
            ).text.activeColor,
          },
          theme.colorPalette
        )}
        padding: 0 8px 0 8px;
      }
    }

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      flex-flow: nowrap;
      overflow-x: auto;
      
      &::-webkit-scrollbar {
        height: 5px;
    }

    ::-webkit-scrollbar-track {
      background-color: #eeeeee;
    }

    ::-webkit-scrollbar-thumb{
      color: #cccccc;
    }

      .breadcrumb{
        flex-shrink: 0;
      }
    }
  `;
  return cssStyles;
};

export const StyledBreadcrumb = styled.div<IBoxBreadcrumbSettings>((props) =>
  generateBoxBreadcrumbStyles(props)
);
