import {
  ISearchResultsRequest,
  ISearchResultsResponse,
  ISearchResultsTransformResponse,
  ISrpRequest,
  ISrpResponse,
  ISrpTransformResponse,
  ISrpWithSearchRequest,
  ISrpWithSearchResponse,
  ISrpWithSearchTransformResponse,
  ISrpWithSearchTransformResponseV2,
  isCategoryValue,
} from "../dto/searchResults.types";
import { getLineAttributesDetected } from "../helpers";
import { API, api } from "./api";
import {
  sortProductsInOrderFromSearch,
  transformSrpResponse,
} from "./searchResults.methods";

export const searchResults = api.injectEndpoints({
  endpoints: (builder) => ({
    getSearchResults: builder.query<
      ISearchResultsTransformResponse,
      ISearchResultsRequest
    >({
      query: ({ query }) => API.getSearchResults.endpoint(query),
      transformResponse: (
        result: ISearchResultsResponse,
        _,
        { strictCategory }
      ) => {
        const productSkus = result.products?.map((product) => product.sku);

        if (strictCategory) {
          const parameters = result?.parameters.map((param) => {
            if (isCategoryValue(param)) {
              return {
                ...param,
                values: param.values.filter((subCategory) => {
                  return strictCategory.length >= subCategory.id.length
                    ? strictCategory.startsWith(subCategory["id"])
                    : subCategory["id"].startsWith(strictCategory);
                }),
              };
            }
            return param;
          });
          return { ...result, products: productSkus, parameters };
        }
        return { ...result, products: productSkus };
      },
      providesTags: ["CONTRACTORS"],
    }),
    getSearchWithSrpResults: builder.query<
      ISrpWithSearchTransformResponse,
      ISrpWithSearchRequest
    >({
      query: ({ query, ...body }) => {
        return {
          url: API.getSearchWithSrpResults.endpoint(query),
          method: "POST",
          body,
        };
      },
      transformResponse: (result: ISrpWithSearchResponse, meta, arg) => {
        const transformedResult = transformSrpResponse(result.products);
        return {
          products: transformedResult,
          size: result.size,
          attributesLineDetection: getLineAttributesDetected(
            transformedResult,
            arg.attributes
          ),
        };
      },
      providesTags: ["SEARCH_RESULTS", "CONTRACTORS"],
    }),
    getSearchWithSrpResultsV2: builder.query<
      ISrpWithSearchTransformResponseV2,
      { searchQueries: string; projection: string[] }
    >({
      query: ({ searchQueries, ...body }) => {
        return {
          url: API.getSearchWithSrpResultsV2.endpoint(searchQueries),
          method: "POST",
          body,
        };
      },

      providesTags: ["SEARCH_RESULTS", "CONTRACTORS"],
    }),
    getPostProducts: builder.query<ISrpTransformResponse[], ISrpRequest>({
      query: (body) => {
        return {
          url: API.getSearchProducts.endpoint(),
          method: "POST",
          body,
        };
      },
      transformResponse: (result: ISrpResponse[], _, arg: ISrpRequest) => {
        // justInCaseSortedProduct - BE should guarantee the correct order. However, just in case, we check it again.
        const justInCaseSortedProduct = sortProductsInOrderFromSearch(
          result,
          arg.skus
        );
        return transformSrpResponse(justInCaseSortedProduct);
      },
      providesTags: ["SEARCH_RESULTS"],
    }),
  }),
});

export const {
  useGetSearchResultsQuery,
  useLazyGetSearchResultsQuery,
  useLazyGetPostProductsQuery,
  useGetPostProductsQuery,
  useGetSearchWithSrpResultsQuery,
  useLazyGetSearchWithSrpResultsQuery,
  useGetSearchWithSrpResultsV2Query,
} = searchResults;

export const { getPostProducts, getSearchWithSrpResults, getSearchResults } =
  searchResults.endpoints;
