import React, { useRef } from "react";
import { useTheme } from "styled-components";
import * as cartAPI from "@ecp-redux/api/cart";
import { IOrderLine, ProductStatusOption } from "@ecp-redux/dto/cart.types";
import {
  DeliveryTimeCode,
  TSrpProductType,
} from "@ecp-redux/dto/searchResults.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
  TColorId,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useImageSize } from "../../../../../../../lib/global/components/ImageWrapper/ImageWrapper.methods";
import ImageWrapper from "../../../../../../global/components/ImageWrapper/ImageWrapper";
import { formatPrice, isTruthy } from "../../../../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../../../../helpers/isNotEmptyMessage";
import ClipboardIcon from "../../../../../../shared/components/Clipboard/ClipboardIcon";
import ChangeProductQuantity from "../../../../../../shared/components/domain/Cart/ChangeProductQuantity/ChangeProductQuantity";
import useIsMobilePortal from "../../../../../../shared/hooks/useIsMobilePortal";
import { ReactComponent as TrashIcon } from "../../../../../../shared/icons/trash.svg";
import StyledText from "../../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../../structure/Contexts/MessagesSettingsContext";
import {
  StyledCrossedArea,
  StyledProductsCartElement,
} from "../../../BoxCartStepOne.styled";
import {
  IBoxCartStepOneMessages,
  IBoxCartStepOneSettings,
} from "../../../BoxCartStepOne.types";

interface ProductCartElementProps {
  product: IOrderLine;
  imageUrl: TUrl;
  imageHref?: TUrl;
  productType?: TSrpProductType;
  deliveryTime?: DeliveryTimeCode;
  onWishList?: boolean;
}

const ProductCartElement: React.FC<ProductCartElementProps> = ({
  product,
  imageUrl,
  imageHref,
  deliveryTime,
  productType,
  onWishList = false,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepOneMessages,
    IBoxCartStepOneSettings
  >();
  const {
    advanceSettings: {
      settings: { snackbarApiError },
      messages: globalMessages,
    },
  } = useTheme() as IThemeState;
  const currencyShort = globalMessages.currencyShort;

  const [putQuantity] = cartAPI.usePutChangeProductCountMutation();
  const [imgWrapper, imageWidth] = useImageSize();
  const productCartElement = useRef<HTMLAnchorElement>(null);
  const isMobile = useIsMobilePortal();

  const unavailableProduct =
    product.stockLevelStatus === ProductStatusOption.NOT_AVAILABLE ||
    product.enabled === false ||
    product.deliveryTime === DeliveryTimeCode.NOT_AVAILABLE;

  const chooseTextColor = (color: TColorId): TColorId =>
    unavailableProduct ? settings.basket_unavialable_text_color : color;

  const deliveryTimeMessage =
    deliveryTime && globalMessages[`delivery_${deliveryTime}`];

  return (
    <StyledProductsCartElement
      ref={imgWrapper}
      unvailableProduct={unavailableProduct}
      imageSize={settings.basket_step_0_image_size}
      className="product-cart-element"
    >
      {unavailableProduct && <StyledCrossedArea />}
      <a
        ref={productCartElement}
        href={imageHref}
        className="product-cart-element__image"
      >
        {!isMobile && settings.wishlist_icon_visible === "true" && productType && (
          <ClipboardIcon
            isOnWishList={onWishList}
            productData={{
              sku: product.productSku,
              type: productType,
            }}
          />
        )}

        <ImageWrapper
          {...{ imageUrl }}
          seoDescription={product.productName}
          imageFit={false}
          imageAlignment={{
            horizontal: AlignmentHorizontalOption.CENTER,
            vertical: AlignmentVerticalOption.CENTER,
          }}
          width={imageWidth}
        />
      </a>

      <div className="product_cart_info">
        <StyledText
          className="product_cart_info__text"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_2,
            text: {
              color: chooseTextColor(settings.basket_step_all_color_main),
            },
          }}
        >
          <p className="product-cart-element__name">{product.productName}</p>
          <div className="product-cart-element__icons">
            <button
              className="product-cart-element__remove-button"
              onClick={() =>
                putQuantity({
                  productSku: product.productSku,
                  quantity: -product.quantity,
                })
              }
            >
              <TrashIcon />
            </button>
            {isMobile &&
              settings.wishlist_icon_visible === "true" &&
              productType && (
                <div className="product-cart-element__wishlist-icon">
                  <ClipboardIcon
                    strokeColor={"black"}
                    isOnWishList={onWishList}
                    productData={{
                      sku: product.productSku,
                      type: productType,
                    }}
                  />
                </div>
              )}
          </div>
        </StyledText>

        {product.suggestedRetailPrice !== product.salePrice && (
          <StyledText
            className="product-cart-element__suggested-retail-price"
            $settings={{
              font: settings.basket_step_all_typography_paragraph_1,
              text: {
                color: chooseTextColor(settings.basket_step_all_color_second),
              },
            }}
          >
            <p>{formatPrice(product.suggestedRetailPrice, currencyShort)}</p>
          </StyledText>
        )}

        <StyledText
          className="product-cart-element__sale-price"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_1,
            text: {
              color: chooseTextColor(settings.basket_step_all_color_main),
            },
          }}
        >
          <p>{formatPrice(product.salePrice, currencyShort)}</p>
        </StyledText>

        <StyledText
          className="product-cart-element__delivery-time"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_1,
            text: {
              color: chooseTextColor(settings.basket_step_all_color_main),
            },
          }}
          show={
            isNotEmptyMessage(messages.cart_delivery_time) &&
            deliveryTime &&
            deliveryTime !== DeliveryTimeCode.NOT_AVAILABLE &&
            isTruthy(settings.basket_step_0_show_delivery_time)
          }
        >
          <p>{`${messages.cart_delivery_time} ${deliveryTimeMessage}`}</p>
        </StyledText>
      </div>
      <div className="product_cart_quantity">
        <ChangeProductQuantity
          className="product-cart-element__change-quantity"
          product={product}
          inputQuantityStyle={settings.basket_step_0_input_product_amount}
          disabled={unavailableProduct}
          errorSnackbarStyle={snackbarApiError}
        />

        <StyledText
          className="product-cart-element__total-price"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_1,
            text: {
              color: chooseTextColor(settings.basket_step_all_color_main),
            },
          }}
        >
          <p>
            {formatPrice(product.quantity * product.salePrice, currencyShort)}
          </p>
        </StyledText>
      </div>
    </StyledProductsCartElement>
  );
};

export default ProductCartElement;
