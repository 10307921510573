import { useMemo } from "react";
import {
  TDropdownId,
  TDropdownReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ThemeStyledDropdown from "../../../../../shared/styleElements/StyledDropdown/ThemeStyledDropdown";

const ChoosePackageTypeB2B: React.FC<{
  product: {
    currentUnitOfMeasure: string;
    availableUnitsOfMeasure: {
      unitOfMeasure: string;
      unitOfMeasureLabel: string;
      unitOfMeasureQuantity: number;
    }[];
    mainUnitOfMeasure: string;
  };
  onChange: (unitOfMeasure: string) => void;
  dropdownStyle: TDropdownReadoutValue | TDropdownId;
  className?: string;
}> = ({ product, className, dropdownStyle, onChange }) => {
  const items = useMemo(
    () =>
      product.availableUnitsOfMeasure.map(
        ({ unitOfMeasure, unitOfMeasureLabel, unitOfMeasureQuantity }) => ({
          id: unitOfMeasure,
          label:
            unitOfMeasure === product.mainUnitOfMeasure
              ? unitOfMeasureLabel
              : `${unitOfMeasureLabel} (${unitOfMeasureQuantity})`,
        })
      ),
    [product.availableUnitsOfMeasure]
  );

  return (
    <ThemeStyledDropdown
      style={dropdownStyle}
      data-testid="choose-package-type-b2b"
      items={items}
      selectedItemId={product.currentUnitOfMeasure}
      onClickItem={onChange}
      className={className}
    />
  );
};

export default ChoosePackageTypeB2B;
