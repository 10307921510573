import { GlobalTableCellReadoutValue } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import { IAlignment } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../../global/components/ImageWrapper/ImageWrapper";
import LinkWrapper from "../../../global/components/LinkWrapper/LinkWrapper";
import { StyledTableCell } from "../../../shared/components/Table/TableCell";

interface ITableDataImageCell {
  tableCellStyleId: GlobalTableCellReadoutValue;
  imageUrl: TUrl | null;
  seoDescription: string | null;
  imageFit: boolean;
  imageAlignment: IAlignment;
  width: number;
  rowHeight: number;
  isOdd: boolean;
  redirectUrl?: string;
}
export const TableDataImageCell: React.FC<ITableDataImageCell> = ({
  tableCellStyleId,
  imageUrl,
  seoDescription,
  imageFit,
  imageAlignment = {
    horizontal: AlignmentHorizontalOption.CENTER,
    vertical: AlignmentVerticalOption.CENTER,
  },
  width,
  rowHeight,
  isOdd,
  redirectUrl,
}) => {
  return (
    <StyledTableCell
      tableCellStyleId={tableCellStyleId}
      isOdd={isOdd}
      height={rowHeight}
    >
      <div className="imageContainer">
        <ConditionalWrapper
          condition={redirectUrl !== undefined}
          wrapper={(children) => (
            <LinkWrapper href={redirectUrl}>{children}</LinkWrapper>
          )}
        >
          <ImageWrapper
            width={width}
            height={rowHeight}
            imageUrl={imageUrl}
            seoDescription={seoDescription}
            imageFit={imageFit}
            imageAlignment={imageAlignment}
          />
        </ConditionalWrapper>
      </div>
    </StyledTableCell>
  );
};

export default TableDataImageCell;
