import React from "react";
import SimplyAlert, { SimplyAlertProps } from "./SimplyAlert";

interface LocalAlertProps extends SimplyAlertProps {
  open: boolean;
}

const LocalAlert: React.FC<LocalAlertProps> = (props): JSX.Element | null => {
  if (!props.open) {
    return null;
  }

  return <SimplyAlert {...props} lifetime={5000} />;
};

export default LocalAlert;
