import styled from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { IBoxCartB2BSettings } from "./BoxCartB2B.types";

export const StyledCartB2B = styled.div(
  ({
    settings,
    theme,
    summaryHeight,
  }: {
    settings: IBoxCartB2BSettings;
    theme: IThemeState;
    summaryHeight: number;
  }) => {
    const isMobile = useIsMobilePortal();

    return `
      display: flex;
      flex-direction: column;
      gap: 20px;

      .cart-step-one__alerts-container {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      ${
        isMobile
          ? `
              .cart-step-one {
                &__wrapper {
                  display: flex;
                  flex-direction: column;
                }

                &__content {
                  display: flex;
                  gap: 20px;
                  flex-direction: column;
                };
              }

              .delivery-container {
                bottom: ${summaryHeight}px;  
              }
            `
          : `
              margin-bottom: 20px;

              .cart-step-one {
                &__wrapper {
                  display: grid;
                  grid-template-columns: minmax(0, 2fr) 1fr;
                }

                &__content {
                  display: flex;
                  gap: 20px;
                  flex-direction: column;
                  padding-right: 20px;
                  border-right: ${
                    settings.basket_header_line_size
                  } solid ${convertColorIdToHex(
              settings.basket_header_line_color,
              theme.colorPalette
            )}; 
                };
            `
      }



    }
  `;
  }
);
