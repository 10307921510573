import styled, { css } from "styled-components";
import {
  IThemeState,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";

interface IStyledSummary {
  geoIconColor: TColorId;
  columnBackgroundColor: TColorId;
  backgroundColor: TColorId;
  theme: IThemeState;
}

export const StyledSummary = styled.div(
  ({
    geoIconColor,
    columnBackgroundColor,
    backgroundColor,
    theme,
  }: IStyledSummary) => {
    const isMobile = useIsMobilePortal();

    return css`
      transition: 300ms;
      display: grid;
      grid-template-rows: ${isMobile
        ? "auto 1fr min-content"
        : "repeat(3, min-content)"};
      touch-action: none;
      width: 100%;
      padding: 20px;
      background-color: ${convertColorIdToHex(
        isMobile ? backgroundColor : columnBackgroundColor,
        theme.colorPalette
      )};

      &.rolled {
        grid-template-rows: auto 0fr min-content;
      }

      ${isMobile
        ? `
        position: fixed;
        width: calc(100% + 1px);
        bottom: 0;
        z-index: var(--zindex-portal);
        padding: 0 20px 0 20px;
        overflow: hidden;
        box-shadow: 0px -5px 10px 0px #00000040;

      `
        : ""}

      .summary-container {
        &__swipe-button {
          display: ${isMobile ? "flex" : "none"};
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 24px;
          grid-area: 1/1/2/2;
        }

        &__content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          transition: grid-template-rows 0.5s ease-in-out;
          grid-area: 2/1/3/2;

          overflow: hidden;

          &__header {
            border-bottom: 1px solid #cccccc;
            padding-bottom: 20px;
          }

          &__price-container,
          &__payment-container,
          &__delivery {
            background-color: ${convertColorIdToHex(
              backgroundColor,
              theme.colorPalette
            )};
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 16px;
          }

          &__payment-container {
            &__payment-method {
              display: flex;
              justify-content: space-between;
              align-items: center;

              &__label {
                width: 100%;
              }
            }
          }

          &__delivery {
            flex-direction: row;
            border-bottom: 1px solid #cccccc;

            &__address {
              display: flex;
              gap: 10px;

              &__geo-icon {
                path {
                  stroke: ${convertColorIdToHex(
                    geoIconColor,
                    theme.colorPalette
                  )};
                }
              }
            }
          }

          &__final-summary {
            grid-area: 3/1/4/2;
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-bottom: 10px;
            z-index: 1;
            ${isMobile
              ? `
            background-color: ${convertColorIdToHex(
              backgroundColor,
              theme.colorPalette
            )};
          `
              : ""}

            &__total-price {
              background-color: ${convertColorIdToHex(
                backgroundColor,
                theme.colorPalette
              )};
              padding: 20px 16px 16px 16px;

              &.rolled {
                padding: 0 16px 16px 16px;
              }
            }

            &__order-button {
              width: 100%;
              padding-top: 10px;
            }
          }
        }
      }
    `;
  }
);

export const StyledPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;
