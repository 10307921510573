import { useMemo } from "react";
import isEqual from "lodash/isEqual";
import { z } from "zod";
import { IPortalSlot } from "@ecp-pageTypes";
import { PathStringTypeSchema } from "../../settingsPatterns/contentPatterns.scheme";
import { useDynamicPageContext } from "../../structure/Contexts/DynamicPageContext";

export type PathStringType = z.infer<typeof PathStringTypeSchema>;

export type PathNumberType = number | "x";

export class PortalPath {
  private readonly path: Element[] = [];

  static emptyPath() {
    return new PortalPath("");
  }

  constructor(path?: PathStringType | Element[]) {
    //section:1,123-slot:2,321-stack:2,2-publication:2,111
    if (path === "" || path === undefined) {
      this.path = [];
      return;
    }

    if (Array.isArray(path)) {
      this.path = path;
      return;
    }

    const splited = path.split("-");
    splited.forEach((e) => {
      const [type, values] = e.split(":");
      const [index, id] = values.split(",");
      const indexNumb = index === "x" ? "x" : parseFloat(index);
      const idNumb = id === "x" ? "x" : parseFloat(id);

      switch (type) {
        case "main":
          this.path.push(new Element("MAIN", "x", "x"));
          break;
        case "header":
          this.path.push(new Element("HEADER", "x", "x"));
          break;
        case "footer":
          this.path.push(new Element("FOOTER", "x", "x"));
          break;
        case "section":
          this.path.push(new Element("SECTION", indexNumb, idNumb));
          break;
        case "slot":
          this.path.push(new Element("SLOT", indexNumb, idNumb));
          break;
        case "stack":
          this.path.push(new Element("STACK", indexNumb, idNumb));
          break;
        case "publication":
          this.path.push(new Element("PUBLICATION", indexNumb, idNumb));
          break;

        default:
          throw Error(`Invalid page path ${path}`);
      }
    });
  }

  isPathEqual(path: PathStringType) {
    return isEqual(this.toString(), path);
  }

  toString(): PathStringType {
    let resultPath = "";
    this.path.forEach((e) => {
      switch (e.type) {
        case "MAIN":
          resultPath = resultPath.concat(
            `main:${e.elementIndex},${e.elementId}-`
          );
          break;
        case "HEADER":
          resultPath = resultPath.concat(
            `header:${e.elementIndex},${e.elementId}-`
          );
          break;
        case "FOOTER":
          resultPath = resultPath.concat(
            `footer:${e.elementIndex},${e.elementId}-`
          );
          break;
        case "SECTION":
          resultPath = resultPath.concat(
            `section:${e.elementIndex},${e.elementId}-`
          );
          break;
        case "SLOT":
          resultPath = resultPath.concat(
            `slot:${e.elementIndex},${e.elementId}-`
          );
          break;
        case "STACK":
          resultPath = resultPath.concat(
            `stack:${e.elementIndex},${e.elementId}-`
          );
          break;
        case "PUBLICATION":
          resultPath = resultPath.concat(
            `publication:${e.elementIndex},${e.elementId}-`
          );
          break;
      }
    });
    return resultPath.slice(0, -1) as PathStringType;
  }

  concatPaths(path: PortalPath) {
    //TODO validate if path is correct order section-slot-publication
    return new PortalPath([...this.path, ...path.path]);
  }

  containById(pagePath: PortalPath) {
    return pagePath.path.every((e, i) => {
      return (
        this.path[i]?.type === e.type && this.path[i]?.elementId == e.elementId
      );
    });
  }

  isPublicationPath() {
    return this.path.some((e) => e.type === "PUBLICATION");
  }

  getLastIndex() {
    return this.path.at(-1)?.elementId ?? -1;
  }

  isEmpty() {
    return this.path.length === 0;
  }

  getLastSection() {
    return (
      this.path
        .slice()
        .reverse()
        .find((e) => e.type === "SECTION")?.elementId ?? "x"
    );
  }

  getFirstSection() {
    return this.path.find((e) => e.type === "SECTION")?.elementId ?? "x";
  }

  getLastSlot() {
    return (
      this.path
        .slice()
        .reverse()
        .find((e) => e.type === "SLOT")?.elementId ?? "x"
    );
  }

  hasStack() {
    return this.path.some((e) => e.type === "STACK");
  }

  getLastPublication() {
    return (
      this.path
        .slice()
        .reverse()
        .find((e) => e.type === "PUBLICATION")?.elementId ?? "x"
    );
  }

  isSubsection() {
    const pathWithoutPageType = this.path.filter(
      (p) => p.type !== "MAIN" && p.type !== "HEADER" && p.type !== "FOOTER"
    );
    return (
      pathWithoutPageType.length >= 3 &&
      pathWithoutPageType[0].type === "SECTION" &&
      pathWithoutPageType[1].type === "SLOT" &&
      pathWithoutPageType[2].type === "SECTION"
    );
  }

  getPageType() {
    if (this.path[0].type === "HEADER") return "HEADER";
    if (this.path[0].type === "FOOTER") return "FOOTER";
    return "MAIN";
  }

  isPageTypeInfoInPath() {
    return (
      this.path[0].type === "HEADER" ||
      this.path[0].type === "FOOTER" ||
      this.path[0].type === "MAIN"
    );
  }

  getIterator() {
    return new PortalPagePathIterator(this.path);
  }
}
class PortalPagePathIterator {
  path: Element[] = [];
  iterationState: number;

  constructor(pagePath: Element[]) {
    this.path = pagePath;
    this.iterationState = 0;
  }

  next() {
    if (this.hasMore()) {
      this.iterationState += 1;
      return this.path[this.iterationState - 1];
    } else {
      return this.path[0];
    }
  }

  hasMore() {
    return this.iterationState < this.path.length;
  }

  reset() {
    this.iterationState = 0;
  }
}

class Element {
  type: ElementTypePortal;
  elementId: PathNumberType;
  elementIndex: PathNumberType;

  constructor(
    type: ElementTypePortal,
    elementIndex: PathNumberType,
    elementId: PathNumberType
  ) {
    this.type = type;
    this.elementId = elementId;
    this.elementIndex = elementIndex;
  }
}

export type ElementTypePortal =
  | "SECTION"
  | "SLOT"
  | "STACK"
  | "PUBLICATION"
  | "FOOTER"
  | "HEADER"
  | "MAIN";

//this path argument never have publication
export const usePagePathId = (path: PortalPath, slotData: IPortalSlot) => {
  if (path.isPublicationPath()) throw Error("Invalid path");
  const { activePaths } = useDynamicPageContext();

  const reducedActivePaths = useMemo(
    () =>
      activePaths.reduce(
        (acc: PortalPath[], curr) => [...acc, ...curr.paths],
        []
      ),
    [activePaths]
  );

  const publicationIndex = useMemo(
    () =>
      slotData.stacks.map((e) => {
        const i = e.publications.findIndex((x) =>
          reducedActivePaths.find(
            (p) => p.containById(path) && p.getLastPublication() == x.id
          )
        );
        return i === -1 ? 0 : i;
      }),
    [slotData.stacks, reducedActivePaths]
  );

  return { publicationIndex };
};
