import React, { PropsWithChildren, useEffect, useState } from "react";
import useTranslation from "next-translate/useTranslation";
import { useTheme } from "styled-components";
import { PageCategoryType } from "@ecp-pageTypes";
import * as articleApi from "@ecp-redux/api/articles";
import { TArticle } from "@ecp-redux/dto/article.type";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isPortalSide } from "../../helpers/helpers";
import { ArticleContext, useCheckPageContext } from "../../helpers/pageContext";
import {
  getArticleFilters,
  parseHtmlToReactArticle,
} from "./BoxArticleContent.methods";
import { StyledArticleContent } from "./BoxArticleContent.styled";
import { TBoxArticleContentProps } from "./BoxArticleContent.types";

export const BoxArticleContent: React.FC<TBoxArticleContentProps> = (
  props: PropsWithChildren<TBoxArticleContentProps>
) => {
  const themeSettings = useTheme() as IThemeState;
  const { t } = useTranslation();
  const [articleContent, setArticleContent] = useState<TArticle>();
  const [getArticleRequest, articleRequest] =
    articleApi.useGetArticlesMutation();

  const context = useCheckPageContext<ArticleContext>(PageCategoryType.ARTICLE);
  const contextArticleId =
    context.type === PageCategoryType.ARTICLE ? context.articleId : null;

  useEffect(() => {
    if (!articleRequest.isSuccess || articleRequest?.data?.result === undefined)
      return;

    setArticleContent(articleRequest.data.result[0]);
  }, [articleRequest]);

  useEffect(() => {
    getArticleRequest({
      filter: getArticleFilters(contextArticleId),
      page: { number: 1, size: 999999 },
      sort: [
        {
          name: "",
          desc: false,
        },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextArticleId]);

  if (articleContent === undefined)
    return (
      <div className="article-content--empty">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );

  return (
    <StyledArticleContent className="article-content">
      {articleContent &&
        parseHtmlToReactArticle(
          articleContent.content,
          props,
          themeSettings.stylePages.pages.maxWidth
        )}
    </StyledArticleContent>
  );
};

export default BoxArticleContent;
