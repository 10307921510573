import React, { useState } from "react";
import { useRouter } from "next/router";
import { ISrpTransformResponseV2 } from "@ecp-redux/dto/searchResults.types";
import { TLinkId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import Table from "../../shared/components/Table/Table";
import { TableProps } from "../../shared/components/Table/Table.types";
import { StyledTableCell } from "../../shared/components/Table/TableCell";
import BadgesList from "../../shared/components/domain/Product/ProductModule/BadgesList/BadgesList";
import { useBoxSearchResultPaginationV2 } from "../BoxSearchResults/components/useSearchResultPaginationData";
import TableDataAddToCartActionCell from "../BoxTableData/elements/TableDataAddToCartActionCell";
import TableDataImageCell from "../BoxTableData/elements/TableDataImageCell";
import TableDataPackageTypeCell from "../BoxTableData/elements/TableDataPackageCell";
import TableDataTextCell from "../BoxTableData/elements/TableDataTextCell";
import {
  BoxSearchResultsB2BContentSettingsScheme,
  BoxSearchResultsB2BDisplaySettingsScheme,
  IBoxSearchResultsB2BProps,
  ISrpB2bAttributeData,
  SrpB2bColumnType,
} from "./BoxSearchResultsB2B.types";
import useGetProductValueByKey from "./hooks/GetProductValueByKey";

interface ColumnTypeComponentsProps {
  cellSettings: ISrpB2bAttributeData;
  columnKey: string;
  product: ISrpTransformResponseV2;
  linkStyle: TLinkId;
  valueFromProduct: string | null;
  unitOfMeasure: {
    unitOfMeasure: string;
    setUnitOfMeasure: (unitOfMeasure: string) => void;
  };
}

const getSortingFromColumnKey = (columnKey: string) => {
  switch (columnKey) {
    case "netPrice":
      return "net_price";
    case "grossPrice":
      return "gross_price";
    case "ProductName":
    case "Product Name": {
      return "name";
    }

    default:
      return columnKey;
  }
};

const isSortableColumn = (key: string) => {
  return (
    key === "Product Name" ||
    key === "ProductName" ||
    key === "grossPrice" ||
    key === "netPrice"
  );
};

const shouldRenderLink = (key: string) => {
  return key === "Product Name" || key === "ProductName" || key === "name";
};

const columnTypeComponents = ({
  cellSettings,
  columnKey,
  product,
  linkStyle,
  valueFromProduct,
  unitOfMeasure,
}: ColumnTypeComponentsProps): TableProps["rows"]["0"][""] => {
  if (
    cellSettings.columnType === SrpB2bColumnType.ACTIONS ||
    cellSettings.columnType === SrpB2bColumnType.UNITS
  ) {
    switch (cellSettings.columnType) {
      case SrpB2bColumnType.ACTIONS:
        return {
          component: (isOdd) => (
            <TableDataAddToCartActionCell
              tableCellStyleId={cellSettings.dataStyle}
              inputStyleId={cellSettings.actionInput}
              plusButtonStyleId={cellSettings.actionPlusButton}
              minusButtonStyleId={cellSettings.actionMinusButton}
              plusLabel={cellSettings.actionPlusLabel}
              minusLabel={cellSettings.actionMinusLabel}
              spaceBetweenInputButtons={
                cellSettings.actionSpaceBetweenInputButtons
              }
              actionAlignment={cellSettings.actionAlignment}
              isOdd={isOdd}
              product={product}
              unitOfMeasure={unitOfMeasure.unitOfMeasure}
            />
          ),
          isEmpty: false,
        };
      case SrpB2bColumnType.UNITS:
        return {
          component: (isOdd) => (
            <TableDataPackageTypeCell
              tableCellStyleId={cellSettings.dataStyle}
              dropdownStyle={cellSettings.dropdownStyle}
              isOdd={isOdd}
              product={product}
              setUnitOfMeasure={(unitOfMeasureQuantity) =>
                unitOfMeasure.setUnitOfMeasure(unitOfMeasureQuantity)
              }
              unitOfMeasure={unitOfMeasure.unitOfMeasure}
            />
          ),
          isEmpty: false,
        };
    }
  }

  if (columnKey === "badges" && product.badges.length > 0) {
    return {
      component: (isOdd) => (
        <StyledTableCell
          isOdd={isOdd}
          tableCellStyleId={cellSettings.dataStyle}
          columnWithBadges
        >
          <BadgesList product={product} />
        </StyledTableCell>
      ),
      isEmpty: false,
    };
  }

  if (valueFromProduct === null) {
    return {
      component: (isOdd) => (
        <StyledTableCell
          isOdd={isOdd}
          tableCellStyleId={cellSettings.dataStyle}
        >
          -
        </StyledTableCell>
      ),
      isEmpty: true,
    };
  }

  switch (cellSettings.columnType) {
    case SrpB2bColumnType.IMAGE:
      return {
        component: (isOdd, rowHeight) => (
          <TableDataImageCell
            tableCellStyleId={cellSettings.dataStyle}
            imageUrl={valueFromProduct}
            seoDescription={columnKey}
            imageFit={cellSettings.imageFit}
            imageAlignment={cellSettings.alignment}
            width={cellSettings.width}
            rowHeight={rowHeight}
            isOdd={isOdd}
            redirectUrl={columnKey === "Main picture" ? undefined : product.url}
          />
        ),
        isEmpty: false,
      };

    case SrpB2bColumnType.TEXT:
      return {
        component: (isOdd) => (
          <TableDataTextCell
            prefix={{
              label: cellSettings.prefixText,
              textColor: cellSettings.prefixColor,
              textStyle: cellSettings.prefixTextStyle,
              show: cellSettings.prefix,
            }}
            suffix={{
              label: cellSettings.suffixText,
              textColor: cellSettings.suffixColor,
              textStyle: cellSettings.suffixTextStyle,
              show: cellSettings.suffix,
            }}
            tableCellStyleId={cellSettings.dataStyle}
            spaceBetweenPrefixSuffix={cellSettings.spaceBetweenPrefixSuffix}
            isOdd={isOdd}
          >
            {shouldRenderLink(columnKey) ? (
              <LinkWrapper linkStyle={linkStyle} href={product.url}>
                {valueFromProduct}
              </LinkWrapper>
            ) : (
              valueFromProduct
            )}
          </TableDataTextCell>
        ),
        isEmpty: false,
      };
  }
};

const BoxSearchResultsB2B: React.FC<IBoxSearchResultsB2BProps> = (props) => {
  const contentSettings = BoxSearchResultsB2BContentSettingsScheme.parse(
    props.contentSettings
  );
  const display = BoxSearchResultsB2BDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const router = useRouter();

  const { productsVm, page, pageSize, totalSize, setPage, setPageSize } =
    useBoxSearchResultPaginationV2(
      display.pagination.afterRows,
      contentSettings.contentType.PRODUCT_CATALOG ?? []
    );

  const getValueFromProduct = useGetProductValueByKey();
  const [unitOfMeasure, setUnitOfMeasure] =
    useState<{ [sku: string]: string }>();

  return (
    <Table
      columns={contentSettings.columnsOrder.map((e) => ({
        key: e,
        name: contentSettings.tableAttributes[e].translationText,
        sortable: display.sorting.show && isSortableColumn(e),
        width: contentSettings.tableAttributes[e].width,
        headerCellStyleId: contentSettings.tableAttributes[e].headerStyle,
      }))}
      rows={productsVm.map((row, rowIndex) =>
        contentSettings.columnsOrder.reduce<TableProps["rows"]["0"]>(
          (acc, key) => ({
            ...acc,
            [key]: columnTypeComponents({
              cellSettings: contentSettings.tableAttributes[key],
              columnKey: key,
              product: row,
              valueFromProduct: getValueFromProduct(row, key),
              linkStyle: props.settings.product_page_link_style,
              unitOfMeasure: {
                unitOfMeasure:
                  unitOfMeasure?.[row.sku] ?? row.mainUnitOfMeasurement,
                setUnitOfMeasure: (uom) =>
                  setUnitOfMeasure({
                    ...unitOfMeasure,
                    [row.sku]: uom,
                  }),
              },
            }),
          }),

          {}
        )
      )}
      onSort={(key, direction) => {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              sort: `${
                direction === "descending" ? "-" : ""
              }${getSortingFromColumnKey(key)}`,
            },
          },
          undefined,
          { shallow: true }
        );
      }}
      tableSettings={{
        table: display,
        pagination: display.pagination,
        sorting: display.sorting,
      }}
      pagination={
        display.pagination.show
          ? {
              page: page,
              pageSize: pageSize,
              onPageChange: ({ page, pageSize }) => {
                page !== undefined && setPage(page);
                pageSize !== undefined && setPageSize(pageSize);
              },
              showOnPageLabel: contentSettings.paginationLabel,
              fromText: "z",
              initialPageSize: display.pagination.afterRows,
              total: totalSize,
            }
          : undefined
      }
    />
  );
};

export default BoxSearchResultsB2B;
