import styled from "styled-components";
import composeCss from "../../composeCss";
import { TITLE_CONTENT_SETTING_PATTERN } from "../../settingsPatterns.utils";
import {
  IBoxVideoContent,
  IBoxVideoContentSettings,
  IBoxVideoDisplaySettings,
  IBoxVideoSettings,
  RatioOption,
} from "./StyledVideo.types";

export const BOX_VIDEO_DISPLAY_PATTERN = (): IBoxVideoDisplaySettings => ({
  padding: {
    top: 0,
    bottom: 0,
  },
  ratio: RatioOption.SIXTEEN_TO_NINE,
  size: { isProcents: true, procents: 100, pixels: 200 },
});

export const BOX_VIDEO_CONTENT_SETTINGS_PATTERN =
  (): IBoxVideoContentSettings => ({
    title: TITLE_CONTENT_SETTING_PATTERN(),
    video: {
      autoPlay: true,
      showVideoControls: true,
      loopVideo: true,
    },
  });

export const BOX_VIDEO_CONTENT_PATTERN = (): IBoxVideoContent => ({
  videoTitle: "",
  coverImage: "",
  videoURL: "",
  seoDescription: "",
});

export const BOX_VIDEO_PATTERN = () => ({
  ...BOX_VIDEO_DISPLAY_PATTERN(),
  ...BOX_VIDEO_CONTENT_SETTINGS_PATTERN(),
});

export const ratioToNumber = (val: string) => {
  const numbers = val.split("/");
  return +numbers[0] / +numbers[1];
};

export const generateBoxVideoStyles = ({
  $settings,
}: {
  $settings: IBoxVideoSettings["$settings"];
  theme: IBoxVideoSettings["theme"];
}) => {
  const ratio = composeCss.ratioStyle($settings.ratio);
  const cssStyles = `
    padding-top: ${$settings.padding.top}px;
    padding-bottom: ${$settings.padding.bottom}px;
    aspect-ratio: ${ratio};
    margin: auto;
    max-width: 100%;
    width: ${
      $settings.size.isProcents
        ? `${$settings.size.procents}%`
        : `${$settings.size.pixels * ratioToNumber(ratio)}px`
    };      

    .video-container {
      &__video-icon {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #eeeeee;
      }

      &__title {
        margin-top: 30px;
      }
    }
  `;

  return cssStyles;
};

export const StyledBoxVideo = styled.div<IBoxVideoSettings>((props) =>
  generateBoxVideoStyles(props)
);
