import React from "react";
import { useTheme } from "styled-components";
import * as cartAPI from "@ecp-redux/api/cart";
import {
  ChannelType,
  IDeliveryChannel,
  PaymentType,
} from "@ecp-redux/dto/cart.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../../../../helpers/helpers";
import { Radio } from "../../../../../../shared/components/Radio/Radio";
import StyledText from "../../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../../structure/Contexts/MessagesSettingsContext";
import { displayDeliveryDateRange } from "../../../../cart.methods";
import { StyledDeliveryElement } from "../../../BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../../BoxCartStepTwo.types";

interface DeliveryElementProps {
  deliveryChannel: IDeliveryChannel;
  onClick: () => void;
  paymentType: PaymentType;
}

type DeliveryImage = `delivery_image_${ChannelType}`;

const DeliveryElement: React.FC<DeliveryElementProps> = ({
  deliveryChannel,
  onClick,
  paymentType,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();
  const theme = useTheme() as IThemeState;
  const currencyShort = theme.advanceSettings.messages.currencyShort;

  const [changeDeliveryChannel] =
    cartAPI.usePostChangeDeliveryChannelMutation();

  const { channelName, price, channelType } = deliveryChannel;

  const image: DeliveryImage = `delivery_image_${channelType}`;

  const handleSelectDeliveryChannel = () => {
    if (!deliveryChannel.selected) {
      changeDeliveryChannel({
        deliveryChannelType: deliveryChannel.channelType,
      }).then(onClick);
    } else {
      onClick();
    }
  };

  return (
    <StyledDeliveryElement
      className="delivery-channel-element"
      data-testid="deliveryElement"
      deliveryChannelDisabled={!deliveryChannel.enabled}
    >
      <Radio
        onClick={handleSelectDeliveryChannel}
        checked={deliveryChannel.selected}
      >
        <div className="delivery-channel-element__icon">
          <img src={settings[image]} alt={channelName} />
        </div>

        <div>
          <StyledText
            $settings={{
              font: settings.basket_step_2_3_typography_paragraph_4,
              text: { color: settings.basket_step_all_color_main },
            }}
          >
            <p>{channelName}</p>
          </StyledText>
          <StyledText
            className="delivery-channel-element__info-container"
            $settings={{
              font: settings.basket_step_2_typography_paragraph_3,
              text: { color: settings.basket_step_all_color_main },
            }}
          >
            <p className="delivery-channel-element__info-container__price">
              {formatPrice(price, currencyShort)}
            </p>
            <p data-testid="realizationDays">
              {`${displayDeliveryDateRange(deliveryChannel, messages)}`}
            </p>
          </StyledText>
        </div>
      </Radio>
    </StyledDeliveryElement>
  );
};

export default DeliveryElement;
