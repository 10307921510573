import styled, { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../.../../../../helpers/helpers";
import ProgressBar from "../../../../shared/components/ProgressBar/ProgressBar";
import { useCartB2BContext } from "../../../../shared/components/domain/CartB2B/useCartB2BContext";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { IBoxCartB2BMessages, IBoxCartB2BSettings } from "../BoxCartB2B.types";

const StyledCreditLimitProgressBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const StyledCreditLimitProgressBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0px 2px;
`;

const CreditLimitProgressBar: React.FC = () => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const { cartData } = useCartB2BContext();

  if (cartData === undefined) return null;

  const { summary } = cartData;

  const {
    basket_data_typo,
    basket_data_color,
    basket_data_typo2,
    basket_data_color2,
    basket_summary_credit_bar_background_color,
    basket_summary_credit_bar_border_color,
    basket_summary_credit_bar_progress_background_color,
    basket_summary_credit_bar_progress_border_color,
    basket_summary_credit_bar_progress_success_background_color,
  } = settings;

  if (summary.totalCreditLimit === null) return null;

  return (
    <StyledCreditLimitProgressBarContainer>
      <StyledCreditLimitProgressBarContent>
        <StyledText
          $settings={{
            font: basket_data_typo,
            text: {
              color: basket_data_color,
            },
          }}
        >
          {messages.basket_summary_credit_bar_usage_label}
        </StyledText>
        <StyledText
          $settings={{
            font: basket_data_typo,
            text: {
              color: basket_data_color,
            },
          }}
        >
          {messages.basket_summary_credit_bar_left_label}
        </StyledText>
      </StyledCreditLimitProgressBarContent>
      <ProgressBar
        actualValue={summary.totalCreditLimit - summary.creditLimitAvailable}
        fullValue={summary.totalCreditLimit}
        fillerColor={
          summary.creditLimitAvailable === 0
            ? basket_summary_credit_bar_progress_success_background_color
            : basket_summary_credit_bar_progress_background_color
        }
        borderColor={basket_summary_credit_bar_border_color}
        fillerBorderColor={basket_summary_credit_bar_progress_border_color}
        backgroundColor={basket_summary_credit_bar_background_color}
      />
      <StyledCreditLimitProgressBarContent>
        <StyledText
          $settings={{
            font: basket_data_typo,
            text: {
              color: basket_data_color,
            },
          }}
        >
          {formatPrice(
            summary.totalCreditLimit - summary.creditLimitAvailable,
            globalMessages.currencyShort,
            false
          )}
        </StyledText>
        <StyledText
          $settings={{
            font: basket_data_typo2,
            text: {
              color: basket_data_color2,
            },
          }}
        >
          {formatPrice(
            summary.creditLimitAvailable,
            globalMessages.currencyShort,
            false
          )}
        </StyledText>
      </StyledCreditLimitProgressBarContent>
    </StyledCreditLimitProgressBarContainer>
  );
};

export default CreditLimitProgressBar;
