import { IButtonContent, IImageContent } from "./contentPatterns.types";

export const IMAGE_CONTENT_PATTERN = (): IImageContent => ({
  imageUrl: "https://dev-resizer.mysellcentive.com/1429x/https://dev-minio-ecp.nd0.pl/ecp-media-cms/14c4042k.jpg",
});

export const BUTTON_CONTENT_PATTERN = (text?: string): IButtonContent => ({
  heading: text ?? "Button",
  link: "",
});
