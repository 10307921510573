import Trans from "next-translate/Trans";

const Loading = () => {
  return (
    <div>
      <Trans i18nKey="global_keys.loading" />
    </div>
  );
};

export default Loading;
