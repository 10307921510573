import styled from "styled-components";
import { OutlineOptions } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss from "../../composeCss";
import { SelectedGoToOption } from "../../contentPatterns.types";
import {
  BUTTON_CONTENT_PATTERN,
  IMAGE_CONTENT_PATTERN,
} from "../../contentPatterns.utils";
import {
  IMAGE_CONTENT_SETTING_PATTERN,
  INNER_BOX_CONTENT_SETTING_PATTERN,
  MAIN_BUTTON_CONTENT_SETTING_PATTERN,
  SECOND_BUTTON_CONTENT_SETTING_PATTERN,
  TEXT_STYLE_SHOW_SETTING_PATTERN,
  TITLE_CONTENT_SETTING_PATTERN,
} from "../../settingsPatterns.utils";
import {
  BannerSizeOptions,
  IBoxBannerContent,
  IBoxBannerContentSettings,
  IBoxBannerDisplaySettings,
  IBoxBannerSettings,
} from "./StyledBanner.types";

export const BOX_BANNER_DISPLAY_PATTERN = (): IBoxBannerDisplaySettings => ({
  size: BannerSizeOptions.MEDIUM,
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  margin: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  border: {
    color: { id: "color$$1", custom: "#000000" },
    style: OutlineOptions.NONE,
    width: 0,
  },
  customHeight: 600,
});

export const BOX_BANNER_CONTENT_SETTINGS_PATTERN =
  (): IBoxBannerContentSettings => ({
    image: IMAGE_CONTENT_SETTING_PATTERN(),
    box: INNER_BOX_CONTENT_SETTING_PATTERN(),
    title: TITLE_CONTENT_SETTING_PATTERN(),
    description: TEXT_STYLE_SHOW_SETTING_PATTERN(),
    mainButton: MAIN_BUTTON_CONTENT_SETTING_PATTERN(),
    secondaryButton: SECOND_BUTTON_CONTENT_SETTING_PATTERN(),
  });

export const BOX_BANNER_CONTENT_PATTERN = (): IBoxBannerContent => ({
  image: IMAGE_CONTENT_PATTERN(),
  title: "Add title",
  description: "Add description",
  bannerUrl: {
    showLinkOptions: false,
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    goToRowSetting: {
      rowId: null,
    },
    goToPageSetting: {
      link: "",
      openInNewTab: true,
    },
  },
  mainButton: BUTTON_CONTENT_PATTERN(),
  secondaryButton: BUTTON_CONTENT_PATTERN(),
  seoDescription: "",
});

export const BOX_BANNER_PATTERN = () => ({
  ...BOX_BANNER_DISPLAY_PATTERN(),
  ...BOX_BANNER_CONTENT_SETTINGS_PATTERN(),
});

export const generateBoxBannerStyles = ({
  $settings,
  theme,
}: {
  $settings: IBoxBannerSettings["$settings"];
  theme: IBoxBannerSettings["theme"];
}) => {
  const cssStyles = `
    position: relative;
    display: flex;
    height: ${
      $settings.size.includes("px") ? $settings.size : `${$settings.size}px`
    };
    ${$settings.size === "CUSTOM" ? `height: ${$settings.customHeight}px` : ``};
    margin: ${composeCss.margin($settings.margin)};
    border: ${composeCss.border($settings.border, theme)};
  `;

  return cssStyles;
};

export const StyledBanner = styled.div<IBoxBannerSettings>((props) =>
  generateBoxBannerStyles(props)
);
