import { Fragment, ReactElement } from "react";
import ReactHtmlParser, { convertNodeToElement } from "react-html-parser";
import {
  TColorId,
  TLinkId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import LinkWrapper from "../global/components/LinkWrapper/LinkWrapper";

const renderChildrenInNode = (
  nodeElementChildren: Array<any>,
  index: number
): Array<ReactElement | string> =>
  nodeElementChildren.map((el: any): ReactElement | string => {
    if (el.type === "tag") {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return convertNodeToElement(el, index, () => {});
    }
    if (el.type === "text") {
      return el.data;
    }
    return <Fragment key={index} />;
  });

const htmlToReactParser = (
  html: string,
  linkStyle?: TLinkId,
  linkBasicColor?: TColorId
): ReactElement[] => {
  return ReactHtmlParser(html, {
    decodeEntities: true,
    transform: (node, index): ReactElement | void => {
      if (node.type === "tag" && node.name === "a" && node.attribs) {
        const childrenInNode =
          node.children &&
          (renderChildrenInNode(node.children, index) as JSX.Element[]);

        return (
          <LinkWrapper
            key={index}
            href={node.attribs.href}
            linkStyle={linkStyle}
            basicColor={linkBasicColor}
            openInNewTab={node.attribs.target === "_blank"}
          >
            {childrenInNode as JSX.Element[]}
          </LinkWrapper>
        );
      }
      return undefined;
    },
  });
};

export default htmlToReactParser;
