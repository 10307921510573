import React, { useCallback } from "react";
import Link from "next/link";
import styled from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ConditionalWrapper from "../../global/components/ConditionalWrapper/ConditionalWrapper";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useObserveElementDimensions } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import { checkIfLinkExists } from "../../helpers/helpers";
import { TemporaryDescendant } from "../../helpers/htmlHelpers";
import {
  getHrefFromLinkContent,
  getOpenInNewTabFromLinkContent,
} from "../../helpers/linkHelpers";
import serializeHtmlFromDescendant from "../../helpers/serializeHtmlFromDescendant";
import {
  BOX_RICH_TEXT_CONTENT_PATTERN,
  BOX_RICH_TEXT_PATTERN,
  StyledBoxRichText,
} from "../../settingsPatterns/boxes/BoxRichText/StyledRichText";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import StyledRichText from "../../settingsPatterns/elements/StyledRichText/StyledRichText";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { useStickyOffsetsContext } from "../../structure/Contexts/StickyOffsetsContext";
import { scrollIntoRowPosition } from "../BoxListOfButtons/BoxListOfButtons.methods";
import { IBoxRichTextProps } from "./BoxRichText.types";

const StyledHrefWrapper = styled.a`
  cursor: pointer;
`;

const BoxRichText: React.FC<IBoxRichTextProps> = (props) => {
  const { offsetsData } = useStickyOffsetsContext();
  const { settings } = usePrepareSettings({
    props: {
      ...props.contentSettings,
      ...props.displaySettings,
    },
    settingsPattern: BOX_RICH_TEXT_PATTERN(),
  });

  const { settings: content } = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_RICH_TEXT_CONTENT_PATTERN(),
  });
  const {
    imageInline: { imageUrl },
  } = content;

  const [imgWrapper, imageWidth] = useObserveElementDimensions();

  const hrefWrapper = (children: React.ReactNode) =>
    content.richTextUrl.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE ? (
      <Link href={getHrefFromLinkContent(content.richTextUrl)} passHref>
        <StyledHrefWrapper
          target={
            getOpenInNewTabFromLinkContent(content.richTextUrl)
              ? "_blank"
              : "_parent"
          }
        >
          {children}
        </StyledHrefWrapper>
      </Link>
    ) : (
      <StyledHrefWrapper
        href={undefined}
        onClick={() =>
          scrollIntoRowPosition(content.richTextUrl, props.path, offsetsData)
        }
      >
        {children}
      </StyledHrefWrapper>
    );

  //Here we used Descendant typing but it caused typing errors so temporarly we added TemporaryDescendant type.
  const descendantSlateTitle: TemporaryDescendant[] = JSON.parse(content.title);
  const descendantSlateDescription: TemporaryDescendant[] = JSON.parse(
    content.description
  );

  const changeProceentToFraction = (percent: string): number => {
    const percentNumber = Number(percent.replace("%", ""));
    return percentNumber / 100;
  };

  const getCalculatedWidth = useCallback(
    () =>
      imageWidth &&
      Math.round(
        imageWidth * changeProceentToFraction(settings.imageInline.width)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageWidth, settings.imageInline.width]
  );

  return (
    <StyledBoxRichText
      $settings={settings}
      $content={content}
      ref={imgWrapper}
      $imageWidth={getCalculatedWidth()}
      className="rich-text-container"
    >
      {settings.imageInline.show && imageUrl && imageWidth && (
        <div className="rich-text-container__image-container">
          <ConditionalWrapper
            wrapper={hrefWrapper}
            condition={checkIfLinkExists(content.richTextUrl)}
          >
            <ImageWrapper
              imageUrl={imageUrl}
              seoDescription={content.seoDescription}
              imageFit={true}
              imageAlignment={{
                horizontal: AlignmentHorizontalOption.CENTER,
                vertical: AlignmentVerticalOption.CENTER,
              }}
              opacity={settings.imageInline.opacity}
              width={getCalculatedWidth()}
            />
          </ConditionalWrapper>
        </div>
      )}
      <div className="rich-text-container__content-container">
        <StyledRichText
          $settings={settings.title}
          className="rich-text-container__title"
        >
          {serializeHtmlFromDescendant(descendantSlateTitle)}
        </StyledRichText>
        <StyledRichText
          $settings={settings.description}
          className="rich-text-container__description"
        >
          {serializeHtmlFromDescendant(descendantSlateDescription)}
        </StyledRichText>
      </div>
    </StyledBoxRichText>
  );
};

export default BoxRichText;
