import { useMemo } from "react";
import {
  ISummaryB2B,
  PaymentMethodOptionB2B,
} from "@ecp-redux/dto/cartB2B.types";
import { IGlobalDropdownObject } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import ThemeStyledDropdown from "../../../../../shared/styleElements/StyledDropdown/ThemeStyledDropdown";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { useCartB2BContext } from "../useCartB2BContext";

const PaymentMethod = ({ summary }: { summary: ISummaryB2B }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();
  const { setPaymentMethod } = useCartB2BContext();

  const paymentMethodOptions = useMemo(
    () =>
      summary.paymentMethodOptions
        .filter((method) => {
          if (method.portalPaymentMethod === PaymentMethodOptionB2B.CREDIT) {
            return summary.totalCreditLimit !== null;
          }
          return method;
        })
        .map((option) => ({
          id: option.portalPaymentMethod,
          label:
            option.portalPaymentMethod === PaymentMethodOptionB2B.CASH
              ? messages.basket_summary_credit_bar_dropdown_cash_on_delivery
              : messages.basket_summary_credit_bar_dropdown_credit_limit,
        })),
    [summary.paymentMethodOptions.length]
  );

  return (
    <ThemeStyledDropdown
      style={{
        id: settings.basket_summary_credit_payment_method_droplist_style,
        custom: {} as IGlobalDropdownObject,
      }}
      key={summary.paymentMethod}
      items={paymentMethodOptions}
      selectedItemId={summary.paymentMethod}
      onClickItem={(id) => {
        if (id === summary.paymentMethod) return;
        setPaymentMethod({
          contractorPaymentMethod: id as PaymentMethodOptionB2B,
        });
      }}
      className="payment-method-dropdown-toggler"
    />
  );
};

export default PaymentMethod;
