import React, { useEffect } from "react";
import { useTheme } from "styled-components";
import { GlobalTableCellReadoutValue } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import {
  IThemeState,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import ArrowDownIcon from "../../../../shared/icons/ArrowDownIcon";
import { SortingType } from "../Table.types";
import { StyledTableCell } from "../TableCell";

interface TableHeaderCellProps {
  column: {
    name: string;
    key: string;
    sortable: boolean;
    width: number;
    headerCellStyleId: GlobalTableCellReadoutValue;
  };
  tableSettings: {
    table: {
      fixedTableSize: boolean;
    };
    sorting: {
      iconColor: TColorReadoutValue;
      iconColorActive: TColorReadoutValue;
      iconSize: number;
    };
  };
  handleSort: (key: string, direction: SortingType) => void;
  sort: {
    key: string;
    direction: SortingType;
  };
}

const getSortingFromColumnKey = (columnKey: string) => {
  switch (columnKey) {
    case "netPrice":
      return "net_price";
    case "grossPrice":
      return "gross_price";
    case "Product Name": {
      return "name";
    }
    default:
      return columnKey;
  }
};

const isSortableColumn = (key: string) => {
  return key === "Product Name" || key === "grossPrice" || key === "netPrice";
};

const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  column,
  tableSettings,
  handleSort,
  sort,
}) => {
  const theme = useTheme() as IThemeState;
  const [isContentOverflown, setIsContentOverflown] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const columnNameRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current && columnNameRef.current) {
      setIsContentOverflown(
        columnNameRef.current.offsetWidth + tableSettings.sorting.iconSize <
          contentRef.current.offsetWidth
      );
    }
  }, [
    contentRef.current?.offsetWidth,
    columnNameRef.current?.offsetWidth,
    tableSettings.sorting.iconSize,
  ]);

  return (
    <td
      className="table__header__cell"
      style={
        !tableSettings.table.fixedTableSize
          ? { width: `${column.width}px` }
          : {}
      }
      onClick={
        column.sortable
          ? () => {
              handleSort(
                column.key,
                sort.direction === "ascending" ? "descending" : "ascending"
              );
            }
          : undefined
      }
    >
      <StyledTableCell
        className="table__header__cell__content"
        tableCellStyleId={column.headerCellStyleId}
        ref={contentRef}
      >
        <div ref={columnNameRef}>{column.name}</div>
        {column.sortable && isContentOverflown && (
          <div className="table__header__cell__content__sorting">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleSort(column.key, "descending");
              }}
            >
              <ArrowDownIcon
                fill={convertColorIdToHex(
                  sort.key === column.key && sort.direction === "descending"
                    ? tableSettings.sorting.iconColorActive
                    : tableSettings.sorting.iconColor,
                  theme.colorPalette
                )}
                width={tableSettings.sorting.iconSize}
                height={tableSettings.sorting.iconSize}
                className=" table__header__cell__content__sorting__asc"
              />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleSort(column.key, "ascending");
              }}
            >
              <ArrowDownIcon
                fill={convertColorIdToHex(
                  sort.key === column.key && sort.direction === "ascending"
                    ? tableSettings.sorting.iconColorActive
                    : tableSettings.sorting.iconColor,
                  theme.colorPalette
                )}
                width={tableSettings.sorting.iconSize}
                height={tableSettings.sorting.iconSize}
                className="table__header__cell__content__sorting__desc"
              />
            </button>
          </div>
        )}
      </StyledTableCell>
    </td>
  );
};

export default TableHeaderCell;
