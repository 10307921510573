import styled from "styled-components";
import {
  IThemeState,
  VerticalPosition,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss from "../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import {
  IBoxTilesSliderContentSettings,
  IBoxTilesSliderDisplaySettings,
  ITileSettings,
} from "./BoxTilesSlider.types";

export const StyledTilesSlider = styled.div<{
  $contentSettings: IBoxTilesSliderContentSettings;
  $displaySettings: IBoxTilesSliderDisplaySettings;
  theme: IThemeState;
}>(({ $contentSettings, $displaySettings, theme }) => {
  return `
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${$displaySettings.padding.top}px;

  padding-left: ${$displaySettings.padding.left}px;
  padding-right: ${$displaySettings.padding.right}px;
  gap: ${$contentSettings.spaceBetweenTitleAndTiles}px;


  .tiles-container__title {
    display: flex;
    justify-content: ${$contentSettings.headerAlignment};
    align-items: center;
  }
  
  .swiper {
    width: 100%;
    margin: 0;
    height: ${composeCss.height(
      $contentSettings.tileHeight + $displaySettings.padding.bottom
    )};
    padding-bottom: ${$displaySettings.padding.bottom}px;
  }

  .swiper-container {
    height: 100%;
    width: 100% !important;
  }

  .swiper-wrapper {
    width: 100%;
    height: 100%;
    -webkit-transform-style: preserve-3d;
  }

  .swiper__slide {
    display: flex;
    width: 100%;
    overflow: hidden;
    padding: 8px;
    -webkit-backface-visibility: hidden; 
    -webkit-transform: translate3d(0, 0, 0);
    flex-direction: column;
    background-color: ${convertColorIdToHex(
      $contentSettings.tilesBackground,
      theme.colorPalette
    )};
    border-left-width: ${$contentSettings.tileBorder.width.left}px;
    border-right-width: ${$contentSettings.tileBorder.width.right}px;
    border-top-width: ${$contentSettings.tileBorder.width.top}px;
    border-bottom-width: ${$contentSettings.tileBorder.width.bottom}px;
    border-style: ${$contentSettings.tileBorder.style};
    border-color: ${convertColorIdToHex(
      $contentSettings.tileBorder.color,
      theme.colorPalette
    )};
    border-radius: ${$contentSettings.tileBorder.radius.topLeft}px ${
    $contentSettings.tileBorder.radius.topRight
  }px ${$contentSettings.tileBorder.radius.bottomRight}px ${
    $contentSettings.tileBorder.radius.bottomLeft
  }px;
    
  
    :hover {
      border-color: ${convertColorIdToHex(
        $contentSettings.tileHoverBorder.color,
        theme.colorPalette
      )};
      background-color: ${convertColorIdToHex(
        $contentSettings.tilesBackgroundHover,
        theme.colorPalette
      )};
    }

    &__link {
      display: contents;
    }
  }

  .swiper-button-prev, .swiper-button-next {
    color: #81889c !important;
    height: 40px;
    width: 40px;
    background-color: #ffffff;
    z-index: var(--zindex-swiper-buttons);
    transition: all var(--ecp-transition);
  }

  .swiper-button-prev {
    left: 20px;
  }

  .swiper-button-next {
    right: 20px;
  }

  .swiper-button-prev:hover, .swiper-button-next:hover {
    background-color: #f0f5fe;
  }

  .swiper-button-prev:active, .swiper-button-next:active {
    background-color: #dce9fd;
  }

  .swiper-button-prev::after, .swiper-button-next::after {
    font-size: 23px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    pointer-events: auto !important;
  }

  .swiper-pagination-bullet:not(.pagination-counter) {
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    opacity: 1;
    transition: all var(--ecp-transition);
  }

  .swiper-pagination-bullet-active {
    background-color: #81889c !important;
  }
`;
});

export const StyledSwiperSlideContainer = styled.div<{
  tileSettings: ITileSettings;
}>(({ tileSettings }) => {
  return `
  display: flex;
  flex-direction: column;
  gap: ${tileSettings.spaceBetweenTitleAndImage}px;
  height: 100%;

    .swiper__slide__title {
      text-align: ${tileSettings.titleAlignment};
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .swiper__slide__image-container{
      height: 100%;
      order: ${
        tileSettings.titlePlacement === VerticalPosition.BOTTOM ? -1 : 0
      };
      overflow: hidden;

      > div {    
        height: 100%;
        ${composeCss.hoverEffect(tileSettings.image.hoverEffect)}
      }
    }

    .ecp-image {
      position: relative !important;
    }
  `;
});
