import React, { memo } from "react";
import { isEqual } from "lodash";
import useTranslation from "next-translate/useTranslation";
import styled from "styled-components";
import { Plate, PlateProvider } from "@udecode/plate";
import { MyValue } from "../../global/plate/plateTypes";
import { plugins } from "../../global/plate/plugins/plugins";
import { BOX_HTML_CONTENT_PATTERN } from "../../settingsPatterns/boxes/BoxHtml/StyledHtml";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { IBoxHtmlProps } from "./BoxHtml.types";
import { isPortalSide } from "../../helpers/helpers";

export const ListWrapper = styled.div`
  ul,
  ol {
    list-style: revert !important;
  }
`;

const BoxHtml: React.FC<IBoxHtmlProps> = (props) => {
  const { t } = useTranslation();

  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_HTML_CONTENT_PATTERN(),
  }).settings;

  const HtmlViewer: React.FC = () => {
    // this component is created every render, so PlateProvider is created every render with new value
    return (
      <ListWrapper className="html-container__list-wrapper">
        <PlateProvider<MyValue>
          plugins={plugins}
          initialValue={JSON.parse(content.html)}
          readOnly
        >
          <Plate<MyValue> />
        </PlateProvider>
      </ListWrapper>
    );
  };

  if (content.html === "")
    return (
      <div className="html-container__empty">{!isPortalSide() && t("global_keys.empty_box")}</div>
    );
  return <HtmlViewer />;
};

export default memo(BoxHtml, isEqual);
