import styled, { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertTypoIdToValues } from "../../../../settingsPatterns/settingsPatterns.methods";
import { StyledSpaceKeeper } from "../../../../shared/components/domain/Product/ProductModule/ProductModule.styled";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSummaryB2BMessages,
  IBoxSummaryB2BSettings,
} from "../BoxSummaryB2B.types";

const StyledProductListTextValue = styled.div`
  display: flex;
  align-items: center;
`;

export const TableValue: React.FC = (props) => {
  const {
    settings: { basket_success_data_typo, basket_success_data_color },
  } = useMessagesSettingsContext<
    IBoxSummaryB2BMessages,
    IBoxSummaryB2BSettings
  >();
  const theme = useTheme() as IThemeState;

  return (
    <StyledProductListTextValue className="cartB2B__product-line-item">
      <StyledSpaceKeeper className="cartB2B__product-line-item__spacekeeper"
        height={
          convertTypoIdToValues(basket_success_data_typo, theme.typography)
            .lineHeight
        }
        rows={2}
      >
        <StyledText
          $settings={{
            font: basket_success_data_typo,
            text: {
              color: basket_success_data_color,
            },
          }}
        >
          {props.children}
        </StyledText>
      </StyledSpaceKeeper>
    </StyledProductListTextValue>
  );
};
