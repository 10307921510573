import styled from "styled-components";
import { IIcon } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  TAccordionId,
  TAccordionReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { IBoxAccordionDisplaySettings } from "../../../boxes/BoxAccordion/BoxAccordion.types";
import { noEmptyValue } from "../../../helpers/helpers";
import composeCss from "../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import { convertAccordionIdToValues } from "./StyledAccordion.methods";

export const StyledAccordionContainer = styled.div<{
  accordionSettings: IBoxAccordionDisplaySettings;
}>(({ accordionSettings, theme }) => {
  const { spaceBetweenAccordions, accordionStyle } = accordionSettings;

  const accordionAlignment = convertAccordionIdToValues(
    accordionStyle,
    theme.globalObjects.accordions
  );

  return `
    display: flex;
    flex-direction: column;
    gap: ${spaceBetweenAccordions}px;
    text-align: -webkit-${accordionAlignment.accordionAlignment};
  `;
});

const composeIconAndHeaderTextStyles = (icon: IIcon) =>
  noEmptyValue(icon.iconUrl) &&
  `
  & {
    display: flex;
    align-items: center;
  }

  & > div {
    flex-grow: 1;
    word-break: break-all;
    ${icon.position === "LEFT" ? "text-align: right;" : ""};
    ${icon.position === "RIGHT" ? "text-align: left;" : ""};

  }

  & > i,
  & > i {
    order: ${icon.position === "LEFT" ? 0 : 1};
    flex-grow: 0;
  }

  & > i:before,
  & > i:before {
    display: block;
    content: "";
    background: url(${icon.iconUrl});
    background-repeat: no-repeat;
    background-size: 100%;
    height: ${icon.size}px;
    width: ${icon.size}px;
  }
  `;

export const StyledAccordionItemHeader = styled.button<{
  accordionStyle: TAccordionReadoutValue | TAccordionId;
  open: boolean;
}>(({ accordionStyle, open, theme }) => {
  const {
    accordionPadding,
    accordionWidth,
    accordionBorder,
    accordionBackground,
    accordionTypography,
    accordionText,
    openIcon,
    closeIcon,
    accordionHoverBorder,
    accordionActiveBorder,
    accordionDisabledBorder,
  } = convertAccordionIdToValues(
    accordionStyle,
    theme.globalObjects.accordions
  );

  return `
        cursor: pointer;
        padding: ${composeCss.padding(accordionPadding)};
        width: ${accordionWidth}%;
        ${composeCss.borderWidth(accordionBorder.width)};
        ${composeCss.borderStyle(accordionBorder.style)};
        ${composeCss.borderRadius(accordionBorder.radius)}px;
        ${composeCss.borderColor(
          open ? accordionActiveBorder.color : accordionBorder.color,
          theme
        )};
        background-color: ${convertColorIdToHex(
          open ? accordionBackground.activeColor : accordionBackground.color,
          theme.colorPalette
        )};
        ${composeCss.font(accordionTypography, theme.typography)}
        color: ${convertColorIdToHex(
          open ? accordionText.activeColor : accordionText.color,
          theme.colorPalette
        )};
        overflow-wrap: break-word;
        i > {
          color: ${convertColorIdToHex(
            accordionText.color,
            theme.colorPalette
          )};
        }
        ${composeIconAndHeaderTextStyles(open ? openIcon : closeIcon)};
        &:hover {
          background-color: ${convertColorIdToHex(
            accordionBackground.hoverColor,
            theme.colorPalette
          )};
          color: ${convertColorIdToHex(
            accordionText.hoverColor,
            theme.colorPalette
          )};
          ${composeCss.borderColor(accordionHoverBorder.color, theme)};
        }
        &:disabled {
          background-color: ${convertColorIdToHex(
            accordionBackground.disabledColor,
            theme.colorPalette
          )};
          color: ${convertColorIdToHex(
            accordionText.disabledColor,
            theme.colorPalette
          )};
          ${composeCss.borderColor(accordionDisabledBorder.color, theme)};
        }

        .accordion_label {
          word-break: break-word;
        }
    `;
});

const StyledAccordionItemContent = styled.div<{
  accordionStyle: TAccordionReadoutValue | TAccordionId;
  show: boolean;
  spaceBetweenAccordionAndContent: number;
  maxHeight: number;
}>(
  ({
    accordionStyle,
    show,
    spaceBetweenAccordionAndContent,
    maxHeight,
    theme,
  }) => {
    const accordionValue = convertAccordionIdToValues(
      accordionStyle,
      theme.globalObjects.accordions
    );
    return `
    display: ${show ? "block" : "none"};
    margin-top: ${spaceBetweenAccordionAndContent}px;
    width: ${accordionValue.accordionWidth}%;
    overflow: scroll;
    max-height: ${maxHeight}px;
    overflow-y: auto;
    scrollbar-width: none;
    `;
  }
);

export const AccordionItem: React.FC<{
  forceOpen: boolean;
  labelText: string;
  accordionItemStyle: TAccordionReadoutValue | TAccordionId;
  accordionSettings: IBoxAccordionDisplaySettings;
  handleOpenCallback: () => void;
  disabled: boolean;
}> = (props) => {
  const {
    forceOpen,
    labelText,
    accordionItemStyle,
    accordionSettings,
    children,
    handleOpenCallback,
    disabled,
  } = props;

  return (
    <div className="accordion-container" data-testid="accordion-container">
      <StyledAccordionItemHeader
        accordionStyle={accordionItemStyle}
        open={forceOpen}
        onClick={handleOpenCallback}
        data-testid="accordion-header"
        disabled={disabled}
      >
        <i />
        <div className="accordion_label">{labelText}</div>
      </StyledAccordionItemHeader>
      {forceOpen && (
        <StyledAccordionItemContent
          accordionStyle={accordionItemStyle}
          show={forceOpen}
          spaceBetweenAccordionAndContent={
            accordionSettings.spaceBetweenAccordionAndContent
          }
          maxHeight={accordionSettings.maxHeight}
          data-testid="accordion-content"
        >
          {children}
        </StyledAccordionItemContent>
      )}
    </div>
  );
};
