import styled from "styled-components";
import { swiperPaginationArrow } from "../../../../styles/swiper.styles";

export const StyledPhotoGalleryModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--ecp-page-overlay);
  z-index: var(--zindex-freeze-overlay-sidebar);

  .photo-gallery-modal-container__photo-gallery-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-height: 905px;
    height: 100%;
    background-color: #ffffff;
    padding: 20px 30px;
  }

  .photo-gallery-modal-container__photo-gallery-modal__close {
    display: flex;
    justify-content: flex-end;
  }

  .photo-gallery-modal-container__photo-gallery-modal__image {
    .swiper-slide {
      height: 100%;
      width: 100%;

      .photo-gallery-modal-container__photo-gallery-modal__image--image-container {
        width: 610px;
        height: 640px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .photo-gallery-modal-container__photo-gallery-modal__gallery {
    display: flex;
    position: relative;

    .swiper {
      position: initial;
      width: 665px;
    }

    .swiper-slide {
      cursor: pointer;
      height: 100px;
      width: 113px;

      .photo-gallery-modal-container__photo-gallery-modal__gallery--image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 113px;
        border: 1px solid #eeeeee;
        transition: border-color var(--ecp-transition);

        &:hover {
          border-color: #cccccc;
        }
      }
    }

    .swiper-slide-thumb-active {
      .photo-gallery-modal__gallery--image-container {
        border: 1px solid #000000;

        &:hover {
          border-color: #000000;
        }
      }
    }
  }

  .swiper-button-next {
    right: 0 !important;
  }

  .swiper-button-prev {
    left: 0 !important;
  }

  ${swiperPaginationArrow}
`;
