import { useMemo, useState } from "react";
import classNames from "classnames";
import { getImageNameFromUrl } from "../../../helpers/helpers";
import {
  getHrefFromLinkContent,
  getOpenInNewTabFromLinkContent,
} from "../../../helpers/linkHelpers";
import StyledImage from "../../../settingsPatterns/elements/StyledImage/StyledImage";
import LinkWrapper from "../LinkWrapper/LinkWrapper";
import { checkImageType, imgResizer } from "./ImageWrapper.methods";
import { StyledSimplyImage } from "./ImageWrapper.styled";
import { IImageWrapperProps, ImgType } from "./ImageWrapper.types";
import Image from "./MyNextImage";

const ImageWrapper: React.FC<IImageWrapperProps> = (props) => {
  const [isNextImageLoaded, setIsNextImageLoaded] = useState(false);
  const {
    imageUrl,
    seoDescription,
    imageFit,
    imageAlignment,
    opacity,
    parallax,
    width,
    height,
    link,
    imgTagAsBackground,
    className,
  } = props;

  const memoizedSeoDesc = useMemo(
    () =>
      seoDescription ? seoDescription : getImageNameFromUrl(imageUrl ?? ""),
    [seoDescription, imageUrl]
  );

  if (!imageUrl) return null;
  const imgType: ImgType = checkImageType({ ...props });

  switch (imgType) {
    case "next-resize": {
      const linkURL = link && getHrefFromLinkContent(link);
      return (
        <LinkWrapper
          href={linkURL}
          openInNewTab={link && getOpenInNewTabFromLinkContent(link)}
          className={className}
        >
          <Image
            src={imgResizer(imageUrl, width ?? 0)}
            alt={memoizedSeoDesc}
            layout={height ? "responsive" : "fill"}
            objectFit={imageFit ? "cover" : "contain"}
            objectPosition={`${imageAlignment.horizontal} ${imageAlignment.vertical}`}
            onLoadingComplete={() => {
              //setting to avoid flickering on image load
              setIsNextImageLoaded(true);
            }}
            style={{
              ...(opacity !== undefined && { opacity: opacity / 100 ?? 1 }),
              visibility: !isNextImageLoaded ? "hidden" : "visible",
            }}
            width={width}
            loading="lazy"
            height={height}
            data-testid="next-image"
            className={className}
          />
        </LinkWrapper>
      );
    }
    case "ecp-resize": {
      const linkURL = link && getHrefFromLinkContent(link);
      return (
        <LinkWrapper
          href={linkURL}
          openInNewTab={link && getOpenInNewTabFromLinkContent(link)}
          className={className}
        >
          <StyledSimplyImage
            src={imgResizer(imageUrl, width ?? 0)}
            alt={memoizedSeoDesc}
            opacity={opacity ?? 100}
            className={classNames("ecp-image", className)}
            data-testid="ecp-resize-image"
            objectPosition={`${imageAlignment.horizontal} ${imageAlignment.vertical}`}
            objectFit={imageFit ? "contain" : "cover"}
            backgroundMode={imgTagAsBackground}
          />
        </LinkWrapper>
      );
    }
    case "backgorund-img": {
      const linkURL = link && getHrefFromLinkContent(link);

      return (
        <StyledImage
          className={className}
          {...(link &&
            linkURL !== "" && {
              href: linkURL,
              target: getOpenInNewTabFromLinkContent(link)
                ? "_blank"
                : "_parent",
            })}
          role="img"
          data-testid="background-image"
          aria-label={memoizedSeoDesc}
          alt={memoizedSeoDesc}
          $settings={{
            backgroundUrl: imageUrl,
            fit: imageFit,
            alignment: imageAlignment,
            parallax: !!parallax,
            opacity: opacity,
            width: width,
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

export default ImageWrapper;
