import { Dispatch, SetStateAction } from "react";
import { IElementTextContent } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { Radio } from "../../../../shared/components/Radio/Radio";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../../BoxSearchResults.types";
import { SortOption } from "../Sort.types";
import { useSearchResultsLogic } from "../useSearchResultsLogic";
import {
  StyledSearchResultsLabel,
  StyledSearchResultsParameters,
} from "./ExtendedTopNavigation.styled";

interface ExtendedSort {
  label: string;
  sortSettings: IElementTextContent;
  temporarySortingOption: SortOption;
  chooseTemporarySortingOption: Dispatch<SetStateAction<SortOption>>;
}

const ExtendedSort: React.FC<ExtendedSort> = ({
  label,
  sortSettings,
  temporarySortingOption,
  chooseTemporarySortingOption,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchResultsMessages,
    IBoxSearchResultsSettings
  >();

  const { findSortElementMessageKey, sortOptions } = useSearchResultsLogic();

  if (!sortSettings.show) {
    return null;
  }

  return (
    <div className="navigation__left-area__extended-sort">
      <StyledSearchResultsLabel
        $settings={sortSettings}
        backgroundColor={settings.srp_mobile_sorting_parameter_background_color}
      >
        {label}
      </StyledSearchResultsLabel>
      <StyledSearchResultsParameters $settings={sortSettings} renderAs="ul">
        {sortOptions.map((el) => (
          <li key={messages[findSortElementMessageKey(el)]}>
            <Radio
              onClick={() => chooseTemporarySortingOption(el)}
              checked={
                temporarySortingOption.type === el.type &&
                temporarySortingOption.direction === el.direction
              }
            >
              {messages[findSortElementMessageKey(el)]}
            </Radio>
          </li>
        ))}
      </StyledSearchResultsParameters>
    </div>
  );
};

export default ExtendedSort;
