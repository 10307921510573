import {
  ELEMENT_MEDIA_EMBED,
  ELEMENT_PARAGRAPH,
  MediaEmbedElement,
  StyledElement,
  createPlateUI,
  withProps,
} from "@udecode/plate";
import CustomHtml from "./CustomHtml";
import { ELEMENT_HTML } from "./plugins/htmlPlugin";

export const plateUI = createPlateUI({
  [ELEMENT_MEDIA_EMBED]: withProps(MediaEmbedElement, {
    nodeProps: {
      twitterOptions: {
        theme: "dark",
      },
    },
  }),
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    // as: 'p',
    styles: {
      root: {
        margin: 0,
        padding: "4px 0",
      },
    },
    prefixClassNames: "p",
  }),
  [ELEMENT_HTML]: CustomHtml,
});
