import React, { useState } from "react";
import { addDays, format, getDate, isEqual, startOfDay } from "date-fns";
import { IOrderB2B } from "@ecp-redux/dto/cartB2B.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import CloseIcon from "../../../../../shared/icons/CloseIcon";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { ReactComponent as ArrowRightIcon } from "../../../../icons/arrowRight.svg";
import StyledText from "../../../../styleElements/StyledText/StyledText";
import {
  CheckboxButton,
  CheckboxButtonLabel,
} from "../../../CheckboxesList/CheckboxesList.styled";
import { useCartB2BContext } from "../useCartB2BContext";
import {
  checkIfTwoWeeks,
  getMessageForDay,
  getMessageForMonth,
  getWeekWithDeliveryDate,
} from "./DeliveryCalendarB2B.methods";
import {
  StyledDay,
  StyledDeliveryCalendar,
} from "./DeliveryCalendarB2B.styled";

interface IDeliveryCalendarB2BProps {
  order: IOrderB2B;
  handleCloseCalendar: () => void;
  deliveryCalendarOpen: boolean;
}

const DeliveryCalendarB2B: React.FC<IDeliveryCalendarB2BProps> = ({
  order: { deliveryDate, deliveryCalendar, productType },
  handleCloseCalendar,
  deliveryCalendarOpen,
}) => {
  const isMobile = useIsMobilePortal();
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();
  const { setDeliveryDate } = useCartB2BContext();

  const [currentWeek, setCurrentWeek] = useState(
    getWeekWithDeliveryDate(deliveryDate)
  );

  return (
    <StyledDeliveryCalendar
      className="delivery-container"
      isMobile={isMobile}
      deliveryCalendarOpen={deliveryCalendarOpen}
      backgroundColor={settings.basket_orders_list_background_color}
      disableRightArrow={
        !checkIfTwoWeeks(deliveryCalendar) || currentWeek === 1
      }
      disableLeftArrow={!checkIfTwoWeeks(deliveryCalendar) || currentWeek === 0}
    >
      <StyledText
        className="delivery-container__header"
        $settings={{
          font: settings.basket_text_typo,
          text: { color: settings.basket_text_color },
        }}
      >
        <>
          {messages.basket_single_order_delivery_term_label}
          {isMobile && (
            <CloseIcon
              onClick={handleCloseCalendar}
              fill="black"
              width={17}
              height={17}
            />
          )}
        </>
      </StyledText>
      <div className="delivery-container__swiper">
        <div className="delivery-container__swiper__days-container">
          <ArrowRightIcon
            width={20}
            height={15}
            className="delivery-container__swiper__left-arrow"
            onClick={() => setCurrentWeek((prevVal) => prevVal - 1)}
          />
          {[...Array(7)].map((_, index) => {
            const day = addDays(
              startOfDay(new Date()),
              index + currentWeek * 7
            );
            const isActive = deliveryDate ? isEqual(deliveryDate, day) : false;
            const isInactive =
              !isActive &&
              deliveryCalendar.find(
                ({ deliveryDate, enabled }) =>
                  enabled && isEqual(deliveryDate, day)
              ) !== undefined;

            return (
              <StyledDay
                key={JSON.stringify(day)}
                isActive={isActive}
                isInactive={!isActive && isInactive}
                isDisabled={!isActive && !isInactive}
                settings={settings}
                onClick={() => {
                  if (!isActive && isInactive) {
                    setDeliveryDate({
                      productType: productType,
                      deliveryDate: format(day, "yyyy-MM-dd"),
                    });
                  }
                }}
              >
                <StyledText
                  $settings={{
                    font: isActive
                      ? settings.basket_label_typo
                      : settings.basket_text_typo,
                    text: {
                      color: isActive
                        ? settings.basket_label_color
                        : settings.basket_text_color,
                    },
                  }}
                >
                  {getMessageForDay(day, messages)}
                </StyledText>

                {isActive && (
                  <div className="chosen-day-container">
                    <CheckboxButton
                      type="checkbox"
                      checked
                      onChange={() => null}
                    />
                    <CheckboxButtonLabel className="chosen-day-container__checkbox" />
                  </div>
                )}
                <StyledText
                  className="delivery-container__swiper__day-month"
                  $settings={{
                    font: isActive
                      ? settings.basket_label_typo
                      : settings.basket_text_typo,
                    text: {
                      color: isActive
                        ? settings.basket_label_color
                        : settings.basket_text_color,
                    },
                  }}
                >
                  {`${getDate(day)} ${getMessageForMonth(day, messages)}`}
                </StyledText>
              </StyledDay>
            );
          })}
          <ArrowRightIcon
            width={20}
            height={15}
            className="delivery-container__swiper__right-arrow"
            onClick={() => setCurrentWeek((prevVal) => prevVal + 1)}
          />
        </div>
      </div>
    </StyledDeliveryCalendar>
  );
};
export default DeliveryCalendarB2B;
