import { useTheme } from "styled-components";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../helpers/helpers";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  StyledOrderDetailsWrapper,
  StyledOrderGapWrapper,
} from "../BoxMyAccountMyOrdersDetails.styled";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../BoxMyAccountMyOrdersDetails.types";
import ColumnInfoText from "../ColumnInfoText/ColumnInfoText";

interface IMyOrderDetailsTotalCostsProps {
  orderResponse: IOrderDetailsResponse;
}

const MyOrderDetailsTotalCosts: React.FC<IMyOrderDetailsTotalCostsProps> = ({
  orderResponse,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersDetailsMessages,
    IBoxMyAccountMyOrdersDetailsSettings
  >();

  const { order } = orderResponse;

  return (
    <StyledOrderDetailsWrapper
      backgroundColorId={settings.customer_data_order_details_background_color}
      className="my-order-details-total-costs__container"
    >
      <div className="my-order-details-total-costs__container__main">
        <StyledOrderGapWrapper
          className="my-order-details-total-costs__container__gap-container"
          elementsGap={
            settings.customer_data_order_details_total_costs_elements_gap
          }
        >
          <StyledText
            className="my-order-details-total-costs__container__gap-container__order-total-cost"
            $settings={{
              font: settings.customer_data_order_details_order_total_cost_label_typo,
              text: {
                color:
                  settings.customer_data_order_details_order_total_cost_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_order_total_cost
            )}
          >
            {messages.customer_data_order_details_order_total_cost}
          </StyledText>
          <div className="my-order-details-total-costs__container__gap-container__column-info-text">
            <ColumnInfoText
              fontLabelTypo={
                settings.customer_data_order_details_order_products_value_label_typo
              }
              textLabelColor={
                settings.customer_data_order_details_order_products_value_label_color
              }
              messageLabel={
                messages.customer_data_order_details_order_products_value
              }
              fontValueTypo={
                settings.customer_data_order_details_order_products_value_value_typo
              }
              textValueColor={
                settings.customer_data_order_details_order_products_value_value_color
              }
              value={formatPrice(
                order.orderValue - order.orderPayment.deliveryCostsAmount,
                globalMessages["currencyShort"]
              )}
            />
            <ColumnInfoText
              fontLabelTypo={
                settings.customer_data_order_details_order_deliver_cost_label_typo
              }
              textLabelColor={
                settings.customer_data_order_details_order_deliver_cost_label_color
              }
              messageLabel={
                messages.customer_data_order_details_order_deliver_cost
              }
              fontValueTypo={
                settings.customer_data_order_details_order_deliver_cost_value_typo
              }
              textValueColor={
                settings.customer_data_order_details_order_deliver_cost_value_color
              }
              value={formatPrice(
                order.orderPayment.deliveryCostsAmount,
                globalMessages["currencyShort"]
              )}
            />
          </div>
          <ColumnInfoText
            fontLabelTypo={
              settings.customer_data_order_details_order_value_label_typo
            }
            textLabelColor={
              settings.customer_data_order_details_order_value_label_color
            }
            messageLabel={messages.customer_data_order_details_order_value}
            fontValueTypo={
              settings.customer_data_order_details_order_value_value_typo
            }
            textValueColor={
              settings.customer_data_order_details_order_value_value_color
            }
            value={formatPrice(
              order.orderValue,
              globalMessages["currencyShort"]
            )}
          />
        </StyledOrderGapWrapper>
      </div>
    </StyledOrderDetailsWrapper>
  );
};

export default MyOrderDetailsTotalCosts;
