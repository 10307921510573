import React from "react";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import {
  BOX_SEARCH_FILTERS_CONTENT_PATTERN,
  BOX_SEARCH_FILTERS_CONTENT_SETTINGS_PATTERN,
  BOX_SEARCH_FILTERS_DISPLAY_SETTINGS_PATTERN,
} from "./BoxSearchFilters.patterns";
import { BoxSearchFiltersProps } from "./BoxSearchFilters.types";
import SearchFiltersDesktop from "./SearchFiltersDesktop";
import SearchFiltersMobile from "./SearchFiltersMobile";

const BoxSearchFilters: React.FC<BoxSearchFiltersProps> = (props) => {
  const displaySettings = usePrepareSettings({
    props: {
      ...props.displaySettings,
    },
    settingsPattern: BOX_SEARCH_FILTERS_DISPLAY_SETTINGS_PATTERN(),
    options: {
      includeTheme: true,
      themePath: "advanceSettings.settings",
      themeWrapperPath: "image",
    },
  }).settings;

  const contentSettings = usePrepareSettings({
    props: { ...props.contentSettings },
    settingsPattern: BOX_SEARCH_FILTERS_CONTENT_SETTINGS_PATTERN(),
  }).settings;

  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_SEARCH_FILTERS_CONTENT_PATTERN(),
  }).settings;

  const isMobile = useIsMobilePortal();

  return (
    <>
      {!isMobile && (
        <SearchFiltersDesktop
          contentSettings={contentSettings}
          displaySettings={displaySettings}
          content={content}
        />
      )}
      {isMobile && (
        <SearchFiltersMobile
          contentSettings={contentSettings}
          displaySettings={displaySettings}
          content={content}
        />
      )}
    </>
  );
};

export default BoxSearchFilters;
