import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBoxBaseSettings,
  IElementLink,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  TColorReadoutValue,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { ILinkContent } from "../../contentPatterns.types";

export enum BreadcrumbTypes {
  MANUAL = "MANUAL",
  AUTO = "AUTO",
}

export enum BreadcrumbSeparatorType {
  ARROW = "ARROW",
  BACKSLASH = "BACKSLASH",
}

export interface IBreadcrumb {
  id: string;
  heading: string;
  link: ILinkContent;
}

export type IBoxBreadcrumbData = GenericBoxData<
  IBoxBreadcrumbContent,
  IBoxBreadcrumbContentSettings,
  IBoxBreadcrumbDisplaySettings,
  Record<string, never>,
  Record<string, never>
>;

export interface IBoxBreadcrumbDisplaySettings {
  breadcrumbs: IElementLink;
  separator: {
    type: BreadcrumbSeparatorType;
    text: TTypoReadoutValue;
    color: TColorReadoutValue;
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBoxBreadcrumbContentSettings {}

export interface IBoxBreadcrumbContent {
  type: BreadcrumbTypes;
  breadcrumbs: IBreadcrumb[];
  homeBreadcrumb: {
    heading: string;
    link: ILinkContent;
  };
  searchResultBreadcrumb: string;
}

export type IBoxBreadcrumbSettings =
  IBoxBaseSettings<IBoxBreadcrumbDisplaySettings>;
