import styled, { css } from "styled-components";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../StyledText/StyledText";

export const StyledSearchUtilsLabel = styled(StyledText)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  white-space: nowrap;
  margin-right: 8px;
`;

export const StyledToggler = styled(StyledText)<{
  isOpen: boolean;
}>(({ isOpen, theme, $settings }) => {
  return css`
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eeeeee;
    padding: 8px 27px;
    cursor: pointer;
    svg {
      margin-left: 7px;
      ${isOpen ? `transform: rotate(180deg);` : ""}
      .arrowDownPath {
        stroke: ${convertColorIdToHex(
          $settings.text.color,
          theme.colorPalette
        )};
      }
    }
  `;
});

export const StyledTogglerLabel = styled.span`
  width: 144px;
`;

export const StyledDropdownList = styled(StyledText)`
  border: 1px solid #eeeeee;
  padding: 8px 27px;
  background: white;
`;

export const DropdownListEl = styled.li`
  cursor: pointer;
  padding: 5px 0;
`;
