import React, { useState } from "react";
import { ChannelType, IDeliveryChannel } from "@ecp-redux/dto/cart.types";
import { AddressType, isPostalType } from "@ecp-redux/dto/cartAddresses.type";
import { isNotEmptyMessage } from "../../../../../../../helpers/isNotEmptyMessage";
import { ReactComponent as PenIcon } from "../../../../../../../shared/icons/pen.svg";
import { StyledBackground } from "../../../../../../../shared/styleElements/StyledBackground/StyledBackground.styled";
import StyledText from "../../../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../../../structure/Contexts/MessagesSettingsContext";
import ChangeAddress from "../../../../../shared/ChangeBillingPostalAddress";
import { StyledSelectedAddress } from "../../../../BoxCartStepTwo.styled";
import {
  IBoxCartStepTwoMessages,
  IBoxCartStepTwoSettings,
} from "../../../../BoxCartStepTwo.types";
import AddressInfo from "./elements/AddressInfo";

const channelTypeToAddresType = (deliveryType: ChannelType): AddressType => {
  switch (deliveryType) {
    case ChannelType.DPD:
      return AddressType.POSTAL;
    case ChannelType.INPOST:
      return AddressType.PACKSTATION;
    case ChannelType.PICK_UP_AT_POINT:
      return AddressType.PICK_UP_POINT;
    case ChannelType.POCZTEX:
      return AddressType.POSTAL;
    case ChannelType.STORE:
      return AddressType.STORE;
    case ChannelType.UPS:
      return AddressType.POSTAL;
    default:
      return AddressType.POSTAL;
  }
};

interface SelectedAddressProps {
  deliveryChannel: IDeliveryChannel;
  removeSelectedDeliveryChannel: () => void;
  deliveryChannels: IDeliveryChannel[];
}

const SelectedAddress: React.FC<SelectedAddressProps> = ({
  deliveryChannel,
  removeSelectedDeliveryChannel,
  deliveryChannels,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepTwoMessages,
    IBoxCartStepTwoSettings
  >();

  const [editAddress, setEditAddress] = useState(false);
  const selectedAddress = deliveryChannel.selectedAddress;

  return (
    <StyledSelectedAddress className="selected-address">
      <StyledBackground
        className="selected-address__background-delivery-form"
        backgroundColor={
          settings.basket_step_all_background_adress_payment_color
        }
      >
        <StyledText
          className="selected-address__delivery-form"
          $settings={{
            font: settings.basket_step_2_3_typography_paragraph_4,
            text: { color: settings.basket_step_all_color_main },
          }}
          show={isNotEmptyMessage(messages.delivery_form)}
        >
          <p>{messages.delivery_form}</p>
          {deliveryChannels.length > 1 && (
            <button
              onClick={removeSelectedDeliveryChannel}
              data-testid="editDeliveryChannelBtn"
            >
              <PenIcon />
            </button>
          )}
        </StyledText>

        <StyledText
          className="selected-address__channel-name"
          $settings={{
            font: settings.basket_step_2_typography_paragraph_3,
            text: { color: settings.basket_step_all_color_main },
          }}
        >
          <p>{deliveryChannel.channelName}</p>
        </StyledText>
      </StyledBackground>

      {!editAddress && selectedAddress ? (
        <StyledBackground
          className="selected-address__background-address-payment"
          backgroundColor={
            settings.basket_step_all_background_adress_payment_color
          }
        >
          <StyledText
            className="selected-address__delivery-address"
            $settings={{
              font: settings.basket_step_2_3_typography_paragraph_4,
              text: { color: settings.basket_step_all_color_main },
            }}
          >
            <p>
              {isPostalType(deliveryChannel.channelType)
                ? messages.delivery_address
                : messages.delivery_point_address}
            </p>
            {!deliveryChannel.oneStoreActive && (
              <button
                onClick={() => setEditAddress(true)}
                data-testid="editAddressButton"
              >
                <PenIcon />
              </button>
            )}
          </StyledText>

          <AddressInfo address={selectedAddress} />
        </StyledBackground>
      ) : (
        <ChangeAddress
          addresses={deliveryChannel.addresses}
          selectedAddress={deliveryChannel.selectedAddress}
          addressType={channelTypeToAddresType(deliveryChannel.channelType)}
          closeEditAddress={() => setEditAddress(false)}
        />
      )}
    </StyledSelectedAddress>
  );
};

export default SelectedAddress;
