import React from "react";
import { format } from "date-fns";
import { useTheme } from "styled-components";
import { IOrdersHistoryResponse } from "@ecp-redux/dto/myOrders.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { formatPrice } from "../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledOrderHeadingWrapper } from "../BoxMyAccountMyOrders.styled";
import {
  IBoxMyAccountMyOrdersMessages,
  ITBoxMyAccountMyOrdersSettings,
} from "../BoxMyAccountMyOrders.types";
import OrderActionButtons from "../OrderActionButtons/OrderActionButtons";
import {
  IHeadingValues,
  getSingleOrderInfoHeadingElements,
} from "./SingleOrderInfoHeading.methods";

interface SingleOrderInfoHeadingProps {
  order: IOrdersHistoryResponse["orders"]["0"];
}

const SingleOrderInfoHeading: React.FC<SingleOrderInfoHeadingProps> = ({
  order,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersMessages,
    ITBoxMyAccountMyOrdersSettings
  >();

  const headingValues: IHeadingValues = {
    orderStatus: order.orderStatus,
    orderDate: format(new Date(order.creationDate), "dd.MM.yyyy"),
    orderFinalCost: formatPrice(
      order.orderValue,
      globalMessages["currencyShort"]
    ),
  };

  const isMobile = useIsMobilePortal();

  return (
    <StyledOrderHeadingWrapper
      elementsGap={settings.customer_data_orders_heading_elements_gap}
      className="single-order-heading"
    >
      <div className="single-order-heading__container">
        <StyledText
          className="single-order-heading__container__order-status"
          $settings={{
            font: settings.customer_data_orders_heading_number_label_typo,
            text: {
              color: settings.customer_data_orders_heading_number_label_color,
            },
          }}
          show={isNotEmptyMessage(messages.customer_data_orders_heading_number)}
        >
          {`${messages.customer_data_orders_heading_number}:`}
        </StyledText>
        <StyledText
          className="single-order-heading__container__order-number"
          $settings={{
            font: settings.customer_data_orders_heading_number_label_typo,
            text: {
              color: settings.customer_data_orders_heading_number_label_color,
            },
          }}
          show={isNotEmptyMessage(messages.customer_data_orders_heading_number)}
        >
          {order.orderId}
        </StyledText>
      </div>
      {isMobile && <OrderActionButtons order={order} />}
      {getSingleOrderInfoHeadingElements(messages, settings, headingValues).map(
        (elem, index) => (
          <div key={index} className="single-order-heading__container__info">
            <div className="single-order-heading__container__info__label__container">
              <StyledText
                className="single-order-heading__container__info__label"
                $settings={{
                  font: elem.fontLabelTypo,
                  text: {
                    color: elem.textLabelColor,
                  },
                }}
                show={isNotEmptyMessage(elem.messageLabel)}
              >
                {`${elem.messageLabel}:`}
              </StyledText>
            </div>
            <div className="single-order-heading__container__info__value__container">
              <StyledText
                className="single-order-heading__container__info__value"
                $settings={{
                  font: elem.fontValueTypo,
                  text: {
                    color: elem.textValueColor,
                  },
                }}
                show={isNotEmptyMessage(elem.messageLabel)}
              >
                {elem.value}
              </StyledText>
            </div>
          </div>
        )
      )}
    </StyledOrderHeadingWrapper>
  );
};

export default SingleOrderInfoHeading;
