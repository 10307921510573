import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SECOND_BUTTON_CONTENT_SETTING_PATTERN } from "../../settingsPatterns/settingsPatterns.utils";
import {
  IBoxListOfButtonsContent,
  IBoxListOfButtonsContentSettings,
  IBoxListOfButtonsDisplaySettings,
} from "./BoxListOfButtons.types";

export const BOX_LIST_OF_BUTTONS_CONTENT_SETTINGS =
  (): IBoxListOfButtonsContentSettings => ({
    buttonsList: [],
  });

export const BOX_LIST_OF_BUTTONS_CONTENT = (): IBoxListOfButtonsContent => ({
  buttonsLabels: [],
});

export const BOX_LIST_OF_BUTTONS_DISPLAY_SETTINGS =
  (): IBoxListOfButtonsDisplaySettings => ({
    buttonsStyle: {
      id: SECOND_BUTTON_CONTENT_SETTING_PATTERN().id,
      custom: SECOND_BUTTON_CONTENT_SETTING_PATTERN().custom,
    },
    boxWidth: 100,
    spaceBetweenButtons: 5,
    spaceBetweenRows: 5,
    alignment: AlignmentHorizontalOption.CENTER,
    padding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    vertical: false,
  });
