import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import * as cartAPI from "@ecp-redux/api/cart";
import { cartB2B } from "@ecp-redux/api/cartB2B";
import {
  AddToCartB2B2CRequest,
  DeleteProductRequest,
  ICartB2B,
  SetDeliveryDateB2BRequest,
  SetPaymentMethodB2BRequest,
} from "@ecp-redux/dto/cartB2B.types";
import { isB2BClient } from "@ecp-redux/helpers";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  MutationDefinition,
  QueryStatus,
} from "@reduxjs/toolkit/dist/query";
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";

type ActionTriggerType<T> = MutationTrigger<
  MutationDefinition<
    T,
    BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError>,
    any,
    ICartB2B,
    "api"
  >
>;

type CartB2BContextState =
  | { status: "OK"; cartData: ICartB2B | undefined; grossPrice: boolean }
  | {
      status: "ERROR_CART" | "UNINITIALIZED";
      cartData: undefined;
      grossPrice: boolean;
    }
  | {
      status: "LOADING" | "ERROR_CART_ACTION";
      cartData: undefined | ICartB2B;
      grossPrice: boolean;
    };

type ActionsCartB2B = {
  addToCart: ActionTriggerType<AddToCartB2B2CRequest>;
  changeNetGrossPrice: () => void;
  setDeliveryDate: ActionTriggerType<SetDeliveryDateB2BRequest>;
  clearProductType: ActionTriggerType<string>;
  clearCart: ActionTriggerType<void>;
  deleteProduct: (arg: DeleteProductRequest) => void;
  setPaymentMethod: (arg: SetPaymentMethodB2BRequest) => void;
  setPlaceOrder: ActionTriggerType<void>;
  getCartData: () => void;
};

export const CartB2BContext = createContext<
  CartB2BContextState & ActionsCartB2B
>({
  status: "UNINITIALIZED",
  cartData: undefined,
  grossPrice: false,
  addToCart: (arg: AddToCartB2B2CRequest) => ({} as any),
  setDeliveryDate: (arg: SetDeliveryDateB2BRequest) => ({} as any),
  clearProductType: (arg: string) => ({} as any),
  clearCart: () => ({} as any),
  changeNetGrossPrice: () => null,
  deleteProduct: (arg: DeleteProductRequest) => ({} as any),
  setPaymentMethod: (arg: SetPaymentMethodB2BRequest) => ({} as any),
  setPlaceOrder: () => ({} as any),
  getCartData: () => ({} as any),
});

type CartB2BAction =
  | { type: "updateCartB2B"; payload: ICartB2B | undefined }
  | { type: "setLoadingCartB2B" }
  | { type: "setErrorCartB2B" }
  | { type: "setErrorAddToCartB2B" }
  | { type: "changeNetGrossPrice" };

const cartReducer = (
  state: CartB2BContextState,
  action: CartB2BAction
): CartB2BContextState => {
  switch (action.type) {
    case "updateCartB2B":
      return {
        status: "OK",
        cartData: action.payload,
        grossPrice: state.grossPrice,
      };
    case "setLoadingCartB2B":
      return {
        status: "LOADING",
        cartData: state.cartData,
        grossPrice: state.grossPrice,
      };
    case "setErrorCartB2B":
      return {
        status: "ERROR_CART",
        cartData: undefined,
        grossPrice: state.grossPrice,
      };
    case "setErrorAddToCartB2B":
      return {
        status: "ERROR_CART_ACTION",
        cartData: state.cartData,
        grossPrice: state.grossPrice,
      };
    case "changeNetGrossPrice":
      if (state.status === "OK") {
        return {
          status: state.status,
          cartData: state.cartData,
          grossPrice: !state.grossPrice,
        };
      }
      return state;
    default:
      throw new Error("Unknown action.");
  }
};

export const CartB2BProvider: React.FC<{ children: ReactNode }> = (props) => {
  const [cartState, dispatch] = useReducer(cartReducer, {
    status: "UNINITIALIZED",
    cartData: undefined,
    grossPrice: false,
  });
  const [getCartData, cartData] = cartB2B.useLazyGetCartB2BQuery();
  const [addToCart, addToCartData] =
    cartAPI.usePostAddProductQuantityMutation();
  const [setDeliveryDate, setDeliveryDateData] =
    cartB2B.usePutSetDeliveryDateCartB2BMutation();
  const [clearProductType, deleteProductTypeData] =
    cartB2B.usePutClearProductTypeB2BMutation();
  const [clearCart, clearCartData] = cartB2B.usePutClearCartB2BMutation();
  const [deleteProduct, deleteProductData] =
    cartB2B.useDeleteProductCartB2BMutation();
  const changeNetGrossPrice = () => dispatch({ type: "changeNetGrossPrice" });
  const [setPaymentMethod, setPaymentMethodData] =
    cartB2B.usePutPaymentMethodCartB2BMutation();
  const [setPlaceOrder, setPlaceOrderData] =
    cartB2B.usePutPlaceOrderCartB2BMutation();

  useEffect(() => {
    const { status, data } = cartData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartData.fulfilledTimeStamp, cartData.status]);

  useEffect(() => {
    const { status, data } = addToCartData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToCartData.fulfilledTimeStamp, addToCartData.status]);

  useEffect(() => {
    const { status, data } = setDeliveryDateData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDeliveryDateData.fulfilledTimeStamp, setDeliveryDateData.status]);

  useEffect(() => {
    const { status, data } = deleteProductData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductData.fulfilledTimeStamp, deleteProductData.status]);

  useEffect(() => {
    const { status, data } = clearCartData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCartData.fulfilledTimeStamp, clearCartData.status]);

  useEffect(() => {
    const { status, data } = deleteProductTypeData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteProductTypeData.fulfilledTimeStamp, deleteProductTypeData.status]);

  useEffect(() => {
    const { status, data } = setPaymentMethodData;
    if (status === QueryStatus.fulfilled) {
      dispatch({
        type: "updateCartB2B",
        payload: data,
      });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPaymentMethodData.fulfilledTimeStamp, setPaymentMethodData.status]);

  useEffect(() => {
    const { status, data } = setPlaceOrderData;
    if (status === QueryStatus.fulfilled) {
      dispatch({ type: "updateCartB2B", payload: data });
    }
    if (status === QueryStatus.pending) {
      dispatch({ type: "setLoadingCartB2B" });
    }
    if (status === QueryStatus.rejected) {
      dispatch({ type: "setErrorCartB2B" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPlaceOrderData.fulfilledTimeStamp, setPlaceOrderData.status]);

  const memoizedProviderData = useMemo(() => {
    return {
      ...cartState,
      addToCart,
      setDeliveryDate,
      clearProductType,
      clearCart,
      changeNetGrossPrice,
      deleteProduct,
      setPaymentMethod,
      setPlaceOrder,
      getCartData,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartState]);

  return (
    <CartB2BContext.Provider value={memoizedProviderData}>
      {props.children}
    </CartB2BContext.Provider>
  );
};

export const useCartB2BContext = () => {
  const context = useContext(CartB2BContext);

  useEffect(
    () => {
      if (context.status === "UNINITIALIZED" && isB2BClient()) {
        context.getCartData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return context;
};
