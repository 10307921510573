import {
  GetColumnNamesRequest,
  GetColumnNamesResponse,
  GetTableDataRequest,
  GetTableDataResponse,
} from "../dto/getDataTable";
import { API, api } from "./api";

export const getDataTable = api.injectEndpoints({
  endpoints: (builder) => ({
    getColumnNames: builder.query<
      GetColumnNamesResponse,
      GetColumnNamesRequest
    >({
      query: ({ endpoint }) => {
        return API.getGetDataColumnNames.endpoint(endpoint);
      },
    }),
    getTableData: builder.query<GetTableDataResponse, GetTableDataRequest>({
      query: (body) => {
        return { url: API.getTableData.endpoint(), method: "POST", body };
      },
    }),
  }),
});

export const { useGetColumnNamesQuery, useGetTableDataQuery } = getDataTable;
