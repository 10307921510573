import { IIcon } from "./Icon.types";

interface IClipboardOn extends IIcon {
  testId?: string;
  strokeColor?: string;
}

const ClipboardOn: React.FC<IClipboardOn> = ({
  width,
  height,
  viewBox,
  onClick,
  className,
  testId,
  strokeColor = "#666972",
}: IClipboardOn): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
      data-testid={testId}
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.61 8.61a5.5 5.5 0 000 7.78L16 25.777l9.39-9.389a5.5 5.5 0 10-7.78-7.778L16 10.22l-1.61-1.61a5.5 5.5 0 00-7.78 0z"
      ></path>
    </svg>
  );
};
ClipboardOn.defaultProps = {
  width: "24",
  height: "24",
  fill: "#000",
  viewBox: "4 4 24 24",
};

export default ClipboardOn;
