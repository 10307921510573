import React from "react";
import ReactPlayer from "react-player";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../global/components/ImageWrapper/ImageWrapper.methods";
import VideoIcon from "../../global/components/VideoIcon/VideoIcon";
import { VideoLightCover } from "../../global/components/VideoLightCover/VideoLightCover";
import { getImageNameFromUrl } from "../../helpers/helpers";
import {
  BOX_VIDEO_CONTENT_PATTERN,
  BOX_VIDEO_PATTERN,
  StyledBoxVideo,
  ratioToNumber,
} from "../../settingsPatterns/boxes/BoxVideo/StyledVideo";
import { ratioStyle } from "../../settingsPatterns/composeCss";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { IBoxVideoProps } from "./BoxVideo.types";

const BoxVideo: React.FC<IBoxVideoProps> = (props: IBoxVideoProps) => {
  const [imgWrapper, imageWidth] = useImageSize();

  const { settings } = usePrepareSettings({
    props: {
      ...props.displaySettings,
      ...props.contentSettings,
    },
    settingsPattern: BOX_VIDEO_PATTERN(),
  });

  const content = usePrepareSettings({
    props: { ...props.content },
    settingsPattern: BOX_VIDEO_CONTENT_PATTERN(),
  }).settings;

  const { seoDescription, coverImage, videoURL, videoTitle } = content;

  const coverImgAltText = seoDescription
    ? seoDescription
    : getImageNameFromUrl(coverImage);

  const renderCoverImageOrIcon = () => {
    if (coverImage.length > 0 && imageWidth) {
      return (
        <ImageWrapper
          imageUrl={coverImage}
          seoDescription={seoDescription}
          imageFit={true}
          imageAlignment={{
            horizontal: AlignmentHorizontalOption.CENTER,
            vertical: AlignmentVerticalOption.CENTER,
          }}
          opacity={100}
          width={imageWidth}
          height={imageWidth / ratioToNumber(ratioStyle(settings.ratio))}
        />
      );
    } else {
      return (
        <div className="video-container__video-icon">
          <VideoIcon width="160" height="100" viewBox="0 0 60 50" />
        </div>
      );
    }
  };

  return (
    <StyledBoxVideo
      ref={imgWrapper}
      $settings={settings}
      aria-label={seoDescription}
      data-testid="boxVideo"
      className="video-container"
    >
      {videoURL.length > 0 ? (
        <ReactPlayer
          key={
            settings.video.showVideoControls
              ? "video-controls"
              : "no-video-controls"
          }
          data-testid="react-player"
          width="100%"
          height="100%"
          url={videoURL}
          controls={settings.video.showVideoControls}
          playing={settings.video.autoPlay}
          loop={settings.video.loopVideo}
          light={
            !!coverImage && (
              <VideoLightCover coverImage={coverImage} alt={coverImgAltText} />
            )
          }
          config={{
            youtube: {
              playerVars: { showinfo: 0, rel: 0 },
            },
          }}
          className="video-container__player"
        />
      ) : (
        renderCoverImageOrIcon()
      )}
      {videoTitle.length > 0 && settings.title.show && (
        <div className="video-container__title">
          <StyledText $settings={settings.title}>{videoTitle}</StyledText>
        </div>
      )}
    </StyledBoxVideo>
  );
};

export default BoxVideo;
