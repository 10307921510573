import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../../../settingsPatterns/settingsPatterns.methods";
import StyledText from "../../../../styleElements/StyledText/StyledText";
import Popup from "../../../Popup/Popup";

export const StyledSortB2B = styled(Popup)<{
  topBackgroundColor: TColorId;
  backgroundColor: TColorId;
}>(({ topBackgroundColor, backgroundColor, theme }) => {
  return `
  height: 100svh;

  .sort-b2b {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: green;
    position: relative;
    background-color: ${convertColorIdToHex(
      backgroundColor,
      theme.colorPalette
    )};


    &__top {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding: 20px;
      justify-content: space-between;
      background-color: ${convertColorIdToHex(
        topBackgroundColor,
        theme.colorPalette
      )};
    }

    &__parameters {
      width: 100%;
    }

    &__button {
      position: absolute;
      bottom: 20px;
    }
  }
  `;
});

export const StyledSortB2BParameters = styled(StyledText)`
  padding: 8px 22px;

  li {
    display: flex;
    flex-direction: row;
    width: 100%;
    white-space: nowrap;
    padding: 10px 0 10px 0;
  }
`;
