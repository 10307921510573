import {
  ChannelType,
  ICartResponse,
  IDeliveryAndPaymentResponse,
  IMiniCartCountResponse,
  IMiniCartResponse,
  IOrderConfirmationResponse,
  IPlaceOrderResponse,
  IProductsCountRequest,
  IProductsCountResponse,
  PaymentType,
} from "../dto/cart.types";
import { IAddressesAdd, TAddAddressRequest } from "../dto/cartAddresses.type";
import {
  AddToCartB2B2CRequest,
  ICartB2B,
  ICartB2B_DTO,
} from "../dto/cartB2B.types";
import { isB2BClient, parseTimeToTimezoneOffset } from "../helpers";
import { API, api } from "./api";
import { transformCartB2B } from "./cartB2B";

const optimisticUpdateForProductCountInCart =
  (arg: IProductsCountRequest) => (draft: ICartResponse) => {
    const index = draft?.cartViewResponse?.orderLines.findIndex(
      (e) => e.productSku === arg.productSku
    );

    if (index >= 0) {
      draft.cartViewResponse.orderLines[index].quantity =
        draft.cartViewResponse.orderLines[index].quantity + arg.quantity;
    }
  };

const optimisticUpdateForProductCountInMiniCart =
  (arg: IProductsCountRequest) => (draft: IMiniCartResponse) => {
    const index = draft?.content?.orderLines.findIndex(
      (e) => e.productSku === arg.productSku
    );

    if (index >= 0) {
      draft.content.orderLines[index].quantity =
        draft.content.orderLines[index].quantity + arg.quantity;
    }
  };

export const cart = api
  .enhanceEndpoints({
    addTagTypes: ["CART_STEP_TWO"],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCartByPortalUserToken: builder.query<ICartResponse, void>({
        query: () => {
          return API.getCartByPortalUserToken.endpoint();
        },
        providesTags: ["CART", "PORTAL_USER_AUTH"],
      }),

      getMiniCartByPortalUserToken: builder.query<IMiniCartResponse, void>({
        query: () => {
          return API.getMiniCartByPortalUserToken.endpoint();
        },
        providesTags: ["CART", "PORTAL_USER_AUTH"],
      }),
      getMiniCartCountByPortalUserToken: builder.query<
        IMiniCartCountResponse,
        void
      >({
        query: () => {
          return API.getMiniCartCountByPortalUserToken.endpoint();
        },
        providesTags: ["CART", "PORTAL_USER_AUTH"],
      }),

      putChangeProductCount: builder.mutation<
        // endpoint only use in cart B2C
        IProductsCountResponse,
        IProductsCountRequest
      >({
        query: (body) => {
          return {
            url: API.putChangeProductQuantityByPortalUserToken.endpoint(),
            method: "PUT",
            body,
          };
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            cart.util.updateQueryData(
              "getCartByPortalUserToken",
              undefined,
              optimisticUpdateForProductCountInCart(arg)
            )
          );

          const patchMiniCartResult = dispatch(
            cart.util.updateQueryData(
              "getMiniCartByPortalUserToken",
              undefined,
              optimisticUpdateForProductCountInMiniCart(arg)
            )
          );
          await queryFulfilled.catch(() => patchResult.undo());
          await queryFulfilled.catch(() => patchMiniCartResult.undo());
        },
        invalidatesTags: (_result, error) => (error ? [] : ["CART"]),
      }),
      postAddProductQuantity: builder.mutation<ICartB2B, AddToCartB2B2CRequest>(
        {
          query: ({ sku, quantity, unitOfMeasure, isCart }) => {
            if (isB2BClient()) {
              return {
                url: API.putAddToCartB2B.endpoint(),
                method: "PUT",
                body: {
                  sku,
                  quantity,
                  unitOfMeasure,
                  isCart,
                },
                headers: {
                  "time-zone": parseTimeToTimezoneOffset(
                    new Date().getTimezoneOffset()
                  ),
                },
              };
            } else {
              return {
                url: API.postAddProductQuantityByPortalUserToken.endpoint(),
                method: "POST",
                body: { productSku: sku, quantity },
              };
            }
          },
          invalidatesTags: ["CART", "CART_STEP_TWO"],
          transformResponse: isB2BClient()
            ? (response: ICartB2B_DTO) => transformCartB2B(response)
            : undefined,
        }
      ),

      getDeliveryAndPaymentByPortalUserToken: builder.query<
        IDeliveryAndPaymentResponse,
        void
      >({
        query: () => {
          return API.getDeliveryAndPaymentByPortalUserToken.endpoint();
        },
        providesTags: ["CART_STEP_TWO", "PORTAL_USER_AUTH"],
      }),

      postChangeDeliveryChannel: builder.mutation<
        unknown,
        { deliveryChannelType: ChannelType }
      >({
        query: (body) => {
          return {
            url: API.postChangeDeliveryType.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART_STEP_TWO"],
      }),

      postChangePaymentMethod: builder.mutation<
        unknown,
        { paymentType: PaymentType }
      >({
        query: (body) => {
          return {
            url: API.postChangePaymentType.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART_STEP_TWO"],
      }),

      postChangeCartSelectedAddress: builder.mutation<unknown, IAddressesAdd>({
        query: (body) => {
          return {
            url: API.postChangeCartSelectedAddress.endpoint(
              body.addressId.toString()
            ),
            method: "POST",
          };
        },
        invalidatesTags: ["CART_STEP_TWO"],
      }),

      postCartAddressByPortalUserToken: builder.mutation<
        IAddressesAdd,
        TAddAddressRequest
      >({
        query: (body) => {
          return {
            url: API.postCartAddressByPortalUserToken.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART_STEP_TWO"],
      }),

      putCartAddressByPortalUserToken: builder.mutation<
        IAddressesAdd,
        TAddAddressRequest & IAddressesAdd
      >({
        queryFn: async ({ addressId, ...body }, { getState }, _, baseQuery) => {
          const isUnregistered =
            (getState() as any).api.queries[
              "getDeliveryAndPaymentByPortalUserToken(undefined)"
            ].data.response.cartUserStatus === "UNREGISTERED";

          return await baseQuery({
            url: isUnregistered
              ? API.postCartAddressByPortalUserToken.endpoint()
              : API.putCartAddressByPortalUserToken.endpoint(addressId),
            method: isUnregistered ? "POST" : "PUT",
            body,
          });
        },

        invalidatesTags: ["CART_STEP_TWO"],
      }),

      postCartShoppingWithoutRegister: builder.mutation<
        void,
        { email: string }
      >({
        query: (body) => {
          return {
            url: API.postCartShoppingWithoutRegister.endpoint(),
            method: "POST",
            body,
          };
        },
      }),

      postAssignCart: builder.mutation<void, void>({
        query: (body) => {
          return {
            url: API.postAssignCart.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART", "CART_STEP_TWO"],
      }),

      postCartPlaceOrder: builder.mutation<IPlaceOrderResponse, void>({
        query: (body) => {
          return {
            url: API.postCartPlaceOrder.endpoint(),
            method: "POST",
            body,
          };
        },
        invalidatesTags: ["CART"],
      }),

      getCartOrderConfirmation: builder.query<
        IOrderConfirmationResponse,
        string
      >({
        query: (orderId) => {
          return API.getCartOrderConfirmation.endpoint(orderId);
        },
      }),
    }),
    overrideExisting: true,
  });

export const {
  useGetCartByPortalUserTokenQuery,
  useGetMiniCartByPortalUserTokenQuery,
  useGetMiniCartCountByPortalUserTokenQuery,
  useGetDeliveryAndPaymentByPortalUserTokenQuery,
  usePutChangeProductCountMutation,
  usePostAddProductQuantityMutation,
  usePostChangeDeliveryChannelMutation,
  usePostChangePaymentMethodMutation,
  usePostChangeCartSelectedAddressMutation,
  usePostCartAddressByPortalUserTokenMutation,
  usePutCartAddressByPortalUserTokenMutation,
  usePostCartShoppingWithoutRegisterMutation,
  usePostCartPlaceOrderMutation,
  useLazyGetCartOrderConfirmationQuery,
  usePostAssignCartMutation,
  util: { getRunningOperationPromises },
} = cart;

export const {
  getCartByPortalUserToken,
  getDeliveryAndPaymentByPortalUserToken,
} = cart.endpoints;
