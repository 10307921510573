import { useEffect, useRef } from "react";
import { nanoid } from "nanoid";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useLayoutControllerContext } from "../../../structure/Contexts/LayoutControllerContext";
import { DropdownProps } from "./Dropdown.types";
import DropdownPortal from "./DropdownPortal";
import {
  StyledDropdownContent,
  StyledDropdownWrapper,
} from "./StyledDropdownElements";
import useDropdownDimensions from "./useDropdownDimensions";

const Dropdown = ({
  isOpen,
  setIsOpen,
  buttonContent,
  selectContainerContent,
  openHorizontalDirection = AlignmentHorizontalOption.LEFT,
  fixedWidth,
  onBackDropClick,
  onBackDropMouseOver,
  closeDropdown,
  isResponsive = true,
  actionType = "CLICK",
  id,
  menuWrapperWidth,
  menuWrapperPercentageWidth,
}: DropdownProps) => {
  const dropdownWrapper = useRef<HTMLDivElement>(null);
  const dropdownContent = useRef<HTMLDivElement>(null);

  const { dropdownContentDimensions, dropdownOpen } = useDropdownDimensions(
    isOpen,
    dropdownWrapper,
    dropdownContent,
    isResponsive
  );

  const { lastClickedId, setLastClickedId } = useLayoutControllerContext();
  const dropdownId = id ?? nanoid();

  const handleMouseEnter = () => {
    if (actionType === "CLICK") return;
    setLastClickedId(dropdownId);
  };

  useEffect(() => {
    if (lastClickedId !== dropdownId && dropdownOpen) {
      setIsOpen(false);
    }
  }, [lastClickedId]);

  useEffect(() => {
    dropdownWrapper.current?.addEventListener("wheel", () => {
      setIsOpen(false);
    });

    return () => {
      if (dropdownWrapper)
        dropdownWrapper.current?.removeEventListener("wheel", () => {
          setIsOpen(false);
        });
    };
  }, []);

  return (
    <StyledDropdownWrapper className="dropdown-wrapper" ref={dropdownWrapper}>
      <div
        onClick={() => setLastClickedId(dropdownId)}
        onMouseEnter={handleMouseEnter}
        className="dropdown-wrapper__button-container"
      >
        {buttonContent}
      </div>
      <DropdownPortal
        isOpen={dropdownOpen}
        close={closeDropdown}
        onBackDropClick={() => {
          onBackDropClick && onBackDropClick();
          setLastClickedId(null);
        }}
        onBackDropMouseOver={onBackDropMouseOver}
        actionType={actionType}
        data-testid="dropdown"
      >
        <StyledDropdownContent
          ref={dropdownContent}
          openHorizontalDirection={openHorizontalDirection}
          left={dropdownContentDimensions?.left ?? null}
          top={dropdownContentDimensions?.top ?? null}
          wrapperWidth={dropdownContentDimensions?.wrapperWidth ?? null}
          fixedWidth={fixedWidth}
          menuWrapperWidth={menuWrapperWidth}
          menuWrapperPercentageWidth={menuWrapperPercentageWidth}
        >
          {selectContainerContent}
        </StyledDropdownContent>
      </DropdownPortal>
    </StyledDropdownWrapper>
  );
};

export default Dropdown;
