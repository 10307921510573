import {
  ILinkContent,
  SelectedGoToOption,
} from "../../settingsPatterns/contentPatterns.types";
import { PathStringType, PortalPath } from "../../shared/portalPath/portalPath";
import { IElement } from "../../structure/Contexts/DynamicPageContext";
import { OffsetData } from "../../structure/Contexts/StickyOffsetsContext";

export const getButtonPaths = (button: ILinkContent) =>
  Object.values(button.forceOrderInsideStack ?? {}).flatMap((el) => el);

export const getIsActiveButton = (
  activePaths: IElement[],
  buttonPaths: PathStringType[]
) => {
  const flatActivePaths = activePaths.flatMap((activePath) => activePath.paths);

  return flatActivePaths.some((path) =>
    buttonPaths.some((buttonPath) => path.isPathEqual(buttonPath))
  );
};

export const checkIfButtonShouldBeDisabled = (
  isBoxSelected: boolean,
  selectedGoToOption: SelectedGoToOption,
  href: string
) =>
  (!isBoxSelected &&
    selectedGoToOption === SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK) ||
  ((selectedGoToOption === SelectedGoToOption.GO_TO_PAGE ||
    selectedGoToOption === SelectedGoToOption.GO_TO_ROW) &&
    !href);

export const scrollIntoRowPosition = (
  link: ILinkContent,
  path: PortalPath,
  offsetsData: OffsetData[]
) => {
  if (link.selectedGoToOption !== SelectedGoToOption.GO_TO_ROW) return;

  const getScrollTargetOffset = () => {
    const buttonIdx = offsetsData.findIndex(
      (el) => el.sectionId === path.getFirstSection()
    );
    const buttonOffset =
      (offsetsData[buttonIdx]?.height ?? 0) +
      (offsetsData[buttonIdx]?.offset ?? 0);

    const scrollTargetIdx = offsetsData.findIndex(
      (el) => el.sectionId?.toString() === link.goToRowSetting.rowId
    );
    const topTargetSection =
      (offsetsData[scrollTargetIdx]?.sumHeight ?? 0) -
      (offsetsData[scrollTargetIdx]?.height ?? 0);

    //obliczanie offsetu przesunięcia dla sticky elementów między sekcją buttona a sekcją target
    const spaceBetweenButtonAndTarget = [...offsetsData]
      .splice(buttonIdx + 1, scrollTargetIdx - buttonIdx)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    //obliczanie offsetu przesunięcia w przypadku gdy sekcja buttona nie jest sticky
    const spaceBetweenTopPageAndButton = [...offsetsData]
      .splice(0, buttonIdx - 1)
      .filter((el) => el.sticky)
      .reduce((acc, el) => acc + (el.height ?? 0), 0);

    if (offsetsData[buttonIdx].sticky) {
      return topTargetSection - buttonOffset - spaceBetweenButtonAndTarget;
    } else {
      return (
        topTargetSection -
        spaceBetweenButtonAndTarget -
        spaceBetweenTopPageAndButton
      );
    }
  };

  document?.getElementById("scroll-wrapper")?.scrollTo({
    top: getScrollTargetOffset(),
  });
};
