import { PathStringType } from "@ecp-boxes/shared/portalPath/portalPath";
import { TUrl } from "@ecp-redux/dto/themeSettings/themeSettings.types";

export type TTextContent = string;

interface IGoToPage {
  selectedGoToOption: SelectedGoToOption.GO_TO_PAGE;
  goToPageSetting: IGoToPageSetting;
  showLinkOptions?: boolean;
  goToRowSetting?: IGoToRowSetting;
  forceOrderInsideStack?: Record<string, PathStringType[]>;
}

interface IGoToRow {
  selectedGoToOption: SelectedGoToOption.GO_TO_ROW;
  goToRowSetting: IGoToRowSetting;
  showLinkOptions?: boolean;
  goToPageSetting?: IGoToPageSetting;
  forceOrderInsideStack?: Record<string, PathStringType[]>;
}

interface IChangeOrderInsideStack {
  selectedGoToOption: SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK;
  goToPageSetting?: IGoToPageSetting;
  goToRowSetting?: IGoToRowSetting;
  showLinkOptions?: boolean;
  forceOrderInsideStack: Record<string, PathStringType[]>;
}

export type ILinkContent = IGoToPage | IGoToRow | IChangeOrderInsideStack;

export interface IImageContent {
  imageUrl: TUrl;
}

export interface IImageInlineContent {
  imageUrl?: TUrl;
}

export interface IButtonContent {
  heading: string;
  link: string | null;
}

export interface IGoToPageSetting {
  link: TUrl | null;
  openInNewTab: boolean;
}

export interface IGoToRowSetting {
  rowId: string | number | null;
}

export enum SelectedGoToOption {
  GO_TO_PAGE = "GO_TO_PAGE",
  GO_TO_ROW = "GO_TO_ROW",
  CHANGE_ORDER_INSIDE_STACK = "CHANGE_ORDER_INSIDE_STACK",
  // OPEN_POPUP = "OPEN_POPUP", will be added later
}
