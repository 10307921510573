import { RefObject, useEffect } from "react";

export default function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void
): void {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler();
    };
    //previous element was document.body, change to id "__next" because useClickOutside for nested and positioned absolutely elements does not work (they are at document.body level)
    const element = document.getElementById("__next");

    if (element) {
      element.addEventListener("mousedown", listener);
      element.addEventListener("touchstart", listener);
      element.addEventListener("wheel", listener);
    }

    return () => {
      if (element) {
        element.removeEventListener("mousedown", listener);
        element.removeEventListener("touchstart", listener);
        element.removeEventListener("wheel", listener);
      }
    };
  }, [ref, handler]);
}
