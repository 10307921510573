import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { TMessage } from "@ecp-redux/dto/boxes.types";
import { IElementText } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { ReactComponent as ArrowRightIcon } from "../../../icons/arrowRight.svg";
import StyledText from "../../../styleElements/StyledText/StyledText";

export interface PaginationProps {
  page: number;
  pageQuantity: number;
  setPage: (page: number) => void;
  fromText: TMessage;
  textSettings: IElementText;
}

const StyledPagination = styled(StyledText)`
  width: content-fit;

  input {
    border: 1px solid #eeeeee;
    padding: 8px 16px;
    width: 70px;
    text-align: center;
  }
  svg {
    display: inline;
    cursor: pointer;

    &.left {
      transform: rotate(180deg);
      margin-right: 26px;
    }
    &.right {
      margin-left: 26px;
    }
  }

  .from-text {
    margin: 0 17px;
  }
`;

export const Pagination: React.FC<PaginationProps> = ({
  page,
  pageQuantity,
  setPage,
  fromText,
  textSettings,
}) => {
  const showLeftArrow = page > 0;
  const showRightArrow = page < pageQuantity - 1;
  const [count, setCount] = useState(page + 1);

  const handleChange = useCallback(
    (inputValue: number) => {
      if (inputValue < 1) {
        setPage(0);
        setCount(1);
      } else if (inputValue > pageQuantity) {
        setPage(pageQuantity - 1);
        setCount(pageQuantity);
      } else {
        setPage(inputValue - 1);
        setCount(inputValue);
      }
    },
    [pageQuantity, setPage, page]
  );
  useEffect(() => {
    if (pageQuantity <= 0) return;
    if (page + 1 > pageQuantity) {
      setPage(pageQuantity - 1);
      setCount(pageQuantity);
    } else {
      setCount(page + 1);
    }
  }, [pageQuantity, page, setPage]);

  return (
    <StyledPagination $settings={textSettings} className="pagination-container">
      {showLeftArrow && (
        <button onClick={() => setPage(page - 1)}>
          <ArrowRightIcon className="left" />
        </button>
      )}
      <input
        name="actualPage"
        data-testid="setPageInput"
        value={count}
        onChange={(e) =>
          !isNaN(+e.target.value) && handleChange(+e.currentTarget.value)
        }
        onBlur={(e) => handleChange(+e.target.value)}
        onKeyDown={(e) =>
          e.key === "Enter" && handleChange(+e.currentTarget.value)
        }
        maxLength={8}
      />
      <span className="from-text"> {fromText} </span>
      <span>{pageQuantity}</span>
      {showRightArrow && (
        <button
          data-testid="TablePaginationNextButton"
          onClick={() => setPage(page + 1)}
        >
          <ArrowRightIcon className="right" />
        </button>
      )}
    </StyledPagination>
  );
};
