import { z } from "zod";
import { IPortalBox } from "@ecp-pageTypes";
import { GenericBoxData } from "@ecp-redux/dto/page/union.types";
import {
  IBoxLoginMessagesScheme,
  IBoxLoginSettingsScheme,
} from "./BoxLogin.scheme";

export type IBoxLoginData = GenericBoxData<
  { component: "LOGIN" },
  Record<string, never>,
  Record<string, never>,
  IBoxLoginMessages,
  IBoxLoginSettings
>;

export type IBoxLoginProps = IPortalBox<IBoxLoginData>;

export type IBoxLoginMessages = z.infer<typeof IBoxLoginMessagesScheme>;
export type IBoxLoginSettings = z.infer<typeof IBoxLoginSettingsScheme>;

export enum LoginFormErrorCodes {
  PA001 = "pa-001",
  PA002 = "pa-002",
  PA004 = "pa-004",
  PA006 = "pa-006",
  PA008 = "pa-008",
}

export const EXECUTE_CAPTCHA_ERROR = "execute_captcha_error";
