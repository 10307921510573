import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

interface ISimpleInfoProps {
  children: JSX.Element | string;
}

export const StyledCircleIcon = styled.span<{ color?: TColorId }>`
  background: ${({ color, theme }) =>
    color ? convertColorIdToHex(color, theme.colorPalette) : "#F4F5F7"};
  border-radius: 50px;
  height: fit-content;
  padding: 7px;
  content: ${({ color }) => color};
`;
export const StyledSimpleInfo = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  padding: 20px 75px;
  gap: 15px;
`;

export const Info: React.FC<ISimpleInfoProps> = ({ children }) => (
  <StyledSimpleInfo>{children}</StyledSimpleInfo>
);
