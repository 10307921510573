import React from "react";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  StyledOrderDetailsWrapper,
  StyledOrderGapWrapper,
} from "../BoxMyAccountMyOrdersDetails.styled";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../BoxMyAccountMyOrdersDetails.types";
import ColumnInfoText from "../ColumnInfoText/ColumnInfoText";
import OrderDetailsActionButtons from "../OrderDetailsActionButtons/OrderDetailsActionButtons";
import { getOrderDetailsMainInfoHeadingElements } from "./MyOrderDetailsHeader.methods";

export interface IMyOrderDetailsHeaderProps {
  orderResponse: IOrderDetailsResponse;
}

const MyOrderDetailsHeader: React.FC<IMyOrderDetailsHeaderProps> = ({
  orderResponse,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyOrdersDetailsMessages,
    IBoxMyAccountMyOrdersDetailsSettings
  >();

  const { order } = orderResponse;

  const isMobile = useIsMobilePortal();

  return (
    <StyledOrderDetailsWrapper
      backgroundColorId={settings.customer_data_order_details_background_color}
      className="my-order-details-header"
    >
      <div className="my-order-details-header__container">
        <StyledOrderGapWrapper
          className="my-order-details-header__container__gap-container"
          elementsGap={
            settings.customer_data_order_details_heading_info_elements_gap
          }
        >
          <StyledText
            className="my-order-details-header__container__gap-container__order-number"
            $settings={{
              font: settings.customer_data_order_details_heading_order_label_typo,
              text: {
                color:
                  settings.customer_data_order_details_heading_order_label_color,
              },
            }}
            show={isNotEmptyMessage(
              messages.customer_data_order_details_heading_order_number
            )}
          >
            {`${messages.customer_data_order_details_heading_order_number}: `}
            <StyledText
              className="my-order-details-header__container__gap-container__order-number-value"
              renderAs="span"
              $settings={{
                font: settings.customer_data_order_details_heading_order_value_typo,
                text: {
                  color:
                    settings.customer_data_order_details_heading_order_value_color,
                },
              }}
              show={isNotEmptyMessage(
                messages.customer_data_order_details_heading_order_number
              )}
            >
              {order.orderId}
            </StyledText>
          </StyledText>
          {isMobile && <OrderDetailsActionButtons order={order} />}
          {getOrderDetailsMainInfoHeadingElements(
            messages,
            settings,
            order
          ).map((elem, index) => (
            <ColumnInfoText {...elem} key={index} />
          ))}
        </StyledOrderGapWrapper>
        {!isMobile && <OrderDetailsActionButtons order={order} />}
      </div>
    </StyledOrderDetailsWrapper>
  );
};

export default MyOrderDetailsHeader;
