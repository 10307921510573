import React from "react";
import htmlToReact from "../../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountConsentsMessages,
  IBoxMyAccountConsentsSettings,
} from "../../BoxMyAccountConsents.types";

interface EmailConsentsProps {
  email: string | undefined;
}

const EmailConsent: React.FC<EmailConsentsProps> = ({ email }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountConsentsMessages,
    IBoxMyAccountConsentsSettings
  >();

  return (
    <>
      <StyledText
        $settings={{
          font: settings.checkbox_title_typo,
          text: { color: settings.checkbox_color },
        }}
        show={isNotEmptyMessage(messages.customer_data_email_consent_heading)}
      >
        {messages.customer_data_email_consent_heading}
      </StyledText>

      <p>
        {htmlToReact(
          messages.customer_data_email_consent,
          settings.terms_link_style
        )}
      </p>
      <p>
        {messages.customer_email_address_prefix}: {email ?? "-"}
      </p>
    </>
  );
};

export default EmailConsent;
