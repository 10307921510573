import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { useAddAlert } from "../../redux/slices/alertsSlice";
import Alert from "../../shared/components/Alert/Alert";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { REDIRECT_ACTION } from "../BoxRemind/RemindChangePassword/RemindChangePassword";
import { StyledBoxLogin } from "./BoxLogin.styled";
import { EXECUTE_CAPTCHA_ERROR, IBoxLoginProps } from "./BoxLogin.types";
import LoginForm from "./elements/LoginForm";
import RegisterCodeFailed from "./elements/RegisterCodeFailed";
import ShoppingWithoutRegister from "./elements/ShoppingWithoutRegister";

enum RegisterActions {
  REGISTER_COMPLETED = "REGISTER_COMPLETED",
  REGISTER_FAILED = "REGISTER_FAILED",
}

enum LoginStatus {
  LOGIN = "LOGIN",
  REGISTER_CODE_FAILED = "REGISTER_CODE_FAILED",
  SHOW_SHOPPING_WITHOUT_REGISTER = "SHOW_SHOPPING_WITHOUT_REGISTER",
}

const BoxLogin: React.FC<IBoxLoginProps> = ({ settings, messages }) => {
  const { addAlert } = useAddAlert();
  const router = useRouter();
  const [loginCurrentStatus, setLoginCurrentStatus] = useState<LoginStatus>(
    LoginStatus.LOGIN
  );

  useEffect(() => {
    if (router.query?.["action"] === RegisterActions.REGISTER_COMPLETED) {
      addAlert({
        code: "registration_completed",
        message: messages.register_code_success,
      });
    }
    if (router.query?.["action"] === RegisterActions.REGISTER_FAILED) {
      setLoginCurrentStatus(LoginStatus.REGISTER_CODE_FAILED);
    }

    if (router.query?.["action"] === REDIRECT_ACTION) {
      addAlert({
        code: "new_password",
        message: messages.login_form_remind_password_success,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loginCurrentStatus === LoginStatus.LOGIN && (
        <StyledBoxLogin
          buttonLoginWidth={settings.login_form_login_button_width}
          buttonRedirectWidth={settings.login_form_redirect_button_width}
          formElementsGap={settings.login_form_elements_gap}
          titleSpacing={settings.login_form_title_spacing}
          termsLinkSpacing={settings.login_form_terms_link_spacing}
          wrapperMargin={settings.login_form_wrapper_margin}
          backgroundColor={settings.login_form_wrapper_background_color}
          className="login-container"
        >
          <Alert
            alertsCodes={["registration_completed", "new_password"]}
            backgroundColor={settings.register_code_success_color}
            textColor={settings.register_code_success_color_main}
            typography={settings.register_code_success_typo}
            closeable
            lifetime={5000}
          />
          <Alert
            alertsCodes={["account_already_exist"]}
            backgroundColor={settings.login_form_color_error}
            textColor={settings.register_code_success_color_main}
            typography={settings.login_form_error_typo}
            closeable
            lifetime={5000}
          />

          <StyledText
            $settings={{
              font: settings.login_form_title_typo,
              text: { color: settings.login_form_title_color },
            }}
            alignment={settings.login_form_title_align?.toLowerCase()}
            className="login-container__title"
            show={isNotEmptyMessage(messages.login_form_title)}
          >
            {messages.login_form_title}
          </StyledText>

          <Alert
            alertsCodes={[
              "pa-001",
              "pa-002",
              "pa-004",
              "pa-006",
              "pa-008",
              EXECUTE_CAPTCHA_ERROR,
            ]}
            backgroundColor={settings.login_form_color_error}
            textColor={settings.login_form_color_main}
            typography={settings.login_form_error_typo}
            lifetime={5000}
            closeable
          />

          <LoginForm
            clickShoppingWitoutRegister={() => {
              setLoginCurrentStatus(LoginStatus.SHOW_SHOPPING_WITHOUT_REGISTER);
            }}
          />
        </StyledBoxLogin>
      )}

      {loginCurrentStatus === LoginStatus.REGISTER_CODE_FAILED && (
        <RegisterCodeFailed />
      )}

      {loginCurrentStatus === LoginStatus.SHOW_SHOPPING_WITHOUT_REGISTER && (
        <StyledBoxLogin
          buttonLoginWidth={settings.login_form_login_button_width}
          buttonRedirectWidth={settings.login_form_redirect_button_width}
          formElementsGap={settings.login_form_elements_gap}
          titleSpacing={settings.login_form_title_spacing}
          termsLinkSpacing={settings.login_form_terms_link_spacing}
          wrapperMargin={settings.login_form_wrapper_margin}
          backgroundColor={settings.login_form_wrapper_background_color}
          className="login-container__shopping__without-register"
        >
          <StyledText
            $settings={{
              font: settings.login_form_title_typo,
              text: { color: settings.login_form_title_color },
            }}
            alignment={settings.login_form_title_align?.toLowerCase()}
            className="login-container__shopping-without-register-title"
            show={isNotEmptyMessage(messages.shopping_without_register_title)}
          >
            {messages.shopping_without_register_title}
          </StyledText>
          <ShoppingWithoutRegister
            onAddEmailFail={() => {
              addAlert({
                code: "account_already_exist",
                message: messages.account_already_exist,
              });
              setLoginCurrentStatus(LoginStatus.LOGIN);
            }}
          />
        </StyledBoxLogin>
      )}
    </>
  );
};

export default BoxLogin;
