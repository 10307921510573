import styled, { css } from "styled-components";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import { IStyledOrderSummaryContainer } from "./BoxSummaryB2B.types";

export const StyledOrderSummaryContainer = styled.div(
  ({ backgroundColor, theme }: IStyledOrderSummaryContainer) => {
    const isMobile = useIsMobilePortal();
    return css`
      padding: 20px;
      background: ${convertColorIdToHex(backgroundColor, theme.colorPalette)};
      display: flex;
      flex-direction: column;
      gap: 20px;

      .summary-b2b-order-table__column-label {
        margin: 20px 0;
      }

      .cartB2B__product-line-item {
        min-height: 80px;
      }
      ${isMobile ? "box-shadow: 0px 2px 4px 0px #00000014;" : ""}
    `;
  }
);

export const StyledSummary = styled.div(() => {
  const isMobile = useIsMobilePortal();
  return css`
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0px;

    ${isMobile ? `flex-direction: column;` : ``}
  `;
});

export const StyledSummaryB2BContainer = styled.div(
  ({ $spacing }: { $spacing: string }) => {
    return `
      display: grid;
      grid-template-columns: 1fr;
      gap: ${$spacing} 0px;
    `;
  }
);
