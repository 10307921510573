import React from "react";
import { isStoreAddress } from "@ecp-redux/dto/cartAddresses.type";
import {
  TMyAddresses,
  isMyPostalBillingAddress,
} from "@ecp-redux/dto/myAddresses.type";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountMyAddressListMessages,
  IBoxMyAccountMyAddressListSettings,
} from "../BoxMyAccountMyAddressList.types";
import { StyledMyAdressInfo } from "../StyledBoxMyAccountMyAddressList.styled";

interface ChangeAddressProps {
  address: TMyAddresses;
}

const AddressInfo: React.FC<ChangeAddressProps> = ({ address }) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyAddressListMessages,
    IBoxMyAccountMyAddressListSettings
  >();

  return (
    <StyledMyAdressInfo>
      <StyledText
        className="my-address-info"
        $settings={{
          font: settings.myaccount_address_list_delivery_address_value_typo,
          text: {
            color: settings.myaccount_address_list_delivery_address_value_color,
          },
        }}
      >
        {/* To add while addding Packstation and Store addresses */}
        {/* {isPackstationAddress(address) ||
        (isPickUpPointAddress(address) && (
          <p>{messages.delivery_point_address}</p>
        ))}
   
      {isPackstationAddress(address) && <p>{address.packstationCode}</p>} */}
        {isStoreAddress(address) && (
          <p className="my-address-info--store-name">{address.storeName}</p>
        )}
        {isMyPostalBillingAddress(address) && (
          <p className="my-address-info--last-name">{`${address.firstName} ${address.lastName}`}</p>
        )}
        {isMyPostalBillingAddress(address) && address.companyName && (
          <p className="my-address-info--company-name">
            {address.companyName}{" "}
          </p>
        )}
        {isMyPostalBillingAddress(address) && address.nip && (
          <p className="my-address-info--nip">{address.nip}</p>
        )}
        {isMyPostalBillingAddress(address) && (
          <p className="my-address-info--street-info">{`${address.street} ${
            address.streetNumber ?? ""
          } ${address.flatNumber ? `/ ${address.flatNumber}` : ""}`}</p>
        )}

        <p className="my-address-info--city-info">{`${address.zipCode} ${address.city}`}</p>
        {isMyPostalBillingAddress(address) && (
          <p className="my-address-info--country">{address.country}</p>
        )}
        {isMyPostalBillingAddress(address) && address.phone && (
          <p>{address.phone}</p>
        )}
      </StyledText>

      <StyledText
        className="my-address-default-address"
        $settings={{
          font: settings.myaccount_address_list_delivery_address_default_address_typo,
          text: {
            color:
              settings.myaccount_address_list_delivery_address_default_address_color,
          },
        }}
        show={
          isNotEmptyMessage(messages.myaccount_address_list_main_address) &&
          address.defaultAddress
        }
      >
        {messages.myaccount_address_list_main_address}
      </StyledText>
    </StyledMyAdressInfo>
  );
};

export default AddressInfo;
