import React, { useMemo } from "react";
import { useTheme } from "styled-components";
import * as cartAPI from "@ecp-redux/api/cart";
import { CART_ERROR_STATUS } from "@ecp-redux/dto/cart.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNotEmptyMessage } from "../../../../../helpers/isNotEmptyMessage";
import LocalAlert from "../../../../../shared/components/Alert/LocalAlert";
import { useProductInfo } from "../../../../../shared/hooks/useProductInfo";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { isCartError } from "../../../cart.methods";
import { StyledProductsCartList } from "../../BoxCartStepOne.styled";
import {
  IBoxCartStepOneMessages,
  IBoxCartStepOneSettings,
} from "../../BoxCartStepOne.types";
import ProductCartElement from "./elements/ProductCartElement";

const ProductsCartList: React.FC = () => {
  const { data } = cartAPI.useGetCartByPortalUserTokenQuery();
  const skus = useMemo(
    () =>
      data?.cartViewResponse.orderLines.map((orderLine) => ({
        productSku: orderLine.productSku,
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.cartViewResponse.orderLines.length]
  );
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartStepOneMessages,
    IBoxCartStepOneSettings
  >();

  const { getProductInfo, isLoadingProduct } = useProductInfo(skus);

  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;

  if (isLoadingProduct) {
    return null;
  }
  return (
    <StyledProductsCartList
      backgroundColor={settings.basket_step_all_background_color}
      data-testid="ProductsCartList"
      className="products-cart-list"
    >
      <div className="products-cart-list__header-container">
        <StyledText
          className="products-cart-list__header"
          $settings={{
            font: settings.basket_step_0_typography_header,
            text: { color: settings.basket_step_all_color_main },
          }}
          show={isNotEmptyMessage(messages.cart_header)}
        >
          <p>{messages.cart_header}</p>
        </StyledText>
        <StyledText
          className="products-cart-list__items-count"
          $settings={{
            font: settings.basket_step_all_typography_paragraph_1,
            text: { color: settings.basket_step_all_color_second },
          }}
          show={isNotEmptyMessage(messages.cart_art)}
        >
          {`${data?.cartViewResponse.orderLines.length} ${messages.cart_art}`}
        </StyledText>
      </div>

      <StyledText
        className="products-cart-list__description"
        $settings={{
          font: settings.basket_step_all_typography_paragraph_2,
          text: { color: settings.basket_step_all_color_main },
        }}
        show={isNotEmptyMessage(messages.cart_description)}
      >
        <p>{messages.cart_description}</p>
      </StyledText>
      <div className="products-cart-list__alerts-container">
        <LocalAlert
          message={messages.cart_error_product_partially_unavailable}
          open={isCartError(data, [
            CART_ERROR_STATUS.PRODUCT_PARTIALLY_AVAILABLE,
          ])}
          backgroundColor={settings.basket_step_all_background_color_error}
          textColor={settings.basket_step_all_color_main}
          typography={settings.basket_step_all_typography_error_success}
        />
        <LocalAlert
          message={messages.cart_error_limit_exceeded}
          open={isCartError(data, [
            CART_ERROR_STATUS.PRODUCT_OUT_OF_STOCK,
            CART_ERROR_STATUS.PRODUCT_DELIVERY_TIME_NOT_AVAILABLE,
          ])}
          backgroundColor={settings.basket_step_all_background_color_error}
          textColor={settings.basket_step_all_color_main}
          typography={settings.basket_step_all_typography_error_success}
        />
        <LocalAlert
          message={messages.cart_error_product_price_alert_changed}
          open={isCartError(data, [
            CART_ERROR_STATUS.PRODUCT_PRICE_HAS_CHANGED,
          ])}
          backgroundColor={settings.basket_step_all_background_color_error}
          textColor={settings.basket_step_all_color_main}
          typography={settings.basket_step_all_typography_error_success}
        />
      </div>

      <div className="products-cart-list__product-cart-element-container">
        {data?.cartViewResponse.orderLines.map((product) => (
          <ProductCartElement
            product={product}
            imageUrl={
              getProductInfo(product.productSku)?.coverPhoto ??
              globalSettings.dynamicBoxImagePlug
            }
            productType={getProductInfo(product.productSku)?.type}
            onWishList={getProductInfo(product.productSku)?.wishlist.base}
            imageHref={getProductInfo(product.productSku)?.url}
            deliveryTime={getProductInfo(product.productSku)?.deliveryTime}
            key={product.productSku}
          />
        ))}
      </div>
    </StyledProductsCartList>
  );
};

export default ProductsCartList;
