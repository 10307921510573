import { z } from "zod";
import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import {
  BoxAddToCartMessagesScheme,
  BoxAddToCartSettingsScheme,
} from "./BoxAddToCart/BoxAddToCart.scheme";
import {
  IBoxAddToCartQuantityB2BMessagesScheme,
  IBoxAddToCartQuantityB2BSettingsScheme,
} from "./BoxAddToCartB2B/BoxAddToCartQuantityB2B.scheme";
import {
  IBoxAddToCartQuantityContentMessagesScheme,
  IBoxAddToCartQuantityContentSettingsScheme,
} from "./BoxAddToCartQuantity/BoxAddToCartQuantity.scheme";
import {
  IBoxArticleContentMessagesScheme,
  IBoxArticleContentSettingsScheme,
} from "./BoxArticleContent/BoxArticleContent.scheme";
import { IBoxB2BAddToCartUnitSettingsScheme } from "./BoxB2BAddToCartUnit/BoxB2BAddToCartUnit.scheme";
import {
  IBoxContactFormMessagesScheme,
  IBoxContactFormSettingsScheme,
} from "./BoxContactForm/BoxContactForm.scheme";
import {
  IBoxContractorsMessageScheme,
  IBoxContractorsSettingsScheme,
} from "./BoxContractors/BoxContractors.scheme";
import {
  IBoxDropdownMessagesScheme,
  IBoxDropdownSettingsScheme,
} from "./BoxDropdown/BoxDropdown.scheme";
import {
  IBoxLoginMessagesScheme,
  IBoxLoginSettingsScheme,
} from "./BoxLogin/BoxLogin.scheme";
import {
  IBoxMiniCartMessagesScheme,
  IBoxMiniCartSettingsScheme,
} from "./BoxMiniCart/BoxMiniCart.scheme";
import {
  IBoxMiniCartB2BMessagesScheme,
  IBoxMiniCartB2BSettingsScheme,
} from "./BoxMiniCartB2B/BoxMiniCartB2B.scheme";
import {
  IBoxMultiMenuMessagesScheme,
  IBoxMultiMenuSettingsScheme,
} from "./BoxMultiMenu/BoxMultiMenu.scheme";
import {
  IBoxMyAccountConsentsMessagesScheme,
  IBoxMyAccountConsentsSettingsScheme,
} from "./BoxMyAccountConsents/BoxMyAccountConsents.scheme";
import {
  IBoxMyAccountHeaderMessagesScheme,
  IBoxMyAccountHeaderSettingsScheme,
} from "./BoxMyAccountHeader/BoxMyAccountHeader.scheme";
import {
  IBoxMyAccountMyAddressListMessagesScheme,
  IBoxMyAccountMyAddressListSettingsScheme,
} from "./BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.scheme";
import {
  IBoxMyAccountMyDataMessagesScheme,
  IBoxMyAccountMyDataSettingsScheme,
} from "./BoxMyAccountMyData/BoxMyAccountMyData.scheme";
import {
  IBoxMyAccountMyOrdersMessagesScheme,
  ITBoxMyAccountMyOrdersSettingsScheme,
} from "./BoxMyAccountMyOrders/BoxMyAccountMyOrders.scheme";
import {
  IBoxMyAccountMyOrdersDetailsMessagesScheme,
  IBoxMyAccountMyOrdersDetailsSettingsScheme,
} from "./BoxMyAccountMyOrdersDetails/BoxMyAccountMyOrdersDetails.scheme";
import {
  IBoxNewsletterMessagesScheme,
  IBoxNewsletterSettingsScheme,
} from "./BoxNewsletter/BoxNewsletter.scheme";
import {
  IBoxPhotoGalleryMessagesScheme,
  IBoxPhotoGallerySettingsScheme,
} from "./BoxPhotoGallery/BoxPhotoGallery.scheme";
import {
  IBoxProductAttributesMessagesScheme,
  IBoxProductAttributesSettingsScheme,
} from "./BoxProductAttributes/BoxProductAttributes.scheme";
import {
  IBoxProductSliderMessagesScheme,
  IBoxProductSliderSettingsScheme,
} from "./BoxProductSlider/BoxProductSlider.scheme";
import {
  IBoxRegisterMessagesScheme,
  IBoxRegisterSettingsScheme,
} from "./BoxRegister/BoxRegister.scheme";
import {
  IBoxRemindMessagesScheme,
  IBoxRemindSettingsScheme,
} from "./BoxRemind/BoxRemind.scheme";
import {
  IBoxSearchBarMessagesScheme,
  IBoxSearchBarSettingsScheme,
} from "./BoxSearchBar/BoxSearchBar.scheme";
import {
  IBoxSearchFiltersMessagesScheme,
  IBoxSearchFiltersSettingsScheme,
} from "./BoxSearchFilters/BoxSearchFilters.scheme";
import {
  IBoxSearchResultsMessagesScheme,
  IBoxSearchResultsSettingsScheme,
} from "./BoxSearchResults/BoxSearchResults.scheme";
import {
  BoxSearchResultsB2BContentScheme,
  BoxSearchResultsB2BContentSettingsScheme,
  BoxSearchResultsB2BDisplaySettingsScheme,
  BoxSearchResultsB2BMessagesScheme,
  BoxSearchResultsB2BSettingsScheme,
} from "./BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import {
  BoxStoreLocatorMessagesScheme,
  BoxStoreLocatorSettingsScheme,
} from "./BoxStoreLocator/BoxStoreLocator.scheme";
import {
  BoxTableDataContentScheme,
  BoxTableDataContentSettingsScheme,
  BoxTableDataDisplaySettingsScheme,
  BoxTableDataMessagesScheme,
  BoxTableDataSettingsScheme,
} from "./BoxTableData/BoxTableData.types";
import {
  BoxTextContentScheme,
  BoxTextContentSettingsScheme,
  BoxTextDisplaySettingsScheme,
} from "./BoxText/BoxText.scheme";
import {
  BoxTilesSliderContentScheme,
  BoxTilesSliderContentSettingsScheme,
  BoxTilesSliderDisplaySettingsScheme,
} from "./BoxTilesSlider/BoxTilesSlider.scheme";
import {
  IBoxCartStepOneMessagesScheme,
  IBoxCartStepOneSettingsScheme,
} from "./Cart/BoxCartStepOne/BoxCartStepOne.scheme";
import {
  IBoxCartStepTwoMessagesScheme,
  IBoxCartStepTwoSettingsScheme,
} from "./Cart/BoxCartStepTwo/BoxCartStepTwo.scheme";
import {
  IBoxOrderSummaryMessagesScheme,
  IBoxOrderSummarySettingsScheme,
} from "./Cart/BoxOrderSummary/BoxOrderSummary.scheme";
import {
  BoxCartB2BMessagesScheme,
  BoxCartB2BSettingsScheme,
} from "./CartB2B/BoxCartB2B/BoxCartB2B.scheme";
import {
  BoxSummaryB2BMessagesScheme,
  BoxSummaryB2BSettingsScheme,
} from "./CartB2B/BoxSummaryB2B/BoxSummaryB2B.scheme";

const emptyMS = {
  messages: z.object({}),
  settings: z.object({}),
};

export const boxScheme: Record<
  TSystemBoxType | TRegularBoxType,
  {
    messages: z.AnyZodObject;
    settings: z.AnyZodObject;
    displaySettings?:
      | z.ZodDefault<z.AnyZodObject>
      | z.ZodCatch<z.ZodDefault<z.AnyZodObject>>;
    contentSettings?:
      | z.ZodDefault<z.AnyZodObject>
      | z.ZodCatch<z.ZodDefault<z.AnyZodObject>>;
    content?:
      | z.ZodDefault<z.AnyZodObject>
      | z.ZodCatch<z.ZodDefault<z.AnyZodObject>>;
  }
> = {
  TEXT: {
    ...emptyMS,
    content: BoxTextContentScheme,
    displaySettings: BoxTextDisplaySettingsScheme,
    contentSettings: BoxTextContentSettingsScheme,
  },
  IMAGE: emptyMS,
  SLIDER: emptyMS,
  VIDEO: emptyMS,
  HTML: emptyMS,
  RICH_TEXT: emptyMS,
  BUTTON: emptyMS,
  BANNER: emptyMS,
  ARTICLE_LIST: emptyMS,
  BREADCRUMB: emptyMS,
  PRODUCT_VARIANT: emptyMS,
  GET_DATA: emptyMS,
  ACCORDION: emptyMS,
  LIST_OF_BUTTONS: emptyMS,
  TILES_SLIDER: {
    ...emptyMS,
    displaySettings: BoxTilesSliderDisplaySettingsScheme,
    contentSettings: BoxTilesSliderContentSettingsScheme,
    content: BoxTilesSliderContentScheme,
  },
  GET_TABLE_DATA: {
    messages: BoxTableDataMessagesScheme,
    settings: BoxTableDataSettingsScheme,
    displaySettings: BoxTableDataDisplaySettingsScheme,
    contentSettings: BoxTableDataContentSettingsScheme,
    content: BoxTableDataContentScheme,
  },
  SEARCH_RESULTS_B2B: {
    messages: BoxSearchResultsB2BMessagesScheme,
    settings: BoxSearchResultsB2BSettingsScheme,
    displaySettings: BoxSearchResultsB2BDisplaySettingsScheme,
    contentSettings: BoxSearchResultsB2BContentSettingsScheme,
    content: BoxSearchResultsB2BContentScheme,
  },
  PRODUCT_ATTRIBUTES: {
    settings: IBoxProductAttributesSettingsScheme,
    messages: IBoxProductAttributesMessagesScheme,
  },
  SYSTEM: emptyMS,

  MYACCOUNT_CONSENTS: {
    messages: IBoxMyAccountConsentsMessagesScheme,
    settings: IBoxMyAccountConsentsSettingsScheme,
  },
  MYACCOUNT_HEADER: {
    messages: IBoxMyAccountHeaderMessagesScheme,
    settings: IBoxMyAccountHeaderSettingsScheme,
  },
  LOGIN: {
    messages: IBoxLoginMessagesScheme,
    settings: IBoxLoginSettingsScheme,
  },

  REMIND_PASSWORD: {
    messages: IBoxRemindMessagesScheme,
    settings: IBoxRemindSettingsScheme,
  },
  REGISTER: {
    messages: IBoxRegisterMessagesScheme,
    settings: IBoxRegisterSettingsScheme,
  },
  PHOTO_GALLERY: {
    messages: IBoxPhotoGalleryMessagesScheme,
    settings: IBoxPhotoGallerySettingsScheme,
  },
  ARTICLE_CONTENT: {
    messages: IBoxArticleContentMessagesScheme,
    settings: IBoxArticleContentSettingsScheme,
  },
  CART_STEP_ONE: {
    messages: IBoxCartStepOneMessagesScheme,
    settings: IBoxCartStepOneSettingsScheme,
  },
  CART_STEP_TWO: {
    messages: IBoxCartStepTwoMessagesScheme,
    settings: IBoxCartStepTwoSettingsScheme,
  },
  NEWSLETTER: {
    messages: IBoxNewsletterMessagesScheme,
    settings: IBoxNewsletterSettingsScheme,
  },
  SEARCH_BAR: {
    messages: IBoxSearchBarMessagesScheme,
    settings: IBoxSearchBarSettingsScheme,
  },
  DROPDOWN: {
    messages: IBoxDropdownMessagesScheme,
    settings: IBoxDropdownSettingsScheme,
  },
  CONTACT_FORM: {
    messages: IBoxContactFormMessagesScheme,
    settings: IBoxContactFormSettingsScheme,
  },
  ORDER_SUMMARY: {
    messages: IBoxOrderSummaryMessagesScheme,
    settings: IBoxOrderSummarySettingsScheme,
  },
  MYACCOUNT_MYORDERS: {
    messages: IBoxMyAccountMyOrdersMessagesScheme,
    settings: ITBoxMyAccountMyOrdersSettingsScheme,
  },
  MYACCOUNT_ORDER_DETAILS: {
    messages: IBoxMyAccountMyOrdersDetailsMessagesScheme,
    settings: IBoxMyAccountMyOrdersDetailsSettingsScheme,
  },
  MYACCOUNT_MYDATA: {
    messages: IBoxMyAccountMyDataMessagesScheme,
    settings: IBoxMyAccountMyDataSettingsScheme,
  },
  MINI_CART: {
    messages: IBoxMiniCartMessagesScheme,
    settings: IBoxMiniCartSettingsScheme,
  },
  ADD_TO_CART: {
    messages: BoxAddToCartMessagesScheme,
    settings: BoxAddToCartSettingsScheme,
  },
  MYACCOUNT_ADDRESSES: {
    messages: IBoxMyAccountMyAddressListMessagesScheme,
    settings: IBoxMyAccountMyAddressListSettingsScheme,
  },
  STORE_LOCATOR: {
    messages: BoxStoreLocatorMessagesScheme,
    settings: BoxStoreLocatorSettingsScheme,
  },
  ADD_TO_CART_QUANTITY: {
    messages: IBoxAddToCartQuantityContentMessagesScheme,
    settings: IBoxAddToCartQuantityContentSettingsScheme,
  },
  SEARCH_FILTERS: {
    messages: IBoxSearchFiltersMessagesScheme,
    settings: IBoxSearchFiltersSettingsScheme,
  },
  SEARCH_RESULTS: {
    messages: IBoxSearchResultsMessagesScheme,
    settings: IBoxSearchResultsSettingsScheme,
  },
  PRODUCT_SLIDER: {
    messages: IBoxProductSliderMessagesScheme,
    settings: IBoxProductSliderSettingsScheme,
  },
  MULTI_MENU: {
    messages: IBoxMultiMenuMessagesScheme,
    settings: IBoxMultiMenuSettingsScheme,
  },
  CONTRACTORS: {
    messages: IBoxContractorsMessageScheme,
    settings: IBoxContractorsSettingsScheme,
  },
  CART_B2B: {
    messages: BoxCartB2BMessagesScheme,
    settings: BoxCartB2BSettingsScheme,
  },
  B2B_ADD_TO_CART_QUANTITY: {
    messages: IBoxAddToCartQuantityB2BMessagesScheme,
    settings: IBoxAddToCartQuantityB2BSettingsScheme,
  },
  B2B_CART_SUMMARY: {
    messages: BoxSummaryB2BMessagesScheme,
    settings: BoxSummaryB2BSettingsScheme,
  },
  B2B_ADD_TO_CART_UNIT: {
    messages: emptyMS.messages,
    settings: IBoxB2BAddToCartUnitSettingsScheme,
  },
  MINI_CART_B2B: {
    messages: IBoxMiniCartB2BMessagesScheme,
    settings: IBoxMiniCartB2BSettingsScheme,
  },
};
