import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useTheme } from "styled-components";
import {
  CreditLimitType,
  ISummaryB2B,
  LimitedClientStatus,
  PaymentMethodOptionB2B,
} from "@ecp-redux/dto/cartB2B.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import CreditLimitProgressBar from "../../../../../boxes/CartB2B/BoxCartB2B/components/CreditLimitProgressBar";
import ConditionalWrapper from "../../../../../global/components/ConditionalWrapper/ConditionalWrapper";
import { useObserveElementDimensions } from "../../../../../global/components/ImageWrapper/ImageWrapper.methods";
import Portal from "../../../../../global/components/Portal/Portal";
import { formatPrice } from "../../../../../helpers/helpers";
import { isNotEmptyMessage } from "../../../../../helpers/isNotEmptyMessage";
import Alert from "../../../../../shared/components/Alert/Alert";
import MobileSwiper from "../../../../../shared/components/MobileSwiper/MobileSwiper";
import useIsMobilePortal from "../../../../../shared/hooks/useIsMobilePortal";
import IconResize from "../../../../../shared/icons/IconResize";
import StyledButton from "../../../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { ReactComponent as SummaryGeoIcon } from "../../../../icons/summaryGeoIcon.svg";
import { useCartB2BContext } from "../useCartB2BContext";
import PaymentMethod from "./PaymentMethod";
import { StyledPrice, StyledSummary } from "./SummaryB2B.styled";
import useSummaryB2BAlerts from "./useSummaryB2BAlerts";

const SummaryB2B = ({
  summary,
  setHeight,
}: {
  summary: ISummaryB2B;
  setHeight: (height: number) => void;
}) => {
  const [finalSummaryRef, width, height] = useObserveElementDimensions();
  const isMobile = useIsMobilePortal();
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;
  const router = useRouter();
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const [rolledSummary, setRolledSummary] = useState(true);
  const { setPlaceOrder, cartData } = useCartB2BContext();

  useEffect(() => {
    setHeight(height);
  }, [height]);

  const rightTotalCartValue =
    summary.creditLimitType === CreditLimitType.NET
      ? summary.netTotalCartValue
      : summary.grossTotalCartValue;

  useSummaryB2BAlerts(summary, {
    limitedAccount: messages.basket_summary_credit_bar_error_account_limited,
    limitExceeded:
      messages.basket_summary_credit_bar_error_credit_limit_exceeded,
  });

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children) => <Portal>{children}</Portal>}
    >
      <StyledSummary
        className={classNames("summary-container", {
          rolled: rolledSummary && isMobile,
        })}
        data-testid="summary-container"
        ref={finalSummaryRef}
        geoIconColor={settings.basket_summary_geo_icon_color}
        columnBackgroundColor={settings.basket_summary_column_background_color}
        backgroundColor={settings.basket_summary_background_color}
      >
        <MobileSwiper
          onSwipe={(_, deltaY) => {
            deltaY && setRolledSummary(deltaY > 0);
          }}
        >
          <div
            data-testid="box-cart-b2b-summary-clickable-area"
            className="summary-container__swipe-button"
            onClick={() => {
              setRolledSummary((prev) => !prev);
            }}
          >
            <IconResize />
          </div>
        </MobileSwiper>

        <div className="summary-container__content">
          <StyledText
            className="summary-container__content__header"
            $settings={{
              font: settings.basket_header_section_typo,
              text: { color: settings.basket_header_section_color },
            }}
            data-testid="summary-header"
          >
            {messages.basket_summary_label}
          </StyledText>

          <div className="summary-container__content__price-container">
            <StyledPrice>
              <StyledText
                $settings={{
                  font: settings.basket_data_header_typo,
                  text: { color: settings.basket_data_header_color },
                }}
              >
                {messages.basket_summary_gross_price_label}
              </StyledText>
              <StyledText
                $settings={{
                  font: settings.basket_data_header_typo,
                  text: { color: settings.basket_data_header_color },
                }}
              >
                {`${formatPrice(summary.grossTotalCartValue)} ${
                  globalMessages.currencyShort
                }`}
              </StyledText>
            </StyledPrice>
            <StyledPrice>
              <StyledText
                $settings={{
                  font: settings.basket_text_typo,
                  text: { color: settings.basket_text_color },
                }}
              >
                {messages.basket_summary_netto_price_label}
              </StyledText>
              <StyledText
                $settings={{
                  font: settings.basket_text_typo,
                  text: { color: settings.basket_text_color },
                }}
              >
                {`${formatPrice(summary.netTotalCartValue)} ${
                  globalMessages.currencyShort
                }`}
              </StyledText>
            </StyledPrice>
          </div>
          <div className="summary-container__content__payment-container">
            <div className="summary-container__content__payment-container__payment-method">
              <StyledText
                className="summary-container__content__payment-container__payment-method__label"
                $settings={{
                  font: settings.basket_text_typo,
                  text: { color: settings.basket_text_color },
                }}
              >
                {messages.basket_summary_credit_bar_payment_method_label}
              </StyledText>
              <PaymentMethod summary={summary} />
            </div>
            <Alert
              alertsCodes={["limited_account"]}
              backgroundColor={
                settings.basket_summary_credit_bar_error_background_color
              }
              textColor={settings.basket_text_color}
              typography={settings.basket_text_typo}
              closeable
            />
            <Alert
              alertsCodes={["credit_limit_exceeded"]}
              backgroundColor={
                settings.basket_summary_credit_bar_error_background_color
              }
              textColor={settings.basket_text_color}
              typography={settings.basket_text_typo}
              closeable
            />
            {summary.paymentMethod === PaymentMethodOptionB2B.CREDIT &&
              summary.totalCreditLimit !== null && <CreditLimitProgressBar />}
          </div>
          <div className="summary-container__content__delivery">
            <StyledText
              $settings={{
                font: settings.basket_text_typo,
                text: { color: settings.basket_text_color },
              }}
              className="summary-container__content__delivery__label"
            >
              {messages.basket_summary_delivery_point_label}
            </StyledText>

            <div className="summary-container__content__delivery__address">
              <SummaryGeoIcon
                width={settings.basket_summary_geo_icon_size}
                height={settings.basket_summary_geo_icon_size}
                className="summary-container__content__delivery__address__geo-icon"
              />
              <StyledText
                $settings={{
                  font: settings.basket_text_typo,
                  text: { color: settings.basket_text_color },
                }}
              >
                {summary.deliveryAddress.name}
              </StyledText>
            </div>
          </div>
        </div>
        <div className="summary-container__content__final-summary">
          <StyledPrice
            className={classNames(
              "summary-container__content__final-summary__total-price",
              {
                rolled: rolledSummary && isMobile,
              }
            )}
          >
            <StyledText
              $settings={{
                font: settings.basket_label_typo,
                text: { color: settings.basket_label_color },
              }}
            >
              {messages.basket_summary_to_pay_label}
            </StyledText>
            <StyledText
              $settings={{
                font: settings.basket_header_section_typo,
                text: { color: settings.basket_header_section_color },
              }}
            >
              {formatPrice(rightTotalCartValue, globalMessages.currencyShort)}
            </StyledText>
          </StyledPrice>
          <StyledButton
            className="summary-container__content__final-summary__order-button"
            data-testid="order-button"
            $settings={settings.basket_summary_ordering_button_style}
            disabled={
              cartData?.hasNotificationErrors ||
              summary.limitedClientStatus === LimitedClientStatus.LIMITED ||
              (summary.paymentMethod === PaymentMethodOptionB2B.CREDIT &&
                summary.creditLimitExceeded > 0) ||
              cartData?.orders.some((e) => e.netLogisticMinMissing > 0)
            }
            onClick={() =>
              setPlaceOrder().then((data) => {
                if (
                  "data" in data &&
                  !data.data.hasNotificationErrors &&
                  data.data.cartId != null
                ) {
                  router.push(
                    `${settings.basket_order_success_redirect_url}?cartId=${data.data.cartId}`
                  );
                }
              })
            }
            renderAs={"button"}
            show={isNotEmptyMessage(
              messages.basket_summary_ordering_button_label
            )}
          >
            {messages.basket_summary_ordering_button_label}
          </StyledButton>
        </div>
      </StyledSummary>
    </ConditionalWrapper>
  );
};
export default SummaryB2B;
