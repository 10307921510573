import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../global/global";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";

export const StyledBoxCartStepTwo = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return `
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 20px;
      
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 0px;
      }

      .delivery-channels{
        background-color: ${bg};
      }

      .billing{
        background-color: ${bg};
      }

      .payments{
        background-color: ${bg};
      }

      .summary{
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          margin-top: 20px;
        }
      }

      .cart-step-two {
        &__content {
          grid-column: span 2;
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    `;
});

export const StyledDeliveryChannels = styled.div`
  background-color: #ffffff;
  padding: 1.25rem;

  .delivery-channels {
    &__header {
      margin-bottom: 15px;
    }

    &__channel-container__main {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    &__channel-container {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }

    &__channel-button-container {
      justify-self: end;
      align-self: end;
    }
  }
`;

export const StyledDeliveryElement = styled.div<{
  deliveryChannelDisabled: boolean;
}>(({ deliveryChannelDisabled }) => {
  return `
    display: flex;
    gap: 15px;
    align-items: center;

    ${
      deliveryChannelDisabled
        ? `
          opacity: 0.2;
          pointer-events: none;
          `
        : ""
    }

    .delivery-channel-element {
      &__icon {
        flex: none;
        height: 2.25rem;
        width: 2.25rem;
      }

      &__info-container {
        display: flex;
        
        &__price {
          min-width: 80px;
        }
      }

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        &__info-container {
          width: 100vw;
        }
      }
    }
    `;
});

export const StyledSelectedAddress = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .selected-address {
    &__background-delivery-form {
      padding: 10px;
    }

    &__delivery-form {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &__background-address-payment {
      padding: 10px;
    }

    &__delivery-address {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
  }
`;

export const StyledChangeAddress = styled.div`
  .change-address {
    &__address-container {
      display: flex;
      flex-direction: column;
      gap: 15px;

      &__content {
        display: flex;
        align-items: center;
        gap: 15px;

        &__button {
          align-self: flex-start;
          flex-grow: 1;

          &__icon {
            margin-left: auto;
          }
        }

        &__radio {
          overflow: hidden;

          &__address {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &__buttons-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

export const StyledBillingPostalAddressForm = styled.div`
  .buttons {
    display: flex;
    gap: 10px;
    margin-top: 5px;
    align-items: center;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      flex-direction: column;
      ${`${StyledButton} { width: 100%; }`}
    }
  }

  .half_inputs {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
  }

  .billing-postal-address-form {
    &__add-address-button {
      margin-bottom: 0.75rem;
    }

    &__form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__address-type {
        display: flex;
        gap: 1.25rem;
      }
    }
  }
`;

export const StyledBillingAddress = styled.div`
  background-color: #ffffff;
  padding: 1.25rem;

  .billing {
    &__header {
      margin-bottom: 15px;
    }

    &__alert {
      margin-bottom: 15px;
    }

    &__selected-address-background {
      padding: 10px;
    }

    &__select-billing {
      display: flex;
      justify-content: space-between;

      &__button {
        align-self: flex-start;
      }
    }
  }
`;

export const StyledPayments = styled.div`
  padding: 1.25rem;

  .payments {
    &___payment-header {
      margin-bottom: 15px;
    }

    &__alert {
      margin-bottom: 15px;
    }

    &__payment-method-background {
      padding: 10px;
    }

    &__payment-method-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &__edit-payment-button {
      align-self: flex-start;
    }

    &__payments-methods-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      &__content {
        display: flex;
        flex-direction: column;
      }
    }

    &__payment-button {
      justify-self: end;
      align-self: end;
    }
  }
`;

export const StyledPaymentElement = styled.div<{
  paymentDisabled: boolean;
}>(({ paymentDisabled }) => {
  return `
    display: flex;
    gap: 15px;
    align-items: center;

    ${
      paymentDisabled
        ? `
            pointer-events: none;
            opacity: 0.5;
          `
        : ""
    }
    `;
});
