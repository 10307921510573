import React, { Fragment } from "react";
import { useTheme } from "styled-components";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../../../global/components/ImageWrapper/ImageWrapper";
import { formatPrice } from "../../../../helpers/helpers";
import { StyledOrderProductList } from "../../../../shared/components/domain/CartB2B/Order/OrderProductList";
import { ISummaryB2BDesktopMobile } from "../BoxSummaryB2B.types";
import { ColumnLabel } from "./ColumnLabel";
import { TableValue } from "./TableValue";

const DesktopSummaryB2B: React.FC<ISummaryB2BDesktopMobile> = ({
  messages,
  order,
  settings,
}) => {
  const {
    advanceSettings: { messages: globalMessages },
  } = useTheme() as IThemeState;

  const { basket_success_header_line_size, basket_success_header_line_color } =
    settings;
  return (
    <StyledOrderProductList
      gridTemplate="0.6fr 92px 1.3fr 1fr 0.7fr 0.7fr 1fr 1.5fr"
      lineSize={basket_success_header_line_size}
      lineColor={basket_success_header_line_color}
    >
      <ColumnLabel>
        {messages.basket_success_single_order_table_sku_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_image_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_product_name_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_product_added_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_netto_price_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_gross_price_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_netto_value_label}
      </ColumnLabel>
      <ColumnLabel>
        {messages.basket_success_single_order_table_gross_value_label}
      </ColumnLabel>
      {order.orderPositions?.map((position) => (
        <Fragment key={`${position.sku} - ${position.name}`}>
          <TableValue key={position.sku}>{position.sku}</TableValue>
          <div className="cartB2B__product-line-item">
            <ImageWrapper
              width={92}
              imageUrl={position.coverPhoto}
              seoDescription={position.name}
              imageFit
              imageAlignment={{
                horizontal: AlignmentHorizontalOption.CENTER,
                vertical: AlignmentVerticalOption.CENTER,
              }}
            />
          </div>
          <TableValue>{position.name}</TableValue>
          <TableValue>{`${position.quantity} ${
            position.unitOfMeasurementLabel ?? ""
          }`}</TableValue>
          <TableValue>
            {formatPrice(position.netValue, globalMessages["currencyShort"])}
          </TableValue>
          <TableValue>
            {formatPrice(position.grossValue, globalMessages["currencyShort"])}
          </TableValue>
          <TableValue>
            {formatPrice(
              position.totalNetValue,
              globalMessages["currencyShort"]
            )}
          </TableValue>
          <TableValue>
            {formatPrice(
              position.totalGrossValue,
              globalMessages["currencyShort"]
            )}
          </TableValue>
        </Fragment>
      ))}
    </StyledOrderProductList>
  );
};

export default DesktopSummaryB2B;
