import useTranslation from "next-translate/useTranslation";
import { useTheme } from "styled-components";
import { A11y, Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { checkSlidesPerView, isPortalSide } from "../../../helpers/helpers";
import {
  getPaginationStyle,
  isAutoplaying,
} from "../../../settingsPatterns/boxes/BoxSlider/StyledSlider.methods";
import { StyledSwiper } from "../../../settingsPatterns/elements/StyledSwiper/StyledSwiper";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import {
  getSortedElements,
  useFetchArticleList,
} from "../BoxArticleList.methods";
import { IBoxArticleListSliderProps } from "../BoxArticleList.types";

const BoxArticleListSlider = ({
  settings,
  content,
  editMode,
}: IBoxArticleListSliderProps) => {
  const { t } = useTranslation();
  const { articles } = useFetchArticleList(content, settings, editMode);
  const theme = useTheme() as IThemeState;
  const isMobile = useIsMobilePortal();

  if (articles?.length === 0)
    return (
      <div className="slider-container--empty">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );

  const enableLoop = () => {
    if (articles?.length) {
      return settings.article?.articlesPerSlide < articles?.length;
    }
  };

  return (
    <StyledSwiper
      observer={true}
      modules={[Navigation, A11y, Autoplay, Pagination]}
      slidesPerView={checkSlidesPerView(
        settings.article?.articlesPerSlide,
        isMobile ?? false
        // TODO: globalState with platform -> SSR user agent
      )}
      slidesPerGroup={settings.article?.articlesPerSlide}
      autoplay={
        editMode === false ? isAutoplaying(settings?.slidesRotate) : undefined
      }
      $settings={{
        elementsCount: settings.article?.articlesPerSlide,
        spacingHorizontal: settings.spacing.horizontal,
        spacingVertical: settings.spacing.vertical,
        theme: theme,
        paginationStyle: settings.sliderPaginationStyle,
      }}
      {...getPaginationStyle(settings.sliderPaginationStyle)}
      className="article-list__slider"
      loop={enableLoop()}
    >
      <div className="slider-container">
        {articles?.map((article) => (
          <SwiperSlide
            data-testid="slider-slide"
            key={article.id}
            className="article-list__slider__single-slide"
          >
            <div className="article-list__slider__single-slide__content">
              {getSortedElements(settings, article, theme)}
            </div>
          </SwiperSlide>
        ))}
      </div>
    </StyledSwiper>
  );
};

export default BoxArticleListSlider;
