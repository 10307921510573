import { GridPaginationOptions } from "@ecp-redux/dto/article.type";
import {
  BannersPositions,
  CountOfTemplatesInShake,
  HoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  OutlineOptions,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  LINK_STYLE_SETTING_PATTERN,
  MAIN_BUTTON_CONTENT_SETTING_PATTERN,
  TEXT_STYLE_SHOW_SETTING_PATTERN,
  TYPO_STYLE_SETTING_PATTERN,
} from "../../settingsPatterns/settingsPatterns.utils";
import {
  IProductAttribute,
  ProductButtonActions,
} from "../../shared/components/domain/Product/ProductModule/ProductModule.types";
import {
  IBoxSearchResultsContent,
  IBoxSearchResultsContentSettings,
  IBoxSearchResultsDisplaySettings,
  ProductSRPModuleElement,
} from "./BoxSearchResults.types";

const productElementInModule = [
  "sku",
  "productName",
  "actualPrice",
  "oldPrice",
  "netPrice",
  "rating",
  "delivery",
  "button",
] as ProductSRPModuleElement[];

export const BOX_SEARCH_RESULTS_DISPLAY_SETTINGS_PATTERN =
  (): IBoxSearchResultsDisplaySettings => ({
    grid: {
      spaceBetweenRows: 24,
      spaceBetweenProducts: 20,
    },
    module: {
      alignment: AlignmentHorizontalOption.LEFT,
      padding: { top: 0, right: 0, bottom: 0, left: 0 },
      spaceBetweenPhotoAndContent: 20,
      spaceBetweenTextElements: 8,
      background: { id: "color$$7", custom: "#000000" },
      border: {
        style: OutlineOptions.NONE,
        width: 10,
        color: { id: "color$$11", custom: "#000000" },
      },
      spaceBetweenSlides: 0,
    },
    pagination: GridPaginationOptions.DEFAULT,
    orderInModule: productElementInModule,
    marketingCampaign: {
      turnOn: false,
      positionOption: BannersPositions.SHAKE,
      fixPositionOrder: [],
      orderOption: BannersPositions.FIX,
      countOfTemplatesInShake: CountOfTemplatesInShake.ONE,
    },
  });

export const BOX_SEARCH_RESULTS_CONTENT_SETTINGS_PATTERN =
  (): IBoxSearchResultsContentSettings => ({
    productsPerRow: 3,
    productsOnPage: 36,
    showOnPage: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$1"),
    sorting: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$1"),
    pagination: TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$2"),
    product: {
      sku: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$2"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      productName: {
        link: LINK_STYLE_SETTING_PATTERN("link$$1"),
        text: TYPO_STYLE_SETTING_PATTERN("typo$$7"),
        show: true,
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      actualPrice: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$4", "color$$1"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      oldPrice: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$2"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      netPrice: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      rating: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$1"),
        filledPointsColor: { id: "color$$1", custom: "#000000" },
        unfilledPointsColor: { id: "color$$2", custom: "#000000" },
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      delivery: {
        ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$7", "color$$2"),
        prefix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
        suffix: {
          ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
          heading: "",
          spaceBetweenHeadingAndAttribute: 5,
        },
      },
      button: {
        ...MAIN_BUTTON_CONTENT_SETTING_PATTERN(),
        isCustomWidth: false,
        customWidth: 100,
        type: ProductButtonActions.ADD_TO_CART,
        url: "",
      },
      custom: {},
    },
    productPhoto: {
      height: 364,
      hover: HoverEffects.NONE,
    },
    badge: {
      show: true,
    },
    favorites: {
      show: true,
    },
    variants: [],
  });

export const BOX_SRP_PRODUCT_SLIDER_CUSTOM_ATTRIBUTE_PATTERN =
  (): IProductAttribute => ({
    ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1"),
    prefix: {
      ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
      heading: "",
      spaceBetweenHeadingAndAttribute: 5,
    },
    suffix: {
      ...TEXT_STYLE_SHOW_SETTING_PATTERN("typo$$1", "color$$1", false),
      heading: "",
      spaceBetweenHeadingAndAttribute: 5,
    },
  });

export const BOX_SEARCH_RESULTS_CONTENT_PATTERN =
  (): IBoxSearchResultsContent => ({
    showOnPageText: "Show on page",
    sortingText: "Sort by:",
    paginationText: "Pagination (to remove?)",
    productButtonText: "Add to cart",
    ratingText: "Reviews",
  });
