import React, { useState } from "react";
import * as myData from "@ecp-redux/api/myData";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { useAlertByCodes } from "../../redux/slices/alertsSlice";
import Alert from "../../shared/components/Alert/Alert";
import { ReactComponent as PenIcon } from "../../shared/icons/pen.svg";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { StyledBoxMyAccountMyDataWrapper } from "./BoxMyAccountMyData.styled";
import { TBoxMyAccountMyDataProps } from "./BoxMyAccountMyData.types";
import CustomerData from "./CustomerData";
import EditCustomerData from "./EditCustomerData";

const BoxMyAccountMyData: React.FC<TBoxMyAccountMyDataProps> = ({
  messages,
  settings,
}: TBoxMyAccountMyDataProps) => {
  const [editMode, setEditMode] = useState(false);
  const { closeAlert } = useAlertByCodes([
    "edit_customer_data_validation_error",
  ]);
  const { data } = myData.useGetCustomerDataQuery();
  !editMode && closeAlert && closeAlert();
  return (
    <StyledBoxMyAccountMyDataWrapper
      className="my-account-data-container"
      titleSpacing={settings.customer_data_title_spacing}
    >
      {!editMode && (
        <Alert
          alertsCodes={["c_c_data"]}
          backgroundColor={settings.customer_data_success_info_background_color}
          textColor={settings.customer_data_success_info_text_color}
          typography={settings.customer_data_success_info_typo}
          closeable
          lifetime={5000}
        />
      )}
      {editMode && (
        <Alert
          alertsCodes={["edit_customer_data_validation_error"]}
          backgroundColor={settings.edit_customer_data_error_background_color}
          textColor={settings.edit_customer_data_error_text_color}
          typography={settings.edit_customer_data_error_typo}
          closeable
          lifetime={5000}
        />
      )}

      <div className="my-account-data-container__data-info">
        <StyledText
          className="my-account-data-container__data-info__title grow"
          $settings={{
            font: editMode
              ? settings.customer_data_edit_title
              : settings.customer_data_title_typo,
            text: {
              color: editMode
                ? settings.customer_data_edit_title_color
                : settings.customer_data_title_color,
            },
          }}
          alignment={
            editMode
              ? settings.customer_data_edit_title_align.toLowerCase()
              : settings.customer_data_title_align.toLowerCase()
          }
          show={isNotEmptyMessage(messages.customer_data_title)}
        >
          {messages.customer_data_title}
        </StyledText>

        {!editMode && (
          <button
            onClick={() => setEditMode((prev) => !prev)}
            className="my-account-data-container__data-info__pen-icon"
          >
            <PenIcon />
          </button>
        )}
      </div>
      {editMode ? (
        <EditCustomerData
          customerData={data}
          onCancel={() => {
            setEditMode(false);
          }}
        />
      ) : (
        <CustomerData customerData={data} />
      )}
    </StyledBoxMyAccountMyDataWrapper>
  );
};

export default BoxMyAccountMyData;
