import {
  CustomAttributesLineDetection,
  ISrpTransformResponse,
} from "@ecp-redux/dto/searchResults.types";
import {
  HoverEffects,
  IBorder,
  IElementButton,
  IElementLinkWithVisibility,
  IElementTextContent,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  ISpacing,
  TColorReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { TVariant } from "../ProductVariants/ProductVariants.types";

export enum ProductButtonActions {
  GO_TO_PRODUCT = "GO_TO_PRODUCT",
  GO_TO_URL = "GO_TO_URL",
  ADD_TO_CART = "ADD_TO_CART",
}

export interface IProductButton extends IElementButton {
  isCustomWidth: boolean;
  customWidth: number;
  type: ProductButtonActions;
  url: string;
}

export interface IProductAttribute extends IElementTextContent {
  prefix: IPrefixSuffix;
  suffix: IPrefixSuffix;
}

export interface IProductModuleRatingStyle extends IProductAttribute {
  filledPointsColor: TColorReadoutValue;
  unfilledPointsColor: TColorReadoutValue;
}

export interface IPrefixSuffix extends IElementTextContent {
  heading: string;
  spaceBetweenHeadingAndAttribute: number;
}

export interface IProductName extends IElementLinkWithVisibility {
  prefix: IPrefixSuffix;
  suffix: IPrefixSuffix;
}

export interface IProductModuleElementsStyle {
  sku: IProductAttribute;
  productName: IProductName;
  actualPrice: IProductAttribute;
  oldPrice: IProductAttribute;
  netPrice: IProductAttribute;
  rating: IProductModuleRatingStyle;
  delivery: IProductAttribute;
  button: IProductButton;
  custom: { [key: string]: IProductAttribute };
}

export interface IProductModuleSettings {
  alignment: AlignmentHorizontalOption;
  padding: ISpacing;
  spaceBetweenPhotoAndContent: number;
  spaceBetweenTextElements: number;
  background: TColorReadoutValue;
  border: Omit<IBorder, "radius">;
  spaceBetweenSlides: number;
}

export interface IProductModulePhoto {
  height: number;
  hover: HoverEffects | ProductHoverEffects;
}

export interface IPrefixSuffixContainerProps {
  productSettings:
    | IProductName
    | IProductModuleRatingStyle
    | IProductAttribute
    | undefined;
  child: React.ReactNode;
}

export type TCustomElementsDetected = {
  delivery: boolean;
  deliveryTime: boolean;
  tax: boolean;
  badges: boolean;
  pools: boolean;
  default: boolean;
};

export const defaultCustomSelected: TCustomElementsDetected = {
  badges: false,
  default: false,
  delivery: false,
  deliveryTime: false,
  pools: false,
  tax: false,
};

interface IProductAttributesDisplay {
  product: ISrpTransformResponse;
  productSettings: Partial<IProductModuleElementsStyle>;
  boxId: number;
  buttonText: string;
  ratingText: string;
  variantSettings?: TVariant;
  attributesLineDetection: CustomAttributesLineDetection;
}

export interface IAttributesInRowProps extends IProductAttributesDisplay {
  attributesRows: string[];
  orderInModule: string[];
  moduleSettings: IProductModuleSettings;
}

export interface IProductAttributesDisplayProps
  extends IProductAttributesDisplay {
  attribute: string;
  safeIndex: number;
}

export interface IAttributesInColumnProps extends IProductAttributesDisplay {
  orderInModule: string[];
  moduleSettings: IProductModuleSettings;
}
