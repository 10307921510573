import React from "react";
import { useTheme } from "styled-components";
import {
  IThemeState,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { IIcon } from "./Icon.types";

const HamburgerMenuIcon: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
  className,
}: IIcon): JSX.Element => {
  const { colorPalette } = useTheme() as IThemeState;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      onClick={onClick}
      className={className}
    >
      <path
        stroke={
          fill ? convertColorIdToHex(fill as TColorId, colorPalette) : "#000000"
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 1h16M1 7h16M1 13h16"
      ></path>
    </svg>
  );
};

HamburgerMenuIcon.defaultProps = {
  width: "16",
  height: "12",
  fill: "#000000",
  viewBox: "0 0 18 14",
};

export default HamburgerMenuIcon;
