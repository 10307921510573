import { useCallback, useState } from "react";
import { TableProps } from "@ecp-boxes/shared/components/Table/Table.types";
import * as getDataTable from "@ecp-redux/api/getDataTable";
import { BoxTableDataEndpointType } from "@ecp-redux/dto/getDataTable";
import Table from "../../shared/components/Table/Table";
import { StyledTableCell } from "../../shared/components/Table/TableCell";
import {
  BoxTableDataContentScheme,
  BoxTableDataContentSettingsScheme,
  BoxTableDataDisplaySettingsScheme,
  IBoxTableDataProps,
  TableDataColumnType,
} from "./BoxTableData.types";
import TableDataImageCell from "./elements/TableDataImageCell";
import TableDataTextCell from "./elements/TableDataTextCell";

interface ColumnTypeComponentsProps {
  value: string | number | undefined;
  cellSettings: IBoxTableDataProps["contentSettings"]["tableAttributes"][""];
  columnKey: string;
}

const columnTypeComponents = ({
  value,
  cellSettings,
  columnKey,
}: ColumnTypeComponentsProps): TableProps["rows"]["0"][""] => {
  if (value === undefined) {
    return {
      component: (isOdd) => (
        <StyledTableCell
          isOdd={isOdd}
          tableCellStyleId={cellSettings.dataStyle}
        >
          -
        </StyledTableCell>
      ),
      isEmpty: true,
    };
  }

  switch (cellSettings.columnType) {
    case TableDataColumnType.IMAGE:
      return {
        component: (isOdd, rowHeight) => (
          <TableDataImageCell
            tableCellStyleId={cellSettings.dataStyle}
            imageUrl={value as string}
            seoDescription={columnKey}
            imageFit={cellSettings.imageFit}
            imageAlignment={cellSettings.alignment}
            width={cellSettings.width}
            rowHeight={rowHeight}
            isOdd={isOdd}
          />
        ),
        isEmpty: false,
      };

    case TableDataColumnType.TEXT:
      return {
        component: (isOdd) => (
          <TableDataTextCell
            prefix={{
              label: cellSettings.prefixText,
              textColor: cellSettings.prefixColor,
              textStyle: cellSettings.prefixTextStyle,
              show: cellSettings.prefix,
            }}
            suffix={{
              label: cellSettings.suffixText,
              textColor: cellSettings.suffixColor,
              textStyle: cellSettings.suffixTextStyle,
              show: cellSettings.suffix,
            }}
            tableCellStyleId={cellSettings.dataStyle}
            spaceBetweenPrefixSuffix={cellSettings.spaceBetweenPrefixSuffix}
            isOdd={isOdd}
          >
            {value}
          </TableDataTextCell>
        ),
        isEmpty: false,
      };

    default:
      return {
        component: (isOdd) => (
          <StyledTableCell
            isOdd={isOdd}
            tableCellStyleId={cellSettings.dataStyle}
          >
            {value}
          </StyledTableCell>
        ),
        isEmpty: false,
      };
  }
};

const BoxTableData: React.FC<IBoxTableDataProps> = (props) => {
  const content = BoxTableDataContentScheme.parse(props.content);
  const contentSettings = BoxTableDataContentSettingsScheme.parse(
    props.contentSettings
  );
  const display = BoxTableDataDisplaySettingsScheme.parse(
    props.displaySettings
  );

  const [pagination, setPagination] = useState<{
    page: number;
    pageSize: number | undefined;
  }>({
    page: 0,
    pageSize: undefined,
  });

  const setPaginationState = useCallback(
    (page?: number, pageSize?: number) => {
      page !== undefined && setPagination({ ...pagination, page });
      pageSize !== undefined && setPagination({ ...pagination, pageSize });
    },
    [pagination, setPagination]
  );

  const [sort, setSort] = useState<{ key: string; direction: string }>({
    key: "",
    direction: "none",
  });

  const { data } = getDataTable.useGetTableDataQuery(
    {
      projection: content.columnsOrder,
      page: {
        number: pagination.page,
        size: pagination.pageSize ?? display.pagination.afterRows,
      },
      filter: [],
      sort:
        sort.key !== "" && sort.direction !== "none"
          ? [
              {
                name: sort.key,
                desc: sort.direction === "descending",
              },
            ]
          : [],
      caseSensitive: false,
      endpointType: content.contentType,
    },
    {
      skip:
        content.contentType === BoxTableDataEndpointType.NONE ||
        content.columnsOrder.length === 0 ||
        display.pagination.afterRows === 0,
    }
  );

  if (data === undefined) return null;

  return (
    <Table
      columns={content.columnsOrder.map((e) => ({
        key: e,
        name: contentSettings.tableAttributes[e].translationText,
        sortable: display.sorting.show,
        width: contentSettings.tableAttributes[e].width,
        headerCellStyleId: contentSettings.tableAttributes[e].headerStyle,
      }))}
      rows={data.result.map((row, rowIndex) =>
        content.columnsOrder.reduce<TableProps["rows"]["0"]>(
          (acc, key) => ({
            ...acc,
            [key]: columnTypeComponents({
              cellSettings: contentSettings.tableAttributes[key],
              columnKey: key,
              value: row[key],
            }),
          }),

          {}
        )
      )}
      onSort={(key, direction) => setSort({ key, direction })}
      tableSettings={{
        table: display,
        pagination: display.pagination,
        sorting: display.sorting,
      }}
      pagination={
        display.pagination.show && data.page
          ? {
              page: pagination.page,
              pageSize: pagination.pageSize ?? display.pagination.afterRows,
              onPageChange: ({ page, pageSize }) => {
                setPaginationState(page, pageSize);
              },
              showOnPageLabel: content.paginationLabel,
              fromText: "z",
              initialPageSize: display.pagination.afterRows,
              total: data.page.total,
            }
          : undefined
      }
    />
  );
};

export default BoxTableData;
