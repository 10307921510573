import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertInputIdToValues } from "../../shared/components/Input/StyledInput/StyledInput.methods";
import { IBoxAddToCartQuantityContentSettings } from "./BoxAddToCartQuantity.types";

export const StyledBoxAddToCartQuantityWrapper = styled.div<{
  $settings: IBoxAddToCartQuantityContentSettings;
}>(({ $settings, theme }) => {
  const inputSettings = convertInputIdToValues(
    $settings.add_to_cart_quantity_input_style,
    theme.globalObjects?.inputs
  );

  return `
              display: flex;
              justify-content: ${
                $settings.add_to_cart_quantity_aligment ??
                AlignmentHorizontalOption.CENTER
              };


              div {
                display: flex;
                width: ${inputSettings.width}%;
                align-items: center;
                gap: ${$settings.add_to_cart_spacing ?? "0px"};
              }

              input {
                text-align: center;
              }
              
              .add-to-cart-quantity__quantity-input {
                padding-left: 0px;
              }

              .add-to-cart-quantity__minus-button {
                white-space: nowrap;
              }

              .add-to-cart-quantity__plus-button {
                white-space: nowrap;
              }
          `;
});
