import {
  ChangeEvent,
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { StyledLinkWrapper } from "../../global/components/LinkWrapper/LinkWrapper";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { StyledInputValidation } from "../../shared/components/Input/StyledInput/StyledInput";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxContactFormMessages,
  IBoxContactFormSettings,
} from "./BoxContactForm.types";
import { StyledUploadFilesManager } from "./StyledUploadFilesManager.styled";
import { getFilesSizeInMb } from "./UploadFilesManager.methods";

const MAX_FILES_SIZE = 10;

interface IUploadFilesManagerProps {
  setList: (filesList: File[]) => void;
  resetFilesList: boolean;
}

const UploadFilesManager: React.FC<IUploadFilesManagerProps> = ({
  setList,
  resetFilesList,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxContactFormMessages,
    IBoxContactFormSettings
  >();
  const [filesList, setFilesList] = useState<File[] | undefined>();
  const [validationError, setValidationError] = useState(false);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (filesList && filesList?.length >= 5) return;

    !filesList
      ? setFilesList([e.target.files?.[0]])
      : setFilesList([...filesList, e.target.files?.[0]]);
  };

  const handleDeleteButtonClick = (
    e: SyntheticEvent<HTMLSpanElement, MouseEventHandler>
  ) => {
    if (!filesList) return;
    setFilesList(
      [...filesList].filter((_, idx) => idx !== +e.currentTarget.id)
    );
    setValidationError(false);
  };

  useEffect(() => {
    if (!filesList) return;

    const currentFilesSizes = getFilesSizeInMb(filesList);

    if (currentFilesSizes > MAX_FILES_SIZE) {
      filesList && setFilesList(filesList.slice(0, -1));
      setValidationError(true);
    }

    filesList.map((file) => {
      if (
        !file.name.endsWith("doc") &&
        !file.name.endsWith("pdf") &&
        !file.name.endsWith("jpg") &&
        !file.name.endsWith("png") &&
        !file.name.endsWith("gif")
      ) {
        filesList && setFilesList(filesList.slice(0, -1));
        setValidationError(true);
      }
    });

    resetFilesList && setFilesList([]);

    setList(filesList);
  }, [filesList?.length, resetFilesList]);

  return (
    <>
      <StyledUploadFilesManager
        gapBetweenFiles={settings.contact_form_upload_file_gap}
      >
        <StyledText
          className="complaint-form__title"
          $settings={{
            font: settings.contact_form_heading_typography,
            text: { color: settings.contact_form_heading_color },
          }}
          show={isNotEmptyMessage(messages.contact_form_heading_upload_photo)}
        >
          {messages.contact_form_heading_upload_photo}
        </StyledText>
        {(!filesList || (filesList && filesList?.length < 5)) && (
          <>
            <label
              className="complaint-form__upload-file-button__label"
              htmlFor="attachment"
            >
              <StyledButton
                className="complaint-form__upload-file-button"
                renderAs="span"
                type="submit"
                $settings={settings.contact_form_upload_file_button_style}
                show={isNotEmptyMessage(
                  messages.contact_form_upload_file_button
                )}
              >
                {messages.contact_form_upload_file_button}
              </StyledButton>
            </label>
            <input
              data-testid="fileUploader"
              id="attachment"
              key={filesList?.length}
              className="complaint-form__file-uploader__input"
              type="file"
              accept=".doc,.pdf,.png,.gif,.jpg,.docx"
              name="files"
              onChange={handleOnChange}
              onClick={() => setValidationError(false)}
            />
          </>
        )}
        {filesList &&
          Array.from(filesList).map((file, idx) => (
            <div
              className="complaint-form__file-title-container"
              key={file.name + idx}
            >
              <StyledText
                data-testid="fileTitle"
                className="complaint-form__file-title"
                $settings={{
                  font: settings.contact_form_upload_file_name_typography,
                  text: { color: settings.contact_form_upload_file_name_color },
                }}
              >
                {`${file.name}`}
              </StyledText>

              <StyledLinkWrapper
                renderAs="span"
                id={idx}
                className="complaint-form__file-delete"
                $linkStyle={settings.contact_form_upload_file_delete_link_style}
                $fontStyle={settings.contact_form_upload_file_delete_typography}
                onClick={handleDeleteButtonClick}
              >
                {messages.contact_form_upload_file_delete}
              </StyledLinkWrapper>
            </div>
          ))}
      </StyledUploadFilesManager>
      {validationError && (
        <StyledInputValidation
          $settings={settings.contact_form_input_style}
          role="alert"
          className="complaint-form__validation"
        >
          {messages.contact_form_upload_file_error}
        </StyledInputValidation>
      )}
    </>
  );
};

export default UploadFilesManager;
