import React from "react";
import { useRouter } from "next/router";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as cartAPI from "@ecp-redux/api/cart";
import { yupResolver } from "@hookform/resolvers/yup";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import htmlToReact from "../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import { isEmail } from "../../../helpers/validators";
import { InputText } from "../../../shared/components/Input/Inputs";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledLinkContainer } from "../BoxLogin.styled";
import { IBoxLoginMessages, IBoxLoginSettings } from "../BoxLogin.types";

export const isFetchBaseQueryErrorType = (
  error: any
): error is FetchBaseQueryError => "status" in error;

interface IUserEmailForm {
  email: string;
}

const loginDefaultValues: IUserEmailForm = {
  email: "",
};

interface IShoppingWithoutRegisterProps {
  onAddEmailFail: () => void;
}

const ShoppingWithoutRegister: React.FC<IShoppingWithoutRegisterProps> = ({
  onAddEmailFail,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxLoginMessages,
    IBoxLoginSettings
  >();
  const [addEmailWithoutRegister] =
    cartAPI.usePostCartShoppingWithoutRegisterMutation();
  const router = useRouter();

  const validationSchema = yup.object().shape({
    email: isEmail(
      messages.login_form_empty_verification_email,
      messages.login_form_syntax_verification_email
    ),
  });

  const formOptions = useForm<IUserEmailForm>({
    defaultValues: loginDefaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, control } = formOptions;

  const onSubmit = async (data: IUserEmailForm) => {
    const addEmail = await addEmailWithoutRegister(data);

    const errorCode =
      "error" in addEmail &&
      isFetchBaseQueryErrorType(addEmail?.error) &&
      (
        addEmail.error.data as {
          code: number;
          message: string;
          serviceName: string;
        }
      ).code;
    if (errorCode === 9003) return onAddEmailFail();

    router.replace(settings.shopping_step_2_url);
  };

  return (
    <div
      data-testid="ShoppingWithoutCartBox"
      className="shopping-without-register-container"
    >
      <StyledText
        $settings={{
          font: settings.login_form_paragraph_typo,
          text: { color: settings.login_form_title_color },
        }}
        alignment={settings.login_form_title_align?.toLowerCase()}
        className="shopping-without-register-container__subtitle"
        show={isNotEmptyMessage(messages.shopping_without_register_subtitle)}
      >
        {messages.shopping_without_register_subtitle}
      </StyledText>

      <FormProvider {...formOptions}>
        <form
          onSubmit={handleSubmit((d) => onSubmit(d))}
          className="shopping-without-register-container__form"
        >
          <InputText
            name="email"
            control={control}
            placeholder={messages.login_form_placeholder_email}
            label={messages.login_form_heading_email}
            $settings={settings.login_form_input_style}
          />
          <StyledLinkContainer
            remindPasswordPosition={
              settings.login_form_remind_password_position
            }
          >
            <div>
              <StyledText
                $settings={{
                  font: settings.login_form_privacy_policy_typo,
                  text: { color: settings.login_form_privacy_policy_color },
                }}
                className="shopping-without-register-container__privacy-policy"
                show={isNotEmptyMessage(messages.login_form_privacy_policy)}
              >
                <p>
                  {htmlToReact(
                    messages.login_form_privacy_policy,
                    settings.login_form_link_style
                  )}
                </p>
              </StyledText>
            </div>
          </StyledLinkContainer>
          <StyledButton
            renderAs="button"
            type="submit"
            role="submit"
            $settings={settings.login_form_login_button_style}
            className="shopping-without-register-container__submit-button"
            show={isNotEmptyMessage(
              messages.shopping_without_register_next_button
            )}
          >
            {messages.shopping_without_register_next_button}
          </StyledButton>
        </form>
      </FormProvider>
    </div>
  );
};

export default ShoppingWithoutRegister;
