import React from "react";
import { IIcon } from "./icons.types";

const ChevronRightIcon: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
}: IIcon): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.616117 12.8839C0.127962 12.3957 0.127962 11.6043 0.616117 11.1161L4.73223 7L0.616117 2.88388C0.127961 2.39573 0.127961 1.60427 0.616117 1.11612C1.10427 0.627961 1.89573 0.627961 2.38388 1.11612L7.38388 6.11612C7.87204 6.60427 7.87204 7.39573 7.38388 7.88388L2.38388 12.8839C1.89573 13.372 1.10427 13.372 0.616117 12.8839Z"
        fill={fill}
      />
    </svg>
  );
};

ChevronRightIcon.defaultProps = {
  width: "8",
  height: "14",
  fill: "#293740",
  viewBox: "0 0 8 14",
};

export default ChevronRightIcon;
