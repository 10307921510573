import { TGetContractorsResponse } from "../dto/contractors.type";
import { API, api } from "./api";

export const contractors = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractors: builder.query<TGetContractorsResponse, void>({
      query: () => API.getContractors.endpoint(),
      providesTags: ["CONTRACTORS"],
    }),
    putContractors: builder.mutation<void, void>({
      query: () => {
        return {
          url: API.putContractors.endpoint(),
          method: "PUT",
        };
      },
      invalidatesTags: ["CONTRACTORS"],
    }),
  }),
});

export const { useGetContractorsQuery, usePutContractorsMutation } =
  contractors;

export const { getContractors } = contractors.endpoints;
