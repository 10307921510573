import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  LinkPlugin,
  PlateFloatingLink,
  RenderAfterEditable,
  createAlignPlugin,
  createBasicMarksPlugin,
  createBlockquotePlugin,
  createComboboxPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createFontSizePlugin,
  createImagePlugin,
  createIndentPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
} from "@udecode/plate";
import { createPluginFactory } from "@udecode/plate-common";
import { CustomLeaf } from "../CustomLeaf";
import { MyPlatePlugin, MyValue, createMyPlugins } from "../plateTypes";
import { plateUI } from "../plateUI";
import { createHTMLPlugin } from "./htmlPlugin";

export const MARK_TYPO = "typography";
export const MARK_LINK_STYLE = "linkStyle";

export const createTypographyPlugin = createPluginFactory({
  key: MARK_TYPO,
  inject: {
    props: {
      nodeKey: MARK_TYPO,
    },
  },
});

export const createLinkStylePlugin = createPluginFactory({
  key: MARK_LINK_STYLE,
  inject: {
    props: {
      nodeKey: MARK_LINK_STYLE,
    },
  },
});

export const linkPlugin: Partial<MyPlatePlugin<LinkPlugin>> = {
  renderAfterEditable: PlateFloatingLink as RenderAfterEditable<MyValue>,
};

export const plugins = createMyPlugins(
  [
    createParagraphPlugin(),
    createBlockquotePlugin(),
    createListPlugin(),
    createIndentPlugin({
      inject: {
        props: {
          validTypes: [ELEMENT_BLOCKQUOTE, ELEMENT_IMAGE, ELEMENT_PARAGRAPH],
        },
      },
    }), // indent, outdent
    createLinkPlugin(linkPlugin),
    createImagePlugin(), //this is the plugin that allows images to be added
    createComboboxPlugin(), //this is the plugin that allows you to add links
    createBasicMarksPlugin(), // marks - bold, italic, underline, strikethrough, subscript, superscript
    createAlignPlugin({
      inject: {
        props: {
          validTypes: [ELEMENT_BLOCKQUOTE, ELEMENT_PARAGRAPH],
        },
      },
    }),
    createFontColorPlugin({
      component: CustomLeaf,
      isLeaf: true,
    }),
    createFontBackgroundColorPlugin({
      component: CustomLeaf,
      isLeaf: true,
    }),
    createFontSizePlugin(),
    createTypographyPlugin({ component: CustomLeaf, isLeaf: true }),
    createLinkStylePlugin({ component: CustomLeaf, isLeaf: true }),
    createHTMLPlugin(),
  ],
  {
    components: plateUI,
  }
);
