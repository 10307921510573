function Error({ statusCode }: { statusCode: number | string }) {
  return <p id="blank_page">System error page - {statusCode}</p>;
}

Error.getInitialProps = ({ res, err }: any) => {
  const statusCode = res
    ? res.statusCode
    : err
    ? err.statusCode
    : "unknown error (empty statusCode)";
  return { statusCode };
};

export default Error;
