import { useMemo } from "react";
import * as creditLimits from "@ecp-redux/api/creditLimits";
import * as customers from "@ecp-redux/api/customers";
import * as deliverySchedule from "@ecp-redux/api/deliverySchedule";
import * as logisticsMinimum from "@ecp-redux/api/logisticsMinimum";
import * as payers from "@ecp-redux/api/payers";
import * as settlement from "@ecp-redux/api/settlement";
import { ISettlementResponse } from "@ecp-redux/api/settlement";
import { EndpointType } from "@ecp-redux/dto/customers.types";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { ProductAttributeElement } from "../BoxProductAttributes/BoxProductAttributes.types";
import {
  BOX_GET_DATA_CONTENT_SETTINGS,
  BOX_GET_DATA_DISPLAY_SETTINGS,
  getCurrentCreditLimitsSingleResultBody,
  getCurrentDeliveryScheduleSingleResultBody,
  getCurrentSingleResultBody,
} from "./BoxGetData.pattern";
import { IBoxGetDataProps } from "./BoxGetData.types";

const useBoxGetData = (props: IBoxGetDataProps) => {
  const {
    content: { endpoint, selections },
  } = props;

  const contentSettings = usePrepareSettings({
    props: props.contentSettings,
    settingsPattern: BOX_GET_DATA_CONTENT_SETTINGS(),
  }).settings;

  const displaySettings = usePrepareSettings({
    props: props.displaySettings,
    settingsPattern: BOX_GET_DATA_DISPLAY_SETTINGS(),
  }).settings;

  const { data: customerData } = customers.usePostCustomersSingleResultQuery(
    {
      endpointType: endpoint,
      body: getCurrentSingleResultBody(selections),
    },
    {
      skip:
        selections.length === 0 || endpoint !== EndpointType.CUSTOMER_DETAILS,
    }
  );

  const { data: payerData } = payers.usePostPayerSingleResultQuery(
    {
      endpointType: endpoint,
      body: getCurrentSingleResultBody(selections),
    },
    {
      skip: selections.length === 0 || endpoint !== EndpointType.PAYERS,
    }
  );

  const { data: creditLimitsData } =
    creditLimits.usePostCreditLimitsSingleResultQuery(
      {
        ...getCurrentCreditLimitsSingleResultBody(selections),
      },
      {
        skip: selections.length === 0 || endpoint !== EndpointType.CREDIT_LIMIT,
      }
    );
  const { data: settlementData } = settlement.usePostSettlementQuery(
    {
      ...getCurrentCreditLimitsSingleResultBody(selections),
    },
    {
      skip: selections.length === 0 || endpoint !== EndpointType.SETTLEMENT,
    }
  );
  const { data: logisticsMinData } =
    logisticsMinimum.usePostLogisticMinSingleResultQuery(
      {
        ...getCurrentCreditLimitsSingleResultBody(selections),
      },
      {
        skip:
          selections.length === 0 ||
          endpoint !== EndpointType.LOGISTICS_MINIMUM,
      }
    );

  const currency = creditLimitsData?.result["currency"] ?? "";

  const isEmptyObject = (obj: Record<string, any>): boolean =>
    Object.keys(obj).length === 0 && obj.constructor === Object;

  const flatSettlementData = (
    settlement: ISettlementResponse | undefined,
    attrId: string
  ): string | undefined => {
    if (settlement === undefined) return undefined;
    if (isEmptyObject(settlement.result)) return undefined;
    const currentSettlement = settlement.result[attrId];
    if (currentSettlement === undefined || currentSettlement === null)
      return undefined;
    const value = Object.values(currentSettlement);
    const currency = Object.keys(currentSettlement);

    return value.map((v, index) => `${v} ${currency[index]}`).join(", ");
  };

  const getEndpointData = (attributeId: string) => {
    return (
      (
        customerData ??
        payerData ??
        creditLimitsData ??
        logisticsMinData ??
        deliveryScheduleData
      )?.result?.[attributeId] ??
      flatSettlementData(settlementData, attributeId) ??
      null
    );
  };

  const { data: deliveryScheduleData } =
    deliverySchedule.usePostDeliveryScheduleSingleResultQuery(
      {
        ...getCurrentDeliveryScheduleSingleResultBody(selections),
      },
      {
        skip:
          selections.length === 0 ||
          endpoint !== EndpointType.DELIVERY_SCHEDULE,
      }
    );

  const memoizedData = useMemo(
    () =>
      props.content.selections.map(
        (
          selection
        ): Omit<ProductAttributeElement, "activeLink" | "attributeIcon"> => ({
          ...selection,
          value: getEndpointData(selection.attributeId),
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      customerData,
      payerData,
      creditLimitsData,
      deliveryScheduleData,
      settlementData,
      logisticsMinData,
      selections,
    ]
  );

  return {
    contentSettings,
    displaySettings,
    dataAttributes: memoizedData,
    currency: currency,
  };
};

export default useBoxGetData;
