import ProductAttributesDisplay from "./ProductAttributesDisplay";
import { isPromotion } from "./ProductModule.methods";
import { StyledContentProductWrapper } from "./ProductModule.styled";
import { IAttributesInColumnProps } from "./ProductModule.types";

const AttributesInColumn: React.FC<IAttributesInColumnProps> = (props) => {
  const {
    product,
    productSettings,
    orderInModule,
    boxId,
    variantSettings,
    buttonText,
    ratingText,
    moduleSettings,
    attributesLineDetection,
  } = props;

  return (
    <StyledContentProductWrapper
      className="product-module__content-container"
      spaceBetweenTextElements={moduleSettings.spaceBetweenTextElements}
      alignment={moduleSettings.alignment}
      button={{
        custom: productSettings.button?.isCustomWidth || false,
        customWidth: productSettings.button?.customWidth || 0,
      }}
    >
      {isPromotion(
        orderInModule,
        product.price.oldPrice,
        product.price.actualPrice
      ).map((orderName, safeIndex) => {
        return (
          <ProductAttributesDisplay
            key={orderName}
            attribute={orderName}
            productSettings={productSettings}
            product={product}
            boxId={boxId}
            safeIndex={safeIndex}
            buttonText={buttonText}
            ratingText={ratingText}
            variantSettings={variantSettings}
            attributesLineDetection={attributesLineDetection}
          />
        );
      })}
    </StyledContentProductWrapper>
  );
};

export default AttributesInColumn;
