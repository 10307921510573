import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { SelectedGoToOption } from "../../contentPatterns.types";
import { MAIN_BUTTON_CONTENT_SETTING_PATTERN } from "../../settingsPatterns.utils";
import {
  IBoxButtonContent,
  IBoxButtonContentSettings,
} from "./StyledButton.types";

export const BOX_BUTTON_CONTENT_SETTINGS_PATTERN =
  (): IBoxButtonContentSettings => ({
    buttonAlignment: AlignmentHorizontalOption.CENTER,
    buttonStyle: {
      id: MAIN_BUTTON_CONTENT_SETTING_PATTERN().id,
      custom: MAIN_BUTTON_CONTENT_SETTING_PATTERN().custom,
    },
  });

export const BOX_BUTTON_CONTENT_PATTERN = (): IBoxButtonContent => ({
  title: "Button",
  buttonLabel: "Button",
  buttonLink: {
    showLinkOptions: false,
    selectedGoToOption: SelectedGoToOption.GO_TO_PAGE,
    goToRowSetting: {
      rowId: null,
    },
    goToPageSetting: {
      link: "",
      openInNewTab: false,
    },
  },
});

export const BOX_BUTTON_PATTERN = () => ({
  ...BOX_BUTTON_CONTENT_SETTINGS_PATTERN(),
  ...BOX_BUTTON_CONTENT_PATTERN(),
});
