import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as myData from "@ecp-redux/api/myData";
import { yupResolver } from "@hookform/resolvers/yup";
import { isNotEmptyMessage } from "../../helpers/isNotEmptyMessage";
import { isPhone, maxLength } from "../../helpers/validators";
import { useAddAlert } from "../../redux/slices/alertsSlice";
import { InputPhone, InputText } from "../../shared/components/Input/Inputs";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import { StyledButtonsContainer } from "./BoxMyAccountMyData.styled";
import {
  IBoxMyAccountMyDataMessages,
  IBoxMyAccountMyDataSettings,
  TEditCustomerDataProps,
} from "./BoxMyAccountMyData.types";

export interface IMyDataForm {
  firstName: string;
  phone: string;
  lastName: string;
  email?: string;
}

const EditCustomerData: React.FC<TEditCustomerDataProps> = ({
  customerData,
  onCancel,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyDataMessages,
    IBoxMyAccountMyDataSettings
  >();
  const { addAlert } = useAddAlert();

  const myDataDefaultValues: IMyDataForm = {
    email: customerData?.email,
    firstName: customerData?.firstName ?? "",
    lastName: customerData?.lastName ?? "",
    phone: customerData?.phone ?? "",
  };

  const validationSchema = yup.object().shape({
    firstName: maxLength(
      20,
      messages.customer_data_short_input_length_exceeded
    ),
    lastName: maxLength(20, messages.customer_data_short_input_length_exceeded),
    phone: isPhone(messages.customer_data_verification_phone),
  });

  const [postEditMyDataForm] = myData.usePutMyDataFormMutation();

  const formMethods = useForm<IMyDataForm>({
    defaultValues: myDataDefaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
  });

  const { handleSubmit, control } = formMethods;

  const onSubmit = async (data: IMyDataForm) => {
    const parseData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
    };

    try {
      await postEditMyDataForm(parseData).unwrap();

      addAlert({
        code: "c_c_data",
        message: messages.customer_data_edit_success,
      });
      onCancel();
    } catch (error) {
      addAlert({
        code: "edit_customer_data_validation_error",
        message: messages.edit_customer_data_validation_error,
      });
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit((d) => onSubmit(d))}
        autoComplete="off"
        className="edit-customer-data__form"
      >
        <InputText
          className="edit-customer-data__form__email"
          name="email"
          disabled
          placeholder={messages.customer_data_edit_heading_email}
          label={messages.customer_data_edit_heading_email}
          $settings={settings.customer_data_input_style}
        />
        <InputText
          className="edit-customer-data__form__first-name"
          name="firstName"
          control={control}
          placeholder={messages.customer_data_edit_placeholder_name}
          label={messages.customer_data_edit_heading_name}
          $settings={settings.customer_data_input_style}
        />
        <InputText
          className="edit-customer-data__form__last-name"
          name="lastName"
          control={control}
          placeholder={messages.customer_data_edit_placeholder_surname}
          label={messages.customer_data_edit_heading_surname}
          $settings={settings.customer_data_input_style}
        />
        <InputPhone
          className="edit-customer-data__form__phone"
          name="phone"
          mask="999-999-999"
          control={control}
          placeholder={messages.customer_data_edit_placeholder_phone}
          label={messages.customer_data_edit_heading_phone}
          $settings={settings.customer_data_input_style}
        />

        <StyledButtonsContainer className="edit-customer-data__form__button-container">
          <StyledButton
            className="edit-customer-data__form__button-container__save-button"
            renderAs="button"
            type="submit"
            $settings={settings.customer_data_confirm_button_style}
            show={isNotEmptyMessage(messages.customer_data_save_button)}
          >
            {messages.customer_data_save_button}
          </StyledButton>
          <StyledButton
            className="edit-customer-data__form__button-container__cancel-button"
            renderAs="button"
            type="button"
            $settings={settings.customer_data_cancel_button_style}
            onClick={onCancel}
            show={isNotEmptyMessage(messages.customer_data_cancel_button)}
          >
            {messages.customer_data_cancel_button}
          </StyledButton>
        </StyledButtonsContainer>
      </form>
    </FormProvider>
  );
};

export default EditCustomerData;
