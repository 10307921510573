import { useEffect } from "react";
import { useRouter } from "next/router";
import { useTheme } from "styled-components";
import * as searchResults from "@ecp-redux/api/searchResults";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { initialAttributesLineDetection } from "@ecp-redux/helpers";
import { isPortalSide } from "../../../helpers/helpers";
import { useSearchQueries } from "../../../helpers/pageContext";
import { IMarketingCampaign } from "../BoxSearchResults.types";
import { SortDirection, SortOption } from "./Sort.types";
import { useMarketingBannersLogic } from "./useMarketingBannersLogic";

export const createPostProductsRequestBody = (customProductKeys: string[]) => ({
  basicInfo: [
    "name",
    "price",
    "badges",
    "rating",
    "coverPhoto",
    "hoverPhoto",
    "deliveryTime",
    "url",
    "tax",
    "wishlist",
    "axis",
    "variants",
    "pools",
  ],
  attributes: customProductKeys,
  locale: "PL",
});

const useGetSearchResultProducts = (
  searchQueries: string,
  customAttributes: string[]
) => {
  const { data, isLoading } = searchResults.useGetSearchWithSrpResultsQuery({
    query: searchQueries,
    ...createPostProductsRequestBody(customAttributes),
  });

  return {
    productsVm: data,
    isFetching: isLoading,
  };
};

export const useBoxSearchResultPagination = (
  initialTake: number,
  customAttributes: string[],
  marketingCampaign?: IMarketingCampaign
) => {
  const router = useRouter();

  const { getProductsAndBanners, bannersPositions } = useMarketingBannersLogic(
    initialTake,
    marketingCampaign
  );

  const { searchQueries, skip, take } = useSearchQueries(
    {
      initialPageIndex: 1,
      initialPageSize: initialTake,
    },
    marketingCampaign !== undefined
      ? {
          bannersTemplate: bannersPositions,
          bannersDisplayVariant: marketingCampaign.positionOption,
        }
      : undefined
  );

  const { productsVm, isFetching } = useGetSearchResultProducts(
    searchQueries,
    customAttributes
  );

  const setPage = (page: number) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, page: page + 1 },
      },
      undefined,
      { shallow: true }
    );
  };
  const setPageSize = (pageSize?: number, sort?: SortOption) => {
    const newSortQuery =
      sort !== undefined
        ? sort.direction === SortDirection.ASC
          ? sort.type
          : `-${sort.type}`
        : undefined;

    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...{ page: 1 },
          ...(pageSize ? { pageSize: pageSize } : {}),
          ...(sort ? { sort: newSortQuery } : {}),
        },
      },
      undefined,
      { shallow: true }
    );
  };

  return {
    page: skip / take,
    pageCount: Math.floor((productsVm?.size ?? 0) / take),
    totalSize: productsVm?.size ?? 0,
    pageSize: take,
    productsVm:
      marketingCampaign === undefined
        ? productsVm?.products ?? []
        : getProductsAndBanners(productsVm?.products ?? []),
    isError: productsVm === undefined,
    attributesLineDetection:
      productsVm?.attributesLineDetection ?? initialAttributesLineDetection,
    isFetching,
    setPage,
    setPageSize,
  };
};

export const useBoxSearchResultPaginationV2 = (
  initialTake: number,
  customAttributes: string[]
) => {
  const router = useRouter();

  const {
    advanceSettings: {
      settings: { redirectForEmptyResults },
    },
  } = useTheme() as IThemeState;

  const { searchQueries, skip, take } = useSearchQueries({
    initialPageIndex: 1,
    initialPageSize: initialTake,
  });

  const { data, isFetching } = searchResults.useGetSearchWithSrpResultsV2Query({
    searchQueries,
    projection: [
      ...new Set([
        ...customAttributes,
        ...[
          "mainUnitOfMeasurement",
          "mainUnitOfMeasurementLabel",
          "alternativeUnitOfMeasures",
          "deliveryQuantity",
          "sku",
          "url",
          "name",
          "concession",
        ],
      ]),
    ],
  });

  const setPage = (page: number) => {
    router.push(
      {
        pathname: router.pathname,
        query: { ...router.query, page: page + 1 },
      },
      undefined,
      { shallow: true }
    );
  };
  const setPageSize = (pageSize?: number, sort?: SortOption) => {
    const newSortQuery =
      sort !== undefined
        ? sort.direction === SortDirection.ASC
          ? sort.type
          : `-${sort.type}`
        : undefined;

    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          ...{ page: 1 },
          ...(pageSize ? { pageSize: pageSize } : {}),
          ...(sort ? { sort: newSortQuery } : {}),
        },
      },
      undefined,
      { shallow: true }
    );
  };

  useEffect(() => {
    if (data?.products.length === 0 && !isFetching && isPortalSide()) {
      router.replace(redirectForEmptyResults);
    }
  }, [data?.products, isFetching]);

  return {
    page: skip / take,
    pageCount: Math.floor((data?.size ?? 0) / take),
    totalSize: data?.size ?? 0,
    pageSize: take,
    productsVm: isFetching ? [] : data?.products ?? [],
    setPage,
    setPageSize,
  };
};
