import { API, api } from "./api";

export type TLogisticsMinimumSingleResultPayload = {
  projection: string[];
};

export type ILogisticsMinimumResponse = {
  result: Record<string, string>;
};

export const logisticsMinimum = api.injectEndpoints({
  endpoints: (builder) => ({
    getLogisticsMinimumColumnNames: builder.query<
      { columnNames: string[] },
      void
    >({
      query: () => {
        return API.getLogisticMinColumnNames.endpoint();
      },
    }),
    postLogisticMinSingleResult: builder.query<
      ILogisticsMinimumResponse,
      TLogisticsMinimumSingleResultPayload
    >({
      query: (body) => {
        return {
          url: API.postLogisticMinSingleResult.endpoint(),
          method: "POST",
          body,
        };
      },
      providesTags: ["CONTRACTORS"],
    }),
  }),
});

export const {
  useLazyGetLogisticsMinimumColumnNamesQuery,
  usePostLogisticMinSingleResultQuery,
} = logisticsMinimum;

export const { getLogisticsMinimumColumnNames, postLogisticMinSingleResult } =
  logisticsMinimum.endpoints;
