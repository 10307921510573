import styled from "styled-components";
import {
  IThemeState,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../global/global";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import { ICustomMarker } from "./StoreLocatorMap/StoreLocatorMap.types";

export const StyledStoresDisplayMobile = styled.div<{
  mapUsedInPopup: boolean;
}>(
  ({ mapUsedInPopup }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100svh;
  }

  .stores-display-mobile {
    &__top {
      padding: 1rem;

      &__show-map-label {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
      }
    }

    &__show-shop-list-button {
      z-index: var(--zindex-last-view-button);
      display: flex;
      gap: 1.5rem;
      padding: 0.5rem;
      align-items: center;
    }

    &__list-element {
      margin-left: 0.5rem;
      display: flex;
      flex-direction: column;
      height: 780px;
      overflow-y: auto;
    }

    &__map-container {
      width: 100vw;
      ${mapUsedInPopup ? "margin-left: -30px;" : ""}
    }
  }

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    height: 100svh;
  }
`
);

export const StyledStoresDisplayDesktop = styled.div`
  display: flex;
  flex-direction: column;

  .stores-display-desktop {
    &__top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 1.25rem;

      &__view-label {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 0.75rem;
      }
    }

    &__choose-view {
      width: 100%;
    }

    &__not-found {
      height: 780px;
    }
  }
`;

export const StyledTableView = styled.div`
  display: flex;
  flex-direction: column;
  height: 780px;
  overflow-y: auto;

  .table-view {
    &__not-found {
      height: 780px;
      padding: 1rem;
    }
  }
`;

export const StyledSearchBarWrapper = styled.div`
  display: flex;

  @media (min-width: ${MOBILE_BREAKPOINT + 1}px) {
    width: 75%;
    padding-right: 11px;
  }

  .stores-input-search-bar__container__button-container {
    position: absolute;
    right: 0.5rem;
    bottom: 50%;
    transform: translateY(50%);
    padding: 0.25rem;
  }
`;

export const StyledStore = styled.div<{
  width?: number;
  backgroundColor?: TColorId | undefined;
}>(({ backgroundColor, theme, width }) => {
  return `
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px;
    width: 100%;

    .store-container__store-photo {
      ${width ? `width: ${width}px;` : ""}
    }
  
    .store-container__store-info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 4px;
      padding: 0px;

        &__store-open-hours {
            display: flex;
            flex-direction: column;
            padding: 0px;

            &__hours {
                min-width: 80px;
            }

            &__days-container {
                display: flex;
                gap: 0.25rem;

                &__days-label__main {
                    min-width: 60px;
                }

                &__hours-label__main {
                    display: flex;
                    width: auto;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
  
    .chosen-item {
    ${
      backgroundColor &&
      `
        background-color: ${convertColorIdToHex(
          backgroundColor,
          theme.colorPalette
        )};
      `
    }
    }
  `;
});

export const StyledStoreLocatorMapContainer = styled.div`
  display: flex;
  width: 100%;

  .storelocator-map-wrapper {
    width: 75%;
    margin-right: 16px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 100%;
      height: 80vh;
      margin-right: 0px;

      &__store-not-found {
        position: absolute;
        z-index: var(--zindex-storelocator-store-not-found);
        padding: 16px;
        margin-top: 80px;
        font-weight: bold;
        font-size: 24px;
      }

      &__search-bar {
        position: absolute;
        z-index: var(--zindex-storelocator-search-bar);
        padding: 16px;
        width: 100%;
      }
    }
  }

  .storelocator-list-wrapper {
    width: 25%;
    height: 780px;
    overflow-y: auto;
    padding-right: 16px;
  }
`;

export const StyledStoresLocatorMapSingleListElement = styled.div<{
  onClick: (childProps: ICustomMarker) => void;
  isActive: boolean;
  backgroundColor: TColorId;
  theme: IThemeState;
}>`
  margin-bottom: 24px;
  cursor: pointer;
  ${({ isActive, backgroundColor, theme }) =>
    isActive
      ? `background-color: ${convertColorIdToHex(
          backgroundColor,
          theme.colorPalette
        )} `
      : ""}
`;
