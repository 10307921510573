import { ReactNode } from "react";
import styled, { useTheme } from "styled-components";
import { ICartLineB2B } from "@ecp-redux/dto/cartB2B.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import ImageWrapper from "../../../../../global/components/ImageWrapper/ImageWrapper";
import { convertTypoIdToValues } from "../../../../../settingsPatterns/settingsPatterns.methods";
import CloseIcon from "../../../../../shared/icons/CloseIcon";
import StyledText from "../../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import { StyledSpaceKeeper } from "../../Product/ProductModule/ProductModule.styled";
import ChangeProductQuantityB2B from "../ChangeProductQuantityB2B";
import ChoosePackageTypeB2B from "../ChoosePackageTypeB2B/ChoosePackageTypeB2B";
import { useCartB2BContext } from "../useCartB2BContext";
import { PriceB2B } from "./PriceB2B/PriceB2B";

const StyledTotalValueAndDeleteRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
`;

const StyledProductListTextValue = styled.div`
  display: flex;
  align-items: center;
`;

export const ProductListTextValue: React.FC<{
  className: string;
  children: ReactNode;
}> = ({ className, children }) => {
  const { settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();
  const theme = useTheme() as IThemeState;

  return (
    <StyledProductListTextValue className={className}>
      <StyledSpaceKeeper
        height={
          convertTypoIdToValues(settings.basket_data_typo, theme.typography)
            .lineHeight
        }
        rows={2}
      >
        <StyledText
          $settings={{
            font: settings.basket_data_typo,
            text: { color: settings.basket_data_color },
          }}
        >
          {children}
        </StyledText>
      </StyledSpaceKeeper>
    </StyledProductListTextValue>
  );
};

const ProductLine: React.FC<{
  cartLine: ICartLineB2B;
  productType: string;
  className: string;
}> = ({ cartLine, productType, className }) => {
  const {
    advanceSettings: { settings: globalSettings },
  } = useTheme() as IThemeState;

  const { netValue, grossValue } = cartLine.productPriceDetails;

  const { settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const { deleteProduct, addToCart } = useCartB2BContext();

  return (
    <>
      <ProductListTextValue
        className={`cartB2B__product-line-item ${className}`}
      >
        {cartLine.sku}
      </ProductListTextValue>

      <div
        className={`cartB2B__product-line-item ${className}`}
        style={{ minHeight: 70 }}
      >
        <ImageWrapper
          width={150}
          imageUrl={cartLine.photoUrl ?? globalSettings.dynamicBoxImagePlug}
          seoDescription={cartLine.name}
          imageFit
          imageAlignment={{
            horizontal: AlignmentHorizontalOption.CENTER,
            vertical: AlignmentVerticalOption.CENTER,
          }}
          className={`cartB2B--product_line_item ${className}`}
        />
      </div>
      <ProductListTextValue
        className={`cartB2B__product-line-item ${className}`}
      >
        {cartLine.name}
      </ProductListTextValue>
      <ChoosePackageTypeB2B
        product={{
          currentUnitOfMeasure: cartLine.unitOfMeasure.currentUnitOfMeasure,
          availableUnitsOfMeasure:
            cartLine.unitOfMeasure.availableUnitsOfMeasure,
          mainUnitOfMeasure: cartLine.unitOfMeasure.baseUnitOfMeasure,
        }}
        onChange={(unitOfMeasure) => {
          if (unitOfMeasure === cartLine.unitOfMeasure.currentUnitOfMeasure)
            return;
          addToCart({
            sku: cartLine.sku,
            quantity: cartLine.quantity,
            unitOfMeasure: unitOfMeasure,
            isCart: true,
          });
        }}
        dropdownStyle={settings.basket_single_order_unit_dropdown_style}
        className={`cartB2B__product-line-item ${className}`}
      />
      <div className={`cartB2B__product-line-item ${className}`}>
        <ChangeProductQuantityB2B
          product={{ sku: cartLine.sku }}
          key={JSON.stringify(cartLine)}
          minusButtonQuantityStyle={
            settings.basket_single_order_quantity_minus_button_style
          }
          plusButtonQuantityStyle={
            settings.basket_single_order_quantity_plus_button_style
          }
          inputQuantityStyle={settings.basket_single_order_quantity_input_style}
          isCart
        />
      </div>

      <div className={`cartB2B__product-line-item ${className}`}>
        <PriceB2B netProductValue={netValue} grossProductValue={grossValue} />
      </div>
      <StyledTotalValueAndDeleteRow
        className={`cartB2B__product-line-item ${className}`}
      >
        <PriceB2B
          netProductValue={cartLine.quantity * netValue}
          grossProductValue={cartLine.quantity * grossValue}
        />
        <button
          onClick={() => deleteProduct({ sku: cartLine.sku, productType })}
        >
          <CloseIcon width={15} height={15} fill="#293740" />
        </button>
      </StyledTotalValueAndDeleteRow>
    </>
  );
};

export default ProductLine;
