import React from "react";
import dynamic from "next/dynamic";
import { TRegularBoxType, TSystemBoxType } from "@ecp-pageTypes";
import { isB2BClient } from "@ecp-redux/helpers";
import BoxAccordion from "./BoxAccordion/BoxAccordion";
import { IBoxAccordionProps } from "./BoxAccordion/BoxAccordion.types";
import BoxAddToCart from "./BoxAddToCart/BoxAddToCart";
import { IBoxAddToCartProps } from "./BoxAddToCart/BoxAddToCart.types";
import BoxAddToCartQuantityB2B from "./BoxAddToCartB2B/BoxAddToCartQuantityB2B";
import { IBoxAddToCartQuantityB2BProps } from "./BoxAddToCartB2B/BoxAddToCartQuantityB2B.types";
import BoxAddToCartQuantity from "./BoxAddToCartQuantity/BoxAddToCartQuantity";
import { TBoxAddToCartQuantityProps } from "./BoxAddToCartQuantity/BoxAddToCartQuantity.types";
import BoxArticleContent from "./BoxArticleContent/BoxArticleContent";
import { TBoxArticleContentProps } from "./BoxArticleContent/BoxArticleContent.types";
import BoxArticleList from "./BoxArticleList/BoxArticleList";
import { IBoxArticleListProps } from "./BoxArticleList/BoxArticleList.types";
import BoxB2BAddToCartUnit from "./BoxB2BAddToCartUnit/BoxB2BAddToCartUnit";
import { IBoxB2BAddToCartUnitProps } from "./BoxB2BAddToCartUnit/BoxB2BAddToCartUnit.types";
import BoxBanner from "./BoxBanner/BoxBanner";
import { IBoxBannerProps } from "./BoxBanner/BoxBanner.types";
import BoxBreadcrumb from "./BoxBreadcrumb/BoxBreadcrumb";
import { IBoxBreadcrumbProps } from "./BoxBreadcrumb/BoxBreadcrumb.types";
import BoxButton from "./BoxButton/BoxButton";
import { IBoxButtonProps } from "./BoxButton/BoxButton.types";
import BoxContactForm from "./BoxContactForm/BoxContactForm";
import { TBoxContactFormProps } from "./BoxContactForm/BoxContactForm.types";
import BoxContractors from "./BoxContractors/BoxContractors";
import { IBoxContractorsProps } from "./BoxContractors/BoxContractors.types";
import BoxDropdown from "./BoxDropdown/BoxDropdown";
import { IBoxDropdownProps } from "./BoxDropdown/BoxDropdown.types";
import BoxGetData from "./BoxGetData/BoxGetData";
import { IBoxGetDataProps } from "./BoxGetData/BoxGetData.types";
import BoxHtml from "./BoxHtml/BoxHtml";
import { IBoxHtmlProps } from "./BoxHtml/BoxHtml.types";
import BoxImage from "./BoxImage/BoxImage";
import { IBoxImageProps } from "./BoxImage/BoxImage.types";
import BoxListOfButtons from "./BoxListOfButtons/BoxListOfButtons";
import { IBoxListOfButtonsProps } from "./BoxListOfButtons/BoxListOfButtons.types";
import BoxLogin from "./BoxLogin/BoxLogin";
import { IBoxLoginProps } from "./BoxLogin/BoxLogin.types";
import BoxMiniCart from "./BoxMiniCart/BoxMiniCart";
import { TBoxMiniCartProps } from "./BoxMiniCart/BoxMiniCart.types";
import BoxMiniCartB2B from "./BoxMiniCartB2B/BoxMiniCartB2B";
import { TBoxMiniCartB2BProps } from "./BoxMiniCartB2B/BoxMiniCartB2B.types";
import BoxMultiMenu from "./BoxMultiMenu/BoxMultiMenu";
import { IBoxMultiMenuProps } from "./BoxMultiMenu/BoxMultiMenu.types";
import BoxMyAccountConsents from "./BoxMyAccountConsents/BoxMyAccountConsents";
import { IBoxMyAccountConsentsProps } from "./BoxMyAccountConsents/BoxMyAccountConsents.types";
import BoxMyAccountHeader from "./BoxMyAccountHeader/BoxMyAccountHeader";
import { IBoxMyAccountHeaderProps } from "./BoxMyAccountHeader/BoxMyAccountHeader.types";
import BoxMyAccountMyAddressList from "./BoxMyAccountMyAdressList/BoxMyAccountMyAddressList";
import { IBoxMyAccountMyAddressListProps } from "./BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.types";
import BoxMyAccountMyData from "./BoxMyAccountMyData/BoxMyAccountMyData";
import { TBoxMyAccountMyDataProps } from "./BoxMyAccountMyData/BoxMyAccountMyData.types";
import BoxMyAccountMyOrders from "./BoxMyAccountMyOrders/BoxMyAccountMyOrders";
import { TBoxMyAccountMyOrdersProps } from "./BoxMyAccountMyOrders/BoxMyAccountMyOrders.types";
import MyOrderProductDelivery from "./BoxMyAccountMyOrdersDetails/BoxMyAccountMyOrdersDetails";
import { TBoxMyAccountMyOrdersDetailsProps } from "./BoxMyAccountMyOrdersDetails/BoxMyAccountMyOrdersDetails.types";
import BoxNewsletter from "./BoxNewsletter/BoxNewsletter";
import { TBoxNewsletterProps } from "./BoxNewsletter/BoxNewsletter.types";
import BoxPhotoGallery from "./BoxPhotoGallery/BoxPhotoGallery";
import { IBoxPhotoGalleryProps } from "./BoxPhotoGallery/BoxPhotoGallery.types";
import BoxProductAttributes from "./BoxProductAttributes/BoxProductAttributes";
import { BoxProductAttributesProps } from "./BoxProductAttributes/BoxProductAttributes.types";
import BoxProductSlider from "./BoxProductSlider/BoxProductSlider";
import { IBoxProductSliderProps } from "./BoxProductSlider/BoxProductSlider.types";
import BoxProductVariants from "./BoxProductVariants/BoxProductVariants";
import { BoxProductVariantsProps } from "./BoxProductVariants/BoxProductVariants.types";
import BoxRegister from "./BoxRegister/BoxRegister";
import { TBoxRegisterProps } from "./BoxRegister/BoxRegister.types";
import BoxRemind from "./BoxRemind/BoxRemind";
import { TBoxRemindProps } from "./BoxRemind/BoxRemind.types";
import BoxRichText from "./BoxRichText/BoxRichText";
import { IBoxRichTextProps } from "./BoxRichText/BoxRichText.types";
import BoxSearchBar from "./BoxSearchBar/BoxSearchBar";
import { IBoxSearchBarProps } from "./BoxSearchBar/BoxSearchBar.types";
import BoxSearchFilters from "./BoxSearchFilters/BoxSearchFilters";
import { BoxSearchFiltersProps } from "./BoxSearchFilters/BoxSearchFilters.types";
import BoxSearchResults from "./BoxSearchResults/BoxSearchResults";
import { BoxSearchResultsProps } from "./BoxSearchResults/BoxSearchResults.types";
import BoxSearchResultsB2B from "./BoxSearchResultsB2B/BoxSearchResultsB2B";
import { IBoxSearchResultsB2BProps } from "./BoxSearchResultsB2B/BoxSearchResultsB2B.types";
import { IBoxSliderProps } from "./BoxSlider/BoxSlider.types";
import BoxStoreLocator from "./BoxStoreLocator/BoxStoreLocator";
import { IBoxStoreLocatorProps } from "./BoxStoreLocator/BoxStoreLocator.types";
import BoxTableData from "./BoxTableData/BoxTableData";
import { IBoxTableDataProps } from "./BoxTableData/BoxTableData.types";
import BoxText from "./BoxText/BoxText";
import { IBoxTextProps } from "./BoxText/BoxText.types";
import BoxTilesSlider from "./BoxTilesSlider/BoxTilesSlider";
import { IBoxTilesSliderProps } from "./BoxTilesSlider/BoxTilesSlider.types";
import BoxVideo from "./BoxVideo/BoxVideo";
import { IBoxVideoProps } from "./BoxVideo/BoxVideo.types";
import BoxCartStepOne from "./Cart/BoxCartStepOne/BoxCartStepOne";
import { IBoxCartStepOneProps } from "./Cart/BoxCartStepOne/BoxCartStepOne.types";
import BoxCartStepTwo from "./Cart/BoxCartStepTwo/BoxCartStepTwo";
import { IBoxCartStepTwoProps } from "./Cart/BoxCartStepTwo/BoxCartStepTwo.types";
import BoxOrderSummary from "./Cart/BoxOrderSummary/BoxOrderSummary";
import { IBoxOrderSummaryProps } from "./Cart/BoxOrderSummary/BoxOrderSummary.types";
import BoxCartB2B from "./CartB2B/BoxCartB2B/BoxCartB2B";
import { IBoxCartB2BProps } from "./CartB2B/BoxCartB2B/BoxCartB2B.types";
import BoxSummaryB2B from "./CartB2B/BoxSummaryB2B/BoxSummaryB2B";
import { IBoxSummaryB2BProps } from "./CartB2B/BoxSummaryB2B/BoxSummaryB2B.types";

const BoxSlider = dynamic(() => import("./BoxSlider/BoxSlider"), {
  ssr: false,
});

interface ISystemBoxTypes {
  MYACCOUNT_CONSENTS: React.ElementType<IBoxMyAccountConsentsProps>;
  MYACCOUNT_HEADER: React.ElementType<IBoxMyAccountHeaderProps>;
  LOGIN: React.ElementType<IBoxLoginProps>;
  REGISTER: React.ElementType<TBoxRegisterProps>;
  CONTACT_FORM: React.ElementType<TBoxContactFormProps>;
  REMIND_PASSWORD: React.ElementType<TBoxRemindProps>;
  PHOTO_GALLERY: React.ElementType<IBoxPhotoGalleryProps>;
  ARTICLE_CONTENT: React.ElementType<TBoxArticleContentProps>;
  CART_STEP_ONE: React.ElementType<IBoxCartStepOneProps>;
  CART_STEP_TWO: React.ElementType<IBoxCartStepTwoProps>;
  NEWSLETTER: React.ElementType<TBoxNewsletterProps>;
  SEARCH_BAR: React.ElementType<IBoxSearchBarProps>;
  ORDER_SUMMARY: React.ElementType<IBoxOrderSummaryProps>;
  MYACCOUNT_MYORDERS: React.ElementType<TBoxMyAccountMyOrdersProps>;
  MYACCOUNT_ORDER_DETAILS: React.ElementType<TBoxMyAccountMyOrdersDetailsProps>;
  MYACCOUNT_MYDATA: React.ElementType<TBoxMyAccountMyDataProps>;
  MINI_CART: React.ElementType<TBoxMiniCartProps>;
  ADD_TO_CART: React.ElementType<IBoxAddToCartProps>;
  MYACCOUNT_ADDRESSES: React.ElementType<IBoxMyAccountMyAddressListProps>;
  STORE_LOCATOR: React.ElementType<IBoxStoreLocatorProps>;
  ADD_TO_CART_QUANTITY: React.ElementType<TBoxAddToCartQuantityProps>;
  DROPDOWN: React.ElementType<IBoxDropdownProps>;
  CONTRACTORS: React.ElementType<IBoxContractorsProps>;
  CART_B2B: React.ElementType<IBoxCartB2BProps>;
  B2B_ADD_TO_CART_QUANTITY: React.ElementType<IBoxAddToCartQuantityB2BProps>;
  B2B_CART_SUMMARY: React.ElementType<IBoxSummaryB2BProps>;
  B2B_ADD_TO_CART_UNIT: React.ElementType<IBoxB2BAddToCartUnitProps>;
  MINI_CART_B2B: React.ElementType<TBoxMiniCartB2BProps>;
}

type TSystemBoxMappedType = {
  [key in TSystemBoxType]: ISystemBoxTypes[key];
};
interface IRegularBoxTypes {
  HTML: React.ElementType<IBoxHtmlProps>;
  MULTI_MENU: React.ElementType<IBoxMultiMenuProps>;
  SLIDER: React.ElementType<IBoxSliderProps>;
  TEXT: React.ElementType<IBoxTextProps>;
  IMAGE: React.ElementType<IBoxImageProps>;
  VIDEO: React.ElementType<IBoxVideoProps>;
  BANNER: React.ElementType<IBoxBannerProps>;
  PRODUCT_SLIDER: React.ElementType<IBoxProductSliderProps>;
  ARTICLE_LIST: React.ElementType<IBoxArticleListProps>;
  RICH_TEXT: React.ElementType<IBoxRichTextProps>;
  BREADCRUMB: React.ElementType<IBoxBreadcrumbProps>;
  BUTTON: React.ElementType<IBoxButtonProps>;
  SEARCH_RESULTS: React.ElementType<BoxSearchResultsProps>;
  SYSTEM: TSystemBoxMappedType;
  SEARCH_FILTERS: React.ElementType<BoxSearchFiltersProps>;
  PRODUCT_ATTRIBUTES: React.ElementType<BoxProductAttributesProps>;
  PRODUCT_VARIANT: React.ElementType<BoxProductVariantsProps>;
  GET_DATA: React.ElementType<IBoxGetDataProps>;
  ACCORDION: React.ElementType<IBoxAccordionProps>;
  LIST_OF_BUTTONS: React.ElementType<IBoxListOfButtonsProps>;
  GET_TABLE_DATA: React.ElementType<IBoxTableDataProps>;
  B2B_CART_SUMMARY: React.ElementType<IBoxSummaryB2BProps>;
  SUMMARY_B2B: React.ElementType<IBoxSummaryB2BProps>;
  SEARCH_RESULTS_B2B: React.ElementType<IBoxSearchResultsB2BProps>;
  TILES_SLIDER: React.ElementType<IBoxTilesSliderProps>;
}

type TRegularBoxMappedType = {
  [key in TRegularBoxType]: IRegularBoxTypes[key];
};

export const boxTypes: TRegularBoxMappedType = {
  TEXT: BoxText,
  IMAGE: BoxImage,
  SLIDER: BoxSlider,
  VIDEO: BoxVideo,
  HTML: BoxHtml,
  MULTI_MENU: BoxMultiMenu,
  RICH_TEXT: BoxRichText,
  BUTTON: BoxButton,
  SEARCH_RESULTS: BoxSearchResults,
  BANNER: BoxBanner,
  PRODUCT_SLIDER: BoxProductSlider,
  ARTICLE_LIST: BoxArticleList,
  SEARCH_FILTERS: BoxSearchFilters,
  BREADCRUMB: BoxBreadcrumb,
  PRODUCT_ATTRIBUTES: BoxProductAttributes,
  PRODUCT_VARIANT: BoxProductVariants,
  GET_DATA: BoxGetData,
  ACCORDION: BoxAccordion,
  LIST_OF_BUTTONS: BoxListOfButtons,
  GET_TABLE_DATA: BoxTableData,
  SEARCH_RESULTS_B2B: BoxSearchResultsB2B,
  TILES_SLIDER: BoxTilesSlider,

  SYSTEM: {
    MYACCOUNT_CONSENTS: BoxMyAccountConsents,
    MYACCOUNT_HEADER: BoxMyAccountHeader,
    LOGIN: BoxLogin,
    REGISTER: BoxRegister,
    REMIND_PASSWORD: BoxRemind,
    PHOTO_GALLERY: BoxPhotoGallery,
    ARTICLE_CONTENT: BoxArticleContent,
    CART_STEP_ONE: BoxCartStepOne,
    CART_STEP_TWO: BoxCartStepTwo,
    NEWSLETTER: BoxNewsletter,
    SEARCH_BAR: BoxSearchBar,
    CONTACT_FORM: BoxContactForm,
    ORDER_SUMMARY: BoxOrderSummary,
    MYACCOUNT_MYORDERS: BoxMyAccountMyOrders,
    MYACCOUNT_ORDER_DETAILS: MyOrderProductDelivery,
    MYACCOUNT_MYDATA: BoxMyAccountMyData,
    MINI_CART: BoxMiniCart,
    ADD_TO_CART: BoxAddToCart,
    MYACCOUNT_ADDRESSES: BoxMyAccountMyAddressList,
    STORE_LOCATOR: BoxStoreLocator,
    ADD_TO_CART_QUANTITY: BoxAddToCartQuantity,
    DROPDOWN: BoxDropdown,
    CONTRACTORS: BoxContractors,
    CART_B2B: BoxCartB2B,
    B2B_ADD_TO_CART_QUANTITY: BoxAddToCartQuantityB2B,
    B2B_CART_SUMMARY: BoxSummaryB2B,
    B2B_ADD_TO_CART_UNIT: BoxB2BAddToCartUnit,
    MINI_CART_B2B: BoxMiniCartB2B,
  },
};

const disabledOnB2B = ["CART_STEP_ONE", "CART_STEP_TWO"];

const disabledOnB2C = [
  "CART_B2B",
  "B2B_ADD_TO_CART_QUANTITY",
  "B2B_CART_SUMMARY",
  "B2B_ADD_TO_CART_UNIT",
  "MINI_CART_B2B",
];

export const SYSTEM_BOX_NAMES = Object.keys(boxTypes.SYSTEM).filter((key) => {
  if (isB2BClient()) {
    return !disabledOnB2B.includes(key);
  } else {
    return !disabledOnB2C.includes(key);
  }
});
