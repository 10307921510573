import {
  ISearchResultsTransformResponse,
  ISrpTransformResponse,
  TSkusResponse,
} from "@ecp-redux/dto/searchResults.types";
import {
  IBoxProductSliderContentSettings,
  ProductSliderBoxType,
  TProductsDataObject,
} from "./BoxProductSlider.types";

export const getProductsDataObject = (
  data: TSkusResponse | undefined
): TProductsDataObject => ({
  products: data?.products.map((product) => product.sku) ?? undefined,
  size: data?.size ?? 0,
});

export const getDynamicAssociationSkus = (
  data: ISearchResultsTransformResponse | undefined
): string[] | undefined => {
  return data?.products.map((sku) => sku) ?? undefined;
};

export const getSearchRequestConfig = (
  contentSettings: IBoxProductSliderContentSettings,
  productsSkus: string[]
) => {
  const {
    dynamicProductListOption: dynamicOption,
    searchQuery,
    sliderSettings: {
      maxNumberOfProducts,
      maxNumberOfProductsGrid,
      sorting,
      type,
    },
  } = contentSettings;

  const stringCurrentData =
    dynamicOption != undefined ? "&filter=sku:" + productsSkus?.join(";") : "";

  return {
    query: `${searchQuery}${stringCurrentData}&size=${
      type === ProductSliderBoxType.SLIDER
        ? maxNumberOfProducts
        : maxNumberOfProductsGrid
    }&from=${0}`,
    sort: sorting,
  };
};

export const getElementsForPage = (
  array: ISrpTransformResponse[],
  productsPerPage: number,
  currentPage: number
) => {
  const startIndex = currentPage * productsPerPage;
  const endIndex = Math.min(startIndex + productsPerPage, array.length);

  return array.slice(startIndex, endIndex);
};
