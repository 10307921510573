import { useRef, useState } from "react";
import React from "react";
import { useRouter } from "next/router";
import styled, { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../settingsPatterns/settingsPatterns.methods";
import Popup from "../../shared/components/Popup/Popup";
import CloseIcon from "../../shared/icons/CloseIcon";
import { ReactComponent as SearchFiltersIcon } from "../../shared/icons/searchFiltersIcon.svg";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../structure/Contexts/MessagesSettingsContext";
import {
  StyledFacetsContainer,
  StyledFiltersContainer,
  StyledMobileSearchFiltersContainer,
} from "./BoxSearchFilters.styled";
import {
  IBoxSearchFiltersMessages,
  IBoxSearchFiltersSettings,
  ISearchFiltersProps,
} from "./BoxSearchFilters.types";
import SearchCategories from "./SearchCategories";
import SearchFacetMobile from "./SearchFacetMobile";
import { useSearchFiltersLogic } from "./useSearchFiltersLogic";

const StyledSearchFiltersPopupWrapper = styled(Popup)`
  height: 100%;

  .search-filters-container__main {
    display: flex;
    justify-content: space-between;
    height: 100%;
  }
`;

const SearchFiltersMobile: React.FC<ISearchFiltersProps> = ({
  contentSettings,
  displaySettings,
  content,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxSearchFiltersMessages,
    IBoxSearchFiltersSettings
  >();

  const {
    changeCheckbox,
    facetsToShow,
    hasCategories,
    childCategory,
    deleteFiltersFromUrl,
  } = useSearchFiltersLogic(content);

  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const urlRef = useRef(router.asPath);
  const theme = useTheme() as IThemeState;

  const handleClose = () => {
    if (urlRef.current && router.asPath != urlRef.current) {
      router.push(urlRef.current);
    }
    setIsOpen(false);
  };

  return (
    <>
      {!isOpen && (
        <StyledMobileSearchFiltersContainer
          backgroundColor={settings.filters_mobile_header_background_color}
        >
          <div
            className="search-filters__header"
            onClick={() => setIsOpen(true)}
            data-testid="search-filters-mobile-header-container"
          >
            <StyledText
              className="search-filters__header__label"
              $settings={{
                font: settings.filters_mobile_header_label_typography,
                text: { color: settings.filters_mobile_header_label_color },
              }}
            >
              {messages.filters_mobile_header_label}
            </StyledText>
            <SearchFiltersIcon />
          </div>
        </StyledMobileSearchFiltersContainer>
      )}
      {isOpen && (
        <StyledSearchFiltersPopupWrapper>
          <div className="search-filters-container__main">
            <StyledFiltersContainer
              categoriesPosition={contentSettings.categories.position}
              className="search-filters-container no-scrollbar"
              backgroundColor={
                settings.filters_mobile_categoryandfilters_background_color
              }
              spaceBetweenCategoriesAndFilters={
                displaySettings.spaceBetweenCategoriesAndFilters
              }
            >
              {contentSettings.categories.show && hasCategories && (
                <SearchCategories
                  categoryBranch={childCategory()}
                  displaySettings={displaySettings}
                  contentSettings={contentSettings}
                  content={content}
                />
              )}
              {facetsToShow.length > 0 && (
                <div className="search-filters-container__facets-container">
                  <StyledText
                    $settings={contentSettings.title}
                    className="search-filters-container__facets-title"
                  >
                    {content.facetsTitle}
                  </StyledText>

                  <StyledFacetsContainer
                    gap={displaySettings.spaceBetweenFacets}
                    className="search-filters-container__facets"
                  >
                    {facetsToShow.map((attrib) => (
                      <SearchFacetMobile
                        contentSettings={contentSettings}
                        displaySettings={displaySettings}
                        content={content}
                        facet={attrib}
                        selectValue={changeCheckbox}
                        key={attrib.attributeId}
                      />
                    ))}
                    <div className="search-filters-container__facets__button-container">
                      {contentSettings.clear.show && (
                        <StyledButton
                          $settings={
                            settings.filters_mobile_clear_filters_button_style
                          }
                          onClick={deleteFiltersFromUrl}
                          className="search-filters-container__facets__button-container__clear-button"
                        >
                          {content.clear}
                        </StyledButton>
                      )}
                      <StyledButton
                        $settings={
                          settings.filters_mobile_show_result_button_style
                        }
                        onClick={() => setIsOpen(false)}
                        className="search-filters-container__facets__button-container__show-result-button"
                      >
                        {messages.filters_mobile_show_result_button_label}
                      </StyledButton>
                    </div>
                  </StyledFacetsContainer>
                </div>
              )}
            </StyledFiltersContainer>
            <StyledMobileSearchFiltersContainer
              backgroundColor={settings.filters_mobile_right_background_color}
            >
              <div
                className="search-filters-container__close-icon-container"
                onClick={handleClose}
              >
                <div
                  className="search-filters-container__close-icon-container__close-icon"
                  data-testid="search-filters-container__close-icon-container__close-icon"
                >
                  <CloseIcon
                    width={12}
                    height={12}
                    fill={convertColorIdToHex(
                      settings.filters_mobile_close_icon_color,
                      theme.colorPalette
                    )}
                  />
                </div>
              </div>
            </StyledMobileSearchFiltersContainer>
          </div>
        </StyledSearchFiltersPopupWrapper>
      )}
    </>
  );
};

export default SearchFiltersMobile;
