import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "@ecp-boxes/global/global";

export const StyledClipboardIconContainer = styled.div<{}>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--zindex-clipboard-icon);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: #ffffff80;
    path {
      stroke: #000000;
    }
  }
`;

export const StyledClipBoardIconFromSettings = styled.img``;
