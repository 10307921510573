import styled, { css } from "styled-components";
import {
  IThemeState,
  TColorId,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { IBoxCartB2BSettings } from "../../../../../boxes/CartB2B/BoxCartB2B/BoxCartB2B.types";
import { convertColorIdToHex } from "../../../../../settingsPatterns/settingsPatterns.methods";
import {
  CheckboxButton,
  CheckboxButtonLabel,
} from "../../../../../shared/components/CheckboxesList/CheckboxesList.styled";

interface IStyledDeliveryCalendarProps {
  disableRightArrow: boolean;
  disableLeftArrow: boolean;
  isMobile: boolean;
  backgroundColor: TColorId;
  deliveryCalendarOpen: boolean;
  theme: IThemeState;
}

export const StyledDeliveryCalendar = styled.div(
  ({
    disableRightArrow,
    disableLeftArrow,
    isMobile,
    backgroundColor,
    deliveryCalendarOpen,
    theme,
  }: IStyledDeliveryCalendarProps) => {
    const zIndexValue = deliveryCalendarOpen
      ? "calc(var(--zindex-portal) + 1)"
      : "var(--zindex-portal)";

    return css`
      ${isMobile
        ? `
        position: fixed;
        bottom: 0;
        z-index: ${zIndexValue};
        background-color: ${convertColorIdToHex(
          backgroundColor,
          theme.colorPalette
        )};
        box-shadow: 0px -5px 10px 0px #00000040;
        padding: 0 0 10px 0;
        order: 2;

        .delivery-container {
          &__header {
            padding 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          &__swiper {
            padding 0 3px;
            overflow-x: auto;
            
            &__days-container {
              padding-bottom: 7px;
            }
          }
        }
      `
        : ``}

      display: flex;
      flex-direction: column;
      gap: 8px;

      user-select: none;
      width: 100%;

      .delivery-container {
        &__header {
          padding-left: 24px;
        }

        &__swiper {
          display: flex;
          align-items: center;
          gap: 5px;
          width: 100%;

          &__left-arrow {
            ${disableLeftArrow ? "pointer-events: none;" : "cursor: pointer;"}
            transform: rotate(180deg);

            path {
              stroke: ${disableLeftArrow ? "#CCCCCC;" : "#293740"};
            }
          }

          &__right-arrow {
            ${disableRightArrow ? "pointer-events: none;" : "cursor: pointer;"}

            path {
              stroke: ${disableRightArrow ? "#CCCCCC;" : "#293740"};
            }
          }

          &__days-container {
            display: flex;
            ${isMobile ? "" : "width: 100%;"}
            align-items: center;
            gap: 5px;
          }
        }
      }
    `;
  }
);

interface IStyledDayProps {
  isActive: boolean;
  isInactive: boolean;
  isDisabled: boolean;
  settings: IBoxCartB2BSettings;
  theme: IThemeState;
}

export const StyledDay = styled.div(
  ({ isActive, isInactive, isDisabled, settings, theme }: IStyledDayProps) => `
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around; 
    padding: 2px 30px;
    cursor: pointer;
    width: max-content;
    position: relative;
    border: 1px solid transparent;


    ${
      isActive
        ? `background-color: ${convertColorIdToHex(
            settings.basket_single_order_delivery_term_background_color_active,
            theme.colorPalette
          )};
            border: 1px solid ${convertColorIdToHex(
              settings.basket_single_order_delivery_term_border_color_active,
              theme.colorPalette
            )};`
        : ""
    }
    ${
      isInactive
        ? `background-color: ${convertColorIdToHex(
            settings.basket_single_order_delivery_term_background_color_inactive,
            theme.colorPalette
          )};
              border: 1px solid ${convertColorIdToHex(
                settings.basket_single_order_delivery_term_border_color_inactive,
                theme.colorPalette
              )};`
        : ""
    }
    ${
      isDisabled
        ? `background-color: ${convertColorIdToHex(
            settings.basket_single_order_delivery_term_background_color_disabled,
            theme.colorPalette
          )};
            border: 1px solid ${convertColorIdToHex(
              settings.basket_single_order_delivery_term_border_color_disabled,
              theme.colorPalette
            )};
            pointer-events: none;`
        : ""
    }

    .chosen-day-container {
        position: absolute;
        right: 16px;
        top: 0;
        opacity: 1;
        width: 16px;
        height: 16px;
                    
        &__checkbox {
            border: 1px solid black;
        }
    }   

    .delivery-container__swiper__day-month {
      text-wrap: nowrap;
    }

    ${CheckboxButton} {
      &:checked + ${CheckboxButtonLabel} {
        &::before {
          border-width: 0 3px 3px 0;
        }
      }
    }
  `
);
