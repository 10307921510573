import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { checkSlidesPerView } from "../../helpers/helpers";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import { IBoxSearchResultsDisplaySettings } from "./BoxSearchResults.types";

export const StyledSearchResultsGrid = styled.div<{
  perRow: number;
  space: IBoxSearchResultsDisplaySettings["grid"];
}>((props) => {
  const isMobile = useIsMobilePortal();
  const { perRow, space } = props;
  return `
    display: grid;
    grid-template-columns: repeat(${checkSlidesPerView(
      perRow,
      isMobile ?? false
    )}, 1fr);
    gap: ${space.spaceBetweenRows}px ${space.spaceBetweenProducts}px;
  `;
});

export const StyledNavigation = styled.div<{
  align: AlignmentHorizontalOption;
  sameTypography?: boolean;
}>`
  display: flex;
  justify-content: ${({ align }) => alignmentOptionsToValue(align)};
  margin: 24px 0;
  gap: 16px;

  .sort-container,
  .show-on-page-container {
    display: flex;
    align-items: center;
    ${({ sameTypography }) =>
      sameTypography
        ? `
            &__dropdown-container {
              height: 100%;
            
              &__list-element {
                padding: 5px 0;
              }
            
              .dropdown-wrapper {
                height: 100%;
            
                &__button-container {
                  height: 100%;
            
                  button {
                    height: 100%;
                  }
                }
              }
            }
          `
        : ""};
  }
`;
