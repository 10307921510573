import Link from "next/link";
import classNames from "classnames";
import { PageCategoryType } from "@ecp-pageTypes";
import { isPortalSide } from "../../helpers/helpers";
import StyledOption from "../../shared/styleElements/StyledOption/StyledOption";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { StyledBoxProductVariants } from "./BoxProductVariants.styled";
import { BoxProductVariantsProps } from "./BoxProductVariants.types";
import useBoxProductVariants from "./useBoxProductVariants";

const BoxProductVariants: React.FC<BoxProductVariantsProps> = (props) => {
  const { pageContext, selectedVariant, productVariants, contextProductSKU } =
    useBoxProductVariants(props);

  if (!isPortalSide() && (!productVariants?.length || !selectedVariant)) {
    return <div>No content to display</div>;
  } else if (
    (pageContext.type !== PageCategoryType.PRODUCT && isPortalSide()) ||
    !selectedVariant ||
    !productVariants?.length
  ) {
    return null;
  }

  return (
    <StyledBoxProductVariants variant={selectedVariant}>
      <div className="variants-wrapper">
        {selectedVariant.label.show && (
          <StyledText
            className="label"
            $settings={{
              font: { id: selectedVariant.label.style },
              text: { color: selectedVariant.label.color },
            }}
          >
            {selectedVariant.label.name}
          </StyledText>
        )}
        <div className="variants-content">
          {productVariants.map((variant) => (
            <Link href={variant.productUrl}>
              <a>
                <StyledOption
                  className={classNames(
                    variant.sku === contextProductSKU && "active-button"
                  )}
                  option={selectedVariant.optionStyle}
                  text={variant.attribute.label}
                  disabled={!variant.inStock}
                />
              </a>
            </Link>
          ))}
        </div>
      </div>
    </StyledBoxProductVariants>
  );
};

export default BoxProductVariants;
