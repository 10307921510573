import { Dispatch, SetStateAction } from "react";
import { IElementTextContent } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { Radio } from "../../../../shared/components/Radio/Radio";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxSearchResultsMessages,
  IBoxSearchResultsSettings,
} from "../../BoxSearchResults.types";
import { useSearchResultsLogic } from "../useSearchResultsLogic";
import {
  StyledSearchResultsLabel,
  StyledSearchResultsParameters,
} from "./ExtendedTopNavigation.styled";

interface ExtendedShowOnPageProps {
  label: string;
  showOnPageSettings: IElementTextContent;
  chooseTemporaryProductsPerPage: Dispatch<SetStateAction<number>>;
  temporaryProductsPerPage: number;
}

const ExtendedShowOnPage: React.FC<ExtendedShowOnPageProps> = ({
  showOnPageSettings,
  label,
  chooseTemporaryProductsPerPage,
  temporaryProductsPerPage,
}) => {
  const { settings } = useMessagesSettingsContext<
    IBoxSearchResultsMessages,
    IBoxSearchResultsSettings
  >();

  const { showOptions } = useSearchResultsLogic();

  if (!showOnPageSettings.show) return null;

  return (
    <div className="navigation__left-area__extended-show-on-page">
      <StyledSearchResultsLabel
        $settings={showOnPageSettings}
        backgroundColor={settings.srp_mobile_sorting_parameter_background_color}
      >
        {label}
      </StyledSearchResultsLabel>
      <StyledSearchResultsParameters
        $settings={showOnPageSettings}
        renderAs="ul"
      >
        {showOptions.map((el) => (
          <li key={el}>
            <Radio
              onClick={() => chooseTemporaryProductsPerPage(el)}
              checked={temporaryProductsPerPage === el}
            >
              {el}
            </Radio>
          </li>
        ))}
      </StyledSearchResultsParameters>
    </div>
  );
};

export default ExtendedShowOnPage;
