import PortalSwitch from "../../../../../shared/components/PortalSwitch/PortalSwitch";
import { useCartB2BContext } from "../../../../../shared/components/domain/CartB2B/useCartB2BContext";
import { useMessagesSettingsContext } from "../../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxCartB2BMessages,
  IBoxCartB2BSettings,
} from "../../BoxCartB2B.types";

const SwitcherB2B = () => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxCartB2BMessages,
    IBoxCartB2BSettings
  >();

  const { changeNetGrossPrice, grossPrice, status } = useCartB2BContext();

  return (
    <PortalSwitch
      checked={grossPrice}
      handleChange={changeNetGrossPrice}
      styleSettings={{
        backgroundColor: settings.basket_header_prices_switch_background_color,
        borderColor: settings.basket_header_prices_switch_border_color,
        borderSize: settings.basket_header_prices_switch_border_size,
        dotColor: settings.basket_header_prices_switch_drop_color,
      }}
      leftLabel={{
        text: messages.basket_header_prices_switch_price_netto_label,
        typo: settings.basket_data_typo2,
        color: settings.basket_data_color2,
      }}
      rightLabel={{
        text: messages.basket_header_prices_switch_price_gross_label,
        typo: settings.basket_data_typo,
        color: settings.basket_data_color,
      }}
      disabled={status !== "OK"}
    />
  );
};

export default SwitcherB2B;
