import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { IElementInput } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import { TInputId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  StyledTextarea,
  StyledTextareaComponent,
  StyledTextareaLabel,
  StyledTextareaValidation,
} from "./StyledTextarea/StyledTextarea";

interface ITextareaProps<T extends FieldValues> extends UseControllerProps<T> {
  $settings: IElementInput | TInputId;
  label: string;
  placeholder: string;
  className?: string;
}

export interface ITextareaSettings {
  $settings: IElementInput | TInputId;
  noValidate?: boolean;
}

export const Textarea = <T extends FieldValues>(
  props: ITextareaProps<T>
): JSX.Element => {
  const { $settings, label, placeholder, className } = props;
  const { field, fieldState } = useController(props);
  return (
    <div className={className ?? "input-textarea"}>
      <StyledTextareaComponent $settings={$settings}>
        <StyledTextareaLabel
          $settings={$settings}
          className={
            className ? `${className}__label` : "input-textarea__label"
          }
        >
          {label}
        </StyledTextareaLabel>
        <StyledTextarea
          $settings={$settings}
          placeholder={placeholder}
          noValidate={fieldState.error === undefined ? true : false}
          className={
            className ? `${className}__input` : "input-textarea__input"
          }
          {...field}
        />
        {fieldState.error !== undefined && (
          <StyledTextareaValidation
            $settings={$settings}
            role="alert"
            className={
              className
                ? `${className}__validation`
                : "input-textarea__validation"
            }
          >
            {fieldState.error.message}
          </StyledTextareaValidation>
        )}
      </StyledTextareaComponent>
    </div>
  );
};
