import React, { memo, useState } from "react";
import { useTheme } from "styled-components";
import {
  AlignmentHorizontalOption,
  IThemeState,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";
import Popup from "../../../../shared/components/Popup/Popup";
import CloseIcon from "../../../../shared/icons/CloseIcon";
import HamburgerMenuIcon from "../../../../shared/icons/HamburgerMenuIcon";
import { IMultiMenuHorizontalProps } from "../../BoxMultiMenu.types";
import {
  StyledMultimenu,
  StyledMultimenuContent,
  StyledMultimenuIconContainer,
  StyledMultimenuMobileItems,
} from "./MultiMenuMobile.styled";
import MultiMenuMobileGroup from "./MultiMenuMobileGroup";

const INITIAL_GROUP_INDEX = -1;

const MultiMenuMobile: React.FC<IMultiMenuHorizontalProps> = ({
  content,
  settings,
  path,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showGroupIndex, setShowGroupIndex] = useState(INITIAL_GROUP_INDEX);
  const theme = useTheme() as IThemeState;
  const alignment =
    settings?.multimenu_mobile_hamburger_aligment ||
    AlignmentHorizontalOption.LEFT;

  return (
    <StyledMultimenu
      alignment={alignment}
      className="multi-menu-mobile-container"
    >
      <StyledMultimenuIconContainer $settings={settings}>
        <HamburgerMenuIcon
          fill={settings?.multimenu_mobile_hamburger_color}
          onClick={() => setIsOpen(true)}
          width={settings.multimenu_mobile_hamburger_size}
          height={settings.multimenu_mobile_hamburger_size}
        />
      </StyledMultimenuIconContainer>

      {isOpen && (
        <Popup className="multi-menu-mobile-container__popup h-full">
          <StyledMultimenuContent
            closeBackgroundColor={
              settings?.multimenu_mobile_right_background_color
            }
            backgroundColor={settings?.multimenu_mobile_background_color}
          >
            <StyledMultimenuMobileItems $settings={settings}>
              {content.groups.length &&
                content.groups?.map((group, groupIndex) => {
                  return (
                    <MultiMenuMobileGroup
                      key={group.heading + groupIndex}
                      path={path}
                      group={group}
                      setShowGroupIndex={setShowGroupIndex}
                      showGroupIndex={showGroupIndex}
                      onClose={() => setIsOpen(false)}
                      groupIndex={groupIndex}
                      settings={settings}
                    />
                  );
                })}
            </StyledMultimenuMobileItems>
            <div
              className="closeIconContainer"
              onClick={() => {
                setIsOpen(false);
                setShowGroupIndex(INITIAL_GROUP_INDEX);
              }}
            >
              <CloseIcon
                fill={convertColorIdToHex(
                  settings.multimenu_mobile_close_icon_color,
                  theme.colorPalette
                )}
              />
            </div>
          </StyledMultimenuContent>
        </Popup>
      )}
    </StyledMultimenu>
  );
};

export default memo(MultiMenuMobile);
