export enum MyAddressType {
  POSTAL = "POSTAL",
  PACKSTATION = "PACKSTATION",
  STORE = "STORE",
  PICK_UP_POINT = "PICK_UP_POINT",
  BILLING = "BILLING",
}

export interface MyAddress {
  id: number;
  city: string;
  zipCode: string;
  street: string;
  streetNumber: string;
  defaultAddress: boolean;
  packstationAvailable: boolean; //TODO what is this?
}

export type TMyAddresses =
  | MyAddressPackstation
  | MyAddressPickUpPoint
  | MyAddressPostalBilling
  | MyAddressStore;

export interface MyAddressPackstation extends MyAddress {
  packstationCode: string;
}
export interface MyAddressPickUpPoint extends MyAddress {
  storeName: string;
  pni: number;
}
export interface MyAddressPostalBilling extends MyAddress {
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  flatNumber: string;
  nip?: string;
  companyName?: string;
}
export interface MyAddressStore extends MyAddress {
  storeName: string;
  storeCode: string;
  storeClickAndCollect: string;
}

export const isMyPackstationAddress = (
  type: TMyAddresses | null
): type is MyAddressPackstation => type != null && "packstationCode" in type;

export const isMyPickUpPointAddress = (
  type: TMyAddresses | null
): type is MyAddressPickUpPoint => type != null && "pni" in type;

export const isMyPostalBillingAddress = (
  type: TMyAddresses | null
): type is MyAddressPostalBilling => type != null && "firstName" in type;

export const isMyStoreAddress = (
  type: TMyAddresses | null
): type is MyAddressStore => type != null && "storeCode" in type;

export interface IMyAddressesResponse {
  billingAddresses: MyAddressPostalBilling[];
  storeAddresses: MyAddressStore[];
  packstationAddresses: MyAddressPackstation[];
  postalAddresses: MyAddressPostalBilling[];
  pickUpPointAddresses: MyAddressPickUpPoint[];
}
export interface IMyAddressesAdd {
  addressId: number;
}

export interface IBillingPostalAddMyAddressRequest {
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  flatNumber: string;
  zipCode: string;
  city: string;
  country?: string;
  phone: string;
  companyName?: string; //only use in company
  nip?: string; //only use in company
  type: "POSTAL";
  billing: boolean;
  defaultAddress: boolean;
}

export interface IPackstationAddMyAddressRequest {
  packstationCode: string;
  type: MyAddressType.PACKSTATION;
}

export interface IStoreAddMyAddressRequest {
  storeCode: string;
  type: MyAddressType.STORE;
}

export type TAddMyAddressRequest =
  | IBillingPostalAddMyAddressRequest
  | IPackstationAddMyAddressRequest
  | IStoreAddMyAddressRequest;
