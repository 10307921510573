import { parseTimeToTimezoneOffset } from "../helpers";
import { API, api } from "./api";

export type TDeliveryScheduleSingleResultPayload = {
  projection: string[];
};

export const deliverySchedule = api.injectEndpoints({
  endpoints: (builder) => ({
    getDeliveryScheduleColumnNames: builder.query<
      { columnNames: string[] },
      void
    >({
      query: () => {
        return API.getDeliveryScheduleColumnNames.endpoint();
      },
      providesTags: ["CONTRACTORS"],
    }),
    postDeliveryScheduleSingleResult: builder.query<
      void,
      TDeliveryScheduleSingleResultPayload
    >({
      query: (body) => {
        return {
          url: API.postDeliveryScheduleSingleResult.endpoint(),
          method: "POST",
          headers: {
            "time-zone": parseTimeToTimezoneOffset(
              new Date().getTimezoneOffset()
            ),
          },
          body,
        };
      },
      providesTags: ["CONTRACTORS"],
    }),
  }),
});

export const {
  useLazyGetDeliveryScheduleColumnNamesQuery,
  usePostDeliveryScheduleSingleResultQuery,
} = deliverySchedule;

export const {
  getDeliveryScheduleColumnNames,
  postDeliveryScheduleSingleResult,
} = deliverySchedule.endpoints;
