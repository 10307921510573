import { useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import * as contact from "@ecp-redux/api/contact";
import { yupResolver } from "@hookform/resolvers/yup";
import htmlToReact from "../../../helpers/HtmlToReactParser";
import { isNotEmptyMessage } from "../../../helpers/isNotEmptyMessage";
import {
  isEmail,
  isNIP,
  isPhone,
  isRequiredTextByLength,
  maxLength,
} from "../../../helpers/validators";
import { useAddAlert } from "../../../redux/slices/alertsSlice";
import Alert from "../../../shared/components/Alert/Alert";
import { InputPhone, InputText } from "../../../shared/components/Input/Inputs";
import { Textarea } from "../../../shared/components/Textarea/Textarea";
import useScrollIntoElement from "../../../shared/hooks/useScrollIntoElement";
import StyledButton from "../../../shared/styleElements/StyledButton/StyledButton";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledContactForm } from "../BoxContactForm.styled";
import {
  IBoxContactFormMessages,
  IBoxContactFormSettings,
} from "../BoxContactForm.types";
import UploadFilesManager from "../UploadFilesManager";

export interface IComplaintForm {
  topic: string;
  complainReason: string;
  firstName: string;
  lastName: string;
  nip: string;
  returnAddress: string;
  emails: string;
  phone: string;
  invoiceNumber?: string;
  message: string;
}

const ComplaintForm = () => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxContactFormMessages,
    IBoxContactFormSettings
  >();
  const [filesList, setFilesList] = useState<File[] | null>([]);
  const { addAlert } = useAddAlert();

  const complaintFormRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = useScrollIntoElement(complaintFormRef);

  const [postContactForm] = contact.usePostContactFormMutation();

  const complainDefaultValues: IComplaintForm = {
    topic: "",
    complainReason: "",
    firstName: "",
    lastName: "",
    nip: "",
    returnAddress: "",
    emails: "",
    phone: "",
    invoiceNumber: "",
    message: "",
  };

  const validationSchema = yup.object().shape({
    topic: isRequiredTextByLength(
      messages.contact_form_empty_verification_topic,
      messages.contact_form_short_input_length_exceeded,
      100
    ),
    complainReason: isRequiredTextByLength(
      messages.complaint_form_empty_verification_complaint_reason,
      messages.complaint_form_reason_input_exceeded,
      50
    ),
    firstName: isRequiredTextByLength(
      messages.contact_form_empty_verification_firstName,
      messages.contact_form_short_input_length_exceeded,
      100
    ),
    lastName: isRequiredTextByLength(
      messages.contact_form_empty_verification_lastName,
      messages.contact_form_short_input_length_exceeded,
      100
    ),
    nip: isNIP(messages.form_syntax_verification_nip),
    returnAddress: isRequiredTextByLength(
      messages.complaint_form_empty_verification_return_adress,
      messages.complaint_form_return_adress_input_exceeded,
      100
    ),
    emails: isEmail(
      messages.contact_form_empty_verification_email,
      messages.contact_form_syntax_verification_email
    ),
    phone: isPhone(messages.contact_form_verification_phone).required(
      messages.contact_form_empty_verification_phone
    ),
    invoiceNumber: maxLength(
      100,
      messages.complaint_form_invoice_number_exceeded
    ),
    message: isRequiredTextByLength(
      messages.contact_form_empty_verification_textArea,
      messages.contact_form_long_input_length_exceeded,
      500
    ),
  });

  const formMethods = useForm<IComplaintForm>({
    resolver: yupResolver(validationSchema),
    mode: "onBlur",
    delayError: 500,
    defaultValues: complainDefaultValues,
  });
  const { handleSubmit, control, reset } = formMethods;
  const onSubmit = (data: IComplaintForm) => {
    postContactForm({
      ...data,
      complainModel: data.topic,
      complainForm: "true",
      ...(filesList && { files: filesList }),
    })
      .unwrap()
      .then(() => {
        reset();
        setFilesList(null);
        addAlert({
          code: "210",
          message: messages.contact_form_success,
        });
      })
      .catch(() => {
        addAlert({
          code: "410",
          message: messages.contact_form_failure,
        });
      });
    scrollIntoElement();
  };

  return (
    <StyledContactForm
      buttonWidth={settings.contact_form_submit_button_width}
      inputStyleId={settings.contact_form_input_style}
      formElementsGap={settings.contact_form_elements_gap}
      titleSpacing={settings.contact_form_title_spacing}
      termsSpacing={settings.contact_form_terms_spacing}
      backgroundColor={settings.contact_form_background_color}
      submitButtonBottomSpacing={
        settings.contact_form_submit_button_bottom_spacing
      }
      className="complaint-form-container"
      ref={complaintFormRef}
    >
      <>
        <StyledText
          className="complaint-form-container__title"
          $settings={{
            font: settings.contact_form_title_typo,
            text: { color: settings.contact_form_title_color },
          }}
          alignment={settings.contact_form_title_align?.toLowerCase()}
          show={isNotEmptyMessage(messages.contact_form_title)}
        >
          {messages.contact_form_title}
        </StyledText>

        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit((d) => onSubmit(d))}
            autoComplete="off"
            className="complaint-form"
          >
            <Alert
              alertsCodes={["210"]}
              backgroundColor={settings.contact_form_background_success_color}
              textColor={settings.contact_form_success_info_text_color}
              typography={settings.contact_form_success_info_typo}
              closeable={true}
              lifetime={5000}
            />
            <Alert
              alertsCodes={["410"]}
              backgroundColor={settings.contact_form_background_error_color}
              textColor={settings.contact_form_error_info_text_color}
              typography={settings.contact_form_error_info_typo}
              closeable={true}
              lifetime={5000}
            />
            <InputText
              className="complaint-form__model"
              name="topic"
              control={control}
              placeholder={messages.contact_form_placeholder_topic}
              label={messages.contact_form_heading_topic}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="complaint-form__reason"
              name="complainReason"
              control={control}
              placeholder={messages.complaint_form_placeholder_reason}
              label={messages.complaint_form_heading_reason}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="complaint-form__first-name"
              name="firstName"
              control={control}
              placeholder={messages.contact_form_placeholder_name}
              label={messages.contact_form_heading_name}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="complaint-form__last-name"
              name="lastName"
              control={control}
              placeholder={messages.contact_form_placeholder_surname}
              label={messages.contact_form_heading_surname}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="complaint-form__nip"
              name="nip"
              control={control}
              placeholder={messages.complaint_form_placeholder_nip}
              label={messages.complaint_form_heading_nip}
              $settings={settings.contact_form_input_style}
              mask="999-999-99-99"
            />
            <InputText
              className="complaint-form__return-address"
              name="returnAddress"
              control={control}
              placeholder={messages.complaint_form_placeholder_address}
              label={messages.complaint_form_heading_address}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="complaint-form__email"
              name="emails"
              control={control}
              placeholder={messages.contact_form_placeholder_email}
              label={messages.contact_form_heading_email}
              $settings={settings.contact_form_input_style}
            />
            <InputPhone
              className="complaint-form__phone"
              name="phone"
              control={control}
              placeholder={messages.contact_form_placeholder_phone}
              label={messages.contact_form_heading_phone}
              $settings={settings.contact_form_input_style}
            />
            <InputText
              className="contact-form__invoice-number"
              name="invoiceNumber"
              control={control}
              placeholder={messages.complaint_form_placeholder_invoice_number}
              label={messages.complaint_form_heading_invoice_number}
              $settings={settings.contact_form_input_style}
            />
            <Textarea
              className="complaint-form__message"
              name="message"
              control={control}
              placeholder={messages.contact_form_placeholder_textArea}
              label={messages.contact_form_heading_textArea}
              $settings={settings.contact_form_textArea_style}
            />
            <UploadFilesManager
              setList={(filesList) => setFilesList(filesList)}
              resetFilesList={filesList === null}
            />
            <StyledText
              className="complaint-form__terms terms"
              $settings={{
                font: settings.contact_form_information_typo,
                text: { color: settings.contact_form_information_text_color },
              }}
              show={isNotEmptyMessage(messages.contact_form_information)}
            >
              {htmlToReact(
                messages.contact_form_information,
                settings.contact_form_link_style,
                settings.contact_form_link_basic_color
              )}
            </StyledText>
            <StyledButton
              className="complaint-form__save-button"
              renderAs="button"
              type="submit"
              $settings={settings.contact_form_button_style}
              data-testid="submitButton"
              show={isNotEmptyMessage(messages.contact_form_save_button)}
            >
              {messages.contact_form_save_button}
            </StyledButton>
          </form>
        </FormProvider>
      </>
    </StyledContactForm>
  );
};

export default ComplaintForm;
