import { useEffect, useState } from "react";
import {
  A11y,
  FreeMode,
  Navigation,
  Swiper as SwiperProps,
  Thumbs,
} from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { TSrpProductType } from "@ecp-redux/dto/searchResults.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../../../global/components/ImageWrapper/ImageWrapper";
import { useImageSize } from "../../../../global/components/ImageWrapper/ImageWrapper.methods";
import ClipboardIcon from "../../../../shared/components/Clipboard/ClipboardIcon";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import { isOnePhotoOrMore } from "../../BoxPhotoGallery.method";
import {
  IBoxPhotoGallerySettings,
  IPhotoGallerySliderProps,
} from "../../BoxPhotoGallery.types";
import { StyledPhotoGallerySlider } from "./PhotoGallerySlider.styles";

export const PhotoGallerySlider: React.FC<IPhotoGallerySliderProps> = ({
  images,
  handleClick,
  setActiveSlide,
  productData = {
    productName: "",
    productSku: "",
    wishlist: { base: false, concept: false },
    type: TSrpProductType.PRODUCT,
  },
}) => {
  const { productName, productSku, wishlist, type } = productData;

  const [imageSwiper, setImageSwiper] = useState<SwiperProps>();
  const [gallerySwiper, setGallerySwiper] = useState<SwiperProps>();
  const [imgWrapper, imageWidth] = useImageSize();
  const [activeIndex, setActiveIndex] = useState(0);

  const { settings } = useMessagesSettingsContext<
    Record<string, never>,
    IBoxPhotoGallerySettings
  >();

  const handleClickSwiperSlide = (index: number) => {
    if (index === activeIndex) {
      return;
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (!imageSwiper) return;
    imageSwiper.slideTo(activeIndex);
    setActiveSlide(activeIndex);
  }, [activeIndex]);

  return (
    <StyledPhotoGallerySlider
      ref={imgWrapper}
      disabled={!isOnePhotoOrMore(images)}
      className="photo-gallery-slider-container"
    >
      <div className="photo-gallery-slider-container__photo-gallery-slider">
        <div className="photo-gallery-slider-container__photo-gallery-slider__image">
          <Swiper
            modules={[Navigation, A11y, Thumbs]}
            thumbs={{
              swiper:
                gallerySwiper && !gallerySwiper.destroyed
                  ? gallerySwiper
                  : null,
            }}
            navigation
            onSwiper={setImageSwiper}
            preventClicks={false}
          >
            {settings.wishlist_icon_visible === "true" && productSku && (
              <ClipboardIcon
                isOnWishList={wishlist.base}
                productData={{
                  sku: productSku,
                  type,
                }}
              />
            )}
            {images?.map((image, index) => (
              <SwiperSlide
                key={index}
                onClick={handleClick}
                data-testid="slider-gallery"
                className="photo-gallery-slider-container__photo-gallery-slider__slide"
              >
                <div
                  onClick={() => handleClickSwiperSlide(index)}
                  className="photo-gallery-slider-container__photo-gallery-slider__image--image-container"
                >
                  <ImageWrapper
                    imageUrl={image ?? null}
                    seoDescription={productName}
                    imageFit={true}
                    imageAlignment={{
                      horizontal: AlignmentHorizontalOption.CENTER,
                      vertical: AlignmentVerticalOption.CENTER,
                    }}
                    width={imageWidth}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {images && images?.length > 1 && (
          <div className="photo-gallery-slider-container__photo-gallery-slider__gallery">
            <Swiper
              modules={[Navigation, FreeMode, A11y]}
              watchSlidesProgress
              slidesPerView={5}
              freeMode={{ enabled: true, sticky: true }}
              navigation
              onSwiper={setGallerySwiper}
              preventClicks={false}
            >
              {images?.map((image, index) => (
                <SwiperSlide
                  key={index}
                  className={
                    index === 0
                      ? "photo-gallery-slider-container__photo-gallery__slider__gallery__single-slide__first-element"
                      : "photo-gallery-slider-container__photo-gallery__slider__gallery__single-slide"
                  }
                >
                  <div
                    onClick={() => handleClickSwiperSlide(index)}
                    className="photo-gallery-slider-container__photo-gallery-slider__gallery--image-container"
                  >
                    <ImageWrapper
                      imageUrl={image ?? null}
                      seoDescription={productName}
                      imageFit={true}
                      imageAlignment={{
                        horizontal: AlignmentHorizontalOption.CENTER,
                        vertical: AlignmentVerticalOption.CENTER,
                      }}
                      width={imageWidth}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </StyledPhotoGallerySlider>
  );
};

export default PhotoGallerySlider;
