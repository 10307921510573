import { TArticle, TArticleCategory } from "../dto/article.type";
import {
  IPaginatedGridResponse,
  IPaginationGridPayload,
} from "../dto/paginationResponse.types";
import { API, api } from "./api";

export type IArticleCategoriesResponse = IPaginatedGridResponse<
  TArticleCategory[]
>;

export interface IArticleCategoriesPayload {
  page?: { number: number; size: number };
}

export type IArticlesResponse = IPaginatedGridResponse<TArticle[]>;

export const articles = api.injectEndpoints({
  endpoints: (builder) => ({
    getArticles: builder.mutation<IArticlesResponse, IPaginationGridPayload>({
      query: (body) => ({
        url: API.getArticles.endpoint(),
        method: "POST",
        body,
      }),
    }),
    getArticleCategories: builder.mutation<
      IArticleCategoriesResponse,
      IArticleCategoriesPayload
    >({
      query: ({ page }) => ({
        url: API.getArticleCategories.endpoint(),
        method: "POST",
        body: {
          page: {
            ...page,
          },
          sort: [],
          filter: [],
        },
      }),
    }),
  }),
});

export const {
  useGetArticleCategoriesMutation,
  useGetArticlesMutation,
  util: { getRunningOperationPromises },
} = articles;

export const { getArticles, getArticleCategories } = articles.endpoints;
