import React from "react";
import { IIcon } from "./Icon.types";

const ArrowLeftIcon: React.FC<IIcon> = ({
  width,
  height,
  fill,
  viewBox,
  onClick,
  className,
}: IIcon): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      onClick={onClick}
      className={className}
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 19l-7-7 7-7"
      ></path>
    </svg>
  );
};

ArrowLeftIcon.defaultProps = {
  width: "12",
  height: "12",
  fill: "#000",
  viewBox: "0 0 24 24",
};

export default ArrowLeftIcon;
