import { format } from "date-fns";
import {
  TAddresses,
  isPackstationAddress,
  isPostalBillingAddress,
  isStoreAddress,
} from "@ecp-redux/dto/cartAddresses.type";
import { IOrderDetailsResponse } from "@ecp-redux/dto/myOrders.types";
import {
  IBoxMyAccountMyOrdersDetailsMessages,
  IBoxMyAccountMyOrdersDetailsSettings,
} from "../BoxMyAccountMyOrdersDetails.types";

const createAddress = (address: TAddresses) => {
  return [
    ...(isStoreAddress(address) ? [address.storeName] : []),
    ...(isPackstationAddress(address) ? [address.packstationCode] : []),
    ...(isPostalBillingAddress(address)
      ? [`${address.firstName} ${address.lastName} ${address.phone}`]
      : []),
    ...(isPostalBillingAddress(address) ? [address.companyName ?? ""] : []),
    ...(isPostalBillingAddress(address) ? [address.nip ?? ""] : []),
    `${address.street} ${address.streetNumber ?? ""}${
      isPostalBillingAddress(address) &&
      address.flatNumber &&
      address.streetNumber
        ? `/${address.flatNumber}`
        : ""
    }`,
    `${address.zipCode} ${address.city}`,
    ...(isPostalBillingAddress(address) ? [address.country] : []),
  ];
};

export const getOrderDetailsMainInfoHeadingElements = (
  messages: IBoxMyAccountMyOrdersDetailsMessages,
  settings: IBoxMyAccountMyOrdersDetailsSettings,
  order: IOrderDetailsResponse["order"]
) => {
  return [
    {
      fontLabelTypo:
        settings.customer_data_order_details_heading_order_status_label_typo,
      textLabelColor:
        settings.customer_data_order_details_heading_order_status_label_color,
      messageLabel: messages.customer_data_order_details_heading_order_status,

      fontValueTypo:
        settings.customer_data_order_details_heading_order_status_value_typo,
      textValueColor:
        settings.customer_data_order_details_heading_order_status_value_color,
      value: order.orderStatus,
    },
    {
      fontLabelTypo:
        settings.customer_data_order_details_heading_date_label_typo,
      textLabelColor:
        settings.customer_data_order_details_heading_date_label_color,
      messageLabel: messages.customer_data_order_details_heading_date,

      fontValueTypo:
        settings.customer_data_order_details_heading_date_value_typo,
      textValueColor:
        settings.customer_data_order_details_heading_date_value_color,
      value: format(new Date(order.creationDate), "dd.MM.yyyy"),
    },
    {
      fontLabelTypo:
        settings.customer_data_order_details_order_payment_type_label_typo,
      textLabelColor:
        settings.customer_data_order_details_order_payment_type_label_color,
      messageLabel: messages.customer_data_order_details_order_payment_type,

      fontValueTypo:
        settings.customer_data_order_details_order_payment_type_value_typo,
      textValueColor:
        settings.customer_data_order_details_order_payment_type_value_color,
      value: messages[`payment_name_${order.orderPayment.paymentTypes[0]}`],
    },
    {
      fontLabelTypo:
        settings.customer_data_order_details_order_delivery_address_label_typo,
      textLabelColor:
        settings.customer_data_order_details_order_delivery_address_label_color,
      messageLabel: messages.customer_data_order_details_order_delivery_address,

      fontValueTypo:
        settings.customer_data_order_details_order_delivery_address_value_typo,
      textValueColor:
        settings.customer_data_order_details_order_delivery_address_value_color,
      value: createAddress(order.mainShippingAddress),
    },
    {
      fontLabelTypo:
        settings.customer_data_order_details_order_invoice_address_label_typo,
      textLabelColor:
        settings.customer_data_order_details_order_invoice_address_label_color,
      messageLabel: messages.customer_data_order_details_order_invoice_address,
      fontValueTypo:
        settings.customer_data_order_details_order_invoice_address_value_typo,
      textValueColor:
        settings.customer_data_order_details_order_invoice_address_value_color,
      value: createAddress(order.billingAddress),
    },
  ];
};
