import styled from "styled-components";
import { TColorId } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { MOBILE_BREAKPOINT } from "../../../../global/global";
import { convertColorIdToHex } from "../../../../settingsPatterns/settingsPatterns.methods";

export const StyledOrderSummary = styled.div<{
  backgroundColor: TColorId;
}>(({ backgroundColor, theme }) => {
  const bg = convertColorIdToHex(backgroundColor, theme.colorPalette);
  return `
      background-color: ${bg};
      padding: 20px;
      .order-summary-container__header{
        margin-bottom: 20px;
      }

      .order-summary-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 20px;

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }

        .grid-item-1{
          display: flex;
          flex-direction: column;
          gap: 20px;

          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            order: 2;
          }
        }

        .grid-item-2{
          display: flex;
          flex-direction: column;
          gap: 20px;
          
          @media (max-width: ${MOBILE_BREAKPOINT}px) {
            order: 1;
          }
        }
      }

      .order-summary-container {
        &__email-container, &__payment-method-container, &__order-date-container, &__phone-container, &__status-container, &__delivery-time-container, &__delivery-address-container {
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
        }
      }
    `;
});
