import { formatPrice } from "../../../helpers/helpers";
import useIsMobilePortal from "../../../shared/hooks/useIsMobilePortal";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import { StyledBoxMiniCartB2BButton } from "../BoxMiniCartB2B.styled";
import {
  IBoxMiniCartB2BMessages,
  IBoxMiniCartB2BSettings,
} from "../BoxMiniCartB2B.types";

interface IBoxMiniCartB2BButtonProps {
  handleClick: () => void;
  productsCount: number;
  amount: { grossPrice: number; netPrice: number };
}

const BoxMiniCartB2BButton: React.FC<IBoxMiniCartB2BButtonProps> = ({
  handleClick,
  productsCount,
  amount: { grossPrice, netPrice },
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMiniCartB2BMessages,
    IBoxMiniCartB2BSettings
  >();
  const isMobile = useIsMobilePortal();

  return (
    <StyledBoxMiniCartB2BButton
      alignment={settings.mini_cart_icon_position}
      iconSize={settings.mini_cart_icon_size}
      backgroundColor={settings.mini_cart_qty_object_background_color}
    >
      <div
        onClick={handleClick}
        className="mini-cart-b2b-button__container"
        data-testid="mini-cart-b2b-button__container"
      >
        <button className="mini-cart-b2b-button__container__button">
          {settings.mini_cart_icon_URL !== "" && (
            <img
              alt="delivery icon"
              width={settings.mini_cart_icon_size}
              src={settings.mini_cart_icon_URL}
            />
          )}
        </button>
        {productsCount != null && productsCount !== 0 && (
          <div className="mini-cart-b2b-button__container__products">
            <StyledText
              $settings={{
                font: { id: settings.mini_cart_qty_object_typo },
                text: { color: settings.mini_cart_qty_object_color },
              }}
            >
              {productsCount}
            </StyledText>
          </div>
        )}
      </div>
      {!isMobile && (
        <div className="mini-cart-b2b-button__amount-container">
          <StyledText
            $settings={{
              font: { id: settings.mini_cart_gross_amount_typo },
              text: { color: settings.mini_cart_gross_amount_color },
            }}
          >
            {`${formatPrice(grossPrice)} ${
              messages.mini_cart_gross_price_label
            }`}
          </StyledText>
          <StyledText
            $settings={{
              font: { id: settings.mini_cart_net_amount_typo },
              text: { color: settings.mini_cart_net_amount_color },
            }}
          >
            {`${formatPrice(netPrice)} ${messages.mini_cart_netto_price_label}`}
          </StyledText>
        </div>
      )}
    </StyledBoxMiniCartB2BButton>
  );
};

export default BoxMiniCartB2BButton;
