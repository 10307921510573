import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { cartB2B } from "@ecp-redux/api/cartB2B";
import { isPortalSide } from "../../helpers/helpers";
import Dropdown from "../../shared/components/Dropdown/Dropdown";
import useIsMobilePortal from "../../shared/hooks/useIsMobilePortal";
import { setPublicationZIndex } from "../BoxMiniCart/BoxMiniCart.methods";
import { StyledBoxMiniCartB2BWrapper } from "./BoxMiniCartB2B.styled";
import { TBoxMiniCartB2BProps } from "./BoxMiniCartB2B.types";
import BoxMiniCartB2BButton from "./elements/BoxMiniCartB2BButton";
import BoxMiniCartB2BButtonContent from "./elements/BoxMiniCartB2BButtonContent";

const BoxMiniCartB2B: React.FC<TBoxMiniCartB2BProps> = ({
  settings,
  publicationRef,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const isMobile = useIsMobilePortal();

  const { data, refetch } = cartB2B.useGetMiniCartB2BQuery();
  const router = useRouter();

  const handleMouseOver = () => {
    if (settings.mini_cart_action_on_icon === "HOVER" && data?.count !== 0) {
      setIsOpenDropdown(true);
      setPublicationZIndex("var(--zindex-dropdown-content)", publicationRef);
    }
  };

  const handleBackdropMouseOver = () => {
    if (settings.mini_cart_action_on_icon === "HOVER") {
      setPublicationZIndex("var(--zindex-publication)", publicationRef);
      setIsOpenDropdown(false);
    }
  };

  const handleMouseClick = () => {
    settings.mini_cart_action_on_icon === "REDIRECT" &&
      router.push(settings.mini_cart_on_action_redirect_url);

    if (
      settings.mini_cart_action_on_icon === "CLICK" &&
      !isMobile &&
      data?.count !== 0
    ) {
      setPublicationZIndex("var(--zindex-dropdown-content)", publicationRef);
      setIsOpenDropdown((prev) => !prev);
    }
  };

  const handleMouseLeave = () => {
    setIsOpenDropdown(false);
  };

  useEffect(() => {
    isOpenDropdown && refetch();
  }, [isOpenDropdown]);

  useEffect(() => {
    const handleScroll = () => {
      setIsOpenDropdown(false);
    };

    document.addEventListener("scroll", handleScroll, false);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!data && !isPortalSide()) {
    return <div>No content to display</div>;
  }
  if (!data) return null;

  return (
    <StyledBoxMiniCartB2BWrapper
      className="mini-cart-b2b-container"
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseOver}
      data-testid="minicart"
    >
      <Dropdown
        isOpen={isOpenDropdown}
        setIsOpen={setIsOpenDropdown}
        openHorizontalDirection={settings.mini_cart_dropped_position}
        actionType={settings.mini_cart_action_on_icon}
        onBackDropClick={() => {
          setIsOpenDropdown(false);
        }}
        fixedWidth
        closeDropdown={handleMouseLeave}
        onBackDropMouseOver={handleBackdropMouseOver}
        buttonContent={
          <BoxMiniCartB2BButton
            handleClick={handleMouseClick}
            productsCount={data.count}
            amount={{
              grossPrice: data.summary.grossTotalCartValue,
              netPrice: data.summary.netTotalCartValue,
            }}
          />
        }
        selectContainerContent={<BoxMiniCartB2BButtonContent data={data} />}
      />
    </StyledBoxMiniCartB2BWrapper>
  );
};

export default BoxMiniCartB2B;
