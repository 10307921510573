import { createContext, useContext, useState } from "react";

export type OffsetData = {
  height?: number;
  sumHeight?: number;
  sectionUuid?: string;
  sectionId?: number;
  sticky?: boolean;
  offset?: number;
  isHeaderSection?: boolean;
  zIndex?: number;
};

export const StickyOffsetsContext = createContext<{
  offsetsData: OffsetData[];
  isAnySectionSticky: boolean;
  setSectionHeight: (sectionUuid: string, height: number) => void;
}>({
  offsetsData: [],
  isAnySectionSticky: false,
  setSectionHeight: () => null,
});

export function useStickyOffsetsContext() {
  const context = useContext(StickyOffsetsContext);
  if (!context) {
    console.log(
      "useStickyOffsetsContext must be used under StickyOffsetsContext.Provider"
    );
  }
  return context;
}

export const StickyOffsetsProvider = ({
  children,
  offsets,
}: {
  offsets: OffsetData[];
  children: React.ReactNode;
}) => {
  const [offsetsData, setOffsetData] = useState<OffsetData[]>(offsets);

  const setSectionHeight = (sectionUuid: string, height: number) => {
    setOffsetData((prev) =>
      prev.map((offset) => {
        if (offset.sectionUuid === sectionUuid) {
          return { ...offset, height };
        }
        return offset;
      })
    );
  };

  const getCalculatedOffsetsData = (offsets: OffsetData[]) => {
    let sumHeight = 0;
    return offsetsData.map((offset, idx, array) => {
      if (offset.sticky) {
        let offsetSum = 0;
        for (let i = 0; i < idx; i++) {
          if (array[i].sticky) {
            offsetSum += array[i].height || 0;
          }
        }
        array[idx].offset = offsetSum;
      }
      sumHeight += offset.height || 0;
      array[idx].sumHeight = sumHeight;

      return array[idx];
    });
  };

  return (
    <StickyOffsetsContext.Provider
      value={{
        offsetsData: getCalculatedOffsetsData(offsetsData),
        setSectionHeight,
        isAnySectionSticky: offsetsData.some((offset) => offset.sticky),
      }}
    >
      {children}
    </StickyOffsetsContext.Provider>
  );
};
