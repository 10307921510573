import styled from "styled-components";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";

export const StyledSimplyAlert = styled.div<{
  backgroundColor: `#${string}` | `color$$${number}`;
}>(({ backgroundColor, theme }) => {
  return `
        background-color: ${convertColorIdToHex(
          backgroundColor,
          theme.colorPalette
        )};
        width: 100%;
        display: flex;
        padding: 8px 12px;
        justify-content: space-between;

        .simply-alert__close-button {
            opacity: 1;

            &:hover {
                opacity: 0.25;
              }
        }
     `;
});
