import {
  IMyAccountContsents,
  IMyAccountContsentsRequestPayload,
} from "../dto/myAccount";
import { API, api } from "./api";

export const myAccount = api
  .enhanceEndpoints({ addTagTypes: ["CONSENTS"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getConsents: builder.query<IMyAccountContsents, void>({
        query: () => {
          return {
            url: API.getMyAccountConsents.endpoint(),
          };
        },
        providesTags: ["CONSENTS"],
      }),
      deleteCustomerAccount: builder.mutation<void, void>({
        query: () => {
          return {
            url: API.deleteUserAccount.endpoint(),
            method: "DELETE",
          };
        },
      }),
      putConsents: builder.mutation<void, IMyAccountContsentsRequestPayload>({
        query: (body) => {
          return {
            url: API.putMyAccountConsents.endpoint(),
            method: "PUT",
            body,
          };
        },
        invalidatesTags: ["CONSENTS"],
      }),
    }),
  });

export const {
  useGetConsentsQuery,
  useDeleteCustomerAccountMutation,
  usePutConsentsMutation,
} = myAccount;
