import { SomePropertyFrom } from "../../../boxes/BoxGetData/BoxGetData.types";
import AttributeLabel from "../AttributeLabel/AttributeLabel";
import AttributeValue from "../AttributeValue/AttributeValue";
import { isAttributeTranslationAndValueExist } from "../BoxProductAttributes.methods";
import {
  StyledAttrTableCell,
  StyledAttributeTableWrapper,
  StyledRowStyle,
} from "../BoxProductAttributes.styled";
import {
  IBoxProductAttributesContentSettings,
  IBoxProductAttributesDisplaySettings,
  ProductAttributeElement,
} from "../BoxProductAttributes.types";

interface IAttributeTableProps {
  productExtendedAttributes: ProductAttributeElement[];
  contentSettings: SomePropertyFrom<
    IBoxProductAttributesContentSettings,
    "nameStyle" | "valueStyle" | "contentAlignment"
  >;
  displaySettings: IBoxProductAttributesDisplaySettings;
  searchRedirectUrl: string;
  currency?: string;
}

const AttributeTable: React.FC<IAttributeTableProps> = ({
  productExtendedAttributes,
  contentSettings,
  displaySettings,
  searchRedirectUrl,
  currency,
}) => {
  return (
    <StyledAttributeTableWrapper
      gap={displaySettings?.tableSettings?.spaceBetweenRows}
      className="product-attributes-container__table"
    >
      {productExtendedAttributes.map((attribute) => {
        if (!isAttributeTranslationAndValueExist(attribute)) return null;

        return (
          <StyledRowStyle
            key={attribute.attributeId}
            colorsStyle={{
              even: displaySettings.tableSettings.evenRows,
              odd: displaySettings.tableSettings.oddRows,
            }}
            className={`product-attributes-container__table__row-${attribute.attributeId}`}
          >
            <div className="product-attributes-container__table__cell-wrapper">
              <StyledAttrTableCell
                width={displaySettings.tableSettings.firstColumnSize}
                alignment={contentSettings.contentAlignment}
                iconSettings={attribute.attributeIcon?.titleIcon}
                type="label"
              >
                <AttributeLabel
                  attribute={attribute}
                  contentSettings={contentSettings}
                  displaySettings={displaySettings}
                />
              </StyledAttrTableCell>

              <StyledAttrTableCell
                width={100 - displaySettings.tableSettings.firstColumnSize}
                alignment={contentSettings.contentAlignment}
                iconSettings={attribute.attributeIcon?.valuesIcons.find(
                  (icon) => icon.valueCode === attribute.attributeId
                )}
                type="value"
              >
                <AttributeValue
                  attribute={attribute}
                  contentSettings={contentSettings}
                  searchRedirectUrl={searchRedirectUrl}
                  currency={currency}
                />
              </StyledAttrTableCell>
            </div>
          </StyledRowStyle>
        );
      })}
    </StyledAttributeTableWrapper>
  );
};

export default AttributeTable;
