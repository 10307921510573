import { ReactNode, createContext, useContext } from "react";
import { ITableSettings } from "../Table.styled";

export const TableContext = createContext<ITableSettings | undefined>(
  undefined
);
export const TableContextProvider: React.FC<{
  children: ReactNode;
  tableSettings: ITableSettings;
}> = ({ children, tableSettings }) => {
  return (
    <TableContext.Provider value={tableSettings}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableConxtexProvider = () => {
  const context = useContext(TableContext);
  if (context === undefined) {
    return console.warn("Table context is undefined");
  }
  return context;
};
