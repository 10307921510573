import styled from "styled-components";
import { IIconProps } from "./Icon.types";

const Icon: React.FC<IIconProps> = ({
  children,
  targetParam = "fill",
  groupStyling,
  onClick,
  ...props
}) => {
  return (
    <StyledIcon
      onClick={(e: any) => onClick && onClick(e)}
      targetParam={targetParam}
      groupStyling={groupStyling}
      {...props}
    >
      {children}
    </StyledIcon>
  );
};

const StyledIcon = styled.button<IIconProps>((props) => {
  if (props.groupStyling ?? false) {
    return `
    *:not(:disabled) > & path {
      transition: all var(--ecp-transition);
      ${props.targetParam}: ${props.color} !important;
    }
    
    ${
      props?.hoverColor != null &&
      `*:hover > & path {
      ${props.targetParam}: ${props.hoverColor} !important;
    }`
    }
  
    ${
      props?.activeColor != null &&
      `*:active > & path {
      ${props.targetParam}: ${props.activeColor} !important;
    }`
    }
  
    ${
      props?.disabledColor != null &&
      `*:disabled > & path {
      ${props.targetParam}: ${props.disabledColor} !important;
    }`
    }
    `;
  }

  return `
    & path {
      transition: all var(--ecp-transition);
      ${props.targetParam}: ${props.color} !important;
    }
  
    ${
      props?.hoverColor != null &&
      `&:hover path {
      ${props.targetParam}: ${props.hoverColor} !important;
    }`
    }
  
    ${
      props?.activeColor != null &&
      `&:active path {
      ${props.targetParam}: ${props.activeColor} !important;
    }`
    }
  
    ${
      props?.disabledColor != null &&
      `&:disabled path {
    ${props.targetParam}: ${props.disabledColor} !important;
    }`
    }`;
});

export default Icon;
