import * as yup from "yup";
import {
  isConfirmPassword,
  isEmail,
  isPassword,
} from "../../../helpers/validators";
import { IBoxRemindPasswordMessages } from "../BoxRemind.types";

export function getValidationRemindPassSchema(
  messages: IBoxRemindPasswordMessages
) {
  return yup.object().shape({
    email: isEmail(
      messages.remind_password_form_empty_verification_email,
      messages.remind_password_form_syntax_verification_email
    ),
  });
}
export function getValidationChangePassSchema(
  messages: IBoxRemindPasswordMessages
) {
  return yup.object().shape({
    password: isPassword(
      messages.remind_password_form_empty_verification_password,
      messages.remind_password_form_syntax_verification_password
    ),
    repeatedPassword: isConfirmPassword(
      messages.remind_password_form_empty_verification_password,
      messages.remind_password_form_diffrent_verification_password
    )("password"),
  });
}
