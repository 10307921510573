import { memo } from "react";
import { isEqual } from "lodash";
import styled from "styled-components";
import { IPortalSlot, SectionType } from "@ecp-pageTypes";
import { IDisplaySettingsSection } from "@ecp-redux/dto/themeSettings/StyledSection.types";
import composeCss from "../../settingsPatterns/composeCss";
import { PortalPath } from "../../shared/portalPath/portalPath";
import Slot from "./Slot";

interface ISlotLine {
  slots: IPortalSlot[];
  $settings: IDisplaySettingsSection;
  mobileSecondLine?: boolean;
  type: SectionType;
  path: PortalPath;
}

export const StyledSlotsLine = styled.div<{
  $settings: IDisplaySettingsSection;
  columnsInSection: number;
  firstLine?: boolean;
  type: SectionType;
  slotsInSection: number;
}>((props) => {
  const { $settings, firstLine, columnsInSection, slotsInSection, theme } =
    props;
  const { spacingBetweenSlots, fullWidthBackground } = $settings;
  const isFitBackground = !fullWidthBackground;
  return `
  border-color: transparent;
  display: grid;
  max-width: 100%;
  
  //The following is needed to make the mobile view for safari browser work properly
  height: fit-content;
  height:-webkit-fit-content;

  ${composeCss.minHeight($settings.height)}
  // BELOW is content-width background for section (full-width background in StyledSection)
  ${
    isFitBackground
      ? composeCss.backgroundWithOpacity($settings.background, theme)
      : ""
  }
  grid-template-columns: repeat(${columnsInSection}, minmax(0, 1fr));

  ${
    slotsInSection > 1
      ? `column-gap: ${
          spacingBetweenSlots !== null || spacingBetweenSlots !== undefined
            ? spacingBetweenSlots
            : theme.stylePages.section.spacingBetweenSlots
        }px;`
      : ""
  }
  ${
    firstLine
      ? `margin-bottom: ${theme.stylePages.section.spacingBetweenSections}px;`
      : ""
  }
  padding: ${composeCss.padding($settings.padding)};
  ${composeCss.width($settings.width)}
  ${composeCss.minHeight($settings.height)}
  ${composeCss.borderColor($settings.border.color, theme)};
  ${composeCss.borderStyle($settings.border.style)};
  ${composeCss.borderWidth($settings.border.width)};
  #overlay-slot {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    pointer-events: none;
  }
`;
});

const SlotsLine: React.FC<ISlotLine> = ({
  slots,
  mobileSecondLine,
  $settings,
  type,
  path,
}) => {
  const columnsInSection = slots.reduce(
    (result, slot) => result + slot.columns,
    0
  );

  return (
    <StyledSlotsLine
      $settings={$settings}
      id="slots-line"
      columnsInSection={columnsInSection}
      data-testid={mobileSecondLine ? "mobileSecondLine" : "firstLineInSection"}
      firstLine={!mobileSecondLine}
      slotsInSection={slots.length}
      type={type}
    >
      {slots.map((slot: IPortalSlot, index: number) => {
        return (
          <Slot
            key={slot.id}
            slotData={slot}
            type={type}
            path={path.concatPaths(
              new PortalPath([
                {
                  type: "SLOT",
                  elementId: slot.id,
                  elementIndex: index,
                },
              ])
            )}
          />
        );
      })}
    </StyledSlotsLine>
  );
};

export default memo(SlotsLine, isEqual);
