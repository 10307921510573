import { TUrl } from "./themeSettings/themeSettings.types";

export interface TArticleCategory {
  id: number;
  name: string;
  special: boolean;
}

export type TArticleElements =
  | "image"
  | "articleTitle"
  | "articleDescription"
  | "articleCategory"
  | "articleCreationDate";

export enum ArticleListBoxType {
  SLIDER = "SLIDER",
  GRID = "GRID",
}

export enum GridPaginationOptions {
  DEFAULT = "DEFAULT",
  INFINITE_SCROLL = "INFINITE_SCROLL",
}

export enum ArticleListSourceOptions {
  ID = "id",
  STATUS = "status",
  ARTICLE_CATEGORIES = "category",
  RELATED_ARTICLES = "relatedArticle",
  RELATED_PRODUCTS = "relatedProductsSku",
  RELATED_CATEGORIES = "relatedProductCategoriesCodes",
}

export type TArticle = {
  articleUrl: string;
  categories: TArticleCategory[];
  content: string;
  createdBy: string;
  creationDate: string;
  description: string;
  editableArticleUrlPart: string;
  id: number;
  imageUrl: TUrl;
  keywords: string;
  metaDescription: string;
  metaTitle: string;
  modificationDate: string;
  modifiedBy: string;
  relatedArticlesIds: number[];
  relatedProductCategoriesCodes: string[];
  relatedProductsSku: string[];
  status: string;
  title: string;
  category?: TArticleCategory;
};
