import styled from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { alignmentOptionsToValue } from "../../settingsPatterns/composeCss";

export const StyledBoxMiniCartWrapper = styled.div<{
  alignment: AlignmentHorizontalOption;
}>`
  display: grid;
  place-content: ${({ alignment }) => alignmentOptionsToValue(alignment)};

  .mini-cart-container__button-open-container {
    position: relative;
    display: flex;
    gap: 10px;
    z-index: var(--zindex-button-open-minicart-container);
    align-items: center;
    cursor: pointer;

    &__text-content {
      display: flex;
      gap: 3px;
    }

    &__text-content__products-count {
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledDropdownWrapper = styled.div<{
  margin: string;
  gap: string;
}>`
  display: flex;
  flex-direction: column;
  box-shadow: var(--ecp-shadow-card);
  padding: ${({ margin }) => margin};
  gap: ${({ gap }) => gap};
  background-color: #ffffff;
  max-height: 80vh;
  overflow: auto;

  .mini-cart-container__dropdown__container {
    display: flex;
    gap: 10px;
    vertical-align: middle;

    &__button-container {
      display: flex;
      justify-content: center;
    }
  }
`;
