import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styled from "styled-components";
import { IIcon } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  IGlobalPalleteColor,
  TColorId,
  TLinkId,
  TLinkReadoutValue,
  TLinkSet,
  TTypoId,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { scrollIntoRowPosition } from "../../../boxes/BoxListOfButtons/BoxListOfButtons.methods";
import {
  getHrefFromLinkContent,
  getOpenInNewTabFromLinkContent,
} from "../../../helpers/linkHelpers";
import composeCss from "../../../settingsPatterns/composeCss";
import {
  ILinkContent,
  SelectedGoToOption,
} from "../../../settingsPatterns/contentPatterns.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import { PortalPath } from "../../../shared/portalPath/portalPath";
import { useStickyOffsetsContext } from "../../../structure/Contexts/StickyOffsetsContext";
import ConditionalHTMLElement from "../ConditionalHTMLElement/ConditionalHTMLElement";

export interface IDynamicLinkWrapperProps<T> {
  link: ILinkContent;
  children: T;
  linkStyle?: TLinkReadoutValue | TLinkId | "basic";
  fontStyle?: TTypoReadoutValue | TTypoId;
  basicColor?: TColorId;
  path?: PortalPath;
  icon?: IIcon;
  show?: boolean;
  onClick?: () => void;
  isActive?: boolean;
}

interface IStyledLinkWrapper {
  $linkStyle?: TLinkReadoutValue | TLinkId | "basic";
  $fontStyle?: TTypoReadoutValue | TTypoId;
  basicColor?: TColorId;
}

const generateLinkStyle = (
  linkStyle: TLinkReadoutValue | TLinkId | "basic",
  globalLink: TLinkSet,
  colorP: IGlobalPalleteColor[],
  isActive?: boolean
): string => {
  if (linkStyle === "basic") {
    return `color: blue; cursor: pointer; :hover { text-decoration: underline }`;
  } else {
    return composeCss.link(linkStyle, globalLink, colorP, isActive);
  }
};

export const StyledLinkWrapper = styled(ConditionalHTMLElement).attrs(
  (props) => ({
    renderAs: props?.["renderAs"] ?? "a",
  })
)<IStyledLinkWrapper>`
  ${(props) => {
    const {
      $linkStyle,
      $fontStyle,
      $basicColor,
      $isActive,
      icon,
      theme: { globalObjects, colorPalette, typography },
    } = props;
    return `
    ${
      $linkStyle !== undefined
        ? generateLinkStyle(
            $linkStyle,
            globalObjects.links,
            colorPalette,
            $isActive
          )
        : ""
    } 
    ${$fontStyle !== undefined ? composeCss.font($fontStyle, typography) : ""}
    ${
      $basicColor !== undefined
        ? `color: ${convertColorIdToHex($basicColor, colorPalette)};`
        : ""
    }
    ${icon ? composeCss.buttonSettings.icon(icon) : ""};
    `;
  }}
`;

const DynamicLinkWrapper: React.FC<
  IDynamicLinkWrapperProps<string | JSX.Element | JSX.Element[]>
> = <T extends string | JSX.Element | JSX.Element[]>({
  link,
  children,
  linkStyle,
  fontStyle,
  basicColor,
  path,
  icon,
  show = true,
  onClick,
  isActive,
}: IDynamicLinkWrapperProps<T>) => {
  const { offsetsData } = useStickyOffsetsContext();
  const router = useRouter();
  if (!show || link === null || link === undefined) {
    return null;
  }

  if (getHrefFromLinkContent(link) === "") {
    return (
      <StyledLinkWrapper
        role="link"
        onClick={onClick}
        $linkStyle={linkStyle}
        $fontStyle={fontStyle}
        $basicColor={basicColor}
        icon={icon}
      >
        {children}
      </StyledLinkWrapper>
    );
  }

  const isActiveUrl =
    isActive ??
    (link.goToPageSetting?.link &&
      router?.asPath?.endsWith(link.goToPageSetting.link));

  if (getOpenInNewTabFromLinkContent(link)) {
    return (
      <Link href={getHrefFromLinkContent(link)} passHref>
        <StyledLinkWrapper
          role="link"
          target="_blank"
          onClick={onClick}
          $linkStyle={linkStyle}
          $fontStyle={fontStyle}
          $basicColor={basicColor}
          icon={icon}
          $isActive={isActiveUrl}
        >
          {children}
        </StyledLinkWrapper>
      </Link>
    );
  }

  const goToRow = (sectionId: string | number | null) => {
    const element = document.getElementById(`SECTION=${sectionId as string}`);

    if (element) {
      // 👇 Will scroll smoothly to the top of the the indicated section id
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {link.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE &&
        (link.goToPageSetting.link && link.goToPageSetting.link[0] === "/" ? (
          <Link href={getHrefFromLinkContent(link)} passHref>
            <StyledLinkWrapper
              role="link"
              onClick={onClick}
              $linkStyle={linkStyle}
              $fontStyle={fontStyle}
              $basicColor={basicColor}
              icon={icon}
              $isActive={isActiveUrl}
            >
              {children}
            </StyledLinkWrapper>
          </Link>
        ) : (
          <Link href={getHrefFromLinkContent(link)} passHref>
            <StyledLinkWrapper
              role="link"
              onClick={onClick}
              $linkStyle={linkStyle}
              $fontStyle={fontStyle}
              $basicColor={basicColor}
              href={link.goToPageSetting.link}
              icon={icon}
              rel="noreferrer"
              target={link.goToPageSetting.openInNewTab ? "_blank" : "_self"}
              $isActive={isActiveUrl}
            >
              {children}
            </StyledLinkWrapper>
          </Link>
        ))}
      {link.selectedGoToOption === SelectedGoToOption.GO_TO_ROW &&
        link.goToRowSetting?.rowId && (
          <div
            className="go-to-row__wrapper"
            onClick={() =>
              path && scrollIntoRowPosition(link, path, offsetsData)
            }
          >
            <StyledLinkWrapper
              role="link"
              $linkStyle={linkStyle}
              $fontStyle={fontStyle}
              $basicColor={basicColor}
              icon={icon}
              $isActive={isActiveUrl}
            >
              {children}
            </StyledLinkWrapper>
          </div>
        )}
    </>
  );
};

export default DynamicLinkWrapper;
