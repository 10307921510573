import React from "react";
import Head from "next/head";
import Script from "next/script";
import styled from "styled-components";
import Popup from "../../../shared/components/Popup/Popup";

interface NewAddressFormProps {
  disableForm: () => void;
}

const StyledInpostAddressMapPopup = styled(Popup)`
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .inpost-geowidget {
    height: 720px;
  }
`;

const InpostAddressMap: React.FC<NewAddressFormProps> = ({ disableForm }) => {
  return (
    <StyledInpostAddressMapPopup desktopWidth="1280px" closePopup={disableForm}>
      <Head>
        <link
          rel="stylesheet"
          href="https://geowidget.inpost.pl/inpost-geowidget.css"
        />
        <Script
          nonce={process.env["NEXT_PUBLIC_CSP_NONCE"]}
          id="inpost"
          src="https://geowidget.inpost.pl/inpost-geowidget.js"
        />
      </Head>

      <div className="inpost-geowidget">
        <inpost-geowidget
          onpoint="onpointselect"
          token="token-for-geo"
          language="pl"
          config="parcelcollect"
        />
      </div>
    </StyledInpostAddressMapPopup>
  );
};

export default InpostAddressMap;
