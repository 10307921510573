export enum SortDirection {
  ASC = "asc",
  DESC = "desc",
  NONE = "none",
}

export enum SortType {
  NAME = "name",
  PRICE = "price",
  DEFAULT = "default",
}

export interface SortOption {
  type: SortType;
  direction: SortDirection;
}
