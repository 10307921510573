import { z } from "zod";
import * as themeScheme from "@ecp-redux/dto/themeSettings/themeSettings.schemes";
import {
  AlignmentHorizontalOption,
  ButtonActionOption,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";

export const IBoxMiniCartMessagesScheme = z.object({
  mini_cart_icon_open_label: themeScheme.MessageScheme.default("Koszyk"),
  mini_cart_title: themeScheme.MessageScheme.default("Koszyk"),
  mini_cart_dropped_products_price_label:
    themeScheme.MessageScheme.default("Cena produktów:"),
  mini_cart_dropped_count_products_suffix:
    themeScheme.MessageScheme.default("art."),
  mini_cart_dropped_delivery_price_label:
    themeScheme.MessageScheme.default("Dostawa od"),
  mini_cart_dropped_savings_price_label:
    themeScheme.MessageScheme.default("Oszczędzasz:"),
  mini_cart_dropped_products_total_price_label:
    themeScheme.MessageScheme.default("Łączna kwota z vat"),
  mini_cart_dropped_button_go_to_cart_label:
    themeScheme.MessageScheme.default("Przejdź do koszyka"),
  add_to_cart_error_message: themeScheme.MessageScheme.default(
    "Brak możliwości dodania produktu do koszyka"
  ),
  add_to_cart_success_message: themeScheme.MessageScheme.default(
    "Produkt dodany do koszyka"
  ),
  product_error_limit_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono dozwolony limit produktów"
  ),
  product_not_available_error: themeScheme.MessageScheme.default(
    "Produkt niedostępny"
  ),
  product_error_stock_exceeded: themeScheme.MessageScheme.default(
    "Przekroczono ilość produktu dostępną na stock"
  ),
  cart_quantity_limit_exceeded_warning: themeScheme.MessageScheme.default(
    "Przekroczono limit koszykowy"
  ),
});

export const IBoxMiniCartSettingsScheme = z.object({
  mini_cart_action_on_icon: z
    .nativeEnum(ButtonActionOption)
    .default(ButtonActionOption.CLICK)
    .catch(ButtonActionOption.CLICK),
  mini_cart_on_action_redirect_url:
    themeScheme.UrlScheme.default("/cart").catch("/cart"),
  mini_cart_dropped_container_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.LEFT)
    .catch(AlignmentHorizontalOption.LEFT),
  mini_cart_button_position: z
    .nativeEnum(AlignmentHorizontalOption)
    .default(AlignmentHorizontalOption.CENTER)
    .catch(AlignmentHorizontalOption.CENTER),
  mini_cart_cart_products_count_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  mini_cart_icon_button:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  mini_cart_cart_products_count_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_title_typo:
    themeScheme.TypoIdScheme.default("typo$$4").catch("typo$$4"),
  mini_cart_dropped_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_summary_products_count_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  mini_cart_dropped_summary_products_count_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_product_title_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  mini_cart_dropped_product_title_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_single_product_count_typo:
    themeScheme.TypoIdScheme.default("typo$$6").catch("typo$$6"),
  mini_cart_dropped_single_product_count_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_single_product_price_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  mini_cart_dropped_single_product_price_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_single_product_price_line_through_typo:
    themeScheme.TypoIdScheme.default("typo$$9").catch("typo$$9"),
  mini_cart_dropped_single_product_price_line_through_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  mini_cart_dropped_single_product_line_price_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  mini_cart_dropped_single_product_line_price_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_products_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  mini_cart_dropped_products_price_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_products_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$3").catch("typo$$3"),
  mini_cart_dropped_products_price_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_delivery_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  mini_cart_dropped_delivery_price_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_delivery_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$3").catch("typo$$3"),
  mini_cart_dropped_delivery_price_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_savings_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  mini_cart_dropped_savings_price_label_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  mini_cart_dropped_savings_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$3").catch("typo$$3"),
  mini_cart_dropped_savings_price_value_color:
    themeScheme.ColorIdScheme.default("color$$2").catch("color$$2"),
  mini_cart_dropped_products_total_price_label_typo:
    themeScheme.TypoIdScheme.default("typo$$5").catch("typo$$5"),
  mini_cart_dropped_products_total_price_label_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_products_total_price_value_typo:
    themeScheme.TypoIdScheme.default("typo$$3").catch("typo$$3"),
  mini_cart_dropped_products_total_price_value_color:
    themeScheme.ColorIdScheme.default("color$$1").catch("color$$1"),
  mini_cart_dropped_button_go_to_cart_style:
    themeScheme.ButtonIdScheme.default("button$$1").catch("button$$1"),
  mini_cart_dropped_button_go_to_cart_width:
    themeScheme.SizeCSSScheme.default("100%").catch("100%"),
  mini_cart_dropped_wrapper_margin: themeScheme.MarginSizeScheme.default(
    "20px 20px 20px 20px"
  ).catch("20px 20px 20px 20px"),
  mini_cart_dropped_elements_gap:
    themeScheme.SizeCSSScheme.default("20px").catch("20px"),
  mini_cart_dropped_summary_elements_group:
    themeScheme.SizeCSSScheme.default("7px").catch("7px"),
  mini_cart_dropped_input_quantity_style:
    themeScheme.InputIdScheme.default("input$$2").catch("input$$2"),
  add_to_cart_error_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$2").catch("snackbar$$2"),
  add_to_cart_success_snackbar:
    themeScheme.SnackbarIdScheme.default("snackbar$$1").catch("snackbar$$1"),
  mini_cart_dropped_products_gap:
    themeScheme.SizeCSSScheme.default("24px").catch("24px"),
});
