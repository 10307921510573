import { IBannerElement } from "../dto/marketingBanners.types";
import { API, api } from "./api";

export const marketingBanners = api.injectEndpoints({
  endpoints: (builder) => ({
    getMarketingBanners: builder.query<IBannerElement[], string>({
      query: (categoryCode) => {
        return API.getMarketingBanners.endpoint(categoryCode);
      },
    }),
  }),
});

export const { useGetMarketingBannersQuery } = marketingBanners;

export const { getMarketingBanners } = marketingBanners.endpoints;
