import parse, { DOMNode, Element, domToReact } from "html-react-parser";
import ReactPlayer from "react-player";
import { ArticleListSourceOptions } from "@ecp-redux/dto/article.type";
import { FilterOperators } from "@ecp-redux/dto/requestPayload.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  IGlobalLinkObject,
  TLinkId,
  TUrl,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import ImageWrapper from "../../global/components/ImageWrapper/ImageWrapper";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { isPortalSide } from "../../helpers/helpers";
import StyledText from "../../shared/styleElements/StyledText/StyledText";
import { onlyPublishedArticlesFilter } from "../BoxArticleList/BoxArticleList.methods";
import { IArticleListFilter } from "../BoxArticleList/BoxArticleList.types";
import { TBoxArticleContentProps } from "./BoxArticleContent.types";

export const getArticleFilters = (
  contextArticleId: number | null
): IArticleListFilter[] => {
  const filters =
    contextArticleId !== null && !isPortalSide()
      ? []
      : [onlyPublishedArticlesFilter];

  if (contextArticleId !== null) {
    filters.push({
      name: ArticleListSourceOptions.ID,
      operator: FilterOperators.EQ,
      value: contextArticleId,
    });
  }

  return filters;
};

const getStyledTextFromParserWithMapping = (
  domNode: Element,
  tagName: string,
  typoId: string
): JSX.Element | undefined => {
  if (domNode.name === tagName && domNode.children[0].type === "text") {
    return (
      <StyledText
        renderAs={tagName}
        $settings={{ font: { id: typoId }, text: { color: "color$$1" } }}
      >
        {domNode.children[0].data}
      </StyledText>
    );
  }
  return undefined;
};

const getStyledLinkFromParserWithMapping = (
  domNode: Element,
  tagName: string,
  typoId: string
): JSX.Element | undefined => {
  if (domNode.name === tagName && domNode.children[0].type === "text") {
    return (
      <LinkWrapper
        linkStyle={{ id: typoId as TLinkId, custom: {} as IGlobalLinkObject }}
        href={domNode.attribs["href"]}
      >
        {domNode.children[0].data}
      </LinkWrapper>
    );
  }
  return undefined;
};

const getStyledListFromParserWithMapping = (
  domNode: Element,
  tagName: string,
  typoId: string
): JSX.Element | undefined => {
  if (domNode.name === tagName && domNode.children[0].type === "text") {
    return (
      <StyledText
        style={{ display: "list-item" }}
        renderAs={"li"}
        $settings={{ font: { id: typoId }, text: { color: "color$$1" } }}
      >
        {domNode.children[0].data}
      </StyledText>
    );
  }
  return undefined;
};

export const parseHtmlToReactArticle = (
  input: string,
  props: TBoxArticleContentProps,
  imageWidth?: number
) => {
  const options = {
    replace: (domNode: DOMNode) => {
      if (domNode.type !== "tag") return;
      if (!(domNode instanceof Element)) return;

      const {
        styleHeader1Id: styleH1Id,
        styleHeader2Id: styleH2Id,
        styleHeader3Id: styleH3Id,
        styleParagraphId,
        styleLinkId,
        styleListId,
      } = props.settings;

      switch (domNode.name) {
        case "li":
          if (domNode.children.length === 1)
            return getStyledListFromParserWithMapping(
              domNode,
              "li",
              styleListId
            );
          break;
        case "a":
          return getStyledLinkFromParserWithMapping(domNode, "a", styleLinkId);
        case "p":
          if (
            domNode.children.length === 1 &&
            domNode.children[0].type === "text"
          )
            return getStyledTextFromParserWithMapping(
              domNode,
              "p",
              styleParagraphId
            );
          break;
        case "strong":
          return getStyledTextFromParserWithMapping(
            domNode,
            "strong",
            styleParagraphId
          );
        case "italic":
          return getStyledTextFromParserWithMapping(
            domNode,
            "italic",
            styleParagraphId
          );
        case "h4":
          return getStyledTextFromParserWithMapping(domNode, "h4", styleH3Id);
        case "h3":
          return getStyledTextFromParserWithMapping(domNode, "h3", styleH2Id);
        case "h2":
          return getStyledTextFromParserWithMapping(domNode, "h2", styleH1Id);
        case "ol":
          return (
            <ol style={{ listStyle: "auto", padding: "revert" }}>
              {domToReact(domNode.children, options)}
            </ol>
          );
        case "oembed":
          return (
            <ReactPlayer
              width="100%"
              height="100%"
              url={domNode.attribs["url"]}
              controls={true}
              playing={false}
              style={{ aspectRatio: "16/9" }}
              loop={false}
            />
          );
        case "ul":
          return (
            <ul style={{ listStyle: "initial", padding: "revert" }}>
              {domToReact(domNode.children, options)}
            </ul>
          );
        case "img": {
          const alt = domNode.attribs["alt"];
          return (
            <div className="w-auto">
              <ImageWrapper
                imageUrl={domNode.attribs["src"] as TUrl}
                seoDescription={alt ?? "image in article"}
                imageFit={false}
                imageAlignment={{
                  horizontal: AlignmentHorizontalOption.CENTER,
                  vertical: AlignmentVerticalOption.CENTER,
                }}
                width={imageWidth}
              />
            </div>
          );
        }
        default:
          return;
      }
    },
  };

  return parse(input, options);
};
