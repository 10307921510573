import { useState } from "react";
import { AttributeValueViewModel, FacetVM } from "./BoxSearchFilters.types";

const checkMoreToShow = (
  values: AttributeValueViewModel[],
  limit: number
): false | number => (values.length > limit ? values.length - limit : false);

export const useSearchFacetLogic = (facet: FacetVM, initialLimit: number) => {
  const [facetsListSearchQuery, setFacetsListSearchQuery] = useState("");

  const changeSearchQuery = (searchQ: string) => {
    setFacetsListSearchQuery(searchQ.length > 2 ? searchQ : "");
  };

  const showElement = (
    valueIndex: number,
    showAll: boolean,
    limit: number
  ): boolean => showAll || valueIndex < limit;

  const facetValuesSearchResult = facet.values.filter((v) =>
    v.name.toLowerCase().includes(facetsListSearchQuery.toLowerCase())
  );

  const isMoreToShow = checkMoreToShow(facet.values, initialLimit);

  return {
    changeSearchQuery,
    showElement,
    facetValuesSearchResult,
    isMoreToShow,
  };
};
