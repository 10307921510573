import { ReactNode } from "react";
import { useTheme } from "styled-components";
import { convertTypoIdToValues } from "@ecp-boxes/settingsPatterns/settingsPatterns.methods";
import { useTableConxtexProvider } from "@ecp-boxes/shared/components/Table/tableContext/useTableContext";
import useIsMobilePortal from "@ecp-boxes/shared/hooks/useIsMobilePortal";
import { GlobalTableCellReadoutValue } from "@ecp-redux/dto/themeSettings/GlobalTableCell.types";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  StyledTableCell,
  StyledTableText,
  convertTableCellIdToValues,
} from "../../../shared/components/Table/TableCell";
import { StyledSpaceKeeper } from "../../../shared/components/domain/Product/ProductModule/ProductModule.styled";
import StyledText from "../../../shared/styleElements/StyledText/StyledText";
import { IPrefixSuffixSettings } from "../BoxTableData.types";

interface ITableDataTextCell {
  tableCellStyleId: GlobalTableCellReadoutValue;
  spaceBetweenPrefixSuffix: number;
  children: string | ReactNode;
  prefix: IPrefixSuffixSettings;
  suffix: IPrefixSuffixSettings;
  isOdd: boolean;
}
export const TableDataTextCell: React.FC<ITableDataTextCell> = ({
  tableCellStyleId,
  spaceBetweenPrefixSuffix,
  children,
  prefix,
  suffix,
  isOdd,
}) => {
  const theme = useTheme() as IThemeState;
  const isMobile = useIsMobilePortal();

  const tableContext = useTableConxtexProvider();

  const tableCellStyles = convertTableCellIdToValues(
    tableCellStyleId,
    theme.globalObjects.tableCells
  );

  const lineHeight = Math.max(
    convertTypoIdToValues(tableCellStyles.textStyle, theme.typography)
      .lineHeight,
    prefix.show
      ? convertTypoIdToValues(prefix.textStyle, theme.typography).lineHeight
      : 0,
    suffix.show
      ? convertTypoIdToValues(suffix.textStyle, theme.typography).lineHeight
      : 0
  );

  if (tableContext === undefined) {
    return null;
  }

  const height = tableContext?.table.rowHeight;

  const lines = Math.floor(height / lineHeight);

  return (
    <StyledTableCell tableCellStyleId={tableCellStyleId} isOdd={isOdd}>
      <StyledTableText
        spaceBetweenPrefixSuffix={spaceBetweenPrefixSuffix}
        className="table-data-text-cell"
      >
        <StyledSpaceKeeper rows={(isMobile ? 2 : lines) || 1}>
          {prefix.show && (
            <StyledText
              $settings={{
                font: prefix.textStyle,
                text: { color: prefix.textColor },
              }}
              className="table-data-text-cell__prefix"
            >
              {prefix.label}
            </StyledText>
          )}
          {children}
          {suffix.show && (
            <StyledText
              $settings={{
                font: suffix.textStyle,
                text: { color: suffix.textColor },
              }}
              className="table-data-text-cell__suffix"
            >
              {suffix.label}
            </StyledText>
          )}
        </StyledSpaceKeeper>
      </StyledTableText>
    </StyledTableCell>
  );
};

export default TableDataTextCell;
