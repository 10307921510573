import useIsMobilePortal from "../../../../shared/hooks/useIsMobilePortal";
import ShowOnPage from "../../ShowOnPage/ShowOnPage";
import { StyledPagination } from "../Table.styled";
import { TableProps } from "../Table.types";
import { Pagination } from "./Pagination";

type PaginationProps = {
  pagination: NonNullable<TableProps["pagination"]>;
  paginationStyle: TableProps["tableSettings"]["pagination"];
};

const TablePagination: React.FC<PaginationProps> = ({
  pagination: {
    page,
    pageSize,
    total,
    onPageChange,
    initialPageSize,
    showOnPageLabel,
    fromText,
  },
  paginationStyle: { alignment, textStyle, textColor },
}) => {
  const isMobile = useIsMobilePortal();

  return (
    <StyledPagination
      alignment={alignment.horizontal}
      className="table-pagination"
      data-testid="TablePagination"
    >
      <div className="table-pagination__container">
        {!isMobile && (
          <ShowOnPage
            choosen={pageSize}
            label={showOnPageLabel}
            showOnPageLabelSettings={{
              font: textStyle,
              text: { color: textColor },
            }}
            changePageSize={(v) =>
              onPageChange({ page: undefined, pageSize: v })
            }
            showOptions={[
              initialPageSize,
              initialPageSize * 2,
              initialPageSize * 3,
            ]}
          />
        )}
        <Pagination
          page={page}
          pageQuantity={Math.ceil(total / pageSize)}
          setPage={(v) => onPageChange({ page: v, pageSize: undefined })}
          fromText={fromText}
          textSettings={{
            font: textStyle,
            text: { color: textColor },
          }}
        />
      </div>
    </StyledPagination>
  );
};

export default TablePagination;
