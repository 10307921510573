import { useEffect } from "react";
import classNames from "classnames";
import { getHrefFromLinkContent } from "../../helpers/linkHelpers";
import { SelectedGoToOption } from "../../settingsPatterns/contentPatterns.types";
import { usePrepareSettings } from "../../settingsPatterns/settingsPatterns.methods";
import { PortalPath } from "../../shared/portalPath/portalPath";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import { useDynamicPageContext } from "../../structure/Contexts/DynamicPageContext";
import { useStickyOffsetsContext } from "../../structure/Contexts/StickyOffsetsContext";
import {
  checkIfButtonShouldBeDisabled,
  getButtonPaths,
  getIsActiveButton,
  scrollIntoRowPosition,
} from "./BoxListOfButtons.methods";
import {
  BOX_LIST_OF_BUTTONS_CONTENT,
  BOX_LIST_OF_BUTTONS_CONTENT_SETTINGS,
  BOX_LIST_OF_BUTTONS_DISPLAY_SETTINGS,
} from "./BoxListOfButtons.pattern";
import { StyledListOfButtons } from "./BoxListOfButtons.styled";
import { IBoxListOfButtonsProps } from "./BoxListOfButtons.types";

const BoxListOfButtons: React.FC<IBoxListOfButtonsProps> = (props) => {
  const { offsetsData } = useStickyOffsetsContext();

  const displaySettings = usePrepareSettings({
    props: props.displaySettings,
    settingsPattern: BOX_LIST_OF_BUTTONS_DISPLAY_SETTINGS(),
  }).settings;

  const contentSettings = usePrepareSettings({
    props: props.contentSettings,
    settingsPattern: BOX_LIST_OF_BUTTONS_CONTENT_SETTINGS(),
  }).settings;

  const content = usePrepareSettings({
    props: props.content,
    settingsPattern: BOX_LIST_OF_BUTTONS_CONTENT(),
  }).settings;

  const { setActivePaths, activePaths } = useDynamicPageContext();

  useEffect(() => {
    if (contentSettings.buttonsList.length > 0) {
      const firstButtonPaths = getButtonPaths(contentSettings.buttonsList[0]);
      setActivePaths(
        props.id,
        firstButtonPaths.map((el) => new PortalPath(el))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledListOfButtons
      className="list-of-buttons"
      boxWidth={displaySettings.boxWidth}
      spaceBetweenButtons={displaySettings.spaceBetweenButtons}
      spaceBetweenRows={displaySettings.spaceBetweenRows}
      alignment={displaySettings.alignment}
      padding={displaySettings.padding}
      vertical={displaySettings.vertical}
    >
      <div className="list-of-buttons__container">
        {contentSettings.buttonsList.map((button, index) => {
          const buttonPaths = getButtonPaths(button);
          const isActiveButton = getIsActiveButton(activePaths, buttonPaths);
          const isBoxSelected = !buttonPaths.every(
            (path) => new PortalPath(path).getLastPublication() === "x"
          );
          const href = getHrefFromLinkContent(button);

          return (
            <StyledButton
              {...(button
                ? {
                    href:
                      href &&
                      button.selectedGoToOption !== SelectedGoToOption.GO_TO_ROW
                        ? href
                        : undefined,
                    onClick: () => {
                      if (
                        button.selectedGoToOption ===
                        SelectedGoToOption.CHANGE_ORDER_INSIDE_STACK
                      ) {
                        setActivePaths(
                          props.id,
                          buttonPaths.map((el) => new PortalPath(el))
                        );
                      } else if (
                        button.selectedGoToOption ===
                        SelectedGoToOption.GO_TO_ROW
                      ) {
                        scrollIntoRowPosition(button, props.path, offsetsData);
                      }
                    },
                  }
                : {})}
              target={button.goToPageSetting?.openInNewTab}
              className={classNames("list-of-buttons__container__item", {
                "active-button": isActiveButton,
                "disabled-button": checkIfButtonShouldBeDisabled(
                  isBoxSelected,
                  button.selectedGoToOption,
                  href
                ),
              })}
              $settings={displaySettings.buttonsStyle}
              key={`${content.buttonsLabels[index]}${index}`}
            >
              {content.buttonsLabels[index]}
            </StyledButton>
          );
        })}
      </div>
    </StyledListOfButtons>
  );
};
export default BoxListOfButtons;
