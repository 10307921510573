import {
  ContactFormPayloadProperty,
  IContactFormPayload,
} from "../dto/contact.types";
import { API, api } from "./api";

export const contact = api.injectEndpoints({
  endpoints: (builder) => ({
    postContactForm: builder.mutation<void, IContactFormPayload>({
      query: (body) => {
        const formData = new FormData();
        const keys = Object.keys(body) as ContactFormPayloadProperty[];
        keys.forEach((k) => {
          if (k === "files") {
            body[k]?.forEach((file: File) => formData.append("files", file));
          } else {
            formData.append(k, body[k] ?? "");
          }
        });

        return {
          url: API.postContactForm.endpoint(),
          method: "POST",
          credentials: "include",
          mode: "cors",
          body: formData,
        };
      },
    }),
  }),
});

export const { usePostContactFormMutation } = contact;

export const { postContactForm } = contact.endpoints;
