import React, { useEffect, useRef } from "react";
import { useTheme } from "styled-components";
import { IThemeState } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { useMessagesSettingsContext } from "../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountMyAddressListMessages,
  IBoxMyAccountMyAddressListSettings,
} from "../../BoxMyAccountMyAdressList/BoxMyAccountMyAddressList.types";
import { StyledStoresLocatorMapSingleListElement } from "../BoxStoreLocator.styled";
import {
  IBoxStoreLocatorMessages,
  IBoxStoreLocatorSettings,
} from "../BoxStoreLocator.types";
import Store from "../elements/Store";
import { IStoreLocatorMapListElement } from "./StoreLocatorMap.types";

const StoreLocatorMapListElement: React.FC<IStoreLocatorMapListElement> = ({
  store,
  isActive,
  onClick,
  onSelectAddressButtonClick,
}: IStoreLocatorMapListElement) => {
  const { settings } = useMessagesSettingsContext<
    IBoxStoreLocatorMessages | IBoxMyAccountMyAddressListMessages,
    IBoxStoreLocatorSettings | IBoxMyAccountMyAddressListSettings
  >();
  const theme = useTheme() as IThemeState;
  const singleListElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive && singleListElementRef.current) {
      singleListElementRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <StyledStoresLocatorMapSingleListElement
      ref={singleListElementRef}
      onClick={() => onClick && onClick()}
      isActive={isActive}
      backgroundColor={settings.storelocator_store_picked_color}
      theme={theme}
      className="store-single-list-element-container"
    >
      <Store
        key={store.code}
        store={store}
        isPhoto={false}
        onSelectAddressButtonClick={onSelectAddressButtonClick}
      />
    </StyledStoresLocatorMapSingleListElement>
  );
};

export default StoreLocatorMapListElement;
