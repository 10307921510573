import assignWith from "lodash.assignwith";
import { DeliveryTimeCode, ISrpResponse } from "../dto/searchResults.types";

export const sortProductsInOrderFromSearch = (
  productsData: ISrpResponse[],
  skusFromSearch: string[]
): ISrpResponse[] =>
  productsData.sort((a, b) => {
    const indexA = skusFromSearch.indexOf(a.sku);
    const indexB = skusFromSearch.indexOf(b.sku);
    return indexA - indexB;
  });

export const getActualPrice = (
  basePrice: number,
  promotionPrice: number | null
) => {
  return !promotionPrice || basePrice <= promotionPrice
    ? basePrice
    : promotionPrice;
};

export const getOldPrice = (
  basePrice: number,
  promotionPrice: number | null
) => {
  return promotionPrice && promotionPrice < basePrice ? basePrice : null;
};

export const transformSrpResponse = (data: ISrpResponse[]) => {
  return data.map((product) => {
    const { sku } = product;
    const {
      name,
      price,
      rating,
      coverPhoto,
      deliveryTime,
      badges,
      lowestCategoryLevel,
    } = product.srpInfo;
    const { basePrice, promotionPrice, netPrice, grossPrice } = { ...price };
    const actualPrice = getActualPrice(basePrice, promotionPrice);
    const oldPrice = getOldPrice(basePrice, promotionPrice);
    const tax = product.srpInfo?.tax ?? null;
    const deliveryCode = deliveryTime ?? DeliveryTimeCode.NOT_AVAILABLE;
    const pools = product.srpInfo?.["pools"] ?? [];
    const attributes = product?.attributes ?? {};
    const url = product.srpInfo?.url ?? null;
    const hoverPhoto = product.srpInfo?.hoverPhoto ?? null;
    const photoGallery = product.srpInfo?.photoGallery ?? [];
    const wishlist = product.srpInfo?.wishlist ?? false;
    const variants = product.srpInfo?.variants ?? [];
    const axis = product.srpInfo?.axis ?? null;
    const associations = product?.associations ?? {};
    const type = product?.type ?? null;
    const conceptId = product?.conceptId ?? null;
    const deliveryQuantity = product?.srpInfo?.deliveryQuantity ?? null;
    const alternativeUnitOfMeasures =
      product?.srpInfo?.alternativeUnitOfMeasures ?? [];
    const mainUnitOfMeasurement =
      product?.srpInfo?.mainUnitOfMeasurement ?? null;
    const mainUnitOfMeasurementLabel =
      product?.srpInfo?.mainUnitOfMeasurementLabel ?? null;
    const concession = product?.srpInfo?.concession ?? null;
    return assignWith(
      {},
      {
        attributes,
        sku,
        name,
        price: {
          actualPrice: actualPrice ?? null,
          oldPrice: oldPrice ?? null,
          netPrice: netPrice ?? null,
          grossPrice: grossPrice ?? null,
        },
        tax,
        rating,
        coverPhoto,
        hoverPhoto,
        deliveryTime: deliveryCode,
        badges,
        pools,
        url,
        photoGallery,
        wishlist,
        axis,
        variants,
        associations,
        type,
        conceptId,
        lowestCategoryLevel,
        deliveryQuantity,
        alternativeUnitOfMeasures,
        mainUnitOfMeasurement,
        mainUnitOfMeasurementLabel,
        concession,
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_: any, value: any) => (typeof value == "undefined" ? null : value)
    );
  });
};
