import React, { useState } from "react";
import { MyAddressType } from "@ecp-redux/dto/myAddresses.type";
import { ReactComponent as PenIcon } from "../../../../../../../../libs/boxes/src/lib/shared/icons/pen.svg";
import { isNotEmptyMessage } from "../../../../helpers/isNotEmptyMessage";
import { StyledBackground } from "../../../../shared/styleElements/StyledBackground/StyledBackground.styled";
import StyledText from "../../../../shared/styleElements/StyledText/StyledText";
import { useMessagesSettingsContext } from "../../../../structure/Contexts/MessagesSettingsContext";
import {
  IBoxMyAccountMyAddressListMessages,
  IBoxMyAccountMyAddressListSettings,
} from "../../BoxMyAccountMyAddressList.types";
import { StyledPostal } from "../../StyledBoxMyAccountMyAddressList.styled";
import ChangeAddress from "../ChangeMyAddressBillingPostal";
import MyAddressInfo from "../MyAddressInfo";
import { IPostalProps } from "./Postal.types";

const Postal: React.FC<IPostalProps> = ({
  selectedPostal,
  postalAddresses,
}) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxMyAccountMyAddressListMessages,
    IBoxMyAccountMyAddressListSettings
  >();

  const [editAddress, setEditAddress] = useState(false);

  return (
    <StyledPostal className="postal">
      <StyledText
        className="postal__title"
        $settings={{
          font: settings.myaccount_address_list_delivery_address_label_typo,
          text: {
            color: settings.myaccount_address_list_delivery_address_label_color,
          },
        }}
        show={isNotEmptyMessage(
          messages.myaccount_address_list_delivery_address_title
        )}
      >
        <p>{messages.myaccount_address_list_delivery_address_title}</p>
      </StyledText>

      {!editAddress && selectedPostal ? (
        <StyledBackground
          className="postal__background"
          backgroundColor={
            settings.myaccount_address_list_address_background_color
          }
        >
          <StyledText
            className="postal__address"
            $settings={{
              font: settings.myaccount_address_list_delivery_address_value_typo,
              text: {
                color:
                  settings.myaccount_address_list_delivery_address_value_color,
              },
            }}
          >
            <MyAddressInfo address={selectedPostal} />
            <button
              className="postal__edit-address-button"
              onClick={() => setEditAddress(true)}
              data-testid="editAddressButton"
            >
              <PenIcon />
            </button>
          </StyledText>
        </StyledBackground>
      ) : (
        <ChangeAddress
          closeEditAddress={() => setEditAddress(false)}
          selectedAddress={selectedPostal}
          addresses={postalAddresses}
          addressType={MyAddressType.POSTAL}
        />
      )}
    </StyledPostal>
  );
};

export default Postal;
