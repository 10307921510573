import {
  IGlobalAccordionObject,
  VerticalPosition,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  ACCORDION_STYLE_SETTING_PATTERN,
  INPUT_SEARCH_PATTERN,
  LINK_STYLE_SETTING_PATTERN,
  TEXT_STYLE_SETTING_PATTERN,
  TITLE_CONTENT_SETTING_PATTERN,
  TYPO_STYLE_SETTING_PATTERN,
} from "../../settingsPatterns/settingsPatterns.utils";
import {
  FacetStyle,
  IBoxSearchFiltersContent,
  IBoxSearchFiltersContentSettings,
  IBoxSearchFiltersDisplaySettings,
} from "./BoxSearchFilters.types";

export const BOX_SEARCH_FILTERS_CONTENT_SETTINGS_PATTERN =
  (): IBoxSearchFiltersContentSettings => ({
    title: TITLE_CONTENT_SETTING_PATTERN("typo$$3", "color$$1"),
    showMore: {
      show: true,
      ...TEXT_STYLE_SETTING_PATTERN("typo$$7", "color$$1"),
      initialLimit: 4,
    },
    clear: {
      show: true,
      ...TEXT_STYLE_SETTING_PATTERN("typo$$7", "color$$5"),
    },
    count: {
      show: true,
      ...TEXT_STYLE_SETTING_PATTERN("typo$$7", "color$$2"),
    },
    search: INPUT_SEARCH_PATTERN("input$$2"),
    categories: {
      show: true,
      position: VerticalPosition.TOP,
    },
  });

export const BOX_SEARCH_FILTERS_DISPLAY_SETTINGS_PATTERN =
  (): IBoxSearchFiltersDisplaySettings => ({
    spaceBetweenFacets: 5,
    spaceBetweenCategoriesAndFilters: 5,
    facetNameStyle: TEXT_STYLE_SETTING_PATTERN("typo$$5", "color$$1"),
    facetValueStyle: TEXT_STYLE_SETTING_PATTERN("typo$$7", "color$$1"),
    facetStyle: {
      displayType: FacetStyle.LIST,
      accordionStyle: {
        id: ACCORDION_STYLE_SETTING_PATTERN().id,
        custom: ACCORDION_STYLE_SETTING_PATTERN()
          .custom as IGlobalAccordionObject,
      },
    },
    categoryLinkStyle: {
      link: LINK_STYLE_SETTING_PATTERN("link$$1"),
      text: TYPO_STYLE_SETTING_PATTERN("typo$$7"),
    },
  });

export const BOX_SEARCH_FILTERS_CONTENT_PATTERN =
  (): IBoxSearchFiltersContent => ({
    facetsSelection: [],
    facetsTitle: "",
    showMore: "",
    showLess: "",
    clear: "",
    categoriesTitle: "",
    searchPlaceholder: "",
  });
